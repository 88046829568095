import * as actionTypes from '../actions/actionTypes';

const initialState = {
	error: null,
	loading: false,
	rateData: null
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PAGODA_RATES_FAILED:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case actionTypes.FETCH_PAGODA_RATES_SUCCESS:
			return {
				...state,
				rateData: action.rates,
				error: null,
				loading: false
			}
		case actionTypes.FETCH_PAGODA_RATES_START:
			return {
				...state,
				loading: true,
				error: null
			}
		case actionTypes.CLEAN_PAGODA_RATES:
			return initialState;

		default:
			return state;
	}
}

export default reducer;