import React from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
//UI
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
//Material UI
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accountHistory: {
    gridArea: 'accounthistory',
    '& section': {
      display: 'grid',
      boxSizing: 'border-box',
      placeItems: 'center',
      gridTemplateColumns: 'max-content max-content',
      gridTemplateRows: 'auto',
      padding: '0 10px',
      '& p': {
        fontSize: '0.9rem',
      },
      '& h2': {
        paddingRight: '7px',
        textAlign: 'right',
        justifySelf: 'flex-end'
      },
    },
  },
  container: {
    height: 'calc(100% - 42px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width:800px)': {
    accountHistory: {
      gridArea: 'accounthistory',
      '& section': {
        padding: '15px',
        display: 'grid',
        gridTemplateColumns: '100px 100px auto 100px 100px',
        gridTemplateRows: 'auto',
        alignItems: 'center',
        '& p': {
          fontSize: '0.9rem',
        },
        '& h2': {
          paddingRight: '7px',
          textAlign: 'right',
        },
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    accountHistory: {
      gridArea: 'accounthistory',
      '& section': {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        placeItems: 'center',
      },
    },
  },
}));

const AccountHistory = (props) => {
  const classes = useStyles();
  const { prevLoginAt, created } = useSelector((state) => state.user);

  return (
    <Paper elevation={1} className={classes.accountHistory}>
      <AppBar elevation={0} position='static'>
        <CustomToolbar title={props.translations.accounthistory}></CustomToolbar>
      </AppBar>
      <div className={classes.container}>

        <section>
          <h2>{props.translations.created} :</h2>
          <p>{moment(created).format('YYYY-MM-DD')}</p>
          <h2>{props.translations.previouslogin} :</h2>
          <p>{moment(prevLoginAt).format('YYYY-MM-DD')}</p>
        </section>
      </div>
    </Paper>
  );
};

export default connect(null)(AccountHistory);
