import React from 'react'
//UI
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
//Material UI
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	statisticsSettings: {
		gridArea: 'statistics'

	}
})

const StatisticsSettings = (props) => {
	const classes = useStyles();

	return (
		<Paper elevation={1} className={classes.statisticsSettings}>
			<AppBar elevation={0} position="static" >
				<CustomToolbar title={props.translations.statisticssettings}>
				</CustomToolbar>
			</AppBar>
			{/*  {props.translations.comingsoon} */}
		</Paper>
	)
}

export default StatisticsSettings;