import React from 'react';
import ReactEcharts from 'echarts-for-react';
import Paper from '@material-ui/core/Paper';
import translations from '../../../services/translations/translations';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import moment from 'moment';
import { getTheme } from '../../../services/statistics/chartThemes';
import { groupBy } from '../../../helpers';

const MonthlyShipments = ({ data: shipmentData, lang, theme }) => {
  moment.locale(lang);

  const data = Array.from(groupBy(shipmentData, (shpmt) => moment(shpmt.createdAt).month())).sort(
    (a, b) => a[0] - b[0]
  );

  echarts.registerTheme(theme, getTheme[theme]);
  const title = translations[lang].statistics.monthlyShipments;
  const monthlyShipmentsOptions = {
    title: {
      text: title,
    },
    tooltip: {},
    xAxis: {
      data: data.map((shpmt) => moment(shpmt[0] + 1, 'MM').format('MMM')),
    },
    yAxis: {},
    series: [
      {
        name: translations[lang].statistics.shipments,
        type: 'bar',
        data: data.map((shpmt, index) => {
          return { value: shpmt[1].length };
        }),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEcharts
        echarts={echarts}
        option={monthlyShipmentsOptions}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default MonthlyShipments;
