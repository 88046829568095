import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Select from 'react-select';
import { SelectStylesMain } from '../../UI/SelectStyles/SelectStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    alignSelf: 'start',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  select: {
    zIndex: 1001,
    width: '200px',
    boxSizing: 'border-box',
    '& > p': {
      textAlign: 'center',
    },
  },
  button: {
    maxWidth: '400px',
    width: '90%',
  },
  selected: {
    backgroundColor: '#e6e0e8',
  },
  notifyMode: {
    color: 'red',
    fontStyle: 'italic',
    textAlign: 'center',
    '& > span': {
      textTransform: 'lowercase',
    },
  },
});

const TermsOfDelivery = ({ selectedModes, selectedTerm, handleTermsChange, translations }) => {
  const classes = useStyles();

  const allTerms = [
    { label: 'DAP', value: 'DAP' },
    { label: 'EXW', value: 'EXW' },
    { label: 'FCA', value: 'FCA' },
    { label: 'CPT', value: 'CPT' },
    { label: 'CIP', value: 'CIP' },
    { label: 'DPU', value: 'DPU' },
    { label: 'DDP', value: 'DDP' },
  ];
  const seaTerms = [
    { label: 'FAS', value: 'FAS' },
    { label: 'FOB', value: 'FOB' },
    { label: 'CFR', value: 'CFR' },
    { label: 'CIF', value: 'CIF' },
  ];

  let deliveryTerms;
  if (selectedModes.length === 0) {
    deliveryTerms = [];
  } else if (selectedModes.includes('seaFreight')) {
    deliveryTerms = allTerms.concat(seaTerms);
  } else {
    deliveryTerms = allTerms;
  }

  const placeholder = selectedModes.length > 0 ? translations.selectList.statePlaceholder : '';

  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <Select
          styles={SelectStylesMain}
          value={deliveryTerms.filter(option => option.value === selectedTerm)}
          placeholder={placeholder}
          label={translations.requestbooking.termsofdelivery}
          onChange={handleTermsChange}
          options={deliveryTerms}
        />
        {selectedModes.length === 0 && (
          <p className={classes.notifyMode}>
            {translations.selectList.statePlaceholder}
            <span>&nbsp;{translations.requestbooking.transportmode}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default TermsOfDelivery;
