import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, Radio } from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CircleSpinnerSmall } from '../../../../UI/Spinners/Circle';
import ReactToPrint from 'react-to-print';
import clsx from 'clsx';
import {
  GenerateDownloadLink,
  LabelPDF,
  ThermalPrint,
} from '../../../../Documents/Labels/LabelBuilders';
//icons
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ThermalPrinterIcon from '../../../../UI/Icons/ThermalPrinter';
import { CARRIERS } from '../../../../../services/types';
import { useEffect } from 'react';
import { printPdfData } from '../../../../../services/print';
import {
  extractLabelTypes,
  getPrinterOptionsByCarrier,
} from '../../../../../services/helpers/printerOptions';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  DialogContent: {
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  dialogInfo: {
    fontWeight: '400',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  error: {
    color: theme.status.danger,
  },
  labelMultipleActionContainer: {
    display: 'grid',
    columnGap: '15px',
    rowGap: '6px',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    maxWidth: 400,
  },
  labelMultipleActionLabel: {
    fontWeight: '500',
    fontSize: '1.1rem',
    gridColumn: '1 / -1',
    textAlign: 'center',
  },
  labelActionContainer: {
    width: '100%',
    maxWidth: 400,
  },
  labelActionButton: {
    textTransform: 'capitalize',
    height: 45,
    width: '100%',
    position: 'relative',
  },
  otherDocsButton: {
    backgroundColor: 'white',
    color: grey[800],
  },
  icon: {
    position: 'absolute',
    fontSize: '2rem',
    right: 10,
  },
  button: {
    position: 'relative',
    width: '100%',
    maxWidth: '300px',
    textTransform: 'capitalize',
    '&:not(:first-child)': {
      marginTop: '5px',
    },
  },
  spinner: {
    position: 'absolute',
    right: 0,
  },
  selectContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  selectOption: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px',
  },
}));

const initialState = { error: null, loading: false, labels: [], otherDocs: [] };

export default function LabelRecoveryModal({
  open,
  handleAbort,
  translations,
  carrier,
  shipmentIdsWithTrackingNumbers,
}) {
  const [options, setOptions] = useState([]);
  const [labelDeliveryOption, setLabelDeliveryOption] = useState(null);
  const [state, setState] = useState(initialState);
  const { token } = useSelector((state) => state.auth);
  const { upsPrintFormat, dhlfreightPrintFormat } = useSelector((state) => state.bookingPresets);

  useEffect(() => {
    if (open) {
      const printerOptions = getPrinterOptionsByCarrier(carrier, translations);
      setOptions(printerOptions);

      let initialOption = printerOptions[0];
      if (carrier === CARRIERS.UPS) {
        initialOption =
          Object.values(printerOptions).find(({ value }) => value === upsPrintFormat) ||
          printerOptions[0];
      }
      if (carrier === CARRIERS.DHLFREIGHT) {
        initialOption =
          Object.values(printerOptions).find(({ value }) => value === dhlfreightPrintFormat) ||
          printerOptions[0];
      }

      setLabelDeliveryOption(initialOption.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const errorMessageNolabels = translations.shipmenthistory.customToolbar.unableToRecoverLabel;
  const errorMessageFailedRecoveries =
    translations.shipmenthistory.customToolbar.unableToRecoverTheFollowing;

  const handleLabelDeliveryOptionChange = ({ target: { value } }) => {
    if (state.error) setState({ ...state, error: null });
    setLabelDeliveryOption(value);
  };

  const handleLabelRecoverySubmit = () => {
    setState({ error: null, loading: true, labels: [], otherDocs: [] });
    const selectedOption = options.find(({ value }) => value === labelDeliveryOption);

    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    const labelOptions = { recoverFormat: selectedOption.value };
    if (shipmentIdsWithTrackingNumbers.length === 1) {
      const { trackingNumber, shipmentId } = shipmentIdsWithTrackingNumbers[0];
      axios
        .post(
          '/api/shipping/label-recovery',
          { labelOptions, carrier, trackingNumber, shipmentId },
          config
        )
        .then((resp) => {
          const { labels, otherDocs } = extractLabelTypes(resp.data.labels);
          setState(() => ({ loading: false, error: null, labels, otherDocs }));
        })
        .catch((e) => {
          setState(() => ({
            loading: false,
            labels: [],
            otherDocs: [],
            error: errorMessageNolabels,
          }));
        });
      return;
    }
    if (shipmentIdsWithTrackingNumbers.length > 1) {
      axios
        .post(
          '/api/shipping/multiple-labels-recovery',
          { labelOptions, carrier, shipmentIdsWithTrackingNumbers },
          config
        )
        .then((resp) => {
          const { labels, otherDocs } = extractLabelTypes(resp.data.labels);

          const error =
            Array.isArray(resp.data.failedRecoveries) && resp.data.failedRecoveries.length > 0
              ? `${errorMessageFailedRecoveries} ${[...resp.data.failedRecoveries].join(', ')}`
              : null;
          setState(() => ({ loading: false, error, labels, otherDocs }));
        })
        .catch((e) => {
          setState(() => ({
            loading: false,
            labels: [],
            otherDocs: [],
            error: errorMessageNolabels,
          }));
        });
      return;
    }

    return;
  };

  const handleClose = () => {
    setState(initialState);
    options.length > 0 && setOptions([]);
    labelDeliveryOption && setLabelDeliveryOption(null);
    handleAbort();
  };

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="label-recovery-dialog-title"
      aria-describedby="label-recovery-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle className={classes.modalTitle} id="label-recovery-dialog-title">
        {translations.shipmenthistory.customToolbar.labelRecover}
      </DialogTitle>
      <div className={classes.DialogContent}>
        <p className={classes.error}>{state.error}</p>
        {state.labels.length === 0 && (
          <div className={classes.selectContainer}>
            {options.length > 0 &&
              options.map((option) => (
                <div key={option.value} className={classes.selectOption}>
                  <Radio
                    color="primary"
                    onChange={handleLabelDeliveryOptionChange}
                    id={option.value}
                    checked={option.value === labelDeliveryOption}
                    value={option.value}
                    disabled={option.disabled}
                  />
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              ))}
          </div>
        )}
        {state.labels.length > 0 && labelDeliveryOption === 'PNG' && (
          <div className={classes.labelMultipleActionContainer}>
            <p className={classes.labelMultipleActionLabel}>{translations.labels}</p>
            <GenerateDownloadLink labels={state.labels}>
              <Button color="primary" variant="contained" className={classes.labelActionButton}>
                <p>{translations.shipmenthistory.customToolbar.download}</p>
                <DownloadIcon className={classes.icon} />
              </Button>
            </GenerateDownloadLink>
            <div>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    href="#"
                    className={classes.labelActionButton}
                  >
                    <p>{translations.shipmenthistory.customToolbar.print}</p>
                    <PrintIcon className={classes.icon} />
                  </Button>
                )}
                content={() => <LabelPDF packages={state.labels} />}
              />
            </div>
          </div>
        )}
        {state.labels.length > 0 && labelDeliveryOption.substring(0, 3) === 'PDF' && (
          <div className={classes.labelMultipleActionContainer}>
            <p className={classes.labelMultipleActionLabel}>{translations.labels}</p>
            <Button
              title="Download pdf labels"
              download="labels"
              href={`data:application/pdf;base64,${state.labels[0].img}`}
              color="primary"
              variant="contained"
              className={classes.labelActionButton}
            >
              <p>{translations.shipmenthistory.customToolbar.download}</p>
              <DownloadIcon className={classes.icon} />
            </Button>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.labelActionButton}
                onClick={() => printPdfData(state.labels[0].img)}
              >
                <p>{translations.shipmenthistory.customToolbar.print}</p>{' '}
                <PrintIcon className={classes.icon} />
              </Button>
            </div>
          </div>
        )}
        {state.labels.length > 0 && labelDeliveryOption.substring(0, 3) === 'ZPL' && (
          <div className={classes.labelActionContainer}>
            <ThermalPrint packages={state.labels}>
              <Button color="primary" variant="contained" className={classes.labelActionButton}>
                <p>{translations.shipmenthistory.customToolbar.printThermalLabels}</p>
                <ThermalPrinterIcon
                  style={{
                    fontSize: '2rem',
                    paddingLeft: '10px',
                    transform: 'translateY(4px)',
                  }}
                  className={classes.icon}
                />
              </Button>
            </ThermalPrint>
          </div>
        )}
        {state.otherDocs.length > 0 && (
          <div className={classes.labelMultipleActionContainer}>
            <p className={classes.labelMultipleActionLabel}>
              {translations.additionalShippingDocs}
            </p>
            <Button
              title="Download"
              download="labels"
              href={`data:application/pdf;base64,${state.otherDocs[0].img}`}
              variant="contained"
              className={clsx([classes.labelActionButton, classes.otherDocsButton])}
            >
              <p>{translations.shipmenthistory.customToolbar.download}</p>
              <DownloadIcon className={classes.icon} />
            </Button>
            <div>
              <Button
                variant="contained"
                className={clsx([classes.labelActionButton, classes.otherDocsButton])}
                onClick={() => printPdfData(state.otherDocs[0].img)}
              >
                <p>{translations.shipmenthistory.customToolbar.print}</p>{' '}
                <PrintIcon className={classes.icon} />
              </Button>
            </div>
          </div>
        )}

        <div className={classes.actionContainer}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleLabelRecoverySubmit}
            disabled={state.loading || state.labels.length > 0}
          >
            <p> {translations.shipmenthistory.customToolbar.recoverLabel}</p>
            {state.loading && (
              <div className={classes.spinner}>
                <CircleSpinnerSmall />
              </div>
            )}
          </Button>
          <Button variant="outlined" className={classes.button} onClick={handleClose}>
            {translations.shipmenthistory.customToolbar.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
