import Axios from 'axios';
import {
  ADD_INVOICE_FORM_TEMPLATE_FAIL,
  ADD_INVOICE_FORM_TEMPLATE_START,
  ADD_INVOICE_FORM_TEMPLATE_SUCCESS,
  FETCH_INVOICE_FORM_TEMPLATES_FAIL,
  FETCH_INVOICE_FORM_TEMPLATES_START,
  FETCH_INVOICE_FORM_TEMPLATES_SUCCESS,
  DELETE_INVOICE_FORM_TEMPLATE_FAIL,
  DELETE_INVOICE_FORM_TEMPLATE_START,
  DELETE_INVOICE_FORM_TEMPLATE_SUCCESS,
} from './actionTypes';

// Add Invoice Form Template
export const addInvoiceFormTemplate = (invoiceForm) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_INVOICE_FORM_TEMPLATE_START,
    });

    const { user, auth } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await Axios.post(
      `/api/templates/invoice-forms/add`,
      { invoiceForm, userId: user.userId, companyId: user.companyId },
      config
    );
    const { result } = data;
    dispatch({
      type: ADD_INVOICE_FORM_TEMPLATE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: ADD_INVOICE_FORM_TEMPLATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// Fetch invoice form templates
export const fetchInvoiceFormTemplates = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_INVOICE_FORM_TEMPLATES_START,
    });

    const { user, auth } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await Axios.get(`/api/templates/invoice-forms/${user.userId}`, config);
    const { result } = data;
    dispatch({
      type: FETCH_INVOICE_FORM_TEMPLATES_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INVOICE_FORM_TEMPLATES_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// Delete invoice form template
export const deleteInvoiceFormTemplate = (templateId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_INVOICE_FORM_TEMPLATE_START,
    });

    const { auth } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    await Axios.delete(`/api/templates/invoice-forms/delete-one/${templateId}`, config);
    dispatch({
      type: DELETE_INVOICE_FORM_TEMPLATE_SUCCESS,
      payload: templateId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_INVOICE_FORM_TEMPLATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
