import React from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import translationsTooltips from '../../services/translations/translationsTooltips';

import InfoIcon from '@material-ui/icons/Info';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    alignItems: 'baseline',
  },
  termsOfShipment: {
    width: '300px',
  },
  toolTip: {
    padding: '0 10px',
    color: grey[600],
    fontSize: '18px',
  },
}));

export default function Voec({ voecId, handleBlur, handleChange }) {
  const classes = useStyles();

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  return (
    <div className={classes.container}>
      <TextField
        id="voec-registration-number"
        inputProps={{ maxLength: 11 }}
        label={'Voec id'}
        placeholder="ex.VOEC1234567"
        className={classes.textField}
        name="voec-registration-number"
        value={voecId}
        onChange={handleChange}
        onBlur={handleBlur}
        margin="none"
      />
      <Tooltip title={translationsTooltips[lang].voecIdentificationNumber}>
        <InfoIcon className={classes.toolTip} />
      </Tooltip>
    </div>
  );
}
