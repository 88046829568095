import React, { useEffect } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authCheckState } from './store/actions';
import LoginLayout from './hoc/Layout/LoginLayout';
import Layout from './hoc/Layout/Layout';
//components for routes
import Auth from './containers/Auth/Auth';
import Rates from './containers/Rates/Rates';
import ShippingStatistics from './containers/ShippingStatistics/ShippingStatistics';
import CostStatistics from './containers/CostStatistics/CostStatistics';
import Bookings from './containers/Bookings/Bookings';
import OnlineBooking from './containers/Bookings/OnlineBooking/OnlineBooking';
import RequestBooking from './containers/Bookings/RequestBooking/RequestBooking';
import FileUploadBooking from './containers/Bookings/FileUploadBooking/FileUploadBooking';
import RequestOrder from './containers/Bookings/RequestOrder/RequestOrder';
import MyShipments from './containers/MyShipments/MyShipments';
import ManageCollection from './containers/ManageCollection/ManageCollection';
import TrackedShipments from './components/MyShipments/TrackedShipments/TrackedShipments';
import Templates from './containers/Templates/Templates';
import MyAccount from './containers/MyAccount/MyAccount';
/* Invoices section is under development */
// import Invoices from './containers/Invoices/Invoices';

function renderWithLayout(Component, Layout, id) {
  return (
    <Layout routeId={id ? id : null}>
      <Component />
    </Layout>
  );
}

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheckState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = (
    <Switch>
      <Route path="/" render={() => renderWithLayout(Auth, LoginLayout)} exact />

      <Route path="/bookings" render={() => renderWithLayout(Bookings, Layout)} exact />
      <Route
        path="/bookings/select"
        render={() => renderWithLayout(Bookings, Layout, '/bookings')}
        exact
      />
      <Route
        path="/bookings/onlinebooking"
        render={() => renderWithLayout(OnlineBooking, Layout, '/bookings')}
        exact
      />
      <Route
        path="/bookings/requestbooking"
        render={() => renderWithLayout(RequestBooking, Layout, '/bookings')}
        exact
      />
      <Route
        path="/bookings/filebooking"
        render={() => renderWithLayout(FileUploadBooking, Layout, '/bookings')}
        exact
      />
      <Route
        path="/bookings/requestorder"
        render={() => renderWithLayout(RequestOrder, Layout, '/bookings')}
        exact
      />
      <Route
        path="/bookings/pickup"
        render={() => renderWithLayout(ManageCollection, Layout, '/bookings')}
        exact
      />

      <Route path="/tools" render={() => renderWithLayout(MyShipments, Layout)} exact />
      <Route
        path="/tools/myshipments"
        render={() => renderWithLayout(MyShipments, Layout, '/tools')}
        exact
      />
      <Route
        path="/tools/templates"
        render={() => renderWithLayout(Templates, Layout, '/tools')}
        exact
      />
      <Route
        path="/tools/trackedshipments"
        render={() => renderWithLayout(TrackedShipments, Layout, '/tools')}
        exact
      />

      <Route path="/rates" render={() => renderWithLayout(Rates, Layout)} exact />
      <Route
        path="/rates/ratecalc"
        render={() => renderWithLayout(Rates, Layout, '/rates')}
        exact
      />

      <Route path="/statistics" render={() => renderWithLayout(ShippingStatistics, Layout)} exact />
      <Route
        path="/statistics/shipstats"
        render={() => renderWithLayout(ShippingStatistics, Layout, '/statistics')}
        exact
      />
      <Route
        path="/statistics/coststats"
        render={() => renderWithLayout(CostStatistics, Layout, '/statistics')}
        exact
      />

      <Route path="/settings" render={() => renderWithLayout(MyAccount, Layout)} exact />
      <Route
        path="/settings/myaccount"
        render={() => renderWithLayout(MyAccount, Layout, '/settings')}
        exact
      />
      {/* <Route path='/settings/invoices' render={() => renderWithLayout(Invoices, Layout, '/settings')} exact /> */}

      <Redirect to="/" />
    </Switch>
  );

  return <Switch>{routes}</Switch>;
};

export default withRouter(App);
