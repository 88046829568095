import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChangeAvatar from './ChangeAvatar/ChangeAvatar';
//import companyLogo from '../../../../assets/company-generic.png';
//material ui
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles({
  companyHeader: {
    gridColumnStart: 1,
    gridRowStart: 1,
    gridColumnEnd: 3,
    gridRowEnd: 2,
    padding: '10px',
    display: 'flex',
    '& > h1': {
      paddingLeft: '25px',
      alignSelf: 'center',
      transform: 'translateY(6px)',
      color: 'black',
    },
  },
  avatarContainer: {
    background: '#bdbdbd',
    position: 'relative',
    alignSelf: 'flex-end',
    width: '120px',
    height: '120px',
    clipPath: 'circle(50% at 50% 50%)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalDialog: {
    backgroundColor: '#f1f1f1',
    height: '450px',
    width: '100%',
  },
  avatar: {
    position: 'absolute',
    width: '120px',
    height: '120px',
    clipPath: 'circle(50% at 50% 50%)',
  },
  avatarAction: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    height: '40px',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.53)',
    '& > p': {
      color: 'white',
      transform: 'translateY(-3px)',
    },
  },
  photoIcon: {
    color: 'white',
    margin: '0px 3px',
    width: '20px',
    transform: 'translate(-3px,-5px)',
  },
  button: {
    width: '130px',
    height: '30px',
  },
});
const CompanyHeader = (props) => {
  const avatar = useSelector((state) => state.user.avatar);
  const classes = useStyles();

  const [avatarImage, setAvatar] = useState(null);
  const [modalOpen, setOpen] = useState(false);
  const [showAvatarEdit, handleAvatarEdit] = useState(false);

  useEffect(() => {
    if (avatarImage === null && modalOpen === false) {
      setAvatar(`https://pagoda-public.s3.eu-north-1.amazonaws.com/avatars/${avatar}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setOpen(!modalOpen);
  };

  const handleUpdateAvatar = (obj) => {
    //close modal and update avatar
    setAvatar(obj);
    setOpen(false);
  };

  return (
    <section className={classes.companyHeader}>
      <Dialog
        open={modalOpen}
        onClose={() => toggleModal()}
        classes={{
          paper: classes.modalDialog,
        }}
      >
        <DialogTitle>{props.translations.dialogtitle}</DialogTitle>
        <DialogContent>
          <ChangeAvatar
            companyLogo={avatarImage}
            toggleModal={toggleModal}
            updateAvatar={handleUpdateAvatar}
            buttons={props.translations.buttons}
          />
        </DialogContent>
      </Dialog>
      {/* <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}> */}
      <div
        onMouseEnter={() => handleAvatarEdit(true)}
        onMouseLeave={() => handleAvatarEdit(false)}
        onClick={() => toggleModal()}
        className={classes.avatarContainer}
      >
        <img className={classes.avatar} src={avatarImage} alt='' />
        {showAvatarEdit ? (
          <div className={classes.avatarAction}>
            <AddAPhotoIcon className={classes.photoIcon} />
            <p style={{ fontWeight: '400', fontSize: '0.95rem' }}>
              {props.translations.changeimage}
            </p>
          </div>
        ) : null}
      </div>
      <h1>{props.companyName}</h1>
    </section>
  );
};

export default CompanyHeader;
