import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRequestBooking, fetchCarriers } from '../../../store/actions';
import translations from '../../../services/translations/translations.json';
import { IMPORT } from '../../../services/types';
import { userDetailsMapper } from '../../../utils/address/mappers';
import { VerifyRequestBookingSteps } from '../../../services/validations';
//material ui
import {
  Dialog,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//components
import Flow from '../../../components/Flow/Flow';
import TransportMode from '../../../components/RequestBooking/TransportMode/TransportMode';
import SpecificCarriers from '../../../components/RequestBooking/SpecificCarriers/SpecificCarriers';
import TermsOfDelivery from '../../../components/RequestBooking/TermsOfDelivery/TermsOfDelivery';
import DateSelect from '../../../components/DateSelect/DateSelect';
import AddressDetails from '../../../components/AddressDetails/AddressDetails';
import RequestBookingReply from '../../../components/RequestBooking/RequestBookingReply/RequestBookingReply';
import PackagesDetails from '../../../components/PackagesDetails/PackagesDetails';
import FileZoneUpload from '../../../components/FileZoneUpload/FileZoneUpload';
import SnackbarContentWrapper from '../../../components/UI/SnackbarContentWrapper/SnackbarContentWrapper';
import StackableSelection from '../../../components/StackableSelection/StackableSelection';
import { getLocaleFromLang } from '../../../services/helpers/localize';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: '1000px',
    minHeight: '80vh',
    border: '1px solid #eee',
    boxShadow: '1px 1px 3px #eeeeeebf',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& > h2': {
      color: '#424242',
      height: 'fit-content',
      fontWeight: '300',
      fontSize: '2rem',
      padding: '10px 5px',
    },
    '& > p': {
      fontWeight: '300',
      fontSize: '1rem',
      paddingBottom: '5px',
    },
  },
  requestTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: '0 5px !important',
      width: '150px',
    },
  },
  typeButtonSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  flowAndDate: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '400px 300px',
    gap: '10px',
    padding: '20px 0',
  },
  flowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '210px',
  },
  transportModeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '40px',
  },
  addressFrom: {},
  addressTo: {},
  packageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  packageDetails: {
    marginTop: '15px',
    justifySelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '10px',
    maxWidth: '584px',
  },
  shipmentDetailsContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    columnGap: '30px',
    minHeight: '220px',
  },
  referenceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryTitle: {
    paddingBottom: '4px',
    color: theme.palette.secondary.main,
  },
  termsOfDelivery: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginTop: '30px',
    },
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileUploadContainer: {
    marginTop: '30px',
  },
  additionalInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridColumn: 'span 2',
  },
  actionsContainer: {
    marginTop: '20px',
    '& > button + button': {
      marginLeft: '10px',
    },
  },
  stepLabel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      fontSize: '1.5rem',
    },
    addressFrom: {},
    addressTo: {},
  },
  [theme.breakpoints.down('sm')]: {
    flowAndDate: {
      gridTemplateColumns: '300px 200px',
      rowGap: '10px',
      padding: '20px 0',
    },
  },
  [theme.breakpoints.down('xs')]: {
    content: {},
    shipmentDetailsContainer: {
      gridTemplateColumns: '1fr',
    },
    flowAndDate: {
      gridTemplateColumns: '1fr',
    },
    header: {
      fontSize: '1.2rem',
    },
    flowContainer: {},
    dateContainer: {},
  },
}));

const packagesInitialState = {
  templateName: 'none',
  count: 1,
  packaging: '02',
  length: '',
  width: '',
  height: '',
  weight: '',
};

const initialReferencesState = {
  reference1: '',
  reference2: '',
};

const RequestBooking = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [flow, setFlow] = useState(null);
  const [modes, setModes] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [files, setFiles] = useState([]);
  const [packages, setPackages] = useState([packagesInitialState]);
  const [stackable, setStackable] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [sender, setSender] = useState({});
  const [recipient, setRecipient] = useState({});
  const [references, setReferences] = useState(initialReferencesState);
  const [requestType, setRequestType] = useState(null);
  const [shouldUploadInvoice, setShouldUploadInvoice] = useState(false);
  const [packageErrors, setPackageErrors] = useState([]);
  const [snackbar, setSnackbar] = useState({ variant: '', messages: [], open: false });
  const [specificCarriers, setSpecificCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const userDetails = useSelector((state) => state.user);
  const { requestCarriers, allCarriers } = useSelector((state) => state.carriers);
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  const [activeStep, setActiveStep] = useState(null);
  const classes = useStyles({ requestType });

  useEffect(() => {
    if (requestCarriers.length < 1) {
      const { companyId } = userDetails;
      dispatch(fetchCarriers(token, companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestCarriers.length > 0 && allCarriers.length > 0 && specificCarriers.length === 0) {
      const specificCarriersOptions = requestCarriers.map((carrierId) => {
        return allCarriers.find(({ id }) => id === carrierId);
      });
      //Add a non-specific Pagoda Logistics option
      const pagodaCarrierPlaceholder = {
        carrierName: 'Pagoda Logistics',
        carrierAlias: 'pagoda',
      };
      specificCarriersOptions.unshift(pagodaCarrierPlaceholder);

      setSelectedCarrier(pagodaCarrierPlaceholder); //default option
      setSpecificCarriers(specificCarriersOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCarriers]);

  const clearState = () => {
    setSelectedDate(new Date());
    setRequestType(null);
    setFlow(null);
    setModes([]);
    setFiles([]);
    setPackages([packagesInitialState]);
    setSender({});
    setRecipient({});
    setStackable(false);
    setReferences(initialReferencesState);
    setPackageErrors([]);
    setAdditionalInformation('');
    setShouldUploadInvoice(false);
  };

  const handleUpdateFiles = (fileItems) => {
    const newFilesArray = fileItems.map((fileItem) => fileItem.file);
    setFiles(newFilesArray);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleReferenceChange = (nbr) => (e) => {
    setReferences({ ...references, [`reference${nbr}`]: e.target.value });
  };

  const handleSelectCarrier = ({ carrier }) => {
    setSelectedCarrier(carrier);
  };

  const handleMode = (newMode) => {
    if (selectedTerm) setSelectedTerm(''); //clean terms of delivery as it might not match the selected modes
    if (requestType === 'book') setModes([newMode]);
    if (requestType === 'rate') {
      let updatedModes = modes.slice();
      if (modes.includes(newMode)) {
        updatedModes.splice(modes.indexOf(newMode), 1);
      } else {
        updatedModes.push(newMode);
      }
      setModes(updatedModes);
    }
  };
  const handleTermsChange = (term) => {
    setSelectedTerm(term.value);
  };

  const handleUpdatePackages = (rowNum, inputs) => {
    let newPackages = [...packages];
    newPackages[rowNum] = inputs;
    setPackages(newPackages);
  };
  const handleUpdatePackagesErrors = (rowNum, rowErrors) => {
    let newErrors = [...packageErrors];
    newErrors[rowNum] = rowErrors;
    setPackageErrors(newErrors);
  };

  const handleRemovePackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.pop();
    setPackages(updatedPackages);
  };

  const handleAddPackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.push(packagesInitialState);
    setPackages(updatedPackages);
  };

  const handleChangeFlow = (newFlow) => {
    if (newFlow === IMPORT) {
      if (sender) setSender({});
      setRecipient(() => userDetailsMapper(userDetails));
    }
    if (newFlow !== IMPORT) {
      if (recipient) setRecipient({});
      setSender(() => userDetailsMapper(userDetails));
    }

    setFlow(newFlow);
  };

  const handleTextBlur = (updatedAddressData) => (value) => {
    value === 'sender' ? setSender(updatedAddressData) : setRecipient(updatedAddressData);
  };

  const handleRequestTypeChange = ({ currentTarget }) => {
    if (!activeStep) setActiveStep(0);
    setRequestType(currentTarget.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = ({ redo, edit }) => {
    setModalOpen(false);

    if (redo) {
      clearState();
      setActiveStep(null);
    }
    if (edit) {
      setActiveStep(0);
    }
  };

  const handleSubmit = () => {
    const shippingArray = JSON.stringify({
      time: selectedDate,
      terms: selectedTerm || '',
      flow,
      modes,
      packages,
      sender,
      recipient,
      references,
      files,
      requestType,
      stackable,
      additionalInformation,
    });
    let formData = new FormData();

    //attach files
    for (let i = 0; i < files.length; i++) {
      formData.append('invoices', files[i]);
    }

    formData.append('shipdata', shippingArray);
    formData.append('userdata', JSON.stringify(userDetails));
    dispatch(addRequestBooking(formData, token));
    handleOpenModal(true);
  };

  function getSteps() {
    return [
      translations[lang].requestbooking.directionAndDate,
      translations[lang].requestbooking.transportMode,
      translations[lang].requestbooking.addresses,
      translations[lang].requestbooking.packages,
      translations[lang].requestbooking.shipmentDetails,
    ];
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className={classes.flowAndDate}>
            <div className={classes.flowContainer}>
              <Flow
                translations={translations[lang].flow}
                currentFlow={flow}
                changeFlow={handleChangeFlow}
                size="small"
              />
            </div>
            <div className={classes.dateContainer}>
              <DateSelect
                label={translations[lang].onlinebooking.shippingdate}
                handleDateChange={handleDateChange}
                selectedDate={selectedDate}
                locale={getLocaleFromLang(lang)}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className={classes.transportModeContainer}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className={classes.secondaryTitle}>
                {translations[lang].requestbooking.transportmode}
              </p>
              <TransportMode
                translations={translations[lang]}
                handleTermsChange={handleTermsChange}
                selectedTerm={selectedTerm ? selectedTerm : null}
                selectedModes={modes}
                handleMode={handleMode}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {specificCarriers.length > 0 && (
                <>
                  <p className={classes.secondaryTitle}>
                    {translations[lang].requestbooking.chooseSpecificCarrier}
                  </p>

                  <SpecificCarriers
                    handleSelectCarrier={handleSelectCarrier}
                    selectedCarrier={selectedCarrier}
                    carriers={specificCarriers}
                  />
                </>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div className={classes.stepContentRow}>
            <div className={classes.addressFrom}>
              <p style={{ textAlign: 'center', fontWeight: 500, textDecoration: 'underline' }}>
                {translations[lang].requestbooking.shipfrom}
              </p>
              <AddressDetails
                translations={translations[lang]}
                handleTextBlur={handleTextBlur}
                value="sender"
                key={flow}
                userDetails={sender}
              />
            </div>
            <div className={classes.addressTo}>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  textDecoration: 'underline',
                  marginTop: '25px',
                }}
              >
                {translations[lang].requestbooking.shipto}
              </p>
              <AddressDetails
                translations={translations[lang]}
                handleTextBlur={handleTextBlur}
                value="recipient"
                key={flow}
                userDetails={recipient}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={classes.packageDetailsContainer}>
            <div className={classes.packageDetails}>
              <PackagesDetails
                updatePackagesErrors={handleUpdatePackagesErrors}
                updatePackages={handleUpdatePackages}
                packageRows={packages.length}
                removePackageRow={handleRemovePackageRow}
                addPackageRow={handleAddPackageRow}
                initialPackages={packages}
                type="requestBooking"
              />
            </div>
            <StackableSelection stackable={stackable} setStackable={(v) => setStackable(v)} />
          </div>
        );
      case 4:
        return (
          <div className={classes.shipmentDetailsContainer}>
            <div className={classes.referenceContainer}>
              <p className={classes.secondaryTitle}>
                {translations[lang].requestbooking.references}
              </p>
              <TextField
                inputProps={{ maxLength: 35 }}
                id="reference-name1"
                label={`${translations[lang].onlinebooking.shippingref} 1`}
                className={classes.referenceTextBox}
                value={references['reference1']}
                onChange={handleReferenceChange('1')}
                margin="none"
              />
              <TextField
                inputProps={{ maxLength: 35 }}
                id="reference-name2"
                label={`${translations[lang].onlinebooking.shippingref} 2`}
                className={classes.referenceTextBox}
                value={references['reference2']}
                onChange={handleReferenceChange('2')}
                margin="none"
              />
            </div>
            <div className={classes.termsOfDelivery}>
              <p className={classes.secondaryTitle}>
                {translations[lang].requestbooking.termsofdelivery}
              </p>
              <TermsOfDelivery
                translations={translations[lang]}
                handleTermsChange={handleTermsChange}
                selectedTerm={selectedTerm ? selectedTerm : null}
                selectedModes={modes}
              />
            </div>
            {/*  title={translations[lang].requestbooking.upload}>*/}
            <div className={classes.documents}>
              <p className={classes.secondaryTitle}>
                {translations[lang].requestbooking.uploaddocs}
              </p>
              {shouldUploadInvoice && (
                <p style={{ color: 'black', fontStyle: 'italic' }}>
                  &nbsp;{`*${translations[lang].requestbooking.invoiceRequiredForShipment}`}
                </p>
              )}
              <div className={classes.fileUploadContainer}>
                <FileZoneUpload
                  title={translations[lang].dragdropbrowse}
                  updateFiles={handleUpdateFiles}
                  files={files}
                />
              </div>
            </div>
            <div className={classes.additionalInformationContainer}>
              <TextField
                id="additional-information"
                label={`${translations[lang].requestbooking.additionalShipmentInformation}?`}
                inputProps={{ maxLength: 500 }}
                onChange={({ target }) => setAdditionalInformation(target.value)}
                value={additionalInformation}
                multiline
                rows={2}
                variant="outlined"
              />
            </div>
          </div>
        );

      default:
        return 'Unknown step';
    }
  }

  const handleNext = async () => {
    const validationData = Object.assign(
      {},
      { selectedDate, flow, modes, sender, recipient, references, packages, stackable }
    );
    const validation = await VerifyRequestBookingSteps({ activeStep, validationData, lang });

    if (!validation.valid) {
      const snackbarErrors = validation.errors;

      setSnackbar({
        ...snackbar,
        open: true,
        messages: snackbarErrors || '',
        variant: validation.variant,
      });
    } else {
      activeStep === steps.length - 1
        ? handleSubmit()
        : setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = getSteps();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <h2>{translations[lang].categories.requestbooking}</h2>
          <p>{translations[lang].requestbooking.requestbookingintro}</p>
          <div className={classes.requestTypeContainer}>
            <Button
              variant="outlined"
              value="book"
              className={requestType === 'book' ? classes.typeButtonSelected : null}
              onClick={handleRequestTypeChange}
            >
              {translations[lang].requestbooking.requestBooking}
            </Button>
            <Button
              variant="outlined"
              value="rate"
              className={requestType === 'rate' ? classes.typeButtonSelected : null}
              onClick={handleRequestTypeChange}
            >
              {translations[lang].requestbooking.requestRates}
            </Button>
          </div>
        </div>
        <Dialog
          open={modalOpen}
          onClose={() => handleCloseModal({ redo: true, edit: false })}
          aria-labelledby="request-booking-dialog-title"
          aria-describedby="request-booking-dialog-description"
          fullWidth={true}
          maxWidth={'sm'}
        >
          <RequestBookingReply
            translations={translations[lang].requestbooking}
            redo={() => handleCloseModal({ redo: true, edit: false })}
            edit={() => handleCloseModal({ redo: false, edit: true })}
          />
        </Dialog>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className={classes.stepLabel} /* onClick={() => console.log(index)} */>
                {label}
              </StepLabel>
              <StepContent>
                <div>{getStepContent(index)}</div>
                <div className={classes.actionsContainer}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {translations[lang].onlinebooking.buttons.back}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? requestType === 'book'
                        ? translations[lang].requestbooking.sendBooking
                        : translations[lang].requestbooking.sendRequest
                      : translations[lang].requestbooking.next}
                  </Button>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default RequestBooking;
