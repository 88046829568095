import { NOTIFICATION_RECIPIENT_EMAIL } from '../types';

export const buildNotificationForOnlineBooking = (preset, email) => {
  let notifications = [];
  for (let [type, bool] of Object.entries(preset)) {
    if (bool) notifications.push(type);
  }

  return { notifications, notificationType: 'email', recipients: [email] };
};

export const hasEmailNotificationsAdded = (notifications) => {
  if (notifications.length === 0) return false;

  return notifications.some((n) => n.notificationType === NOTIFICATION_RECIPIENT_EMAIL);
};
