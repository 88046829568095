import { CARRIER_NAMES } from '../types';

/**
 * Retrieve carrier aliases from applicable carriers, which can be
 * any type of of: onlineCarriers, requestCarriers,rateCarriers.
 *
 * allCarrier contains aliases
 */
export const getCarrierAliasesFromAllCarriers = (applicableCarriers, allCarriers) =>
  applicableCarriers.map((carrier) => {
    const applyCarrier = allCarriers.find((c) => c.id === carrier);
    return applyCarrier['carrierAlias'];
  });

export const buildCarrierSelectOptions = (carrierAliases) =>
  carrierAliases.map((carrier) => {
    return {
      label: CARRIER_NAMES[carrier],
      value: carrier,
    };
  });
