import React from 'react';
import RequestCollection from '../../components/ManageCollection/RequestCollection/RequestCollection';
import PendingCollections from '../../components/ManageCollection/PendingCollections/PendingCollections';
import translations from '../../services/translations/translations.json';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    maxWidth: '880px',
    gap: '10px',
    '& > section': {
      display: 'flex',
    },
  },
  header: {
    fontWeight: 300,
    color: '#424242',
    fontSize: '2rem',
    textAlign: 'center',
    width: '100%',
    padding: '0px 0 10px 0',
  },
});

const ManageCollection = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  return (
    <div className={classes.root}>
      <h1 className={classes.header}>{translations[lang].managecollection.header}</h1>
      <div className={classes.container}>
        <PendingCollections />
        <RequestCollection />
      </div>
    </div>
  );
};

export default ManageCollection;
