import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import marker from '../../../assets/green_marker.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    transform: 'translate(-47%,-83%)',
    width: 'fit-content',
  },
  marker: {
    width: '20px',
  },
  number: {
    transform: 'translate(-79%,-85%)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    fontWeight: 500,
    paddingLeft: '10px',
    fontSize: '0.9rem',
  },
}));

export default function NumberMapMarker({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={marker} alt="marker" className={classes.marker} />
      <p className={classes.number}>{text}</p>
    </div>
  );
}
