import axios from 'axios';
import { AVATAR_UPLOAD_START, AVATAR_UPLOAD_SUCCESS, AVATAR_UPLOAD_FAILED, USER_AVATAR_UPLOAD_SUCCESS } from './actionTypes';

export const avatarUploadStart = () => {
	return {
		type: AVATAR_UPLOAD_START
	}
}

export const avatarUploadSuccess = () => {
	return {
		type: AVATAR_UPLOAD_SUCCESS
	}
}
export const avatarUploadFailed = (err) => {
	return {
		error: err,
		type: AVATAR_UPLOAD_FAILED
	}
}

export const updateAuthAvatar = (avatar) => {
	return {
		type: USER_AVATAR_UPLOAD_SUCCESS,
		avatar
	}
}

export const avatarUpload = (formData, userId) => {
	return dispatch => {
		dispatch(avatarUploadStart());
		axios.post(`/uploads/avatar/${userId}`, formData)
			.then(resp => {
				dispatch(updateAuthAvatar(resp.data.avatar))
				dispatch(avatarUploadSuccess())
			})
			.catch(err => dispatch(avatarUploadFailed(err)))
	}
}