import {
  FETCH_CARRIERS_START,
  FETCH_CARRIERS_SUCCESS,
  FETCH_CARRIERS_FAILED,
} from '../actions/actionTypes';

const initialState = {
  allCarriers: [],
  onlineCarriers: [],
  requestCarriers: [],
  rateCarriers: [],
  loading: false,
  error: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CARRIERS_SUCCESS:
      const { allowedCarriers } = action;
      let onlineCarriers = [];
      let requestCarriers = [];
      let rateCarriers = [];

      Object.keys(action.allowedCarriers).forEach((key) => {
        if (key === 'allowedRequestCarriers') requestCarriers = allowedCarriers[key];
        if (key === 'allowedOnlineCarriers') onlineCarriers = allowedCarriers[key];
        if (key === 'allowedRateCarriers') rateCarriers = allowedCarriers[key];
      });

      return {
        ...state,
        allCarriers: action.carriers,
        requestCarriers,
        onlineCarriers,
        rateCarriers,
        loading: false,
      };
    case FETCH_CARRIERS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CARRIERS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
