import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import axios from 'axios';
import SelectList from '../../SelectionLists/IntegrationReactSelect/IntegrationReactSelect';
import { stateRegionCountries, stateRegionPaths } from '../../../services/helpers/common';
//material ui
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '../../UI/CustomToolbar/CustomToolbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableChartIcon from '@material-ui/icons/TableChart';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import { makeStyles } from '@material-ui/core';
import FavouriteTextField from '../../FavouriteTextField/FavouriteTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: '800px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  countryContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: '15px',
    gridAutoFlow: 'row',
    width: '100%',
    placeItems: 'center',
  },
  addressContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
    'personal-field address-field'
    'personal-field address-field'
    'personal-field address-field'
    'favourite-field address-field'
    `,
    gridColumnGap: '15px',
    width: '100%',
    placeItems: 'center',
  },
  countrySelectList: {
    width: '100%',
    gridColumn: ({ hasStateOrRegion }) => (!hasStateOrRegion ? '1 / span 4' : '1 / span 3'),
    height: 60,
  },
  stateRegionSelectList: {
    width: '100%',
    gridColumn: '4 / span 1',
    height: 60,
  },
  personalFields: {
    gridArea: 'personal-field',
  },
  streetFields: {
    gridArea: 'address-field',
  },
  formTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  textField: {
    width: '100%',
    marginBottom: '1px',
  },
  input: {
    fontSize: '0.9rem',
  },
  label: {
    fontSize: '0.9rem',
  },
  actionContainer: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gap: '20px',
    padding: '20px 0',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  favouriteContainer: {
    gridArea: 'favourite-field',
    width: '100%',
    paddingTop: '10px',
  },
  [theme.breakpoints.down('xs')]: {
    countryContainer: {
      gridTemplateColumns: '1fr',
    },
    countrySelectList: {
      gridColumn: 'span 1 !important',
    },
    stateRegionSelectList: {
      gridColumn: '1 / span 1 !important',
    },
    addressContainer: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
      'personal-field'
      'personal-field'
      'personal-field'
      'personal-field'
      'address-field'
      'address-field'
      'address-field'
      'address-field'
      'address-field'
      'favourite-field'
      `,
    },
    favouriteContainer: {
      paddingTop: '20px',
    },
    actionContainer: {
      gap: '7px',
      gridTemplateColumns: '1fr',
      padding: '20px 0 10px',
    },
  },
}));

const initialCompanyData = {
  templateName: '',
  company: '',
  email: '',
  phone: '',
  city: '',
  zip: '',
  address1: '',
  address2: '',
  address3: '',
  countryName: '',
  countryCode: '',
  stateRegion: '',
};

const EditRecipientCompany = (props) => {
  const [stateRegionList, setStateRegionList] = useState([]);
  const [hasStateOrRegion, setHasStateOrRegion] = useState(false);
  const [companyData, setCompanyData] = useState(initialCompanyData);

  const { countriesData: countries } = useSelector((state) => state.countries);
  const { token } = useSelector((state) => state.auth);
  const { defaultLanguage: lang } = useSelector((state) => state.rootTemplates);
  const recipientTemplates = useSelector((state) => state.recipientTemplates);

  const dispatch = useDispatch();

  useEffect(() => {
    if (countries === null) {
      dispatch(actions.fetchCountries(token));
    }
    if (props.companyData) {
      setCompanyData({
        templateName: props.companyData.templateName || '',
        company: props.companyData.company || '',
        email: props.companyData.email || '',
        phone: props.companyData.phone || '',
        city: props.companyData.city || '',
        zip: props.companyData.zip || '',
        address1: props.companyData.address1 || '',
        address2: props.companyData.address2 || '',
        address3: props.companyData.address3 || '',
        countryName: props.companyData.countryName || '',
        countryCode: props.companyData.countryCode || '',
        stateRegion: props.companyData.stateRegion || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRegionCountries.includes(props.companyData.countryCode)) {
      fetchStatesRegions(props.companyData.countryCode);
      setHasStateOrRegion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyData]);

  const handleCountryChange = (e) => {
    if (stateRegionCountries.includes(e.value.code)) {
      fetchStatesRegions(e.value.code);
      setHasStateOrRegion(true);
    } else {
      hasStateOrRegion && setHasStateOrRegion(false);
      stateRegionList.length > 0 && setStateRegionList([]);
    }

    setCompanyData({
      ...companyData,
      countryName: e.value.name,
      countryCode: e.value.code,
    });
  };

  const fetchStatesRegions = (countryCode) => {
    const path = stateRegionPaths[countryCode];

    axios
      .get(`/api/countries/states-or-regions/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        setStateRegionList(resp.data.statesOrRegions);
      })
      .catch((e) => {
        setStateRegionList([]);
      });
  };

  const handleStateRegionChange = (e) => {
    setCompanyData({
      ...companyData,
      stateRegion: e.value.code,
    });
  };

  const handleTextChange = (value) => (e) => {
    setCompanyData({
      ...companyData,
      [value]: e.target.value,
    });
  };

  const handleTemplateNameChange = ({ target: { value } }) => {
    setCompanyData({
      ...companyData,
      templateName: value,
    });
  };

  const handleEditRecipientCompanySubmit = () => {
    const submitData = { ...companyData, contacts: props.companyData.contacts };

    dispatch(
      actions.updateRecipientCompanyTemplateFromTemplates(submitData, props.companyData.id, token)
    );
  };

  const classes = useStyles({ hasStateOrRegion });
  const loading = recipientTemplates.loading;

  return (
    <Paper className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar title={`${props.translations.templates.change} - ${props.companyData.company}`}>
          <Tooltip title={props.translations.templates.recipientlist}>
            <IconButton onClick={props.handleChangeToTable} aria-label="Recipient table">
              <TableChartIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {loading ? (
        <CircleSpinnerLarge />
      ) : (
        <div className={classes.container}>
          <div>
            <p className={classes.formTitle}>{props.translations.templates.changecompanydetails}</p>
          </div>
          <div className={classes.countryContainer}>
            {countries ? (
              <div className={classes.countrySelectList}>
                <SelectList
                  lang={lang}
                  translations={props.translations.selectList}
                  change={handleCountryChange}
                  value={companyData.countryName}
                  countries={countries}
                  height="68"
                  id={'recipient-country'}
                />
              </div>
            ) : null}

            {stateRegionList.length > 0 ? (
              <div className={classes.stateRegionSelectList}>
                <SelectList
                  lang={lang}
                  translations={props.translations.selectList}
                  change={handleStateRegionChange}
                  value={companyData.stateRegion}
                  statesOrRegions={stateRegionList}
                  height="68"
                  id={'recipient-state'}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.addressContainer}>
            <div className={classes.personalFields}>
              <TextField
                id="edit-recipient-template-company"
                label={props.translations.company}
                className={classes.textField}
                margin="normal"
                value={companyData.company}
                onChange={handleTextChange('company')}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                required
              />
              <TextField
                id="edit-recipient-template-email"
                label={props.translations.email}
                className={classes.textField}
                value={companyData.email}
                onChange={handleTextChange('email')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />
              <TextField
                id="edit-recipient-template-phone"
                label={props.translations.phone}
                className={classes.textField}
                value={companyData.phone}
                onChange={handleTextChange('phone')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                required
              />
            </div>
            <div className={classes.favouriteContainer}>
              {/* <div style={{ paddingTop: '10px' }}> */}
              <FavouriteTextField
                label={'Favourite name'}
                value={companyData.templateName}
                onChange={handleTemplateNameChange}
              />
              {/* </div> */}
            </div>
            <div className={classes.streetFields}>
              <TextField
                id="edit-recipient-template-city"
                label={props.translations.city}
                className={classes.textField}
                value={companyData.city}
                onChange={handleTextChange('city')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                required
              />
              <TextField
                id="edit-recipient-template-zip"
                label={props.translations.zip}
                className={classes.textField}
                value={companyData.zip}
                onChange={handleTextChange('zip')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                required
              />
              <TextField
                id="edit-recipient-template-address1"
                label={`${props.translations.addressrow} 1`}
                className={classes.textField}
                value={companyData.address1}
                onChange={handleTextChange('address1')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                required
              />
              <TextField
                id="edit-recipient-template-address2"
                label={`${props.translations.addressrow} 2`}
                className={classes.textField}
                value={companyData.address2}
                onChange={handleTextChange('address2')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />
              <TextField
                id="edit-recipient-template-address3"
                label={`${props.translations.addressrow} 3`}
                className={classes.textField}
                value={companyData.address3}
                onChange={handleTextChange('address3')}
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />
            </div>
          </div>
          <div className={classes.actionContainer}>
            <Button color="primary" variant="contained" onClick={handleEditRecipientCompanySubmit}>
              {props.translations.templates.save}
            </Button>
            <Button color="secondary" variant="outlined" onClick={props.handleChangeToTable}>
              {props.translations.templates.cancel}
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default EditRecipientCompany;
