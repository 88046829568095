import 'date-fns';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

export default function TimeSelect(props) {
  const label = props.label || 'Time picker';

  const handleTimeChange = (date) => {
    props.handleTimeChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        style={{ width: '100px', margin: '0 10px' }}
        variant='inline'
        margin='none'
        id={`time-picker-${label}`}
        ampm={false}
        label={label}
        mode='24h'
        minutesStep={15}
        value={props.selected}
        onChange={handleTimeChange}
        autoOk={true}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
