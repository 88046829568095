import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PackageMove = (props) => {

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Lager 1"
        viewBox="0 0 19.56 19.71"
      >
        <path
          d="M13.4 16.87a8.1 8.1 0 01-.79.94c-.22 0-.43.05-.65.06v-3.2h1.21l-.07-.75H12V10.6h.81l-.1-.92H12V6.13h2.92a12.38 12.38 0 01.5 3.55h-1.29l2.15 1.56v-.64h3a8 8 0 01-.56 2.4l1.63 1.17a9.81 9.81 0 10-6.7 5.43zm5.91-7.19h-3a14.07 14.07 0 00-.45-3.55h2.35a7.79 7.79 0 011.1 3.55zm-1.55-4.29h-2.1a9 9 0 00-1.44-2.65 7.86 7.86 0 013.54 2.65zM12 2.27h.47a7.51 7.51 0 012.19 3.08H12zm-3.41.56a8.78 8.78 0 00-1.43 2.56H5.24a7.82 7.82 0 013.31-2.56zm-3.8 3.3h2.13a14 14 0 00-.47 3.55H3.69a7.79 7.79 0 011.06-3.55zm-1 4.47h2.68A15.47 15.47 0 007 13.92H4.7a7.8 7.8 0 01-1-3.32zm1.48 4.07h2a10.75 10.75 0 001.29 2.61 7.89 7.89 0 01-3.38-2.61zm5.73 3.2a8.51 8.51 0 01-.9-.11 8.61 8.61 0 01-1.9-3.09H11zm0-4H8a14.28 14.28 0 01-.52-3.32H11zm0-4.24H7.42a12.38 12.38 0 01.5-3.55H11zm0-4.29H8.19a7.24 7.24 0 012.19-3l.62-.07z"
          transform="translate(-1.72 -.29)"
        ></path>
        <path
          fillRule="evenodd"
          d="M11.9 10.02L19.46 15.7 16.75 16.45 17.89 18.53 15.95 19.71 14.81 17.62 12.83 19.71 11.9 10.02z"
        ></path>
      </svg>
    </SvgIcon>
  );
}

PackageMove = pure(PackageMove);
PackageMove.displayName = 'PackageMove';
PackageMove.muiName = 'SvgIcon';

export default PackageMove
