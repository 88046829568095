import * as actionTypes from '../actions/actionTypes';

const initialState = {
	countriesData: null,
	loading: false,
	error: null
}

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.FETCH_COUNTRIES_START:
			return {
				...state,
				loading: true
			}
		case actionTypes.FETCH_COUNTRIES_SUCCESS:
			return {
				...state,
				countriesData: action.countriesData,
				loading: false,
				error: null
			}
		case actionTypes.FETCH_COUNTRIES_FAILED:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}

export default reducer;