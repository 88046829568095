import * as actionTypes from '../actions/actionTypes';

const initialState = {
	packageRows: 1,
	packages: [],
	redirect: false
}

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.ADD_PACKAGE_ROW:
			return {
				...state,
				packageRows: state.packageRows + 1,
			}
		case actionTypes.REMOVE_PACKAGE_ROW:
			const updatedPackages = [...state.packages];
			updatedPackages.pop();
			return {
				...state,
				packageRows: state.packageRows - 1,
				packages: updatedPackages
			}
		case actionTypes.UPDATE_PACKAGE_DETAILS:
			const index = action.rowNumber;
			let newPackages = [...state.packages]
			newPackages[index] = action.updatedValues;
			return {
				...state,
				packages: newPackages
			}
		case actionTypes.PACKAGES_DETAILS_CLEANUP:
			return {
				...state,
				packageRows: 1,
				packages: [],
			}
		case actionTypes.PACKAGES_DETAILS_REDIRECT:
			return {
				...state,
				redirect: true
			}
		default:
			return state;
	}
}

export default reducer;
