import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  addressRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    fontSize: '0.9rem',
    gap: '5px 10px',
    fontStyle: 'italic',
  },
  label: {
    fontWeight: 500,
  },
  detail: {
    fontWeight: 400,
  },
});

const AddressOutputs = (props) => {
  const classes = useStyles();

  const addressDetails = [
    { label: props.translations.company, key: 'company' },
    { label: props.translations.city, key: 'city' },
    { label: props.translations.contact, key: 'contact' },
    { label: props.translations.zip, key: 'zip' },
    { label: props.translations.phone, key: 'phone' },
    { label: `${props.translations.addressrow} 1`, key: 'address1' },
    { label: props.translations.email, key: 'email' },
    { label: `${props.translations.addressrow} 2`, key: 'address2' },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.addressRow}>
        {addressDetails.map((addressObject) => (
          <div key={addressObject.key}>
            <p className={classes.label}>{addressObject.label}:&nbsp;</p>
            <p className={classes.detail}>{props.addressData[addressObject.key]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddressOutputs;
