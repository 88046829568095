import * as yup from 'yup';
import errors from '../../../translations/errors';

export const productSchema = (lang = 'en') => {
  return yup.object().shape({
    description: yup
      .string()
      .min(1, errors.description.min[lang])
      .max(35, errors.description.max[lang]),
    unitNumber: yup
      .string()
      .min(1, errors.unitNumber.min[lang])
      .max(7, errors.unitNumber.max[lang]),
    unitMeasureCode: yup
      .string()
      .min(1, errors.unitMeasureCode.min[lang])
      .max(3, errors.unitMeasureCode.max[lang]),
    unitValue: yup //TODO: add validation for uomValue - (string: max 19) must be > 0, max 6 digits after decimal
      .string()
      .min(1, errors.unitValue.min[lang])
      .max(19, errors.unitValue.max[lang]),
    commodityCode: yup
      .string()
      .min(6, errors.commodityCode.min[lang])
      .max(15, errors.commodityCode.max[lang]),
    originCountryCode: yup.string().length(2, errors.originCountryCode.length[lang]),
    templateId: yup.string().when('saveProduct', {
      is: saveProduct => saveProduct,
      then: yup.string().min(2, errors.templateId.min[lang]).max(50, errors.templateId.min[lang]),
      otherwise: yup.string().min(0),
    }),
  });
};
export const productSchemaSimple = (lang = 'en') => {
  return yup.object().shape({
    description: yup
      .string()
      .min(1, errors.description.min[lang])
      .max(35, errors.description.max[lang]),
    templateId: yup
      .string()
      .min(2, errors.templateId.min[lang])
      .max(50, errors.templateId.min[lang]),
  });
};
