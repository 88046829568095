import * as actionTypes from '../actions/actionTypes';
import { NOTIFICATION_RECIPIENT_TEXT_MESSAGE } from '../../services/types';

const initialState = {
  loading: false,
  loadingAdditionalServices: false,
  updated: null,
  error: null,
  sender: null,
  recipient: null,
  residentialAddress: null,
  description: '',
  reference: '',
  reference2: '',
  date: null,
  customsValue: '',
  customsCurrency: null,
  documentsOnly: false,
  packages: [],
  packageCount: null,
  serviceCode: null,
  serviceName: null,
  services: [],
  carrier: null,
  rate: null,
  currencyCode: null,
  billableWeight: null,
  totalRate: null,
  fuel: null,
  fuelSurcharge: null,
  surcharges: [],
  printFormat: null,
  deliveryMethod: null,
  deliveryRemark: '',
  alternateDeliveryAddress: null,
  dropOffLocations: null,
  alternateCollectionAddress: null,
  requestPickup: false,
  pickupDate: null,
  pickupTimeStart: null,
  pickupTimeEnd: null,
  pickupReference: '',
  invoiceType: 'file',
  invoiceForm: {},
  voec: null,
  notifications: [],
  documents: {}, //contains files as object with properties corresponding to document type
  billingDuties: 'R', //'R' = 'Recipient', 'S' = Shipper
  movementDirection: null, //enum: export,import,domestic,third-party
  additionalServices: [],
  additionalServiceOptions: [],
  upsDropOffLocations: [],
  dhlDropOffLocations: [],
  requestedDeliveryDate: null,
  fixedDeliveryDate: null,
  fixedDeliveryDates: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_BOOKING_SENDER:
      return {
        ...state,
        sender: action.sender,
      };
    case actionTypes.ADD_BOOKING_RECIPIENT:
      return {
        ...state,
        recipient: action.recipient,
      };
    case actionTypes.ADD_BOOKING_RESIDENTIAL_ADDRESS:
      return {
        ...state,
        residentialAddress: action.residentialAddress,
      };
    case actionTypes.ADD_BOOKING_PACKAGES:
      return {
        ...state,
        packages: action.packages,
      };
    case actionTypes.ADD_BOOKING_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case actionTypes.ADD_BOOKING_DOCUMENTS_ONLY:
      return {
        ...state,
        documentsOnly: action.documentsOnly,
      };
    case actionTypes.ADD_BOOKING_REFERENCE_ONE:
      return {
        ...state,
        reference: action.reference,
      };
    case actionTypes.ADD_BOOKING_REFERENCE_TWO:
      return {
        ...state,
        reference2: action.reference,
      };
    case actionTypes.ADD_BOOKING_CUSTOMS_VALUE:
      return {
        ...state,
        customsValue: action.customsValue,
      };
    case actionTypes.ADD_BOOKING_CUSTOMS_CURRENCY:
      return {
        ...state,
        customsCurrency: action.currency,
      };
    case actionTypes.ADD_BOOKING_DATE:
      return {
        ...state,
        date: action.date,
      };
    case actionTypes.UPDATE_BILLING_OPTION:
      return {
        ...state,
        billingDuties: action.billingDuties,
      };
    case actionTypes.ADD_BOOKING_SERVICE_SELECTED:
      return {
        ...state,
        ...action.selectedServiceData,
        additionalServices: [], //Always clear additional services for specific service
        additionalServiceOptions: [],
      };
    case actionTypes.ADD_BOOKING_ALTERNATE_DELIVERY:
      return {
        ...state,
        alternateDeliveryAddress: action.payload,
      };
    case actionTypes.ADD_BOOKING_UPS_DROP_OFF_LOCATIONS:
      return {
        ...state,
        upsDropOffLocations: action.dropOffLocations,
      };
    case actionTypes.ADD_BOOKING_DHL_DROP_OFF_LOCATIONS:
      return {
        ...state,
        dhlDropOffLocations: action.dropOffLocations,
      };
    case actionTypes.ADD_BOOKING_DROP_OFF_LOCATIONS:
      return {
        ...state,
        dropOffLocations: action.dropOffLocations,
      };
    case actionTypes.ADD_BOOKING_SERVICES:
      //Do not update state with services if booking has been reset on component unmount
      if (JSON.stringify(state) === JSON.stringify(initialState)) return state;

      return {
        ...state,
        services: action.services,
        // deliveryMethod: action.deliveryMethod,
      };
    case actionTypes.ADD_BOOKING_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.deliveryMethod,
      };
    case actionTypes.UPDATE_BOOKING_SERVICE_COST:
      return {
        ...state,
        services: action.services,
      };
    case actionTypes.UPDATE_BOOKING_SERVICE_CODE:
      const updatedServices = [...state.services];
      const updatedServiceCodeIdx = updatedServices.findIndex(
        ({ serviceCode }) => serviceCode === action.prevServiceCode
      );
      updatedServices[updatedServiceCodeIdx].serviceCode = action.newServiceCode;

      return {
        ...state,
        services: updatedServices,
        serviceCode: action.newServiceCode,
      };
    case actionTypes.ADD_BOOKING_DELIVERY_REMARK:
      return {
        ...state,
        deliveryRemark: action.payload,
      };
    case actionTypes.BOOKING_VALIDATION_UPDATE:
      return {
        ...state,
        updated: action.payload,
      };
    case actionTypes.BOOKING_PRINT_FORMAT:
      return {
        ...state,
        printFormat: action.payload,
      };
    case actionTypes.ADD_BOOKING_PICKUP_DETAILS:
      return {
        ...state,
        pickupDate: action.pickupDate,
        pickupTimeStart: action.pickupTimeStart,
        pickupTimeEnd: action.pickupTimeEnd,
        pickupReference: action.pickupRef,
        alternateCollectionAddress: action.alternateCollectionAddress,
      };
    case actionTypes.ADD_BOOKING_PICKUP_DETAIL_TIME:
      return {
        ...state,
        [action.field]: action.time,
      };
    case actionTypes.ADD_BOOKING_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case actionTypes.ADD_BOOKING_INVOICE_TYPE:
      return {
        ...state,
        invoiceType: action.invoiceType,
      };
    case actionTypes.UPDATE_INVOICE_FORM:
      return {
        ...state,
        invoiceForm: action.invoiceForm,
      };
    case actionTypes.UPDATE_PACKAGE_COUNT:
      //calculate amount of packages in shipment
      const packageCount = state.packages.reduce((acc, curr) => {
        acc = acc + curr.count;
        return acc;
      }, 0);

      return {
        ...state,
        packageCount,
      };
    case actionTypes.ADD_BOOKING_REQUEST_PICKUP:
      return {
        ...state,
        requestPickup: action.option,
      };
    case actionTypes.ADD_NOTIFICATION:
      const updatedAddNotifications = state.notifications.slice();
      updatedAddNotifications.push(action.notification);
      return {
        ...state,
        notifications: updatedAddNotifications,
      };
    case actionTypes.ADD_MULTIPLE_NOTIFICATIONS:
      const updatedAddMultipleNotifications = [...state.notifications, ...action.payload];
      return {
        ...state,
        notifications: updatedAddMultipleNotifications,
      };
    case actionTypes.REMOVE_NOTIFICATION:
      const updatedRemoveNotification = [...state.notifications]
        .map((notification) => {
          if (notification.recipients.includes(action.recipient)) {
            const recipientIdx = notification.recipients.findIndex(
              (recipient) => recipient === action.recipient
            );
            if (recipientIdx === undefined) return notification;
            notification.recipients.splice(recipientIdx, 1);
            return notification;
          }
          return notification;
        })
        .filter((notification) => {
          return notification.recipients && notification.recipients.length > 0;
        });

      return {
        ...state,
        notifications: updatedRemoveNotification,
      };

    case actionTypes.SET_MOVEMENT_DIRECTION:
      return {
        ...state,
        movementDirection: action.movementDirection,
      };

    case actionTypes.FETCH_ADDITIONAL_SERVICES_START:
      return {
        ...state,
        loadingAdditionalServices: true,
        additionalServiceOptions: [],
      };
    case actionTypes.FETCH_ADDITIONAL_SERVICES_SUCCESS:
      return {
        ...state,
        loadingAdditionalServices: false,
        additionalServiceOptions: action.payload,
      };
    case actionTypes.FETCH_ADDITIONAL_SERVICES_FAILED:
      return {
        ...state,
        loadingAdditionalServices: false,
      };

    case actionTypes.UPDATE_BOOKING_ADDITIONAL_SERVICES:
      const updatedAdditionalServices = [...state.additionalServices];
      const updatedAddAdditionalServicesIdx = updatedAdditionalServices.findIndex(
        ({ code }) => code === action.additionalService.code
      );
      if (updatedAddAdditionalServicesIdx < 0) {
        updatedAdditionalServices.push(action.additionalService);
      } else {
        updatedAdditionalServices.splice(updatedAddAdditionalServicesIdx, 1);
      }

      return {
        ...state,
        additionalServices: updatedAdditionalServices,
      };
    case actionTypes.ADD_BOOKING_FROM_RATES:
      return {
        ...initialState,
        sender: action.sender,
        recipient: action.recipient,
        packages: action.packages,
      };
    case actionTypes.UPDATE_VOEC:
      return {
        ...state,
        voec: action.voec,
      };

    case actionTypes.ADD_REQUESTED_DELIVERY_DATE:
      return {
        ...state,
        requestedDeliveryDate: action.payload,
      };

    case actionTypes.SET_FIXED_DELIVERY_DATES:
      return {
        ...state,
        fixedDeliveryDates: action.payload,
      };

    case actionTypes.UPDATE_BOOKING_FIXED_DELIVERY_DATE:
      return {
        ...state,
        fixedDeliveryDate: action.payload,
      };
    case actionTypes.CLEAR_DELIVERY_DATA:
      //clear notifications depended on service
      let { notifications } = state;
      const clearedServiceNotifications = notifications.filter(
        (n) => n.notificationType !== NOTIFICATION_RECIPIENT_TEXT_MESSAGE
      );

      return {
        ...state,
        serviceCode: null,
        serviceName: null,
        carrier: null,
        currencyCode: null,
        billableWeight: null,
        rate: null,
        totalRate: null,
        fuel: null,
        voec: '',
        fuelSurcharge: null,
        surcharges: null,
        services: [],
        deliveryMethod: null,
        dropOffLocations: null,
        alternateDeliveryAddress: null,
        alternateCollectionAddress: null,
        upsDropOffLocations: [],
        requestedDeliveryDate: null,
        fixedDeliveryDate: null,
        // dhlDropOffLocations: [],
        notifications: clearedServiceNotifications,
      };

    case actionTypes.CLEAR_SERVICE_DATA:
      return {
        ...state,
        services: [],
        deliveryMethod: null,
        requestedDeliveryDate: null,
        fixedDeliveryDate: null,
        alternateDeliveryAddress: null,
        billableWeight: null,
        rate: null,
        totalRate: null,
        serviceCode: null,
        serviceName: null,
        carrier: null,
      };

    case actionTypes.CLEAR_PICKUP_DATA:
      return {
        ...state,
        alternateCollectionAddress: null,
        requestPickup: false,
        pickupDate: null,
        pickupTimeStart: null,
        pickupTimeEnd: null,
        pickupReference: '',
      };
    case actionTypes.CLEAR_PRINT_FORMAT:
      return {
        ...state,
        printFormat: null,
      };
    case actionTypes.CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
