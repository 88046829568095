import * as actions from '../actions/actionTypes';

const initialState = {
  templates: [],
  loading: false,
  addError: null,
  fetchError: null,
  updateError: null,
  removeError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD
    case actions.ADD_PRODUCT_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_PRODUCT_TEMPLATE_SUCCESS:
      let updatedAddProductTemplates = state.templates.slice();
      updatedAddProductTemplates.splice(state.templates.length - 1, 0, action.payload);
      return {
        ...state,
        templates: updatedAddProductTemplates,
        loading: false,
      };
    case actions.ADD_PRODUCT_TEMPLATE_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //FETCH
    case actions.FETCH_PRODUCT_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.products,
        loading: false,
      };
    case actions.FETCH_PRODUCT_TEMPLATE_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //REMOVE
    case actions.REMOVE_PRODUCT_TEMPLATE_START:
      return {
        ...state,
        loading: true,
        removeError: null,
      };
    case actions.REMOVE_PRODUCT_TEMPLATE_SUCCESS:
      const removeProductIndex = state.templates.findIndex((template) => {
        return template.id === action.removedId;
      });

      let updatedRemoveProductTemplates = state.templates.slice();
      updatedRemoveProductTemplates.splice(removeProductIndex, 1);

      return {
        ...state,
        templates: updatedRemoveProductTemplates,
        loading: false,
      };
    case actions.REMOVE_PRODUCT_TEMPLATE_FAILED:
      return {
        ...state,
        removeError: action.error,
        loading: false,
      };

    //UPDATE
    case actions.UPDATE_PRODUCT_TEMPLATE_START:
      return {
        ...state,
        loading: true,
        updateError: null,
      };
    case actions.UPDATE_PRODUCT_TEMPLATE_SUCCESS:
      const updateProductIndex = state.templates.findIndex(
        (template) => template.id === action.productId
      );
      let updatedUpdateProductTemplates = state.templates.slice();
      updatedUpdateProductTemplates.splice(updateProductIndex, 1, action.productData);
      return {
        ...state,
        templates: updatedUpdateProductTemplates,
        loading: false,
      };
    case actions.UPDATE_PRODUCT_TEMPLATE_FAILED:
      return {
        ...state,
        updateError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
