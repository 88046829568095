import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let ThermalPrinter = (props) => {

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          d="M672 54c0-1 1-2 2-2h1v3h10v-3h1c1 0 2 1 2 2v5c0 1-1 2-2 2h-1v-3h-10v3h-1c-1 0-2-1-2-2v-5zm4 7v-2h8v5h-8v-3zm8-9v2h-8v-5h8v3zm-7 8v1h6v-1h-6zm0 2v1h6v-1h-6zm0 0"
          transform="translate(-672 -48)"
        ></path>
      </svg>

    </SvgIcon>
  );
}

ThermalPrinter = pure(ThermalPrinter);
ThermalPrinter.displayName = 'ThermalPrinter';
ThermalPrinter.muiName = 'SvgIcon';

export default ThermalPrinter
