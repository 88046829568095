import React from 'react'
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  snackbar: {
    width: '100%'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: 25,
    color: 'white'
  },
  checkIcon: {
    fontSize: 40,
    color: '#65bb41'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  snackbarContent: {
    backgroundColor: '#261a2d',
    color: 'white',
    marginLeft: '20px',
    width: '95%'
  }
}));

export const GDPRNotification = (props) => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.open}
      //autoHideDuration={4000}
      onClose={props.handleSnackbarClose}
      className={classes.snackbar}
    >
      <SnackbarContent
        className={classes.snackbarContent}
        aria-describedby="client-snackbar"
        message={
          <span className={classes.message}>
            <InfoIcon className={clsx(classes.infoIcon, classes.iconVariant)} />
            <p>{props.message}</p>
          </span>
        }
        action={[
          <div key='close' style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontWeight: '400', fontSize: '1.2rem' }}>{props.consent}</p>
            <IconButton key="close" aria-label="close" color="inherit" onClick={props.handleSnackbarConsent}>
              <CheckCircleIcon className={classes.checkIcon} />
            </IconButton>
          </div>
        ]}
      />
    </Snackbar>
  )
}

export default GDPRNotification