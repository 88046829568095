export const calculateUpsPaperlessCountryEligibility = (recipientCountryCode) =>
  countryEligibility[recipientCountryCode] && !countryEligibility[recipientCountryCode].destination;

const countryEligibility = {
  A2: { countryName: 'Azores', origin: true, destination: true },
  AD: { countryName: 'Andorra', origin: true, destination: true },
  AE: { countryName: 'United Arab Emirates', origin: true, destination: true },
  AF: { countryName: 'Afghanistan', origin: false, destination: false },
  AG: { countryName: 'Antigua and Barbuda', origin: true, destination: false },
  AI: { countryName: 'Anguilla', origin: true, destination: false },
  AL: { countryName: 'Albania', origin: false, destination: false },
  AM: { countryName: 'Armenia', origin: false, destination: false },
  AO: { countryName: 'Angola', origin: false, destination: false },
  AR: { countryName: 'Argentina', origin: true, destination: true },
  AS: { countryName: 'American Samoa', origin: true, destination: true },
  AT: { countryName: 'Austria', origin: true, destination: true },
  AU: { countryName: 'Australia', origin: true, destination: true },
  AW: { countryName: 'Aruba', origin: false, destination: false },
  AX: { countryName: 'Aland Islands', origin: true, destination: true },
  AZ: { countryName: 'Azerbaijan', origin: false, destination: false },
  BA: { countryName: 'Bosnia', origin: true, destination: true },
  BB: { countryName: 'Barbados', origin: true, destination: true },
  BD: { countryName: 'Bangladesh', origin: true, destination: true },
  BE: { countryName: 'Belgium', origin: true, destination: true },
  BF: { countryName: 'Burkina Faso', origin: false, destination: false },
  BG: { countryName: 'Bulgaria', origin: true, destination: true },
  BH: { countryName: 'Bahrain', origin: true, destination: true },
  BI: { countryName: 'Burundi', origin: false, destination: false },
  BJ: { countryName: 'Benin', origin: false, destination: false },
  BL: { countryName: 'St. Barthelemy', origin: true, destination: false },
  BM: { countryName: 'Bermuda', origin: true, destination: false },
  BN: { countryName: 'Brunei', origin: true, destination: true },
  BO: { countryName: 'Bolivia', origin: false, destination: false },
  BQ: { countryName: 'Bonaire, St. Eustatius, Saba', origin: true, destination: false },
  BR: { countryName: 'Brazil', origin: false, destination: false },
  BS: { countryName: 'Bahamas', origin: true, destination: false },
  BT: { countryName: 'Bhutan', origin: true, destination: true },
  BW: { countryName: 'Botswana', origin: false, destination: false },
  BY: { countryName: 'Belarus', origin: true, destination: true },
  BZ: { countryName: 'Belize', origin: true, destination: false },
  C3: { countryName: 'St. Croix', origin: true, destination: true },
  CA: { countryName: 'Canada', origin: true, destination: true },
  CD: { countryName: 'Congo, The Democratic Republic of', origin: false, destination: false },
  CF: { countryName: 'Central African Republic', origin: false, destination: false },
  CG: { countryName: 'Congo', origin: false, destination: false },
  CH: { countryName: 'Switzerland', origin: true, destination: true },
  CI: { countryName: "Cote d' Ivoire", origin: false, destination: false },
  CK: { countryName: 'Cook Islands', origin: true, destination: true },
  CL: { countryName: 'Chile', origin: true, destination: true },
  CM: { countryName: 'Cameroon', origin: false, destination: false },
  CN: { countryName: "China, People's Republic of", origin: true, destination: true },
  CO: { countryName: 'Colombia', origin: true, destination: true },
  CR: { countryName: 'Costa Rica', origin: true, destination: true },
  CU: { countryName: 'Cuba', origin: false, destination: false },
  CV: { countryName: 'Cape Verde Island', origin: false, destination: false },
  CW: { countryName: 'Curacao', origin: true, destination: false },
  CY: { countryName: 'Cyprus', origin: true, destination: true },
  CZ: { countryName: 'Czech Republic', origin: true, destination: true },
  DE: { countryName: 'Germany', origin: true, destination: true },
  DJ: { countryName: 'Djibouti', origin: false, destination: false },
  DK: { countryName: 'Denmark', origin: true, destination: true },
  DM: { countryName: 'Dominica', origin: true, destination: false },
  DO: { countryName: 'Dominican Republic', origin: false, destination: false },
  DZ: { countryName: 'Algeria', origin: false, destination: false },
  E2: { countryName: 'St. Eustatius', origin: true, destination: false },
  EC: { countryName: 'Ecuador', origin: true, destination: true },
  EE: { countryName: 'Estonia', origin: true, destination: true },
  EG: { countryName: 'Egypt', origin: false, destination: false },
  EN: { countryName: 'England', origin: true, destination: true },
  ER: { countryName: 'Eritrea', origin: false, destination: false },
  ES: { countryName: 'Spain', origin: true, destination: true },
  ET: { countryName: 'Ethiopia', origin: false, destination: false },
  FI: { countryName: 'Finland', origin: true, destination: true },
  FJ: { countryName: 'Fiji', origin: true, destination: true },
  FM: { countryName: 'Micronesia, Federated States of', origin: true, destination: true },
  FO: { countryName: 'Faeroe Islands', origin: true, destination: true },
  FR: { countryName: 'France', origin: true, destination: true },
  GA: { countryName: 'Gabon', origin: false, destination: false },
  GB: { countryName: 'United Kingdom', origin: true, destination: true },
  GD: { countryName: 'Grenada', origin: true, destination: false },
  GE: { countryName: 'Georgia', origin: true, destination: true },
  GF: { countryName: 'French Guiana', origin: true, destination: false },
  GG: { countryName: 'Guernsey', origin: true, destination: true },
  GH: { countryName: 'Ghana', origin: false, destination: false },
  GI: { countryName: 'Gibraltar', origin: true, destination: true },
  GL: { countryName: 'Greenland', origin: true, destination: true },
  GM: { countryName: 'Gambia', origin: false, destination: false },
  GN: { countryName: 'Guinea', origin: false, destination: false },
  GP: { countryName: 'Guadeloupe', origin: true, destination: false },
  GQ: { countryName: 'Equatorial Guinea', origin: false, destination: false },
  GR: { countryName: 'Greece', origin: false, destination: true },
  GT: { countryName: 'Guatemala', origin: true, destination: true },
  GU: { countryName: 'Guam', origin: true, destination: true },
  GW: { countryName: 'Guinea-Bissau', origin: false, destination: false },
  GY: { countryName: 'Guyana', origin: true, destination: true },
  HK: { countryName: 'Hong Kong', origin: true, destination: true },
  HN: { countryName: 'Honduras', origin: true, destination: true },
  HO: { countryName: 'Holland', origin: true, destination: true },
  HR: { countryName: 'Croatia', origin: true, destination: true },
  HT: { countryName: 'Haiti', origin: true, destination: true },
  HU: { countryName: 'Hungary', origin: true, destination: true },
  IC: { countryName: 'Canary Islands', origin: true, destination: true },
  ID: { countryName: 'Indonesia', origin: true, destination: true },
  IE: { countryName: 'Ireland, Republic of', origin: true, destination: true },
  IL: { countryName: 'Israel', origin: true, destination: true },
  IN: { countryName: 'India', origin: true, destination: true },
  IS: { countryName: 'Iceland', origin: true, destination: true },
  IT: { countryName: 'Italy', origin: true, destination: true },
  JE: { countryName: 'Jersey', origin: true, destination: true },
  JM: { countryName: 'Jamaica', origin: true, destination: true },
  JP: { countryName: 'Japan', origin: true, destination: true },
  KE: { countryName: 'Kenya', origin: true, destination: true },
  KH: { countryName: 'Cambodia', origin: true, destination: true },
  KI: { countryName: 'Kiribati', origin: true, destination: true },
  KN: { countryName: 'St. Kitts and Nevis', origin: true, destination: true },
  KO: { countryName: 'Kosrae', origin: true, destination: true },
  KR: { countryName: 'Korea, South', origin: true, destination: true },
  KV: { countryName: 'Kosovo', origin: true, destination: true },
  LA: { countryName: 'Laos', origin: true, destination: true },
  LI: { countryName: 'Liechtenstein', origin: true, destination: true },
  LT: { countryName: 'Lithuania', origin: true, destination: true },
  LU: { countryName: 'Luxembourg', origin: true, destination: true },
  LV: { countryName: 'Latvia', origin: true, destination: true },
  M3: { countryName: 'Madeira', origin: true, destination: true },
  MA: { countryName: 'Morocco', origin: true, destination: true },
  MC: { countryName: 'Monaco', origin: true, destination: true },
  MH: { countryName: 'Marshall Islands', origin: true, destination: true },
  MN: { countryName: 'Mongolia', origin: true, destination: true },
  MO: { countryName: 'Macau', origin: true, destination: true },
  MP: { countryName: 'Northern Mariana Islands', origin: true, destination: true },
  MT: { countryName: 'Malta', origin: true, destination: true },
  MX: { countryName: 'Mexico', origin: true, destination: true },
  MY: { countryName: 'Malaysia', origin: true, destination: true },
  NB: { countryName: 'Northern Ireland', origin: true, destination: true },
  NC: { countryName: 'New Caledonia', origin: true, destination: true },
  NF: { countryName: 'Norfolk Island', origin: true, destination: true },
  NG: { countryName: 'Nigeria', origin: true, destination: true },
  NL: { countryName: 'Netherlands', origin: true, destination: true },
  NO: { countryName: 'Norway', origin: true, destination: true },
  NZ: { countryName: 'New Zealand', origin: true, destination: true },
  PE: { countryName: 'Peru', origin: true, destination: true },
  PF: { countryName: 'French Polynesia', origin: true, destination: true },
  PG: { countryName: 'Papua New Guinea', origin: true, destination: true },
  PH: { countryName: 'Philippines', origin: true, destination: true },
  PL: { countryName: 'Poland', origin: true, destination: true },
  PO: { countryName: 'Ponape', origin: true, destination: true },
  PR: { countryName: 'Puerto Rico', origin: true, destination: true },
  PT: { countryName: 'Portugal', origin: true, destination: true },
  PW: { countryName: 'Palau', origin: true, destination: true },
  RO: { countryName: 'Romania', origin: true, destination: true },
  RS: { countryName: 'Serbia', origin: true, destination: true },
  RT: { countryName: 'Rota', origin: true, destination: true },
  RU: { countryName: 'Russia', origin: false, destination: true },
  SA: { countryName: 'Saudi Arabia', origin: true, destination: true },
  SB: { countryName: 'Solomon Islands', origin: true, destination: true },
  SE: { countryName: 'Sweden', origin: true, destination: true },
  SF: { countryName: 'Scotland', origin: true, destination: true },
  SG: { countryName: 'Singapore', origin: true, destination: true },
  SI: { countryName: 'Slovenia', origin: true, destination: true },
  SK: { countryName: 'Slovakia', origin: true, destination: true },
  SM: { countryName: 'San Marino', origin: true, destination: true },
  SP: { countryName: 'Saipan', origin: true, destination: true },
  SR: { countryName: 'Suriname', origin: true, destination: true },
  SV: { countryName: 'El Salvador', origin: true, destination: true },
  TA: { countryName: 'Tahiti', origin: true, destination: true },
  TH: { countryName: 'Thailand', origin: true, destination: true },
  TI: { countryName: 'Tinian', origin: true, destination: true },
  TL: { countryName: 'Timor Leste', origin: true, destination: true },
  TO: { countryName: 'Tonga', origin: true, destination: true },
  TR: { countryName: 'Turkey', origin: true, destination: true },
  TU: { countryName: 'Truk', origin: true, destination: true },
  TV: { countryName: 'Tuvalu', origin: true, destination: true },
  TW: { countryName: 'Taiwan', origin: true, destination: true },
  US: { countryName: 'United States', origin: true, destination: true },
  UV: { countryName: 'St. John', origin: true, destination: true },
  UY: { countryName: 'Uruguay', origin: true, destination: true },
  VA: { countryName: 'Vatican City State', origin: true, destination: true },
  VL: { countryName: 'St. Thomas', origin: true, destination: true },
  VN: { countryName: 'Vietnam', origin: true, destination: true },
  VU: { countryName: 'Vanuatu', origin: true, destination: true },
  WF: { countryName: 'Wallis and Futuna Islands', origin: true, destination: true },
  WL: { countryName: 'Wales', origin: true, destination: true },
  WS: { countryName: 'Samoa', origin: true, destination: true },
  YA: { countryName: 'Yap', origin: true, destination: true },
  ZA: { countryName: 'South Africa', origin: true, destination: true },
};
