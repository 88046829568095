import React from 'react'
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let TemplateNew = (props) => {

	return (
		<SvgIcon {...props}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fillRule='evenodd'
				clipRule='evenodd' strokeLinejoin='round' strokeMiterlimit='1.414'
			>
				<path d='M 5.8730469 2.0019531 C 5.6480469 2.0109531 5.42875 2.0517656 5.21875 2.1347656 C 4.71275 2.3347656 4.3061406 2.7507187 4.1191406 3.2617188 C 4.0471406 3.4577187 4.0119063 3.6620937 4.0039062 3.8710938 C 4.0189063 9.2920938 3.9929062 14.712859 4.0039062 20.130859 C 4.0119062 20.339859 4.0471406 20.545188 4.1191406 20.742188 C 4.3061406 21.252188 4.71275 21.668187 5.21875 21.867188 C 5.42875 21.950187 5.6480469 21.992953 5.8730469 22.001953 C 8.8660469 22.000953 11.860516 21.993141 14.853516 21.994141 L 14.458984 21.3125 L 12.275391 20.726562 L 12.470703 20.001953 L 6.0019531 20.001953 L 6.0019531 4.0019531 L 18.001953 4.0019531 L 18.001953 12.96875 L 18.412109 12.730469 L 19.541016 14.6875 L 19.986328 14.806641 C 19.979328 11.171641 19.981953 7.5260937 20.001953 3.8710938 C 19.993953 3.6620938 19.958719 3.4577188 19.886719 3.2617188 C 19.698719 2.7507188 19.291156 2.3347656 18.785156 2.1347656 C 18.575156 2.0517656 18.356813 2.0109531 18.132812 2.0019531 C 14.057813 1.9979531 9.9710469 1.9999531 5.8730469 2.0019531 z M 7.0234375 5.0019531 L 7.0234375 7.0019531 L 16.980469 7.0019531 L 16.980469 5.0019531 L 7.0234375 5.0019531 z M 7.0234375 8.0019531 L 7.0234375 10.001953 L 16.980469 10.001953 L 16.980469 8.0019531 L 7.0234375 8.0019531 z M 7.0234375 11.001953 L 7.0234375 19.001953 L 12.738281 19.001953 L 12.861328 18.544922 L 11.730469 16.587891 L 13.6875 15.458984 L 14.273438 13.275391 L 16.455078 13.861328 L 16.980469 13.558594 L 16.980469 11.001953 L 7.0234375 11.001953 z M 18.097656 14.404297 L 16.744141 15.185547 L 15.238281 14.78125 L 14.833984 16.287109 L 13.482422 17.068359 L 14.263672 18.419922 L 13.859375 19.927734 L 15.365234 20.332031 L 16.146484 21.683594 L 17.498047 20.902344 L 19.005859 21.306641 L 19.410156 19.800781 L 20.761719 19.019531 L 19.980469 17.667969 L 20.384766 16.160156 L 18.876953 15.755859 L 18.097656 14.404297 z'
				/>
			</svg>
		</SvgIcon>
	)
}

TemplateNew = pure(TemplateNew);
TemplateNew.displayName = 'TemplateNew';
TemplateNew.muiName = 'SvgIcon';

export default TemplateNew
