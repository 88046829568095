import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Toolbar from '../../../UI/CustomToolbar/CustomToolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCommentIcon from '@material-ui/icons/AddComment';
import AddCompanyIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CopyIcon from '../../../UI/Icons/ContentCopy';
import { lighten } from '@material-ui/core/styles/colorManipulator';

function createData(
  id,
  primary,
  templateName,
  company,
  countryCode,
  contacts,
  city,
  zip,
  address1
) {
  return {
    id,
    primary,
    templateName,
    company,
    countryCode,
    contacts,
    city,
    zip,
    address1,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead({ onRequestSort, order, orderBy, rows, translations }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        <TableCell style={{ padding: 0 }}></TableCell>
        {rows.map((row) => (
          <TableCell
            key={row.id}
            align={row.align} //{row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <Tooltip
              title={translations.shipmenthistory.textLabels.sort}
              placement={row.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

function EnhancedTableToolbar(props) {
  const { numSelected, title, copyTooltip } = props;
  let toolbarTitle =
    numSelected > 0 ? `${numSelected} ${props.translations.templates.selected}` : title;

  const classes = useToolbarStyles();
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar title={toolbarTitle}>
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <div>
              <Tooltip title={props.translations.templates.makedefaultselection}>
                <IconButton onClick={props.handleUpdateDefault} aria-label="Update Default">
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={props.translations.templates.change}>
                <IconButton onClick={props.handleEdit} aria-label="Edit">
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={props.translations.templates.contacts}>
                <IconButton onClick={props.handleEditContacts} aria-label="Contacts">
                  <GroupIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={props.translations.addCommentForTemplate}>
                <IconButton onClick={props.updateComment} aria-label="Add comment">
                  <AddCommentIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`${props.translations.templates.copyto} "${copyTooltip}"`}>
                <IconButton onClick={props.handleCopyTemplate} aria-label="Copy">
                  <CopyIcon style={{ fontSize: '20px', padding: '2px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={props.translations.shipmenthistory.textLabels.delete}>
                <IconButton onClick={props.handleDelete} aria-label="Delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              title={`${props.translations.templates.add} ${
                props.translations.company.substr(0, 1).toLowerCase() +
                props.translations.company.substr(1)
              }`}
            >
              <IconButton aria-label="add company" onClick={props.handleAddCompany}>
                <AddCompanyIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  contactsTableCell: {
    fontWeight: '500',
    color: theme.palette.primary.main,
    zIndex: '10000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const initialCompanyDataState = {
  order: 'asc',
  orderBy: 'company',
  selected: [],
  data: [],
  page: 0,
  rowsPerPage: 9,
};

export default function EnhancedTableCompany({
  companyData,
  handleUpdateDefaultTemplate,
  handleEditTemplate,
  handleEditTemplateContacts,
  updateTemplateComment,
  handleCopyTemplate,
  handleRemoveTemplate,
  handleAddCompany,
  translations,
  copyTooltip,
  title,
  rows,
  defaultId,
  modals = [],
}) {
  const [state, setState] = useState(initialCompanyDataState);

  useEffect(() => {
    const companyDataRows = companyData.map((company) =>
      createData(
        company.id,
        company.primary,
        company.templateName,
        company.company,
        company.countryCode,
        company.contacts,
        company.city,
        company.zip,
        company.address1
      )
    );
    setState({ ...state, data: companyDataRows });
  }, []);

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }
    setState({ ...state, order, orderBy });
  };

  const handleClick = (event, id) => {
    const { selected } = state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(id);
    }

    setState({ ...state, selected: newSelected });
  };

  const handleChangePage = (event, page) => {
    setState({ ...state, page });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleUpdateDefault = () => {
    handleUpdateDefaultTemplate(state.selected[0]);
  };
  const updateComment = () => {
    updateTemplateComment(state.selected[0]);
  };
  const handleDelete = () => {
    handleRemoveTemplate(state.selected[0]);
  };
  const handleEdit = () => {
    handleEditTemplate(state.selected[0]);
  };
  const handleCopy = () => {
    handleCopyTemplate(state.selected[0]);
  };
  const handleEditContacts = (id, tableCell = false) => {
    handleEditTemplateContacts(tableCell ? id : state.selected[0]);
  };

  const handleClickAway = (e) => {
    // Check whether the click was on the iconbuttons in toolbar
    if (typeof e.target.className === 'string') {
      if (
        e.target.className.includes('PrivateSwitchBase-input-') ||
        e.target.className.includes('MuiIconButton-')
      )
        return;
    }

    if (typeof e.target.className === 'object' && 'baseVal' in e.target.className) return;

    setState({ ...state, selected: [] });
  };

  const isSelected = (id) => state.selected.indexOf(id) !== -1;

  const classes = useTableStyles();

  const emptyRows =
    state.rowsPerPage -
    Math.min(state.rowsPerPage, state.data.length - state.page * state.rowsPerPage);

  return (
    <div className={classes.root}>
      {modals.length > 0 &&
        modals.map((modal) =>
          modal.props.open ? React.cloneElement(modal, { key: modal.props.id }) : null
        )}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          translations={translations}
          copyTooltip={copyTooltip}
          title={title}
          numSelected={state.selected.length}
          handleAddCompany={handleAddCompany}
          handleEdit={handleEdit}
          handleEditContacts={handleEditContacts}
          handleDelete={handleDelete}
          handleUpdateDefault={handleUpdateDefault}
          updateComment={updateComment}
          handleCopyTemplate={handleCopy}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              translations={translations}
              rows={rows}
              numSelected={state.selected.length}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={state.data.length}
            />
            <ClickAwayListener onClickAway={handleClickAway}>
              <TableBody>
                {stableSort(state.data, getSorting(state.order, state.orderBy))
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((n) => {
                    const selected = isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={selected}
                        tabIndex={-1}
                        key={n.id}
                        selected={selected}
                      >
                        <TableCell padding="checkbox">
                          {' '}
                          {/* TODO: change left padding from 4px on checkbox to 0 */}
                          <Checkbox checked={selected} />
                        </TableCell>
                        <TableCell style={{ padding: 0, minWidth: '20px' }}>
                          {n.primary ? (
                            <HomeIcon
                              style={{
                                fontSize: '1.2rem',
                                color: '#484747',
                                position: 'absolute',
                                transform: 'translate(3px,-12px)',
                              }}
                            />
                          ) : null}
                          {n.id === defaultId ? (
                            <Tooltip
                              title={`${translations.defaultsender
                                .substr(0, 1)
                                .toUpperCase()}${translations.defaultsender.substr(1)}`}
                            >
                              <FavoriteIcon
                                style={{
                                  fontSize: '1.2rem',
                                  transform: 'translate(-13px,-12px)',
                                  color: '#a91e1e',
                                  position: 'absolute',
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell style={{ padding: 0, paddingRight: '5px' }} align="left">
                          {n.templateName}
                        </TableCell>
                        <TableCell style={{ padding: 0, paddingRight: '5px' }} align="left">
                          {n.company}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          {n.countryCode}
                        </TableCell>
                        <Tooltip
                          title={
                            n.contacts.length < 1 ? (
                              <span>{translations.templates.nocontacts}</span>
                            ) : (
                              n.contacts.map((value, index) => (
                                <span key={index}>
                                  {value.name}
                                  <br />
                                </span>
                              ))
                            )
                          }
                        >
                          <TableCell
                            className={classes.contactsTableCell}
                            onClick={() => handleEditContacts(n.id, true)}
                            padding="none"
                            align="center"
                            style={{ transform: 'translateX(-15px)' }}
                          >
                            {n.contacts.length}
                            <PersonIcon
                              color="primary"
                              style={{ fontSize: '1.2rem', transform: 'translateY(4px)' }}
                            />
                          </TableCell>
                        </Tooltip>
                        <TableCell padding="none" align="left">
                          {n.city}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          {n.zip}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          {n.address1}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 &&
                  Array.apply(null, { length: emptyRows }).map((_, idx) => (
                    <TableRow key={`empty-row-${idx}`} style={{ height: 43 }}>
                      <TableCell colSpan={9}></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </ClickAwayListener>
          </Table>
        </div>
        <TablePagination
          labelRowsPerPage={translations.templates.rowsperpage}
          rowsPerPageOptions={[10, 15, 50]}
          component="div"
          count={state.data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
