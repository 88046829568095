import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDefaultLanguage, updateDefaultCurrency } from '../../../store/actions';
//UI
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
//Icons
import GBicon from '../../UI/Icons/Flags/gb';
import SEicon from '../../UI/Icons/Flags/se';
import FIicon from '../../UI/Icons/Flags/fi';
//Material UI
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { buildOptionsFromApplicableCurrencyCodes } from '../../../services/helpers/getCurrencies';

//TODO: move these values to database (also exists in customsvalue component)
const currencies = buildOptionsFromApplicableCurrencyCodes();

//for reference - "language codes"
const languages = [
  {
    value: 'se',
    label: 'sv_SE',
  },
  {
    value: 'en',
    label: 'en_GB',
  },
  {
    value: 'fi',
    label: 'fi_FI',
  },
];

const icons = {
  en: <GBicon />,
  fi: <FIicon />,
  se: <SEicon />,
};

const useStyles = makeStyles(theme => ({
  root: {
    gridArea: 'accountsettings',
  },
  container: {
    display: 'flex',
  },
  content: {
    display: 'grid',
    width: '100%',
    height: 'calc(100% - 42px)',
    gridTemplateColumns: '1fr 1fr',
    gap: '5px',
    placeItems: 'center',
    '& article': {
      display: 'flex',
      padding: '10px 0',
      alignItems: 'center',
      justifyContent: 'center',
      '& h3': {
        fontWeight: 500,
        padding: '0 10px 8px 0',
        fontSize: '1rem',
        alignSelf: 'flex-end',
      },
    },
  },
  label: {
    marginRight: '10px',
  },
  currency: {
    width: '64px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  language: {
    width: '120px',
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      justifyContent: 'center',
    },
    content: {
      width: 'fit-content',
      gridTemplateColumns: 'max-content',
      '& article': {
        width: '100%',
        justifyContent: 'flex-start',
        '& h3': {
          fontSize: '0.9rem',
        },
      },
    },
  },
}));

const AccountSettings = props => {
  const { defaultLanguage, defaultCurrency } = useSelector(state => state.rootTemplates);

  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleDefaultCurrencyChange = e => {
    dispatch(updateDefaultCurrency(e.target.value, token));
  };

  const handleDefaultLanguageChange = e => {
    dispatch(updateDefaultLanguage(e.target.value, token));
  };

  return (
    <Paper elevation={1} className={classes.root}>
      <AppBar elevation={0} position='static'>
        <CustomToolbar title={props.translations.accountsettings}></CustomToolbar>
      </AppBar>
      <div className={classes.container}>
        <section className={classes.content}>
          <article>
            <h3>{props.translations.languagedisplay}:</h3>
            <TextField
              id='standard-select-language'
              select
              className={classes.language}
              value={defaultLanguage || 'en'}
              onChange={handleDefaultLanguageChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='none'
            >
              {languages.map(option => (
                <MenuItem className={classes.menuItem} key={option.value} value={option.value}>
                  <div className={classes.menuItem}>
                    <p className={classes.label}>{option.label}</p>
                    {icons[option.value]}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          </article>
          <article>
            <h3>{props.translations.defaultcurrency}:</h3>
            <TextField
              id='standard-select-currency'
              select
              className={classes.currency}
              value={defaultCurrency || 'SEK'}
              onChange={handleDefaultCurrencyChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='none'
            >
              {currencies.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </article>
        </section>
      </div>
    </Paper>
  );
};

export default AccountSettings;
