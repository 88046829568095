import React, { useState, useEffect } from 'react';
import { Document, Image, Page, PDFDownloadLink, StyleSheet, View } from '@react-pdf/renderer';

const height = (488 * 0.60215053).toString(); //height in pixels with same ratio as the html from UPS width and height pixel values

//Styling Label PDF only applies to UPS
//If used by additional carriers, then values such as height should be dynamic
const stylesLabelPDF = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '488px',
    height: `${height}px`,
  },
});

/**
 * Generates PDF with shipping labels from base64 encoded array of image(png) strings
 */
export const LabelPDF = (props) => {
  return (
    <Document>
      {props.packages.map((pack) => (
        <Page key={pack.tracking} size="A4">
          <View style={stylesLabelPDF.section}>
            <Image style={stylesLabelPDF.image} src={`data:image/png;base64, ${pack.img}`} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

export const GenerateDownloadLink = ({ labels, fileName, children }) => {
  return (
    <div>
      <PDFDownloadLink
        style={{ textDecoration: 'none' }}
        document={<LabelPDF packages={labels} />}
        fileName={fileName}
      >
        {() => children}
      </PDFDownloadLink>
    </div>
  );
};

/**
 * Generats Thermal Printing Labels by loading script provided by Zebra Inc.
 * Only works with Zebra Printers and Installed Zebra Browser Printer software
 * Both USB and Network (Ethernet) installed Zebra printers work.
 *
 */
export const ThermalPrint = ({ updatePrinted = null, packages, children }) => {
  const [zpl, setZpl] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = __dirname + 'js/BrowserPrint.min.js';
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    setZplString(packages);
  });

  /**
   * Use default printer set in Zebra Browser Printer and send zpl code to printer
   */
  const printTest = () => {
    window.BrowserPrint.getDefaultDevice(
      'printer',
      function (printer) {
        printer.send(zpl);
        if (updatePrinted) {
          updatePrinted();
        }
      },
      function (error) {
        alert('An error occured while attempting to connect to your Zebra Printer.');
      }
    );
  };

  /**
   * Map all zpl code strings to a single zpl string
   * @param {Array} packages
   */
  const setZplString = (packages) => {
    const zplArray = packages.map((pack) => {
      return window.atob(pack.img);
    });

    setZpl(zplArray.join('\n'));
  };

  return (
    <div onClick={printTest} style={{ width: '100%' }}>
      {children}
    </div>
  );
};
