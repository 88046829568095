import { CARRIERS } from '../../../../services/types';

export const getMapData = (packages, carrier) => {
  const mapData = packages.map((pack) => {
    const { Activity, TrackingNumber } = pack;
    let location;
    let city, countryCode, activities;
    let error = false;

    switch (carrier) {
      case CARRIERS.UPS:
        activities = Array.isArray(Activity) ? Activity : [Activity];
        //find first Activity location with CountryCode
        for (let idx of activities) {
          if (
            typeof idx.ActivityLocation === 'object' &&
            idx.ActivityLocation.Address.hasOwnProperty('CountryCode')
          ) {
            countryCode = idx.ActivityLocation.Address.CountryCode;
            if (idx.ActivityLocation.Address.hasOwnProperty('City')) {
              city = idx.ActivityLocation.Address.City;
            }
            break;
          }
        }
        if (!city || !countryCode) error = true;
        if (!error) location = `${city},${countryCode}`;
        break;
      case CARRIERS.DHLFREIGHT:
        const activity = Activity[0];

        location = `${activity.terminalName},${activity.terminalCountry}`;
        break;
      case CARRIERS.MATKAHUOLTO:
        if (Activity.length < 1) {
          error = 'No activites found';
          break;
        }

        location = `${Activity[0].place},FI`;
        break;
      case CARRIERS.UPSSCS:
        activities = Array.isArray(Activity) ? Activity : [Activity];
        for (let idx of activities) {
          if (idx.ActivityLocation && idx.ActivityLocation.hasOwnProperty('CountryCode')) {
            countryCode = idx.ActivityLocation.CountryCode;
            if (idx.ActivityLocation.hasOwnProperty('City')) {
              city = idx.ActivityLocation.City;
            }
            break;
          }
        }

        if (!city || !countryCode) error = true;
        if (!error) location = `${city},${countryCode}`;
        break;
      default:
        error = 'Carrier not supported for tracking map';
    }

    return { location, tracking: TrackingNumber, error };
  });

  return mapData;
};
