import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidatePackageTemplate } from '../../../../services/validations';
//custom ui
import Toolbar from '../../../UI/CustomToolbar/CustomToolbar';
import SnackbarContentWrapper from '../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
//material ui
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import TableChartIcon from '@material-ui/icons/TableChart';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '336px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: 'calc(100% - 42px)',
  },
  packageDetails: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-around',
  },
  textField: {
    maxWidth: '100px',
  },
  dimensions: {
    display: 'flex',
    '& $textField': {
      margin: '0 5px',
    },
  },
  importButton: {
    backgroundColor: '#5d9665',
    color: 'white',
  },
  buttonContainer: {
    width: '90%',
    display: 'grid',
    columnGap: '10px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  add: {
    gridColumn: '3',
  },
  cancel: {
    gridColumn: '4',
  },
});

const PackageTemplateDetails = (props) => {
  const [inputs, setInputs] = useState({
    packaging: '02',
    templateName: '',
    length: '',
    width: '',
    height: '',
    weight: '',
  });

  const [errors, setError] = useState({
    templateName: false,
    length: false,
    width: false,
    height: false,
    weight: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    messages: [],
    variant: '',
  });

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  useEffect(() => {
    if (props.type === 'edit' && props.packageTemplate) {
      setInputs({
        packaging: props.packageTemplate.packaging,
        templateName: props.packageTemplate.templateName,
        length: props.packageTemplate.length,
        width: props.packageTemplate.width,
        height: props.packageTemplate.height,
        weight: props.packageTemplate.weight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStringChange = (field) => (e) => {
    const value = e.target.value.replace(',', '.');

    setInputs({ ...inputs, [field]: value });
  };
  const handleFloatChange = (field) => (e) => {
    const value = e.target.value.replace(',', '.');

    setInputs({ ...inputs, [field]: value });
  };

  const handleFloatBlur = (field) => (e) => {
    const float = parseFloat(e.target.value);

    if (isNaN(float)) {
      setError({ ...errors, [field]: true });
    } else {
      if (errors[field]) setError({ ...errors, [field]: false });
      setInputs({ ...inputs, [field]: Math.round(float * 100) / 100 });
    }
  };

  const handleWeightBlur = (field) => (e) => {
    if (e.target.value !== '') {
      const float = parseFloat(e.target.value);
      if (isNaN(float)) {
        setError({ ...errors, [field]: true });
      } else {
        if (errors[field]) setError({ ...errors, [field]: false });
        setInputs({ ...inputs, [field]: Math.round(float * 100) / 100 });
      }
    } else {
      if (errors[field]) setError({ ...errors, [field]: false });
    }
  };

  const handleSubmit = () => {
    //component validation for errors, specific for weight. TODO: integrate into yup validation
    const validated = Object.keys(errors).every((key) => errors[key] === false);

    if (!validated) {
      setSnackbar({
        open: true,
        messages: [props.translations.templates.incorrectfields],
        variant: 'error',
      });
      return;
    }

    //yup validations (validates length of all characters);
    const yupValid = ValidatePackageTemplate(inputs, lang);

    yupValid
      .then((resp) => {
        if (resp.valid) {
          if (props.type === 'add') props.handleAddPackage(inputs);
          if (props.type === 'edit') props.handleUpdatePackage(inputs);
        } else {
          setSnackbar({
            open: true,
            messages: resp.errors,
            variant: 'error',
          });
        }
      })
      .catch((err) => {
        setSnackbar({
          open: true,
          messages: [props.translations.templates.incorrectfields],
          variant: 'error',
        });
      });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar
          title={`${props.translations.templates.add} ${
            props.translations.templates.packagetemplate.substr(0, 1).toLowerCase() +
            props.translations.templates.packagetemplate.substr(1)
          }`}
        >
          <Tooltip title={props.translations.templates.packagetemplates}>
            <IconButton onClick={props.handleChangeToTable} aria-label="TableView">
              <TableChartIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <div className={classes.formTitle}>{`${props.translations.templates.new} ${
          props.translations.templates.packagetemplate.substr(0, 1).toLowerCase() +
          props.translations.templates.packagetemplate.substr(1)
        }`}</div>
        <div className={classes.packageDetails}>
          <div className={classes.templateName}>
            <TextField
              required
              inputProps={{ maxLength: 15 }}
              className={classes.textField}
              value={inputs.templateName}
              label={props.translations.templates.template}
              onChange={handleStringChange('templateName')}
              //onBlur={handleBlur('templateName')}
              error={errors.templateName === true}
            />
          </div>
          <div className={classes.dimensions}>
            <TextField
              required
              inputProps={{ maxLength: 5 }}
              className={classes.textField}
              value={inputs.length}
              label={props.translations.packagedetails.length}
              onChange={handleFloatChange('length')}
              onBlur={handleFloatBlur('length')}
              error={errors.length === true}
            />
            <TextField
              required
              inputProps={{ maxLength: 5 }}
              className={classes.textField}
              value={inputs.width}
              label={props.translations.packagedetails.width}
              onChange={handleFloatChange('width')}
              onBlur={handleFloatBlur('width')}
              error={errors.width === true}
            />
            <TextField
              required
              inputProps={{ maxLength: 5 }}
              className={classes.textField}
              value={inputs.height}
              label={props.translations.packagedetails.height}
              onChange={handleFloatChange('height')}
              onBlur={handleFloatBlur('height')}
              error={errors.height === true}
            />
          </div>
          <div className={classes.weight}>
            <TextField
              inputProps={{ maxLength: 5 }}
              className={classes.textField}
              value={inputs.weight}
              label={props.translations.packagedetails.weight}
              onChange={handleFloatChange('weight')}
              onBlur={handleWeightBlur('weight')}
              error={errors.weight === true}
            />
          </div>
        </div>
        {/*   <Button className={classes.importButton} variant='contained'>{props.translations.templates.import}</Button> */}

        <div className={classes.buttonContainer}>
          <Button
            className={classes.add}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {props.type === 'add'
              ? `+${props.translations.templates.add}`
              : props.translations.templates.update}
          </Button>
          <Button
            className={classes.cancel}
            color="secondary"
            variant="outlined"
            onClick={props.handleChangeToTable}
          >
            {props.translations.templates.cancel}
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </Paper>
  );
};

export default PackageTemplateDetails;
