import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DHLFREIGHT, MATKAHUOLTO, UPS, HRX } from '../../../../services/types';
import moment from 'moment';
import {
  addPickupDetails,
  addRequestPickupOption,
  addPickupDetailTime,
} from '../../../../store/actions';
import { initialPickupDate } from '../../../../utils/pickup';
import DefaultCollection from '../DefaultCollection/DefaultCollection';
import DhlFreightPickup from '../DhlFreightPickup/DhlFreightPickup';
import MatkahuoltoDeparture from '../MatkahuoltoDeparture/MatkahuoltoDeparture';
import HrxPickup from '../HrxPickup/HrxPickup';

const PickupSelection = (props) => {
  const bookingData = useSelector((state) => state.booking);
  const { token } = useSelector((state) => state.auth);

  //Pickup states
  const [requestPickup, setRequestPickup] = useState(false);
  const [selectedPickupDate, setSelectedPickupDate] = useState(bookingData.date);
  const [selectedPickupTimeStart, setSelectedPickupDateTimeStart] = useState(bookingData.date);
  const [selectedPickupTimeEnd, setSelectedPickupDateTimeEnd] = useState(bookingData.date);
  const [pickupReference, setPickupReference] = useState('');

  //Pickup location states
  const [dropOffLocations, setDropOffLocations] = useState([]);
  const [dropOffLoading, setDropOffLoading] = useState(false);
  const [dropOffError, setDropOffError] = useState(null);

  //refs for dispatching to redux
  const dateRef = useRef();
  dateRef.current = selectedPickupDate;
  const timeStartRef = useRef();
  timeStartRef.current = selectedPickupTimeStart;
  const timeEndRef = useRef();
  timeEndRef.current = selectedPickupTimeEnd;
  const pickupRef = useRef();
  pickupRef.current = pickupReference;

  //add to redux global store on component unmount
  useEffect(() => {
    return () => {
      dispatch(
        addPickupDetails(
          moment(dateRef.current).format(),
          moment(timeStartRef.current).format(),
          moment(timeEndRef.current).format(),
          pickupRef.current,
          null
        )
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingData.carrier === MATKAHUOLTO) {
      handleDropOffLocationRequest(MATKAHUOLTO);
    }

    if (bookingData.requestPickup) {
      setRequestPickup(true);
      if (bookingData.pickupReference.length > 0) setPickupReference(bookingData.pickupReference);
    }

    initializePickupDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const initializePickupDates = () => {
    let date;
    let timeStart;
    let timeEnd;

    if (bookingData.pickupDate) {
      date = bookingData.pickupDate;
      timeStart = bookingData.pickupTimeStart;
      timeEnd = bookingData.pickupTimeEnd;
    } else {
      //set a initial pickup date depending on the current selected shippping date
      const { pickupDate, pickupTimeStart, pickupTimeEnd } = initialPickupDate(bookingData.date);

      date = pickupDate;
      timeStart = pickupTimeStart;
      timeEnd = pickupTimeEnd;
    }

    setSelectedPickupDate(date);
    setSelectedPickupDateTimeStart(timeStart);
    setSelectedPickupDateTimeEnd(timeEnd);
  };

  const handlePickupChange = () => {
    let option = !requestPickup;
    setRequestPickup(option);
    dispatch(addRequestPickupOption(option));
  };

  const handlePickupDateChange = (date) => {
    if (props.instantUpdate) dispatch(addPickupDetailTime({ field: 'pickupDate', time: date }));

    setSelectedPickupDate(date);
  };

  const handleStartTimeChange = (startTime) => {
    if (props.instantUpdate)
      dispatch(addPickupDetailTime({ field: 'pickupTimeStart', time: moment(startTime).format() }));
    setSelectedPickupDateTimeStart(startTime);
  };

  const handleEndTimeChange = (endTime) => {
    if (props.instantUpdate)
      dispatch(addPickupDetailTime({ field: 'pickupTimeEnd', time: moment(endTime).format() }));
    setSelectedPickupDateTimeEnd(endTime);
  };

  const handlePickupReferenceChange = (e) => {
    setPickupReference(e.target.value);
  };

  const getPickupOptions = (carrier) => {
    switch (carrier) {
      case MATKAHUOLTO:
        return (
          <MatkahuoltoDeparture
            translations={props.translations}
            dropOffError={dropOffError}
            dropOffLoading={dropOffLoading}
            dropOffLocations={dropOffLocations}
          />
        );
      case UPS:
        return (
          <DefaultCollection
            translations={props.translations}
            requestPickup={requestPickup}
            handlePickupChange={handlePickupChange}
            selectedPickupDate={selectedPickupDate}
            selectedPickupTimeStart={selectedPickupTimeStart}
            selectedPickupTimeEnd={selectedPickupTimeEnd}
            handleStartTimeChange={handleStartTimeChange}
            handleEndTimeChange={handleEndTimeChange}
            handlePickupDateChange={handlePickupDateChange}
            pickupReference={pickupReference}
            handlePickupReferenceChange={handlePickupReferenceChange}
            locale={props.locale}
            withOptions={props.withOptions}
          />
        );
      case DHLFREIGHT:
        return (
          <DhlFreightPickup
            translations={props.translations}
            requestPickup={requestPickup}
            handlePickupChange={handlePickupChange}
            selectedPickupDate={selectedPickupDate}
            handlePickupDateChange={handlePickupDateChange}
            pickupReference={pickupReference}
            handlePickupReferenceChange={handlePickupReferenceChange}
            serviceCode={bookingData.serviceCode}
          />
        );
      case HRX:
        return (
          <HrxPickup
            translations={props.translations}
            selectedPickupDate={selectedPickupDate}
            handlePickupDateChange={handlePickupDateChange}
            pickupReference={pickupReference}
            handlePickupChange={handlePickupChange}
            handlePickupReferenceChange={handlePickupReferenceChange}
          />
        );
      default:
        return <p>No pickup available</p>;
    }
  };

  const handleDropOffLocationRequest = (carrier) => {
    setDropOffLoading(true);
    dropOffError && setDropOffError(null); //reset error message

    const address = {
      address1: bookingData.sender.address1,
      city: bookingData.sender.city,
      zip: bookingData.sender.zip,
      countryCode: bookingData.sender.countryCode,
    };

    axios
      .post(
        '/api/shipping/locator',
        { address, carrier },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        // const dropOffOptions = resp.data.locatorResults.locations.map((dropOff) => {
        //   console.log(dropOff);
        //   const { id, name, distance, address, distanceUnit } = dropOff;
        //   return { id, name, distance, address,distanceUnit };
        // });

        setDropOffLoading(false);
        setDropOffLocations(resp.data.locatorResults.locations);
        //TODO: check in global store if dropOffOption has been selected
      })
      .catch((e) => {
        setDropOffLoading(false);
        setDropOffError(props.translations.onlinebooking.noaccesspointfound);
      });
  };

  const pickupOptions = getPickupOptions(bookingData.carrier);

  return <div>{pickupOptions}</div>;
};

export default PickupSelection;
