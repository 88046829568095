import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Voec from '../../../../Voec/Voec';
import { UPDATE_VOEC } from '../../../../../store/actions/actionTypes';
import CustomPaper from '../../../../UI/CustomPaper/CustomPaper';

const VoecContainer = ({ translations }) => {
  const [voecNumber, setVoecNumber] = useState('');

  const { voec } = useSelector((state) => state.booking);
  const { voecNumber: voecNumberPreset } = useSelector((state) => state.bookingPresets);
  const dispatch = useDispatch();

  const voecNumberRef = useRef();
  voecNumberRef.current = voecNumber;

  useEffect(() => {
    if (voec) setVoecNumber(voec);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voec]);

  useEffect(() => {
    if (typeof voec !== 'string' && voecNumberPreset) {
      setVoecNumber(voecNumberPreset);
      dispatch({ type: UPDATE_VOEC, voec: voecNumberPreset });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVoecBlur = () => {
    dispatch({ type: UPDATE_VOEC, voec: voecNumberRef.current });
  };

  const handleChange = (e) => {
    setVoecNumber(e.target.value);
  };

  return (
    <CustomPaper title={translations.voecIdentificationNumber}>
      <Voec voecId={voecNumber} handleBlur={handleVoecBlur} handleChange={handleChange} />
    </CustomPaper>
  );
};

export default VoecContainer;
