import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/userDetails';
import shipmentsReducer from './store/reducers/shipments';
import eventsReducer from './store/reducers/events';
import trackedShipmentsReducer from './store/reducers/trackedShipments';
import countriesReducer from './store/reducers/countries';
import carriersReducer from './store/reducers/carriers';
import ratesReducer from './store/reducers/rates';
import packagesDetailsReducer from './store/reducers/packagesDetails';
import userUploadReducer from './store/reducers/userUpload';
import bookingReducer from './store/reducers/booking';
import recipientCompanyReducer from './store/reducers/recipientCompanyTemplates';
import senderCompanyReducer from './store/reducers/senderCompanyTemplates';
import rootTemplatesReducer from './store/reducers/rootTemplates';
import bookingPresetsReducer from './store/reducers/bookingPresets';
import bookingStatusReducer from './store/reducers/bookingStatus';
import packageTemplatesReducer from './store/reducers/packageTemplates';
import productTemplatesReducer from './store/reducers/productTemplates';
import requestBookingReducer from './store/reducers/requestBooking';
import myShipmentsReducer from './store/reducers/myShipments';
import batchBookingReducer from './store/reducers/batchBooking';
import invoiceFormTemplatesReducer from './store/reducers/invoiceFormTemplates';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      main: '#62485E',
    },
    secondary: {
      main: '#7b5d77',
      // main: '#7d4274',
      //TODO: change this to its own theme -> main:'#d1c3f0'
    },
    text: {
      primary: grey[800],
    },
  },
  status: {
    danger: '#a91e1e',
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiAppBar: {
      root: {
        borderRadius: '4px 4px 0 0',
        borderBottom: '1px solid #e0e0e0',
      },
      colorPrimary: {
        color: '#616161',
        backgroundColor: '#f5f5f5',
      },
    },
    MuiToolbar: {
      regular: {
        height: '40px',
        '@media (min-width: 600px)': {
          minHeight: '54px',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '3px',
      },
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#62485E',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '5px',
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: '0px 0px 0px 0px !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.8em',
        fontWeight: 400,
        color: 'white',
        // backgroundColor: '#62485E',
        backgroundColor: '#4c4c4c',
        // border: '1px solid #f5f5f5',
      },
    },
  },
});

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  booking: bookingReducer,
  requestBooking: requestBookingReducer,
  shipments: shipmentsReducer,
  trackedShipments: trackedShipmentsReducer,
  countries: countriesReducer,
  carriers: carriersReducer,
  rates: ratesReducer,
  packagesDetails: packagesDetailsReducer,
  events: eventsReducer,
  senderTemplates: senderCompanyReducer,
  recipientTemplates: recipientCompanyReducer,
  rootTemplates: rootTemplatesReducer,
  bookingPresets: bookingPresetsReducer,
  bookingStatus: bookingStatusReducer,
  packageTemplates: packageTemplatesReducer,
  productTemplates: productTemplatesReducer,
  userUpload: userUploadReducer,
  myShipments: myShipmentsReducer,
  batchBooking: batchBookingReducer,
  invoiceFormTemplates: invoiceFormTemplatesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

const app = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
