import axios from 'axios';
import * as actionTypes from './actionTypes';

const addProductTemplateSuccess = (addedProduct) => {
  return {
    type: actionTypes.ADD_PRODUCT_TEMPLATE_SUCCESS,
    payload: addedProduct,
  };
};

const addProductTemplateStart = () => {
  return {
    type: actionTypes.ADD_PRODUCT_TEMPLATE_START,
  };
};

const addProductTemplateFailed = (error) => {
  return {
    type: actionTypes.ADD_PRODUCT_TEMPLATE_FAILED,
    error,
  };
};

export const addProductTemplate = ({ productData, token }) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    dispatch(addProductTemplateStart());
    axios
      .post('/api/templates/addproduct', { productData }, config)
      .then((resp) => {
        dispatch(addProductTemplateSuccess(resp.data.response));
      })
      .catch((e) => addProductTemplateFailed(e));
  };
};

const fetchProductTemplateSuccess = (products) => {
  return {
    type: actionTypes.FETCH_PRODUCT_TEMPLATE_SUCCESS,
    products,
  };
};

const fetchProductTemplateStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_TEMPLATE_START,
  };
};
const fetchProductTemplateFailed = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_TEMPLATE_FAILED,
    error,
  };
};

export const fetchProductTemplate = ({ userId, token }) => {
  return (dispatch) => {
    dispatch(fetchProductTemplateStart());
    axios
      .get(`/api/templates/products/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        dispatch(fetchProductTemplateSuccess(resp.data.products));
      })
      .catch((e) => dispatch(fetchProductTemplateFailed(e)));
  };
};

const removeProductTemplateSuccess = (id) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_TEMPLATE_SUCCESS,
    removedId: id,
  };
};

const removeProductTemplateStart = () => {
  return {
    type: actionTypes.REMOVE_PRODUCT_TEMPLATE_START,
  };
};

const removeProductTemplateFailed = (error) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_TEMPLATE_FAILED,
    error,
  };
};

export const removeProductTemplate = ({ id, token }) => {
  return (dispatch) => {
    dispatch(removeProductTemplateStart());
    axios
      .delete(`/api/templates/removeproduct/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        dispatch(removeProductTemplateSuccess(resp.data.removedId));
      })
      .catch((e) => removeProductTemplateFailed(e));
  };
};
//UPDATE
const updateProductTemplateSuccess = ({ productId, productData }) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_TEMPLATE_SUCCESS,
    productId,
    productData: { ...productData, id: productId },
  };
};

const updateProductTemplateStart = () => {
  return {
    type: actionTypes.UPDATE_PRODUCT_TEMPLATE_START,
  };
};

const updateProductTemplateFailed = (error) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_TEMPLATE_FAILED,
    error,
  };
};

export const updateProductTemplate = ({ productData, productId, token }) => {
  return (dispatch) => {
    dispatch(updateProductTemplateStart());
    axios
      .put(
        '/api/templates/updateproduct',
        { productData, productId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        dispatch(
          updateProductTemplateSuccess({ productData: resp.data.updatedProduct, productId })
        );
      })
      .catch((e) => dispatch(updateProductTemplateFailed(e)));
  };
};
