import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/hrx-logotype.svg';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
});

export const HrxTrackingView = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.carrierHeader}>
        <p>{props.service}</p>
        <img src={Logotype} alt='HrxTrackingView Logotype' />
      </div>
    </>
  );
};

export default HrxTrackingView;
