import React from 'react';
import { NavLink } from 'react-router-dom'

//material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px',
    // width: '420px',
    height: '400px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gap: '5px',
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  listItem: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  buttonRipple: {
    color: '#BBABBB'
  },
  navLink: {
    textDecoration: 'none',
    color: '#5d4359',
  },
  linkContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      fontSize: '1rem',
      fontWeight: '300',
      textAlign: 'center'
    },
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
      color: '#4c3649'
    }
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      gap: '2px',
      padding: '5px',
      height: '300px',
    },
    linkContent: {
      '& h3': {
        fontSize: '0.9rem',
      },
    }
  }
}));

const AppMenu = (props) => {
  const classes = useStyles();
  const showLargeIcon = useMediaQuery('(min-width:600px');
  const resources = props.categoryItems
    .map(category => category.resources)
    .flat()
    .filter(item => item.id !== '/select');

  return (
    <List component='nav' className={classes.root}>
      {resources.map(resource => (
        <ListItem key={resource.id} button className={classes.listItem} TouchRippleProps={{ classes: { root: classes.buttonRipple } }}>
          <NavLink
            className={classes.navLink} to={resource.path} ><span className={classes.linkContent}>{showLargeIcon ? resource.iconLarge : resource.icon}<h3> {resource.name}</h3></span></NavLink>
        </ListItem>
      ))}
    </List>
  )
}

export default AppMenu
