import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import mapStyles from '../../../services/geo-map/styles/blueWaterStyle';
import NumberMapMarker from '../MapMarkers/NumberMapMarker';

export default function NumberMap({ locations }) {
  const [mapLocations, setMapLocations] = useState([]);

  const center = {
    lat: 30.5,
    lng: 29.2,
  };
  //TODO: Dynamically set senter
  // const [center, setCenter] = useState({
  // 	lat: 30.5,
  // 	lng: 29.2
  // });

  const handleApiLoaded = (map, maps) => {
    //let addedLocations = [];
    let geocoder = new maps.Geocoder();
    let locs = [];

    locations.forEach((location, idx) => {
      location &&
        geocoder.geocode(
          { address: location },

          function (results, status) {
            if (status === 'OK') {
              locs.push({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });

              if (locs.length === locations.length) {
                setMapLocations(locs);
              }
              //Center from first package in array
              if (idx === 0) {
                map.setZoom(13);
                map.setCenter(locs[0]);
              }
            } else {
              console.log('Geocode was not successful for the following reason:', status);
            }
          }
        );
    });
  };

  const mapOptions = { styles: mapStyles };
  return (
    <div
      style={{
        background: 'whitesmoke',
        boxShadow: '0px -1px 4px #969494bf',
        width: '100%',
        height: '100%',
      }}
    >
      {Array.isArray(locations) && locations.length > 0 ? (
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAhwPG-cPtsOvGt0b04nIBP5VTvMMIzDuk' }}
            defaultCenter={center}
            defaultZoom={1}
            options={mapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {mapLocations.map((loc, idx) => (
              <NumberMapMarker key={idx} lat={loc.lat} lng={loc.lng} text={idx + 1} />
            ))}
          </GoogleMapReact>
        </div>
      ) : (
        <div style={{ textAlign: 'center', color: '#9e464c' }}>
          <p>Unable to show map</p>
        </div>
      )}
    </div>
  );
}
