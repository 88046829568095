import {
  flowValidationSchema,
  dateValidationSchema,
  transportModeValidationSchema,
  requestBookingAddressSchema,
  packagesSchema,
  requestBookingShipmentDetailsSchema,
  stackableValidationSchema,
} from '../schemas';

export const VerifyRequestBookingSteps = async ({ activeStep, validationData, lang = 'en' }) => {
  const defaults = {
    valid: true,
    errors: null,
  };

  const options = {
    abortEarly: false,
  };

  switch (activeStep) {
    case 0:
      const { selectedDate, flow } = validationData;
      const dateValidation = dateValidationSchema(lang).validate(selectedDate, options);
      const flowValidation = flowValidationSchema(lang).validate(flow, options);

      const flowAndDateValidation = await Promise.all([dateValidation, flowValidation])
        .then((_) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch((err) => {
          return {
            valid: false,
            errors: [err.errors[0]],
            variant: 'error',
          };
        });
      return flowAndDateValidation;

    case 1:
      const transportModeValidation = await transportModeValidationSchema(lang)
        .validate(validationData.mode, options)
        .then((valid) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
          };
        });

      return transportModeValidation;
    case 2:
      const { sender, recipient } = validationData;
      const senderValidation = requestBookingAddressSchema(lang).validate(sender, options);
      const recipientValidation = requestBookingAddressSchema(lang).validate(recipient, options);
      const addressesValidation = await Promise.all([senderValidation, recipientValidation])
        .then((_) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch((err) => {
          return {
            valid: false,
            errors: [err.errors[0]],
            variant: 'error',
          };
        });
      return addressesValidation;

    case 3:
      const { stackable, packages } = validationData;
      const stackableValidation = stackableValidationSchema(lang).validate(stackable, options);
      const packagesValidation = packagesSchema(lang).validate(packages, options);

      const packageDetailsValiation = await Promise.all([packagesValidation, stackableValidation])
        .then((valid) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
          };
        });

      return packageDetailsValiation;

    case 4:
      const { references } = validationData;
      const shipmentDetailsValidation = await requestBookingShipmentDetailsSchema(lang)
        .validate({ references }, options)
        .then((valid) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
          };
        });

      return shipmentDetailsValidation;
    default:
      return defaults;
  }
};
