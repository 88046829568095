import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import translations from '../../../../../services/translations/translations';
import Logotype from '../../../../../assets/Expeditors_International_logo.svg';
import { useSelector } from 'react-redux';
import { buildExpeditorsPackagesFromTracking } from '../../TrackingResponseBuilder';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';
import Axios from 'axios';
import { serializeExpeditorsShipmentDetails } from '../../../../../services/tracking';
import { HorizontalSpinner } from '../../../../UI/Spinners/Horizontal';
import { CARRIER_NAMES } from '../../../../../services/types';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  loading: {
    display: 'grid',
    height: '100px',
    placeItems: 'center',
    boxShadow: '0 4px 2px -2px #c7c7c7',
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    gridTemplateColumns: '1fr 1fr',
    '& > div': {
      display: 'flex',
      '& p:first-child': {
        width: '170px',
        fontWeight: 300,
      },
    },
  },

  '@media (max-width:830px)': {
    responseHeader: {
      gridTemplateColumns: '1fr',
    },
  },
});

export default function ExpeditorsTrackingView({ trackingState }) {
  const [packages, setPackages] = useState([]);

  const [detailsState, setDetailsState] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  useEffect(() => {
    setPackages(() =>
      buildExpeditorsPackagesFromTracking(trackingState.result.trackedPackagesResult, lang)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);
  useEffect(() => {
    setDetailsLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    Axios.post(
      '/api/tracking/shipment-details',
      { carrier: trackingState.carrier, trackingValue: trackingState.trackingNumber },
      config
    )
      .then((resp) => {
        const shipmentDetails = serializeExpeditorsShipmentDetails(resp.data.results);

        if (shipmentDetails) {
          setDetailsState(shipmentDetails);
        }
        setDetailsLoading(false);
      })
      .catch((_) => {
        setDetailsLoading(false);
        detailsState && setDetailsState(null);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <>
      <div className={classes.carrierHeader}>
        <p>
          {detailsState && detailsState.transportationMode
            ? `${CARRIER_NAMES[trackingState.carrier]} ${detailsState.transportationMode}`
            : `${CARRIER_NAMES[trackingState.carrier]}`}
        </p>
        <img src={Logotype} alt="Expeditors Logotype" />
      </div>
      {detailsLoading && (
        <div className={classes.loading}>
          <HorizontalSpinner />
        </div>
      )}
      {!detailsLoading && detailsState && (
        <section className={classes.responseHeader}>
          <div>
            <p>Tracking number:&nbsp;</p>
            <p>{detailsState.trackingNumber}</p>
          </div>
          <div>
            <p>Status:&nbsp;</p>
            <p>{detailsState.status}</p>
          </div>
          <div>
            <p>Last update time:&nbsp;</p>
            <p>{detailsState.lastUpdateTime}</p>
          </div>
          <div>
            <p>Transportation mode:&nbsp;</p>
            <p>{detailsState.transportationMode}</p>
          </div>
          <div>
            <p>Service type:&nbsp;</p>
            <p>{detailsState.serviceType}</p>
          </div>
          <div>
            <p>Goods description:&nbsp;</p>
            <p>{detailsState.description}</p>
          </div>
          <div>
            <p>Quantity:&nbsp;</p>
            <p>{detailsState.quantity}</p>
          </div>
          <div>
            <p>Weight:&nbsp;</p>
            <p>{detailsState.weight}</p>
          </div>
          <div>
            <p>Volume:&nbsp;</p>
            <p>{detailsState.volume}</p>
          </div>
          <div>
            <p>Shipper:&nbsp;</p>
            <p>{detailsState.shipper}</p>
          </div>
          <div>
            <p>Consignee:&nbsp;</p>
            <p>{detailsState.consignee}</p>
          </div>
          <div>
            <p>Origin name:&nbsp;</p>
            <p>{detailsState.originName}</p>
          </div>
          <div>
            <p>Origin code:&nbsp;</p>
            <p>{detailsState.originCode}</p>
          </div>
          <div>
            <p>Destination name:&nbsp;</p>
            <p>{detailsState.destinationName}</p>
          </div>
          <div>
            <p>Destination code:&nbsp;</p>
            <p>{detailsState.destinationCode}</p>
          </div>
          <div>
            <p>Flight:&nbsp;</p>
            <p>{detailsState.flight}</p>
          </div>
          <div>
            <p>Flight carrier:&nbsp;</p>
            <p>{detailsState.flightCarrier}</p>
          </div>
        </section>
      )}
      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
    </>
  );
}
