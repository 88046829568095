import * as actionTypes from '../actions/actionTypes';

const initialState = {
	loading: false,
	trackingData: null
}

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.FETCH_TRACKED_SHIPMENTS_SUCCESS:
			return {
				...state,
				trackingData: action.shipments,
				loading: false
			}
		case actionTypes.FETCH_TRACKED_SHIPMENTS_START:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default reducer;
