import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import translations from '../../services/translations/translations';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  templateNameTextField: {
    padding: '13px',
  },
  templateNameLabel: {
    transform: 'translate(14px, -6px) scale(0.75)',
    transformOrigin: 'top left',
    padding: '0 2px',
    background: '#ffffff',
  },
});

const FavouriteTextField = ({ onChange, value, label = 'Name template' }) => {
  const classes = useStyles();
  const { defaultLanguage: lang } = useSelector((state) => state.rootTemplates);

  return (
    <TextField
      inputProps={{
        maxLength: 35,
        autoComplete: 'new-password',
        className: classes.templateNameTextField,
        type: 'search',
      }}
      InputLabelProps={{
        className: classes.templateNameLabel,
      }}
      value={value}
      label={translations[lang].templates.nametemplate}
      name="templateName"
      onChange={onChange}
      variant="outlined"
      className={classes.root}
    />
  );
};

export default FavouriteTextField;
