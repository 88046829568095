import React, { useMemo, useState } from 'react';
import ApplyInvoiceIcon from '@material-ui/icons/PostAdd';
import ThermalPrinterIcon from '../../UI/Icons/ThermalPrinter';
import EditIcon from '@material-ui/icons/Edit';
import EnhancedTableExtended from '../../Tables/EnhancedTableExtended/EnhancedTableExtended';
import { useEffect } from 'react';
import { CARRIER_NAMES } from '../../../services/types';
import translationsObj from '../../../services/translations/translations.json';
import translationsTooltipsObj from '../../../services/translations/translationsTooltips';
import {
  CreateBookedStatusIcon,
  CreateInvoiceStatusIcon,
  CreatePrintedStatusIcon,
} from '../../Tables/CustomizedCells';
import FileBookingPrintModal from './FileBookingPrintModal/FileBookingPrintModal';
import FileBookingInvoiceModal from './FileBookingInvoiceModal/FileBookingInvoiceModal';
import ModifyShipmentModal from './ModifyShipmentModal/ModifyShipmentModal';
import { useSelector } from 'react-redux';

const createRowsData = (tableData) => {
  return tableData.map(
    ({
      id,
      company,
      countryCode,
      totalWeight,
      serviceLevelName,
      deliveryMethodName,
      carrierAlias,
      invoiceStatus,
      bookedStatus,
      printStatus,
      printFormat,
    }) => ({
      id,
      company,
      countryCode,
      totalWeight,
      serviceLevelName,
      deliveryMethodName,
      carrierAlias,
      invoiceStatus,
      bookedStatus,
      printStatus,
      printFormat,
    })
  );
};

const initialPrintModalState = {
  open: false,
  printShipments: [],
};
const initialInvoiceModalState = {
  open: false,
  invoiceAddShipments: [],
};

const initialModifyShipmentModalState = {
  open: false,
  shipment: null,
};

export default function FileBookingShipmentTable({
  tableData,
  errors,
  handleProcessBooking,
  successBookingsByFormat,
  actionByFormat,
  handleUpdateShipmentData,
  handleSetInvoiceData,
  loading,
}) {
  const [rows, setRows] = useState([]);
  const [fileBookingPrintModalState, setFileBookingPrintModalState] =
    useState(initialPrintModalState);
  const [fileBookingInvoiceModalState, setFileBookingInvoiceModalState] =
    useState(initialInvoiceModalState);
  const [modifyShipmentModalState, setModifyShipmentModalState] = useState(
    initialModifyShipmentModalState
  );

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translations = translationsObj[lang];
  const translationsTooltips = translationsTooltipsObj[lang];

  useEffect(() => {
    setRows(createRowsData(tableData));
  }, [tableData]);

  const headCells = [
    {
      id: 'company',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Name',
    },
    {
      id: 'countryCode',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Country',
    },
    {
      id: 'totalWeight',
      numeric: true,
      disablePadding: true,
      align: 'left',
      label: 'Weight',
    },
    {
      id: 'serviceLevelName',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Service',
    },
    {
      id: 'deliveryMethodName',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Delivery method',
    },
    {
      id: 'carrierAlias',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Carrier',
      customRowCells: (carrierAlias) => CARRIER_NAMES[carrierAlias],
    },
    {
      id: 'invoiceStatus',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Invoice',
      customRowCells: (bookedStatus) => CreateInvoiceStatusIcon(bookedStatus, 'en'),
    },
    {
      id: 'bookedStatus',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Booked status',
      customRowCells: (bookedStatus, id) => {
        let error = '';
        if (errors.length > 0) {
          //Find error by id
          const errorObj = errors.find((err) => err.id === id);

          if (errorObj && Array.isArray(errorObj.errors)) {
            error = errorObj.errors.join(',');
          }
        }

        return CreateBookedStatusIcon(bookedStatus, error, translations);
      },
    },
    {
      id: 'printStatus',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: 'Printed',
      customRowCells: (printStatus, id) => {
        // Get printFormat from rows
        const printFormat = rows.find((row) => row.id === id).printFormat;

        return CreatePrintedStatusIcon(printStatus, printFormat, translations);
      },
    },
  ];

  const successBookedStatusIds = useMemo(() => {
    return rows.filter((row) => row.bookedStatus === 'success').map((row) => row.id);
  }, [rows]);

  const processShipments = (ids) => {
    handleProcessBooking(ids);
  };

  const applyInvoice = (ids) => {
    setFileBookingInvoiceModalState({
      open: true,
      invoiceAddShipments: ids,
    });
  };
  const modifyShipment = (id) => {
    if (!Array.isArray(id) && id.length > 0) return;

    const shipment = tableData.find((shipment) => shipment.id === id[0]);
    setModifyShipmentModalState({
      open: true,
      shipment,
    });
  };

  const printShipments = (ids) => {
    // Filter selected shipments from successBookingsByFormat
    const selectedSuccessBookings = Object.keys(successBookingsByFormat).reduce((acc, format) => {
      const selectedShipments = successBookingsByFormat[format].filter((shipment) =>
        ids.includes(shipment.id)
      );
      if (selectedShipments.length > 0) {
        acc[format] = selectedShipments;
      }
      return acc;
    }, {});

    if (Object.keys(selectedSuccessBookings).length === 0) {
      alert('No shipments to print');
      return;
    }

    // Check that all selected shipments are included in selectedSuccessBookings
    const allSelectedShipmentsIncluded = ids.every((id) =>
      Object.keys(selectedSuccessBookings).some((format) =>
        selectedSuccessBookings[format].some((shipment) => shipment.id === id)
      )
    );

    if (!allSelectedShipmentsIncluded) {
      alert('Not all selected shipments can be printed');
      return;
    }

    setFileBookingPrintModalState({ open: true, printShipments: selectedSuccessBookings });
  };

  const toolbarProps = [
    {
      id: 'printShipments',
      activateForIds: successBookedStatusIds,
      allowMultiple: true,
      funcName: 'printShipments',
      icon: <ThermalPrinterIcon style={{ fontSize: '28px', transform: 'translate(4px,3px)' }} />,
      toolTip: translations.printSelectedShipments,
    },
    {
      id: 'applyInvoice',
      activateForIds: null,
      allowMultiple: true,
      funcName: 'applyInvoice',
      icon: <ApplyInvoiceIcon />,
      toolTip: translations.applyInvoice,
    },
    {
      id: 'modifyShipment',
      activateForIds: null,
      allowMultiple: false,
      funcName: 'modifyShipment',
      icon: <EditIcon />,
      toolTip: translations.modifyShipment,
    },
  ];

  const toolbarFeatures = [
    {
      funcName: 'processShipments',
      title: 'Process shipments',
      toolTip: translationsTooltips.toggleAutofillAutocomplete,
    },
  ];

  const actions = {
    processShipments,
    applyInvoice,
    printShipments,
    modifyShipment,
  };

  const modals = useMemo(
    () => [
      <FileBookingPrintModal
        id="fileBookingPrintModal"
        open={fileBookingPrintModalState.open}
        printShipments={fileBookingPrintModalState.printShipments}
        translations={translations}
        handleAbort={() => setFileBookingPrintModalState(initialPrintModalState)}
        actionByFormat={actionByFormat}
      />,
      <FileBookingInvoiceModal
        id="fileBookingInvoiceModal"
        open={fileBookingInvoiceModalState.open}
        invoiceAddShipments={fileBookingInvoiceModalState.invoiceAddShipments}
        translations={translations}
        handleAbort={() => setFileBookingInvoiceModalState(initialInvoiceModalState)}
        handleSetInvoiceData={handleSetInvoiceData}
      />,
      <ModifyShipmentModal
        id="modifyShipmentModal"
        open={modifyShipmentModalState.open}
        shipment={modifyShipmentModalState.shipment}
        translations={translations}
        handleAbort={() => setModifyShipmentModalState(initialModifyShipmentModalState)}
        handleUpdateShipmentData={handleUpdateShipmentData}
      />,
    ],
    [
      fileBookingPrintModalState,
      fileBookingInvoiceModalState,
      modifyShipmentModalState,
      handleUpdateShipmentData,
      translations,
      actionByFormat,
      handleSetInvoiceData,
    ]
  );

  return (
    <div>
      <EnhancedTableExtended
        rows={rows}
        headCells={headCells}
        sortByKey={'id'}
        title={'Bookings'}
        selectedTitle={translations.templates.selected}
        endAdornmentFields={null}
        toolbarProps={toolbarProps}
        toolbarFeatures={toolbarFeatures}
        hiddenFields={['id', 'printFormat']}
        loading={loading}
        modals={modals}
        {...actions}
      />
    </div>
  );
}
