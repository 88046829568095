import {
  ADD_BOOKING_STATUS,
  REMOVE_BOOKING_STATUS,
  RESET_BOOKING_STATUS,
  RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY,
} from '../actions/actionTypes';

const initialState = {
  messages: [], // {text:string, variant: 'info' | 'warning' | 'error', id: number, priority: 'low' | 'medium' | 'high'}
  loading: false,
};

const bookingStatus = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKING_STATUS: {
      // If the same id exists in messages, remove it
      const messagesWithoutId = state.messages.filter(
        (message) => message.id !== action.payload.id
      );

      // If message is empty, return
      if (!action.payload.text) return { ...state, messages: messagesWithoutId };

      // Add booking status to messages, sort by priority
      const messages = [...messagesWithoutId, action.payload];
      messages.sort((a, b) => {
        if (a.priority === 'high' && b.priority !== 'high') return -1;
        if (a.priority === 'medium' && b.priority === 'low') return -1;
        if (a.priority === 'low' && b.priority === 'low') return 1;
        return 0;
      });
      return { ...state, messages };
    }
    case REMOVE_BOOKING_STATUS: {
      // action.payload = id: number
      const messages = state.messages.filter((message) => message.id !== action.payload);

      return { ...state, messages };
    }
    case RESET_BOOKING_STATUS: {
      return { ...state, messages: [] };
    }
    case RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY: {
      const messages = state.messages.filter((message) => message.priority === 'high');
      return { ...state, messages };
    }
    default:
      return state;
  }
};

export default bookingStatus;
