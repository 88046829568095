import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import FileZoneUpload from '../../../components/FileZoneUpload/FileZoneUpload';
import Select from 'react-select';
import { SelectStylesMain } from '../../../components/UI/SelectStyles/SelectStyles';
import ThermalPrinterIcon from '../../../components/UI/Icons/ThermalPrinter';
import { CircleSpinnerLarge as CircularProgress } from '../../../components/UI/Spinners/Circle';
import translations from '../../../services/translations/translations.json';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from '@material-ui/core';
import Axios from 'axios';
import FileUploadRules from '../../../components/FileUploadBooking/FileUploadRules/FileUploadRules';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import GetFileIcon from '@material-ui/icons/GetApp';
import { getCarrierAliasesFromAllCarriers } from '../../../services/helpers/carriers';
import {
  clearBatchBookingData,
  fetchBatchBookingRules,
  fetchCarriers,
  fetchSenderCompaniesTemplates,
  processBatchBooking,
  processBatchBookingFile,
  removeBatchBookingCountryRule,
  updateBatchBookingDataWhenPrinted,
  updateBatchBookingRules,
  updateInvoiceDataForBatchBooking,
  updateSenderTemplateForBooking,
} from '../../../store/actions';
import FileBookingShipmentTable from '../../../components/FileUploadBooking/FileBookingShipmentTable/FileBookingShipmentTable';
import FavouriteCompanies from '../../../components/Bookings/Templates/FavouriteCompanies';
import { grey, indigo } from '@material-ui/core/colors';
import FileBookingShipmentHandler from '../../../components/FileUploadBooking/FileBookingShipmentHandler/FileBookingShipmentHandler';
import { printPdfData } from '../../../services/print';
import { ThermalPrint } from '../../../components/Documents/Labels/LabelBuilders';
import { updateBatchBookingDataShipment } from '../../../store/actions/batchBooking';
import { LABEL_SHORT_NAME_TYPE } from '../../../services/types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& > h2': {
      color: '#424242',
      height: 'fit-content',
      fontWeight: '300',
      fontSize: '2rem',
      padding: '10px 5px',
    },
    '& > p': {
      fontWeight: '300',
      fontSize: '1rem',
      paddingBottom: '5px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    fontSize: '0.95rem',
    fontWeight: '500',
  },
  rulesContainer: {
    width: '610px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  shipperSelect: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '15px',
    '& > label': {
      marginBottom: '5px',
    },
  },
  fileUploadSection: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
  },
  rulesButton: {
    padding: '3px',
    color: indigo[800],
  },
  fileUploadItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.1rem',
  },
  fileVersionSelectionContainer: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'end',
  },
  getTemplateLink: {
    width: 'max-content',
    color: indigo[800],
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  processFileButton: {
    width: '100%',
  },
  loadingContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '900px',
  },
});

// Only one file type version is allowed for now
const fileUploadTypes = [
  { id: 'v1', label: 'version 1', accept: '.xlsx' },
  // {
  //   id: 'v2',
  //   label: 'version 2',
  //   accept: '.xls',
  // },
];

const fileVersionSelectOptions = fileUploadTypes.map((fileVersion) => {
  return {
    label: fileVersion.label,
    value: fileVersion.id,
  };
});

export default function FileUploadBooking() {
  const [onlineBookingCarriers, setOnlineBookingCarriers] = useState([]);
  const [baseRules, setBaseRules] = useState(null);
  const [countryRules, setCountryRules] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]); //format: {shipmentIds:['1','2',..], invoiceData: {invoiceNumber: '123', ...},type: 'form'}

  const [showRules, setShowRules] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFileVersion, setSelectedFileVersion] = useState(fileVersionSelectOptions[0].value);
  const { token } = useSelector((state) => state.auth);
  const { companyId, userId, firstname, lastname } = useSelector((state) => state.user);
  const carriers = useSelector((state) => state.carriers);
  const templates = useSelector((state) => state.senderTemplates.templates);
  const {
    senderTemplate,
    batchBookingBaseRules,
    batchBookingCountryRules,
    batchBookingData,
    batchBookingProcessedErrors,
    batchBookingProcessedShipments,
    processBatchBookingLoading,
    processBatchFileLoading,
  } = useSelector((state) => state.batchBooking);
  const senderTemplateId = useSelector((state) => state.bookingPresets.defaultSender);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (templates.length < 1) {
      dispatch(fetchSenderCompaniesTemplates({ userId, token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.length > 0 && !senderTemplate && senderTemplateId) {
      const senderDefault = templates.filter((template) => senderTemplateId === template.id);

      if (senderDefault.length > 0) {
        dispatch(
          updateSenderTemplateForBooking({
            templateId: senderDefault[0].id || null,
            company: senderDefault[0].company || '',
            phone:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].phone
                : senderDefault[0].phone || '',
            email:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].email
                : senderDefault[0].email || '',
            contact:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].name
                : `${firstname} ${lastname}`,
            city: senderDefault[0].city || '',
            zip: senderDefault[0].zip || '',
            address1: senderDefault[0].address1 || '',
            address2: senderDefault[0].address2 || '',
            countryCode: senderDefault[0].countryCode || '',
            stateRegion: senderDefault[0].stateRegion || '',
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  useEffect(() => {
    if (carriers.allCarriers.length < 1) {
      dispatch(fetchCarriers(token, companyId));
    }
    if (onlineBookingCarriers.length === 0 && carriers.allCarriers.length > 0) {
      const { onlineCarriers, allCarriers } = carriers;
      const onlineBookingCarrierAliases = getCarrierAliasesFromAllCarriers(
        onlineCarriers,
        allCarriers
      );

      setOnlineBookingCarriers(onlineBookingCarrierAliases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers.allCarriers]);

  // Fetch batch booking rules if online carriers are available
  useEffect(() => {
    if (onlineBookingCarriers.length > 0) {
      dispatch(fetchBatchBookingRules(onlineBookingCarriers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineBookingCarriers]);

  // Set base rules if batch booking rules are available
  useEffect(() => {
    console.log('batchBookingBaseRules', batchBookingBaseRules);
    console.log('baseRules', baseRules);
    if (!baseRules && batchBookingBaseRules) {
      setBaseRules(batchBookingBaseRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchBookingBaseRules]);

  //Update country rules if changed
  useEffect(() => {
    if (batchBookingCountryRules.length !== countryRules.length) {
      setCountryRules(batchBookingCountryRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchBookingCountryRules]);

  // Clear batch booking data on unmount
  useEffect(() => {
    return () => {
      dispatch(clearBatchBookingData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateFiles = (fileItems) => {
    const newFilesArray = fileItems.map((fileItem) => fileItem.file);
    setFiles(newFilesArray);
  };

  function handleFileVersionChange(selectedOption) {
    setSelectedFileVersion(selectedOption.value);
  }

  const handleSaveRules = () => {
    const rules = {
      baseRules,
      countryRules,
    };

    dispatch(updateBatchBookingRules(rules, companyId, token));
  };

  const handleRemoveCountryRule = (ruleId) => {
    dispatch(removeBatchBookingCountryRule(ruleId));
  };

  const handleSenderTemplateSelected = (company) => {
    dispatch(
      updateSenderTemplateForBooking({
        templateId: company.id,
        company: company.company || '',
        phone: company.contacts.length > 0 ? company.contacts[0].phone : company.phone || '',
        email: company.contacts.length > 0 ? company.contacts[0].email : company.email || '',
        contact: company.contacts.length > 0 ? company.contacts[0].name : '',
        city: company.city || '',
        zip: company.zip || '',
        address1: company.address1 || '',
        address2: company.address2 || '',
        countryName: company.countryName || '',
        countryCode: company.countryCode || '',
        stateRegion: company.stateRegion || '',
      })
    );
  };

  const getTemplateSelectedName = () => {
    if (!senderTemplate || !senderTemplate.templateId) return;
    const { templateName } = templates.find(({ id }) => id === senderTemplate.templateId);
    return templateName;
  };

  const handleProcessFile = () => {
    dispatch(
      processBatchBookingFile({
        file: files[0],
        selectedFileVersion,
        baseRules,
        countryRules,
      })
    );
  };

  const handleProcessBooking = (ids) => {
    const shipments = batchBookingData.filter((shipment) => ids.includes(shipment.id));

    // Validate that no selected row has previously been processed
    const hasProcessedShipments = shipments.some((shipment) => shipment.bookedStatus === 'success');
    if (hasProcessedShipments) {
      alert('Cannot process a shipment that has already been processed');
      return;
    }

    const sender = Object.assign({}, senderTemplate);
    delete sender.templateId;

    dispatch(processBatchBooking(shipments, sender, invoiceData));
  };

  // Get all success bookings by format
  const successBookingsByFormat = useMemo(() => {
    const successBookingsByFormat = {};
    batchBookingProcessedShipments.forEach((successBooking) => {
      if (!successBookingsByFormat[successBooking.format]) {
        successBookingsByFormat[successBooking.format] = [];
      }
      successBookingsByFormat[successBooking.format].push(successBooking);
    });
    return successBookingsByFormat;
  }, [batchBookingProcessedShipments]);

  const printMergedPdfs = (labels) => {
    Axios.post(
      '/api/batch-booking/merge-pdf-labels',
      { labels },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        if (res.data.mergedLabel && res.data.mergedLabel.length > 0) {
          printPdfData(res.data.mergedLabel);
          dispatch(updateBatchBookingDataWhenPrinted(res.data.mergedLabelIds));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdatePrintedZpl = (labelArray) => {
    const ids = labelArray.map((label) => label.id);
    dispatch(updateBatchBookingDataWhenPrinted(ids));
  };

  const actionByFormat = (format, labelArray) => {
    if (!Object.keys(LABEL_SHORT_NAME_TYPE).includes(format))
      return <p style={{ color: 'red' }}>{translations[lang].invalidPrintFormat}</p>;

    if (format.substring(0, 3) === 'PDF') {
      return (
        <Button
          key={format}
          onClick={() => printMergedPdfs(labelArray)}
          color="primary"
          variant="outlined"
          style={{ width: 'fit-content' }}
        >
          {LABEL_SHORT_NAME_TYPE[format]}
        </Button>
      );
    }
    if (format.substring(0, 3) === 'ZPL') {
      return (
        <ThermalPrint
          key={format}
          packages={labelArray}
          updatePrinted={() => handleUpdatePrintedZpl(labelArray)}
        >
          <Button
            color="primary"
            variant="outlined"
            // style={{ marginTop: '10px', width: '100%' }}
          >
            ZPL 4x6
            <ThermalPrinterIcon
              style={{
                fontSize: '2rem',
                paddingLeft: '10px',
                transform: 'translateY(4px)',
              }}
            />
          </Button>
        </ThermalPrint>
      );
    }
    return <p style={{ color: 'red' }}>{translations[lang].invalidPrintFormat}</p>;
  };

  const handleSetInvoiceData = (newInvoiceData) => {
    // shipmentIds in newInvoiceData should not be included in invoiceData state
    const newInvoiceDataState = invoiceData.filter((invoice) => {
      const shipmentIds = invoice.shipmentIds;
      return !shipmentIds.some((id) => newInvoiceData.shipmentIds.includes(id));
    });
    newInvoiceDataState.push(newInvoiceData);
    setInvoiceData(newInvoiceDataState);

    // Update invoiceType and invoiceStatus in batchBookingData
    dispatch(
      updateInvoiceDataForBatchBooking({
        shipmentIds: newInvoiceData.shipmentIds,
        invoiceStatus: newInvoiceData.type,
      })
    );
  };

  const handleUpdateShipmentData = (shipment) => {
    dispatch(updateBatchBookingDataShipment(shipment));
  };

  const handleDownloadBatchTemplateFile = () => {
    const fileType = fileUploadTypes.find((type) => type.id === selectedFileVersion);
    const lang = 'en';

    // Filename example: batch-template-v1-en.xlsx
    const fileName = `batch-template-${fileType.id}-${lang}${fileType.accept}`;
    // Url for public s3 bucket
    const url = `https://pagoda-public.s3.eu-north-1.amazonaws.com/batch-templates/${fileName}`;

    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2>{translations[lang].processBatchBooking}</h2>
      </div>

      <div className={classes.rulesContainer}>
        <div className={classes.shipperSelect}>
          <label className={classes.title}>{translations[lang].selectSender}</label>
          <FavouriteCompanies
            templateSelected={handleSenderTemplateSelected}
            options={templates}
            styles={classes.templateSelect}
            label={translations[lang].onlinebooking.buttons.templates}
            defaultValue={getTemplateSelectedName()}
          />
        </div>
        {error && <p>{error}</p>}
        {!error && baseRules && (
          <div className={classes.fileUploadSection}>
            <div className={classes.fileUploadItem}>
              <label className={classes.title}>{translations[lang].fileVersion}</label>
              <div className={classes.fileVersionSelectionContainer}>
                <div style={{ width: '100%' }}>
                  <Select
                    value={fileVersionSelectOptions.find(
                      (option) => option.value === selectedFileVersion
                    )}
                    onChange={handleFileVersionChange}
                    options={fileVersionSelectOptions}
                    //
                    styles={{
                      ...SelectStylesMain,
                      singleValue: (provided) => ({ ...provided, color: indigo[800] }),
                    }}
                    menuPlacement="auto"
                  />
                </div>
                <div>
                  <Tooltip title={translations[lang].getTemplate}>
                    <GetFileIcon
                      className={classes.getTemplateLink}
                      onClick={() => handleDownloadBatchTemplateFile()}
                      style={{ color: indigo[600] }}
                    />
                  </Tooltip>
                  {/* <p
                    className={classes.getTemplateLink}
                    onClick={() => handleDownloadBatchTemplateFile()}
                  >
                    {translations[lang].getTemplate}
                  </p> */}
                </div>
              </div>
              <Button
                className={classes.rulesButton}
                variant="outlined"
                onClick={() => setShowRules(!showRules)}
              >
                {translations[lang].showRules}
                <span style={{ transform: 'translateY(4px)' }}>
                  {showRules ? <UpArrow /> : <DownArrow />}
                </span>
              </Button>
            </div>
            <div className={classes.fileUploadItem}>
              <label className={classes.title}>{translations[lang].addBatchBookingFile}</label>
              <FileZoneUpload
                title={translations[lang].dragdropbrowse}
                updateFiles={handleUpdateFiles}
                files={files}
                width="300px"
                maxFiles={1}
              />
            </div>
          </div>
        )}

        {showRules && (
          <div className={classes.rulesContainer}>
            <FileUploadRules
              fileVersion={selectedFileVersion}
              carriers={onlineBookingCarriers}
              handleSaveRules={handleSaveRules}
              setBaseRules={setBaseRules}
              baseRules={baseRules}
              setCountryRules={setCountryRules}
              countryRules={countryRules}
              handleRemoveCountryRule={handleRemoveCountryRule}
            />
          </div>
        )}

        <Button
          className={classes.processFileButton}
          variant="contained"
          color="primary"
          onClick={handleProcessFile}
          disabled={files.length !== 1}
        >
          {translations[lang].processFile}
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <FileBookingShipmentHandler
          loading={processBatchBookingLoading || processBatchFileLoading}
          successBookings={batchBookingProcessedShipments}
          successBookingsByFormat={successBookingsByFormat}
          errorBookings={batchBookingProcessedErrors}
          // printMergedPdfs={printMergedPdfs}
          translations={translations[lang]}
          actionByFormat={actionByFormat}
        />
        {processBatchFileLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!processBatchFileLoading && batchBookingData.length > 0 && (
          <FileBookingShipmentTable
            tableData={batchBookingData}
            translations={translations[lang]}
            handleProcessBooking={handleProcessBooking}
            handleUpdateShipmentData={handleUpdateShipmentData}
            loading={processBatchBookingLoading}
            errors={batchBookingProcessedErrors}
            successBookingsByFormat={successBookingsByFormat}
            actionByFormat={actionByFormat}
            handleSetInvoiceData={handleSetInvoiceData}
          />
        )}
      </div>
    </div>
  );
}
