import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button, TextField } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  arrows: {
    border: `1px solid #d2d2d2`,
    width: '28px',
    height: '28px',
    padding: '2px',
    borderRadius: '2px',
    color: 'black',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    '&:active': {
      backgroundColor: darken(theme.palette.primary.main, 0.1),
      color: 'white',
    },
  },
  inputLabel: {
    fontSize: '0.75rem',
    color: '#4e4e4e',
    textAlign: 'center',
  },
  textField: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderRadius: 0,
    textAlign: 'center',
    fontFamily: 'Roboto ,Open Sans ,sans-serif',
    fontSize: '1rem',
    width: 52,
    color: 'black',
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  input: {
    height: 24,
    textAlign: 'center',
  },
  '@media (max-width:400px)': {
    buttonGroup: {
      '& > *': {
        width: 40,
      },
    },
  },
}));

const NumberSpinner = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.inputLabel}>{props.title}</p>
      <div className={classes.container}>
        <ButtonGroup className={classes.buttonGroup}>
          <Button aria-label='reduce' onClick={props.downClick}>
            <RemoveIcon fontSize='small' />
          </Button>
          <TextField
            onBlur={props.handleNumberBlur}
            onChange={props.handleNumberChange}
            onClick={() => document.execCommand('selectall', null, false)}
            value={props.count || ''}
            className={classes.textField}
            inputProps={{ className: classes.input }}
            type='text'
            name='amount'
            id='amount'
          />
          <Button aria-label='increase' onClick={props.upClick}>
            <AddIcon fontSize='small' />
          </Button>
        </ButtonGroup>

        {/* <RemoveIcon onClick={props.downClick} className={classes.arrows} />
        <input onBlur={props.handleNumberBlur} onChange={props.handleNumberChange} value={props.count} className={classes.textField} type="text" name="amount" id="amount" />
        <AddIcon onClick={props.upClick} className={classes.arrows} /> */}
      </div>
    </div>
  );
};

export default NumberSpinner;
