import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { CARRIER_NAMES } from '../../../services/types';
import clsx from 'clsx';
import { getCarrierIcon } from '../../../utils/carrier';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content',
    gridGap: '5px',
    placeItems: 'start',
  },
  button: {
    maxWidth: '220px',
    height: '40px',
    width: '100%',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    backgroundColor: '#e8e8e8',
  },
  icon: {
    height: 25,
    width: 45,
  },
});

const SpecificCarriers = ({ carriers, selectedCarrier, handleSelectCarrier }) => {
  const classes = useStyles();
  let selectedCarrierAlias = selectedCarrier.carrierAlias ? selectedCarrier.carrierAlias : null;
  return (
    <div className={classes.root}>
      {carriers.map((carrier) => (
        <Button
          key={carrier.carrierAlias}
          onClick={() => handleSelectCarrier({ carrier })}
          className={clsx([
            selectedCarrierAlias === carrier.carrierAlias ? classes.selected : null,
            classes.button,
          ])}
          variant="outlined"
        >
          <span>{CARRIER_NAMES[carrier.carrierAlias]}</span>
          <img
            className={classes.icon}
            src={getCarrierIcon(carrier.carrierAlias)}
            alt="Carrier icon"
          />
        </Button>
      ))}
    </div>
  );
};

export default SpecificCarriers;
