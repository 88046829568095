import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import UpArrowIcon from '@material-ui/icons/ArrowDropUp';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content',
    columnGap: 3,
    height: '58px',
    width: '50px',
  },
  title: {
    fontSize: '0.75rem',
    color: '#4e4e4e',
    textAlign: 'center',
    gridColumn: '1 / span 2',
    // transform: 'translateY(2px)',
  },
  arrowsContainer: {
    gridColumn: 1,
    display: 'grid',
    alignSelf: 'flex-end',
    height: '44px',
    width: '22px',
  },
  arrows: {
    border: `1px solid #d2d2d2`,
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    color: 'black',
    backgroundColor: grey[200],
    boxSizing: 'border-box',
    '&:active': {
      backgroundColor: darken(grey[200], 0.1),
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inputContainer: {
    gridColumn: 2,
  },
  inputField: {
    borderRadius: '2px',
    textAlign: 'center',
    height: '100%',
    fontFamily: 'Roboto ,Open Sans ,sans-serif',
    fontSize: '1rem',
    color: 'black',
    border: `1px solid ${grey[700]}`,
    boxSizing: 'border-box',
    boxShadow: 'inset 0 0 1px 1px #5a5a5a7a',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const NumberSpinner = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.title}>{props.title}</p>
      <div className={classes.arrowsContainer}>
        <UpArrowIcon onClick={props.upClick} className={classes.arrows} />
        <DownArrowIcon onClick={props.downClick} className={classes.arrows} />
      </div>
      <div className={classes.inputContainer}>
        <input
          style={{ width: props.count > 99 ? '35px' : '25px' }}
          onBlur={props.handleNumberBlur}
          onClick={() => document.execCommand('selectall', null, false)}
          onChange={props.handleNumberChange}
          value={props.count || ''}
          className={classes.inputField}
          type="text"
        />
      </div>
    </div>
  );
};

export default NumberSpinner;
