import React, { useState, useEffect } from 'react';
import { auth, cleanUserDetails } from '../../store/actions';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PagodaLogotype from '../../assets/pagodaWhite.svg';
import WisdomLogotype from '../../components/UI/Icons/wisdomLogotype';
import translations from './translations.json';
import GBicon from '../../components/UI/Icons/Flags/gb';
import SEicon from '../../components/UI/Icons/Flags/se';
import FIicon from '../../components/UI/Icons/Flags/fi';
//Material UI components
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { CircleSpinnerLarge } from '../../components/UI/Spinners/Circle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    position: 'absolute',
    right: '0',
    padding: '10px',
    display: 'flex',
  },
  flags: {
    display: 'flex',
    margin: '0 20px',
  },
  pagodaLogotype: {
    width: '200px',
    paddingRight: '10px',
    borderRight: '1px solid white',
    height: '80px',
    [theme.breakpoints.down('xs')]: {
      width: '130px',
      height: '50px',
    },
  },
  header: {
    marginTop: '80px',
    width: '100%',
    marginBottom: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& h1': {
      color: 'white',
      fontSize: '3.2rem',
      fontFamily: 'Philosopher, sans-serif',
      fontWeight: '200',
      paddingLeft: '10px',
      transform: 'translateY(-8px)',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.2rem',
        transform: 'translateY(-6px)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '350px',
    '& input': {
      paddingLeft: '5px',
      fontSize: '1rem',
      margin: '5px 0px',
      color: 'black',
      width: '100%',
      height: '45px',
      backgroundColor: '#ffffffc9',
      border: 'none',
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    '& h3': {
      fontSize: '1.1rem',
      fontWeight: '300',
      color: '#dcd6d6',
      textAlign: 'center',
      marginBottom: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      '& h3': {
        fontSize: '0.9rem',
      },
    },
  },
  loginButton: {
    color: 'white',
    fontSize: '1.2rem',
    marginTop: '10px',
    width: '100%',
    border: '1px solid #ffffff7a',
  },
  copyright: {
    position: 'absolute',
    bottom: 20,
    fontSize: '1rem',
    fontWeight: '200',
    color: 'white',
    '& > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  '@media (max-height:420px)': {
    copyright: {
      display: 'none',
    },
  },
}));

const navigatorLanguagePreferences = {
  fi: 'fi',
  'sv-FI': 'se',
  'sv-SE': 'se',
};

const Auth = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { error, token, loading } = useSelector((state) => state.auth);
  const { error: userFetchError } = useSelector((state) => state.user);

  const [lang, setLang] = useState('en');

  useEffect(() => {
    const browserLang = navigator.language;
    if (navigatorLanguagePreferences[browserLang])
      setLang(navigatorLanguagePreferences[browserLang]);
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(auth(e.target.email.value, e.target.password.value));
  };

  if (userFetchError) dispatch(cleanUserDetails());

  let form = (
    <form className={classes.form} onSubmit={onSubmitHandler}>
      <h3>{translations[lang].description}</h3>
      <input
        label='Email'
        type='email'
        name='email'
        autoComplete='email'
        placeholder={translations[lang].username}
      />
      <input
        name='password'
        label='Password'
        type='password'
        autoComplete='current-password'
        placeholder={translations[lang].password}
      />
      <Button variant='outlined' className={classes.loginButton} type='submit'>
        {translations[lang].login}
      </Button>
    </form>
  );

  if (loading) {
    form = (
      <div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100%',
          display: 'grid',
          placeItems: 'center',
          transform: 'translateY(-20vh)',
        }}
      >
        <CircleSpinnerLarge />
      </div>
    );
  }

  let errorMessage = null;

  if (error) {
    errorMessage = (
      <div style={{ color: 'white', marginTop: '20px', fontSize: '1.2rem' }}>
        {translations[lang].error}
      </div>
    );
    setTimeout(() => (errorMessage = null), 1000);
  }

  let authRedirect = null;

  if (token !== null && !userFetchError) {
    authRedirect = <Redirect to='/bookings' />;
  }

  return (
    <main className={classes.root}>
      {authRedirect}
      <div className={classes.topContainer}>
        <div className={classes.flags}>
          <IconButton onClick={() => setLang('en')}>
            <GBicon />
          </IconButton>
          <IconButton onClick={() => setLang('se')}>
            <SEicon />
          </IconButton>
          <IconButton onClick={() => setLang('fi')}>
            <FIicon />
          </IconButton>
        </div>
        <WisdomLogotype style={{ fontSize: 50 }} />
      </div>
      <div className={classes.header}>
        <div className={classes.pagodaLogotype}>
          {' '}
          <img className={classes.pagodaLogotype} src={PagodaLogotype} alt='Pagoda Logotype' />{' '}
        </div>
        <h1>Wisdom</h1>
      </div>
      {form}
      {errorMessage}
      <div className={classes.copyright}>
        <p>© 2019 Pagoda Logistics AB. All Rights Reserved. Confidential and Proprietary</p>
      </div>
    </main>
  );
};

export default Auth;
