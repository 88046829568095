const checkOverweightPackage = (pack) => {
  return parseFloat(pack.weight) > 32;
};
export const anyOverweight = (packageArray) => {
  return packageArray.some((pack) => checkOverweightPackage(pack));
};

export const getPackagesCount = (packageArray) => {
  return packageArray.reduce((acc, curr) => {
    acc += curr.count;
    return acc;
  }, 0);
};
