import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CARRIERS, DOMESTIC, UPS_EXPRESS_ENVELOPE } from '../../../../services/types';
import {
  addBookingDescription,
  addBookingReferenceOne,
  addBookingCustomsValue,
  addBookingCustomsCurrency,
  addBookingDocumentOnly,
  addBookingReferenceTwo,
} from '../../../../store/actions';
//components
import CustomsValue from './CustomsValue/CustomsValue';
import CustomPaper from '../../../UI/CustomPaper/CustomPaper';
import ShipmentDescription from './ShipmentDescription/ShipmentDescription';
import DescriptionAutocomplete from '../../Templates/DescriptionAutocomplete';
import translationsTooltipsObj from '../../../../services/translations/translationsTooltips';
//material ui
import { TextField, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  shipmentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '80%',
    minHeight: '300px',
    '& > div': {
      margin: '20px 0',
    },
  },
  shipmentInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
    boxSizing: 'border-box',
  },
  shipmentInfoTextbox: {
    width: '100%',
    height: '50px',
  },
  commodityWrapper: {
    width: '100%',
    height: '85px',
    maxWidth: '600px',
    display: 'grid',
    gridTemplateColumns: '1fr 194px',
    gridColumnGap: '10px',
  },
  referencesWrapper: {
    width: '100%',
    maxWidth: '600px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px',
    transform: 'translateY(-10px)',
    position: 'relative',
  },
  referenceTooltip: {
    position: 'absolute',
    bottom: '-15px',
    display: 'flex',
  },
  referenceTooltipIcon: {
    paddingLeft: '2px',
    color: grey[600],
    fontSize: '18px',
  },
  documentsOnlyOption: {
    marginTop: '10px',
    gridColumn: '1',
    display: 'flex',
    alignSelf: 'flex-start',
    height: '20px',
    color: ({ isValidDocumentsOnly }) => (isValidDocumentsOnly ? 'inherit' : '#d2d2d2'),
  },
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: 'auto auto !important',
  },
  '@media (max-width:400px)': {
    commodityWrapper: {
      gridTemplateColumns: '1fr',
      height: 'calc(100% + 50px)',
    },
    referencesWrapper: {
      gridTemplateColumns: '1fr',
      marginBottom: '10px',
    },
    documentsOnlyOption: {
      gridColumn: '1 / span 1',
      gridRow: 1,
      display: 'flex',
      justifyContent: 'center',
      '& label': {
        fontSize: '0.9rem',
      },
    },
  },
}));

const buildDescriptionOptions = (descriptions) => descriptions.map((d) => ({ label: d, value: d }));

const ShipmentDetails = ({ onlineBookingCarriers, translations }) => {
  const {
    description,
    reference,
    reference2,
    customsValue,
    customsCurrency,
    documentsOnly,
    packages,
    movementDirection,
  } = useSelector((state) => state.booking);
  const { defaultCustomsCurrency, defaultCustomsValue } = useSelector(
    (state) => state.bookingPresets
  );

  const [descriptionState, setDescriptionState] = useState('');
  const [referenceOneState, setReferenceOneState] = useState('');
  const [referenceTwoState, setReferenceTwoState] = useState('');
  const [monetaryValueState, setMonetaryValueState] = useState('');
  const [currencyState, setCurrencyState] = useState('SEK');
  const [isValidDocumentsOnly, setIsValidDocumentsOnly] = useState(false);
  const [errors, setError] = useState({
    descriptionState: false,
    monetaryValueState: false,
  });
  const [descriptionOptions, setDescriptionOptions] = useState([]);

  const { defaultCurrency } = useSelector((state) => state.rootTemplates);
  const { descriptions, defaultDescription } = useSelector((state) => state.bookingPresets);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translationsTooltips = translationsTooltipsObj[lang];

  const monetaryValueRequired = movementDirection !== DOMESTIC;

  useEffect(() => {
    if (description.length > 0) setDescriptionState(description);
    if (reference.length > 0) setReferenceOneState(reference);
    if (reference2.length > 0) setReferenceTwoState(reference2);
    if (customsValue) setMonetaryValueState(customsValue);
    if (!customsValue && defaultCustomsValue) {
      setMonetaryValueState(defaultCustomsValue);
      dispatch(addBookingCustomsValue(defaultCustomsValue));
    }
    if (customsCurrency) {
      setCurrencyState(customsCurrency);
    }

    if (!customsCurrency && (defaultCustomsCurrency || defaultCurrency)) {
      const presetCustomsCurrency = defaultCustomsCurrency || defaultCurrency;
      dispatch(addBookingCustomsCurrency(presetCustomsCurrency));
      setCurrencyState(presetCustomsCurrency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (descriptions.length > 0) {
      const descriptionOptions = buildDescriptionOptions(descriptions);
      setDescriptionOptions(descriptionOptions);
    }
    if (!description && defaultDescription) {
      setDescriptionState(defaultDescription);
      dispatch(addBookingDescription(defaultDescription));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let documentsOnlyValidation = false;
    if (packages.length > 0)
      documentsOnlyValidation = packages.every((pack) => pack.packaging === UPS_EXPRESS_ENVELOPE);

    if (isValidDocumentsOnly && !documentsOnlyValidation) {
      documentsOnly && dispatch(addBookingDocumentOnly(!documentsOnly));
      setIsValidDocumentsOnly(false);
    }
    if (!isValidDocumentsOnly && documentsOnlyValidation) setIsValidDocumentsOnly(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  const dispatch = useDispatch();

  const handleMonetaryValueChange = (e) => {
    if (errors['monetaryValueState']) setError({ ...errors, monetaryValueState: false });
    const monValue = e.target.value.replace(',', '.');
    setMonetaryValueState(monValue);
  };

  const handleMonetaryValueBlur = (e) => {
    const float = parseFloat(e.target.value);

    if (isNaN(float)) {
      setMonetaryValueState('');
      dispatch(addBookingCustomsValue(''));
      monetaryValueRequired && setError({ ...errors, monetaryValueState: true });
    } else {
      if (errors['monetaryValueState']) setError({ ...errors, monetaryValueState: false });
      setMonetaryValueState(float);
      dispatch(addBookingCustomsValue(float));
    }
  };
  const handleCurrencyChange = (e) => {
    setCurrencyState(e.target.value);
    dispatch(addBookingCustomsCurrency(e.target.value));
  };

  const handleDescriptionChange = (e) => {
    setDescriptionState(e.target.value);
  };
  const handleDescriptionBlur = (e) => {
    dispatch(addBookingDescription(descriptionState));
  };
  const handleAutocompleteChange = (value) => {
    setDescriptionState(value);
    dispatch(addBookingDescription(value));
  };
  const handleReferenceOneChange = (e) => {
    setReferenceOneState(e.target.value);
  };
  const handleReferenceOneBlur = () => {
    dispatch(addBookingReferenceOne(referenceOneState));
  };
  const handleReferenceTwoChange = (e) => {
    setReferenceTwoState(e.target.value);
  };
  const handleReferenceTwoBlur = () => {
    dispatch(addBookingReferenceTwo(referenceTwoState));
  };

  const classes = useStyles({ documentsOnly, isValidDocumentsOnly });

  return (
    <CustomPaper title={translations.onlinebooking.commodityenter}>
      <div className={classes.shipmentInfo}>
        <div className={classes.documentsOnlyOption}>
          <label htmlFor="document-only-option">{translations.onlinebooking.docsonly}?</label>
          <Checkbox
            color="primary"
            disabled={!isValidDocumentsOnly}
            checked={documentsOnly}
            onChange={() => dispatch(addBookingDocumentOnly(!documentsOnly))}
          />
        </div>
        <div className={classes.commodityWrapper}>
          <div className={classes.shipmentDescription}>
            {descriptionOptions.length === 0 && (
              <ShipmentDescription
                label={translations.onlinebooking.commoditydescription}
                value={descriptionState}
                onChange={handleDescriptionChange}
                onBlur={handleDescriptionBlur}
                required={true}
              />
            )}
            {descriptionOptions.length > 0 && (
              <DescriptionAutocomplete
                handleAutocompleteChange={handleAutocompleteChange}
                defaultValue={descriptionState || ''}
                options={descriptions}
                // options={descriptionOptions}
                onChange={handleDescriptionChange}
                onBlur={handleDescriptionBlur}
                label={translations.onlinebooking.commoditydescription}
              />
            )}
          </div>
          {!documentsOnly && (
            <div className={classes.customsValue}>
              <CustomsValue
                required={monetaryValueRequired}
                translations={translations.onlinebooking}
                monetaryValue={monetaryValueState}
                currency={currencyState}
                handleCurrencyChange={handleCurrencyChange}
                handleMonetaryValueChange={handleMonetaryValueChange}
                handleMonetaryValueBlur={handleMonetaryValueBlur}
                monetaryError={errors.monetaryValueState}
              />
            </div>
          )}
        </div>
        <div className={classes.referencesWrapper}>
          {Array.isArray(onlineBookingCarriers) &&
            onlineBookingCarriers.includes(CARRIERS.DHLFREIGHT) && (
              <div className={classes.referenceTooltip}>
                <Tooltip title={translationsTooltips.dhlFreightReferencesTooltip}>
                  <InfoIcon className={classes.referenceTooltipIcon} />
                </Tooltip>
              </div>
            )}
          <div className={classes.shipmentReference}>
            <TextField
              inputProps={{ maxLength: 35 }}
              id="reference-name-1"
              label={`${translations.onlinebooking.shippingref} 1`}
              className={classes.shipmentInfoTextbox}
              value={referenceOneState}
              onChange={handleReferenceOneChange}
              onBlur={handleReferenceOneBlur}
              margin="dense"
            />
          </div>
          <div className={classes.shipmentReference}>
            <TextField
              inputProps={{ maxLength: 35 }}
              id="reference-name-2"
              label={`${translations.onlinebooking.shippingref} 2`}
              className={classes.shipmentInfoTextbox}
              value={referenceTwoState}
              onChange={handleReferenceTwoChange}
              onBlur={handleReferenceTwoBlur}
              margin="dense"
            />
          </div>
        </div>
      </div>
    </CustomPaper>
  );
};

export default ShipmentDetails;
