import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchEventTrackingNumbersStart = () => {
	return {
		type: actionTypes.FETCH_EVENT_TRACKING_NUMBERS_START
	}
}

export const eventReset = () => {
	return {
		type: actionTypes.EVENT_RESET
	}
}

export const fetchEventTrackingNumbersSuccess = (eventTrackingNumbers) => {
	return {
		type: actionTypes.FETCH_EVENT_TRACKING_NUMBERS_SUCCESS,
		eventTrackingNumbers
	}
}

export const fetchEventTrackingNumbersFailed = () => {
	return {
		type: actionTypes.FETCH_EVENT_TRACKING_NUMBERS_FAILED
	}
}

export const fetchEventTrackingNumbers = (companyId, prevLogin) => {
	return dispatch => {
		fetchEventTrackingNumbersStart();
		axios.post('/api/shipments/customer/shipmentevents', { companyId, prevLogin })
			.then(resp => {
				dispatch(fetchEventTrackingNumbersSuccess(resp.data.trackingArray))
			});
	}

}
