import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
//material ui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/core/styles';

//temporary icon for label recovery
import LabelRecoveryIcon from '@material-ui/icons/LibraryBooks';

//custom
import TrackingIcon from '../../../UI/Icons/Tracking';
import BookingIcon from '../../../UI/Icons/PackageMove';
import DeleteShipmentModal from '../Modals/DeleteShipment/DeleteShipment';
import LabelRecoveryModal from '../Modals/LabelRecovery/LabelRecovery';
import { CARRIERS_APPLICABLE_FOR_LABEL_RECOVERY } from '../../../../services/types';

const defaultToolbarSelectStyles = {
  iconButton: {
    margin: 0,
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    //transform: "translateY(-50%)",
  },
  icon: {
    color: '#7b5d77',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

const initialLabelRecoveryModalState = { open: false, shipmentIdsWithTrackingNumbers: null };

const CustomToolbarSelect = (props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [labelRecoveryModalState, setLabelRecoveryModalState] = useState(
    initialLabelRecoveryModalState
  );

  const handleClickInverseSelection = () => {
    const nextSelectedRows = props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!props.selectedRows.data.find((selectedRow) => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    props.setSelectedRows(nextSelectedRows);
  };

  const handleClickDeselectAll = () => {
    props.setSelectedRows([]);
  };

  const handleClickTrackedShipments = () => {
    //extract the selected rows and add to an array of tracking numbers selected
    const selectedShipmentIds = props.displayData.reduce((acc, curr) => {
      if (props.selectedRows.data.some((value) => value.dataIndex === curr.dataIndex)) {
        acc.push(curr.data[0]);
      }

      return acc;
    }, []);

    props.handleTrackShipment(selectedShipmentIds[0]);
  };

  const handleDelete = () => {
    props.handleDelete(props.selectedRows.data[0].dataIndex);
  };

  const handleBookOnline = () => {
    props.redirectBookOnline(props.selectedRows.data[0].dataIndex);
  };

  const checkSameCarrier = () => {
    const indices = props.selectedRows.data.map((row) => row.index);
    return indices.every(
      (i) => props.displayData[i].data[12] === props.displayData[indices[0]].data[12]
    );
  };

  // Check with useMemo that the selected rows are all from the same carrier
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sameCarrier = useMemo(() => checkSameCarrier(), [props.selectedRows.data]);

  const handleLabelRecoverySelected = () => {
    const selectedIndices = props.selectedRows.data.map((row) => row.index);
    const shipmentIdsWithTrackingNumbers = selectedIndices.map((index) => ({
      shipmentId: props.displayData[index].data[0],
      trackingNumber: props.displayData[index].data[3],
    }));
    setLabelRecoveryModalState({ open: true, shipmentIdsWithTrackingNumbers });
  };

  const { classes } = props;
  return (
    <div style={{ display: 'flex' }} className={'custom-toolbar-select'}>
      <DeleteShipmentModal
        open={deleteModalOpen}
        translations={props.translations.shipmenthistory.customToolbar}
        handleDelete={handleDelete}
        handleAbort={() => setDeleteModalOpen(false)}
      />
      <LabelRecoveryModal
        open={labelRecoveryModalState.open}
        translations={props.translations}
        handleAbort={() => setLabelRecoveryModalState(initialLabelRecoveryModalState)}
        carrier={props.displayData[props.selectedRows.data[0].index].data[12]}
        shipmentIdsWithTrackingNumbers={labelRecoveryModalState.shipmentIdsWithTrackingNumbers}
        // trackingNumber={props.displayData[props.selectedRows.data[0].index].data[3]}
        // shipmentId={props.displayData[props.selectedRows.data[0].index].data[0]}
      />
      {/* Options at the beginning of navigation bar available when only one shipment is selected */}
      {props.selectedRows.data.length === 1 && (
        <Tooltip title={props.translations.shipmenthistory.customToolbar.viewDetailedInfo}>
          <IconButton className={classes.iconButton} onClick={handleClickTrackedShipments}>
            <TrackingIcon
              style={{ fontSize: '1.7rem', transform: 'translateY(3px)' }}
              className={classes.icon}
            />
          </IconButton>
        </Tooltip>
      )}
      {/* Options available when multiple shipments are selected */}
      <Tooltip title={props.translations.shipmenthistory.customToolbar.deSelectAll}>
        <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title={props.translations.shipmenthistory.customToolbar.reverseSelected}>
        <IconButton className={classes.iconButton} onClick={handleClickInverseSelection}>
          <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(' ')} />
        </IconButton>
      </Tooltip>
      {/** Label Recoery */}
      {CARRIERS_APPLICABLE_FOR_LABEL_RECOVERY.includes(
        props.displayData[props.selectedRows.data[0].index].data[12]
      ) &&
        sameCarrier && (
          <Tooltip title={props.translations.shipmenthistory.customToolbar.labelRecover}>
            <IconButton className={classes.iconButton} onClick={handleLabelRecoverySelected}>
              <LabelRecoveryIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        )}
      {/* Options at the end of navigation bar available when only one shipment is selected */}
      {props.selectedRows.data.length === 1 && (
        <>
          <Tooltip title="Book again">
            <IconButton onClick={handleBookOnline} className={classes.iconButton}>
              <BookingIcon style={{ fontSize: '1.7rem' }} className={classes.icon} />
            </IconButton>
          </Tooltip>
          {CARRIERS_APPLICABLE_FOR_LABEL_RECOVERY.includes(
            props.displayData[props.selectedRows.data[0].index].data[12]
          ) && (
            <Tooltip title={props.translations.shipmenthistory.customToolbar.labelRecover}>
              <IconButton className={classes.iconButton} onClick={handleLabelRecoverySelected}>
                <LabelRecoveryIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={props.translations.shipmenthistory.customToolbar.deleteShipment}>
            <IconButton onClick={() => setDeleteModalOpen(true)} className={classes.iconButton}>
              <DeleteForeverIcon style={{ fontSize: '1.7rem' }} className={classes.icon} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default withRouter(withStyles(defaultToolbarSelectStyles)(CustomToolbarSelect));
