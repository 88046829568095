import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f5f6f7',
    fontWeight: 400,
    height: '42px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    borderRadius: '4px 4px 0 0',
    paddingLeft: '8px',
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5px',
    '&:>*': {
      padding: '30px',
    },
  },
});

const CustomEnhancedToolbar = ({ children, title, toolbarFeatureContent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainWrapper}>
        {toolbarFeatureContent}
        <p className='title'>{title}</p>
      </div>
      <div className={classes.actions}>{children}</div>
    </div>
  );
};

export default CustomEnhancedToolbar;
