import React, { Component } from 'react';
//material ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
    minHeight: '185px',
    display: 'grid',
    gridColumnGap: '10px',
    padding: '10px',
    boxSizing: 'border-box',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  textField: {
    width: '100%',
  },
  formTitle: {
    gridColumn: '1 / span 3',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  input: {
    fontSize: '0.9rem',
  },
  label: {
    fontSize: '0.9rem',
  },
  buttonsContainer: {
    placeSelf: 'center',
    height: '40px',
    gridColumn: '1 / span 3',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '100px',
    margin: '0 3px',
  },
});

class ContactForm extends Component {
  state = {
    selected: false,
    contactData: {
      name: '',
      phone: '',
      email: '',
    },
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.contactSelected !== this.props.contactSelected &&
      typeof this.props.contactSelected !== 'undefined'
    ) {
      const contactData = {
        name: this.props.contactSelected.name,
        phone: this.props.contactSelected.phone,
        email: this.props.contactSelected.email,
      };

      this.setState({ ...this.state, contactData, selected: true });
    } else if (typeof this.props.contactSelected == 'undefined' && this.state.selected) {
      const contactData = {
        name: '',
        phone: '',
        email: '',
      };

      this.setState({ ...this.state, contactData, selected: false });
    }
  }

  handleTextChange = (value) => (e) => {
    let prevState = { ...this.state };
    prevState['contactData'] = { ...this.state.contactData, [value]: e.target.value };

    this.setState({
      ...this.state,
      ...prevState,
    });
  };

  handleAddContact = () => {
    const submitData = this.state.contactData;
    this.props.handleAddCompanyContact(submitData);
  };

  handleUpdateContact = () => {
    const submitData = this.state.contactData;
    this.props.handleUpdateCompanyContact(submitData);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.formTitle}>
          {this.state.selected
            ? `${this.props.translations.templates.change} ${this.props.translations.templates.contact}`
            : `${this.props.translations.templates.new} ${this.props.translations.templates.contact}`}
        </div>
        <TextField
          id='contact-name'
          label={this.props.translations.templates.contactname}
          className={classes.textField}
          value={this.state.contactData.name}
          onChange={this.handleTextChange('name')}
          margin='normal'
          InputProps={{
            classes: {
              root: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
        ></TextField>
        <TextField
          id='contact-phone'
          label={this.props.translations.phone}
          className={classes.textField}
          value={this.state.contactData.phone}
          onChange={this.handleTextChange('phone')}
          margin='normal'
          InputProps={{
            classes: {
              root: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
        ></TextField>
        <TextField
          id='contact-email'
          label={this.props.translations.email}
          className={classes.textField}
          value={this.state.contactData.email}
          onChange={this.handleTextChange('email')}
          margin='normal'
          InputProps={{
            classes: {
              root: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
        ></TextField>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            color='secondary'
            variant='contained'
            onClick={this.props.handleChangeToTable}
          >
            {this.props.translations.templates.cancel}
          </Button>
          {this.state.selected ? (
            <Button
              className={classes.button}
              color='primary'
              variant='contained'
              onClick={this.handleUpdateContact}
            >
              {this.props.translations.templates.update}
            </Button>
          ) : (
            <Button
              className={classes.button}
              color='primary'
              variant='contained'
              onClick={this.handleAddContact}
            >
              +{this.props.translations.templates.add}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContactForm);
