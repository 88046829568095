import React from 'react';
import { useSelector } from 'react-redux';
import translations from '../../services/translations/translations.json';
import PackageInputs from './PackageInputs/PackageInputs';
import PackageAddIcon from '../UI/Icons/PackageAdd';
import PackageDeleteIcon from '../UI/Icons/PackageDelete';
//Material UI
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  packageDetails: {
    display: 'grid',
    gridRowGap: 5,
  },
  packageButtons: {
    display: 'grid',
    gridTemplateColumns: '70px auto',
    gridColumnGap: '10px',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  removeButton: {
    width: '70px',
    backgroundColor: theme.status.danger,
    '&:hover': {
      background: darken(theme.status.danger, 0.25),
    },
  },
  addButton: {
    width: '70px',
    fontSize: '0.9rem',
    color: '#252525',
  },
}));

export default function PackagesDetails({
  packageRows,
  updatePackagesErrors,
  reloadKey,
  updatePackages,
  type,
  removePackageRow,
  addPackageRow,
  initialPackages = null,
}) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const classes = useStyles();
  let packageInputs = [];

  for (let i = 0; i < packageRows; i++) {
    packageInputs.push(
      <PackageInputs
        translations={translations[lang]}
        updatePackagesErrors={updatePackagesErrors}
        reloadKey={reloadKey}
        key={i}
        rowNumber={i}
        updatePackageRow={updatePackages}
        type={type}
        initialPackages={initialPackages}
      />
    );
  }

  return (
    <div className={classes.packageDetails}>
      <div style={{ width: '100%' }}>{packageInputs}</div>
      <div className={classes.packageButtons}>
        {packageRows > 1 ? (
          <>
            <Tooltip title={translations[lang].packagedetails.tooltips.removepackage}>
              <Button
                onClick={removePackageRow}
                variant="contained"
                className={classes.removeButton}
              >
                <PackageDeleteIcon />
              </Button>
            </Tooltip>
          </>
        ) : null}
        <>
          <Tooltip title={translations[lang].packagedetails.tooltips.addpackage}>
            <Button
              color="primary"
              onClick={addPackageRow}
              variant="contained"
              className={classes.addButton}
            >
              <PackageAddIcon />
            </Button>
          </Tooltip>
        </>
      </div>
    </div>
  );
}
