import * as actions from '../actions/actionTypes';

const initialState = {
  templates: [],
  loading: false,
  addError: null,
  fetchError: null,
  updateError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD
    case actions.ADD_SENDER_COMPANY_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_SENDER_COMPANY_TEMPLATE_SUCCESS:
      let updatedAddSender = state.templates.slice();
      updatedAddSender.splice(state.templates.length - 1, 0, action.payload);

      return {
        ...state,
        templates: updatedAddSender,
        loading: false,
      };
    case actions.ADD_SENDER_COMPANY_TEMPLATE_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE
    case actions.UPDATE_SENDER_COMPANY_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_SENDER_COMPANY_TEMPLATE_SUCCESS:
      const updateCompanyIndex = state.templates.findIndex((template) => {
        return template.id === action.id;
      });
      let updateCompany = action.updatedCompany;
      updateCompany.id = action.id;
      let updatedUpdateSender = state.templates.slice();
      updatedUpdateSender.splice(updateCompanyIndex, 1, updateCompany); //todo, integrate with id

      return {
        ...state,
        templates: updatedUpdateSender,
        loading: false,
      };
    case actions.UPDATE_SENDER_COMPANY_TEMPLATE_FAILED:
      return {
        ...state,
        updateError: action.error,
        loading: false,
      };
    //FETCH
    case actions.FETCH_SENDER_COMPANIES_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_SENDER_COMPANIES_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        loading: false,
      };
    case actions.FETCH_SENDER_COMPANIES_TEMPLATES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //REMOVE
    case actions.REMOVE_SENDER_COMPANIES_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };
    case actions.REMOVE_SENDER_COMPANIES_TEMPLATES_SUCCESS:
      //find object to remove
      const removeCompanyIndex = state.templates.findIndex((template) => {
        return template.id === action.payload.id;
      });

      let updatedRemoveSender = state.templates.slice();
      updatedRemoveSender.splice(removeCompanyIndex, 1);

      return {
        ...state,
        templates: updatedRemoveSender,
        loading: false,
      };
    case actions.REMOVE_SENDER_COMPANIES_TEMPLATES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //HANDLING CONTACT
    //ADD
    case actions.ADD_SENDER_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_SENDER_COMPANY_CONTACT_SUCCESS:
      let updatedAddSenderCompanies = state.templates.slice();
      const addCompanyIndex = updatedAddSenderCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedAddSenderCompanies[addCompanyIndex].contacts = action.contactData;

      return {
        ...state,
        templates: updatedAddSenderCompanies,
        loading: false,
      };
    case actions.ADD_SENDER_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //REMOVE
    case actions.REMOVE_SENDER_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };
    case actions.REMOVE_SENDER_COMPANY_CONTACT_SUCCESS:
      let updatedRemoveSenderCompanies = state.templates.slice();
      const contactRemoveCompanyIndex = updatedRemoveSenderCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedRemoveSenderCompanies[contactRemoveCompanyIndex].contacts = action.updatedContactData;

      return {
        ...state,
        templates: updatedRemoveSenderCompanies,
        loading: false,
      };
    case actions.REMOVE_SENDER_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE
    case actions.UPDATE_SENDER_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };

    case actions.UPDATE_SENDER_COMPANY_CONTACT_SUCCESS:
      let updatedUpdateSenderCompanies = state.templates.slice();
      const contactUpdateCompanyIndex = updatedUpdateSenderCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedUpdateSenderCompanies[contactUpdateCompanyIndex].contacts = action.updatedContactData;

      return {
        ...state,
        templates: updatedUpdateSenderCompanies,
        loading: false,
      };
    case actions.UPDATE_SENDER_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE COMMENT
    case actions.UPDATE_SENDER_TEMPLATE_COMMENT_FAILED:
      return {
        ...state,
        updateError: action.error,
        commentLoading: false,
      };
    case actions.UPDATE_SENDER_TEMPLATE_COMMENT_START:
      return {
        ...state,
        commentLoading: true,
      };
    case actions.UPDATE_SENDER_TEMPLATE_COMMENT_SUCCESS: {
      let updatedTemplates = state.templates.slice();
      const updatedTemplateIndex = updatedTemplates.findIndex(
        (template) => template.id === action.templateId
      );
      updatedTemplates[updatedTemplateIndex].templateComment = action.templateComment;

      return {
        ...state,
        templates: updatedTemplates,
        commentLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
