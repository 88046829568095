import { packagesTemplatesSchema, packagesTemplatesDimensionsSchema } from '../schemas';

/*
TODO:
export const ValidatePackagesArray = async (step, packages, lang = 'en') => {
  return;
}; */

export const ValidatePackageTemplate = async (pack, lang = 'en') => {
  const options = {
    abortEarly: false,
  };
  const packageValidation = await packagesTemplatesSchema(lang)
    .validate(pack, options)
    .then((valid) => {
      //TODO: Perhaps transfer the verified data here, then store these in redux
      return {
        valid: true,
        errors: null,
      };
    })
    .catch(function (err) {
      return {
        valid: false,
        errors: err.errors,
        variant: 'error',
      };
    });

  return packageValidation;
};
export const ValidatePackageTemplateDimensions = async (pack, lang = 'en') => {
  const options = {
    abortEarly: false,
  };
  const packageValidation = await packagesTemplatesDimensionsSchema(lang)
    .validate(pack, options)
    .then((valid) => {
      //TODO: Perhaps transfer the verified data here, then store these in redux
      return {
        valid: true,
        errors: null,
      };
    })
    .catch(function (err) {
      return {
        valid: false,
        errors: err.errors,
        variant: 'error',
      };
    });

  return packageValidation;
};
