import React, { useState, useEffect, useRef } from 'react';
import { DHLFREIGHT, MATKAHUOLTO, UPS } from '../../../services/types';
import { useDispatch } from 'react-redux';
import { cleanBooking } from '../../../store/actions';
//components
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import axios from 'axios';
//import ReactToPrint from 'react-to-print';
//icons
import FileDownloadIcon from '../../UI/Icons/FileDownload';
import ThermalPrinterIcon from '../../UI/Icons/ThermalPrinter';
import CopyLinkIcon from '../../UI/Icons/ContentCopy';
//material ui
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import RetryPickupRequestModal from './RetryPickupRequestModal/RetryPickupRequestModal';
import { ThermalPrint, GenerateDownloadLink, LabelPDF } from '../../Documents/Labels/LabelBuilders';
import { PDFViewer } from '@react-pdf/renderer';
import { grey } from '@material-ui/core/colors';
import { extractLabelTypes } from '../../../services/helpers/printerOptions';
import { calculateUpsPaperlessCountryEligibility } from '../../../services/helpers/paperless-country-eligibility';
import { buildDocuments } from '../../../services/documents';

const retryPickupCarriers = [UPS];

const getTrackingLinkText = (tracking, carrier) => {
  switch (carrier) {
    case UPS:
      return `https://www.ups.com/track?loc=en_US&tracknum=${tracking}`;
    case DHLFREIGHT:
      return `https://www.dhl.com/se-sv/home/tracking/tracking-freight.html?tracking-id=${tracking}`;
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    padding: '10px 0px',
    '& h3': {
      textAlign: 'center',
      fontSize: '1.3rem',
    },
    '& h4': {
      textAlign: 'center',
      fontSize: '1.1rem',
      fontWeight: '400',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  success: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '800px',
    justifyContent: 'space-between',
  },
  copyTrackingLink: {
    width: '60px',
    backgroundColor: '#c7e4f1',
    padding: '5px 0 4px 7px',
    margin: '10px 0 4px 5px',
    border: `1px solid ${grey[100]}`,
    borderRadius: '5px',
    position: 'absolute',
    right: 0,
    transform: 'translateX(64px)',
    '&:hover': {
      backgroundColor: grey[100],
      cursor: 'pointer',
    },
    '&:active': {
      outline: 'none',
      border: `1px solid ${grey[100]}`,
      backgroundColor: grey[200],
    },
    '&:focus': {
      outline: 0,
    },
  },
  bookingFailure: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '500px',
    height: '300px',
    justifyContent: 'space-between',
    '& h4': {
      color: 'red',
    },
  },
  pickupErrorContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    placeItems: 'center',
    marginBottom: '3px',
    rowGap: '2px',
  },
  retryButton: {
    padding: '2px 4px',
  },
  pickupRequestFailed: {
    color: 'red',
    justifySelf: 'right',
    marginRight: '15px',
  },
  pickupErrorMessage: {
    textAlign: 'center',
    fontWeight: 500,
    textDecoration: 'underline',
    gridColumn: '1 / -1',
  },
  pickupDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '5px',
  },
  pickupDetailsMessage: {
    fontSize: '0.9rem',
    padding: '0 5px',
  },
  labelView: {
    width: '100%',
    height: '350px',
    maxWidth: '500px',
  },
  labelViewThermal: {
    height: '60px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfLabels: {
    height: '100%',
    width: '100%',
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    color: 'red',
  },
  actionButton: {
    width: '100%',
    maxWidth: '400px',
  },
  fileDownloadIcon: {
    fontSize: '1.2rem',
    paddingLeft: '10px',
  },
  downloadLabelButton: {
    marginTop: '10px',
    width: '100%',
    fontSize: '0.9rem',
  },
  emailTrackingContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '6px 16px',
    fontSize: '0.9rem',
  },
  emailTrackingText: {
    position: 'relative',
    fontSize: '1rem',
  },
  uploadButton: {
    width: '120px',
    color: 'white',
    backgroundColor: '#368697',
    '&:hover': {
      backgroundColor: '#2c636f',
    },
  },
  emailButton: {
    padding: '0 16px',
    margin: '10px 0',
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      padding: '10px 0px',
      '& h3': {
        fontSize: '1.1rem',
      },
      '& h4': {
        marginTop: '5px',
        fontSize: '1rem',
      },
    },
    actionButton: {
      width: '95%',
    },
    labelView: {
      height: '300px',
    },
    downloadLabelButton: {
      marginTop: '20px',
      fontSize: '0.8rem',
    },
    emailButton: {
      marginTop: '20px',
      fontSize: '0.8rem',
    },
    copyTrackingLink: {
      width: '100%',
      margin: '0px',
      position: 'static',
      transform: 'none',
    },
  },
}));

/**
 * Loads shipping confirmation section and request shipment booking on first render.
 */
const BookingConfirmed = (props) => {
  const [shipmentState, setShipmentState] = useState({
    saved: false,
    shipmentId: null,
    pickup: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseLabels, setResponseLabels] = useState(null);
  const [responseOtherDocs, setResponseOtherDocs] = useState(null);
  const bookingData = useSelector((state) => state.booking);
  const carriers = useSelector((state) => state.carriers);
  const { token } = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.user);
  const [emailState, setEmailState] = useState({ loading: false, sent: false, error: false });
  const [retryPickupRequestModalOpen, setRetryPickupRequestModalOpen] = useState(false);

  const dispatch = useDispatch();
  //refs for validating error on unmount
  const errorRef = useRef();
  errorRef.current = error;

  useEffect(() => {
    handleShipRequest();

    //if booking has been successfull, reset redux store
    return () => {
      !errorRef.current && dispatch(cleanBooking());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Generate shipping request
   */
  const handleShipRequest = async () => {
    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    //Check if documents should be uploaded in request
    const documentsInShipment = Object.values(bookingData.documents).some(
      (doctype) => doctype.length > 0
    );

    let documentUploadError = false;

    const requestData = Object.assign({}, bookingData, {
      shipper: userDetails,
    });

    //remove unneccessary fields from request
    delete requestData.documents;
    delete requestData.services;
    delete requestData.dropOffLocations;
    delete requestData.upsDropOffLocations;
    delete requestData.dhlDropOffLocations;

    setLoading(() => true);

    //Run upload documents function if customs documents have been added
    if (documentsInShipment && bookingData.carrier === UPS) {
      try {
        const documentIds = await carrierDocumentUpload();
        requestData.documentIds = documentIds;
      } catch (e) {
        setError(e.message);
        setLoading(false);
        documentUploadError = true;
      }
    }
    !documentUploadError &&
      axios
        .post(`/api/shipping/ship`, { requestData }, config)
        .then((resp) => {
          const { labels, saved, shipmentId, pickup, wisdomId } = resp.data;

          setShipmentState({ ...shipmentState, saved, shipmentId, pickup });
          handleResponseLabels(labels);
          setLoading(false);

          /* Email customs documents if shipment contains documents and one of the following criterias is met
           * 1. Carrier is DHL Freight
           * 2. Carrier is UPS and destination country does not allow customs documents to be uploaded
           */

          if (
            documentsInShipment &&
            (bookingData.carrier === DHLFREIGHT ||
              (bookingData.carrier === UPS &&
                calculateUpsPaperlessCountryEligibility(bookingData.recipient.countryCode)))
          ) {
            try {
              carrierDocumentEmail(shipmentId);
            } catch (e) {
              console.error('Unable to upload documents', e);
            }
          }

          //Run function to upload documents to AWS
          documentsInShipment && wisdomDocumentUpload(wisdomId);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data.message);
          } else {
            setError('Unknown error occured');
          }
          setLoading(false);
        });
  };

  const handleResponseLabels = (response) => {
    const { labels, otherDocs } = extractLabelTypes(response);
    setResponseLabels(labels);
    otherDocs.length > 0 && setResponseOtherDocs(otherDocs);
  };

  /**
   * Email export documents
   *
   */
  const carrierDocumentEmail = async (trackingNumber) => {
    const { documents } = bookingData;

    if (!('invoice' in documents) && !Array.isArray(documents.invoice)) {
      return;
    }

    const { invoice } = documents;

    let formData = new FormData();

    for (let i = 0; i < invoice.length; i++) {
      formData.append('invoice', invoice[i].file);
    }

    const requestData = {
      shipToCountryCode: bookingData.recipient.countryCode,
      carrier: bookingData.carrier,
      trackingNumber,
    };

    formData.append('requestData', JSON.stringify(requestData));

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(`/api/shipping/email-docs`, formData, config);

      return response.data.result;
    } catch (e) {
      console.error('Unable to upload documents', e);
    }
  };

  /**
   * Upload customs documents to carrier
   * @todo Implement ruleset for carriers, different use cases should be used depending on carrier
   */
  const carrierDocumentUpload = async () => {
    const userDocuments = bookingData.documents;

    const documents = await buildDocuments(userDocuments);

    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(`/api/shipping/paperlessdocs`, { documents }, config);

      return response.data.result;
    } catch (e) {
      throw new Error('Unable to upload documents');
    }
  };

  const wisdomDocumentUpload = (id) => {
    Object.keys(bookingData.documents).forEach((docType) => {
      const endpoint = docType === 'invoice' ? 'invoices' : 'other';

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
          endpoint,
        },
      };
      const formData = new FormData();
      const files = bookingData.documents[docType];

      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files[i].file);
      }

      axios.post(`/uploads/shipment/documents`, formData, config);
    });
  };

  /**
   * Send email with shipment notice to recipient
   *
   * @todo Change recipient to sender when shipment is an import
   */
  const handleEmail = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    setEmailState({ ...emailState, loading: true, error: false });
    const email = bookingData.recipient.email;
    const tracking = shipmentState.shipmentId;
    let reference = bookingData.sender.company;
    const { carrier } = bookingData;
    let carrierName;

    try {
      for (let carrierFields of carriers.allCarriers) {
        if (carrierFields['carrierAlias'] === carrier) carrierName = carrierFields['carrierName'];
      }
    } catch (_) {
      carrierName = carrier;
    }

    //try to match the sender reference
    const match = reference.match(/(.*).com/g);
    if (match) {
      reference = match[0];
    }

    const requestData = { email, tracking, reference, carrierAlias: carrier, carrierName };

    axios
      .post('/api/shipping/email-tracking', { ...requestData }, config)
      .then((_) => {
        setEmailState({ ...emailState, loading: true, sent: true, error: false });
      })
      .catch((e) => {
        setEmailState({ ...emailState, loading: false, error: true });
      });
  };
  const updateConfirmedPickup = (pickupNumber) => {
    const updatedPickup = { success: true, pickupNumber };
    setShipmentState({ ...shipmentState, pickup: updatedPickup });
  };
  const classes = useStyles();
  const trackingLinkText = shipmentState.shipmentId
    ? getTrackingLinkText(shipmentState.shipmentId, bookingData.carrier)
    : null;
  return (
    <div className={classes.root}>
      {loading ? (
        <CircleSpinnerSmall />
      ) : (
        <div className={classes.container}>
          {responseLabels ? (
            <div className={classes.success}>
              <div className={classes.header}>
                <h3>{props.translations.onlinebooking.bookingsuccess}!</h3>
                <h4>
                  {props.translations.onlinebooking.shippingnumber} {shipmentState.shipmentId}
                </h4>
              </div>
              {shipmentState.pickup && shipmentState.pickup.success && (
                <div className={classes.pickupDetails}>
                  {/*For UPS show pickup number */}
                  {bookingData.carrier === UPS && (
                    <>
                      <p style={{ color: 'green' }}>
                        {props.translations.onlinebooking.pickupsuccess}
                      </p>
                      <p style={{ fontWeight: 500 }}>
                        {props.translations.onlinebooking.pickupnumber}:{' '}
                        {shipmentState.pickup.pickupNumber}
                      </p>
                    </>
                  )}
                  {/*For DHL show message instead of only pickup number */}
                  {bookingData.carrier === DHLFREIGHT && (
                    <>
                      <p style={{ color: 'green' }}>
                        {props.translations.onlinebooking.pickupsuccess}
                      </p>
                      <p className={classes.pickupDetailsMessage}>{shipmentState.pickup.message}</p>
                    </>
                  )}

                  {bookingData.carrier === MATKAHUOLTO && (
                    <>
                      <p style={{ fontWeight: 500 }}>
                        {/* {props.translations.onlinebooking.pickupnumber}:{' '} */}
                        Activation code:{shipmentState.pickup.pickupNumber}
                      </p>
                      <p className={classes.pickupDetailsMessage}>{shipmentState.pickup.message}</p>
                    </>
                  )}
                </div>
              )}
              {/* SHOW ERROR MESSAGE AND BUTTON TO OPEN MODAL AND RETRYING PICKUP REQUEST */}
              {/* TEMPORARY: DO NOT OPEN RETRY MODAL FOR DHLFREIGHT */}
              {shipmentState.pickup && !shipmentState.pickup.success && (
                <>
                  {retryPickupCarriers.includes(bookingData.carrier) && (
                    <>
                      <RetryPickupRequestModal
                        bookingData={bookingData}
                        translations={props.translations}
                        retryPickupRequestModalOpen={retryPickupRequestModalOpen}
                        cancel={() => setRetryPickupRequestModalOpen(false)}
                        updateConfirmedPickup={updateConfirmedPickup}
                        locale={props.locale}
                      />
                      <div className={classes.pickupErrorContainer}>
                        <p className={classes.pickupRequestFailed}>
                          {props.translations.onlinebooking.pickupfailed}
                        </p>
                        <Button
                          className={classes.retryButton}
                          onClick={() => setRetryPickupRequestModalOpen(true)}
                          variant="outlined"
                          color="primary"
                        >
                          {props.translations.onlinebooking.tryotherpickupdetails}
                        </Button>
                        <p className={classes.pickupErrorMessage}>{shipmentState.pickup.error}</p>
                      </div>
                    </>
                  )}
                  {!retryPickupCarriers.includes(bookingData.carrier) && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <p className={classes.pickupRequestFailed}>
                        {props.translations.onlinebooking.pickupfailed}
                      </p>
                      <p className={classes.pickupErrorMessage}>{shipmentState.pickup.error}</p>
                    </div>
                  )}
                </>
              )}
              {/* Displays iframe when file format is img/png from base64 string */}
              {bookingData.printFormat === 'PNG' && (
                <>
                  <div className={classes.labelView}>
                    <PDFViewer className={classes.pdfLabels}>
                      <LabelPDF fileName={'labels.pdf'} packages={responseLabels} />
                    </PDFViewer>
                  </div>
                  <div className={classes.actionButton}>
                    <GenerateDownloadLink labels={responseLabels}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.downloadLabelButton}
                      >
                        {props.translations.onlinebooking.labeldownload}{' '}
                        <FileDownloadIcon className={classes.fileDownloadIcon} />
                      </Button>
                    </GenerateDownloadLink>
                  </div>
                </>
              )}
              {/* Displays button with printing to Zebra printer action when Zebra printing is requested  */}
              {bookingData.printFormat.substring(0, 3) === 'ZPL' && (
                <div className={classes.labelViewThermal}>
                  <div style={{ width: '80%' }}>
                    <ThermalPrint packages={responseLabels}>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '10px', width: '100%' }}
                      >
                        {props.translations.onlinebooking.printlabels}
                        <ThermalPrinterIcon
                          style={{
                            fontSize: '2rem',
                            paddingLeft: '10px',
                            transform: 'translateY(4px)',
                          }}
                        />
                      </Button>
                    </ThermalPrint>
                  </div>
                </div>
              )}
              {/* Displays iframe when file format is base64 encoded pdf */}
              {bookingData.printFormat.substring(0, 3) === 'PDF' && (
                <>
                  <div className={classes.labelView}>
                    <iframe
                      style={{ width: '100%', height: '100%' }}
                      src={`data:application/pdf;base64,${responseLabels[0].img}`}
                      title="Label"
                    />
                  </div>
                  <div className={classes.actionButton}>
                    <Button
                      title="Download pdf document"
                      download="labels"
                      component={Link}
                      href={`data:application/pdf;base64,${responseLabels[0].img}`}
                      color="primary"
                      variant="contained"
                      className={classes.downloadLabelButton}
                    >
                      {props.translations.onlinebooking.labeldownload}{' '}
                      <FileDownloadIcon className={classes.fileDownloadIcon} />
                    </Button>
                  </div>
                </>
              )}
              {/* Show other docs download and print - Only PDF  */}
              {responseOtherDocs && (
                <div className={classes.actionButton}>
                  <Button
                    title="Download pdf document"
                    download="shipping-docs"
                    component={Link}
                    href={`data:application/pdf;base64,${responseOtherDocs[0].img}`}
                    color="primary"
                    variant="contained"
                    className={classes.downloadLabelButton}
                    style={{
                      backgroundColor: '#d0dacc',
                      color: 'black',
                    }}
                  >
                    Additional shipping docs
                    <FileDownloadIcon className={classes.fileDownloadIcon} />
                  </Button>
                </div>
              )}
              <div style={{ position: 'relative' }} className={classes.actionButton}>
                {!emailState.sent && !emailState.error && (
                  <Button
                    variant="contained"
                    style={{
                      background: emailState.loading ? '#e0e0e0' : '#b1d5e5',
                    }}
                    className={classes.emailButton}
                    onClick={handleEmail}
                  >
                    <span className={classes.emailTrackingContainer}>
                      {!emailState.loading ? (
                        props.translations.onlinebooking.sendtracking
                      ) : (
                        <>
                          <p className={classes.emailTrackingText}>
                            {props.translations.onlinebooking.sending}
                          </p>{' '}
                          <div
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: '-1px',
                            }}
                          >
                            <CircleSpinnerSmall />
                          </div>
                        </>
                      )}
                    </span>
                  </Button>
                )}
                {emailState.sent && (
                  <p style={{ textAlign: 'center', padding: '5px 0' }}>
                    {props.translations.onlinebooking.emailsent}
                  </p>
                )}
                {emailState.error && (
                  <p style={{ color: 'red', textAlign: 'center', padding: '5px 0' }}>
                    {props.translations.onlinebooking.emailerror}
                  </p>
                )}
                {trackingLinkText && (
                  <Tooltip title={props.translations.onlinebooking.copyTrackingLink}>
                    <button
                      className={classes.copyTrackingLink}
                      onClick={() => {
                        navigator.clipboard.writeText(trackingLinkText);
                      }}
                    >
                      <CopyLinkIcon />
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.bookingFailure}>
              <div className={classes.header}>
                <h3>{props.translations.onlinebooking.bookingfailed}</h3>
              </div>
              {error && <h4>{error}</h4>}
              <Button
                style={{ width: '100%' }}
                variant="contained"
                color="primary"
                onClick={props.redo}
              >
                {props.translations.onlinebooking.editbooking}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingConfirmed;
