import * as yup from 'yup';
import {
  NOTIFICATION_TYPE_EXCEPTION,
  NOTIFICATION_TYPE_SHIP,
  NOTIFICATION_TYPE_ACCESS_POINT,
  NOTIFICATION_TYPE_DELIVERY,
  NOTIFICATION_RECIPIENT_EMAIL,
  NOTIFICATION_RECIPIENT_TEXT_MESSAGE,
} from '../../../types';
import { emailPattern } from '../../helpers';
import errors from '../../../translations/errors';

export const notificationsSchema = (lang = 'en') => {
  return yup.array().of(
    yup.object().shape({
      notificationType: notificationTypesSchema(lang),
      recipients: yup.mixed().when('notificationType', {
        is: (notificationType) => notificationType === NOTIFICATION_RECIPIENT_EMAIL,
        then: notificationEmailsSchema(lang),
        otherwise: yup.string(), //TODO: validation for Text-Message
      }),
      notification: notificationSchema(lang),
    })
  );
  // Validation that max 3 unique notifications does not seem necessary
  // .max(3, errors.notifications.max[lang]);
};

const notificationEmailsSchema = (lang = 'en') => {
  return yup
    .array()
    .of(
      yup
        .string()
        //regex is used, as yup.email validates incorrectly
        .matches(emailPattern, { message: errors.email[lang], excludeEmptyString: false })
        .max(50, errors.default.max[lang])
    )
    .max(5, errors.notifications.tooManyEmails[lang]);
};

const notificationTypesSchema = (lang = 'en') => {
  return yup.string().oneOf([NOTIFICATION_RECIPIENT_EMAIL, NOTIFICATION_RECIPIENT_TEXT_MESSAGE]);
};
const notificationSchema = (lang = 'en') => {
  return yup
    .string()
    .oneOf([
      NOTIFICATION_TYPE_EXCEPTION,
      NOTIFICATION_TYPE_SHIP,
      NOTIFICATION_TYPE_ACCESS_POINT,
      NOTIFICATION_TYPE_DELIVERY,
    ]);
};
