import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getTheme } from '../../../services/statistics/chartThemes';
import Paper from '@material-ui/core/Paper';
import translations from '../../../services/translations/translations';

const ExportWeightByCountry = ({ data: shipmentData, theme, lang }) => {
  const countryExportData = shipmentData.reduce((acc, curr) => {
    if (!acc.some((val) => val === curr.recipientAddressCountryCode)) {
      acc.push(curr.recipientAddressCountryCode);
    }

    return acc;
  }, []);

  const weightExportData = countryExportData.reduce((acc, curr) => {
    let weight = 0;
    shipmentData.forEach((shpmt) => {
      if (shpmt.recipientAddressCountryCode === curr) {
        weight = weight + shpmt.chargeableWeight;
      }
    });

    acc.push(weight);

    return acc;
  }, []);

  echarts.registerTheme(theme, getTheme[theme]);
  const options = {
    title: {
      text: translations[lang].statistics.weightsByCountry,
      textStyle: {
        fontWeight: 'normal',
      },
    },
    tooltip: {},
    xAxis: {
      data: countryExportData,
    },
    yAxis: {},
    series: [
      {
        name: translations[lang].statistics.weights,
        type: 'bar',
        data: weightExportData.map((weight, i) => {
          return { value: weight };
        }),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default ExportWeightByCountry;
