import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../../services/translations/translations';
//components
import BookingSettings from '../../components/Bookings/BookingSettings/BookingSettings';
//material ui
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
//images
// import OnlineBookingImage from '../../assets/online-booking.svg';
import OnlineBookingIcon from '../../components/UI/Icons/OnlineBooking';
import RequestBookingIcon from '../../components/UI/Icons/RequestBooking';
import BatchBookingIcon from '../../components/UI/Icons/BatchBookingExt';
// import FileUploadBookingImage from '../../assets/file-upload-booking.svg';
// import RequestBookingImage from '../../assets/request-booking.svg';
// import OrderBookingImage from '../../assets/order-booking.svg';
import { fetchCountries } from '../../store/actions';
import { fetchBookingPresets } from '../../store/actions/bookingPresets';
import { grey, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
    justifyItems: 'flex-end',
    width: '100%',
    height: '100%',
    '& h1': {
      fontSize: '2rem',
      fontWeight: '300',
      textAlign: 'center',
      color: '#424242',
    },
  },
  selectContainer: {
    display: 'grid',
    gap: '20px',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, 350px)',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
    },
  },
  paper: {
    height: '100%',
    transition: 'transform 100ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
    '& h2': {
      padding: '5px 5px',
      fontSize: '1.5rem',
      fontWeight: '400',
    },
    '& p': {
      padding: '5px 5px',
      fontSize: '1rem',
      fontWeight: '300',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    backgroundColor: grey[100],
    height: '150px',
  },
  bookingSettings: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '900px',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      '& h1': {
        fontSize: '1.4rem',
        fontWeight: '300',
      },
    },
    selectContainer: {
      gridTemplateColumns: 'repeat(auto-fit, 250px)',
    },
    paper: {
      '& h2': {
        padding: '3px 3px',
        fontSize: '1.2rem',
        fontWeight: '400',
      },
      '& p': {
        padding: '3px 3px',
        fontSize: '0.9rem',
      },
    },
  },
}));

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const Bookings = () => {
  const [hovered, setHovered] = useState(null);
  const classes = useStyles();
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const bookingPresets = useSelector((state) => state.bookingPresets);
  const { countriesData } = useSelector((state) => state.countries);
  const { allowFileBooking } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.user.userId);
  const loaded = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    //Currently Online booking is start page and therefore iniatialize following
    if (!countriesData) {
      dispatch(fetchCountries(token));
    }

    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS}&libraries=places`,
          document.querySelector('head'),
          'google-maps'
        );
      }

      loaded.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!bookingPresets.fetched) dispatch(fetchBookingPresets(userId, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookingOptions = [
    {
      id: 1,
      headline: translations[lang].categories.onlinebooking,
      description: translations[lang].onlinebookingdescription,
      link: '/bookings/onlinebooking',
      icon: <OnlineBookingIcon style={{ fontSize: '70px', color: '#62485E' }} />,
    },
    {
      id: 2,
      headline: translations[lang].categories.requestbooking,
      description: translations[lang].requestbookingdescription,
      link: '/bookings/requestbooking',
      icon: <RequestBookingIcon style={{ fontSize: '70px', color: '#62485E' }} />,
    },
    /* TODO: warehouse module
    {id:4, headline:'Orderbokning', description:'Skicka en orderbokning så hanteras denna via Pagodas lager', link:'/bookings/orderbooking', image:OrderBookingImage}, */
  ];

  if (allowFileBooking) {
    bookingOptions.push({
      id: 3,
      headline: translations[lang].categories.batchBooking,
      description: translations[lang].batchBookingDescription,
      link: '/bookings/filebooking',
      icon: <BatchBookingIcon style={{ fontSize: '120px', color: '#62485E' }} />,
    });
  }

  const handleHover = (index) => {
    setHovered(index);
  };

  return (
    <div className={classes.root}>
      <h1>{translations[lang].selectbookingtype}</h1>
      <div className={classes.selectContainer}>
        {bookingOptions.map((item, index) => (
          <Link key={item.id} to={item.link}>
            <Paper
              style={{ transform: item.id === hovered ? 'scale(1.05)' : 'none' }}
              onMouseEnter={() => handleHover(index + 1)}
              onMouseLeave={() => handleHover(null)}
              className={classes.paper}
            >
              <div className={classes.content}>
                {item.icon}
                {/* <img src={item.image} alt="" /> */}
              </div>
              <h2> {item.headline}</h2>
              <p>{item.description}</p>
            </Paper>
          </Link>
        ))}
      </div>
      <div className={classes.bookingSettings}>
        <BookingSettings translations={translations[lang]} />
      </div>
    </div>
  );
};

export default Bookings;
