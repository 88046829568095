import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import moment from 'moment';
import 'moment/locale/sv';
//material ui styles
import { makeStyles } from '@material-ui/core/styles';

//icons
import CheckCircle from '@material-ui/icons/CheckCircleOutlineOutlined';
import PackageDefault from '../../../UI/Icons/PackageDefault';
import SeaFreight from '../../../UI/Icons/SeaFreight';
import AirFreight from '../../../UI/Icons/AirFreight';
import { CARRIERS } from '../../../../services/types';

const useStyles = makeStyles({
  root: {
    height: 'fit-content',
  },
  heading: {
    fontSize: '1rem',
  },
});

export default function TrackingEventTimeline({ activities, carrier }) {
  const classes = useStyles();

  const buildExpeditorsTitle = (origin, destination) => {
    let from = '';
    let to = '';

    if ('name' in origin) from = `From: ${origin.name}`;
    if ('name' in destination) to = `To: ${destination.name}`;

    return `${from} ${to}`;
  };

  const eventFieldsByCarrier = (carrierAlias, activity, service) => {
    switch (carrierAlias) {
      case CARRIERS.UPS:
        return {
          title:
            activity.ActivityLocation && activity.ActivityLocation.Address
              ? `${activity.ActivityLocation.Address.City || 'N/A'},${
                  activity.ActivityLocation.Address.CountryCode || 'N/A'
                }`
              : 'N/A, N/A',
          createdAt: moment(`${activity.Date}T${activity.Time}`).format('LLL'),
          icon: <PackageDefault />,
          description: activity.Status.Description,
        };
      case CARRIERS.UPSSCS:
        return {
          title: activity.ActivityLocation
            ? `${activity.ActivityLocation.City || 'N/A'},${
                activity.ActivityLocation.CountryCode || 'N/A'
              }`
            : 'N/A, N/A',
          createdAt: moment(`${activity.Date}T${activity.Time}`).format('LLL'),
          icon: <AirFreight />,
          description: activity.Description,
        };
      case CARRIERS.EXPEDITORS:
        return {
          title: buildExpeditorsTitle(activity.origin, activity.destination),
          createdAt: moment(activity.time).format('LLL'),
          icon: <CheckCircle style={{ color: '#525275', fontSize: '20px' }} />,
          description: activity.description,
        };
      case CARRIERS.SCHENKER:
        return {
          title: activity.ActivityLocation.Address
            ? `${activity.ActivityLocation.Address.City || 'N/A'},${
                activity.ActivityLocation.Address.CountryCode || 'N/A'
              }`
            : 'N/A, N/A',
          createdAt: moment(
            `${activity.ActivityLocation.Date}T${activity.ActivityLocation.Time}`
          ).format('LLL'),
          icon: <PackageDefault />,
          description: activity.ActivityLocation.Status.Description,
        };
      case CARRIERS.CEVA:
        return {
          title: activity.LocationName,
          createdAt: moment(activity.StatusDate).format('LLL'),
          icon: <SeaFreight style={{ color: '#525275' }} />,
          description: activity.Description,
        };
      case CARRIERS.DHLFREIGHT:
        return {
          title: `${activity.terminalName},${activity.terminalCountry}`, //activity.eventKey.eventCode
          createdAt: moment(`${activity.eventDate}T${activity.eventTime}`).format('LLL'),
          icon: <PackageDefault style={{ color: '#525275' }} />,
          description: activity.eventDescription,
        };
      case CARRIERS.MATKAHUOLTO:
        return {
          title: `${activity.place},FI`,
          createdAt: moment(activity.eventTime).format('LLL'),
          icon: <PackageDefault style={{ color: '#525275' }} />,
          description: activity.eventDescription,
        };
      case CARRIERS.HRX:
        return {
          title: `${activity.Name}`,
          createdAt: moment(activity.DateTime, 'DD.MM.YYYY HH:mm').format('LLL'),
          icon: <PackageDefault style={{ color: '#525275' }} />,
          description: activity.Event,
        };
      case CARRIERS.GEODIS:
        return {
          title: `${activity.City || 'N/A'},${activity.Country || 'N/A'}`,
          createdAt: moment(activity.Actual).format('LLL'),
          icon: <CheckCircle style={{ color: '#525275', fontSize: '20px' }} />,
          description: activity.Description,
        };

      default:
        return {
          title: '',
          createdAt: '',
          icon: <PackageDefault />,
          description: '',
        };
    }
  };

  return (
    <div className={classes.root}>
      <Timeline className={classes.timeline} lineColor="#d0b2d8">
        {activities.map((activity, index) => {
          const { title, createdAt, icon, description } = eventFieldsByCarrier(carrier, activity);

          return (
            <TimelineEvent
              key={index}
              title={title}
              createdAt={createdAt}
              icon={icon}
              bubbleStyle={{ backgroundColor: 'white', borderColor: '#7b5d77' }}
              titleStyle={{ fontWeight: '700' }}
              contentStyle={{ backgroundColor: '#f3f3f3', color: '#070707' }}
            >
              {description}
            </TimelineEvent>
          );
        })}
      </Timeline>
    </div>
  );
}
