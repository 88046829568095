import React, { useState, useEffect } from 'react';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import ProductTemplateForm from './ProductTemplateForm/ProductTemplateForm';
import EnhancedTable from '../TemplateTables/EnhancedTable/EnhancedTable';
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import EmptyTable from '../TemplateTables/EmptyTable/EmptyTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductTemplate,
  removeProductTemplate,
  updateProductTemplate,
  addProductTemplate,
} from '../../../store/actions';
import { serializeTemplateProduct } from '../../../helpers';

const trimUnitValue = ({ unitValue }) => {
  return unitValue ? (parseFloat(unitValue).toPrecision(10) / 1).toString() : '';
};

//Ensures fetched data displays in correct order according to order of the headCells
const createRowsData = ({
  id,
  templateId,
  description,
  unitNumber,
  unitMeasureCode,
  unitValue,
  unitCurrency,
  commodityCode,
  originCountryCode,
}) => {
  const unitValueTrimmed = trimUnitValue({ unitValue });
  const currency = unitCurrency ? unitCurrency : '';
  const unitValueString = `${unitValueTrimmed} ${currency}`;
  return {
    id,
    templateId,
    description,
    unitNumber,
    unitMeasureCode,
    unitValue: unitValueString,
    commodityCode,
    originCountryCode,
  };
};

export default function ProductTemplate({ translations }) {
  const [content, setContent] = useState('table');
  const [templateEditId, setTemplateEditId] = useState(null);
  const [rows, setRows] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.user);
  const { templates, loading } = useSelector((state) => state.productTemplates);
  const dispatch = useDispatch();
  useEffect(() => {
    if (templates.length === 0) {
      dispatch(fetchProductTemplate({ userId, token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.length > 0 && templates.length !== rows.length)
      updateRows({ templateData: templates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const headCells = [
    {
      id: 'templateId',
      numeric: false,
      disablePadding: true,
      label: translations.templates.template,
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.description,
    },
    {
      id: 'unitNumber',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.amount,
    },
    {
      id: 'unitMeasureCode',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.unitOfMeasure,
    },
    {
      id: 'unitValue',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.unitValue,
    },
    {
      id: 'commodityCode',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.commodityCode,
    },
    {
      id: 'originCountryCode',
      numeric: false,
      disablePadding: true,
      label: translations.invoiceForm.countryOfOrigin,
    },
  ];

  const updateRows = ({ templateData }) => {
    const updatedRows = templateData.map((template) => createRowsData(template));
    setRows(updatedRows);
  };

  const changeToAdd = () => setContent('add');

  const changeToEdit = (id) => {
    setTemplateEditId(id);
    setContent('edit');
  };

  const handleAddProduct = ({ productData }) => {
    dispatch(addProductTemplate({ productData, token }));
  };
  const handleUpdateProduct = ({ productData }) => {
    dispatch(updateProductTemplate({ productData, productId: templateEditId, token }));
    //update rows in table
    const rowsUpdate = [...templates].map((template) =>
      template.id === templateEditId ? { ...productData, id: templateEditId } : template
    );
    updateRows({ templateData: rowsUpdate });
    setContent('table');
  };
  const handleDelete = (id) => {
    dispatch(removeProductTemplate({ id, token }));
  };

  function getTemplateEditFromId({ templateEditId }) {
    const template = templates.find((template) => template.id === templateEditId);
    const {
      templateId,
      description,
      unitNumber,
      unitMeasureCode,
      unitValue,
      unitCurrency,
      commodityCode,
      originCountryCode,
    } = template;

    const templateEdit = serializeTemplateProduct({
      product: {
        templateId,
        description,
        unitNumber,
        unitMeasureCode,
        unitValue,
        unitCurrency,
        commodityCode,
        originCountryCode,
      },
    });

    return templateEdit;
  }

  let renderContent;
  switch (content) {
    case 'table':
      const toolbarProps = [
        {
          hasSelected: false,
          funcName: 'changeToAdd',
          icon: <AddIcon />,
          toolTip: translations.invoiceForm.addProduct,
        },
        {
          hasSelected: true,
          funcName: 'changeToEdit',
          icon: <EditIcon />,
          toolTip: translations.templates.change,
        },
        {
          hasSelected: true,
          funcName: 'handleDelete',
          icon: <DeleteIcon />,
          toolTip: translations.shipmenthistory.textLabels.delete,
        },
      ];

      const actions = { changeToAdd, handleDelete, changeToEdit };

      renderContent =
        rows.length === 0 ? (
          <EmptyTable
            translations={translations}
            changeToAdd={changeToAdd}
            type="product"
            title={translations.templates.savedproducttemplates}
          />
        ) : (
          <EnhancedTable
            rows={rows}
            headCells={headCells}
            sortByKey={'description'}
            title={translations.templates.producttemplates}
            selectedTitle={translations.templates.producttemplate}
            endAdornmentFields={null}
            toolbarProps={toolbarProps}
            {...actions}
          />
        );
      break;
    case 'add':
      renderContent = (
        <ProductTemplateForm
          type="add"
          translations={translations}
          handleChangeToTable={() => setContent('table')}
          handleSubmit={handleAddProduct}
        />
      );
      break;
    case 'edit':
      const updateTemplate = getTemplateEditFromId({ templateEditId });
      renderContent = (
        <ProductTemplateForm
          type="change"
          translations={translations}
          handleChangeToTable={() => setContent('table')}
          handleSubmit={handleUpdateProduct}
          template={updateTemplate}
        />
      );
      break;
    default:
      renderContent = (
        <EmptyTable
          translations={translations}
          changeToAdd={() => setContent('add')}
          type="package"
          title={'Saved product templates'}
        />
      );
  }

  return <div>{loading ? <CircleSpinnerLarge /> : renderContent}</div>;
}
