import axios from 'axios';
import { validForCarrierSpecificPrintOptions } from '../../services/helpers/printerOptions';
import { CARRIERS } from '../../services/types';
import * as actionTypes from './actionTypes';

export const fetchBookingPresetsStart = () => {
  return { type: actionTypes.FETCH_BOOKING_PRESET_START };
};

export const fetchBookingPresetsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_BOOKING_PRESET_SUCCESS,
    payload,
  };
};

export const fetchBookingPresetsFail = () => {
  return {
    type: actionTypes.FETCH_BOOKING_PRESET_FAILED,
  };
};

export const fetchBookingPresets = (userId, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    dispatch(fetchBookingPresetsStart());
    axios
      .get(`/api/booking-presets/user/${userId}`, config)
      .then((resp) => {
        dispatch(fetchBookingPresetsSuccess(resp.data.results));
      })
      .catch((e) => {
        dispatch(fetchBookingPresetsFail(e));
      });
  };
};
//DEFAULT Currency
export const updateDefaultCustomsCurrencyStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_START,
  };
};

export const updateDefaultCustomsCurrencyFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_FAILED,
    error,
  };
};

export const updateDefaultCustomsCurrencySuccess = (currency) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_SUCCESS,
    currency,
  };
};

export const updateDefaultCustomsCurrency = (currency, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateDefaultCustomsCurrencyStart());
    axios
      .put(`/api/booking-presets/customs-currency/${currency}`, null, config)
      .then((resp) => {
        dispatch(updateDefaultCustomsCurrencySuccess(resp.data.currency));
      })
      .catch((err) => {
        updateDefaultCustomsCurrencyFailed(err);
      });
  };
};

//DEFAULT CUSTOMS VALUE
export const updateDefaultCustomsValueStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_START,
  };
};

export const updateDefaultCustomsValueFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_FAILED,
    error,
  };
};

export const updateDefaultCustomsValueSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_SUCCESS,
    payload,
  };
};

export const updateDefaultCustomsValue = (defaultCustomsValue, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateDefaultCustomsValueStart());
    axios
      .put(`/api/booking-presets/default-customs-value`, { defaultCustomsValue }, config)
      .then((resp) => {
        dispatch(updateDefaultCustomsValueSuccess(resp.data.results));
      })
      .catch((err) => {
        updateDefaultCustomsValueFailed(err);
      });
  };
};

//SENDER ID - UPDATE
export const updateDefaultSenderStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_SENDER_START,
  };
};

export const updateDefaultSenderFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_SENDER_FAILED,
    error,
  };
};

export const updateDefaultSenderSuccess = (defaultId) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_SENDER_SUCCESS,
    defaultId,
  };
};

export const updateDefaultSender = (userId, senderId) => {
  return (dispatch) => {
    dispatch(updateDefaultSenderStart());
    axios
      .put('/api/booking-presets/sender-template', { userId, senderId })
      .then((resp) => {
        dispatch(updateDefaultSenderSuccess(resp.data.defaultId));
      })
      .catch((err) => console.log(err) /* updateDefaultSenderFailed(err) */);
  };
};

//USE SENDER - UPDATE
export const updateUseDefaultSenderStart = () => {
  return {
    type: actionTypes.USE_DEFAULT_SENDER_START,
  };
};

export const updateUseDefaultSenderFailed = (error) => {
  return {
    type: actionTypes.USE_DEFAULT_SENDER_FAILED,
    error,
  };
};

export const updateUseDefaultSenderSuccess = (isUse) => {
  return {
    type: actionTypes.USE_DEFAULT_SENDER_SUCCESS,
    isUse,
  };
};

export const updateUseDefaultSender = (userId, isUse) => {
  return (dispatch) => {
    dispatch(updateUseDefaultSenderStart());
    axios
      .put('/api/booking-presets/usesendertemplate', { userId, isUse })
      .then((resp) => {
        dispatch(updateUseDefaultSenderSuccess(resp.data.isUse));
      })
      .catch((err) => console.log(err) /* updateUseDefaultSenderFailed(err) */);
  };
};

//RECIPIENT ID - UPDATE
export const updateDefaultRecipientStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_RECIPIENT_START,
  };
};

export const updateDefaultRecipientFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_RECIPIENT_FAILED,
    error,
  };
};

export const updateDefaultRecipientSuccess = (defaultId) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_RECIPIENT_SUCCESS,
    defaultId,
  };
};

export const updateDefaultRecipient = (userId, recipientId) => {
  return (dispatch) => {
    dispatch(updateDefaultRecipientStart());
    axios
      .put('/api/booking-presets/recipient-template', { userId, recipientId })
      .then((resp) => {
        dispatch(updateDefaultRecipientSuccess(resp.data.defaultId));
      })
      .catch((err) => console.log(err) /* updateDefaultRecipientFailed(err) */);
  };
};

//USE RECIPIENT - UPDATE
export const updateUseDefaultRecipientStart = () => {
  return {
    type: actionTypes.USE_DEFAULT_RECIPIENT_START,
  };
};

export const updateUseDefaultRecipientFailed = (error) => {
  return {
    type: actionTypes.USE_DEFAULT_RECIPIENT_FAILED,
    error,
  };
};

export const updateUseDefaultRecipientSuccess = (isUse) => {
  return {
    type: actionTypes.USE_DEFAULT_RECIPIENT_SUCCESS,
    isUse,
  };
};

export const updateUseDefaultRecipient = (userId, isUse) => {
  return (dispatch) => {
    dispatch(updateUseDefaultRecipientStart());
    axios
      .put('/api/booking-presets/userecipienttemplate', { userId, isUse })
      .then((resp) => {
        dispatch(updateUseDefaultRecipientSuccess(resp.data.isUse));
      })
      .catch((err) => console.log(err) /* updateUseDefaultRecipientFailed(err) */);
  };
};

//INVOICE FORM PRESET VALUES

export const updateInvoiceFormPresetStart = () => {
  return {
    type: actionTypes.UPDATE_INVOICE_FORM_PRESET_START,
  };
};

export const updateInvoiceFormPresetFailed = (error) => {
  return {
    type: actionTypes.UPDATE_INVOICE_FORM_PRESET_FAILED,
    error,
  };
};

export const updateInvoiceFormPresetSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_INVOICE_FORM_PRESET_SUCCESS,
    payload,
  };
};

export const updateInvoiceFormPreset = ({
  invoiceFormPreset,
  productsFilteredColums,
  voec,
  userId,
  token,
}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateInvoiceFormPresetStart());
    axios
      .put(
        '/api/booking-presets/booking-invoice-form',
        { userId, invoiceFormPreset, productsFilteredColums, voec },
        config
      )
      .then((resp) => {
        dispatch(updateInvoiceFormPresetSuccess(resp.data.results));
      })
      .catch((err) => {
        dispatch(updateInvoiceFormPresetFailed(err));
      });
  };
};

export const updateAutoIncrementInvoiceNumberSuccess = (payload) => {
  //type check
  if (typeof payload !== 'boolean') return;

  return {
    type: actionTypes.UPDATE_AUTO_INCREMENT_INVOICE_NUMBER,
    payload,
  };
};

export const updateAutoIncrementInvoiceNumber = ({ autoIncrement, userId, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    axios
      .put('/api/booking-presets/auto-increment-invoice-number', { userId, autoIncrement }, config)
      .then((resp) => {
        dispatch(updateAutoIncrementInvoiceNumberSuccess(resp.data.results));
      })
      .catch((_) => {});
  };
};
export const updateInvoiceNumberSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_INVOICE_NUMBER,
    payload,
  };
};

export const updateInvoiceNumber = ({ invoiceNumber, userId, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    axios
      .put('/api/booking-presets/update-invoice-number', { userId, invoiceNumber }, config)
      .then((resp) => {
        dispatch(updateInvoiceNumberSuccess(resp.data.results));
      })
      .catch((_) => {});
  };
};

export const updateCompanyAutocompleteStart = () => {
  return {
    type: actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_START,
  };
};

export const updateCompanyAutocompleteFailed = (error) => {
  let message;
  error.response ? (message = error.response.data.message) : (message = null);
  return {
    type: actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_FAILED,
    error: message,
  };
};

export const updateCompanyAutocompleteSuccess = (isUse) => {
  return {
    type: actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_SUCCESS,
    isUse,
  };
};

export const updateCompanyAutocomplete = (userId, isUse) => {
  return (dispatch) => {
    dispatch(updateCompanyAutocompleteStart());
    axios
      .put('/api/booking-presets/update-company-autocomplete', { userId, isUse })
      .then((resp) => {
        dispatch(updateCompanyAutocompleteSuccess(resp.data.isUse));
      })
      .catch((err) => dispatch(updateCompanyAutocompleteFailed(err)));
  };
};

// DESCRIPTION

export const updateBookingPresetDescriptionStart = () => {
  return {
    type: actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_START,
  };
};

export const updateBookingPresetDescriptionFailed = (error) => {
  let message;
  error.response ? (message = error.response.data.message) : (message = null);
  return {
    type: actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_FAILED,
    error: message,
  };
};

export const updateBookingPresetDescriptionSuccess = ({ defaultDescription, descriptions }) => {
  return {
    type: actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_SUCCESS,
    defaultDescription,
    descriptions,
  };
};

export const updateBookingPresetDescription = ({
  userId,
  token,
  defaultDescription,
  descriptions,
}) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    dispatch(updateBookingPresetDescriptionStart());
    axios
      .put(
        '/api/booking-presets/update-description',
        { userId, defaultDescription, descriptions },
        config
      )
      .then((resp) => {
        const { defaultDescription, descriptions } = resp.data.results;
        dispatch(updateBookingPresetDescriptionSuccess({ defaultDescription, descriptions }));
      })
      .catch((err) => dispatch(updateBookingPresetDescriptionFailed(err)));
  };
};

// DEFAULT LABEL PRINT FORMAT
export const updateBookingPresetPrintFormatStart = (carrier) => {
  let type;
  if (carrier === CARRIERS.DHLFREIGHT) type = actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_START;
  if (carrier === CARRIERS.UPS) type = actionTypes.UPDATE_UPS_PRINT_FORMAT_START;
  if (carrier === CARRIERS.MATKAHUOLTO) type = actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_START;
  if (!type) return;
  return {
    type,
  };
};

export const updateBookingPresetPrintFormatFailed = (error, carrier) => {
  let type;
  if (carrier === CARRIERS.DHLFREIGHT) type = actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_FAILED;
  if (carrier === CARRIERS.UPS) type = actionTypes.UPDATE_UPS_PRINT_FORMAT_FAILED;
  if (carrier === CARRIERS.MATKAHUOLTO) type = actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_FAILED;
  if (!type) return;

  let message;
  error.response ? (message = error.response.data.message) : (message = null);
  return {
    type,
    error: message,
  };
};

export const updateBookingPresetPrintFormatSuccess = (payload, carrier) => {
  let type;
  if (carrier === CARRIERS.DHLFREIGHT) type = actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_SUCCESS;
  if (carrier === CARRIERS.UPS) type = actionTypes.UPDATE_UPS_PRINT_FORMAT_SUCCESS;
  if (carrier === CARRIERS.MATKAHUOLTO) type = actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_SUCCESS;
  if (!type) return;

  return {
    type,
    payload,
  };
};

export const updateBookingPresetPrintFormat = ({ userId, token, carrier, printFormat }) => {
  //validation
  if (!validForCarrierSpecificPrintOptions.includes(carrier)) return;

  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    dispatch(updateBookingPresetPrintFormatStart(carrier));
    axios
      .put('/api/booking-presets/update-print-format', { userId, carrier, printFormat }, config)
      .then((resp) => {
        dispatch(updateBookingPresetPrintFormatSuccess(resp.data.updatedPrintFormat, carrier));
      })
      .catch((err) => dispatch(updateBookingPresetPrintFormatFailed(err, carrier)));
  };
};

//NOTIFICATION PRESETS

export const updateNotificationsPresetStart = () => {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_PRESET_START,
  };
};

export const updateNotificationsPresetFailed = (error) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_PRESET_FAILED,
    error,
  };
};

export const updateNotificationsPresetSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_PRESET_SUCCESS,
    payload,
  };
};

export const updateNotificationsPreset = ({
  senderEmailNotifications,
  recipientEmailNotifications,
  userId,
  token,
}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateNotificationsPresetStart());
    axios
      .put(
        '/api/booking-presets/email-notifications',
        { userId, senderEmailNotifications, recipientEmailNotifications },
        config
      )
      .then((resp) => {
        dispatch(updateNotificationsPresetSuccess(resp.data.results));
      })
      .catch((err) => {
        dispatch(updateNotificationsPresetFailed(err));
      });
  };
};
