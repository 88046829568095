import moment from 'moment';

export const serializeExpeditorsShipmentDetails = (response) => {
  try {
    const results = response[0];
    const details = {
      trackingNumber: results.id,
      status: results.status,
      lastUpdateTime: moment(results.lastUpdateTime).format('LLL'),
      transportationMode: results.mode,
      serviceType: results.serviceType,

      description: results.goodsDesc,
      quantity: `${results.quantity.value} ${results.quantity.units}`,
      weight: `${results.weight.value} ${results.weight.units}`,
      volume: `${results.volume.value} ${results.volume.units}`,

      shipper: results.shipper,
      consignee: results.consignee,
      originName: results.origin.name,
      originCode: results.origin.code,
      destinationName: results.destination.name,
      destinationCode: results.destination.code,

      flight: results.voyageFlight,
      flightCarrier: results.carrier.name,
    };

    return details;
  } catch {
    return null;
  }
};
export const serializeGeodisShipmentDetails = (results) => {
  try {
    const { ShipmentDetails, GoodsDetails } = results;
    const { consignor, consignee, sendingAgent, receivingAgent, controllingParty } =
      ShipmentDetails.Part.reduce(
        (acc, curr) => {
          if (curr.Role === 'Consignor') {
            acc['consignor'] = curr.Address;
          }
          if (curr.Role === 'Consignee') {
            acc['consignee'] = curr.Address;
          }
          if (curr.Role === 'SendingAgent') {
            acc['sendingAgent'] = curr.Address;
          }
          if (curr.Role === 'ReceivingAgent') {
            acc['receivingAgent'] = curr.Address;
          }
          if (curr.Role === 'ShipmentControllingParty') {
            acc['controllingParty'] = curr.Address;
          }
          return acc;
        },
        { consignor: {}, consignee: {}, sendingAgent: {}, receivingAgent: {}, controllingParty: {} }
      );

    const details = {
      trackingNumber: ShipmentDetails.ShipmentNumber,
      masterBill: ShipmentDetails.MasterBill,
      houseBill: ShipmentDetails.HouseBill,
      transportationMode: ShipmentDetails.TransportMode,
      serviceLevel: ShipmentDetails.ServiceLevel,
      incoTerms: ShipmentDetails.IncoTerms,
      etd: ShipmentDetails.ETD.length > 0 ? moment(ShipmentDetails.ETD).format('LLL') : '',
      eta: ShipmentDetails.ETA.length > 0 ? moment(ShipmentDetails.ETA).format('LLL') : '',
      ata: ShipmentDetails.ATA.length > 0 ? moment(ShipmentDetails.ATA).format('LLL') : '',
      atd: ShipmentDetails.ATD.length > 0 ? moment(ShipmentDetails.ATD).format('LLL') : '',
      goodsDescription: GoodsDetails.Description,
      numberOfContainers: GoodsDetails.NoOfContainers,
      pieces: GoodsDetails.Pieces,
      chargeableWeight: `${GoodsDetails.ChargeableMeasure} ${GoodsDetails.ChargeableMeasurementType}`,
      actualWeight: `${GoodsDetails.ActualWeight} ${GoodsDetails.ActualWeightMeasurementType}`,
      volume: `${GoodsDetails.Volume} ${GoodsDetails.VolumeMeasurementType}`,

      consignorName: consignor.Name,
      consignorAddressLine1: consignor.AddressLine1,
      consignorAddressLine2: consignor.AddressLine2,
      consignorPostalCode: consignor.PostCode,
      consignorCity: consignor.City,
      consignorState: consignor.State,

      consigneeName: consignee.Name,
      consigneeAddressLine1: consignee.AddressLine1,
      consigneeAddressLine2: consignee.AddressLine2,
      consigneePostalCode: consignee.PostCode,
      consigneeCity: consignee.City,
      consigneeState: consignee.State,

      sendingAgentName: sendingAgent.Name,
      sendingAgentAddressLine1: sendingAgent.AddressLine1,
      sendingAgentAddressLine2: sendingAgent.AddressLine2,
      sendingAgentPostalCode: sendingAgent.PostCode,
      sendingAgentCity: sendingAgent.City,
      sendingAgentState: sendingAgent.State,

      receivingAgentName: receivingAgent.Name,
      receivingAgentAddressLine1: receivingAgent.AddressLine1,
      receivingAgentAddressLine2: receivingAgent.AddressLine2,
      receivingAgentPostalCode: receivingAgent.PostCode,
      receivingAgentCity: receivingAgent.City,
      receivingAgentState: receivingAgent.State,

      controllingPartyName: controllingParty.Name,
      controllingPartyAddressLine1: controllingParty.AddressLine1,
      controllingPartyAddressLine2: controllingParty.AddressLine2,
      controllingPartyPostalCode: controllingParty.PostCode,
      controllingPartyCity: controllingParty.City,
      controllingPartyState: controllingParty.State,
    };

    return details;
  } catch (e) {
    return null;
  }
};
