import React from 'react';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
    zIndex: 1000,
  },
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default function IntegrationReactSelect({
  lang,
  id,
  countries,
  translations,
  value,
  statesOrRegions,
  width,
  height,
  translateY,
  change,
  maxMenuHeight,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const label = lang; //for country display purpose only, corresponds to language set (se, fi and 'en' supported)
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  let suggestions;
  let suggestionValue;
  let placeholder = '';
  let selectLabel = '';
  const inputId = id ? 'react-select-' + id : 'react-select-single';

  if (countries) {
    suggestions = countries.map((country) => {
      return {
        value: { name: country.en, code: country.countryCode },
        label: country[label],
      };
    });
    suggestionValue = suggestions.filter((suggestion) => suggestion.value.name === value)[0];
    selectLabel = translations.countrySelectLabel;
    placeholder = `${translations.countryPlaceholder} *`;
  } else if (statesOrRegions) {
    suggestions = statesOrRegions.map((state) => {
      return {
        value: { name: state.name, code: state.code },
        label: state.name,
      };
    });
    suggestionValue = suggestions.filter((suggestion) => suggestion.value.code === value)[0];
    selectLabel = translations.stateSelectLabel;
    placeholder = `${translations.statePlaceholder} *`;
  }

  let widthStyle = '100%';
  if (width) width = `${width}px`;
  let heightStyle = '100%';
  if (height) height = `${height}px`;

  return (
    <div
      className={classes.container}
      style={{
        height: `${heightStyle}`,
        width: `${widthStyle}`,
        transform: `translateY(${translateY || 12}px)`,
      }}
    >
      <div className={classes.root}>
        <NoSsr>
          <Select
            onFocus={(e) => {
              if (e.target.autocomplete) {
                e.target.autocomplete = 'nope';
              }
            }}
            classes={classes}
            styles={selectStyles}
            inputId={inputId}
            TextFieldProps={{
              label: selectLabel,
              InputLabelProps: {
                htmlFor: { inputId },
                shrink: true,
              },
            }}
            options={suggestions}
            components={components}
            value={suggestionValue}
            onChange={(e) => (e === null ? change('') : change(e))}
            placeholder={placeholder}
            maxMenuHeight={maxMenuHeight}
          />
        </NoSsr>
      </div>
    </div>
  );
}
