import React, { useState, useEffect } from 'react';
import {
  stateRegionCountries,
  stateRegionPaths,
  countriesWithoutZip,
} from '../../../../services/helpers/common';
import { fetchCountries, bookingValidationUpdate } from '../../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import SelectList from '../../../SelectionLists/IntegrationReactSelect/IntegrationReactSelect';
import ContactTemplate from '../../Templates/Contacts';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Switch, Checkbox, Tooltip, Button } from '@material-ui/core';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { DOMESTIC } from '../../../../services/types';
import AddressAutocomplete from '../../../AddressAutocomplete/AddressAutocomplete';
import FavouriteTextField from '../../../FavouriteTextField/FavouriteTextField';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  countryContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: '15px',
    gridAutoFlow: 'row',
    width: '100%',
    placeItems: 'center',
  },
  addressContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
    'personal-field address-field'
    'personal-field address-field'
    'personal-field address-field'
    'personal-field address-field'
    'residential-field address-field'
    `,
    gridColumnGap: '15px',
    width: '100%',
    placeItems: 'center',
  },
  countrySelectList: {
    width: '100%',
    gridColumn: ({ hasStateOrRegion }) => (!hasStateOrRegion ? '1 / span 4' : '1 / span 3'),
    height: 60,
  },
  stateRegionSelectList: {
    width: '100%',
    gridColumn: '4 / span 1',
    height: 60,
  },
  textField: {
    width: '100%',
    marginBottom: '1px',
  },
  personalFields: {
    gridArea: 'personal-field',
    width: '100%',
  },
  streetFields: {
    gridArea: 'address-field',
    width: '100%',
  },
  residentialAddressContainer: {
    gridArea: 'residential-field',
    width: '100%',
    height: '50px',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  templateOptions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    transform: 'translateX(-10px)',
    gap: '5px',
    '& > div': {
      display: 'flex',
      height: 25,
      alignItems: 'center',
    },
  },
  addressFooter: {
    display: 'grid',
    gridTemplateColumns: 'max-content minmax(max-content, 400px) max-content',
    gap: '10px',
    padding: '10px 0',
    height: '40px',
  },
  checkbox: {
    padding: 0,
    paddingRight: 5,
    fontSize: '1.7rem',
  },
  templateComment: {
    padding: '10px',
    minWidth: '30px',
    height: '44px',
    transform: 'translateX(-10px)',
    color: blue[700],
  },
  [theme.breakpoints.down('xs')]: {
    countryContainer: {
      gridTemplateColumns: '1fr',
    },
    countrySelectList: {
      gridColumn: 'span 1 !important',
    },
    stateRegionSelectList: {
      gridColumn: '1 / span 1 !important',
    },
    addressContainer: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
      'personal-field'
      'personal-field'
      'personal-field'
      'personal-field'
      'address-field'
      'address-field'
      'address-field'
      'address-field'
      'address-field'
      'residential-field'
      `,
    },
    addressFooter: {
      gridTemplateColumns: '1fr',
      height: '115px',
      padding: '20px 20px 0px 20px',
    },
  },
}));

//Change in below fields will reset data if services and cost has previously been requested and exists in redux store
const servicesResetFields = ['zip', 'city', 'countryCode'];

const RecipientInputs = (props) => {
  const [recipientData, setRecipientData] = useState({
    templateId: null,
    company: '',
    contact: '',
    phone: '',
    email: '',
    city: '',
    zip: '',
    address1: '',
    address2: '',
    address3: '',
    countryName: '',
    countryCode: '',
    stateRegion: '',
    contacts: [],
  });

  const [errors, setError] = useState({
    company: false,
    contact: false,
    phone: false,
    email: false,
    city: false,
    zip: false,
    address1: false,
    countryName: false,
    countryCode: false,
    stateRegion: false,
  });

  const countries = useSelector((state) => state.countries.countriesData);
  const booking = useSelector((state) => state.booking);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { companyAutocomplete } = useSelector((state) => state.bookingPresets);
  const token = useSelector((state) => state.auth.token);

  const [stateRegionList, setStateRegionList] = useState([]);
  const [hasStateOrRegion, setHasStateOrRegion] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (countries === null) {
      dispatch(fetchCountries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.recipientData) {
      //Handle state/region/province and alike
      if (stateRegionCountries.includes(props.recipientData.countryCode)) {
        if (
          props.recipientData.countryCode !== recipientData.countryCode ||
          stateRegionList.length === 0
        ) {
          fetchStatesRegions(props.recipientData.countryCode);
          !hasStateOrRegion && setHasStateOrRegion(true);
        }
      } else {
        hasStateOrRegion && setHasStateOrRegion(false);
        stateRegionList.length > 0 && setStateRegionList([]);
      }

      //check for change in movement direction of shipment
      props.checkMovementDirectionChange(props.recipientData.countryCode);

      setRecipientData({
        templateId: props.recipientData.templateId,
        company: props.recipientData.company || '',
        contact: props.recipientData.contact || '',
        phone: props.recipientData.phone || '',
        email: props.recipientData.email || '',
        city: props.recipientData.city || '',
        zip: props.recipientData.zip || '',
        address1: props.recipientData.address1 || '',
        address2: props.recipientData.address2 || '',
        address3: props.recipientData.address3 || '',
        countryName: props.recipientData.countryName || '',
        countryCode: props.recipientData.countryCode || '',
        stateRegion: props.recipientData.stateRegion || '',
        contacts: props.recipientData.contacts || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recipientData]);

  const handleContactFromTemplate = (name, phone, email) => {
    setRecipientData({ ...recipientData, contact: name, phone });
    props.updateContact(name, phone, email);
  };

  const handleCountryChange = (e) => {
    props.checkServicesReset('countryCode', e.value.code);
    //check for change in movement direction of shipment
    props.checkMovementDirectionChange(e.value.code);

    if (stateRegionCountries.includes(e.value.code)) {
      fetchStatesRegions(e.value.code);
      setHasStateOrRegion(true);
    } else {
      hasStateOrRegion && setHasStateOrRegion(false);
      stateRegionList.length > 0 && setStateRegionList([]);
    }

    setRecipientData({
      ...recipientData,
      countryName: e.value.name,
      countryCode: e.value.code,
      stateRegion: '',
    });
    props.textBlur({
      ...recipientData,
      countryName: e.value.name,
      countryCode: e.value.code,
      stateRegion: '',
    });
  };

  const fetchStatesRegions = (countryCode) => {
    const path = stateRegionPaths[countryCode];

    axios
      .get(`/api/countries/states-or-regions/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        setStateRegionList(resp.data.statesOrRegions);
      })
      .catch((e) => {
        setStateRegionList([]);
      });
  };

  const handleStateChange = (e) => {
    setRecipientData({ ...recipientData, stateRegion: e.value.code });
    props.textBlur({ ...recipientData, stateRegion: e.value.code });
  };
  const handleTextChange = (field) => (e) => {
    if (booking.updated) dispatch(bookingValidationUpdate(null));
    setRecipientData({ ...recipientData, [field]: e.target.value });
  };

  const handleTextBlur = (field) => (e) => {
    let value;
    let errorExit = false;

    //clear services in redux if needed
    if (servicesResetFields.includes(field)) {
      props.checkServicesReset(field, e.target.value);
    }

    if (field === 'zip') {
      value = e.target.value.replace(/ /g, '');
      if (countriesWithoutZip.includes(recipientData.countryCode)) errorExit = true;
    } else {
      value = e.target.value.trim();
    }

    //set error indicator, as of now only if field is empty
    value === '' && !errorExit
      ? setError({ ...errors, [e.target.name]: true })
      : errors[e.target.name] && setError({ ...errors, [e.target.name]: false });

    setRecipientData({ ...recipientData, [e.target.name]: value });
    props.textBlur({ ...recipientData, [e.target.name]: value });
  };

  const handleTrimPaste =
    ({ field, pattern, maxLength }) =>
    (e) => {
      const printedText = e.clipboardData.getData('text/plain');

      if (printedText.length > maxLength) {
        const trimmedText = printedText.replace(pattern, '');
        setRecipientData({ ...recipientData, [field]: trimmedText });
        e.preventDefault();
      }
    };

  const handleAutocompleteTextChange = (field) => (value) => {
    setRecipientData({ ...recipientData, [field]: value });
  };
  const handleAddressTextClose = (field) => (value) => {
    //set error indicator, as of now only if field is empty
    value === ''
      ? setError({ ...errors, [field]: true })
      : errors[field] && setError({ ...errors, [field]: false });
    setRecipientData({ ...recipientData, [field]: value });
    props.textBlur({ ...recipientData, [field]: value });
  };

  const updateFieldsFromAutocomplete = (updateFrom) => (fields, countryFields, addressFields) => {
    let serviceReset = false;
    if ('zip' in fields) serviceReset = props.checkServicesReset('zip', fields.zip);

    if (Object.keys(countryFields).includes('countryCode')) {
      if (!serviceReset)
        serviceReset = props.checkServicesReset('countryCode', countryFields['countryCode']);

      const country = countries.find(
        (country) => country.countryCode === countryFields['countryCode']
      );

      //Guard against country codes not in list
      if (country) {
        fields['countryName'] = country['en'];
        fields['countryCode'] = countryFields['countryCode'];
      }
      if (stateRegionCountries.includes(countryFields['countryCode'])) {
        if ('stateRegion' in countryFields) fields['stateRegion'] = countryFields['stateRegion'];
      } else {
        hasStateOrRegion && setHasStateOrRegion(false);
        stateRegionList.length > 0 && setStateRegionList([]);
      }
    }

    if (updateFrom !== 'address1' && Object.keys(addressFields).includes('streetName')) {
      let address1 = addressFields['streetName'];
      if (addressFields['streetNumber']) address1 += ` ${addressFields['streetNumber']}`;
      fields['address1'] = address1;
    }
    setRecipientData({ ...recipientData, ...fields });
    props.textBlur({ ...recipientData, ...fields });
    props.refresh();
  };

  const classes = useStyles({ hasStateOrRegion });

  return (
    <div className={classes.root}>
      <div className={classes.countryContainer}>
        {countries ? (
          <div className={classes.countrySelectList}>
            <SelectList
              lang={lang}
              translations={props.translations.selectList}
              change={handleCountryChange}
              value={recipientData.countryName}
              height="65"
              countries={countries.sort((a, b) => a[lang].localeCompare(b[lang]))}
              id="recipient-country"
            />
          </div>
        ) : null}

        {stateRegionList.length > 0 ? (
          <div className={classes.stateRegionSelectList}>
            <SelectList
              lang={lang}
              translations={props.translations.selectList}
              change={handleStateChange}
              value={recipientData.stateRegion}
              height="65"
              statesOrRegions={stateRegionList}
              id="recipient-state"
            />
          </div>
        ) : null}
      </div>
      <div className={classes.addressContainer}>
        <div className={classes.personalFields}>
          <div className={classes.textField}>
            {companyAutocomplete ? (
              <AddressAutocomplete
                inputValue={recipientData.company}
                setInputValue={handleAutocompleteTextChange('company')}
                searchTypes={['establishment']}
                country={recipientData.countryCode}
                maxLength={35}
                updateFieldsFromAutocomplete={updateFieldsFromAutocomplete('company')}
                label={props.translations.company}
                name="company"
                onClose={handleAddressTextClose('company')}
                onBlur={handleTextBlur('company')}
                margin="normal"
                error={
                  errors.company ||
                  (booking.updated === 'recipient' && recipientData.company === '')
                }
                required
              />
            ) : (
              <TextField
                id="recipient-company"
                inputProps={{ maxLength: 35, autoComplete: 'new-password' }}
                label={props.translations.company}
                className={classes.textField}
                name="company"
                value={recipientData.company}
                onChange={handleTextChange('company')}
                onBlur={handleTextBlur('company')}
                margin="normal"
                error={
                  errors.company ||
                  (booking.updated === 'recipient' && recipientData.company === '')
                }
                required
              />
            )}
          </div>
          <TextField
            id="recipient-contact"
            inputProps={{ maxLength: 35 }}
            label={props.translations.contact}
            className={classes.textField}
            name="contact"
            value={recipientData.contact}
            onChange={handleTextChange('contact')}
            onBlur={handleTextBlur('contact')}
            margin="normal"
            required={props.flow !== DOMESTIC}
            error={
              errors.contact || (booking.updated === 'recipient' && recipientData.contact === '')
            }
            // eslint-disable-next-line
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ContactTemplate
                    disabled={recipientData.contacts.length === 0}
                    contacts={recipientData.contacts.length > 0 ? recipientData.contacts : null}
                    handleContact={handleContactFromTemplate}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="recipient-phone"
            inputProps={{ maxLength: 15 }}
            label={props.translations.phone}
            className={classes.textField}
            name="phone"
            value={recipientData.phone}
            onChange={handleTextChange('phone')}
            onBlur={handleTextBlur('phone')}
            onPaste={handleTrimPaste({ field: 'phone', pattern: /[\s-]+/g, maxLength: 15 })}
            margin="normal"
            error={errors.phone || (booking.updated === 'recipient' && recipientData.phone === '')}
            required
          />
          <TextField
            id="recipient-email"
            inputProps={{ maxLength: 50 }}
            label={props.translations.email}
            className={classes.textField}
            name="email"
            value={recipientData.email}
            onChange={handleTextChange('email')}
            onBlur={handleTextBlur('email')}
            margin="normal"
          />
        </div>

        <div className={classes.streetFields}>
          <div className={classes.textField}>
            <AddressAutocomplete
              inputValue={recipientData.address1}
              setInputValue={handleAutocompleteTextChange('address1')}
              searchTypes={['address']}
              country={recipientData.countryCode}
              maxLength={35}
              updateFieldsFromAutocomplete={updateFieldsFromAutocomplete('address1')}
              label={`${props.translations.addressrow} 1`}
              name="address1"
              onClose={handleAddressTextClose('address1')}
              onBlur={handleTextBlur('address1')}
              margin="normal"
              helperText={`(${props.translations.streetAddress})`}
              error={
                errors.address1 ||
                (booking.updated === 'recipient' && recipientData.address1 === '')
              }
              required
            />
          </div>
          <TextField
            id="recipient-address2"
            inputProps={{ maxLength: 35 }}
            label={`${props.translations.addressrow} 2`}
            className={classes.textField}
            name="address2"
            value={recipientData.address2}
            onChange={handleTextChange('address2')}
            onBlur={handleTextBlur('address2')}
            margin="normal"
          />
          <TextField
            id="recipient-address3"
            inputProps={{ maxLength: 35 }}
            label={`${props.translations.addressrow} 3`}
            className={classes.textField}
            name="address3"
            value={recipientData.address3}
            onChange={handleTextChange('address3')}
            onBlur={handleTextBlur('address3')}
            margin="normal"
          />
          <div className={classes.textField}>
            <AddressAutocomplete
              inputValue={recipientData.zip}
              setInputValue={handleAutocompleteTextChange('zip')}
              searchTypes={['(regions)']}
              country={recipientData.countryCode}
              maxLength={9}
              updateFieldsFromAutocomplete={updateFieldsFromAutocomplete('zip')}
              label={props.translations.zip}
              name="zip"
              onClose={handleAddressTextClose('zip')}
              onBlur={handleTextBlur('zip')}
              margin="normal"
              error={errors.zip || (booking.updated === 'recipient' && recipientData.zip === '')}
              required
            />
          </div>
          <TextField
            id="recipient-city"
            inputProps={{ maxLength: 30 }}
            label={props.translations.city}
            className={classes.textField}
            name="city"
            value={recipientData.city}
            onChange={handleTextChange('city')}
            onBlur={handleTextBlur('city')}
            margin="normal"
            error={errors.city || (booking.updated === 'recipient' && recipientData.city === '')}
            required
          />
        </div>

        <div className={classes.residentialAddressContainer}>
          <Checkbox
            id="residential-checkbox"
            checked={props.residentialAddress}
            onChange={props.handleResidentialAddressChange}
            className={classes.checkbox}
          />
          <label htmlFor="residential-checkbox">
            {props.translations.onlinebooking.residentialAddress}
          </label>
        </div>
      </div>
      <div className={classes.addressFooter}>
        <div className={classes.templateOptions}>
          {recipientData.templateId && (
            <div>
              <Switch
                id="update-recipient-template-switch"
                checked={props.updateTemplate}
                onChange={props.handleUpdateTemplateChange}
              />
              <label htmlFor="update-recipient-template-switch">
                {props.translations.updateTemplate}
              </label>
            </div>
          )}
          <div>
            <Switch
              id="save-recipient-switch"
              checked={props.saveAddress}
              onChange={props.handleSaveAddressChange}
            />
            <label htmlFor="save-recipient-switch">
              {recipientData.templateId
                ? props.translations.saveAsNewRecipient
                : props.translations.onlinebooking.saveRecipient}
            </label>
          </div>
        </div>
        {(props.updateTemplate || props.saveAddress) && (
          <FavouriteTextField
            label={'Favourite name'}
            value={props.templateName}
            onChange={props.templateNameChange}
          />
        )}
        {(props.updateTemplate || props.saveAddress) && (
          <Tooltip title={props.translations.addCommentForTemplate}>
            <Button
              onClick={props.handleUpdateCommentModal}
              className={classes.templateComment}
              aria-label="Add comment"
            >
              <AddCommentIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default RecipientInputs;
