import { CARRIERS } from '../types';

export const validForCarrierSpecificPrintOptions = [
  CARRIERS.UPS,
  CARRIERS.DHLFREIGHT,
  CARRIERS.MATKAHUOLTO,
];
const onlineBookingPdfA4Formats = ['PNG', 'PDFA4'];

export const getPrinterOptionsByCarrier = (carrier, translations) => {
  switch (carrier) {
    case CARRIERS.UPS:
      return [
        { value: 'PDFA4', label: 'PDF - A4' },
        { value: 'PDF6', label: 'PDF - (4" X 6")' },
        { value: 'PDFX2', label: `PDF - A4 (${translations.twoPerPage})` },
        {
          value: 'ZPL6',
          label: `Zebra ${translations.onlinebooking.thermalprint} (4" X 6")`,
        },
        {
          value: 'ZPL8',
          label: `Zebra ${translations.onlinebooking.thermalprint} (4" X 8")`,
        },
        // { value: 'PNG', label: 'PDF - (A4 fallback)' },
      ];
    case CARRIERS.MATKAHUOLTO:
      return [
        { value: 'PDFA4', label: 'PDF - A4' },
        { value: 'PDF6', label: 'PDF - (4" X 6")' },
        {
          value: 'ZPL6',
          label: `Zebra ${translations.onlinebooking.thermalprint} (4" X 6")`,
        },
      ];
    case CARRIERS.HRX:
      return [
        { value: 'PDFA4', label: 'PDF - A4' },
        { value: 'PDF', label: 'PDF - 100mm X 140mm' },
      ];
    case CARRIERS.DHLFREIGHT:
      return [
        { value: 'PDFA4', label: 'PDF - A4' },
        { value: 'PDF6', label: 'PDF - 4" X 8"' },
        { value: 'PDFX2', label: `PDF - A4 (${translations.twoPerPage})` },
        // {
        //   value: 'ZPL6',
        //   label: `Zebra ${translations.onlinebooking.thermalprint} (4" X 6")`,
        // },
        {
          value: 'ZPL8',
          label: `Zebra ${translations.onlinebooking.thermalprint} (4" X 8")`,
        },
      ];
    default:
      return [{ value: 'PDFA4', label: 'PDF - A4' }];
  }
};

export const getDefaultPrinterOptionFromOptions = (printerOptions, defaultPrintFormat) =>
  printerOptions.find(
    ({ value }) =>
      value === defaultPrintFormat ||
      (onlineBookingPdfA4Formats.includes(defaultPrintFormat) &&
        onlineBookingPdfA4Formats.includes(value))
  ) || printerOptions[0];

export const extractLabelTypes = (responseLabels) => {
  const { labels, otherDocs } = responseLabels.reduce(
    (acc, curr) => {
      if (curr.type === 'label') {
        acc.labels.push(curr);
      }

      if (curr.type === 'other') {
        acc.otherDocs.push(curr);
      }

      return acc;
    },
    { labels: [], otherDocs: [] }
  );

  return { labels, otherDocs };
};
