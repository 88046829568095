import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { THIRD_PARTY, DOMESTIC, EXPORT, IMPORT } from '../../services/types';

const sizeStyles = {
  small: {
    padding: '5px 8px',
    fontSize: '1rem',
    maxWidth: '150px',
  },
  medium: {
    padding: '10px 15px',
    fontSize: '1.05rem',
    maxWidth: '120px',
  },
  large: {
    padding: '10px 25px',
    fontSize: '1.2rem',
    maxWidth: '150px',
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      padding: ({ size }) => sizeStyles[size]['padding'],
      outline: 'none',
      width: '100%',
      border: '1px solid gray',
      fontSize: ({ size }) => sizeStyles[size]['fontSize'],
      backgroundColor: '#f5f5f5',
      borderRadius: '25px',
      maxWidth: ({ size }) => sizeStyles[size]['maxWidth'],
      '&:hover': {
        cursor: 'pointer',
      },
      '&:not(:first-child)': {
        marginLeft: '5px',
      },
    },
  },
  selected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    border: `1px solid black !important`,
    color: 'white',
  },
}));
const Flow = ({ translations, changeFlow, currentFlow, size = 'small' }) => {
  const classes = useStyles({ size });

  const flows = [
    { label: translations.export, value: EXPORT },
    { label: translations.import, value: IMPORT },
    { label: translations.domestic, value: DOMESTIC },
    { label: translations.thirdParty, value: THIRD_PARTY },
  ];
  return (
    <div className={classes.container}>
      {flows.map((flow) => (
        <button
          className={flow.value === currentFlow ? classes.selected : null}
          key={flow.value}
          onClick={() => changeFlow(flow.value)}
          type='button'
        >
          {flow.label}
        </button>
      ))}
    </div>
  );
};

export default Flow;
