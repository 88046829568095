import * as actionTypes from '../actions/actionTypes';

const initalState = {
	error: false,
	isFetched: false,
	eventTrackingNumbers: null
}

const reducer = (state = initalState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_EVENT_TRACKING_NUMBERS_SUCCESS:
			return {
				...state,
				isFetched: true,
				eventTrackingNumbers: action.eventTrackingNumbers
			}
		case actionTypes.EVENT_RESET:
			return {
				...state,
				isFetched: true,
				eventTrackingNumbers: null
			}
		default:
			return state;
	}
}

export default reducer;