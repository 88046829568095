import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CompanyAutocomplete = ({
  templateSelected,
  styles,
  defaultValue,
  options,
  handleCompanyBlur,
  label,
  index = '1',
}) => {
  const handleValueChange = newValue => {
    if (newValue) {
      templateSelected(newValue);
    }
  };

  return (
    <>
      <Autocomplete
        id={`company-templates-${index}`}
        className={styles}
        onChange={(event, newValue) => handleValueChange(newValue)}
        freeSolo
        defaultValue={defaultValue}
        onBlur={e => handleCompanyBlur(e)}
        options={options}
        getOptionSelected={(option, value) => {
          if (typeof option === 'object') {
            return option.company === value;
          } else {
            return option;
          }
        }}
        getOptionLabel={option => {
          if (typeof option === 'object') {
            return option.company;
          } else {
            return option;
          }
        }}
        renderInput={params => {
          //NOTE: temporary fix as chrome ignores autoComplete='off'
          const paramsChanged = Object.assign({}, params);
          paramsChanged['inputProps'] = Object.assign({}, params.inputProps);
          paramsChanged['inputProps'].autoComplete = 'new-password';
          paramsChanged['inputProps'].maxLength = 35;

          return (
            <TextField
              {...paramsChanged}
              label={label}
              name='company'
              onBlur={e => handleCompanyBlur(e)}
              required
              fullWidth
            />
          );
        }}
      />
    </>
  );
};

export default CompanyAutocomplete;
