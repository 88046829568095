import * as yup from 'yup';
import errors from '../../../translations/errors';
import { DOMESTIC, MATKAHUOLTO } from '../../../types';
import { countriesWithoutZip } from '../../../helpers/common';
import { MatkahuoltoHomeDeliveryServices } from '../../../../utils/carrier/lib';
import moment from 'moment';

export const senderAddressSchema = (lang = 'en') => {
  return yup.object().shape({
    // date: yup.date().min(new Date()),
    date: yup
      .date()
      .min(`${moment().format('YYYY-MM-DD')}T00:00:00`, errors.date.min[lang])
      .typeError(errors.date.typeerror[lang]),
    company: yup
      .string()
      .min(1, errors.company.min[lang])
      .max(35, errors.company.max[lang])
      .required(errors.company.required[lang]),
    contact: yup
      .string()
      .min(2, errors.contact.min[lang])
      .max(35, errors.contact.max[lang])
      .required(errors.contact.required[lang]),
    phone: yup
      .string()
      .min(2, errors.phone.min[lang])
      .max(15, errors.phone.max[lang])
      .required(errors.phone.required[lang]),
    email: yup.string().email(errors.email[lang]),
    city: yup
      .string()
      .min(2, errors.city.min[lang])
      .max(30, errors.city.max[lang])
      .required(errors.city.required[lang]),
    zip: yup.string().when('countryCode', {
      is: (countryCode) => !countriesWithoutZip.includes(countryCode),
      then: yup
        .string()
        .min(2, errors.zip.min[lang])
        .max(9, errors.zip.min[lang])
        .required(errors.zip.required[lang]),
      otherwise: yup.string().min(0),
    }),
    address1: yup
      .string()
      .min(5, errors.addressline.min[lang])
      .max(35, errors.addressline.max[lang])
      .required(errors.addressline.required[lang]),
    address2: yup.string().max(35),
    address3: yup.string().max(35),
    countryName: yup.string().required(errors.countryname.required[lang]),
    countryCode: yup.string().length(2, errors.countryname.required[lang]),
    //stateRegion: '',
  });
};
export const recipientAddressSchema = (lang = 'en') => {
  return yup.object().shape({
    company: yup
      .string()
      .min(1, errors.company.min[lang])
      .max(35, errors.company.max[lang])
      .required(errors.company.required[lang]),
    contact: yup.string().when('movementDirection', {
      is: (movementDirection) => {
        return movementDirection === DOMESTIC;
      },
      then: yup.string(),
      otherwise: yup
        .string()
        .min(2, errors.contact.min[lang])
        .max(35, errors.contact.max[lang])
        .required(errors.contact.required[lang]),
    }),
    phone: yup
      .string()
      .min(2, errors.phone.min[lang])
      .max(15, errors.phone.max[lang])
      .required(errors.phone.required[lang]),
    email: yup.string().email(errors.email[lang]),
    city: yup
      .string()
      .min(2, errors.city.min[lang])
      .max(30, errors.city.max[lang])
      .required(errors.city.required[lang]),
    zip: yup.string().when('countryCode', {
      is: (countryCode) => !countriesWithoutZip.includes(countryCode),
      then: yup
        .string()
        .min(2, errors.zip.min[lang])
        .max(9, errors.zip.min[lang])
        .required(errors.zip.required[lang]),
      otherwise: yup.string().min(0),
    }),
    address1: yup
      .string()
      .min(5, errors.addressline.min[lang])
      .max(35, errors.addressline.max[lang])
      .required(errors.addressline.required[lang]),
    address2: yup.string().max(35),
    address3: yup.string().max(35),
    countryName: yup.string().required(errors.countryname.required[lang]),
    countryCode: yup.string().length(2, errors.countryname.required[lang]),
    //stateRegion: '',
  });
};

export const shipmentDetailsSchema = (lang = 'en') =>
  yup.object().shape({
    reference: yup.string().max(35, errors.shippingreference.max[lang]),
    description: yup
      .string()
      .max(50, errors.commoditydescription.max[lang])
      .required(errors.commoditydescription.required[lang]),
    customsValue: yup.mixed().when(['documentsOnly', 'movementDirection'], {
      is: (documentsOnly, movementDirection) => !documentsOnly && movementDirection !== DOMESTIC,
      then: yup
        .number()
        .typeError(errors.customsvalue.typeerror[lang])
        .moreThan(0, errors.customsvalue.morethan[lang])
        .required('Not a Number'),
      otherwise: yup.mixed(),
    }),
  });

export const packagesSchema = (lang = 'en', unitSys = 'metric') =>
  yup.array().of(
    yup.object().shape({
      count: yup.number().min(1),
      height: yup
        .number()
        .typeError(errors.package.typeerror[lang])
        .min(1, errors.package.dimensions.min[lang])
        .required(),
      length: yup
        .number()
        .typeError(errors.package.typeerror[lang])
        .min(1, errors.package.dimensions.min[lang])
        .required(),
      width: yup
        .number()
        .typeError(errors.package.typeerror[lang])
        .min(1, errors.package.dimensions.min[lang])
        .required(),
      weight: yup
        .number()
        .typeError(errors.package.typeerror[lang])
        .min(0.1, errors.package.weight.min[lang])
        .required(),
    })
  );

export const bookingSchema = (lang = 'en') =>
  yup.object().shape({
    sender: senderAddressSchema(lang),
    recipient: recipientAddressSchema(lang),
    description: yup.string().max(35, errors.commoditydescription.max[lang]).required(),
    reference: yup.string().max(50, errors.shippingreference.max[lang]),
    date: yup.date(),
    customsValue: yup.mixed().when(['documentsOnly', 'movementDirection'], {
      is: (documentsOnly, movementDirection) => !documentsOnly && movementDirection !== DOMESTIC,
      then: yup
        .number()
        .typeError(errors.customsvalue.typeerror[lang])
        .moreThan(0, errors.customsvalue.morethan[lang])
        .required('Not a Number'),
      otherwise: yup.mixed(),
    }),
    // currency: yup.mixed().when('documentsOnly', {
    //   is: (documentsOnly) => !documentsOnly,
    //   then: yup.string().length(3),
    //   otherwise: yup.mixed(),
    // }),
    packages: packagesSchema(lang),
    serviceCode: validServiceCode(lang),
    documentsOnly: yup.bool().required(),
  });

export const serviceValidationSchema = (lang = 'en') =>
  yup.object().shape({
    serviceCode: validServiceCode(lang),
    alternateDeliveryAddress: yup.mixed().when(['carrier', 'serviceCode'], {
      is: (carrier, serviceCode) =>
        carrier === MATKAHUOLTO &&
        !MatkahuoltoHomeDeliveryServices.includes(parseFloat(serviceCode)),
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(errors.dropoff.required[lang])
        .typeError(errors.dropoff.required[lang]),
      otherwise: yup.mixed(),
    }),
    deliveryRemark: yup.mixed().when(['carrier', 'serviceCode'], {
      is: (carrier, serviceCode) => {
        return (
          carrier === MATKAHUOLTO &&
          MatkahuoltoHomeDeliveryServices.includes(parseFloat(serviceCode))
        );
      },
      then: yup
        .string()
        .min(3, errors.deliveryremark.min[lang])
        .max(50, errors.deliveryremark.max[lang])
        .required(),
      otherwise: yup.mixed(),
    }),
  });

export const validServiceCode = (lang = 'en') =>
  yup
    .string()
    .typeError(errors.servicecode.typeerror[lang])
    .min(2, errors.servicecode.length[lang])
    .max(3, errors.servicecode.length[lang]);

export const packagesTemplatesDimensionsSchema = (lang = 'en', unitSys = 'metric') =>
  yup.object().shape({
    height: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
    length: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
    width: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
  });

export const packagesTemplatesSchema = (lang = 'en', unitSys = 'metric') =>
  yup.object().shape({
    packaging: yup.string().max(3),
    templateName: yup
      .string()
      .min(1, errors.package.name.min[lang])
      .max(50, errors.package.name.max[lang])
      .required(errors.package.name.required[lang]),
    height: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
    length: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
    width: yup
      .number()
      .typeError(errors.package.typeerror[lang])
      .min(1, errors.package.dimensions.min[lang])
      .required(),
    /*  weight: yup.number().typeError(errors.package.typeerror[lang]).min(0.1, errors.package.weight.min[lang]).required(), */
  });

export const shippingDocumentsSchema = (lang = 'en') => {};
