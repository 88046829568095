import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
//UI
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
import SnackbarContentWrapper from '../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
//Material UI
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  accountHistory: {
    gridArea: 'password',
  },
  form: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 42px)',
    padding: '5px',
  },
  textField: {
    width: '90%',
    padding: '2px 0',
    fontSize: '0.9rem',
  },
  passwordSave: {
    width: '90%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    alignSelf: 'center',
  },
});

const PasswordChange = (props) => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, variant: '', message: '' });
  const [passwords, setPasswords] = useState({
    currentpassword: '',
    newpassword: '',
  });

  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.auth.token);
  const handlePasswordChange = (value) => (e) => {
    setPasswords({ ...passwords, [value]: e.target.value });
  };
  const classes = useStyles();

  const changePasswordSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    if (passwords.newpassword.length < 6) {
      setSnackbar({
        open: true,
        variant: 'error',
        message: ['Lösenordet måste innehålla minst 6 tecken!'],
      });
      setLoading(false);
      return;
    }

    const postData = {
      password: passwords.currentpassword,
      newPassword: passwords.newpassword,
      userId,
    };

    axios
      .post('/api/user/changepassword', { ...postData }, config)
      .then((resp) => {
        //setSnackbar({open:true, variant:'success', message:"Nytt lösenord sparat!"})
        setLoading(false);
        setPasswords({ ...passwords, currentpassword: '', newpassword: '' });
        return;
      })
      .catch((_) => {
        setSnackbar({ open: true, variant: 'error', message: ['Incorrect password!'] });
        setLoading(false);
        return;
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper elevation={1} className={classes.accountHistory}>
      <AppBar elevation={0} position='static'>
        <CustomToolbar title={props.translations.changepassword}></CustomToolbar>
      </AppBar>
      <form onSubmit={changePasswordSubmit} className={classes.form}>
        {loading ? (
          <CircleSpinnerSmall />
        ) : (
          <>
            <TextField
              onChange={handlePasswordChange('currentpassword')}
              label={props.translations.currentpassword}
              className={classes.textField}
              type='password'
              autoComplete='current-password'
            />
            <TextField
              onChange={handlePasswordChange('newpassword')}
              label={props.translations.newpassword}
              className={classes.textField}
              type='password'
              autoComplete='current-password'
            />
            <div className={classes.passwordSave}>
              <Button className={classes.button} type='submit'>
                {props.translations.buttons.save}
              </Button>
            </div>
          </>
        )}

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <SnackbarContentWrapper
            onClose={handleClose}
            variant={snackbar.variant}
            messages={snackbar.message}
          />
        </Snackbar>
      </form>
    </Paper>
  );
};

export default PasswordChange;
