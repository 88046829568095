import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import ShowCountriesIcon from '@material-ui/icons/Visibility';
import { CARRIER_NAMES } from '../../../../services/types';
import translationsObj from '../../../../services/translations/translations.json';
import { blue } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr) 50px',
    gap: '5px',
    placeItems: 'center',
    '& > label': {
      fontWeight: '500',
      fontSize: '0.8rem',
      textAlign: 'center',
      width: '100%',
      color: theme.palette.text.primary,
    },
  },
  divider: {
    gridColumn: '1 / -1',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  countriesItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > p': {
      fontWeight: '300',
      fontSize: '0.8rem',
      textAlign: 'center',
      width: '100%',
    },
  },
  countrySettingsIcon: {
    fontSize: '20px',
    transform: 'translateY(-2px)',
    color: blue[900],
  },
  removeIcon: {
    fontSize: '20px',
    transform: 'translateY(-2px)',
    color: theme.status.danger,
  },
  iconButton: {
    padding: '5px',
  },
}));

export default function AdditionalRule({ rule, handleRemoveRule, generateLabels }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translations = translationsObj[lang];

  const labels = [
    translations.countries,
    translations.carrier,
    translations.weightMin,
    translations.weightMax,
    '',
  ];
  const countryWildcardLabelsMapper = {
    '*': translations.allCountries,
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {generateLabels &&
        labels.map((label, i) => (
          <label key={label}>{i === labels.length - 1 ? <span>&nbsp;</span> : label}</label>
        ))}
      {generateLabels && <div className={classes.divider}></div>}

      <div className={classes.countriesItem}>
        <p>
          {rule.countryCodes
            .map((countryCode) => {
              return countryWildcardLabelsMapper[countryCode] || countryCode;
            })
            .join(',')}
        </p>
        <Tooltip title={translations.showAllCountries}>
          <IconButton className={classes.iconButton} onClick={() => {}}>
            <ShowCountriesIcon className={classes.countrySettingsIcon} />
          </IconButton>
        </Tooltip>
      </div>

      <div>
        <p>{CARRIER_NAMES[rule.carrierAlias]}</p>
      </div>
      <div>
        <p>{rule.weightRules.weightMin}</p>
      </div>
      <div>
        <p>{rule.weightRules.weightMax}</p>
      </div>
      <div>
        <Tooltip title={translations.removeRule}>
          <IconButton className={classes.iconButton} onClick={() => handleRemoveRule(rule.id)}>
            <DeleteIcon className={classes.removeIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
