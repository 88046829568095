import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CARRIER_NAMES,
  NOTIFICATION_TYPE_DELIVERY,
  NOTIFICATION_TYPE_EXCEPTION,
  NOTIFICATION_TYPE_SHIP,
} from '../../../../services/types';
import { Button, Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationsPreset } from '../../../../store/actions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'grid',
    padding: '10px 20px 20px 20px',
    boxSizing: 'border-box',
    placeItems: 'center',
    gap: '20px',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: {
    borderBottom: '3px double black',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'grid',
    rowGap: '10px',
    width: '100%',
    maxWidth: '400px',
    marginTop: '20px',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const validForCarrierNames = [CARRIER_NAMES.ups /* , CARRIER_NAMES.dhlfreight */];

const initialEmailNotifications = { ship: false, exception: false, delivery: false };

const NotificationsModal = ({ translations, bookingPresets, close }) => {
  const classes = useStyles();
  const [senderEmailNotifications, setSenderEmailNotifications] =
    useState(initialEmailNotifications);
  const [recipientEmailNotifications, setRecipientEmailNotifications] =
    useState(initialEmailNotifications);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    setSenderEmailNotifications(bookingPresets.senderEmailNotifications);
    setRecipientEmailNotifications(bookingPresets.recipientEmailNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationLabels = {
    [NOTIFICATION_TYPE_SHIP]: translations.onlinebooking.ship,
    [NOTIFICATION_TYPE_EXCEPTION]: translations.onlinebooking.exception,
    [NOTIFICATION_TYPE_DELIVERY]: translations.onlinebooking.delivery,
  };

  const handleSenderNotificationCheckbox = (n) => {
    setSenderEmailNotifications({ ...senderEmailNotifications, [n]: !senderEmailNotifications[n] });
  };
  const handleRecipientNotificationCheckbox = (n) => {
    setRecipientEmailNotifications({
      ...recipientEmailNotifications,
      [n]: !recipientEmailNotifications[n],
    });
  };

  const handleSubmit = () => {
    dispatch(
      updateNotificationsPreset({
        senderEmailNotifications,
        recipientEmailNotifications,
        userId,
        token,
      })
    );
    close();
  };

  return (
    <div className={classes.root}>
      <p style={{ fontStyle: 'italic', fontSize: '1.2rem' }}>
        {translations.applyOnlineBookingNotifications}&#58;&nbsp;
        {validForCarrierNames.map((c, idx) => (
          <span style={{ fontWeight: 'bold', fontStyle: 'normal' }} key={c}>
            {idx !== 0 && ', '}
            {c}
          </span>
        ))}
      </p>
      <div className={classes.section}>
        <p className={classes.sectionTitle}>{translations.notificationsToRecipientEmail}</p>
        <div className={classes.optionsContainer}>
          {Object.keys(recipientEmailNotifications).map((n) => (
            <div className={classes.option} key={`recipient-${n}`}>
              <Checkbox
                checked={recipientEmailNotifications[n]}
                onClick={() => handleRecipientNotificationCheckbox(n)}
              />
              <label>{notificationLabels[n]}</label>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.section}>
          <p className={classes.sectionTitle}>{translations.notificationsToSenderEmail}</p>
          <div className={classes.optionsContainer}>
            {Object.keys(senderEmailNotifications).map((n) => (
              <div className={classes.option} key={`sender-${n}`}>
                <Checkbox
                  checked={senderEmailNotifications[n]}
                  onClick={() => handleSenderNotificationCheckbox(n)}
                />
                <label>{notificationLabels[n]}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          {translations.submitChanges}
        </Button>
        <Button className={classes.cancelButton} onClick={close}>
          {translations.templates.cancel}
        </Button>
      </div>
    </div>
  );
};

export default NotificationsModal;
