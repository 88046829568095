import axios from 'axios';
import { buildOneDocument } from '../../services/documents';
import { DOCUMENT_TYPE_INVOICE } from '../../services/types';
import * as actionTypes from './actionTypes';

export const processBatchBooking = (shipments, sender, invoices) => async (dispatch, getState) => {
  let invoicesData = [];
  /*  Serialize invoice data and only send invoice data which has
      has at least one shipment id in shipmentIds corresponding to a
      shipment in shipments 
  */
  if (invoices.length > 0) {
    let invoiceDataSerialized = [];
    const invoiceDataWithShipmentIds = invoices.filter((invoice) =>
      shipments.some(({ id }) => invoice.shipmentIds.includes(id))
    );
    // Asynchronously loop through invoiceDataWithShipmentIds and if type equals 'file' convert file to base64
    invoiceDataSerialized = invoiceDataWithShipmentIds.map(async (invoice) => {
      if (invoice.type === 'file') {
        const invoiceDocumentData = await buildOneDocument(
          invoice.invoiceData,
          DOCUMENT_TYPE_INVOICE
        );

        return { ...invoice, invoiceData: invoiceDocumentData };
      }
      return invoice;
    });
    invoicesData = await Promise.all(invoiceDataSerialized);
  }

  try {
    dispatch({
      type: actionTypes.PROCESS_BATCH_BOOKING_START,
    });
    const { user, auth } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/batch-booking/process`,
      { shipments, sender, invoicesData, userId: user.userId },
      config
    );
    const { result } = data;
    const [success, errors] = result;
    const successFlattened = success.flat();
    dispatch({
      type: actionTypes.PROCESS_BATCH_BOOKING_SUCCESS,
      errors,
      success: successFlattened,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.PROCESS_BATCH_BOOKING_FAILED,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const fetchBatchBookingRules = (carrierAliases) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.FETCH_BATCH_BOOKING_RULES_START,
    });
    const { user, auth } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/batch-booking/get-rules`,
      { carrierAliases, companyId: user.companyId },
      config
    );

    const { baseRules, countryRules } = data;
    dispatch({
      type: actionTypes.FETCH_BATCH_BOOKING_RULES_SUCCESS,
      baseRules,
      countryRules,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_BATCH_BOOKING_RULES_FAILED,
      error:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//UPDATE
export const updateBatchBookingRules = (batchBookingRules) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.UPDATE_BATCH_BOOKING_RULES_START,
    });
    const { user, auth } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/batch-booking/update-rules`,
      { batchBookingRules, companyId: user.companyId },
      config
    );
    const { baseRules, countryRules } = data;
    dispatch({
      type: actionTypes.UPDATE_BATCH_BOOKING_RULES_SUCCESS,
      baseRules,
      countryRules,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_BATCH_BOOKING_RULES_FAILED,
      error:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// REMOVE COUNTRY RULE
export const removeBatchBookingCountryRule = (countryRuleId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.UPDATE_BATCH_BOOKING_RULES_START,
    });
    const { auth } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const { data } = await axios.delete(
      `/api/batch-booking/remove-country-rule/${countryRuleId}`,
      config
    );
    const { removeId } = data;
    dispatch({
      type: actionTypes.REMOVE_BATCH_BOOKING_COUNTRY_RULE_SUCCESS,
      removedId: removeId,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.REMOVE_BATCH_BOOKING_COUNTRY_RULE_FAILED,
      error:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// PROCESS BATCH BOOKING FILE
export const processBatchBookingFile =
  ({ file, selectedFileVersion, baseRules, countryRules }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.PROCESS_BATCH_FILE_START,
      });
      const { user, auth } = getState();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('version', selectedFileVersion);
      formData.append('rules', JSON.stringify({ baseRules, countryRules }));
      formData.append('userId', user.userId);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.token}`,
        },
      };
      const { data } = await axios.post(`/api/batch-booking/parse-file`, formData, config);
      if (data.success === false && data.errors.length > 0) {
        dispatch({
          type: actionTypes.PROCESS_BATCH_FILE_FAILED,
          errors: data.errors,
        });
        return;
      }

      const { result } = data;

      dispatch({
        type: actionTypes.PROCESS_BATCH_FILE_SUCCESS,
        payload: result,
      });
    } catch (e) {
      const error = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: actionTypes.PROCESS_BATCH_FILE_FAILED,
        errors: [error],
      });
    }
  };

// UPDATE INVOICE DATA FOR BATCH BOOKING
export const updateInvoiceDataForBatchBooking = (invoiceData) => {
  return {
    type: actionTypes.UPDATE_INVOICE_DATA_FOR_BATCH_BOOKING,
    invoiceData,
  };
};

// UPDATE SENDER TEMPLATE FOR BOOKING
export const updateSenderTemplateForBooking = (senderTemplate) => {
  return {
    type: actionTypes.UPDATE_SENDER_TEMPLATE_FOR_BOOKING,
    senderTemplate,
  };
};

// UPDATE WHEN PRINTED
export const updateBatchBookingDataWhenPrinted = (ids) => {
  return {
    type: actionTypes.UPDATE_BATCH_BOOKING_DATA_WHEN_PRINTED,
    ids,
  };
};

export const updateBatchBookingDataShipment = (shipment) => {
  return {
    type: actionTypes.UPDATE_BATCH_BOOKING_DATA_SHIPMENT,
    shipment,
  };
};

// CLEAR BATCH BOOKING STATE
export const clearBatchBookingData = () => {
  return {
    type: actionTypes.CLEAR_BATCH_BOOKING_STATE,
  };
};
