import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PackageMove = (props) => {

	return (
		<SvgIcon {...props}>
			<polygon points="12.46 9.41 12.46 20.4 2.88 16.29 2.88 14.38 5.27 14.38 5.27 13.42 2.88 13.42 2.88 11.5 6.23 11.5 6.23 10.54 2.88 10.54 2.88 8.63 5.27 8.63 5.27 7.67 2.88 7.67 2.88 4.84 7.19 6.89 7.19 9.1 8.15 9.51 8.15 7.35 12.46 9.41" />
			<polygon points="16.98 1.74 12.94 0 3.48 4.07 7.78 6.12 16.98 1.74" />
			<polygon points="18.16 2.24 8.9 6.65 12.94 8.58 22.41 4.06 18.16 2.24" />
			<path d="M13.92,11.21v11l9.58-4.12V6.65Zm8.16,5.09L19.2,17.53v-1l2.88-1.23Z" transform="translate(-0.5 -1.8)" />
			<rect y="13.42" width="2.88" height="0.96" />
			<rect y="7.67" width="2.88" height="0.96" />
			<rect x="0.96" y="10.54" width="1.92" height="0.96" />
		</SvgIcon>
	);
}

PackageMove = pure(PackageMove);
PackageMove.displayName = 'PackageMove';
PackageMove.muiName = 'SvgIcon';

export default PackageMove
