import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCountries } from '../../../../store/actions';
import { pickupCarrierServices } from '../../../../services/helpers/common';
import translations from '../../../../services/translations/translations.json';
import Select from 'react-select';
import { SelectStylesMain } from '../../../UI/SelectStyles/SelectStyles';
import NumberSpinner from '../../../PackagesDetails/NumberSpinner/NumberSpinner';
import { initialPickupDate } from '../../../../utils/pickup';
import DateSelect from '../../../DateSelect/DateSelect';
import TimeSelect from '../../../TimeSelect/TimeSelect';
import SelectList from '../../../SelectionLists/IntegrationReactSelect/IntegrationReactSelect';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: '5px',
    textAlign: 'center',
  },
  recipientCountry: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  packagesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    boxSizing: 'border-box',
    padding: '0 20px',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: '10px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  select: {
    width: '100%',
  },
  textField: {
    width: '100%',
    transform: 'translateY(-10px)',
  },
  pickupReferenceContainer: {
    justifySelf: 'start',
    '& > p': {
      transform: 'translateY(-10px)',
    },
  },
  pickupTimeSelections: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    placeContent: 'center',
    columnGap: '20px',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  timeSelect: {
    display: 'grid',
    gridTemplateColumns: '100px 100px',
    columnGap: '10px',
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      width: 'fit-content',
    },
    packagesContainer: {
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    },
    pickupReferenceContainer: {
      gridColumn: '1 / -1',
      width: '100%',
    },
    pickupTimeSelections: {
      gridTemplateColumns: 'auto',
      rowGap: '15px',
    },
  },
}));

const UPSCollectionDetails = (props) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countriesData);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { token } = useSelector((state) => state.auth);

  const [recipientCountry, setRecipientCountry] = useState({ countryName: '', countryCode: '' });
  const [isOverweight, setIsOverweight] = useState(false);
  const [pickupReference, setPickupReference] = useState('');
  const [count, setCount] = useState(1);
  const [pickupTime, setPickupTime] = useState({
    pickupDate: null,
    pickupTimeStart: null,
    pickupTimeEnd: null,
  });
  const [serviceCode, setServiceCode] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([
    {
      label: `${translations[lang].managecollection.requestcollection.collectiondetails.recipientcountry}`,
      value: null,
    },
  ]);

  useEffect(() => {
    if (countries === null) {
      dispatch(fetchCountries(token));
    }
    //build UPS service options
    const options = Object.keys(pickupCarrierServices['ups']).map((code) => {
      return { label: pickupCarrierServices['ups'][code], value: code };
    });
    setServiceOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pickupDate, pickupTimeStart, pickupTimeEnd } = initialPickupDate(new Date());
    setPickupTime({ ...pickupTime, pickupDate, pickupTimeStart, pickupTimeEnd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCount = (type) => {
    if (type === 'increase' && count <= 999) setCount(count + 1);
    if (type === 'decrease' && count > 1) setCount(count - 1);
  };

  const handleNumberChange = (e) => {
    if (e.target.value === '') {
      setCount(1);
      return;
    }

    const float = parseFloat(e.target.value);

    if (isNaN(float)) return;
    if (float > 999) return;
    setCount(float);
  };

  const handleCountryChange = (e) => {
    setRecipientCountry({ countryName: e.value.name, countryCode: e.value.code });
  };

  const handleServiceChange = (e) => {
    setServiceCode(e.value);
  };

  const handlePickupReferenceChange = (e) => {
    setPickupReference(e.target.value);
  };

  const handlePickupTimeChange = (type) => (value) => {
    setPickupTime({ ...pickupTime, [type]: value });
  };
  const handleSubmit = () => {
    const { pickupDate, pickupTimeStart, pickupTimeEnd } = pickupTime;
    props.handleSubmitWithCarrierDetails({
      isOverweight,
      recipient: { countryCode: recipientCountry.countryCode },
      packageCount: count,
      serviceCode,
      pickupReference,
      pickupDate: moment(pickupDate).format(),
      pickupTimeStart: moment(pickupTimeStart).format(),
      pickupTimeEnd: moment(pickupTimeEnd).format(),
    });
  };

  const classes = useStyles();

  return (
    <>
      <div style={{ width: '50%', zIndex: 1001 }}>
        <Select
          className={classes.select}
          styles={SelectStylesMain}
          value={serviceOptions.filter((option) => option.value === serviceCode)}
          placeholder={
            translations[lang].managecollection.requestcollection.collectiondetails.selectService
          }
          label={`Carrier`}
          onChange={handleServiceChange}
          options={serviceOptions}
        />
      </div>
      <div className={classes.recipientCountry}>
        <p className={classes.description}>
          {translations[lang].managecollection.requestcollection.collectiondetails.recipientcountry}
        </p>
        <SelectList
          id="pickup-sender-country"
          lang={props.lang}
          translations={props.translations.selectList}
          change={handleCountryChange}
          value={recipientCountry.countryName}
          height="48"
          translateY="-4"
          countries={countries}
        />
      </div>
      <div className={classes.packagesContainer}>
        <div>
          <p style={{ transform: 'translateY(-10px)' }}>
            {translations[lang].managecollection.requestcollection.collectiondetails.packagecount}?
          </p>
          <NumberSpinner
            title={'Count'}
            handleNumberChange={handleNumberChange}
            upClick={() => handleCount('increase')}
            downClick={() => handleCount('decrease')}
            count={count}
          />
        </div>
        <div>
          <p style={{ transform: 'translateY(-10px)' }}>
            {translations[lang].managecollection.requestcollection.collectiondetails.overweight}?
          </p>
          <Checkbox checked={isOverweight} onChange={() => setIsOverweight(!isOverweight)} />
        </div>
        <div className={classes.pickupReferenceContainer}>
          <p>
            {
              translations[lang].managecollection.requestcollection.collectiondetails
                .enterReferenceForPickup
            }
          </p>
          <TextField
            id="pickupReference"
            inputProps={{ maxLength: 35 }}
            label={
              translations[lang].managecollection.requestcollection.collectiondetails.reference
            }
            className={classes.textField}
            name="pickupReference"
            value={pickupReference}
            onChange={handlePickupReferenceChange}
            margin="none"
          ></TextField>
        </div>
      </div>
      <div className={classes.pickupTimeSelections}>
        <DateSelect
          locale={props.locale}
          label={props.translations.onlinebooking.pickupdate}
          inline={true}
          handleDateChange={handlePickupTimeChange('pickupDate')}
          selectedDate={pickupTime.pickupDate}
        />
        <div className={classes.timeSelect}>
          <TimeSelect
            selected={pickupTime.pickupTimeStart}
            handleTimeChange={handlePickupTimeChange('pickupTimeStart')}
            label={props.translations.onlinebooking.earliest}
          />
          <TimeSelect
            selected={pickupTime.pickupTimeEnd}
            handleTimeChange={handlePickupTimeChange('pickupTimeEnd')}
            label={props.translations.onlinebooking.latest}
          />
        </div>
      </div>
      <div style={{ marginTop: '30px' }}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {translations[lang].managecollection.requestcollection.collectiondetails.submit}
        </Button>
      </div>
    </>
  );
};

export default UPSCollectionDetails;
