import { makeStyles, TextField } from '@material-ui/core';
import Select from 'react-select';
import React from 'react';
import * as shippingTypes from '../../../../../services/types';
import { CircleSpinnerSmall } from '../../../../UI/Spinners/Circle';
import { SelectStylesMain } from '../../../../UI/SelectStyles/SelectStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  deliveryRemark: {},
  deliveryMethod: {
    height: 'calc(100% - 30px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  additionalServicesHeader: {
    alignSelf: 'baseline',
    width: '100%',
    padding: '7px 0',
    boxShadow: '0 4px 4px -2px grey',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '& > p': {
      textAlign: 'center',
      fontSize: '1.1rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
  },
  homeDeliveryContainer: {
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    '& > label': {
      fontWeight: 500,
      color: '#0070b5',
      textAlign: 'center',
      fontSize: '1.1rem',
    },
  },
  DropOffLocationList: {
    width: '100%',
  },
  top15: {
    marginTop: '15px',
  },
}));

const MatkahuoltoAdditionalServices = ({
  deliveryRemark,
  handleDeliveryRemark,
  selectedDeliveryMethod,
  handleDeliveryRemarkBlur,
  translations,
  dropOffLoading,
  dropOffError,
  dropOffSelected,
  handleDropOffChange,
  dropOffLocationOptions,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.deliveryMethod}>
      <div className={classes.additionalServicesHeader}>
        <p>{translations.onlinebooking.deliverto}</p>
      </div>
      {selectedDeliveryMethod === shippingTypes.MATKAHUOLTO_HOME_DELIVERY ? (
        <div className={classes.homeDeliveryContainer}>
          <label htmlFor="delivery-remark">{translations.onlinebooking.homeDelivery}</label>
          <TextField
            inputProps={{ maxLength: 50 }}
            id="delivery-remark"
            name="delivery-remark"
            label={translations.onlinebooking.remark}
            className={classes.deliveryRemark}
            value={deliveryRemark}
            onChange={handleDeliveryRemark}
            onBlur={handleDeliveryRemarkBlur}
            margin="none"
            helperText={translations.onlinebooking.remarkDescription}
            required
          />
        </div>
      ) : (
        <div className={clsx([classes.DropOffLocationList, classes.top15])}>
          {dropOffLoading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircleSpinnerSmall />
            </div>
          )}
          {!dropOffLoading && dropOffError && <p>{dropOffError}.</p>}
          {!dropOffLoading && !dropOffError && dropOffLocationOptions.length > 0 && (
            <Select
              placeholder={
                <div style={{ textTransform: 'capitalize' }}>
                  {translations.onlinebooking.select}...
                </div>
              }
              value={dropOffSelected}
              onChange={handleDropOffChange}
              options={dropOffLocationOptions}
              styles={SelectStylesMain}
              menuPlacement="auto"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MatkahuoltoAdditionalServices;
