import React from 'react';

const packageShipped = () => {
	
	return (
		<svg id='Lager_1' data-name='Lager 1' xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 46.94 25.32' width='26'
			height='26'>
			<path d='M45.55,29.64c0-.84.35-4-1.74-4.52-.18-.34-1-2-1.62-3.48a3.8,3.8,0,0,0-3.44-2.21H33.16V33.28h12.1s1-.69,1-1.45S46.47,29.88,45.55,29.64Z'
				transform='translate(-.36 -11.87)' fill='#a7d1f1' />
			<path d='M33.16,33.28h12.1s1-.69,1-1.45.19-1.95-.73-2.19c0-.84.35-4-1.74-4.52-.18-.34-1-2-1.62-3.48a3.8,3.8,0,0,0-3.44-2.21H33.16'
				transform='translate(-.36 -11.87)' fill='none' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<path d='M40.47,25.2l-1.19-2.55a.55.55,0,0,0-.48-.3H36.08V26H40A.56.56,0,0,0,40.47,25.2Z'
				transform='translate(-.36 -11.87)' fill='#fff' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<polygon points='9.48 1 9.48 6.83 9.48 14.85 9.48 21.4	1 32.8 21.41 32.8 1 9.48 1'
				fill='#fff' />
			<polyline points='9.48 6.83 9.48 1 32.8 1 32.8 21.41 9.48 21.41 9.48 14.85'
				fill='none' stroke='#2c5183' strokeMiterlimit='10' strokeWidth='2' />
			<line x1='9.48' y1='13.39' x2='9.48' y2='9.02' fill='none' stroke='#2c5183'
				strokeMiterlimit='10' strokeWidth='2' />
			<path d='M19.32,33.28a2.92,2.92,0,1,1-2.92-2.9A2.91,2.91,0,0,1,19.32,33.28Z'
				transform='translate(-.36 -11.87)' fill='#e9eaeb' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<path d='M41.19,33.28a2.92,2.92,0,1,1-2.92-2.9A2.91,2.91,0,0,1,41.19,33.28Z'
				transform='translate(-.36 -11.87)' fill='#e9eaeb' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<line x1='16.77' y1='12.66' x2='2.92' y2='12.66' fill='none' stroke='#2c5183'
				strokeMiterlimit='10' strokeWidth='2' />
			<line x1='24.79' y1='6.83' x2='6.56' y2='6.83' fill='none' stroke='#2c5183'
				strokeMiterlimit='10' strokeWidth='2' />
			<line x1='29.16' y1='1' x2='6.56' y2='1' fill='none' stroke='#2c5183'
				strokeMiterlimit='10' strokeWidth='2' />
			<line x1='5.1' y1='1' x2='3.65' y2='1' fill='none' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<line x1='2.19' y1='1' x2='0.73' y2='1' fill='none' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<line x1='1.46' y1='12.66' y2='12.66' fill='none' stroke='#2c5183' strokeMiterlimit='10'
				strokeWidth='2' />
			<line x1='5.1' y1='6.83' x2='3.65' y2='6.83' fill='none' stroke='#2c5183'
				strokeMiterlimit='10' strokeWidth='2' />
		</svg>

	);
}

export default packageShipped;