import React, { useState, useEffect } from 'react';
import translations from '../../services/translations/translations';
import {
  stateRegionCountries,
  stateRegionPaths,
  countriesWithoutZip,
  cityRequired,
} from '../../services/helpers/common';
import { THIRD_PARTY, DOMESTIC, EXPORT, IMPORT } from '../../services/types';
import Snackbar from '@material-ui/core/Snackbar';
import SelectionList from '../../components/SelectionLists/IntegrationReactSelect/IntegrationReactSelect';
import CustomToolbar from '../../components/UI/CustomToolbar/CustomToolbar';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCountries,
  fetchPagodaRates,
  cleanPagodaRates,
  fetchCarriers,
  addBookingFromRates,
} from '../../store/actions';
import Flow from '../../components/Flow/Flow';
import PackagesDetails from '../../components/PackagesDetails/PackagesDetails';
import RateResult from '../../components/Rates/RateResult/RateResult';
import { userDetailsMapper } from '../../utils/address/mappers';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
//Material UI
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import SnackbarContentWrapper from '../../components/UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    placeItems: 'center',
    gridAutoFlow: 'row',
    padding: '10px',
    rowGap: '20px',
  },
  flow: {
    width: '100%',
    display: 'grid',
    alignSelf: 'start',
    gap: '10px',
    gridTemplateColumns: '1fr',
    justifyContent: 'start',
  },
  calculator: {
    maxWidth: '680px',
    // minWidth: '475px',
    width: '100%',
  },
  addressContainer: {
    display: 'flex',
    height: '100%',
    minHeight: '130px',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    minWidth: '250px',
  },
  countrySelection: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(80% + 10px)',
    '& > div:not(:first-child)': {
      margin: '5px 0',
    },
  },
  zipCity: {
    width: '80%',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gap: '10px',
    margin: '5px 0',
    transform: 'translateX(-5px)',
  },
  packagesControl: {
    minHeight: '200px',
  },
  packageDetails: {
    maxWidth: '584px',
  },
  submitButtonContainer: {
    marginBottom: '20px',
  },
  submitButton: {
    fontSize: '1.2rem',
    width: '190px',
    height: '45px',
  },
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  label: {
    color: '#62485E',
  },

  '@media (max-width:740px)': {
    calculator: {
      /*    margin:'0px 8px 0px 2px !important' */
    },
    addressContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    submitButton: {
      fontSize: '1.1rem',
      width: '170px',
      height: '50px',
    },
    packageDetails: {
      maxWidth: '584px',
    },
  },
}));

const initialAddress = {
  countryName: '',
  countryCode: '',
  stateRegion: '',
  zip: '',
  city: '',
};

const initialHasPostalCode = { sender: true, recipient: true };
const initialHasStateOrRegion = { sender: false, recipient: false };
const initialStateRegion = { sender: [], recipient: [] };

const initialPackage = {
  templateName: 'none',
  count: 1,
  packaging: '02',
  length: '',
  width: '',
  height: '',
  weight: '',
};

const initialSnackbar = {
  open: false,
  messages: [],
  variant: '',
};

const Rates = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //component states
  const [flow, setFlow] = useState(EXPORT); //enum ['export','import','domestic','third-party']
  const [sender, setSender] = useState(initialAddress);
  const [recipient, setRecipient] = useState(initialAddress);
  const [packages, setPackages] = useState([initialPackage]);
  // const [userAddress, setUserAddress] = useState(null);
  const [packageErrors, setPackageErrors] = useState([]);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [hasPostalCode, setHasPostalCode] = useState(initialHasPostalCode);
  const [redirect, setRedirect] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reloadKey, setReloadKey] = useState(1);
  const [snackbar, setSnackbar] = useState(initialSnackbar);
  const [stateRegion, setStateRegion] = useState(initialStateRegion);
  const [hasStateOrRegion, setHasStateOrRegion] = useState(initialHasStateOrRegion);
  //redux store values
  const countries = useSelector((state) => state.countries.countriesData);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const token = useSelector((state) => state.auth.token);
  const carriers = useSelector((state) => state.carriers);
  const userDetails = useSelector((state) => state.user);
  useEffect(() => {
    if (countries === null) {
      dispatch(fetchCountries(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (flow) {
      case EXPORT:
        setSender(userDetailsMapper(userDetails));
        setRecipient(initialAddress);
        break;
      case DOMESTIC:
        const userAddress = userDetailsMapper(userDetails);
        const { countryName: recipientCountryName, countryCode: recipientCountryCode } =
          userAddress;
        //check whether postal codes exists in domestic country
        if (countriesWithoutZip.includes(recipientCountryCode) && hasPostalCode['recipient']) {
          setHasPostalCode({ ...hasPostalCode, recipient: false });
        } else if (!hasPostalCode['recipient']) {
          setHasPostalCode({ ...hasPostalCode, recipient: true });
        }
        setSender(userAddress);
        setRecipient({
          ...initialAddress,
          countryName: recipientCountryName,
          countryCode: recipientCountryCode,
        });
        break;
      case IMPORT:
        setRecipient(userDetailsMapper(userDetails));
        setSender(initialAddress);
        break;
      default:
        setRecipient(initialAddress);
        setSender(initialAddress);
    }

    //reset state and region settings if exists
    if (hasStateOrRegion.sender || hasStateOrRegion.recipient)
      setHasStateOrRegion(initialHasStateOrRegion);
    if (stateRegion.sender.length > 0 || stateRegion.recipient.length > 0)
      setStateRegion(initialStateRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    if (carriers.allCarriers.length < 1) {
      dispatch(fetchCarriers(token, userDetails.companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleFlow = (flow) => {
    setFlow(flow);
  };

  const handleNew = () => {
    setSender(initialAddress);
    setRecipient(initialAddress);
    setReloadKey(reloadKey + 1);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(cleanPagodaRates());
    setModalOpen(false);
  };

  const handleCountryChange = (type) => (event) => {
    //check whether country requires a region
    if (stateRegionCountries.includes(event.value.code)) {
      fetchStatesRegions({ type, countryCode: event.value.code });
    } else {
      hasStateOrRegion[type] && setHasStateOrRegion({ ...hasStateOrRegion, [type]: false });
      stateRegion[type].length > 0 && setStateRegion({ ...stateRegion, [type]: [] });
    }

    //check whether selected country utilizes postal codes
    if (countriesWithoutZip.includes(event.value.code) && hasPostalCode[type]) {
      setHasPostalCode({ ...hasPostalCode, [type]: false });
    } else if (!hasPostalCode[type]) {
      setHasPostalCode({ ...hasPostalCode, [type]: true });
    }
    if (type === 'sender')
      setSender({
        ...sender,
        countryName: event.value.name,
        countryCode: event.value.code,
        zip: '',
        city: '',
      });
    if (type === 'recipient')
      setRecipient({
        ...recipient,
        countryName: event.value.name,
        countryCode: event.value.code,
        zip: '',
        city: '',
      });
  };

  const handleStateChange = (type) => (e) => {
    if (type === 'sender') setSender({ ...sender, stateRegion: e.value.code });
    if (type === 'recipient') setRecipient({ ...recipient, stateRegion: e.value.code });
  };

  const fetchStatesRegions = ({ type, countryCode }) => {
    setHasStateOrRegion({ ...hasStateOrRegion, [type]: true });
    const path = stateRegionPaths[countryCode];

    axios
      .get(`/api/countries/states-or-regions/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const { statesOrRegions } = resp.data;
        setStateRegion({ ...stateRegion, [type]: statesOrRegions });
      })
      .catch((e) => console.log(e));
  };

  const handleAddressChange =
    (type) =>
    ({ target }) => {
      const { value, name } = target;
      if (type === 'sender') setSender({ ...sender, [name]: value });
      if (type === 'recipient') setRecipient({ ...recipient, [name]: value });
    };

  const handlePostalCodeBlur = (event) => {
    if (event.target.value.length < 3) {
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }
  };

  const handleCityBlur = (event) => {
    if (event.target.value.trim() === '') {
      setCityError(true);
    } else {
      setCityError(false);
    }
  };

  const handleUpdatePackages = (rowNum, inputs) => {
    let newPackages = [...packages];
    newPackages[rowNum] = inputs;
    setPackages(newPackages);
  };

  const handleUpdatePackagesErrors = (rowNum, rowErrors) => {
    let newErrors = [...packageErrors];
    newErrors[rowNum] = rowErrors;
    setPackageErrors(newErrors);
  };

  const handleRemovePackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.pop();
    setPackages(updatedPackages);
  };

  const handleAddPackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.push(initialPackage);
    setPackages(updatedPackages);
  };

  const handleRedirect = (path) => {
    dispatch(addBookingFromRates(sender, recipient, packages));
    setModalOpen(false);
    setRedirect(path);
    dispatch(cleanPagodaRates());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { rateCarriers, allCarriers } = carriers;
    const rateRequestCarriers = rateCarriers.map((carrier) => {
      const applyCarrier = allCarriers.find((c) => c.id === carrier);

      return applyCarrier['carrierAlias'];
    });

    dispatch(
      fetchPagodaRates(
        { sender, recipient, date: new Date(), packages },
        rateRequestCarriers,
        token
      )
    );
    handleOpenModal();
  };

  return (
    <div className={classes.container}>
      {redirect && <Redirect to={redirect} />}
      {/* Show Dialog with rates if applied  */}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth={true} maxWidth={'md'}>
        <DialogTitle className={classes.modalTitle} id="rate-dialog-title">
          {translations[lang].rates.dialogTitle}
        </DialogTitle>
        <RateResult redirect={handleRedirect} />
      </Dialog>
      <Paper elevation={1} className={clsx(classes.calculator)}>
        <AppBar elevation={0} position="static">
          <CustomToolbar title={translations[lang].rates.toolbar.title}>
            <Tooltip title={translations[lang].rates.toolbar.refreshTooltip}>
              <IconButton onClick={handleNew}>
                <RefreshIcon className={classes.block} color="inherit" />
              </IconButton>
            </Tooltip>
          </CustomToolbar>
        </AppBar>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormLabel className={classes.label}>{translations[lang].rates.selectFlow}</FormLabel>
          <div className={classes.flow}>
            {/*  Selection for Import and Export through Radio Buttons */}
            <Flow
              translations={translations[lang].flow}
              currentFlow={flow}
              changeFlow={handleFlow}
              size="medium"
            />
          </div>
          {(flow === IMPORT || flow === THIRD_PARTY) && (
            <div className={classes.addressContainer}>
              <p>{translations[lang].onlinebooking.shipfrom}</p>
              <div className={classes.countrySelection}>
                <div style={{ zIndex: 1004 }}>
                  <SelectionList
                    translations={translations[lang].selectList}
                    key={reloadKey}
                    translateY="0"
                    change={handleCountryChange('sender')}
                    value={sender.countryName}
                    countries={countries}
                    lang={lang}
                  />
                </div>
                {hasStateOrRegion.sender && (
                  <div style={{ zIndex: 1003 }}>
                    <SelectionList
                      translations={translations[lang].selectList}
                      translateY="0"
                      change={handleStateChange('sender')}
                      value={sender.stateRegion}
                      statesOrRegions={stateRegion['sender']}
                      lang={lang}
                    />
                  </div>
                )}
              </div>
              {/*if country does not utilize postal codes, then city is mandatory and used in the request instead of postal code */}
              {sender.countryCode && (
                <div className={classes.zipCity}>
                  {hasPostalCode.sender && (
                    <TextField
                      id="postal-code"
                      label={translations[lang].rates.zip}
                      margin="none"
                      name="zip"
                      value={sender.zip}
                      onChange={handleAddressChange('sender')}
                      onBlur={handlePostalCodeBlur}
                      required
                      error={postalCodeError}
                    ></TextField>
                  )}

                  {(!hasPostalCode.sender || cityRequired.includes(sender.countryCode)) && (
                    <TextField
                      id="city"
                      label={translations[lang].rates.city}
                      margin="none"
                      name="city"
                      value={sender.city}
                      onChange={handleAddressChange('sender')}
                      onBlur={handleCityBlur}
                      required
                      error={cityError}
                    ></TextField>
                  )}
                </div>
              )}
            </div>
          )}
          {(flow === EXPORT || flow === THIRD_PARTY) && (
            <div className={classes.addressContainer}>
              <p>{translations[lang].onlinebooking.shipto}</p>
              <div style={{ zIndex: 1002 }} className={classes.countrySelection}>
                <div style={{ zIndex: 1001 }}>
                  <SelectionList
                    translations={translations[lang].selectList}
                    key={reloadKey}
                    translateY="0"
                    change={handleCountryChange('recipient')}
                    value={recipient.countryName}
                    countries={countries}
                    lang={lang}
                  />
                </div>
                {hasStateOrRegion.recipient && (
                  <div style={{ zIndex: 1000 }}>
                    <SelectionList
                      translations={translations[lang].selectList}
                      translateY="0"
                      change={handleStateChange('recipient')}
                      value={recipient.stateRegion}
                      statesOrRegions={stateRegion['recipient']}
                      lang={lang}
                    />
                  </div>
                )}
              </div>
              {/*if country does not utilize postal codes, then city is mandatory and used in the request instead of postal code */}
              {recipient.countryCode && (
                <div className={classes.zipCity}>
                  {hasPostalCode.recipient && (
                    <TextField
                      id="postal-code"
                      label={translations[lang].rates.zip}
                      margin="none"
                      name={'zip'}
                      value={recipient.zip}
                      onChange={handleAddressChange('recipient')}
                      onBlur={handlePostalCodeBlur}
                      required
                      error={postalCodeError}
                    ></TextField>
                  )}

                  {(!hasPostalCode.recipient || cityRequired.includes(recipient.countryCode)) && (
                    <TextField
                      id="city"
                      label={translations[lang].rates.city}
                      margin="none"
                      name={'city'}
                      value={recipient.city}
                      onChange={handleAddressChange('recipient')}
                      onBlur={handleCityBlur}
                      required
                      error={cityError}
                    ></TextField>
                  )}
                </div>
              )}
            </div>
          )}
          {flow === DOMESTIC && (
            <div className={classes.addressContainer}>
              <p>{translations[lang].onlinebooking.shipto}</p>
              {/*if country does not utilize postal codes, then city is mandatory and used in the request instead of postal code */}
              <div className={classes.zipCity}>
                {hasPostalCode.recipient && (
                  <TextField
                    id="postal-code"
                    label={translations[lang].rates.zip}
                    margin="none"
                    name="zip"
                    value={recipient.zip}
                    onChange={handleAddressChange('recipient')}
                    onBlur={handlePostalCodeBlur}
                    required
                    error={postalCodeError}
                  ></TextField>
                )}

                {(!hasPostalCode.recipient || cityRequired.includes(recipient.countryCode)) && (
                  <TextField
                    id="city"
                    label={translations[lang].rates.city}
                    margin="none"
                    name="city"
                    value={recipient.city}
                    onChange={handleAddressChange('recipient')}
                    onBlur={handleCityBlur}
                    required
                    error={cityError}
                  ></TextField>
                )}
              </div>
            </div>
          )}
          {/* Package detail section */}
          <FormLabel className={classes.label}>
            {translations[lang].rates.selectPackageDetails}
          </FormLabel>
          <div className={classes.packagesControl}>
            <div className={classes.packageDetails}>
              <PackagesDetails
                updatePackagesErrors={handleUpdatePackagesErrors}
                updatePackages={handleUpdatePackages}
                packageRows={packages.length}
                reloadKey={reloadKey}
                removePackageRow={handleRemovePackageRow}
                addPackageRow={handleAddPackageRow}
              />
            </div>
          </div>
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitButton}
            >
              {translations[lang].rates.calculateRates}
            </Button>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
            messages={snackbar.messages}
          />
        </Snackbar>
      </Paper>
    </div>
  );
};

export default Rates;
