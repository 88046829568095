import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Popover,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import AddIcon from '@material-ui/icons/AddCircle';
import ColumnsIcon from '@material-ui/icons/ViewColumn';
import CloseIcon from '@material-ui/icons/Close';
import { blueGrey, green, grey } from '@material-ui/core/colors';
import { useEffect } from 'react';
import { useState } from 'react';
import { filterUUIDsFromString } from '../../../../../services/helpers/validateFunctions';
import TableCellNumberSpinner from './TableCellNumberSpinner/TableCellNumberSpinner';
import FavouriteTextField from '../../../../FavouriteTextField/FavouriteTextField';

const useStyles = makeStyles((theme) => ({
  root: {},
  tableContainer: {
    minHeight: '160px',
    // display: 'flex',
    // flexDirection: 'column',
  },
  tableSettings: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  popover: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  columnFilterList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  listItem: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-11px',
    marginRight: '16px',
    verticalAlign: 'middle',
    height: '32px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr:first-child': {
        borderBottom: '1px solid black',
      },
      '& th': {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'center',
        padding: '0 5px',
      },
      '& th:nth-child(1),th:nth-child(2),th:nth-child(3)': {
        width: '20px',
      },
      '& th:nth-child(3)': {
        fontWeight: '700',
      },
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: blueGrey[50],
    },
    '& td': {
      fontWeight: 300,
      fontSize: '1rem',
      padding: '0px 5px',
      textAlign: 'center',
    },
    '& td:nth-child(1),td:nth-child(2),td:nth-child(3)': {
      width: '20px',
    },
    '& td:nth-child(3)': {
      fontWeight: '700',
      borderRight: `1px solid ${grey[500]}`,
      borderLeft: `1px solid ${grey[500]}`,
    },
  },
  iconButton: {
    padding: '4px',
  },
  removeIcon: {
    fontSize: 26,
    transform: 'translateY(-2px)',
    color: theme.status.danger,
  },
  addIcon: {
    fontSize: 26,
    transform: 'translateY(-2px)',
    color: green[600],
  },
  noProducts: {
    fontStyle: 'italic',
    textAlign: 'center',
    marginTop: '10px',
  },
  spacer: {
    height: '44px',
    marginTop: '5px',
  },
  saveAsTemplateInput: {
    width: '100%',
    color: 'red',
  },
  tableFooter: {
    borderTop: `4px double ${grey[500]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  result: {
    padding: '5px',
    fontStyle: 'italic',
  },
  saveAsTemplateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    height: '45px',
    '& p': {
      whiteSpace: 'nowrap',
      fontsize: '1rem',
    },
  },
  '@media (max-width:700px)': {
    tableContainer: {
      width: '90vw',
      overflowX: 'auto',
    },
    table: {
      '& thead': {
        '& th': {
          fontSize: '0.8rem',
        },
      },
      '& td': {
        fontSize: '0.8rem',
      },
    },
    saveAsTemplateContainer: {
      '& p': {
        whiteSpace: 'nowrap',
        fontsize: '0.9rem',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      '& thead': {
        '& th': {
          fontSize: '0.7rem',
        },
      },
      '& td': {
        fontSize: '0.7rem',
      },
    },
    tableFooter: {
      flexDirection: 'column-reverse',
      gap: '10px',
      marginBottom: '30px',
    },
    result: {
      alignSelf: 'flex-end',
    },
  },
}));

const defaultFilteredColumns = ['commodityCode', 'originCountryCode'];

const AddedProductsTable = ({
  products,
  handleRemoveProduct,
  handleAddProduct,
  translationsCommonCodes,
  translations,
  addedProductsCounter,
  templateRows,
  productsFilteredColumns,
  handleUnitAmountChange,
  forceSaveTemplate,
  setInvoiceFormTemplateName,
  invoiceFormTemplateName,
}) => {
  const [anchorElColumnFilter, setAnchorElColumnFilter] = useState(null);
  const [customsValueUnit, setCustomsValueUnit] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [filteredColumns, setFilteredColumns] = useState(defaultFilteredColumns);
  const [saveInvoiceFormTemplate, setSaveInvoiceFormTemplate] = useState(false);

  const columnFilterOpen = Boolean(anchorElColumnFilter);
  const columnFilterId = columnFilterOpen ? 'column-filter-popper' : undefined;

  useEffect(() => {
    if (products.length > 0) {
      const isEqualCustomsValue = products.every(
        (p) => p.unitCurrency === products[0].unitCurrency
      );

      if (!isEqualCustomsValue) {
        customsValueUnit && setCustomsValueUnit(null);
        totalValue > 0 && setTotalValue(0);
        return;
      }

      const updatedTotalValue = products.reduce(
        (acc, curr) => (acc += curr.count * curr.unitValue * curr.unitNumber),
        0
      );
      !customsValueUnit && setCustomsValueUnit(products[0].unitCurrency);
      setTotalValue(updatedTotalValue);
    }

    //reset to original state if all products are deleted from list
    if (products.length === 0 && customsValueUnit) {
      setCustomsValueUnit(null);
      totalValue > 0 && setTotalValue(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (Array.isArray(productsFilteredColumns) && productsFilteredColumns.length > 0) {
      setFilteredColumns(productsFilteredColumns);
    }

    if (forceSaveTemplate) {
      setSaveInvoiceFormTemplate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnListItems = [
    { title: '', id: 'addProduct', filterOption: false, textAlign: 'center' },
    { title: '', id: 'removeProduct', filterOption: false, textAlign: 'center' },
    {
      title: `${translations.areAdded} ${translations.categories.templates}`,
      id: 'added',
      filterOption: false,
      textAlign: 'center',
    },
    {
      title: translations.templates.producttemplate,
      id: 'templateId',
      filterOption: true,
      textAlign: 'start',
    },
    {
      title: translations.invoiceForm.description,
      id: 'description',
      filterOption: true,
      textAlign: 'start',
    },
    {
      title: translations.invoiceForm.unitValue,
      id: 'customsValue',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.amount,
      id: 'unitNumber',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: `Totalt ${translations.invoiceForm.amount}`,
      id: 'templateTotalUnitAmount',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.unit,
      id: 'unitMeasureCode',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.commodityCode,
      id: 'commodityCode',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.country,
      id: 'originCountryCode',
      filterOption: true,
      textAlign: 'center',
    },
  ];

  const buildTableBody = (productsTableValues, fromValue, minWidth940) => {
    let visableProducts = [];
    if (fromValue === 'products')
      visableProducts = productsTableValues.filter(
        ({ templateId }) => !templateRows.some((t) => t.templateId === templateId)
      );

    if (fromValue === 'templates') {
      visableProducts = productsTableValues.map((template) => {
        const templateInAddedProducts = products.find(
          ({ templateId }) => templateId === template.templateId
        );
        if (templateInAddedProducts) return templateInAddedProducts;
        return template;
      });
    }

    return visableProducts.map(
      (
        {
          templateId,
          count,
          description,
          unitValue,
          unitCurrency,
          unitNumber,
          unitMeasureCode,
          originCountryCode,
          commodityCode,
        },
        idx
      ) => (
        <tr key={description + idx}>
          <td>
            <Tooltip title={translations.invoiceForm.addProduct}>
              <IconButton
                className={classes.iconButton}
                style={{ color: green[400] }}
                onClick={() => handleAddProduct({ product: visableProducts[idx] })}
              >
                <AddIcon className={classes.addIcon} />
              </IconButton>
            </Tooltip>
          </td>
          <td>
            <Tooltip title={translations.removeProduct}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleRemoveProduct({ templateId })}
              >
                <DeleteIcon className={classes.removeIcon} />
              </IconButton>
            </Tooltip>
          </td>
          <td>{addedProductsCounter.get(templateId) || 0}</td>
          <td
            style={{
              textAlign: 'start',
              display: filteredColumns.includes('templateId') ? 'none' : 'table-cell',
            }}
          >
            {filterUUIDsFromString(templateId)}
          </td>
          <td
            style={{
              textAlign: 'start',
              display: filteredColumns.includes('description') ? 'none' : 'table-cell',
            }}
          >
            {description}
          </td>
          <td
            style={{
              width: '80px',
              display: filteredColumns.includes('customsValue') ? 'none' : 'table-cell',
            }}
          >{`${unitValue} ${unitCurrency}`}</td>
          <td
            style={{
              display: filteredColumns.includes('unitNumber') ? 'none' : 'table-cell',
              textAlign: 'end',
              width: '65px',
            }}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <p style={{ display: 'flex', justifyContent: 'center' }}>{unitNumber}</p>
              {minWidth940 && (
                <TableCellNumberSpinner
                  handleDecreaseOne={() => handleUnitAmountChange(templateId, 'decrease')}
                  handleIncreaseOne={() => handleUnitAmountChange(templateId, 'increase')}
                />
              )}
            </div>
          </td>
          <td
            style={{
              width: '20px',
              display: filteredColumns.includes('templateTotalUnitAmount') ? 'none' : 'table-cell',
            }}
          >
            {count > 0 ? count * unitNumber : 0}
          </td>
          <td
            style={{ display: filteredColumns.includes('unitMeasureCode') ? 'none' : 'table-cell' }}
          >
            {translationsCommonCodes.ProductUnitOfMeasureCodes[unitMeasureCode]}
          </td>

          <td
            style={{ display: filteredColumns.includes('commodityCode') ? 'none' : 'table-cell' }}
          >
            {commodityCode}
          </td>
          <td
            style={{
              display: filteredColumns.includes('originCountryCode') ? 'none' : 'table-cell',
            }}
          >
            {originCountryCode}
          </td>
        </tr>
      )
    );
  };

  const handleFilterColumnChange = ({ target: { id } }) => {
    const updatedFilteredColumn = [...filteredColumns];
    const filteredIdIndex = updatedFilteredColumn.indexOf(id);
    if (filteredIdIndex < 0) {
      //At least one regular column must be visible
      if (columnListItems.length - 3 - filteredColumns.length <= 1) return;
      updatedFilteredColumn.push(id);
    }
    if (filteredIdIndex >= 0) updatedFilteredColumn.splice(filteredIdIndex, 1);
    setFilteredColumns(updatedFilteredColumn);
  };

  const handleSaveInvoiceFormTemplateChange = () => {
    // Reset template name if user unchecks the checkbox
    if (invoiceFormTemplateName.length > 0 && saveInvoiceFormTemplate) {
      setInvoiceFormTemplateName('');
    }

    setSaveInvoiceFormTemplate(!saveInvoiceFormTemplate);
  };

  const classes = useStyles();
  // Show TableCellNumberSpinner only on desktop
  // Also make save template switch button smaller
  const minWidth940 = useMediaQuery('(min-width:940px)');
  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <div className={classes.tableSettings}>
          <Button
            onClick={(event) =>
              setAnchorElColumnFilter(anchorElColumnFilter ? null : event.currentTarget)
            }
            style={{ padding: 0, width: '32px', height: '32px' }}
          >
            <ColumnsIcon style={{ fontSize: '24px' }} />
          </Button>
          <Popover
            id={columnFilterId}
            open={columnFilterOpen}
            anchorEl={anchorElColumnFilter}
            onClose={() => setAnchorElColumnFilter(null)}
            // onClick={() => setAnchorElColumnFilter(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={classes.popover}
          >
            <div className={classes.filterHeader}>
              <p>Visa kolumner</p>
              <Button
                onClick={() => setAnchorElColumnFilter(null)}
                style={{ padding: 0, width: '32px', height: '32px' }}
              >
                <CloseIcon style={{ fontSize: '24px' }} />
              </Button>
            </div>
            <List className={classes.columnFilterList}>
              {columnListItems
                .filter(({ filterOption }) => filterOption)
                .map((item) => (
                  <ListItem key={item.id} className={classes.listItem}>
                    <Checkbox
                      id={item.id}
                      checked={!filteredColumns.includes(item.id)}
                      onChange={handleFilterColumnChange}
                      className={classes.checkbox}
                    />
                    <p>{item.title}</p>
                  </ListItem>
                ))}
            </List>
          </Popover>
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              {columnListItems
                .filter(({ id }) => !filteredColumns.includes(id))
                .map((row) => (
                  <th style={{ textAlign: row.textAlign }} key={row.id}>
                    {row.title}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {templateRows.length > 0 && buildTableBody(templateRows, 'templates', minWidth940)}
            {products.length > 0 && buildTableBody(products, 'products', minWidth940)}
          </tbody>
        </table>

        {products.length === 0 && (
          <p className={classes.noProducts}>{translations.invoiceForm.noProductsAdded}</p>
        )}
      </div>

      {!customsValueUnit && <div className={classes.spacer}></div>}
      {/* If products are added, show total customs value and option to save as template */}
      {products.length > 0 && customsValueUnit && (
        <div className={classes.tableFooter}>
          <div className={classes.saveAsTemplateContainer}>
            {!forceSaveTemplate && (
              <>
                <p>{translations.invoiceForm.saveAsTemplate}</p>

                <Switch
                  size={minWidth940 ? 'medium' : 'small'}
                  disableRipple
                  onClick={handleSaveInvoiceFormTemplateChange}
                  checked={saveInvoiceFormTemplate}
                />
              </>
            )}
            {saveInvoiceFormTemplate && (
              <FavouriteTextField
                label={'Favourite name'}
                value={invoiceFormTemplateName}
                onChange={(event) => setInvoiceFormTemplateName(event.target.value)}
              />
            )}
          </div>
          <p
            className={classes.result}
          >{`${translations.totalCustomsValue}: ${totalValue} ${customsValueUnit}`}</p>
        </div>
      )}
    </div>
  );
};

export default AddedProductsTable;
