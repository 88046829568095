export const userDetailsMapper = (userDetails) => ({
  company: userDetails.companyName,
  contact: `${userDetails.firstname} ${userDetails.lastname}`,
  phone: userDetails.phone,
  email: userDetails.email,
  city: userDetails.companyCity,
  zip: userDetails.companyPostalCode.replace(/\s/g, ''),
  address1: userDetails.companyAddress1,
  address2: userDetails.companyAddress2,
  countryName: userDetails.companyCountryName,
  countryCode: userDetails.companyCountryCode,
});
