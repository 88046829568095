import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let RequestBooking = (props) => {

	return (
		<SvgIcon {...props}>
			<path d="M0,0H24V24H0Z" fill="none" />
			<path d="M20,2H4A2,2,0,0,0,2,4V22l4-4H20a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2Zm0,14H5.17l-.59.59L4,17.17V4H20Z" />
			<polygon points="15.69 6.9 15.69 14.34 17.28 12.82 17.28 5.38 15.69 6.9" />
			<polygon points="12.12 7.04 12.12 9.37 10.35 9.37 10.35 7.04 7.25 7.04 7.25 14.57 15.21 14.57 15.21 14.57 15.21 7.04 12.12 7.04" />
			<polygon points="13.95 5.05 12.37 6.56 15.35 6.56 16.93 5.05 13.95 5.05" />
			<polygon points="12.18 5.05 9.09 5.05 7.5 6.56 10.6 6.56 12.18 5.05" />
		</SvgIcon>
	);
}

RequestBooking = pure(RequestBooking);
RequestBooking.displayName = 'RequestBooking';
RequestBooking.muiName = 'SvgIcon';

export default RequestBooking
