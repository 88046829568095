import * as actionTypes from './actionTypes';
import axios from 'axios';

export const fetchShipmentsStart = () => {
	return {
		type: actionTypes.FETCH_SHIPMENTS_START
	}
}

export const fetchShipmentsSuccess = (shpmts) => {
	return {
		type: actionTypes.FETCH_SHIPMENTS_SUCCESS,
		shipments: shpmts
	}
}
export const fetchShipmentsFailed = (error) => {
	return {
		type: actionTypes.FETCH_SHIPMENTS_FAILED,
		error
	}
}

export const fetchShipments = (companyId, token) => {
	return dispatch => {
		dispatch(fetchShipmentsStart());
		axios.get(`/api/shipments/customer/${companyId}`, { headers: { Authorization: `Bearer ${token}` } })
			.then(resp => {
				return dispatch(fetchShipmentsSuccess(resp.data.shipmentArray));
			}).catch(err => fetchShipmentsFailed(err));
	}
}

//REMOVE
export const removeShipmentStart = () => {
	return {
		type: actionTypes.REMOVE_SHIPMENT_START
	}
}

export const removeShipmentSuccess = (id) => {
	return {
		type: actionTypes.REMOVE_SHIPMENT_SUCCESS,
		removedId: id
	}
}

export const removeShipmentFailed = (error) => {
	
	return {
		type: actionTypes.REMOVE_SHIPMENT_FAILED,
		error
	}
}

export const removeShipment = (shipmentId, token) => {
	return dispatch => {
		dispatch(removeShipmentStart());
		axios.delete(`/api/shipments/removeone/${shipmentId}`, { headers: { Authorization: `Bearer ${token}` } })
			.then(resp => {
				return dispatch(removeShipmentSuccess(resp.data.deleted));
			}).catch(err => dispatch(removeShipmentFailed(err)));
	}
}

