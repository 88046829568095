import { red } from '@material-ui/core/colors';
import { UPS, UPSSCS, MATKAHUOLTO, CEVA, DHLFREIGHT, HRX, GEODIS } from '../../services/types';

//color palette for layout (now only in online booking)
export const getCarrierColors = (carrierAlias) => {
  switch (carrierAlias) {
    case UPS:
      return { color: 'white', backgroundColor: '#612c14' };
    case UPSSCS:
      return { color: 'white', backgroundColor: '#612c14' };
    case DHLFREIGHT:
      return { color: 'black', backgroundColor: '#fab80a' };
    case MATKAHUOLTO:
      return { color: 'white', backgroundColor: '#0070b5' };
    case CEVA:
      return { color: 'white', backgroundColor: '#021d49' };
    case HRX:
      return { color: 'white', backgroundColor: red[800] };
    case GEODIS:
      return { color: 'white', backgroundColor: '#2e00e0' };
    default:
      return { color: 'black', backgroundColor: '#9e9e9e' };
  }
};
