import React from 'react'

const packageAdd = (props) => {

	const defaults = {
		color: '#fff',
		height: '24',
		width: '24'
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20.17"
			width={props.width || defaults.width}
			height={props.height || defaults.height}>
			>
			<title></title>
			<polygon points="17.42 12.83 16.5 12.83 16.5 14.67 14.67 14.67 14.67 15.58 16.5 15.58 16.5 17.42 17.42 17.42 17.42 15.58 19.25 15.58 19.25 14.67 17.42 14.67 17.42 12.83" fill={props.color || defaults.color} />
			<polygon points="5.04 9.1 4.13 8.71 4.13 6.59 0 4.63 0 15.58 9.17 19.51 9.17 9 5.04 7.03 5.04 9.1" fill={props.color || defaults.color} />
			<polygon points="13.49 1.66 9.63 0 0.58 3.9 4.69 5.86 13.49 1.66" fill={props.color || defaults.color} />
			<polygon points="14.62 2.14 5.76 6.36 9.63 8.2 18.69 3.88 14.62 2.14" fill={props.color || defaults.color} />
			<path d="M20.25,11.63v-6L11.08,10V20.51L14,19.26a5,5,0,1,0,6.24-7.63ZM18,20.25a4.15,4.15,0,0,1-4.13-4.13A4.13,4.13,0,1,1,18,20.25Z" transform="translate(-1 -1)" fill={props.color || defaults.color} />
		</svg>
	)
}

export default packageAdd
