import * as actions from '../actions/actionTypes';

const initialState = {
  templates: [],
  loading: false,
  addError: null,
  fetchError: null,
  updateError: null,
  commentLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD
    case actions.ADD_RECIPIENT_COMPANY_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_RECIPIENT_COMPANY_TEMPLATE_SUCCESS:
      let updatedAddRecipient = state.templates.slice();
      updatedAddRecipient.splice(state.templates.length - 1, 0, action.payload);
      return {
        ...state,
        templates: updatedAddRecipient,
        loading: false,
      };
    case actions.ADD_RECIPIENT_COMPANY_TEMPLATE_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE
    case actions.UPDATE_RECIPIENT_COMPANY_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_RECIPIENT_COMPANY_TEMPLATE_SUCCESS:
      const updateCompanyIndex = state.templates.findIndex((template) => {
        return template.id === action.id;
      });

      let updateCompany = action.updatedCompany;
      updateCompany.id = action.id;
      let updatedUpdateRecipient = state.templates.slice();
      updatedUpdateRecipient.splice(updateCompanyIndex, 1, updateCompany); //todo, integrate with id

      return {
        ...state,
        templates: updatedUpdateRecipient,
        loading: false,
      };
    case actions.UPDATE_RECIPIENT_COMPANY_TEMPLATE_FAILED:
      return {
        ...state,
        updateError: action.error,
        loading: false,
      };
    //FETCH
    case actions.FETCH_RECIPIENT_COMPANIES_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        loading: false,
      };
    case actions.FETCH_RECIPIENT_COMPANIES_TEMPLATES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //REMOVE
    case actions.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };
    case actions.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS:
      //find object to remove
      const removeCompanyIndex = state.templates.findIndex((template) => {
        return template.id === action.payload.id;
      });
      let updatedRemoveRecipient = state.templates.slice();
      updatedRemoveRecipient.splice(removeCompanyIndex, 1);
      return {
        ...state,
        templates: updatedRemoveRecipient,
        loading: false,
      };
    case actions.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //HANDLING CONTACT
    //ADD
    case actions.ADD_RECIPIENT_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_RECIPIENT_COMPANY_CONTACT_SUCCESS:
      let updatedAddRecipientCompanies = state.templates.slice();
      const addCompanyIndex = updatedAddRecipientCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedAddRecipientCompanies[addCompanyIndex].contacts = action.contactData;
      return {
        ...state,
        templates: updatedAddRecipientCompanies,
        loading: false,
      };
    case actions.ADD_RECIPIENT_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //REMOVE
    case actions.REMOVE_RECIPIENT_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };
    case actions.REMOVE_RECIPIENT_COMPANY_CONTACT_SUCCESS:
      let updatedRemoveRecipientCompanies = state.templates.slice();
      const contactRemoveCompanyIndex = updatedRemoveRecipientCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedRemoveRecipientCompanies[contactRemoveCompanyIndex].contacts =
        action.updatedContactData;
      return {
        ...state,
        templates: updatedRemoveRecipientCompanies,
        loading: false,
      };
    case actions.REMOVE_RECIPIENT_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE
    case actions.UPDATE_RECIPIENT_COMPANY_CONTACT_START:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_RECIPIENT_COMPANY_CONTACT_SUCCESS: {
      let updatedUpdateRecipientCompanies = state.templates.slice();
      const contactUpdateCompanyIndex = updatedUpdateRecipientCompanies.findIndex(
        (company) => company.id === action.companyId
      );
      updatedUpdateRecipientCompanies[contactUpdateCompanyIndex].contacts =
        action.updatedContactData;
      return {
        ...state,
        templates: updatedUpdateRecipientCompanies,
        loading: false,
      };
    }
    case actions.UPDATE_RECIPIENT_COMPANY_CONTACT_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //UPDATE COMMENT
    case actions.UPDATE_RECIPIENT_TEMPLATE_COMMENT_FAILED:
      return {
        ...state,
        updateError: action.error,
        commentLoading: false,
      };
    case actions.UPDATE_RECIPIENT_TEMPLATE_COMMENT_START:
      return {
        ...state,
        commentLoading: true,
      };
    case actions.UPDATE_RECIPIENT_TEMPLATE_COMMENT_SUCCESS: {
      let updatedTemplates = state.templates.slice();
      const updatedTemplateIndex = updatedTemplates.findIndex(
        (template) => template.id === action.templateId
      );
      updatedTemplates[updatedTemplateIndex].templateComment = action.templateComment;

      return {
        ...state,
        templates: updatedTemplates,
        commentLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
