import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import PickupSelection from '../../InvoiceAndPickup/PickupSelection/PickupSelection';
import { makeStyles } from '@material-ui/core/styles';
import { anyOverweight, getPackagesCount } from '../../../../services/helpers/packageCalculations';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CircleSpinnerLarge } from '../../../UI/Spinners/Circle';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalTitle: {
    padding: '16px 24px 0px 24px',
  },
  loadingContainer: {
    display: 'grid',
    placeItems: 'center',
  },
  errorContainer: {
    minHeight: '40px',
    '& > p': {
      color: 'red',
      textAlign: 'center',
    },
  },
  button: {
    width: '100%',
    maxWidth: '300px',
    '&:not(:first-child)': {
      marginTop: '5px',
    },
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '15px',
  },
});

const RetryPickupRequestModal = ({
  retryPickupRequestModalOpen,
  cancel,
  translations,
  locale,
  updateConfirmedPickup,
}) => {
  const [error, setError] = useState(null);
  const [isOverweight, setIsOverweight] = useState(null);
  const [packageCount, setPackageCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const bookingData = useSelector((state) => state.booking);

  useEffect(() => {
    setIsOverweight(() => anyOverweight(bookingData.packages));
    setPackageCount(() => getPackagesCount(bookingData.packages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const request = () => {
    setLoading(true);
    const requestData = { ...bookingData, isOverweight, packageCount };

    axios
      .post(
        `/api/collection/create`,
        { requestData },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        updateConfirmedPickup(resp.data.pickupNumber);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.data.message);
      });
  };

  const classes = useStyles();
  return (
    <div>
      <Dialog open={retryPickupRequestModalOpen} onClose={cancel} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className={classes.modalTitle} id='product-dialog-title'>
          {translations.managecollection.requestcollection.modal.title}
        </DialogTitle>
        {loading && (
          <div>
            <CircleSpinnerLarge />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <div className={classes.errorContainer}>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
            <PickupSelection
              locale={locale}
              translations={translations}
              withOptions={false}
              instantUpdate={true}
            />
            <div className={classes.actionContainer}>
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={request}
              >
                {translations.managecollection.requestcollection.title}
              </Button>
              <Button variant='outlined' className={classes.button} onClick={cancel}>
                {translations.templates.cancel}
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default RetryPickupRequestModal;
