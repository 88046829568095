import React from 'react'
import AboutMe from '../../components/MyAccount/AboutMe/AboutMe';
import AccountHistory from '../../components/MyAccount/AccountHistory/AccountHistory';
import PasswordChange from '../../components/MyAccount/PasswordChange/PasswordChange';
import AccountSettings from '../../components/MyAccount/AccountSettings/AccountSettings';
import StatisticsSettings from '../../components/MyAccount/StatisticsSettings/StatisticsSettings';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import translations from '../../services/translations/translations';

const useStyles = makeStyles(theme => ({
	flexContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'

	},
	gridContainer: {
		display: 'grid',
		width: '100%',
		maxWidth: '960px',
		gridColumnGap: '8px',
		gridRowGap: '8px',
		gridTemplateColumns: 'repeat(6, auto)',
		gridTemplateAreas: `
			'main main main main accounthistory accounthistory'
			'main main main main password password'
			'main main main main password password'
			'accountsettings accountsettings accountsettings accountsettings accountsettings accountsettings'
			'statistics statistics statistics statistics statistics statistics'
      `,
		'& h1': {
			fontWeight: '500',
			fontSize: '1.5rem',

		},
		'& h2': {
			fontWeight: '400',
			fontSize: '1rem',
			color: 'black',
		},
	},
	'@media (max-width:800px)': {
		gridContainer: {
			gridTemplateColumns: 'repeat(4, 1fr)',
			gridTemplateAreas: `
				'main main main main'
				'main main main main'
				'main main main main'
				'main main main main'
				'accounthistory accounthistory accounthistory accounthistory'
				'password password password password'
				'accountsettings accountsettings accountsettings accountsettings'
				'statistics statistics statistics statistics'
      `,
		}
	}
}))


const MyAccount = () => {
	const classes = useStyles();
	const lang = useSelector(state => state.rootTemplates.defaultLanguage);
	const text = translations[lang];

	return (
		<div className={classes.flexContainer}>
			<section className={classes.gridContainer}>
				<AboutMe />
				<AccountHistory translations={text.myaccount} />
				<PasswordChange translations={text.myaccount} />
				<AccountSettings translations={text.myaccount} />
				<StatisticsSettings translations={text.myaccount} />
			</section>
		</div>
	)
}

export default MyAccount
