import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getTheme } from '../../../services/statistics/chartThemes';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import translations from '../../../services/translations/translations';
import { CARRIER_NAMES } from '../../../services/types';

const CostPerKiloPerMonth = ({ data: shipmentData, locale, theme, lang }) => {
  echarts.registerTheme(theme, getTheme[theme]);
  moment.locale(locale);

  let currency = '';
  const carriersIncluded = [];
  let minMonth, maxMonth;
  const shipmentsWithMonthCost = shipmentData
    .filter((shpmt) => shpmt.totalCost && shpmt.carrier && shpmt.chargeableWeight)
    .map((shpmt) => {
      if (!carriersIncluded.includes(shpmt.carrier)) carriersIncluded.push(shpmt.carrier);
      if (currency.length === 0 && shpmt.costCurrency) currency = shpmt.costCurrency;

      //calculate cost per kilo
      const costPerKilo =
        Math.round((shpmt.totalCost / shpmt.chargeableWeight) * 100 + Number.EPSILON) / 100;

      const month = moment(shpmt.createdAt).month();
      if (!minMonth || month < minMonth) minMonth = month;
      if (!maxMonth || month > maxMonth) maxMonth = month;
      return { carrier: shpmt.carrier, month: moment(shpmt.createdAt).month(), costPerKilo };
    });

  //fallback when no shipment have any total cost for shipments
  if (shipmentsWithMonthCost.length === 0) {
    const currentMonth = moment().month();
    minMonth = currentMonth;
    maxMonth = currentMonth;
  }
  const monthNbrs = Array(maxMonth - minMonth + 1)
    .fill()
    .map((_, idx) => minMonth + idx);

  const series = carriersIncluded.map((carrier) => {
    const carrierSpecificData = shipmentsWithMonthCost.filter((item) => item.carrier === carrier);
    const data = monthNbrs.map((month) => {
      const monthDataCosts = carrierSpecificData
        .filter((item) => item.month === month)
        .map((item) => item.costPerKilo);

      let monthDataAverage = null;

      if (monthDataCosts.length > 0) {
        const average = monthDataCosts.reduce((acc, curr) => acc + curr) / monthDataCosts.length;
        monthDataAverage = Math.round(average * 10 + Number.EPSILON) / 10;
      }

      return monthDataAverage;
    });

    return {
      name: CARRIER_NAMES[carrier],
      data,
    };
  });
  const title = translations[lang].statistics.costPerKilo;
  const subTitle = translations[lang].statistics.seperatedByCarrier;
  const options = {
    title: {
      text: title,
      subtext: subTitle,
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: carriersIncluded.map((carrier) => CARRIER_NAMES[carrier]),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      show: true,
      orient: 'horizontal',
      right: 30,
      top: 10,
      feature: {
        saveAsImage: { title: translations[lang].statistics.saveAsImage },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: monthNbrs.map((month) => moment().month(month).format('MMM')),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => `${value.toLocaleString(locale)} ${currency}`,
        fontWeight: 400,
        fontSize: 14,
      },
    },
    series: series.map((serie) => ({ name: serie.name, data: serie.data, type: 'line' })),
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default CostPerKiloPerMonth;
