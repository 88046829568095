import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isImportControl, shipmentOutsideEU } from '../../../services/validations/helpers';
import * as shippingTypes from '../../../services/types';
import { getCarrierColors } from '../../../utils/carrier';
import { MatkahuoltoHomeDeliveryServices } from '../../../utils/carrier/lib';
import {
  addBookingPackages,
  addBookingServices,
  addBookingDeliveryMethod,
  updateBillingOption,
  addBookingAlternateDelivery,
  addBookingDropOffLocations,
  addBookingDeliveryRemark,
  addNotification,
  removeNotification,
  clearServiceData,
} from '../../../store/actions';
import PackagesDetails from '../../../components/PackagesDetails/PackagesDetails';
import ShipmentDetails from './ShipmentDetails/ShipmentDetails';
import ShipServices from './ShipServices/ShipServices';
import SnackbarContentWrapper from '../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import CustomPaper from '../../UI/CustomPaper/CustomPaper';
//material ui
import { Button, Radio } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import UpsAdditionalServices from './AdditionalServices/UPS/UPS';
import MatkahuoltoAdditionalServices from './AdditionalServices/Matkahuolto/Matkahuolto';
import DhlFreightAdditionalServices from './AdditionalServices/DhlFreight/DhlFreight.js';
import { onlineBookingServiceRequestValidation } from '../../../services/validations';
import { fetchUpsDropOffLocations } from '../../../store/actions/booking';
import { SET_LOADING, UNSET_LOADING } from '../../../store/actions/actionTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  detailsServicesContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 584px) minmax(auto, 600px)',
    gap: '20px',
    justifyItems: 'center',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  billingContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    border: '1px solid #eee',
    boxShadow: '1px 1px 3px #f1f1f1',
    '& > p': {
      color: '#62485E',
      padding: '5px',
      fontSize: '1rem',
    },
    '& label': {
      fontWeight: 400,
      fontSize: '1rem',
    },
  },
  billingOptionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& label': {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#0e0e0eab',
    },
  },
  billingOption: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  defaultDutiesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  defaultDutiesCurrent: {
    display: 'flex',
    alignItems: 'center',
  },
  dutiesSelectableButton: {
    padding: '2.5px 0',
    margin: '6px',
    fontSize: '0.85rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  shipmentDetails: {
    height: '215px',
    marginBottom: '10px',
  },
  packageDetailsContainer: {
    justifySelf: 'center',
    height: 'fit-content',
    marginBottom: '20px',
  },
  packageDetails: {
    padding: '20px 5px 5px 0',
  },
  searchServices: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    width: '400px',
    padding: '8px 40px',
    fontSize: '1.1rem',
  },
  servicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 32px)',
  },
  services: {
    width: '100%',
    height: 'fit-content',
    alignSelf: 'center',
  },
  servicesMessage: {
    paddingTop: '5px',
    textAlign: 'center',
    fontStyle: 'italic',
  },
  '@media (max-width:1280px)': {
    detailsServicesContainer: {
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
    },
  },
  [theme.breakpoints.down('xs')]: {
    submitButton: {
      width: '80vw',
    },
    shipmentDetails: {
      height: 'max-content',
      marginBottom: '10px',
    },
    billingContainer: {
      gridTemplateColumns: 'auto',
      '& > p': {
        fontSize: '0.9rem',
      },
      '& label': {
        fontSize: '0.9rem',
      },
    },
  },
}));

const PackageAndService = (props) => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.booking);
  const userDetails = useSelector((state) => state.user);
  const { packages: bookingPackages } = useSelector((state) => state.booking);
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { companyCountryCode } = useSelector((state) => state.user);

  //for api requests
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const [dutiesAndTaxesApply, setDutiesAndTaxesApply] = useState(false);

  const [snackbar, setSnackbar] = useState({
    variant: '',
    messages: [],
    open: false,
  });

  const [packages, setPackages] = useState([
    {
      templateName: 'none',
      reference: '',
      count: 1,
      packaging: shippingTypes.OTHER_PACKAGING,
      length: '',
      width: '',
      height: '',
      weight: '',
    },
  ]);

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(shippingTypes.DOOR_DELIVERY);

  const [serviceLoadingIdx, setServiceLoadingIdx] = useState(null); //Sets loading for one specific service
  const [dropOffLocationOptions, setDropOffLocationOptions] = useState([]);
  const [dropOffSelected, setDropOffSelected] = useState(null);
  const [dropOffLoading, setDropOffLoading] = useState(false);
  const [dropOffError, setDropOffError] = useState(null);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [error, setError] = useState(false);
  const [carrierColors, setCarrierColors] = useState({ backgroundColor: 'grey', color: 'black' });
  const [selectableDutiesOption, setSelectableDutiesOption] = useState(false);
  const [packageErrors, setPackageErrors] = useState([]);
  const [importOrThirdPartyShipment, setImportOrThirdPartyShipment] = useState(false);
  const [deliveryRemark, setDeliveryRemark] = useState('');

  //refs for dispatching to redux
  const deliveryMethod = useRef();
  deliveryMethod.current = selectedDeliveryMethod;

  const dropOffLocationOptionsRef = useRef();
  dropOffLocationOptionsRef.current = dropOffLocationOptions;
  const dropOffSelectedRef = useRef();
  dropOffSelectedRef.current = dropOffSelected;

  // On component unmount
  useEffect(() => {
    return () => {
      dispatch(addBookingDeliveryMethod(deliveryMethod.current));
      //Store drop off location options in global state
      dispatch(addBookingDropOffLocations(dropOffLocationOptionsRef.current));
      dispatch(addBookingAlternateDelivery(dropOffSelectedRef.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.onlineBookingCarriers.includes(shippingTypes.UPS) &&
      bookingData.upsDropOffLocations.length === 0
    )
      dispatch(fetchUpsDropOffLocations(bookingData.recipient, token));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    bookingData.deliveryMethod && setSelectedDeliveryMethod(bookingData.deliveryMethod);
    if (bookingPackages.length > 0) {
      setPackages(bookingPackages);
    }

    if (bookingData.alternateDeliveryAddress) {
      const { dropOffLocations, alternateDeliveryAddress } = bookingData;

      if (
        Array.isArray(dropOffLocations) &&
        dropOffLocations.map(({ value }) => value.name).includes(alternateDeliveryAddress.name)
      ) {
        setDropOffLocationOptions(bookingData.dropOffLocations);
      }

      setDropOffSelected({
        value: alternateDeliveryAddress,
        label: `${alternateDeliveryAddress.name}`,
      });
    }

    //Matkahuolto
    if (bookingData.carrier === shippingTypes.MATKAHUOLTO) {
      if (MatkahuoltoHomeDeliveryServices.includes(bookingData.serviceCode)) {
        bookingData.deliveryRemark.length > 0 && setDeliveryRemark(bookingData.deliveryRemark);
      } else {
        bookingData.dropOffLocations && setDropOffLocationOptions(bookingData.dropOffLocations);
        bookingData.alternateDeliveryAddress &&
          setDropOffSelected({
            value: bookingData.alternateDeliveryAddress,
            label: bookingData.alternateDeliveryAddress.name,
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //check whether duties and taxes apply on shipment
    const dtApply = bookingData.documentsOnly
      ? false
      : shipmentOutsideEU(bookingData.sender.countryCode, bookingData.recipient.countryCode);

    setDutiesAndTaxesApply(dtApply);

    //check whether the shipment is a import or third party shipment
    const calculateImport = isImportControl(
      bookingData.sender.countryCode,
      bookingData.recipient.countryCode,
      companyCountryCode
    );
    setImportOrThirdPartyShipment(calculateImport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dutiesAndTaxesApply && bookingData.documentsOnly) setDutiesAndTaxesApply(false);
    if (dutiesAndTaxesApply && !bookingData.documentsOnly) setDutiesAndTaxesApply(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingData.documentsOnly]);

  useEffect(() => {
    const { backgroundColor, color } = getCarrierColors(bookingData.carrier);
    setCarrierColors({ backgroundColor, color });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingData.carrier]);

  //if package values changes then clear all values for service selected
  const clearServices = () => {
    props.handleClearServices();
    handleDeliveryReset();
  };

  const clearAlternateDeliveryAddress = () => {
    handleDropOffChange(null);
    dropOffLocationOptions.length > 0 && setDropOffLocationOptions([]);
  };

  const handleBillingDutiesChange = (e) => {
    dispatch(updateBillingOption(e.target.value));
  };

  const handleUpdatePackages = (rowNum, inputs) => {
    if (bookingData.services.length > 0) clearServices();
    let newPackages = [...packages];
    newPackages[rowNum] = inputs;
    setPackages(newPackages);
    dispatch(addBookingPackages(newPackages));
  };

  const handleUpdatePackagesErrors = (rowNum, rowErrors) => {
    let newErrors = [...packageErrors];
    newErrors[rowNum] = rowErrors;
    setPackageErrors(newErrors);
  };

  const handleRemovePackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.pop();
    setPackages(updatedPackages);
    dispatch(addBookingPackages(updatedPackages));
  };

  const handleAddPackageRow = () => {
    const updatedPackages = [...packages];
    updatedPackages.push({
      templateName: 'none',
      reference: '',
      count: 1,
      packaging: shippingTypes.OTHER_PACKAGING,
      length: '',
      width: '',
      height: '',
      weight: '',
    });
    setPackages(updatedPackages);
  };

  const requestAllRates = async (carriers, bookingData) => {
    return new Promise((resolve) =>
      resolve(
        axios.post(
          `/api/rates/get-all`,
          {
            bookingData: {
              ...bookingData,
              deliveryMethod: shippingTypes.DOOR_DELIVERY,
              accessPointRates: true,
              shipper: userDetails,
            },
            carriers,
          },
          config
        )
      )
    );
  };

  const requestOneSpecificRate = async (carrier, bookingData) => {
    return new Promise((resolve) =>
      resolve(
        axios.post(
          `/api/rates/get-one-specific`,
          {
            carrier,
            bookingData: {
              ...bookingData,
              shipper: userDetails,
            },
          },
          config
        )
      )
    );
  };

  const handleServiceRequest = async () => {
    if (bookingData.services.length > 0) {
      dispatch(clearServiceData());
      handleDeliveryReset();
    }

    //Validate
    const isValidRequest = await onlineBookingServiceRequestValidation({ lang, data: bookingData });

    if (!isValidRequest.valid) {
      setSnackbar({
        ...snackbar,
        open: true,
        variant: isValidRequest.variant,
        messages: isValidRequest.errors,
      });
      return;
    }

    setError(false);
    setServiceLoading(true);

    //Proceed with request for rates based on applicable carriers
    try {
      const rateRequest = await requestAllRates(props.onlineBookingCarriers, {
        ...bookingData,
        withTransitTimes: true,
        serviceCode: '00',
      });
      const { rates } = rateRequest.data;
      if (rates.length > 0) {
        dispatch(addBookingServices(rates));
      } else {
        setError(true);
      }
      setServiceLoading(false);
    } catch (e) {
      setError(true);
      setServiceLoading(false);
    }
  };

  //Updates only cost for a service, keeps the same services
  const updateCostForService = (carrier, serviceCode, props = {}) => {
    const prevServiceData = [...bookingData.services];
    let serviceIdx = bookingData.services.findIndex(
      (service) => service.carrier === carrier && service.serviceCode === serviceCode
    );

    if (serviceIdx < 0) return;
    setServiceLoadingIdx(serviceIdx);
    dispatch({ type: SET_LOADING });
    requestOneSpecificRate(carrier, { ...bookingData, ...props })
      .then((resp) => {
        dispatch({ type: UNSET_LOADING });
        setServiceLoadingIdx(null);
        prevServiceData[serviceIdx] = resp.data.rate;
        dispatch(addBookingServices(prevServiceData));
      })
      .catch((_) => {
        setServiceLoadingIdx(null);
        dispatch({ type: UNSET_LOADING });
      });
  };

  const addTextMessageNotification = () => {
    //Guard for adding multiple text notifications
    if (
      bookingData.notifications.some(
        ({ notificationType }) =>
          notificationType === shippingTypes.NOTIFICATION_RECIPIENT_TEXT_MESSAGE
      )
    )
      return;

    dispatch(
      addNotification({
        notificationType: shippingTypes.NOTIFICATION_RECIPIENT_TEXT_MESSAGE,
        recipients: [bookingData.recipient.phone],
        notifications: [shippingTypes.NOTIFICATION_TYPE_ACCESS_POINT],
      })
    );
  };

  const removeTextMessageNotification = () => {
    //remove text message notification
    bookingData.notifications.length > 0 &&
      dispatch(removeNotification({ recipient: bookingData.recipient.phone }));
  };

  const handleDeliveryMethodChange = (e) => {
    //if option changes from access point to door, update rates
    if (
      selectedDeliveryMethod === shippingTypes.UPS_ACCESS_POINT_DELIVERY &&
      bookingData.carrier === shippingTypes.UPS &&
      e.target.value === shippingTypes.DOOR_DELIVERY
    ) {
      updateCostForService(shippingTypes.UPS, bookingData.serviceCode, {
        deliveryMethod: shippingTypes.DOOR_DELIVERY,
        withTransitTimes: true,
        serviceCode: bookingData.serviceCode,
      });
    }

    setSelectedDeliveryMethod(e.target.value);

    if (e.target.value === shippingTypes.UPS_ACCESS_POINT_DELIVERY) {
      updateCostForService(shippingTypes.UPS, bookingData.serviceCode, {
        deliveryMethod: shippingTypes.UPS_ACCESS_POINT_DELIVERY,
        withTransitTimes: true,
        serviceCode: bookingData.serviceCode,
      });
    } else if (e.target.value === null) {
      handleDropOffChange(null);
    }
  };

  const handleDeliveryReset = () => {
    clearAlternateDeliveryAddress();
    setSelectedDeliveryMethod(shippingTypes.DOOR_DELIVERY);
  };

  const handleDeliveryMethodChangeByCode = (code) => {
    if (code === shippingTypes.MATKAHUOLTO_HOME_DELIVERY)
      dropOffSelected && setDropOffSelected(null);
    if (code === shippingTypes.MATKAHUOLTO_DROPOFF_DELIVERY)
      handleDropOffLocationRequest(shippingTypes.MATKAHUOLTO, false);

    setSelectedDeliveryMethod(code);
  };

  const handleDropOffLocationRequest = (carrier, setDefaultDropOff = false) => {
    setDropOffLoading(true);
    dispatch({ type: SET_LOADING });
    dropOffError && setDropOffError(null); //reset error message

    axios
      .post('/api/shipping/locator', { address: bookingData.recipient, carrier }, config)
      .then((resp) => {
        const dropOffOptions = resp.data.locatorResults.locations.map((dropOff) => ({
          value: { code: dropOff.id, name: dropOff.name, address: dropOff.address },
          label: `${dropOff.name} (${dropOff.distance} ${dropOff.distanceUnit})`,
        }));

        //When transport carrier is MATKAHUOLTO, No initial value - make user manually select drop off location
        dispatch({ type: UNSET_LOADING });
        setDropOffLoading(false);
        setDropOffLocationOptions(dropOffOptions);
        setDefaultDropOff && dropOffOptions.length > 0 && handleDropOffChange(dropOffOptions[0]);
      })
      .catch((e) => {
        dispatch({ type: UNSET_LOADING });
        setDropOffLoading(false);
        setDropOffError(props.translations.onlinebooking.noaccesspointfound);
      });
  };

  const handleDropOffChange = (value) => {
    setDropOffSelected(value);
    dispatch(addBookingAlternateDelivery(value));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const handleDeliveryRemark = (e) => {
    setDeliveryRemark(e.target.value);
  };
  const handleDeliveryRemarkBlur = () => {
    dispatch(addBookingDeliveryRemark(deliveryRemark));
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.detailsServicesContainer}>
        <div className={classes.detailsContainer}>
          <div className={classes.packageDetailsContainer}>
            <CustomPaper title={props.translations.onlinebooking.dimweights}>
              <div className={classes.packageDetails}>
                <PackagesDetails
                  updatePackagesErrors={handleUpdatePackagesErrors}
                  updatePackages={handleUpdatePackages}
                  packageRows={packages.length}
                  removePackageRow={handleRemovePackageRow}
                  addPackageRow={handleAddPackageRow}
                  type="booking"
                />
              </div>
            </CustomPaper>
          </div>
          <div className={classes.shipmentDetails}>
            <ShipmentDetails
              onlineBookingCarriers={props.onlineBookingCarriers}
              translations={props.translations}
            />
          </div>
          {dutiesAndTaxesApply && !importOrThirdPartyShipment && (
            <div className={classes.billingContainer}>
              <p className={classes.title}>{props.translations.onlinebooking.billdutiesto}:</p>

              {selectableDutiesOption ? (
                <div className={classes.billingOptionsContainer}>
                  <div className={classes.billingOption}>
                    <label htmlFor="bill-duties-recipient">
                      {props.translations.onlinebooking.timeline.recipient}
                    </label>
                    <Radio
                      color="primary"
                      checked={bookingData.billingDuties === shippingTypes.BILL_DUTIES_RECIPIENT}
                      onChange={handleBillingDutiesChange}
                      value={shippingTypes.BILL_DUTIES_RECIPIENT}
                      name="bill-duties-recipient"
                      id="bill-duties-recipient"
                    />
                  </div>
                  <div className={classes.billingOption}>
                    <label style={{ marginLeft: '10px' }} htmlFor="bill-duties-sender">
                      {props.translations.onlinebooking.timeline.sender}
                    </label>
                    <Radio
                      color="primary"
                      checked={bookingData.billingDuties === shippingTypes.BILL_DUTIES_SENDER}
                      onChange={handleBillingDutiesChange}
                      value={shippingTypes.BILL_DUTIES_SENDER}
                      name="bill-duties-sender"
                      id="bill-duties-sender"
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.defaultDutiesContainer}>
                  <div className={classes.defaultDutiesCurrent}>
                    {bookingData.billingDuties === shippingTypes.BILL_DUTIES_RECIPIENT && (
                      <label htmlFor="bill-duties-default">
                        {' '}
                        {props.translations.onlinebooking.timeline.recipient}
                      </label>
                    )}
                    {bookingData.billingDuties === shippingTypes.BILL_DUTIES_SENDER && (
                      <label htmlFor="bill-duties-default">
                        {' '}
                        {props.translations.onlinebooking.timeline.sender}
                      </label>
                    )}
                    <Radio disabled checked name="bill-duties-default" id="bill-duties-default" />
                  </div>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectableDutiesOption(true)}
                    className={classes.dutiesSelectableButton}
                  >
                    {props.translations.templates.change}
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className={classes.searchServices}>
            <Button
              className={classes.submitButton}
              onClick={handleServiceRequest}
              color="primary"
              variant="outlined"
            >
              {props.translations.onlinebooking.findservices}
            </Button>
          </div>
        </div>
        <CustomPaper title={props.translations.onlinebooking.transportservices}>
          <div className={classes.servicesContainer}>
            <div className={classes.services}>
              {bookingData.services.length === 0 && (
                <>
                  {error && (
                    <p className={classes.servicesMessage}>
                      {props.translations.onlinebooking.noservicesfound}
                    </p>
                  )}
                  {!error && !serviceLoading && (
                    <p className={classes.servicesMessage}>
                      {props.translations.onlinebooking.noservices}
                    </p>
                  )}
                </>
              )}

              {bookingData.services.length > 0 && (
                <div className={classes.services}>
                  <ShipServices
                    matkahuoltoHomeDeliveryServices={MatkahuoltoHomeDeliveryServices}
                    carrierColors={carrierColors}
                    serviceLoadingIdx={serviceLoadingIdx}
                    lang={lang}
                    translations={props.translations}
                    error={error}
                    selectedDeliveryMethod={selectedDeliveryMethod}
                    handleDeliveryMethodChangeByCode={handleDeliveryMethodChangeByCode}
                    handleDropOffLocationRequest={handleDropOffLocationRequest}
                    clearAlternateDeliveryAddress={clearAlternateDeliveryAddress}
                    dropOffLoading={dropOffLoading}
                    updateCostForService={updateCostForService}
                  />
                </div>
              )}
              {serviceLoading && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircleSpinnerLarge />
                </div>
              )}
            </div>
            <div className={classes.additionalServices}>
              {bookingData.carrier === shippingTypes.UPS && !importOrThirdPartyShipment && (
                <UpsAdditionalServices
                  addTextMessageNotification={addTextMessageNotification}
                  removeTextMessageNotification={removeTextMessageNotification}
                  handleDeliveryMethodChange={handleDeliveryMethodChange}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  translations={props.translations}
                  dropOffSelected={dropOffSelected}
                  setDropOffSelected={(v) => setDropOffSelected(v)}
                  handleDropOffChange={handleDropOffChange}
                  carrierColors={carrierColors}
                />
              )}
              {bookingData.carrier === shippingTypes.DHLFREIGHT && (
                <DhlFreightAdditionalServices
                  setSelectedDeliveryMethod={setSelectedDeliveryMethod}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  translations={props.translations}
                  dropOffLoading={dropOffLoading}
                  dropOffError={dropOffError}
                  dropOffSelected={dropOffSelected}
                  handleDropOffChange={handleDropOffChange}
                  dropOffLocationOptions={dropOffLocationOptions}
                  carrierColors={carrierColors}
                  updateCostForService={updateCostForService}
                />
              )}
              {bookingData.carrier === shippingTypes.MATKAHUOLTO && (
                <MatkahuoltoAdditionalServices
                  deliveryRemark={deliveryRemark}
                  handleDeliveryRemark={handleDeliveryRemark}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  handleDeliveryRemarkBlur={handleDeliveryRemarkBlur}
                  translations={props.translations}
                  dropOffLoading={dropOffLoading}
                  dropOffError={dropOffError}
                  dropOffSelected={dropOffSelected}
                  handleDropOffChange={handleDropOffChange}
                  dropOffLocationOptions={dropOffLocationOptions}
                  carrierColors={carrierColors}
                />
              )}
            </div>
          </div>
        </CustomPaper>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default PackageAndService;
