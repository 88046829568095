import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchShipments } from '../../store/actions';
import { lightThemes, darkThemes } from '../../services/statistics/chartThemes';
import { CircleSpinnerLarge } from '../../components/UI/Spinners/Circle';
//components
import MonthlyShipments from '../../components/Statistics/MonthlyShipments/MonthlyShipments';
import ExportWeightByCountry from '../../components/Statistics/ExportWeightByCountry/ExportWeightByCountry';
import CarrierDistribution from '../../components/Statistics/CarrierDistribution/CarrierDistribution';
import DailyBookingTimeDistribution from '../../components/Statistics/DailyBookingTimeDistribution/DailyBookingTimeDistribution';
import WorldExportMap from '../../components/Statistics/WorldExportMap/WorldExportMap';
//material ui
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  yearSelectionContainer: {
    display: 'flex',
    color: 'gray',
    '& > p': {
      textDecoration: 'underline',
      padding: '0 5px',
      fontSize: '1.2rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  themeSelectionContainer: {
    display: 'flex',
    marginBottom: '10px',
    '& button + button': {
      margin: '0 5px',
    },
  },
  themeButtons: {
    position: 'relative',
    height: '26px',
    width: '80px',
    border: 'none',
    borderRadius: '3px',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  lightTheme: {
    '&:before': {
      position: 'absolute',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      borderBottom: '26px solid white',
      borderLeft: '80px solid transparent',
      content: '""',
    },
    '&:after': {
      position: 'absolute',
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      borderTop: '26px solid #6fd0d0',
      borderRight: '80px solid transparent',
      content: '""',
    },
  },
  darkTheme: {
    '&:before': {
      position: 'absolute',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      borderBottom: '26px solid #8a788e',
      borderLeft: '80px solid transparent',
      content: '""',
    },
    '&:after': {
      position: 'absolute',
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      borderTop: '26px solid #383838',
      borderRight: '80px solid transparent',
      content: '""',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(2, 300px) 300px 300px',
    gap: '10px',
  },
  spinner: {
    gridColumn: '1 / span 6',
    placeSelf: 'center',
  },
  monthlyShipments: {
    gridColumn: '1 / span 3',
  },
  countryWeightDistribution: {
    gridColumn: '4 / -1',
  },
  carrierDistribution: {
    gridColumn: '1 / span 2',
  },
  dailyBookingTimeDistribution: {
    gridColumn: '3 / span 4',
  },
  worldExportMap: {
    gridColumn: '1 / -1',
    gridRow: '3 / 5',
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      gridAutoRows: '230px',
    },
    titles: {
      fontSize: '0.9rem',
    },
    monthlyShipments: {
      gridColumn: '1 / -1 !important',
    },
    countryWeightDistribution: {
      gridColumn: '1 / -1',
    },
    carrierDistribution: {
      gridColumn: '1 / -1',
    },
    dailyBookingTimeDistribution: {
      gridColumn: '1 / -1',
      gridRow: '4 / 5',
    },
    worldExportMap: {
      gridRow: '4 /5',
    },
  },
}));

const ShippingStatistics = () => {
  const { shipmentData, loading: shipmentLoading } = useSelector((state) => state.shipments);
  const [annualData, setAnnualData] = useState({ year: null, data: null, loading: false });
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [themeType, setThemeType] = useState('light');

  const { companyId } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  const dispatch = useDispatch();

  useEffect(() => {
    !shipmentData && dispatch(fetchShipments(companyId, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shipmentData && years.length === 0) {
      const distinctYears = new Set();
      shipmentData.forEach(({ createdAt }) => {
        const year = moment(createdAt).year();
        if (!distinctYears.has(year)) {
          distinctYears.add(year);
        }
      });
      const distinctYearsSortedArray = Array.from(distinctYears).sort((a, b) => b - a);
      distinctYearsSortedArray.unshift(0); //use 0 as placeholder for all years

      //update years which have shipments with array of years ascending sorted
      setSelectedYear(() => distinctYearsSortedArray[0]);
      setYears(distinctYearsSortedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData]);

  useEffect(() => {
    if (shipmentData && annualData.year !== selectedYear) {
      setAnnualData({ ...annualData, loading: true });
      let filteredShipments = [...shipmentData];

      //if year is not set to 0(representing all years) then filter shipments by selected year
      if (selectedYear)
        filteredShipments = filteredShipments.filter(
          ({ createdAt }) => moment(createdAt).year() === selectedYear
        );

      setTimeout(() => {
        //this is needed for updating correctly
        setAnnualData(() => ({ year: selectedYear, data: filteredShipments, loading: false }));
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const chartComponents = [
    {
      component: (props) => <MonthlyShipments {...props} />,
      className: 'monthlyShipments',
    },
    {
      component: (props) => <ExportWeightByCountry {...props} />,
      className: 'countryWeightDistribution',
    },
    {
      component: (props) => <CarrierDistribution {...props} />,
      className: 'carrierDistribution',
    },
    {
      component: (props) => <DailyBookingTimeDistribution {...props} />,
      className: 'dailyBookingTimeDistribution',
    },
    {
      component: (props) => <WorldExportMap {...props} />,
      className: 'worldExportMap',
    },
  ];

  let themes;
  if (themeType === 'dark') themes = darkThemes;
  if (themeType === 'light') themes = lightThemes;
  let themeIndex = 0;
  const loading = shipmentLoading || annualData.loading;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.themeSelectionContainer}>
          <button
            style={{ background: themeType === 'light' ? '#e6e6e6' : 'none' }}
            className={classes.themeButtons}
            onClick={() => setThemeType('light')}
          >
            <span className={classes.lightTheme}></span>
          </button>
          <button
            style={{ background: themeType === 'dark' ? '#e6e6e6' : 'none' }}
            className={classes.themeButtons}
            onClick={() => setThemeType('dark')}
          >
            <span className={classes.darkTheme}></span>
          </button>
        </div>
        <div className={classes.yearSelectionContainer}>
          {years.length > 0 &&
            years.map((year) => (
              <p
                key={year}
                style={{ color: year === selectedYear ? 'black' : 'inherit' }}
                onClick={() => setSelectedYear(year)}
              >
                {year || 'All'}
              </p>
            ))}
        </div>
      </div>
      <div className={classes.container}>
        {loading && (
          <div className={classes.spinner}>
            <CircleSpinnerLarge />
          </div>
        )}
        {!loading &&
          annualData.data &&
          chartComponents.map((chartComponent, i) => {
            const { component, className } = chartComponent;
            if (i !== 0) themeIndex === i % 2 ? (themeIndex = 0) : (themeIndex = 1); //sets themes in pairs on every other consecutive components ([1],[2,3],[4,5]...[n,n+1])
            return (
              <div key={className} className={classes[className]}>
                {component({ data: annualData.data, lang: lang, theme: themes[themeIndex] })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ShippingStatistics;
