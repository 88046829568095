import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'center'
	},
	container: {
		minWidth: '700px',
		minHeight: '80vh'
	}

})

const RequestOrder = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Paper className={classes.container}>
			</Paper>
		</div>
	)
}

export default RequestOrder
