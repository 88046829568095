import React, { useState, useEffect } from 'react';
import Navigator from '../../components/Navigator/Navigator';
import NavigatorMobile from '../../components/Navigator/NavigatorMobile';
import { withRouter, Link } from 'react-router-dom';
import PrimaryHeader from '../../components/Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { userConsent, fetchUserDetails } from '../../store/actions';
import translations from '../../services/translations/translations';
import GDPR from './GDPRNotification/GDPRNotification';
import { CircleSpinnerLarge } from '../../components/UI/Spinners/Circle';
//material UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//material UI icons
import AccountIcon from '@material-ui/icons/PersonRounded';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import RateIcon from '@material-ui/icons/MonetizationOn';
import ChartIcon from '@material-ui/icons/InsertChartRounded';
import ListIcon from '@material-ui/icons/ListRounded';
import BarChartIcon from '@material-ui/icons/BarChartRounded';
import ShowChart from '@material-ui/icons/ShowChartRounded';
import FileBookingIcon from '../../components/UI/Icons/BatchBooking';
// import InvoiceIcon from '@material-ui/icons/DescriptionRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//custom icons
import TemplateIcon from '../../components/UI/Icons/TemplateNew';
import BookingIcon from '../../components/UI/Icons/PackageMove';
import ToolsIcon from '../../components/UI/Icons/PackageTools';
import PickupIcon from '../../components/UI/Icons/Pickup';
import OnlineBookingIcon from '../../components/UI/Icons/OnlineBooking';
import RequestBookingIcon from '../../components/UI/Icons/RequestBooking';
import CalculatorIcon from '../../components/UI/Icons/Calculator';
import TrackingIcon from '../../components/UI/Icons/Tracking';

//NOTE this layout should only be shown to logged in users
function TabPanel({ children, styles }) {
  return <div className={styles}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  secondaryHeader: {
    marginTop: '44px',
    width: '100%',
  },
  tabBar: {
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      zIndex: 1300,
    },
    maxWidth: 'calc(100vw - 72px)',
    '@media (max-width:740px)': {
      maxWidth: '100vw',
    },
  },
  tabPanel: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '64px 5px 64px 5px',
    },
    '@media (max-width:740px)': {
      paddingBottom: '64px !important',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
      paddingTop: '20px',
    },
  },
  tabRoot: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '38px',
    },
  },
  tabsRoot: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '38px',
    },
  },
  tabLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 'fit-content',
    '& > h3': {
      fontSize: '0.95rem',
      // textTransform:'lowercase',
      textTransform: 'capitalize',
      fontWeight: '500',
      alignSelf: 'center',
      color: 'black',
      padding: '0 5px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1px 0 3px',
        fontSize: '0.9rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem',
        fontWeight: '400',
        padding: '0 1px 0 3px',
      },
    },
    '& > span': {
      paddingRight: '2px',
      paddingTop: '5px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px',
        paddingTop: '2px',
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
        paddingTop: '2px',
      },
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    boxSizing: 'border-box',
    padding: '0px',
    // padding: '20px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
    },
  },
  navigation: {
    /*  display: 'fixed',
     bottom: '0' */
  },
  multiApp: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const isAuth = useSelector((state) => state.auth.token !== null);
  const token = useSelector((state) => state.auth.token);
  const {
    consent: gdprConsent,
    loggedIn,
    error,
    allowFileBooking,
  } = useSelector((state) => state.user);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const dispatch = useDispatch();
  const [gdprOpen, setGdprOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const mobileNavigator = useMediaQuery('(max-width:740px)');

  useEffect(() => {
    if (isAuth && !error && !loggedIn) {
      dispatch(fetchUserDetails(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackbarClose = () => {
    setGdprOpen(false);
  };

  const handleSnackbarConsent = () => {
    if (isAuth) {
      dispatch(userConsent(token));
    }
    setGdprOpen(false);
  };

  let authRedirect = null;
  if (!isAuth || error) {
    authRedirect = <Redirect to="/" />;
  }

  if (loadingState && loggedIn) {
    setLoadingState(false);
  }

  let { routeId } = props;
  const iconLarge = 38;
  const iconMedium = 30;

  const categories = [
    {
      id: '/bookings',
      tooltip: translations[lang].categories.bookings,
      name: translations[lang].categories.booking,
      icon: <BookingIcon style={{ fontSize: iconLarge }} />,
      iconMedium: <BookingIcon style={{ fontSize: iconMedium }} />,
      resources: [
        {
          id: '/select',
          name: translations[lang].categories.bookingtype,
          icon: <BookingIcon />,
          iconLarge: <BookingIcon style={{ fontSize: iconLarge }} />,
          path: '/bookings/select',
          background: 'white',
        },
        {
          id: '/onlinebooking',
          name: translations[lang].categories.onlinebooking,
          icon: <OnlineBookingIcon />,
          iconLarge: <OnlineBookingIcon style={{ fontSize: iconLarge }} />,
          path: '/bookings/onlinebooking',
          background: 'white',
        },
        {
          id: '/requestbooking',
          name: translations[lang].categories.requestbooking,
          icon: <RequestBookingIcon />,
          iconLarge: <RequestBookingIcon style={{ fontSize: iconLarge }} />,
          path: '/bookings/requestbooking',
          background: 'white',
        },
        {
          id: '/pickup',
          name: translations[lang].categories.manageCollection,
          icon: <PickupIcon />,
          iconLarge: <PickupIcon style={{ fontSize: iconLarge }} />,
          path: '/bookings/pickup',
        },
      ],
    },
    {
      id: '/tools',
      tooltip: translations[lang].categories.tools,
      name: translations[lang].categories.tools,
      icon: <ToolsIcon style={{ fontSize: iconLarge, transform: 'translate(3px, 3px)' }} />,
      iconMedium: <ToolsIcon style={{ fontSize: iconMedium, transform: 'translate(3px, 3px)' }} />,
      resources: [
        {
          id: '/myshipments',
          name: translations[lang].categories.shippingtable,
          icon: <ListIcon />,
          iconLarge: <ListIcon style={{ fontSize: iconLarge }} />,
          path: '/tools/myshipments',
        },
        {
          id: '/templates',
          name: translations[lang].categories.templates,
          icon: <TemplateIcon />,
          iconLarge: <TemplateIcon style={{ fontSize: iconLarge }} />,
          path: '/tools/templates',
        },
        {
          id: '/trackedshipments',
          name: translations[lang].categories.tracking,
          icon: <TrackingIcon />,
          iconLarge: <TrackingIcon style={{ fontSize: iconLarge }} />,
          path: '/tools/trackedshipments',
        },
      ],
    },
    {
      id: '/rates',
      tooltip: translations[lang].categories.rates,
      name: translations[lang].categories.rates,
      icon: <RateIcon style={{ fontSize: iconLarge }} />,
      iconMedium: <RateIcon style={{ fontSize: iconMedium }} />,
      resources: [
        {
          id: '/ratecalc',
          name: translations[lang].categories.freightcalc,
          icon: <CalculatorIcon />,
          iconLarge: <CalculatorIcon style={{ fontSize: iconLarge }} />,
          path: '/rates/ratecalc',
        },
      ],
    },
    {
      id: '/statistics',
      tooltip: translations[lang].categories.statistics,
      name: translations[lang].categories.statistics,
      icon: <ChartIcon style={{ fontSize: iconLarge }} />,
      iconMedium: <ChartIcon style={{ fontSize: iconMedium }} />,
      resources: [
        {
          id: '/shipstats',
          name: translations[lang].categories.statistics,
          icon: <BarChartIcon />,
          iconLarge: <BarChartIcon style={{ fontSize: iconLarge }} />,
          path: '/statistics/shipstats',
        },
        {
          id: '/coststats',
          name: translations[lang].categories.financialOverview,
          icon: <ShowChart />,
          iconLarge: <ShowChart style={{ fontSize: iconLarge }} />,
          path: '/statistics/coststats',
        },
      ],
    },
    {
      id: '/settings',
      tooltip: translations[lang].categories.settings,
      name: translations[lang].categories.settings,
      icon: <SettingsIcon style={{ fontSize: iconLarge }} />,
      iconMedium: <SettingsIcon style={{ fontSize: iconMedium }} />,
      resources: [
        {
          id: '/myaccount',
          name: translations[lang].categories.myaccount,
          icon: <AccountIcon />,
          iconLarge: <AccountIcon style={{ fontSize: iconLarge }} />,
          path: '/settings/myaccount',
        },
        /* Invoices section under development */
        // {
        //   id: '/invoices',
        //   name: translations[lang].categories.invoices,
        //   icon: <InvoiceIcon />,
        //   iconLarge: <InvoiceIcon style={{ fontSize: iconLarge }} />,
        //   path: '/settings/invoices',
        // },
      ],
    },
  ];

  if (allowFileBooking) {
    categories[0].resources.push({
      id: '/filebooking',
      name: translations[lang].categories.batchBooking,
      icon: <FileBookingIcon />,
      iconLarge: <FileBookingIcon style={{ fontSize: iconLarge }} />,
      path: '/bookings/filebooking',
      background: 'white',
    });
  }

  let currentRoute;
  let value = 0;

  if (!routeId) {
    currentRoute = categories.find(({ id }) => id === props.match.path);
  } else {
    currentRoute = categories.find(({ id }) => id === routeId);
    const innerRoute = props.match.url.replace(routeId, '');
    const innerId = currentRoute.resources.findIndex(({ id }) => id === innerRoute);
    value = innerId;
  }

  // Validate that route exists and user has access to it, otherwise redirect to home
  if (value === -1) {
    return <Redirect to="/" />;
  }

  //check whether route has a different background color, else use a default grey color
  const backgroundColor = currentRoute.resources[value].background || '#eaeff1';

  return (
    <div className={classes.root}>
      {authRedirect}
      {!error && loadingState && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'grid',
            placeItems: 'center',
            transform: 'translateY(-20vh)',
          }}
        >
          <CircleSpinnerLarge />
        </div>
      )}
      {!error && !loadingState && loggedIn && (
        <div className={classes.appContent}>
          <PrimaryHeader
            categoryItems={categories}
            translations={translations[lang].accountpopup}
            location={props.location.pathname}
          />
          <main className={classes.mainContent} style={{ backgroundColor }}>
            <nav className={classes.navigation}>
              {mobileNavigator ? (
                <NavigatorMobile categoryItems={categories} />
              ) : (
                <Navigator categoryItems={categories} />
              )}
            </nav>
            <div className={classes.secondaryHeader}>
              <AppBar className={classes.tabBar} position="static">
                <Tabs
                  value={value}
                  variant={'scrollable'}
                  scrollButtons="on"
                  classes={{
                    root: classes.tabsRoot,
                  }}
                >
                  {currentRoute.resources.map((sub, index) => (
                    <Tab
                      component={Link}
                      to={routeId ? `${routeId}${sub.id}` : `${props.match.url}${sub.id}`}
                      key={index}
                      classes={{
                        root: classes.tabRoot,
                      }}
                      label={
                        <>
                          <div className={classes.tabLabel}>
                            <span>{sub.icon}</span>
                            <h3>{sub.name}</h3>
                          </div>
                        </>
                      }
                    />
                  ))}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} styles={classes.tabPanel}>
                {isAuth ? props.children : null}
              </TabPanel>
            </div>
          </main>
        </div>
      )}
      {!gdprConsent && (
        <GDPR
          consent={translations[lang].iconsent}
          message={translations[lang].gdprconsent}
          open={gdprOpen}
          handleSnackbarClose={handleSnackbarClose}
          handleSnackbarConsent={handleSnackbarConsent}
        />
      )}
    </div>
  );
};

export default withRouter(Layout);
