import React, { useEffect, useState } from 'react';
import DateSelect from '../../../DateSelect/DateSelect';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import { DHL_RETURN_SERVICES, DHL_SERVICE_POINT_RETURN_SERVICE } from '../../../../services/types';

const useStyles = makeStyles((theme) => ({
  pickupContainer: {
    padding: '15px',
  },
  pickupSelectionOptions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  pickupOption: {
    display: 'flex',
    alignItems: 'center',
  },
  requestPickupContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: '10px',
  },
  [theme.breakpoints.down('xs')]: {
    pickupSelectionOptions: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: 'fit-content',
    },
    pickupOption: {
      '& label': {
        fontSize: '0.9rem',
      },
    },
  },
  '@media (max-width:400px)': {
    pickupSelectionOptions: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    pickupOption: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5px 0',
      '& label': {
        fontSize: '0.9rem',
        textAlign: 'center',
      },
    },
  },
}));

const DhlFreightPickup = (props) => {
  const [isReturn, setIsReturn] = useState(false);
  const classes = useStyles();

  //Atm, always use pickup for DHL
  useEffect(() => {
    if (!props.requestPickup) props.handlePickupChange();
    !isReturn && DHL_RETURN_SERVICES.includes(props.serviceCode) && setIsReturn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alternativePickupLabel = !isReturn
    ? props.translations.onlinebooking.useExistingPickup
    : props.serviceCode === DHL_SERVICE_POINT_RETURN_SERVICE
    ? props.translations.onlinebooking.leaveAtServicePoint
    : props.translations.onlinebooking.requestPickupLater;

  return (
    <div className={classes.pickupContainer}>
      <div className={classes.pickupSelectionOptions}>
        <div className={classes.pickupOption}>
          <Radio
            color='primary'
            checked={isReturn ? false : props.requestPickup}
            onChange={props.handlePickupChange}
            value='01'
            name='requestpickup'
            id='requestpickup'
            disabled={isReturn}
          />
          <label htmlFor='requestpickup'>{props.translations.onlinebooking.requestpickup}</label>
        </div>
        <div className={classes.pickupOption}>
          <Radio
            color='primary'
            checked={isReturn ? true : !props.requestPickup}
            onChange={props.handlePickupChange}
            value='0'
            name='usecurrentpickup'
            id='usecurrentpickup'
            disabled={!isReturn}
          />
          <label htmlFor='usecurrentpickup'>{alternativePickupLabel}</label>
        </div>
      </div>
      {props.requestPickup && (
        <div className={classes.requestPickupContainer}>
          <DateSelect
            locale={props.locale}
            label={props.translations.onlinebooking.pickupdate}
            inline={true}
            handleDateChange={props.handlePickupDateChange}
            selectedDate={props.selectedPickupDate}
          />
          <TextField
            id='pickupReference'
            inputProps={{ maxLength: 35 }}
            label={props.translations.onlinebooking.pickupInstructions}
            className={classes.textField}
            name='pickupReference'
            value={props.pickupReference}
            onChange={props.handlePickupReferenceChange}
            margin='none'
            disabled={isReturn}
          />
        </div>
      )}
    </div>
  );
};

export default DhlFreightPickup;
