import { getCarrierIcon } from './index';
import { packagingValues } from './lib';
import EnvelopeImage from '../../assets/packaging/ups_express_envelope.webp';
import BoxImage from '../../assets/packaging/ups_express_box.webp';
import PakImage from '../../assets/packaging/ups_express_pak.webp';
import TubeImage from '../../assets/packaging/ups_express_tube.png';
import PackageDefaultIcon from '../../assets/package.svg';
// import DefaultImage from '../../assets/packaging/default_packaging.png';
import {
  UPS_EXPRESS_PAK,
  UPS_EXPRESS_BOX,
  UPS_EXPRESS_TUBE,
  UPS_EXPRESS_ENVELOPE,
  OTHER_PACKAGING,
} from '../../services/types';

export const getCarrierPackagingFields = () => {
  return packagingValues.map((packaging) => {
    const icon = getCarrierIcon(packaging.carrier);

    return { ...packaging, icon };
  });
};

export const getPackagingImage = (packagingCode) => {
  switch (packagingCode) {
    case OTHER_PACKAGING:
      return PackageDefaultIcon;
    case UPS_EXPRESS_ENVELOPE:
      return EnvelopeImage;
    case UPS_EXPRESS_BOX:
      return BoxImage;
    case UPS_EXPRESS_PAK:
      return PakImage;
    case UPS_EXPRESS_TUBE:
      return TubeImage;
    default:
      return PackageDefaultIcon;
  }
};
