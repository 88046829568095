import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import translations from '../../../services/translations/translations';
import 'moment/locale/sv';
import 'moment/locale/fi';
//components
import ShippingDocsTable from './ShippingDocsTable/ShippingDocsTable';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';

//custom
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import { grey } from '@material-ui/core/colors';
import TrackingViewProvider from './TrackingViewProvider/TrackingViewProvider';
import TrackingInputForm from './TrackingInputForm/TrackingInputForm';

const nbrStrDelimiter = (val) => (typeof val === 'string' ? <span>&nbsp;{val}</span> : val);

const shipmentDetailsFields = [
  {
    type: 'simple',
    fields: ['createdAt'],
    label: 'created',
    display: 'row',
    func: (value) => moment(value).format('LLL'),
  },
  { type: 'simple', fields: ['trackingNumber'], label: 'trackingnumber', display: 'row' },
  {
    type: 'extended',
    fields: [
      { label: 'company', fieldName: 'senderCompanyName' },
      { label: 'contact', fieldName: 'senderContactName' },
      { label: 'addressline1', fieldName: 'senderAddressLine1' },
      { label: 'addressline2', fieldName: 'senderAddressLine2' },
      { label: 'zip', fieldName: 'senderAddressPostal' },
      { label: 'city', fieldName: 'senderAddressCity' },
      { label: 'country', fieldName: 'senderAddressCountryName' },
      { label: 'countryCode', fieldName: 'senderAddressCountryCode' },
      { label: 'phone', fieldName: 'senderPhone' },
      { label: 'email', fieldName: 'senderEmail' },
    ],
    label: 'senderaddress',
    display: 'column',
  },
  {
    type: 'extended',
    fields: [
      { label: 'company', fieldName: 'recipientCompanyName' },
      { label: 'contact', fieldName: 'recipientContactName' },
      { label: 'addressline1', fieldName: 'recipientAddressLine1' },
      { label: 'addressline2', fieldName: 'recipientAddressLine2' },
      { label: 'zip', fieldName: 'recipientAddressPostal' },
      { label: 'city', fieldName: 'recipientAddressCity' },
      { label: 'country', fieldName: 'recipientAddressCountryName' },
      { label: 'countryCode', fieldName: 'recipientAddressCountryCode' },
      { label: 'phone', fieldName: 'recipientPhone' },
      { label: 'email', fieldName: 'recipientEmail' },
    ],
    label: 'recipientaddress',
    display: 'column',
  },
  { type: 'simple', fields: ['reference1'], label: 'reference1', display: 'row' },
  { type: 'simple', fields: ['reference2'], label: 'reference2', display: 'row' },
  { type: 'simple', fields: ['description'], label: 'description', display: 'row' },
  { type: 'simple', fields: ['service'], label: 'servicename', display: 'row' },
  { type: 'simple', fields: ['pieces'], label: 'pieces', display: 'row' },
  {
    type: 'simple',
    fields: ['actualWeight', 'weightUnit'],
    label: 'bookedweight',
    display: 'row',
    func: nbrStrDelimiter,
  },
  {
    type: 'simple',
    fields: ['chargeableWeight', 'weightUnit'],
    label: 'bookedchargeable',
    display: 'row',
    func: nbrStrDelimiter,
  },
  {
    type: 'packages',
    fields: ['packages', 'weightUnit', 'dimensionUnit'],
    label: 'bookedPackages',
    display: 'grid',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    '& > div': {
      marginBottom: '10px',
    },
  },
  shipmentDetailsContainer: {
    display: 'grid',
    fontSize: '1rem',
    gridTemplateColumns: '1fr 1fr',
    '& > div:nth-child(4n + 1), > div:nth-child(4n + 2)': {
      backgroundColor: grey[100],
    },
  },
  shipmentDetailsFields: {
    display: 'flex',
    padding: '4px 0',
  },
  shipmentDetailsFieldsPrimaryTitle: {
    fontWeight: 500,
  },
  shipmentDetailsFieldsSecondaryTitle: {
    fontStyle: 'italic',
    fontSize: '0.9rem',
  },
  shipmentDetailsLabel: {
    width: '195px',
    textAlign: 'right',
    marginRight: '5px',
  },
  keyedPackagesContainer: {
    display: 'grid',
    gridTemplateColumns: '195px 1fr',
  },
  keyedPackageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    '& > p': {
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
  },
  keyedPackagesDetails: {
    display: 'flex',
    fontSize: '0.9rem',
    '& > p': {
      margin: '0 5px',
      fontSize: '0.9rem',
    },
  },
  shipmentDetailsDocuments: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > h2': {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '1.2rem',
      color: 'white',
      borderTop: '1px solid #333333',
      borderBottom: '1px solid #333333',
      backgroundColor: '#55556b',
    },
    '& > p': {
      textAlign: 'center',
      fontStyle: 'italic',
      padding: '5px 0',
    },
  },
  trackingMapContainer: {
    height: 'fit-content',
    width: '100%',
    background: 'whitesmoke',
    boxShadow: '0px -1px 4px #969494bf',
  },
  paper: {
    width: '100%',
  },
  errorPaper: {
    width: '100%',
    maxWidth: '1000px',
    backgroundColor: '#9e464c',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    padding: '3px',
    fontWeight: 300,
    color: 'white',
    border: '2px solid white',
  },
  /* Media Queries */
  '@media (max-width:1000px)': {
    content: {
      width: '100%',
    },
    foundPackagesTable: {
      paddingBottom: '30px',
      fontSize: '0.9rem',
    },
  },
  '@media (max-width:930px)': {
    shipmentDetailsContainer: {
      gridTemplateColumns: '1fr',
      '& > div:nth-child(4n + 1), > div:nth-child(4n + 2)': {
        backgroundColor: 'transparent',
      },
      '& > div:nth-child(odd)': {
        backgroundColor: '#eee',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    shipmentDetailsDocuments: {
      '& > h2': {
        fontWeight: 300,
        fontSize: '1rem',
      },
      '& > p': {
        fontSize: '0.9rem',
      },
    },
    shipmentDetailsContainer: {
      fontSize: '0.9rem',
    },
    errorText: {
      fontSize: '1rem',
    },
  },
  '@media (max-width:400px)': {
    shipmentDetailsLabel: {
      width: '125px',
    },
  },
}));

const initialTrackingState = {
  loading: false,
  carrier: null,
  trackingNumber: null,
  error: null,
  result: null,
};
const initialShipmentState = {
  loading: false,
  error: null,
  shipment: null,
};

const TrackedShipments = (props) => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const { companyId } = useSelector((state) => state.user);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  moment.locale(lang);
  const [keyedValues, setKeyedValues] = useState(null);
  const [searchOption, setSearchOption] = useState('tracking-number');
  const [searchInput, setSearchInput] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [trackingState, setTrackingState] = useState(initialTrackingState);
  const [shipmentState, setShipmentState] = useState(initialShipmentState);
  const [documents, setDocuments] = useState({ loading: false, error: null, docs: null });
  const [showCarrierOptions, setShowCarrierOptions] = useState(false);
  // const [trackingHistoryIdx, setTrackingHistoryIdx] = useState(null);

  useEffect(() => {
    if (props.location.state) {
      const { shipment } = props.location.state;
      setSearchInput(shipment.trackingNumber);
      setShipmentState({ ...shipmentState, shipment, loading: false, error: null });

      fetchTrackingEventsByCarrier(shipment.trackingNumber, shipment.carrier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { shipment } = shipmentState;

    if (shipment) {
      shipment.documents && fetchDocuments(shipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentState.shipment]);

  const fetchDocuments = (shipment) => {
    const { id, customerId } = shipment;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`/api/documents/list/${customerId}/${id}`, config)
      .then((resp) => {
        setDocuments({ docs: resp.data.documents, loading: false, error: null });
      })
      .catch((error) => {
        setDocuments({ docs: null, loading: false, error });
      });
  };

  const fetchShipmentDetails = (trackingNumber) => {
    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    //Fetch shipment details
    return axios.get(`/api/shipments/${companyId}/${trackingNumber}`, config);
  };

  const fetchTrackingEventsByCarrier = (tracking, carrier) => {
    setTrackingState({
      ...trackingState,
      loading: true,
      error: null,
      result: null,
      trackingNumber: null,
    });
    const config = {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(
        '/api/tracking/events',
        { carrier, trackingNumber: tracking, trackBy: searchOption },
        config
      )
      .then((resp) => {
        const { trackingNumber } = resp.data.results;
        setTrackingState({
          ...trackingState,
          loading: false,
          trackingNumber,
          carrier,
          error: null,
          result: resp.data.results,
        });
      })
      .catch((error) => {
        setTrackingState({
          ...trackingState,
          carrier: null,
          loading: false,
          error,
          result: null,
          trackingNumber: null,
        });
      });
  };

  const handleOptionChange = (option) => {
    setSearchOption(option);
  };
  const handleSearchInputChange = (e) => {
    if (e.target.value.length > 25) return;
    setSearchInput(e.target.value);
  };

  const handleSubmit = (trackingNumber) => async (e) => {
    e.preventDefault();
    setTrackingState(initialTrackingState);

    //Request if shipment details exists, grab carrier from Shipment state
    setShipmentState({ ...shipmentState, loading: true, error: null, shipment: null });

    try {
      const req = await fetchShipmentDetails(trackingNumber);

      const { shipment } = req.data;

      setShipmentState({
        ...shipmentState,
        shipment: shipment || null,
        loading: false,
        error: null,
      });

      const carrier = shipment && shipment.carrier ? shipment.carrier : selectedCarrier;

      if (!carrier) throw new Error('Carrier undefined');

      fetchTrackingEventsByCarrier(trackingNumber, carrier);
    } catch (error) {
      setShipmentState({ ...shipmentState, loading: false, error, shipment: null });
      setShowCarrierOptions(true);
    }
  };

  const trackingNumberRedirect = async (trackingNumber) => {
    setTrackingState(initialTrackingState);

    //Request if shipment details exists, grab carrier from Shipment state
    setShipmentState({ loading: true, error: null, shipment: null });

    try {
      const req = await fetchShipmentDetails(trackingNumber);

      const { shipment } = req.data;

      setShipmentState({
        ...shipmentState,
        shipment: shipment || null,
        loading: false,
        error: null,
      });

      const carrier = shipment && shipment.carrier ? shipment.carrier : selectedCarrier;

      if (!carrier) throw new Error('Carrier undefined');

      fetchTrackingEventsByCarrier(trackingNumber, carrier);
    } catch (error) {
      setShipmentState({ ...shipmentState, loading: false, error, shipment: null });
    }
  };

  const buildSimpleDetails = (fieldObj) => {
    const label = translations[lang].trackedshipments.titles[fieldObj.label];
    const details = fieldObj.fields.map((field) => (
      <p key={field}>
        {'func' in fieldObj
          ? fieldObj.func(shipmentState.shipment[field])
          : shipmentState.shipment[field]}
      </p>
    ));

    return (
      <div className={classes.shipmentDetailsFields} key={fieldObj.label}>
        <p
          className={clsx([
            classes.shipmentDetailsLabel,
            classes.shipmentDetailsFieldsPrimaryTitle,
          ])}
        >
          {label}&#58;
        </p>
        <span style={{ display: 'flex', flexDirection: fieldObj.display }}>{details}</span>
      </div>
    );
  };

  const buildExtendedDetails = (fieldObj) => {
    const label = translations[lang].trackedshipments.titles[fieldObj.label];
    const details = fieldObj.fields.map((field) => (
      <div style={{ display: 'flex' }} key={field.fieldName}>
        <p
          className={clsx([
            classes.shipmentDetailsLabel,
            classes.shipmentDetailsFieldsSecondaryTitle,
          ])}
        >
          {translations[lang][field.label]}&#58;
        </p>
        <span style={{ display: 'flex', flexDirection: fieldObj.display }}>
          {shipmentState.shipment[field.fieldName]}
        </span>
      </div>
    ));

    return (
      <div
        style={{ flexDirection: 'column' }}
        className={classes.shipmentDetailsFields}
        key={fieldObj.label}
      >
        <p
          className={clsx([
            classes.shipmentDetailsLabel,
            classes.shipmentDetailsFieldsPrimaryTitle,
          ])}
        >
          {label}&#58;
        </p>
        <div style={{ flexDirection: 'column' }} className={classes.shipmentDetailsFields}>
          {details}
        </div>
      </div>
    );
  };

  const PackagesKeyedValues = ({ label }) => {
    const packages = shipmentState.shipment.packages;
    const weightUnit = shipmentState.shipment.weightUnit;
    const dimensionUnit = shipmentState.shipment.dimensionUnit;
    const totalPackageCount = Array.isArray(packages) ? packages.length : 0;
    let packageCounter = 0;

    return (
      <div key="packages" className={classes.keyedPackagesContainer}>
        <p className={classes.shipmentDetailsFieldsPrimaryTitle} style={{ textAlign: 'right' }}>
          {translations[lang].trackedshipments.titles[label]}&#58;
        </p>
        <div className={classes.keyedPackageWrapper}>
          {/* {totalPackageCount > 1 && <br />} */}
          {totalPackageCount > 0 &&
            packages.map((packageObj, packageRow) =>
              Array.from(Array(packageObj.count)).map((_, packageNbr) => {
                const packageKey = `${packageRow}-${packageNbr}`;
                packageCounter++;
                return (
                  <div key={packageKey} className={classes.keyedPackagesDetails}>
                    <p className={classes.keyedValuesLabel}>
                      {totalPackageCount > 1 ? `${packageCounter}: ` : ''}{' '}
                    </p>
                    <p className={classes.keyedValues}>
                      {packageObj.length} x {packageObj.width} x {packageObj.height}{' '}
                      {dimensionUnit ? dimensionUnit.toLowerCase() : 'cm'}, {packageObj.weight}{' '}
                      {weightUnit ? weightUnit.toLowerCase() : ''}
                    </p>
                  </div>
                );
              })
            )}
          {totalPackageCount === 0 && (
            <p style={{ fontWeight: 'normal', fontStyle: 'italic' }}>N/A</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TrackingInputForm
          translations={translations[lang]}
          handleOptionChange={handleOptionChange}
          handleSearchInputChange={handleSearchInputChange}
          searchOption={searchOption}
          searchInput={searchInput}
          handleSubmit={handleSubmit}
          showCarrierOptions={showCarrierOptions}
          setSelectedCarrier={(v) => setSelectedCarrier(v)}
        />
        {shipmentState.loading && <CircleSpinnerLarge />}
        {shipmentState.shipment && (
          <Paper className={classes.paper}>
            <AppBar elevation={0} position="static">
              <CustomToolbar title={translations[lang].shipmentDetails}></CustomToolbar>
            </AppBar>
            <section className={classes.shipmentDetailsContainer}>
              {shipmentDetailsFields.map((fieldObj) => {
                let detail = null;
                if (fieldObj.type === 'simple') detail = buildSimpleDetails(fieldObj);
                if (fieldObj.type === 'extended') detail = buildExtendedDetails(fieldObj);
                if (fieldObj.type === 'packages') detail = PackagesKeyedValues(fieldObj);

                return detail;
              })}
              {/* Display empty cell if array length is odd for coloring to stretch on last row */}
              {shipmentDetailsFields.length % 2 !== 0 && <div></div>}
            </section>
            <section className={classes.shipmentDetailsDocuments}>
              <h2>{translations[lang].documents}</h2>
              {documents.loading && <CircleSpinnerSmall />}
              {documents.docs && !documents.loading && (
                <ShippingDocsTable
                  documents={documents.docs}
                  translations={translations[lang].trackedshipments.docstable}
                />
              )}
              {!documents.loading && !documents.docs && (
                <p>{translations[lang].trackedshipments.nodocsfound}</p>
              )}
            </section>
          </Paper>
        )}
        {trackingState.loading && <CircleSpinnerLarge />}
        {trackingState.error && (
          <Paper className={classes.errorPaper}>
            <p className={classes.errorText}>
              {translations[lang].trackedshipments.noTrackingFound}
            </p>
          </Paper>
        )}
        {trackingState.carrier && (
          <TrackingViewProvider
            trackingNumberRedirect={trackingNumberRedirect}
            translations={translations[lang]}
            trackingState={trackingState}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(TrackedShipments);
