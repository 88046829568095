import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let BatchBookingExt = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
        <g>
          <g transform="matrix(.2879 0 0 .26416 11.965 6.478)">
            <path d="M20 0.66L16.83 3.7 16.83 18.55 20 15.51z"></path>
            <path d="M15.87 19L15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97 0 19z"></path>
            <path d="M19.31 0L13.36 0 10.21 3.02 16.16 3.02z"></path>
            <path d="M6.68 3.02L9.84 0 3.66 0 0.5 3.02z"></path>
          </g>
          <g transform="matrix(.2879 0 0 .26416 18.122 6.364)">
            <path d="M20 15.51L20 0.66 16.83 3.7 16.83 18.55z"></path>
            <path d="M0 19L15.87 19 15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97z"></path>
            <path d="M16.16 3.02L19.31 0 13.36 0 10.21 3.02z"></path>
            <path d="M0.5 3.02L6.68 3.02 9.84 0 3.66 0z"></path>
          </g>
          <g transform="matrix(.2879 0 0 .26416 11.842 11.838)">
            <path d="M16.83 3.7L16.83 18.55 20 15.51 20 0.66z"></path>
            <path d="M15.87 3.97L9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97 0 19 15.87 19z"></path>
            <path d="M13.36 0L10.21 3.02 16.16 3.02 19.31 0z"></path>
            <path d="M9.84 0L3.66 0 0.5 3.02 6.68 3.02z"></path>
          </g>
          <g transform="matrix(.2879 0 0 .26416 17.999 11.724)">
            <path d="M20 0.66L16.83 3.7 16.83 18.55 20 15.51z"></path>
            <path d="M15.87 19L15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97 0 19z"></path>
            <path d="M19.31 0L13.36 0 10.21 3.02 16.16 3.02z"></path>
            <path d="M6.68 3.02L9.84 0 3.66 0 0.5 3.02z"></path>
          </g>
          <g
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.001"
            transform="matrix(2.56908 0 0 2.56908 -4.972 11.738)"
          >
            <path d="M4.77 1.31H2.364v-2.797H4.77V1.31m-2.2-2.594v2.391h1.998v-2.391H2.568"></path>
            <path d="M4.323-.69h-1.51a.046.046 0 01-.047-.046c0-.026.02-.046.046-.046h1.51c.026 0 .047.02.047.046s-.02.047-.046.047"></path>
            <path d="M4.323-.042h-1.51a.046.046 0 01-.047-.047c0-.026.02-.046.046-.046h1.51c.026 0 .047.02.047.046s-.02.047-.046.047"></path>
            <path d="M4.323.605h-1.51a.046.046 0 01-.047-.047c0-.026.02-.046.046-.046h1.51c.026 0 .047.02.047.046 0 .027-.02.047-.046.047"></path>
            <path d="M2.173 1.47h1.999V-.922H2.173zm2.202.202H1.97v-2.797h2.404v2.797"></path>
            <path d="M3.928-.389h-1.51c-.02 0-.02.031 0 .031h1.51c.02 0 .02-.03 0-.03"></path>
            <path d="M3.928-.327h-1.51a.046.046 0 01-.047-.046c0-.027.02-.047.047-.047h1.51c.026 0 .046.02.046.047 0 .026-.02.046-.046.046"></path>
            <path d="M3.928.258h-1.51c-.02 0-.02.031 0 .031h1.51c.02 0 .02-.03 0-.03"></path>
            <path d="M3.928.32h-1.51A.046.046 0 012.37.274c0-.026.02-.047.047-.047h1.51c.026 0 .046.02.046.047 0 .026-.02.046-.046.046"></path>
            <path d="M3.928.905h-1.51c-.02 0-.02.031 0 .031h1.51c.02 0 .02-.03 0-.03"></path>
            <path d="M3.928.968h-1.51A.046.046 0 012.37.92c0-.026.02-.047.047-.047h1.51c.026 0 .046.02.046.047 0 .026-.02.047-.046.047"></path>
          </g>
          <path
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.003"
            d="M10.204 13.504l1.386-1.598-1.402-1.402a.332.332 0 00-.47.47l.706.706H8.08a.332.332 0 100 .665h2.25l-.627.723a.331.331 0 00.033.469c.139.12.349.105.469-.033"
          ></path>
        </g>
      </svg>
    </SvgIcon>
  );
};

BatchBookingExt = pure(BatchBookingExt);
BatchBookingExt.displayName = 'BatchBookingExt';
BatchBookingExt.muiName = 'SvgIcon';

export default BatchBookingExt;
