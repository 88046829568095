import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PackageTools = (props) => {

	return (
		<SvgIcon {...props}>
			<polygon points="12.86 0 9.82 2.9 15.55 2.9 18.59 0 12.86 0" />
			<polygon points="9.47 0 3.52 0 0.48 2.9 6.43 2.9 9.47 0" />
			<path d="M17,10.15h0a5.26,5.26,0,0,1,2.42,1.92V.8L16.37,3.72V9.94A5.31,5.31,0,0,1,17,10.15Z" transform="translate(-0.17 -0.16)" />
			<path d="M10.18,17.2a5.25,5.25,0,0,1-.07-4.06,5.32,5.32,0,0,1,5.34-3.35V4H9.52V8.45H6.13V4h-6V18.45H10.94A5,5,0,0,1,10.18,17.2Z" transform="translate(-0.17 -0.16)" />
			<path d="M18.68,14.94l1-.53-.2-.82-1.1,0a3.55,3.55,0,0,0-.74-1l.31-1.06L17.2,11l-.81.75a3.38,3.38,0,0,0-1.25-.2l-.53-1-.82.19,0,1.11a3.64,3.64,0,0,0-1,.74l-1.06-.31-.44.71.75.82a3.38,3.38,0,0,0-.2,1.25l-1,.53.19.82,1.11.05a3.38,3.38,0,0,0,.74,1l-.31,1.06.72.44.81-.75a3.37,3.37,0,0,0,1.24.21l.54,1,.81-.19.05-1.1a3.55,3.55,0,0,0,1-.74l1.05.3.45-.71-.75-.81A3.46,3.46,0,0,0,18.68,14.94ZM15.12,16a1,1,0,1,1,1.11-.9A1,1,0,0,1,15.12,16Z" transform="translate(-0.17 -0.16)" />
			<path d="M22.16,17.89l.32-.37-.2-.3-.46.14a1.44,1.44,0,0,0-.46-.31l0-.49L21,16.49l-.23.43a1.4,1.4,0,0,0-.54.1l-.36-.32-.31.2.14.46a1.62,1.62,0,0,0-.31.46l-.48,0-.08.36.43.22a1.42,1.42,0,0,0,.1.55l-.32.36.2.31.46-.14a1.62,1.62,0,0,0,.46.31l0,.48.36.08.23-.43a1.4,1.4,0,0,0,.54-.1l.36.32L22,20l-.14-.46a1.44,1.44,0,0,0,.31-.46l.48,0,.08-.36-.43-.23A1.4,1.4,0,0,0,22.16,17.89Zm-1.31,1a.44.44,0,0,1-.54-.32.46.46,0,0,1,.33-.54.44.44,0,1,1,.21.86Z" transform="translate(-0.17 -0.16)" />
		</SvgIcon>
	);
}

PackageTools = pure(PackageTools);
PackageTools.displayName = 'PackageTools';
PackageTools.muiName = 'SvgIcon';

export default PackageTools
