import React, { useEffect, useState } from 'react';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvoiceFormTemplate,
  deleteInvoiceFormTemplate,
  fetchInvoiceFormTemplates,
} from '../../../store/actions';
import EmptyTable from '../TemplateTables/EmptyTable/EmptyTable';
import EnhancedTable from '../TemplateTables/EnhancedTable/EnhancedTable';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  TableChart as TableChartIcon,
} from '@material-ui/icons';
import UpsInvoiceForm from '../../Bookings/InvoiceAndPickup/UpsInvoiceForm/UpsInvoiceForm';
import TableWrapper from '../TableWrapper/TableWrapper';
import { Snackbar } from '@material-ui/core';
import SnackbarContentWrapper from '../../UI/SnackbarContentWrapper/SnackbarContentWrapper';

//Ensures fetched data displays in correct order according to order of the headCells
const createRowsData = ({
  id,
  templateName,
  invoiceNumber,
  reasonForExport,
  termsOfShipment,
  products,
}) => {
  return {
    id,
    templateName,
    invoiceNumber,
    reasonForExport,
    termsOfShipment,
    products: products.length,
  };
};

export default function InvoiceFormTemplates({ translations }) {
  const [content, setContent] = useState('table');
  const [rows, setRows] = useState([]);
  const [autoIncrement, setAutoIncrement] = useState(false);
  const [snackbar, setSnackbar] = useState({ variant: '', messages: [], open: false });

  const { invoiceFormTemplates, loading } = useSelector((state) => state.invoiceFormTemplates);

  useEffect(() => {
    // Check if invoiceFormTemplates is null
    if (invoiceFormTemplates === null) {
      dispatch(fetchInvoiceFormTemplates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      invoiceFormTemplates &&
      invoiceFormTemplates.length > 0 &&
      invoiceFormTemplates.length !== rows.length
    )
      updateRows({ templateData: invoiceFormTemplates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceFormTemplates]);
  const dispatch = useDispatch();

  const changeToAdd = () => setContent('add');
  const changeToTable = () => setContent('table');

  const handleDelete = (id) => {
    dispatch(deleteInvoiceFormTemplate(id));
  };

  const headCells = [
    { id: 'templateName', numeric: false, disablePadding: true, label: 'Template Name' },
    { id: 'invoiceNumber', numeric: false, disablePadding: true, label: 'Invoice Number' },
    { id: 'reasonForExport', numeric: false, disablePadding: true, label: 'Reason For Export' },
    { id: 'termsOfShipment', numeric: false, disablePadding: true, label: 'Terms Of Shipment' },
    { id: 'products', numeric: false, disablePadding: true, label: 'Products count' },
  ];

  const updateRows = ({ templateData }) => {
    const updatedRows = templateData.map((template) => createRowsData(template));
    setRows(updatedRows);
  };

  const handleCancelInvoiceForm = () => {
    setContent('table');
  };

  const handleAddInvoiceForm = ({ invoiceForm, invoiceFormTemplateName }) => {
    const invoiceFormTemplate = { ...invoiceForm };
    // For Invoice Form templates invoice number must be an integer
    // Check if the string invoice number is an integer, then convert to integer
    if (Number.isInteger(Number(invoiceFormTemplate.invoiceNumber))) {
      invoiceFormTemplate.invoiceNumber = Number(invoiceFormTemplate.invoiceNumber);
    } else {
      const updatedSnackbar = {
        variant: 'error',
        messages: ['Invoice number must be an integer'],
        open: true,
      };
      setSnackbar({ ...snackbar, ...updatedSnackbar });
      return;
    }

    // Validate template name at least have 3 characters
    if (invoiceFormTemplateName.length < 3) {
      const updatedSnackbar = {
        variant: 'error',
        messages: ['Template name must be at least 3 characters'],
        open: true,
      };
      setSnackbar({ ...snackbar, ...updatedSnackbar });
      return;
    }

    // Add template name to invoice form template
    invoiceFormTemplate.templateName = invoiceFormTemplateName;

    dispatch(addInvoiceFormTemplate(invoiceFormTemplate));

    setContent('table');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const updatedSnackbar = { ...snackbar, open: false };
    setSnackbar({ ...snackbar, ...updatedSnackbar });
  };

  let renderContent;
  switch (content) {
    case 'table': {
      const toolbarProps = [
        {
          hasSelected: false,
          funcName: 'changeToAdd',
          icon: <AddIcon />,
          toolTip: 'Add new invoice form template',
        },
        {
          hasSelected: true,
          funcName: 'handleDelete',
          icon: <DeleteIcon />,
          toolTip: 'Delete selected invoice form template',
        },
      ];

      const actions = { changeToAdd, handleDelete };

      renderContent =
        rows.length === 0 ? (
          <EmptyTable
            translations={translations}
            changeToAdd={changeToAdd}
            type="product"
            title={'Saved invoice form templates'}
          />
        ) : (
          <EnhancedTable
            rows={rows}
            headCells={headCells}
            sortByKey={'description'}
            title={'Saved invoice form templates'}
            selectedTitle={'selected invoice form templates'}
            endAdornmentFields={null}
            toolbarProps={toolbarProps}
            {...actions}
          />
        );
      break;
    }
    case 'add': {
      const toolbarProps = [
        {
          hasSelected: false,
          funcName: 'changeToTable',
          icon: <TableChartIcon />,
          toolTip: 'Invoice template list',
        },
      ];
      const actions = { changeToTable };
      renderContent = (
        <TableWrapper toolbarProps={toolbarProps} toolbarTitle="Invoice template" {...actions}>
          <UpsInvoiceForm
            bookingCurrency={'SEK'}
            handleAddInvoiceForm={handleAddInvoiceForm}
            handleCancelInvoiceForm={handleCancelInvoiceForm}
            actionTitle={'Add template'}
            forceSaveTemplate
            showAutoIncrement={false}
          />
        </TableWrapper>
      );
      break;
    }
    default:
      renderContent = (
        <EmptyTable
          translations={translations}
          changeToAdd={() => setContent('add')}
          type="package"
          title={'Saved product templates'}
        />
      );
  }

  return (
    <div>
      {loading ? <CircleSpinnerLarge /> : renderContent}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
}
