import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  bubbles: {
    display: 'flex',
    width: ({ containerSize }) => containerSize,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bubble: {
    width: ({ bubbleSize }) => bubbleSize,
    height: ({ bubbleSize }) => bubbleSize,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  bubble1: {
    animation: '$pulse .4s ease .0s infinite alternate',
  },
  bubble2: {
    animation: '$pulse .4s ease .2s infinite alternate',
  },
  bubble3: {
    animation: '$pulse .4s ease .4s infinite alternate',
  },
  '@keyframes pulse': {
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0.25,
      transform: 'scale(.75)',
    },
  },
}));

export const HorizontalSpinner = ({ size = 'lg' }) => {
  let bubbleSize, containerSize;
  switch (size) {
    case 'md':
      bubbleSize = 10;
      containerSize = 60;

      break;
    case 'lg':
      bubbleSize = 20;
      containerSize = 120;

      break;
    default:
      bubbleSize = 20;
      containerSize = 120;
  }

  const styles = useStyles({ bubbleSize, containerSize });
  return (
    <div className={styles.bubbles}>
      <div className={clsx([styles.bubble, styles.bubble1])}></div>
      <div className={clsx([styles.bubble, styles.bubble2])}></div>
      <div className={clsx([styles.bubble, styles.bubble3])}></div>
    </div>
  );
};
