import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PackageMove = (props) => {
  return (
    <SvgIcon {...props}>
      <g id='_80' data-name='80'>
        <path
          d='M20.27.39H3.17A1.25,1.25,0,0,0,1.92,1.64v17.1A1.24,1.24,0,0,0,3.17,20h17.1a1.24,1.24,0,0,0,1.25-1.24V1.64A1.25,1.25,0,0,0,20.27.39ZM5.59,3.69a.58.58,0,0,1,.87,0l.63.63.56.55c.37-.38.7-.74,1.06-1.08a.58.58,0,0,1,.94,0,.57.57,0,0,1,0,.87L8.55,5.77l.19.2c.32.32.64.63,1,1a.6.6,0,0,1,.1.81.61.61,0,0,1-1,.07c-.38-.37-.75-.76-1.15-1.17L6.57,7.76a.52.52,0,0,1-.67.12.64.64,0,0,1-.19-1.1c.36-.32.7-.67,1.07-1L5.57,4.57A.59.59,0,0,1,5.59,3.69Zm4.36,11c-.45,0-.89,0-1.34,0H8.33c0,.07,0,.11,0,.14,0,.48,0,1,0,1.45a.6.6,0,0,1-.69.56A.56.56,0,0,1,7,16.32c0-.25,0-.5,0-.74v-.85H5.36a.63.63,0,0,1,0-1.25H7c0-.55,0-1.05,0-1.55a.57.57,0,0,1,.47-.61.65.65,0,0,1,.83.66v1.5H9.94a.6.6,0,0,1,.64.61A.59.59,0,0,1,10,14.72Zm4.28-11h3.59a.58.58,0,0,1,.63.63.56.56,0,0,1-.63.62H14.23a.56.56,0,0,1-.58-.62A.58.58,0,0,1,14.23,3.74ZM17.8,7.59H14.27a.54.54,0,0,1-.54-.31.64.64,0,0,1,.59-1c.79,0,1.58,0,2.37,0h1.16a.56.56,0,0,1,.59.54A.6.6,0,0,1,17.8,7.59Zm.31,7.14H14.23a.56.56,0,0,1-.57-.63.56.56,0,0,1,.58-.62h3.87a.56.56,0,0,1,.59.61A.57.57,0,0,1,18.11,14.73Z'
          transform='translate(-1.92 -0.39)'
        />
      </g>
    </SvgIcon>
  );
};

PackageMove = pure(PackageMove);
PackageMove.displayName = 'PackageMove';
PackageMove.muiName = 'SvgIcon';

export default PackageMove;
