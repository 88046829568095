import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShipmentDescription from '../../PackageAndService/ShipmentDetails/ShipmentDescription/ShipmentDescription';
import { Button } from '@material-ui/core';
import DescriptionTable from '../DescriptionTable/DescriptionTable';
import { useDispatch, useSelector } from 'react-redux';
import { updateBookingPresetDescription } from '../../../../store/actions/bookingPresets';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '15px',
    width: '90%',
  },
  button: {
    height: '30px',
    padding: '5px',
    fontSize: '0.9rem',
    minWidth: 'max-content',
  },
});

const AddDescription = ({ translations }) => {
  const [newDescription, setNewDescription] = useState('');
  const { descriptions, defaultDescription } = useSelector((state) => state.bookingPresets);
  const userId = useSelector((state) => state.user.userId);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleNewDescriptionChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleAddDescription = () => {
    if (descriptions.includes(newDescription) || newDescription.length === 0) return; //Guard
    const updatedDescriptions = [...descriptions];
    updatedDescriptions.push(newDescription);
    dispatch(
      updateBookingPresetDescription({
        token,
        userId,
        defaultDescription,
        descriptions: updatedDescriptions,
      })
    );
    setNewDescription('');
  };
  const handleRemoveDescription = ({ description }) => {
    let updatedDefaultDescription = defaultDescription;
    const descriptionId = descriptions.findIndex((item) => item === description);
    if (!descriptions.includes(description) || descriptionId < 0) return; //Guard
    const updatedDescriptions = [...descriptions];
    if (updatedDescriptions[descriptionId] === defaultDescription) updatedDefaultDescription = '';
    updatedDescriptions.splice(descriptionId, 1);
    dispatch(
      updateBookingPresetDescription({
        token,
        userId,
        defaultDescription: updatedDefaultDescription,
        descriptions: updatedDescriptions,
      })
    );
  };

  const handleChangeDefaultDescription = ({ target: { value } }) => {
    let updatedDefaultDescription = '';
    if (value !== defaultDescription) updatedDefaultDescription = value;

    dispatch(
      updateBookingPresetDescription({
        token,
        userId,
        defaultDescription: updatedDefaultDescription,
        descriptions,
      })
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.descriptionContainer}>
        <ShipmentDescription
          label={translations.onlinebooking.commoditydescription}
          value={newDescription}
          onChange={handleNewDescriptionChange}
          // onBlur={handleNewDescriptionBlur}
        />
        <Button
          onClick={handleAddDescription}
          color="primary"
          className={classes.button}
          variant="contained"
        >
          {translations.templates.add}
        </Button>
      </div>

      {descriptions.length > 0 && (
        <DescriptionTable
          descriptions={descriptions}
          defaultDescription={defaultDescription}
          handleRemoveDescription={handleRemoveDescription}
          handleChangeDefaultDescription={handleChangeDefaultDescription}
          translations={translations}
        />
      )}
    </div>
  );
};

export default AddDescription;
