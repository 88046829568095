import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './index.module.css';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '1.2rem',
    height: '1.2rem',
    margin: '0.5rem',
    borderRadius: '50%',
    border: `0.2rem solid ${theme.palette.primary.main}`,
    borderTopColor: theme.palette.secondary.main,
    animation: '1.5s spin infinite linear',
  },
  '@keyframes bounce': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const CircleSpinnerLarge = () => {
  return <div className={styles.loader}>Loading...</div>;
};

export const CircleSpinnerSmall = () => {
  const styles = useStyles();
  return <div className={styles.small}>{/* Loading... */}</div>;
};
