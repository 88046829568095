import React from 'react';
import Toolbar from '../../../UI/CustomToolbar/CustomToolbar';
import translations from '../../../../services/translations/translations';
import { useSelector } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { ClickAwayListener } from '@material-ui/core';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const EnhancedTableToolbar = ({ title, selectedTitle, selected, toolbarProps, ...actions }) => {
  const toolbarContet =
    selected.length > 0
      ? toolbarProps
          .filter(({ hasSelected }) => hasSelected)
          .map(option => (
            <div key={option['funcName']}>
              <Tooltip title={option.toolTip}>
                <IconButton onClick={() => actions[`${option['funcName']}`](selected[0])}>
                  {option.icon}
                </IconButton>
              </Tooltip>
            </div>
          ))
      : toolbarProps
          .filter(({ hasSelected }) => !hasSelected)
          .map(option => (
            <div key={option['funcName']}>
              <Tooltip title={option.toolTip}>
                <IconButton onClick={actions[`${option['funcName']}`]}>{option.icon}</IconButton>
              </Tooltip>
            </div>
          ));

  return <Toolbar title={selected.length > 0 ? selectedTitle : title}>{toolbarContet}</Toolbar>;
};

function EnhancedTableHead({ classes, order, orderBy, onRequestSort, headCells }) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  title,
  selectedTitle,
  rows,
  sortByKey,
  headCells,
  toolbarProps,
  endAdornmentFields,
  ...actions
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(sortByKey);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const lang = useSelector(state => state.rootTemplates.defaultLanguage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(name);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAway = () => {
    setSelected([]);
  };

  const isIdSelected = id => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title={title}
          selectedTitle={selectedTitle}
          toolbarProps={toolbarProps}
          selected={selected}
          {...actions}
        />
        <Table className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <ClickAwayListener onClickAway={handleClickAway}>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = isIdSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, n.id)}
                      role='checkbox'
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      {Object.keys(n)
                        .filter(field => field !== 'id')
                        .map(field => (
                          <TableCell key={field} padding='none' align='left'>
                            <p>
                              {n[field]}
                              {endAdornmentFields && field in endAdornmentFields && (
                                <span>&nbsp;{endAdornmentFields[field]}</span>
                              )}
                            </p>
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </ClickAwayListener>
        </Table>
        <TablePagination
          labelRowsPerPage={translations[lang].templates.rowsperpage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${translations[lang].shipmenthistory.textLabels.of} ${count}`
          }
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
