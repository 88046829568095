import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { CARRIER_NAMES } from '../../../services/types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchShipments, removeShipment, addMyShipmentsProps } from '../../../store/actions/index';
import translations from '../../../services/translations/translations';
import statusCodes from '../../../services/translations/translationsStatusCodes';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import moment from 'moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import CustomToolbar from './Customization/CustomToolbarSelect';
import { getCarrierIcon } from '../../../utils/carrier/carrierIcons';
// import { serializeHistoryShipment } from '../../../services/helpers/serializeShipment';
import { green, grey, red, yellow } from '@material-ui/core/colors';

const initialViewColumnsList = [
  'excluded',
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  true,
  true,
  true,
  'excluded',
];

const ShipmentHistory = (props) => {
  const [applicableCarriers, setApplicableCarriers] = useState(null);
  const [tableState, setTableState] = useState({});

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const shipmentsLoading = useSelector((state) => state.shipments.loading);
  const shipments = useSelector((state) => state.shipments.shipmentData);
  const myShipments = useSelector((state) => state.myShipments);
  const { token } = useSelector((state) => state.auth);
  const { companyCity, companyId } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const ts = useRef();
  ts.current = tableState;

  useEffect(() => {
    dispatch(fetchShipments(companyId, token));
    setTableState(myShipments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(addMyShipmentsProps(ts.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shipments) {
      const carrierNames = new Set();

      for (let shpmt of shipments) {
        if (shpmt.carrier && CARRIER_NAMES[shpmt.carrier]) {
          carrierNames.add(CARRIER_NAMES[shpmt.carrier]);
        }
      }

      setApplicableCarriers(Array.from(carrierNames));
    }
  }, [shipments]);

  const getColumns = (translations, statusCodes) => [
    {
      name: 'id',
      options: {
        display: initialViewColumnsList[0],
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Carrier',
      label: translations.columnLabels.carrier,
      options: {
        display: initialViewColumnsList[1],
        filter: true,
        filterList: getFilters(1),
        filterOptions: {
          names: applicableCarriers,
          logic: (key, value) => {
            return !value.includes(CARRIER_NAMES[key]);
          },
        },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const logo = getCarrierIcon(shipments[dataIndex].carrier);
          return (
            <img style={{ width: '50px', maxHeight: '20px' }} src={logo} alt="Carrier logotype" />
          );
        },
      },
    },
    {
      name: 'Created',
      label: translations.columnLabels.created,
      options: {
        display: isDisplayed(2),
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Tracking Number',
      label: translations.columnLabels.tracking,
      options: {
        display: isDisplayed(3),
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Flow',
      label: translations.columnLabels.flow,
      options: {
        display: isDisplayed(4),
        filter: true,
        filterList: getFilters(4),
        sort: true,
      },
    },
    {
      name: 'Company',
      label: translations.columnLabels.company,
      options: {
        display: isDisplayed(5),
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Country',
      label: translations.columnLabels.country,
      options: {
        display: isDisplayed(6),
        filter: true,
        filterList: getFilters(6),
        sort: true,
      },
    },
    {
      name: 'Reference 1',
      options: {
        display: isDisplayed(7),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Reference 2',
      options: {
        display: isDisplayed(8),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Status At',
      label: translations.columnLabels.statusAt,
      options: {
        display: isDisplayed(9),
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Status Description',
      label: translations.columnLabels.statusDesc,
      options: {
        display: isDisplayed(10),
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const { currentStatus, currentStatusDescriptionCode, currentStatusDescription } =
            shipments[dataIndex];
          let statusDesc = '';
          try {
            statusDesc =
              statusCodes.statusdescription[currentStatus][currentStatusDescriptionCode] ||
              currentStatusDescription ||
              '';
          } catch {
            statusDesc = '';
          }
          return statusDesc;
        },
      },
    },
    {
      name: 'Status',
      label: translations.columnLabels.status,
      options: {
        display: isDisplayed(11),
        filter: true,
        filterList: getFilters(11),
        sort: true,
        filterOptions: {
          names: Object.entries(statusCodes.status).map(([key, value]) =>
            key === 'A' ? `${value} Access Point` : value
          ),
          logic: (key, value) =>
            key === 'A'
              ? !value.includes(`${statusCodes.status[key]} Access Point`)
              : !value.includes(statusCodes.status[key]),
        },
        customBodyRender: (value, tableMeta, updatedValue) => {
          let fontColor;
          //set color props
          switch (value) {
            case 'A':
              fontColor = yellow[900];
              break;
            case 'X':
              fontColor = red[600];
              break;
            case 'D':
              fontColor = green[600];
              break;
            default:
              fontColor = grey[700];
          }

          const status = statusCodes.status[value] || value;

          return <div style={{ fontWeight: '500', color: fontColor }}>{status}</div>;
        },
      },
    },
    {
      name: 'Carrier Alias',
      options: {
        display: initialViewColumnsList[12],
        filter: false,
        sort: false,
      },
    },
  ];

  const getFilters = (columnIdx) => {
    return tableState.filterList ? tableState.filterList[columnIdx] : [];
  };

  const isDisplayed = (columnIdx) =>
    tableState.viewColumnsList
      ? tableState.viewColumnsList[columnIdx]
      : initialViewColumnsList[columnIdx];

  const columns = getColumns(translations[lang].shipmenthistory, statusCodes[lang]);

  const handleTrackShipment = (id) => {
    const selectedShipment = shipments.find((shpmts) => shpmts.id === id);
    props.history.push({
      pathname: '/tools/trackedshipments',
      state: {
        shipment: selectedShipment,
        title: translations[lang].shipmenthistory.customToolbar.detailedTracking,
      },
    });
  };

  const selectableRows = useCallback(
    (index) => {
      const { page, rowsPerPage } = tableState;
      const startIndex = page * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const isRowInCurrentView = index >= startIndex && index < endIndex;
      return isRowInCurrentView;
    },
    [tableState]
  );

  const getMuiTheme = () =>
    createMuiTheme({
      palette: {
        primary: {
          main: '#62485E',
        },
        secondary: {
          main: '#BBABBB',
          //TODO: change this to its own theme -> main:'#d1c3f0'
        },
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: '#FF000',
            width: '100%',
          },
          /*  paper:{
					 minWidth:'960px'
				 } */
        },
        MUIDataTableHeadCell: {
          root: {
            textAlign: 'left',
            padding: '10px 0px',
          },
        },
        MUIDataTableToolbar: {
          icon: {
            borderRadius: 0,
          },
        },
        MUIDataTableToolbarSelect: {
          root: {
            height: '48px',
            paddingRight: '8px',
          },
        },
        MuiIconButton: {
          root: {
            borderRadius: 0,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            '&:nth-child(odd)': {
              backgroundColor: '#F8F8F8',
            },

            '& + *:hover': {
              backgroundColor: '#bbabbb70 !important',
              cursor: 'pointer',
            },
            '&:hover': {
              backgroundColor: '#bbabbb70 !important',
              cursor: 'pointer',
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: '4px 2px',
            textAlign: 'left',
          },
          stackedCommon: {
            '@media (max-width:960px)': {
              fontSize: '0.9rem',
            },
          },
        },
      },
    });

  const handleShipmentDelete = (index) => {
    const deleteShipmentId = shipments[index].id;
    dispatch(removeShipment(deleteShipmentId, token));
  };

  const handleRedirectBookOnline = (index) => {
    return;
    // const redirectShipment = shipments[index].keyedBooking;
    // const bookingData = serializeHistoryShipment(redirectShipment);

    // dispatch(addBookingFromHistory(bookingData));
  };

  const options = {
    filterType: 'checkbox',
    responsive: 'vertical', //enum: 'simple','stacked','standard','vertical,'scrollMaxHeight','scrollFullHeight',
    page: tableState.page,
    draggableColumns: {
      enabled: true,
    },
    searchText: tableState.searchText,
    rowsPerPage: tableState.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    searchAlwaysOpen: true,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      columns.forEach((col, i) => {
        if (col.searchable) {
          if (
            currentRow[i] &&
            currentRow[i].toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
          ) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    onTableChange: (action, state) => {
      switch (action) {
        case 'changeRowsPerPage':
          setTableState({ ...tableState, rowsPerPage: state.rowsPerPage });
          break;
        case 'changePage':
          setTableState({ ...tableState, page: state.page });
          break;
        case 'search':
          setTableState({ ...tableState, searchText: state.searchText });
          break;
        case 'filterChange':
          setTableState({ ...tableState, filterList: state.filterList });
          break;
        case 'viewColumnsChange':
          const updatedViewColumns = state.columns.map((col) => col.display);
          setTableState({ ...tableState, viewColumnsList: updatedViewColumns });
          break;
        default:
          return;
      }
    },
    onChangeRowsPerPage: (e) => {
      //hack to avoid scrolling to bottom upon rows per page change
      //TODO: improve this implementation
      setTimeout(() => window.scrollTo(0, 0), 1);
    },
    downloadOptions: {
      filename: 'pagoda-shipments.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const serializedData = data.map((row, idx1) => {
        return row.data.reduce(
          (acc, curr, idx2) => {
            if (columns[idx2].name === 'Carrier') {
              acc.data.push(CARRIER_NAMES[curr]);
            } else if (columns[idx2].name === 'Status') {
              acc.data.push(statusCodes[lang].status[curr]);
            } else {
              acc.data.push(curr);
            }
            return acc;
          },
          { index: idx1, data: [] }
        );
      });
      return buildHead(columns) + buildBody(serializedData);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        handleTrackShipment={handleTrackShipment}
        translations={translations[lang]}
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        handleDelete={handleShipmentDelete}
        redirectBookOnline={handleRedirectBookOnline}
      />
    ),
    onRowClick: (e) => handleTrackShipment(e[0]),
    isRowSelectable: (dataIndex) => {
      if (selectableRows(dataIndex)) return true;
      return false;
    },
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: translations[lang].shipmenthistory.textLabels.noMatch,
        toolTip: translations[lang].shipmenthistory.textLabels.sort,
      },
      pagination: {
        next: translations[lang].shipmenthistory.textLabels.nextPage,
        previous: translations[lang].shipmenthistory.textLabels.previousPage,
        rowsPerPage: `${translations[lang].shipmenthistory.textLabels.shipmentsPerPage}:`,
        displayRows: translations[lang].shipmenthistory.textLabels.of,
        jumpToPage: translations[lang].shipmenthistory.textLabels.jumpToPage,
      },
      toolbar: {
        search: translations[lang].shipmenthistory.textLabels.search,
        downloadCsv: translations[lang].shipmenthistory.textLabels.download,
        print: translations[lang].shipmenthistory.textLabels.print,
        viewColumns: translations[lang].shipmenthistory.textLabels.viewColumns,
        filterTable: translations[lang].shipmenthistory.textLabels.filterTable,
      },
      filter: {
        all: translations[lang].shipmenthistory.textLabels.all,
        title: translations[lang].shipmenthistory.textLabels.filters,
        reset: translations[lang].shipmenthistory.textLabels.reset,
      },
      viewColumns: {
        title: translations[lang].shipmenthistory.textLabels.showColumns,
        titleAria: translations[lang].shipmenthistory.textLabels.showHideTableColumns,
      },
      selectedRows: {
        text: translations[lang].shipmenthistory.textLabels.selectedShipments,
        delete: translations[lang].shipmenthistory.textLabels.delete,
        deleteAria: translations[lang].shipmenthistory.textLabels.deleteSelected,
      },
    },
  };

  let tableData = <CircleSpinnerLarge />;

  if (!shipmentsLoading && shipments !== null && applicableCarriers !== null) {
    const calculateDisplayCountryAndName = (value) => {
      switch (value.flow) {
        case 'import':
          return {
            country: value.senderAddressCountryCode,
            name: value.senderCompanyName,
          };
        case 'domestic':
          /*	If the domestic shipment destination postal code
							is the same as the user company postal code then display1
							the sender company, else display the recipient company
							hence, always display the company not associated with
					*/
          let countryNameObj = {};
          const inbound = companyCity.toLowerCase() === value.recipientAddressCity.toLowerCase();

          if (inbound) {
            countryNameObj = {
              country: value.senderAddressCountryCode,
              name: value.senderCompanyName,
            };
          } else {
            countryNameObj = {
              country: value.recipientAddressCountryCode,
              name: value.recipientCompanyName,
            };
          }

          return countryNameObj;
        default:
          return {
            country: value.recipientAddressCountryCode,
            name: value.recipientCompanyName,
          };
      }
    };

    const data = shipments
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        } else {
          return 1;
        }
      })
      .map((value) => {
        const id = value.id;
        const reference1 = value.reference1;
        const reference2 = value.reference2;
        const carrier = value.carrier;
        const createdAt = moment(value.createdAt).format('YYYY-MM-DD');
        const pickedUp = value.pickedUp ? moment(value.pickedUp).format('YYYY-MM-DD') : 'N/A';
        const trackingNumber = value.trackingNumber;
        const flow = value.flow;
        const { name, country } = calculateDisplayCountryAndName(value);
        const status = value.currentStatus;
        const statusAt = value.activityAt ? moment(value.activityAt).format('L LT') : 'N/A';

        return {
          id: id,
          'Reference 1': reference1,
          'Reference 2': reference2,
          Carrier: carrier,
          Created: createdAt,
          'Picked Up': pickedUp,
          'Tracking Number': trackingNumber,
          Flow: flow,
          Company: name,
          Country: country,
          'Status Description': id,
          Status: status,
          'Status At': statusAt,
          'Carrier Alias': carrier,
        };
      });

    tableData = (
      <MUIDataTable
        title={translations[lang].shipmenthistory.title}
        data={data}
        columns={columns}
        options={options}
      />
    );
  }

  return <MuiThemeProvider theme={getMuiTheme()}>{tableData}</MuiThemeProvider>;
};

export default withRouter(ShipmentHistory);
