import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import translationsObj from '../../../services/translations/translations.json';
import { SelectStylesMain } from '../../UI/SelectStyles/SelectStyles';
import { buildCarrierSelectOptions } from '../../../services/helpers/carriers';
// import CustomsValue from '../../Bookings/PackageAndService/ShipmentDetails/CustomsValue/CustomsValue';
import { indigo, grey } from '@material-ui/core/colors';
import AdditionalRule from './AdditionalRule/AdditionalRule';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    gap: '10px',
    border: `1px dashed ${indigo[700]}`,
    '& > h2': {
      color: '#424242',
      fontWeight: '300',
      fontSize: '1.2rem',
    },
  },
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    width: '100%',
    placeItems: 'center',
  },
  baseOption: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.1rem',
    alignItems: 'center',
    width: '100%',
    '& > p': {
      fontWeight: '300',
      fontSize: '1rem',
    },
    '& > div': {
      width: '100%',
    },
  },
  additionalRulesContainer: {
    gridColumn: '1 / -1',
    width: '100%',
  },
  additionalRulesTitle: {
    fontWeight: '400',
    fontSize: '1rem',
    padding: '5px 0',
  },
  rulesActionButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gridColumn: '1 / -1',
    width: '80%',
  },
  addRuleButton: {
    width: '300px',
    fontSize: '0.9rem',
    color: indigo[900],
    padding: '4px 12px',
    // color: 'white',
    borderRadius: '15px',
    borderColor: indigo[900],
    '&:hover': {
      background: grey[50],
    },
  },
  saveRulesButton: {
    width: '100%',
    fontSize: '0.9rem',
    backgroundColor: indigo[700],
    padding: '4px 12px',
    color: 'white',
    borderRadius: '15px',
    '&:hover': {
      background: indigo[800],
    },
  },
});

const deliveryMethodLabelMapper = {
  'door-door': 'door',
  'door-dropoff': 'dropOffLocation',
};

export default function FileUploadRules({
  handleSaveRules,
  baseRules,
  setBaseRules,
  countryRules,
  carriers,
  handleRemoveCountryRule,
}) {
  const [carrierOptions, setCarrierOptions] = useState([]);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translations = translationsObj[lang];

  // Build the carrier options for the select on the first render
  useEffect(() => {
    const carrierOptions = buildCarrierSelectOptions(carriers);
    setCarrierOptions(carrierOptions);
  }, [carriers]);

  const globalDeliveryOptions = useMemo(() => {
    const deliveryMethods = Object.keys(deliveryMethodLabelMapper);

    const options = deliveryMethods.map((deliveryMethod) => {
      return {
        value: deliveryMethod,
        label: translations[deliveryMethodLabelMapper[deliveryMethod]],
      };
    });
    return options;
  }, [translations]);

  //Build options for service level
  const serviceLevelOptions = useMemo(() => {
    const serviceLevelsLabelMapper = {
      standard: 'Standard',
      express: 'Express',
    };
    const serviceLevels = Object.keys(serviceLevelsLabelMapper);

    const options = serviceLevels.map((serviceLevel) => {
      return {
        value: serviceLevel,
        label: serviceLevelsLabelMapper[serviceLevel],
      };
    });

    return options;
  }, []);

  //Build options for import charges
  const importChargesToOptions = useMemo(() => {
    const importChargesOptionsMapper = {
      S: translations.onlinebooking.timeline.sender,
      R: translations.onlinebooking.timeline.recipient,
    };
    const importChargesTo = Object.keys(importChargesOptionsMapper);

    const options = importChargesTo.map((chargeTo) => {
      return {
        value: chargeTo,
        label: importChargesOptionsMapper[chargeTo],
      };
    });

    return options;
  }, []);

  const handlePreferredCarrierChange = (selectedOption) => {
    setBaseRules({ ...baseRules, preferredCarrierAlias: selectedOption.value });
  };
  const handleGlobalDeliveryMethodChange = (selectedOption) => {
    setBaseRules({ ...baseRules, globalDeliveryMethod: selectedOption.value });
  };
  const handlePreferredServiceLevelChange = (selectedOption) => {
    setBaseRules({ ...baseRules, preferredServiceLevel: selectedOption.value });
  };

  const handleImportChargesToChange = (selectedOption) => {
    setBaseRules({ ...baseRules, billingDuties: selectedOption.value });
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2>{translations.fileUploadRules}</h2>
      {carrierOptions.length > 0 && baseRules && (
        <div className={classes.optionsContainer}>
          <div className={classes.baseOption}>
            <p>{translations.preferredCarrier}</p>
            <Select
              value={carrierOptions.find(
                (option) => option.value === baseRules.preferredCarrierAlias
              )}
              onChange={handlePreferredCarrierChange}
              options={carrierOptions}
              styles={SelectStylesMain}
              menuPlacement="auto"
            />
          </div>
          <div className={classes.baseOption}>
            <p>{translations.deliveryMethod}</p>
            <Select
              value={globalDeliveryOptions.find(
                (option) => option.value === baseRules.globalDeliveryMethod
              )}
              onChange={handleGlobalDeliveryMethodChange}
              options={globalDeliveryOptions}
              styles={SelectStylesMain}
              menuPlacement="auto"
            />
          </div>
          <div className={classes.baseOption}>
            <p>{translations.serviceLevel}</p>
            <Select
              value={serviceLevelOptions.find(
                (option) => option.value === baseRules.preferredServiceLevel
              )}
              onChange={handlePreferredServiceLevelChange}
              options={serviceLevelOptions}
              styles={SelectStylesMain}
              menuPlacement="auto"
            />
          </div>
          <div className={classes.baseOption}>
            <p>{translations.onlinebooking.billdutiesto}</p>
            <Select
              value={importChargesToOptions.find(
                (option) => option.value === baseRules.billingDuties
              )}
              onChange={handleImportChargesToChange}
              options={importChargesToOptions}
              styles={SelectStylesMain}
              menuPlacement="auto"
            />
          </div>

          {/* Development Option */}
          {/* <div className={classes.baseOption}>
            <p>{translations.customsValueEU}</p>
            <div style={{ maxWidth: '150px' }}>
              <CustomsValue
                required={false}
                translations={translations.onlinebooking}
                monetaryValue={100}
                currency={'SEK'}
                handleCurrencyChange={() => {}}
                handleMonetaryValueChange={() => {}}
                handleMonetaryValueBlur={() => {}}
                monetaryError={false}
              />
            </div>
          </div> */}
          {countryRules.length > 0 && (
            <div className={classes.additionalRulesContainer}>
              <h3 className={classes.additionalRulesTitle}>{translations.additionalRules}</h3>
              {countryRules.map((countryRule, idx) => {
                return (
                  <AdditionalRule
                    key={countryRule.id}
                    rule={countryRule}
                    generateLabels={idx === 0}
                    handleRemoveRule={handleRemoveCountryRule}
                  />
                );
              })}
            </div>
          )}
          <div className={classes.rulesActionButtonContainer}>
            <Button disabled variant="outlined" className={classes.addRuleButton}>
              {`${translations.addRule} +`}
            </Button>
          </div>
          <div className={classes.rulesActionButtonContainer}>
            <Button
              onClick={handleSaveRules}
              variant="contained"
              className={classes.saveRulesButton}
            >
              {translations.saveRules}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
