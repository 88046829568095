import { green, grey, orange, red } from '@material-ui/core/colors';
import { Description as UploadedInvoiceIcon } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PrintStatusIcon from '@material-ui/icons/FiberManualRecord';
import RequiredIcon from '@material-ui/icons/Warning';
import NotRequiredIcon from '@material-ui/icons/Remove';
import React from 'react';
import { Tooltip } from '@material-ui/core';

const ChipContentRounded = ({ children, color }) => {
  return (
    <div
      style={{
        marginRight: '2px',
        color,
        border: `1px solid ${color}`,
        borderRadius: '15px',
        textAlign: 'center',
        fontSize: '0.8rem',
        boxSizing: 'border-box',
        width: '80px',
        cursor: 'pointer',
      }}
    >
      {children}
    </div>
  );
};

export const CreateBookedStatusIcon = (bookedStatus, error = '', translations) => {
  const bookedStatusPropMapper = {
    pending: { label: 'Pending', style: { color: orange[800] }, tooltip: 'Booking is pending' },
    success: {
      label: 'Success',
      style: { color: green[800] },
      tooltip: 'Booking processed successfully',
    },
    error: { label: 'Error', style: { color: red[800] }, tooltip: error || 'Error when processed' },
  };

  const { label, style, tooltip } = bookedStatusPropMapper[bookedStatus];

  return (
    <Tooltip title={tooltip}>
      <div>
        <ChipContentRounded color={style.color}>
          <p>{label}</p>
        </ChipContentRounded>
      </div>
    </Tooltip>
  );
};
export const CreateInvoiceStatusIcon = (invoiceStatus, lang) => {
  const invoiceStatusIconMapper = {
    form: {
      icon: <CheckCircleIcon style={{ color: green[800], cursor: 'pointer' }} />,
      tooltip: 'Paperless invoice',
    },
    file: {
      icon: <UploadedInvoiceIcon style={{ color: green[800], cursor: 'pointer' }} />,
      tooltip: 'Invoice uploaded',
    },
    required: {
      icon: <RequiredIcon style={{ color: red[800], cursor: 'pointer' }} />,
      tooltip: 'Invoice required',
    },
    'not-required': {
      icon: <NotRequiredIcon style={{ color: grey[300], cursor: 'pointer' }} />,
      tooltip: 'Not required',
    },
  };

  return (
    <Tooltip title={invoiceStatusIconMapper[invoiceStatus].tooltip}>
      {invoiceStatusIconMapper[invoiceStatus].icon}
    </Tooltip>
  );
};
export const CreatePrintedStatusIcon = (isPrinted, printFormat, translations) => {
  // Get three first letters of print format
  const shortFormat =
    typeof printFormat === 'string' && printFormat.length >= 3
      ? printFormat.slice(0, 3).toLowerCase()
      : '?';

  return (
    <Tooltip title={isPrinted ? 'Booking is printed' : 'Not printed'}>
      <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
        <PrintStatusIcon style={{ color: isPrinted ? green[800] : grey[300] }} />
        <p
          style={{
            color: grey[600],
            borderRadius: '5px',
            fontSize: '0.65rem',
            padding: '0px 3px',
            letterSpacing: '0.04rem',
          }}
        >
          {shortFormat}
        </p>
      </div>
    </Tooltip>
  );
};
