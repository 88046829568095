import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  thermalPrinter: null,
  defaultLanguage: 'se',
  defaultCurrency: 'EUR',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROOT_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: null,
        defaultCurrency: action.payload.defaultCurrency,
        defaultLanguage: action.payload.defaultLanguage,
        thermalPrinter: action.payload.thermalPrinter,
      };

    //LANGUAGE
    case actionTypes.UPDATE_DEFAULT_LANGUAGE_START:
      return {
        ...state,
        defaultLanguageError: null,
        defaultLanguageLoading: true,
      };
    case actionTypes.UPDATE_DEFAULT_LANGUAGE_FAILED:
      return {
        ...state,
        defaultLanguageLoading: false,
        defaultLanguageError: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_LANGUAGE_SUCCESS:
      return {
        ...state,
        defaultLanguageLoading: false,
        defaultLanguageError: null,
        defaultLanguage: action.language,
      };
    //CURRENCY
    case actionTypes.UPDATE_DEFAULT_CURRENCY_START:
      return {
        ...state,
        defaultCurrencyError: null,
        defaultCurrencyLoading: true,
      };
    case actionTypes.UPDATE_DEFAULT_CURRENCY_FAILED:
      return {
        ...state,
        defaultCurrencyLoading: false,
        defaultCurrencyError: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_CURRENCY_SUCCESS:
      return {
        ...state,
        defaultCurrencyLoading: false,
        defaultCurrencyError: null,
        defaultCurrency: action.currency,
      };
    default:
      return state;
  }
};

export default reducer;
