import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import InvoiceSettings from '../../InvoiceAndPickup/UpsInvoiceForm/InvoiceSettings/InvoiceSettings';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { grey } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAutoIncrementInvoiceNumber,
  updateInvoiceFormPreset,
} from '../../../../store/actions';
import Voec from '../../../Voec/Voec';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    padding: '10px 20px 20px 20px',
    boxSizing: 'border-box',
    placeItems: 'center',
    gap: '20px',
  },
  wrapper: {
    width: '100%',
    padding: '0px 5px 5px',
    boxSizing: 'border-box',
    border: `1px solid ${grey[300]}`,
    boxShadow: `1px 1px 3px ${grey[100]}`,
  },
  tableSettings: {
    paddingBottom: '20px',
    marginTop: '15px',
  },
  sectionTitle: {
    textAlign: 'start',
    fontWeight: '400',
    fontSize: '1.1rem',
    color: grey[900],
    borderBottom: `1px solid ${grey[300]}`,
    boxSizing: 'border-box',
    padding: '3px 2px',
    width: '100%',
  },
  columnSettings: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    justifyItems: 'start',
    marginBottom: '5px',
  },
  columnSettingsItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    fontSize: '0.5rem',
  },
  table: {
    width: '100%',
    backgroundColor: grey[50],
    borderCollapse: 'collapse',
    '& th': {
      borderBottom: `2px solid ${grey[600]}`,
    },
    '& th,td': {
      fontWeight: 400,
      fontSize: '1rem',
      padding: '0 5px',
      textAlign: 'center',
    },
    '& th:not(:first-child)': {
      borderLeft: `1px solid ${grey[400]}`,
    },
    '& td:not(:first-child)': {
      borderLeft: `1px solid ${grey[400]}`,
    },
    '& th:not(:last-child)': {
      borderRight: `1px solid ${grey[400]}`,
    },
    '& td:not(:last-child)': {
      borderRight: `1px solid ${grey[400]}`,
    },
    '& td': {
      fontWeight: 300,
      fontStyle: 'italic',
      padding: '5px',
    },
  },
  buttonContainer: {
    display: 'grid',
    rowGap: '10px',
    width: '100%',
    maxWidth: '400px',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  [theme.breakpoints.down('sm')]: {
    table: {
      '& th,td': {
        fontWeight: 400,
        fontSize: '0.8rem',
        padding: '0 5px',
        textAlign: 'center',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      '& th,td': {
        fontWeight: 400,
        fontSize: '0.6rem',
        padding: '0 5px',
        textAlign: 'center',
      },
    },
    sectionTitle: {
      fontSize: '1rem',
    },
    columnSettings: {
      fontSize: '0.9rem',
    },
  },
}));

const initialInvoiceDetails = { invoiceNumber: '', reasonForExport: '', termsOfShipment: '' };

const InvoiceFormModal = ({ translations, bookingPresets, close }) => {
  const [invoiceDetailsState, setInvoiceDetailsState] = useState(initialInvoiceDetails);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [autoIncrement, setAutoIncrement] = useState(false);
  const [voecNumber, setVoecNumber] = useState('');

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    setAutoIncrement(bookingPresets.autoIncrementInvoiceNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingPresets.autoIncrementInvoiceNumber]);
  useEffect(() => {
    setVoecNumber(bookingPresets.voecNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingPresets.voecNumber]);

  useEffect(() => {
    setInvoiceDetailsState((state) => ({ ...state, ...bookingPresets.invoiceFormPreset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingPresets.productsFilteredColumns]);
  useEffect(() => {
    setFilteredColumns((state) => [...state, ...bookingPresets.productsFilteredColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingPresets.productsFilteredColumns]);

  const columnListItems = [
    { title: '', id: 'addProduct', filterOption: false, textAlign: 'center' },
    { title: '', id: 'removeProduct', filterOption: false, textAlign: 'center' },
    { title: translations.areAdded, id: 'added', filterOption: false, textAlign: 'center' },
    {
      title: translations.templates.template,
      id: 'templateId',
      filterOption: true,
      textAlign: 'start',
    },
    {
      title: translations.invoiceForm.description,
      id: 'description',
      filterOption: true,
      textAlign: 'start',
    },
    {
      title: translations.invoiceForm.unitValue,
      id: 'customsValue',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.amount,
      id: 'unitNumber',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.totalAmount,
      id: 'templateTotalUnitAmount',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.unit,
      id: 'unitMeasureCode',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.invoiceForm.commodityCode,
      id: 'commodityCode',
      filterOption: true,
      textAlign: 'center',
    },
    {
      title: translations.country,
      id: 'originCountryCode',
      filterOption: true,
      textAlign: 'center',
    },
  ];

  const dummyProducts = [
    {
      id: 'dummy-id-1',
      templateId: 'shoes1',
      description: 'Shoes size 44',
      customsValue: '100 USD',
      unitNumber: 2,
      templateTotalUnitAmount: 2,
      unitMeasureCode: 'Pairs',
      commodityCode: '64042090',
      originCountryCode: 'US',
    },
  ];

  const handleFilterColumnChange = ({ target: { id } }) => {
    const updatedFilteredColumn = [...filteredColumns];
    const filteredIdIndex = updatedFilteredColumn.indexOf(id);
    if (filteredIdIndex < 0) {
      //At least one regular column must be visible
      if (columnListItems.length - 3 - filteredColumns.length <= 1) return;
      updatedFilteredColumn.push(id);
    }
    if (filteredIdIndex >= 0) updatedFilteredColumn.splice(filteredIdIndex, 1);
    setFilteredColumns(updatedFilteredColumn);
  };
  const handleInvoiceDetailsChange = (e) => {
    const { value, name } = e.target;
    setInvoiceDetailsState({ ...invoiceDetailsState, [name]: value });
  };

  const handleVoecChange = (e) => {
    setVoecNumber(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      updateInvoiceFormPreset({
        invoiceFormPreset: invoiceDetailsState,
        productsFilteredColums: filteredColumns,
        voec: voecNumber,
        userId,
        token,
      })
    );
    if (bookingPresets.autoIncrementInvoiceNumber !== autoIncrement) {
      dispatch(updateAutoIncrementInvoiceNumber({ autoIncrement, userId, token }));
    }
    close();
  };

  const classes = useStyles();
  const smallCheckboxes = useMediaQuery('(max-width:600px)');

  return (
    <div className={classes.root}>
      <InvoiceSettings
        translations={translations}
        invoiceDetailsState={invoiceDetailsState}
        handleInvoiceDetailsChange={handleInvoiceDetailsChange}
        showAutoIncrement={true}
        autoIncrement={autoIncrement}
        setAutoIncrement={setAutoIncrement}
        smallCheckboxes={smallCheckboxes}
      />
      <div className={clsx([classes.wrapper, classes.tableSettings])}>
        <p className={classes.sectionTitle}>{translations.productTableSettings}</p>
        <div className={classes.columnSettings}>
          {columnListItems
            .filter(({ filterOption }) => filterOption)
            .map((item) => (
              <div key={item.id} className={classes.columnSettingsItem}>
                {smallCheckboxes ? (
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    id={item.id}
                    checked={!filteredColumns.includes(item.id)}
                    onChange={handleFilterColumnChange}
                    className={classes.checkbox}
                  />
                ) : (
                  <Checkbox
                    id={item.id}
                    checked={!filteredColumns.includes(item.id)}
                    onChange={handleFilterColumnChange}
                    className={classes.checkbox}
                  />
                )}
                <p>{item.title}</p>
              </div>
            ))}
        </div>
        <div>
          <table className={classes.table}>
            <thead>
              <tr>
                {columnListItems
                  .filter(({ filterOption }) => filterOption)
                  .filter(({ id }) => !filteredColumns.includes(id))
                  .map((row) => (
                    <th style={{ textAlign: row.textAlign }} key={row.id}>
                      {row.title}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {dummyProducts
                .filter(({ id }) => !filteredColumns.includes(id))
                .map((row) => (
                  <tr key={row.id}>
                    <td
                      style={{
                        textAlign: 'start',
                        display: filteredColumns.includes('templateId') ? 'none' : 'table-cell',
                      }}
                    >
                      {row.templateId}
                    </td>
                    <td
                      style={{
                        textAlign: 'start',
                        display: filteredColumns.includes('description') ? 'none' : 'table-cell',
                      }}
                    >
                      {row.description}
                    </td>
                    <td
                      style={{
                        display: filteredColumns.includes('customsValue') ? 'none' : 'table-cell',
                      }}
                    >
                      {row.customsValue}
                    </td>
                    <td
                      style={{
                        display: filteredColumns.includes('unitNumber') ? 'none' : 'table-cell',
                      }}
                    >
                      {row.unitNumber}
                    </td>
                    <td
                      style={{
                        width: '20px',
                        display: filteredColumns.includes('templateTotalUnitAmount')
                          ? 'none'
                          : 'table-cell',
                      }}
                    >
                      {row.templateTotalUnitAmount}
                    </td>
                    <td
                      style={{
                        display: filteredColumns.includes('unitMeasureCode')
                          ? 'none'
                          : 'table-cell',
                      }}
                    >
                      {row.unitMeasureCode}
                    </td>
                    <td
                      style={{
                        display: filteredColumns.includes('commodityCode') ? 'none' : 'table-cell',
                      }}
                    >
                      {row.commodityCode}
                    </td>
                    <td
                      style={{
                        display: filteredColumns.includes('originCountryCode')
                          ? 'none'
                          : 'table-cell',
                      }}
                    >
                      {row.originCountryCode}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.wrapper}>
        <p className={classes.sectionTitle}>{translations.voec}</p>
        <Voec voecId={voecNumber} handleChange={handleVoecChange} />
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          {translations.submitChanges}
        </Button>
        <Button className={classes.cancelButton} onClick={close}>
          {translations.templates.cancel}
        </Button>
      </div>
    </div>
  );
};

export default InvoiceFormModal;
