import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/scatter';
import { getTheme } from '../../../services/statistics/chartThemes';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import translations from '../../../services/translations/translations';

const DailyBookingTimeDistribution = ({ data: shipmentData, theme, lang }) => {
  moment.locale(lang === 'se' ? 'sv' : lang);

  echarts.registerTheme(theme, getTheme[theme]);

  function buildData() {
    let shipmentDayMonth = [];
    let calculatedData = [];

    for (let shpmt of shipmentData) {
      //exclude shipments which has no hour set
      if (
        moment.utc(shpmt.createdAt).format('HHmm') !== '0000' &&
        moment(shpmt.createdAt).format('HHmm') !== '0000'
      ) {
        const hour = moment(shpmt.createdAt).hour();
        const dayNbr = moment(shpmt.createdAt).day();
        shipmentDayMonth.push([hour, dayNbr]);
      }
    }

    const groupedWithDay = shipmentDayMonth.reduce((acc, curr) => {
      let existIdx;
      acc.forEach((val, idx) => {
        if (curr[0] === val[0] && curr[1] === val[1]) {
          existIdx = idx;
          return;
        }
      });
      if (existIdx) {
        acc.splice(existIdx, 1, [curr[0], curr[1], ++acc[existIdx][2]]);
      } else {
        acc.push([curr[0], curr[1], 1]);
      }
      return acc;
    }, []);

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 7; j++) {
        let exists = false;
        groupedWithDay.forEach((v) => {
          if (v[0] === i && v[1] === j) {
            calculatedData.push([i, j, v[2]]);
            exists = true;
          }
        });
        if (!exists) calculatedData.push([i, j, 0]);
      }
    }

    return calculatedData;
  }
  const twelveHourFormatLocals = ['en'];
  const hourFormat = twelveHourFormatLocals.includes(lang) ? 'ha' : 'kk';
  const hours = [...Array(24).keys()].map((idx) => moment().hours(idx).format(hourFormat));
  const days = [...Array(7).keys()].map((idx) => moment().day(idx).format('dddd')); /* .reverse() */

  const options = {
    title: {
      text: translations[lang].statistics.timeOfBookings,
    },
    legend: {
      data: [translations[lang].statistics.bookingCount],
      left: 'right',
    },
    tooltip: {
      position: 'top',
      formatter: (params) =>
        `${params.value[2]} ${translations[lang].statistics.bookingsIn} ${days[params.value[1]]} ${
          hours[params.value[0]]
        }`,
    },
    grid: {
      left: 2,
      bottom: 10,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: hours,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#999',
          type: 'dashed',
        },
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: days,
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        name: [translations[lang].statistics.bookingCount],
        type: 'scatter',
        symbolSize: function (val) {
          return val[2] * 2;
        },
        data: buildData(),
        animationDelay: function (idx) {
          return idx * 5;
        },
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%', boxSizing: 'border-box', padding: '5px' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default DailyBookingTimeDistribution;
