import React from 'react';
import { IconButton, makeStyles, Switch, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '25px 40px 1fr',
    columnGap: '5px',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    padding: '4px',
  },
  removeIcon: {
    fontSize: '22px',
    color: theme.status.danger,
    transform: 'translateY(-1px)',
  },
}));

const DescriptionTable = ({
  descriptions,
  handleRemoveDescription,
  defaultDescription,
  handleChangeDefaultDescription,
  translations,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {descriptions.map((d) => (
        <div key={d} className={classes.list}>
          <div>
            <Tooltip title={translations.removeProduct}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleRemoveDescription({ description: d })}
              >
                <DeleteIcon className={classes.removeIcon} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={translations.templates.makedefaultselection}>
              <Switch
                id={`set-default-description-${d}`}
                size="small"
                disableRipple
                onClick={handleChangeDefaultDescription}
                checked={defaultDescription === d}
                value={d}
              />
            </Tooltip>
          </div>
          <div>{d}</div>
        </div>
      ))}
    </div>
  );
};

export default DescriptionTable;
