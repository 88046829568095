import React from 'react';
import DateSelect from '../../../DateSelect/DateSelect';
import TimeSelect from '../../../TimeSelect/TimeSelect';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pickupContainer: {
    padding: '15px',
  },
  pickupSelectionOptions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pickupOption: {
    display: 'flex',
    alignItems: 'center',
  },
  requestPickupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pickupReference: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '226px',
  },
  pickupTimeSelections: {
    display: 'flex',
    paddingBottom: '10px',
    justifyContent: 'space-between',
    '& > *': {
      margin: '10px 0',
    },
  },
  [theme.breakpoints.down('xs')]: {
    pickupSelectionOptions: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'fit-content',
    },
    pickupOption: {
      '& label': {
        fontSize: '0.9rem',
      },
    },
  },
  '@media (max-width:400px)': {
    pickupSelectionOptions: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    pickupOption: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5px 0',
      '& label': {
        fontSize: '0.9rem',
        textAlign: 'center',
      },
    },
  },
}));

const DefaultCollection = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.pickupContainer}>
      {props.withOptions && (
        <div className={classes.pickupSelectionOptions}>
          <div className={classes.pickupOption}>
            <Radio
              color="primary"
              checked={!props.requestPickup}
              onChange={props.handlePickupChange}
              value="0"
              name="usecurrentpickup"
              id="usecurrentpickup"
            />
            <label htmlFor="usecurrentpickup">
              {props.translations.onlinebooking.usecurrentpickup}
            </label>
          </div>
          <div className={classes.pickupOption}>
            <Radio
              color="primary"
              checked={props.requestPickup}
              onChange={props.handlePickupChange}
              value="01"
              name="requestpickup"
              id="requestpickup"
            />
            <label htmlFor="requestpickup">{props.translations.onlinebooking.requestpickup}</label>
          </div>
        </div>
      )}
      {props.requestPickup && (
        <div className={classes.requestPickupContainer}>
          <div className={classes.pickupTimeSelections}>
            <DateSelect
              locale={props.locale}
              label={props.translations.onlinebooking.pickupdate}
              inline={true}
              handleDateChange={props.handlePickupDateChange}
              selectedDate={props.selectedPickupDate}
            />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <TimeSelect
                selected={props.selectedPickupTimeStart}
                handleTimeChange={props.handleStartTimeChange}
                label={props.translations.onlinebooking.earliest}
              />
              <TimeSelect
                selected={props.selectedPickupTimeEnd}
                handleTimeChange={props.handleEndTimeChange}
                label={props.translations.onlinebooking.latest}
              />
            </div>
          </div>
          <div className={classes.pickupReference}>
            {props.withOptions && (
              <label htmlFor="pickupReference">
                {props.translations.onlinebooking.pickupreference}
              </label>
            )}
            <TextField
              id="pickupReference"
              inputProps={{ maxLength: 35 }}
              label={'Reference'}
              className={classes.textField}
              name="pickupReference"
              value={props.pickupReference}
              onChange={props.handlePickupReferenceChange}
              margin="none"
              disabled={!props.withOptions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultCollection;
