import React from 'react';
import { CircleSpinnerSmall } from '../../../UI/Spinners/Circle';
import { makeStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import DepartureLocationsList from './DepartureLocationsList/DepartureLocationsList';
import NumberMap from '../../../GeoMaps/NumberMap/NumberMap';

const useStyles = makeStyles((theme) => ({
  pickupLocationOptions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pickupSelectionOptions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  pickupOption: {
    display: 'flex',
    alignItems: 'center',
  },
  departurePointsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '10px',
  },
  mapContainer: {
    padding: '20px',
  },
  departureLocationsListContainer: {
    display: 'grid',
    placeContent: 'center',
  },

  [theme.breakpoints.down('xs')]: {
    departurePointsContainer: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'max-content 300px',
    },
    mapContainer: {
      gridRow: '2',
      padding: '0',
      justifySelf: 'center',
      width: '100%',
    },
    departureLocationsListContainer: {
      gridRow: '1',
    },
    pickupOption: {
      '& label': {
        fontSize: '0.9rem',
      },
    },
  },
  '@media (max-width:400px)': {
    pickupSelectionOptions: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    pickupOption: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5px 0',
      '& label': {
        fontSize: '0.9rem',
        textAlign: 'center',
      },
    },
    mapContainer: {
      padding: '0px',
    },
  },
}));

const MatkahuoltoDeparture = ({
  translations,
  dropOffError,
  dropOffLoading,
  dropOffLocations,
  // handleDropOffChange,
  // dropOffSelected,
}) => {
  const classes = useStyles();

  // const mapLocations = dropOffLocations.reduce((mapLocations,location) => {
  //   acc[curr.id] = curr;
  //   return acc;
  // },[]);

  return (
    <div>
      <div className={classes.pickupLocationOptions}>
        <div className={classes.pickupSelectionOptions}>
          <div className={classes.pickupOption}>
            <Radio
              color="primary"
              checked={true}
              onChange={() => null}
              value="0"
              name="usecurrentpickup"
              id="usecurrentpickup"
              disabled={false}
            />
            <label htmlFor="usecurrentpickup"> {translations.dropOffAtDeparturePoint}</label>
          </div>
          <div className={classes.pickupOption}>
            <Radio
              color="primary"
              checked={false}
              onChange={() => null}
              value="01"
              name="requestpickup"
              id="requestpickup"
              disabled
            />
            <label style={{ color: grey[600] }} htmlFor="requestpickup">
              {translations.onlinebooking.requestpickup}
            </label>
          </div>
        </div>

        {dropOffLoading && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircleSpinnerSmall />
          </div>
        )}
        {!dropOffLoading && dropOffError && <p>{dropOffError}.</p>}
        {!dropOffLoading && !dropOffError && dropOffLocations.length > 0 && (
          <div className={classes.departurePointsContainer}>
            <div className={classes.mapContainer}>
              <NumberMap
                locations={dropOffLocations.map(
                  (loc) => `${loc.address.street},${loc.address.city},${loc.address.countryCode}`
                )}
              />
            </div>
            <div className={classes.departureLocationsListContainer}>
              <DepartureLocationsList
                translations={translations}
                dropOffLocations={dropOffLocations}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatkahuoltoDeparture;
