import { euCountryCodes } from '../../helpers/common';
import { CARRIERS, DOMESTIC, EXPORT, IMPORT, THIRD_PARTY } from '../../types';

export const emailPattern =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validates if shipment needs commercial invoice (required by all shipments not within EU)
 */
export const shipmentOutsideEU = (senderCountry, recipientCountry) => {
  const recipientEU = euCountryCodes.includes(senderCountry);
  const senderEU = euCountryCodes.includes(recipientCountry);

  return !recipientEU || !senderEU;
};

export const checkOnlineBookingInvoiceRequired = (senderCountry, recipientCountry, carrier) => {
  if (carrier === CARRIERS.DHLFREIGHT && recipientCountry === 'IE') return true;
  return shipmentOutsideEU(senderCountry, recipientCountry);
};

export const flowByCountry = (senderCountryCode, recipientCountryCode, originCountryCode) => {
  let flow = null;

  //calculate flow direction from user booking perspective
  if (senderCountryCode === recipientCountryCode) {
    flow = DOMESTIC;
  } else if (
    originCountryCode !== senderCountryCode &&
    originCountryCode === recipientCountryCode
  ) {
    flow = IMPORT;
  } else if (
    originCountryCode !== recipientCountryCode &&
    originCountryCode === senderCountryCode
  ) {
    flow = EXPORT;
  } else {
    flow = THIRD_PARTY;
  }

  return flow;
};

export const isImportControl = (senderCountryCode, recipientCountryCode, originCountryCode) => {
  const flow = flowByCountry(senderCountryCode, recipientCountryCode, originCountryCode);

  return flow === THIRD_PARTY || flow === IMPORT;
};

export const getFieldsFromData = (data, params) => {
  return params.reduce((acc, field) => {
    acc[field] = data[field];
    return acc;
  }, {});
};

export const groupNotificationsByType = ({ notifications }) => {
  let groupedNotificationsMap = new Map();

  notifications.forEach((n) => {
    for (let type in n.notifications) {
      const existingType = groupedNotificationsMap.get(n.notifications[type]);
      existingType
        ? groupedNotificationsMap.set(n.notifications[type], {
            recipients: [...existingType.recipients, ...n.recipients],
            notificationType: n.notificationType,
          })
        : groupedNotificationsMap.set(n.notifications[type], {
            recipients: [...n.recipients],
            notificationType: n.notificationType,
          });
    }
  });

  const groupedNotificationsArray = [];
  for (const key of groupedNotificationsMap.keys()) {
    groupedNotificationsArray.push(
      Object.assign({ notification: key }, groupedNotificationsMap.get(key))
    );
  }

  return groupedNotificationsArray;
};
