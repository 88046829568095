import React, { useState, useEffect, useRef } from 'react';
import {
  fetchSenderCompaniesTemplates,
  addBookingSender,
  bookingValidationUpdate,
  addCompanyTemplate,
  addBookingDate,
  updateSenderCompanyFromBooking,
} from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
//components
import FavouriteCompanies from '../Templates/FavouriteCompanies';
import DateSelect from '../../DateSelect/DateSelect';
import SenderInputs from './SenderInputs/SenderInputs';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReloadIcon from '@material-ui/icons/Autorenew';
import { Tooltip } from '@material-ui/core';
import { ADD_BOOKING_STATUS, REMOVE_BOOKING_STATUS } from '../../../store/actions/actionTypes';
import { createBookingStatusFromTemplateComment } from '../../../services/helpers/bookingStatus';
import AddCompanyCommentModal from '../../Template/AddCompanyCommentModal/AddCompanyCommentModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    minHeight: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButtons: {
    order: 0,
    display: 'flex',
    '& > *': {
      marginRight: '5px',
    },
  },
  bookingDate: {
    order: 1,
  },
  senderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '800px',
    justifySelf: 'center',
  },
  senderInputsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  reloadButton: {
    color: '#333333',
    backgroundColor: '#e4e4e8',
    height: '38px',
    width: '50px',
    margin: '1px 0 3px',
    padding: '5px',
  },
  templateSelect: {
    width: '220px',
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    headerButtons: {
      order: 1,
    },
    bookingDate: {
      order: 0,
      height: 74,
    },
    templateSelect: {
      width: '220px',
    },
  },
}));

const initialUpdateCommentModalState = {
  open: false,
  template: null,
  flow: null,
  comment: '',
};

export default function SenderDetails(props) {
  const [type, setType] = useState('manual');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [saveAddress, setSaveAddress] = useState(false);
  const [updateTemplate, setUpdateTemplate] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [keyCounter, setKeyCounter] = useState(0);
  const [updateCommentModalState, setUpdateCommentModalState] = useState(
    initialUpdateCommentModalState
  );
  const [newComment, setNewComment] = useState('');

  const useSenderTemplate = useSelector((state) => state.bookingPresets.useDefaultSender);
  const senderTemplateId = useSelector((state) => state.bookingPresets.defaultSender);
  const sender = useSelector((state) => state.booking.sender);
  const booking = useSelector((state) => state.booking);
  const templates = useSelector((state) => state.senderTemplates.templates);
  const id = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  const templateNameRef = useRef();
  templateNameRef.current = templateName;
  const saveSender = useRef();
  saveSender.current = saveAddress;
  const senderData = useRef();
  senderData.current = sender;
  const updateSenderTemplate = useRef();
  updateSenderTemplate.current = updateTemplate;
  const updateComment = useRef();
  updateComment.current = newComment;

  useEffect(() => {
    if (templates.length < 1) {
      dispatch(fetchSenderCompaniesTemplates({ userId: id, token }));
    }
    //Check if global state has values saved
    if (sender !== null) {
      setType('store');
    } else if (templates.length > 0 && useSenderTemplate && senderTemplateId) {
      const senderDefault = templates.filter((template) => senderTemplateId === template.id);

      if (senderDefault.length > 0) {
        dispatch(
          addBookingSender({
            templateId: senderDefault[0].id,
            company: senderDefault[0].company,
            contact: senderDefault[0].contacts.length > 0 ? senderDefault[0].contacts[0].name : '',
            phone:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].phone
                : senderDefault[0].phone,
            email:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].email
                : senderDefault[0].email,
            city: senderDefault[0].city,
            zip: senderDefault[0].zip,
            address1: senderDefault[0].address1,
            address2: senderDefault[0].address2,
            countryName: senderDefault[0].countryName,
            countryCode: senderDefault[0].countryCode,
            stateRegion: senderDefault[0].stateRegion,
            contacts: senderDefault[0].contacts,
          })
        );
      }
    }

    booking.date ? setSelectedDate(booking.date) : dispatch(addBookingDate(new Date()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //fires when component unmounts
  useEffect(() => {
    return () => {
      if (saveSender.current && templateNameRef.current) {
        let saveTemplateData = Object.assign({}, senderData.current);
        if (updateComment.current) saveTemplateData.templateComment = updateComment.current;
        dispatch(addCompanyTemplate(saveTemplateData, templateNameRef.current, 'sender', token));
      }
      if (updateSenderTemplate.current && templateNameRef.current) {
        let updateTemplateData = Object.assign({}, senderData.current);
        if (updateComment.current) updateTemplateData.templateComment = updateComment.current;

        dispatch(
          updateSenderCompanyFromBooking(updateTemplateData, templateNameRef.current, token)
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.length > 0 && !sender && useSenderTemplate && senderTemplateId) {
      const senderDefault = templates.filter((template) => senderTemplateId === template.id);

      if (senderDefault.length > 0) {
        dispatch(
          addBookingSender({
            templateId: senderDefault[0].id || null,
            company: senderDefault[0].company || '',
            phone:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].phone
                : senderDefault[0].phone || '',
            email:
              senderDefault[0].contacts.length > 0
                ? senderDefault[0].contacts[0].email
                : senderDefault[0].email || '',
            contact: senderDefault[0].contacts.length > 0 ? senderDefault[0].contacts[0].name : '',
            city: senderDefault[0].city || '',
            zip: senderDefault[0].zip || '',
            address1: senderDefault[0].address1 || '',
            address2: senderDefault[0].address2 || '',
            countryName: senderDefault[0].countryName || '',
            countryCode: senderDefault[0].countryCode || '',
            stateRegion: senderDefault[0].stateRegion || '',
            contacts: senderDefault[0].contacts,
          })
        );

        addTemplateComment(senderDefault[0].templateComment);

        if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in sender inputs referring to update and empty string

        setType('template');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  // Adds a comment to the booking status, also removes existing sender comment if exists
  const addTemplateComment = (comment) => {
    const bookingStatusComment = createBookingStatusFromTemplateComment(comment, 'sender');
    dispatch({
      type: ADD_BOOKING_STATUS,
      payload: bookingStatusComment,
    });
  };

  const handleTemplateSelected = (company) => {
    //Reset services if data exist in redux store and company template changes
    if (
      booking.sender &&
      (booking.services.length > 0 || booking.carrier) &&
      booking.sender.company !== company.company
    ) {
      props.handleClearServices();
    }

    updateTemplate && setUpdateTemplate(false);
    saveAddress && setSaveAddress(false);
    //Reset template name if exists
    // templateName.length > 0 && setTemplateName('');

    dispatch(
      addBookingSender({
        templateId: company.id,
        company: company.company || '',
        phone: company.contacts.length > 0 ? company.contacts[0].phone : company.phone || '',
        email: company.contacts.length > 0 ? company.contacts[0].email : company.email || '',
        contact: company.contacts.length > 0 ? company.contacts[0].name : '',
        city: company.city || '',
        zip: company.zip || '',
        address1: company.address1 || '',
        address2: company.address2 || '',
        countryName: company.countryName || '',
        countryCode: company.countryCode || '',
        stateRegion: company.stateRegion || '',
        contacts: company.contacts,
      })
    );
    addTemplateComment(company.templateComment);
    if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in sender inputs referring to update and empty string

    type !== 'template' && setType('template');
    type === 'template' && setKeyCounter(keyCounter + 1);
  };

  const handleReload = () => {
    dispatch(
      addBookingSender({
        templateId: null,
        company: '',
        contact: '',
        phone: '',
        email: '',
        city: '',
        zip: '',
        address1: '',
        address2: '',
        countryName: '',
        countryCode: '',
        stateRegion: '',
      })
    );

    type !== 'reload' && setType('reload');
    type === 'reload' && setKeyCounter(keyCounter + 1);

    if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in sender inputs referring to update and empty string

    dispatch({
      type: REMOVE_BOOKING_STATUS,
      payload: 'senderComment',
    });

    updateTemplate && setUpdateTemplate(false);
    saveAddress && setSaveAddress(false);
  };
  const handleTextBlur = (sender) => {
    dispatch(addBookingSender(sender));
  };

  const handleUpdateContact = (name, phone, email) => {
    dispatch(addBookingSender({ ...sender, contact: name, phone, email }));
  };

  const handleSaveAddressChange = () => {
    updateTemplate && setUpdateTemplate(false);
    setSaveAddress(!saveAddress);

    setTemplateName(booking.sender.company); //Default favourite name
  };

  const handleUpdateTemplateChange = () => {
    saveAddress && setSaveAddress(false);
    setUpdateTemplate(!updateTemplate);
    setTemplateName(booking.sender.company); //Default favourite name
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(addBookingDate(date));
  };

  const getTemplateSelectedName = () => {
    if (!booking.sender || !booking.sender.templateId) return;
    const { templateName } = templates.find(({ id }) => id === booking.sender.templateId);
    return templateName;
  };

  const checkServicesReset = (field, value) => {
    if (booking.services.length > 0 || booking.carrier) {
      if (booking.sender && booking.sender[field] !== value) {
        props.handleClearServices();
      }
    }
  };

  const handleUpdateCommentModal = () => {
    let template = null;
    let comment = null;
    // Case for existing template
    if (updateTemplate && booking.sender.templateId) {
      template = templates.find(({ id }) => id === booking.sender.templateId);
      comment = template.templateComment;
    }

    setUpdateCommentModalState({
      open: true,
      template,
      flow: 'sender',
      comment,
    });
  };

  const handleUpdateComment = (comment) => {
    setUpdateCommentModalState(initialUpdateCommentModalState);
    setNewComment(comment);
  };

  const classes = useStyles();

  const key = `${type}${keyCounter}`;

  return (
    <div className={classes.root}>
      <AddCompanyCommentModal
        id="updateCommentModal"
        open={updateCommentModalState.open}
        template={updateCommentModalState.template}
        flow={updateCommentModalState.flow}
        updateComment={handleUpdateComment}
        handleAbort={() => setUpdateCommentModalState(initialUpdateCommentModalState)}
      />
      <div className={classes.senderContainer}>
        <div className={classes.header}>
          <div className={classes.headerButtons}>
            {templates.length > 0 ? (
              <FavouriteCompanies
                templateSelected={handleTemplateSelected}
                options={templates}
                styles={classes.templateSelect}
                label={props.translations.onlinebooking.buttons.templates}
                defaultValue={getTemplateSelectedName()}
              />
            ) : null}
            <Tooltip title={props.translations.onlinebooking.buttons.cleanfields}>
              <Button className={classes.reloadButton} variant="contained" onClick={handleReload}>
                <ReloadIcon />
              </Button>
            </Tooltip>
          </div>
          <div className={classes.bookingDate}>
            <DateSelect
              locale={props.locale}
              label={props.translations.onlinebooking.shippingdate}
              inline={true}
              disablePast={true}
              handleDateChange={handleDateChange}
              selectedDate={selectedDate}
            />
          </div>
        </div>
        <div className={classes.senderInputsContainer}>
          <SenderInputs
            handleUpdateCommentModal={handleUpdateCommentModal}
            handleSaveAddressChange={handleSaveAddressChange}
            handleUpdateTemplateChange={handleUpdateTemplateChange}
            saveAddress={saveAddress}
            updateTemplate={updateTemplate}
            checkServicesReset={checkServicesReset}
            translations={props.translations}
            updateContact={handleUpdateContact}
            textBlur={handleTextBlur}
            key={key}
            senderData={sender}
            templateNameChange={(e) => setTemplateName(e.target.value)}
            templateName={templateName}
            refresh={() => setKeyCounter(keyCounter + 1)}
          />
        </div>
      </div>
    </div>
  );
}
