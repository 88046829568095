import React, { useState, useEffect } from 'react';
import {
  bookingValidationUpdate,
  cleanBooking,
  fetchCarriers,
  clearServiceData,
  clearDeliveryData,
} from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import 'filepond/dist/filepond.min.css';
import translations from '../../../services/translations/translations.json';
import { VerifyOnlineBookingSteps } from '../../../services/validations';
//Material UI
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import StarIcon from '@material-ui/icons/Star';
//Custom Components
import SnackbarContentWrapper from '../../../components/UI/SnackbarContentWrapper/SnackbarContentWrapper';
//Step Components
import SenderDetails from '../../../components/Bookings/SenderDetails/SenderDetails';
import InvoiceAndPickup from '../../../components/Bookings/InvoiceAndPickup/InvoiceAndPickup';
import RecipientDetails from '../../../components/Bookings/RecipientDetails/RecipientDetails';
import PackageAndService from '../../../components/Bookings/PackageAndService/PackageAndService';
import VerifySend from '../../../components/Bookings/VerifySend/VerifySend';
import BookingConfirmed from '../../../components/Bookings/BookingConfirmed/BookingConfirmed';
import { getLocaleFromLang } from '../../../services/helpers/localize';
import { getCarrierAliasesFromAllCarriers } from '../../../services/helpers/carriers';
import { grey } from '@material-ui/core/colors';
import OnlineBookingStatusBar from '../../../components/Bookings/OnlineBookingStatusBar/OnlineBookingStatusBar';
import { RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY } from '../../../store/actions/actionTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateY(-20px)',
  },
  header: {
    width: '100%',
    maxWidth: '1000px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
  },
  statusBarContainer: {
    width: 'calc(100% + 40px)',
    transform: 'translateX(-20px)',
  },
  headerText: {
    color: '#424242',
    height: 'fit-content',
    textAlign: 'center',
    width: '100%',
    padding: '10px',
    fontWeight: '300',
    fontSize: '2rem',
    paddingTop: '20px',
  },
  headerReset: {
    display: 'flex',
    position: 'absolute',
    right: '0px',
    top: '4px',
    height: '35px',
    backgroundColor: grey[50],
  },
  resetBookingIcon: {
    padding: '0 10px',
  },
  stepper: {
    background: 'transparent',
    padding: '8px 15px 15px 15px',
    maxWidth: '1000px',
    width: '100%',
    justifySelf: 'center',
    boxSizing: 'border-box',
  },
  stepContainer: {
    display: 'grid',
    minHeight: 'calc(100vh - 145px)',
    width: '100%',
    gridTemplateRows: '35px 85px minmax(395px, auto) 80px',
  },
  stepContent: {
    minHeight: '350px',
  },
  button: {
    marginRight: '8px',
    padding: '8px 45px',
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  addButton: {
    width: '110px',
    fontSize: '1.1rem',
    alignSelf: 'flex-end',
  },
  [theme.breakpoints.down('xs')]: {
    stepContainer: {
      gridTemplateRows: '50px 80px minmax(395px, auto) 80px',
    },
    statusBarContainer: {
      width: '100%',
      transform: 'translateX(0)',
    },
    headerText: {
      fontSize: '1.5rem',
    },
    headerReset: {
      top: '0px',
    },
    button: {
      marginRight: '6px',
      padding: '6px 35px',
      fontSize: '0.9rem',
      fontWeight: 400,
    },
  },
  '@media (max-width:740px)': {
    stepContainer: {
      minHeight: 'calc(100vh - 200px)',
    },
    headerReset: {
      right: '10px',
    },
    resetBookingIcon: {
      display: 'none',
    },
  },
}));

function getSteps(timeline) {
  return [
    timeline.sender,
    timeline.recipient,
    timeline.shipdetails,
    timeline.docspickup,
    timeline.verify,
  ];
}

const OnlineBooking = (props) => {
  const [state, setState] = useState({
    modalOpen: false,
    redirect: false,
    error: '',
    snackbar: {
      variant: '',
      messages: [],
      open: false,
    },
  });

  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);
  const booking = useSelector((state) => state.booking);
  const carriers = useSelector((state) => state.carriers);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [activeStep, setActiveStep] = useState(0);
  const [onlineBookingCarriers, setOnlineBookingCarriers] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  const renderLabels = useMediaQuery('(min-width:740px)');

  useEffect(() => {
    if (carriers.allCarriers.length < 1) {
      dispatch(fetchCarriers(token, companyId));
    }
    return () => {
      // dispatch(cleanBooking());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onlineBookingCarriers.length === 0 && carriers.allCarriers.length > 0) {
      const { onlineCarriers, allCarriers } = carriers;
      const onlineBookingCarrierAliases = getCarrierAliasesFromAllCarriers(
        onlineCarriers,
        allCarriers
      );

      setOnlineBookingCarriers(onlineBookingCarrierAliases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers.allCarriers]);

  const handleNext = async () => {
    const currentStep = activeStep;
    const validation = await VerifyOnlineBookingSteps(currentStep, booking, lang);

    if (!validation.valid) {
      dispatch(bookingValidationUpdate(validation.step));
      const snackbarErrors = validation.errors;

      setState({
        ...state,
        snackbar: { open: true, messages: snackbarErrors || '', variant: validation.variant },
      });
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setResetKey(resetKey + 1);
    dispatch(cleanBooking());
    dispatch({ type: RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY });
    setActiveStep(0);
  };

  const handleRedo = () => {
    setActiveStep(0);
  };

  //If any value changes that can effect rates, services need to be reset
  const handleClearServices = () => {
    dispatch(clearServiceData());
  };
  const handleClearDeliveryData = () => {
    dispatch(clearDeliveryData());
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const updatedState = { ...state };
    const updatedSnackbar = { ...state.snackbar, open: false };
    updatedState.snackbar = updatedSnackbar;
    setState({ ...state, ...updatedState });
  };

  const classes = useStyles();
  const steps = getSteps(translations[lang].onlinebooking.timeline);
  const locale = getLocaleFromLang(lang);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div key={resetKey}>
            <SenderDetails
              resetKey={resetKey}
              handleClearServices={handleClearDeliveryData}
              locale={locale}
              translations={translations[lang]}
            />
          </div>
        );
      case 1:
        return (
          <RecipientDetails
            handleClearServices={handleClearDeliveryData}
            translations={translations[lang]}
          />
        );
      case 2:
        return (
          <PackageAndService
            handleClearServices={handleClearServices}
            onlineBookingCarriers={onlineBookingCarriers}
            translations={translations[lang]}
          />
        );
      case 3:
        return <InvoiceAndPickup locale={locale} translations={translations[lang]} />;
      case 4:
        return <VerifySend translations={translations[lang]} />;
      default:
        return (
          <BookingConfirmed locale={locale} translations={translations[lang]} redo={handleRedo} />
        );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.statusBarContainer}>
        <OnlineBookingStatusBar />
      </div>
      <div className={classes.stepContainer}>
        <div className={classes.header}>
          <h1 className={classes.headerText}>{translations[lang].categories.booking}</h1>
          <Button variant="outlined" onClick={handleReset} className={classes.headerReset}>
            {translations[lang].onlinebooking.buttons.newbooking}
            <StarIcon color="primary" className={classes.resetBookingIcon} />
          </Button>
        </div>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          orientation="horizontal"
          alternativeLabel={true}
        >
          {steps.map((label, index) => (
            <Step key={activeStep}>
              <StepLabel>{renderLabels ? label : ''}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
        <div className={classes.actionsContainer}>
          {activeStep !== steps.length && (
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                {translations[lang].onlinebooking.buttons.back}
              </Button>
              <Button
                disabled={booking.loading || booking.loadingAdditionalServices}
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1
                  ? translations[lang].onlinebooking.buttons.send
                  : translations[lang].onlinebooking.buttons.next}
              </Button>
            </div>
          )}
          {activeStep === steps.length && (
            <Button onClick={handleReset} className={classes.button}>
              {translations[lang].onlinebooking.buttons.newbooking}
            </Button>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={state.snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={state.snackbar.variant}
          messages={state.snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default OnlineBooking;
