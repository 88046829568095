import { AppBar, IconButton, makeStyles, Paper, Tooltip } from '@material-ui/core';
import React from 'react';
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';

const useStyles = makeStyles({
  paper: {
    width: '100%',
  },
  content: {
    padding: '10px',
  },
});

export default function TableWrapper({ children, toolbarTitle = '', toolbarProps, ...actions }) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <AppBar position="static" elevation={0}>
        <CustomToolbar title={toolbarTitle}>
          {toolbarProps.map((option) => (
            <div key={option['funcName']}>
              <Tooltip title={option.toolTip}>
                <IconButton onClick={actions[`${option['funcName']}`]}>{option.icon}</IconButton>
              </Tooltip>
            </div>
          ))}
        </CustomToolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </Paper>
  );
}
