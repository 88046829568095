//Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
//Logout
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
//User data
export const FETCH_USER_DETAILS_START = 'FETCH_USER_DETAILS_START';
export const FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const CLEAN_USER_DETAILS = 'CLEAN_USER_DETAILS';
//GDPR CONSENT
export const USER_CONSENT_SUCCESS = 'USER_CONSENT_SUCCESS';
//AVATAR CHANGE
export const USER_AVATAR_UPLOAD_SUCCESS = 'USER_AVATAR_UPLOAD_SUCCESS';
//MY SHIPMENTS STATE
export const ADD_MY_SHIPMENTS_PROPS = 'ADD_MY_SHIPMENTS_PROPS';

// BATCH BOOKING
export const PROCESS_BATCH_BOOKING_START = 'PROCESS_BATCH_BOOKING_START';
export const PROCESS_BATCH_BOOKING_SUCCESS = 'PROCESS_BATCH_BOOKING_SUCCESS';
export const PROCESS_BATCH_BOOKING_FAILED = 'PROCESS_BATCH_BOOKING_FAILED';
export const FETCH_BATCH_BOOKING_RULES_START = 'FETCH_BATCH_BOOKING_RULES_START';
export const FETCH_BATCH_BOOKING_RULES_SUCCESS = 'FETCH_BATCH_BOOKING_RULES_SUCCESS';
export const FETCH_BATCH_BOOKING_RULES_FAILED = 'FETCH_BATCH_BOOKING_RULES_FAILED';

export const UPDATE_BATCH_BOOKING_RULES_START = 'UPDATE_BATCH_BOOKING_RULES_START';
export const UPDATE_BATCH_BOOKING_RULES_SUCCESS = 'UPDATE_BATCH_BOOKING_RULES_SUCCESS';
export const UPDATE_BATCH_BOOKING_RULES_FAILED = 'UPDATE_BATCH_BOOKING_RULES_FAILED';

export const REMOVE_BATCH_BOOKING_COUNTRY_RULE_SUCCESS =
  'REMOVE_BATCH_BOOKING_COUNTRY_RULE_SUCCESS';
export const REMOVE_BATCH_BOOKING_COUNTRY_RULE_FAILED = 'REMOVE_BATCH_BOOKING_COUNTRY_RULE_FAILED';
export const UPDATE_SENDER_TEMPLATE_FOR_BOOKING = 'UPDATE_SENDER_TEMPLATE_FOR_BOOKING';
export const UPDATE_BATCH_BOOKING_DATA_WHEN_PRINTED = 'UPDATE_BATCH_BOOKING_DATA_WHEN_PRINTED';
export const CLEAR_BATCH_BOOKING_STATE = 'CLEAR_BATCH_BOOKING_STATE';
export const PROCESS_BATCH_FILE_START = 'PROCESS_BATCH_FILE_START';
export const PROCESS_BATCH_FILE_SUCCESS = 'PROCESS_BATCH_FILE_SUCCESS';
export const PROCESS_BATCH_FILE_FAILED = 'PROCESS_BATCH_FILE_FAILED';
export const UPDATE_INVOICE_DATA_FOR_BATCH_BOOKING = 'UPDATE_INVOICE_DATA_FOR_BATCH_BOOKING';
export const UPDATE_BATCH_BOOKING_DATA_SHIPMENT = 'UPDATE_BATCH_BOOKING_DATA_SHIPMENT';

//TEMPLATES
// Invoice form templates
export const ADD_INVOICE_FORM_TEMPLATE_START = 'ADD_INVOICE_FORM_TEMPLATE_START';
export const ADD_INVOICE_FORM_TEMPLATE_SUCCESS = 'ADD_INVOICE_FORM_TEMPLATE_SUCCESS';
export const ADD_INVOICE_FORM_TEMPLATE_FAIL = 'ADD_INVOICE_FORM_TEMPLATE_FAIL';
export const FETCH_INVOICE_FORM_TEMPLATES_START = 'FETCH_INVOICE_FORM_TEMPLATES_START';
export const FETCH_INVOICE_FORM_TEMPLATES_SUCCESS = 'FETCH_INVOICE_FORM_TEMPLATES_SUCCESS';
export const FETCH_INVOICE_FORM_TEMPLATES_FAIL = 'FETCH_INVOICE_FORM_TEMPLATES_FAIL';
export const DELETE_INVOICE_FORM_TEMPLATE_START = 'DELETE_INVOICE_FORM_TEMPLATE_START';
export const DELETE_INVOICE_FORM_TEMPLATE_SUCCESS = 'DELETE_INVOICE_FORM_TEMPLATE_SUCCESS';
export const DELETE_INVOICE_FORM_TEMPLATE_FAIL = 'DELETE_INVOICE_FORM_TEMPLATE_FAIL';
//Booking presets
export const FETCH_BOOKING_PRESET_SUCCESS = 'FETCH_BOOKING_PRESET_SUCCESS';
export const FETCH_BOOKING_PRESET_FAILED = 'FETCH_BOOKING_PRESET_FAILED';
export const FETCH_BOOKING_PRESET_START = 'FETCH_BOOKING_PRESET_START';
export const UPDATE_DEFAULT_CUSTOMS_VALUE_START = 'UPDATE_DEFAULT_CUSTOMS_VALUE_START';
export const UPDATE_DEFAULT_CUSTOMS_VALUE_FAILED = 'UPDATE_DEFAULT_CUSTOMS_VALUE_FAILED';
export const UPDATE_DEFAULT_CUSTOMS_VALUE_SUCCESS = 'UPDATE_DEFAULT_CUSTOMS_VALUE_SUCCESS';
export const UPDATE_DEFAULT_CUSTOMS_CURRENCY_START = 'UPDATE_DEFAULT_CUSTOMS_CURRENCY_START';
export const UPDATE_DEFAULT_CUSTOMS_CURRENCY_FAILED = 'UPDATE_DEFAULT_CUSTOMS_CURRENCY_FAILED';
export const UPDATE_DEFAULT_CUSTOMS_CURRENCY_SUCCESS = 'UPDATE_DEFAULT_CUSTOMS_CURRENCY_SUCCESS';
//UPDATE DEFAULT SENDER
export const UPDATE_DEFAULT_SENDER_START = 'UPDATE_DEFAULT_SENDER_START';
export const UPDATE_DEFAULT_SENDER_SUCCESS = 'UPDATE_DEFAULT_SENDER_SUCCESS';
export const UPDATE_DEFAULT_SENDER_FAILED = 'UPDATE_DEFAULT_SENDER_FAILED';
//UPDATE USE DEFAULT SENDER
export const USE_DEFAULT_SENDER_START = 'USE_DEFAULT_SENDER_START';
export const USE_DEFAULT_SENDER_FAILED = 'USE_DEFAULT_SENDER_FAILED';
export const USE_DEFAULT_SENDER_SUCCESS = 'USE_DEFAULT_SENDER_SUCCESS';
//UPDATE DEFAULT RECIPIENT
export const UPDATE_DEFAULT_RECIPIENT_START = 'UPDATE_DEFAULT_RECIPIENT_START';
export const UPDATE_DEFAULT_RECIPIENT_SUCCESS = 'UPDATE_DEFAULT_RECIPIENT_SUCCESS';
export const UPDATE_DEFAULT_RECIPIENT_FAILED = 'UPDATE_DEFAULT_RECIPIENT_FAILED';
//UPDATE USE DEFAULT RECIPIENT
export const USE_DEFAULT_RECIPIENT_START = 'USE_DEFAULT_RECIPIENT_START';
export const USE_DEFAULT_RECIPIENT_FAILED = 'USE_DEFAULT_RECIPIENT_FAILED';
export const USE_DEFAULT_RECIPIENT_SUCCESS = 'USE_DEFAULT_RECIPIENT_SUCCESS';
//UPDATE THE PRESET VALUES FOR INVOICE FORM
export const UPDATE_INVOICE_FORM_PRESET_START = 'UPDATE_INVOICE_FORM_PRESET_START';
export const UPDATE_INVOICE_FORM_PRESET_FAILED = 'UPDATE_INVOICE_FORM_PRESET_FAILED';
export const UPDATE_INVOICE_FORM_PRESET_SUCCESS = 'UPDATE_INVOICE_FORM_PRESET_SUCCESS';
export const UPDATE_AUTO_INCREMENT_INVOICE_NUMBER = 'UPDATE_AUTO_INCREMENT_INVOICE_NUMBER';
export const UPDATE_INVOICE_NUMBER = 'UPDATE_INVOICE_NUMBER';
//UPDATE PRESET NOTIFICATIONS
export const UPDATE_NOTIFICATIONS_PRESET_START = 'UPDATE_NOTIFICATIONS_PRESET_START';
export const UPDATE_NOTIFICATIONS_PRESET_FAILED = 'UPDATE_NOTIFICATIONS_PRESET_FAILED';
export const UPDATE_NOTIFICATIONS_PRESET_SUCCESS = 'UPDATE_NOTIFICATIONS_PRESET_SUCCESS';

//SET OPTIONS WHETHER COMPANY NAMES SHOULD HAVE AUTOCOMPLETE
export const UPDATE_COMPANY_AUTOCOMPLETE_SUCCESS = 'UPDATE_COMPANY_AUTOCOMPLETE_SUCCESS';
export const UPDATE_COMPANY_AUTOCOMPLETE_START = 'UPDATE_COMPANY_AUTOCOMPLETE_START';
export const UPDATE_COMPANY_AUTOCOMPLETE_FAILED = 'UPDATE_COMPANY_AUTOCOMPLETE_FAILED';

export const UPDATE_BOOKING_PRESET_DESCRIPTION_START = 'UPDATE_BOOKING_PRESET_DESCRIPTION_START';
export const UPDATE_BOOKING_PRESET_DESCRIPTION_FAILED = 'UPDATE_BOOKING_PRESET_DESCRIPTION_FAILED';
export const UPDATE_BOOKING_PRESET_DESCRIPTION_SUCCESS =
  'UPDATE_BOOKING_PRESET_DESCRIPTION_SUCCESS';
//Root Templates
/* NOTE:  this is fetched by login, may be changed */
export const SET_ROOT_TEMPLATE_SUCCESS = 'SET_ROOT_TEMPLATE_SUCCESS';
//UPDATE DEFAULT LANGUAGE
export const UPDATE_DEFAULT_LANGUAGE_START = 'UPDATE_DEFAULT_LANGUAGE_START';
export const UPDATE_DEFAULT_LANGUAGE_SUCCESS = 'UPDATE_DEFAULT_LANGUAGE_SUCCESS';
export const UPDATE_DEFAULT_LANGUAGE_FAILED = 'UPDATE_DEFAULT_LANGUAGE_FAILED';
//UPDATE DEFAULT CURRENCY
export const UPDATE_DEFAULT_CURRENCY_START = 'UPDATE_DEFAULT_CURRENCY_START';
export const UPDATE_DEFAULT_CURRENCY_SUCCESS = 'UPDATE_DEFAULT_CURRENCY_SUCCESS';
export const UPDATE_DEFAULT_CURRENCY_FAILED = 'UPDATE_DEFAULT_CURRENCY_FAILED';
//UPDATE UPS DEFAULT PRINT FORMAT
export const UPDATE_UPS_PRINT_FORMAT_START = 'UPDATE_UPS_PRINT_FORMAT_START';
export const UPDATE_UPS_PRINT_FORMAT_FAILED = 'UPDATE_UPS_PRINT_FORMAT_FAILED';
export const UPDATE_UPS_PRINT_FORMAT_SUCCESS = 'UPDATE_UPS_PRINT_FORMAT_SUCCESS';
//UPDATE DHLFREIGHT DEFAULT PRINT FORMAT
export const UPDATE_DHLFREIGHT_PRINT_FORMAT_START = 'UPDATE_DHLFREIGHT_PRINT_FORMAT_START';
export const UPDATE_DHLFREIGHT_PRINT_FORMAT_FAILED = 'UPDATE_DHLFREIGHT_PRINT_FORMAT_FAILED';
export const UPDATE_DHLFREIGHT_PRINT_FORMAT_SUCCESS = 'UPDATE_DHLFREIGHT_PRINT_FORMAT_SUCCESS';
//UPDATE MATKAHUOLTO DEFAULT PRINT FORMAT
export const UPDATE_MATKAHUOLTO_PRINT_FORMAT_START = 'UPDATE_MATKAHUOLTO_PRINT_FORMAT_START';
export const UPDATE_MATKAHUOLTO_PRINT_FORMAT_FAILED = 'UPDATE_MATKAHUOLTO_PRINT_FORMAT_FAILED';
export const UPDATE_MATKAHUOLTO_PRINT_FORMAT_SUCCESS = 'UPDATE_MATKAHUOLTO_PRINT_FORMAT_SUCCESS';

//TEMPLATES
export const ADD_RECIPIENT_COMPANY_TEMPLATE = 'ADD_RECIPIENT_COMPANY_TEMPLATE';
export const ADD_RECIPIENT_COMPANY_TEMPLATE_START = 'ADD_RECIPIENT_COMPANY_TEMPLATE_START';
export const ADD_RECIPIENT_COMPANY_TEMPLATE_SUCCESS = 'ADD_RECIPIENT_COMPANY_TEMPLATE_SUCCESS';
export const ADD_RECIPIENT_COMPANY_TEMPLATE_FAILED = 'ADD_RECIPIENT_COMPANY_TEMPLATE_FAILED';
export const UPDATE_RECIPIENT_COMPANY_TEMPLATE = 'UPDATE_RECIPIENT_COMPANY_TEMPLATE';
export const UPDATE_RECIPIENT_COMPANY_TEMPLATE_START = 'UPDATE_RECIPIENT_COMPANY_TEMPLATE_START';
export const UPDATE_RECIPIENT_COMPANY_TEMPLATE_SUCCESS =
  'UPDATE_RECIPIENT_COMPANY_TEMPLATE_SUCCESS';
export const UPDATE_RECIPIENT_COMPANY_TEMPLATE_FAILED = 'UPDATE_RECIPIENT_COMPANY_TEMPLATE_FAILED';
export const FETCH_RECIPIENT_COMPANIES_TEMPLATES = 'FETCH_RECIPIENT_COMPANIES_TEMPLATE';
export const FETCH_RECIPIENT_COMPANIES_TEMPLATES_START =
  'FETCH_RECIPIENT_COMPANIES_TEMPLATES_START';
export const FETCH_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS =
  'FETCH_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS';
export const FETCH_RECIPIENT_COMPANIES_TEMPLATES_FAILED =
  'FETCH_RECIPIENT_COMPANIES_TEMPLATES_FAILED';
export const REMOVE_RECIPIENT_COMPANIES_TEMPLATES = 'REMOVE_RECIPIENT_COMPANIES_TEMPLATE';
export const REMOVE_RECIPIENT_COMPANIES_TEMPLATES_START =
  'REMOVE_RECIPIENT_COMPANIES_TEMPLATES_START';
export const REMOVE_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS =
  'REMOVE_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS';
export const REMOVE_RECIPIENT_COMPANIES_TEMPLATES_FAILED =
  'REMOVE_RECIPIENT_COMPANIES_TEMPLATES_FAILED';
export const ADD_SENDER_COMPANY_TEMPLATE = 'ADD_SENDER_COMPANIES_TEMPLATE';
export const ADD_SENDER_COMPANY_TEMPLATE_START = 'ADD_SENDER_COMPANY_TEMPLATE_START';
export const ADD_SENDER_COMPANY_TEMPLATE_SUCCESS = 'ADD_SENDER_COMPANY_TEMPLATE_SUCCESS';
export const ADD_SENDER_COMPANY_TEMPLATE_FAILED = 'ADD_SENDER_COMPANY_TEMPLATE_FAILED';
export const UPDATE_SENDER_COMPANY_TEMPLATE = 'UPDATE_SENDER_COMPANIES_TEMPLATE';
export const UPDATE_SENDER_COMPANY_TEMPLATE_START = 'UPDATE_SENDER_COMPANY_TEMPLATE_START';
export const UPDATE_SENDER_COMPANY_TEMPLATE_SUCCESS = 'UPDATE_SENDER_COMPANY_TEMPLATE_SUCCESS';
export const UPDATE_SENDER_COMPANY_TEMPLATE_FAILED = 'UPDATE_SENDER_COMPANY_TEMPLATE_FAILED';
export const FETCH_SENDER_COMPANIES_TEMPLATES = 'FETCH_SENDER_COMPANIES_TEMPLATES';
export const FETCH_SENDER_COMPANIES_TEMPLATES_START = 'FETCH_SENDER_COMPANIES_TEMPLATES_START';
export const FETCH_SENDER_COMPANIES_TEMPLATES_SUCCESS = 'FETCH_SENDER_COMPANIES_TEMPLATES_SUCCESS';
export const FETCH_SENDER_COMPANIES_TEMPLATES_FAILED = 'FETCH_SENDER_COMPANIES_TEMPLATES_FAILED';
export const REMOVE_SENDER_COMPANIES_TEMPLATES = 'REMOVE_SENDER_COMPANIES_TEMPLATES';
export const REMOVE_SENDER_COMPANIES_TEMPLATES_START = 'REMOVE_SENDER_COMPANIES_TEMPLATES_START';
export const REMOVE_SENDER_COMPANIES_TEMPLATES_SUCCESS =
  'REMOVE_SENDER_COMPANIES_TEMPLATES_SUCCESS';
export const REMOVE_SENDER_COMPANIES_TEMPLATES_FAILED = 'REMOVE_SENDER_COMPANIES_TEMPLATES_FAILED';
export const COPY_COMPANY_TEMPLATES_START = 'COPY_COMPANY_TEMPLATES_START';
export const COPY_COMPANY_TEMPLATES_START_SUCCESS = 'COPY_COMPANY_TEMPLATES_START_SUCCESS';
export const COPY_COMPANY_TEMPLATES_START_FAILED = 'COPY_COMPANY_TEMPLATES_START_FAILED';
//Contacts
export const ADD_RECIPIENT_COMPANY_CONTACT_START = 'ADD_RECIPIENT_COMPANY_CONTACT_START';
export const ADD_RECIPIENT_COMPANY_CONTACT_SUCCESS = 'ADD_RECIPIENT_COMPANY_CONTACT_SUCCESS';
export const ADD_RECIPIENT_COMPANY_CONTACT_FAILED = 'ADD_RECIPIENT_COMPANY_CONTACT_FAILED';
export const REMOVE_RECIPIENT_COMPANY_CONTACT_START = 'REMOVE_RECIPIENT_COMPANY_CONTACT_START';
export const REMOVE_RECIPIENT_COMPANY_CONTACT_SUCCESS = 'REMOVE_RECIPIENT_COMPANY_CONTACT_SUCCESS';
export const REMOVE_RECIPIENT_COMPANY_CONTACT_FAILED = 'REMOVE_RECIPIENT_COMPANY_CONTACT_FAILED';
export const UPDATE_RECIPIENT_COMPANY_CONTACT_START = 'UPDATE_RECIPIENT_COMPANY_CONTACT_START';
export const UPDATE_RECIPIENT_COMPANY_CONTACT_SUCCESS = 'UPDATE_RECIPIENT_COMPANY_CONTACT_SUCCESS';
export const UPDATE_RECIPIENT_COMPANY_CONTACT_FAILED = 'UPDATE_RECIPIENT_COMPANY_CONTACT_FAILED';
export const ADD_SENDER_COMPANY_CONTACT_START = 'ADD_SENDER_COMPANY_CONTACT_START';
export const ADD_SENDER_COMPANY_CONTACT_SUCCESS = 'ADD_SENDER_COMPANY_CONTACT_SUCCESS';
export const ADD_SENDER_COMPANY_CONTACT_FAILED = 'ADD_SENDER_COMPANY_CONTACT_FAILED';
export const REMOVE_SENDER_COMPANY_CONTACT_START = 'REMOVE_SENDER_COMPANY_CONTACT_START';
export const REMOVE_SENDER_COMPANY_CONTACT_SUCCESS = 'REMOVE_SENDER_COMPANY_CONTACT_SUCCESS';
export const REMOVE_SENDER_COMPANY_CONTACT_FAILED = 'REMOVE_SENDER_COMPANY_CONTACT_FAILED';
export const UPDATE_SENDER_COMPANY_CONTACT_START = 'UPDATE_SENDER_COMPANY_CONTACT_START';
export const UPDATE_SENDER_COMPANY_CONTACT_SUCCESS = 'UPDATE_SENDER_COMPANY_CONTACT_SUCCESS';
export const UPDATE_SENDER_COMPANY_CONTACT_FAILED = 'UPDATE_SENDER_COMPANY_CONTACT_FAILED';
export const UPDATE_SENDER_TEMPLATE_COMMENT_START = 'UPDATE_SENDER_TEMPLATE_COMMENT_START';
export const UPDATE_SENDER_TEMPLATE_COMMENT_SUCCESS = 'UPDATE_SENDER_TEMPLATE_COMMENT_SUCCESS';
export const UPDATE_SENDER_TEMPLATE_COMMENT_FAILED = 'UPDATE_SENDER_TEMPLATE_COMMENT_FAILED';
export const UPDATE_RECIPIENT_TEMPLATE_COMMENT_START = 'UPDATE_RECIPIENT_TEMPLATE_COMMENT_START';
export const UPDATE_RECIPIENT_TEMPLATE_COMMENT_SUCCESS =
  'UPDATE_RECIPIENT_TEMPLATE_COMMENT_SUCCESS';
export const UPDATE_RECIPIENT_TEMPLATE_COMMENT_FAILED = 'UPDATE_RECIPIENT_TEMPLATE_COMMENT_FAILED';

//Package Templates
export const ADD_PACKAGE_TEMPLATE_START = 'ADD_PACKAGE_TEMPLATE_START';
export const ADD_PACKAGE_TEMPLATE_SUCCESS = 'ADD_PACKAGE_TEMPLATE_SUCCESS';
export const ADD_PACKAGE_TEMPLATE_FAILED = 'ADD_PACKAGE_TEMPLATE_FAILED';
export const FETCH_PACKAGE_TEMPLATE_START = 'FETCH_PACKAGE_TEMPLATE_START';
export const FETCH_PACKAGE_TEMPLATE_SUCCESS = 'FETCH_PACKAGE_TEMPLATE_SUCCESS';
export const FETCH_PACKAGE_TEMPLATE_FAILED = 'FETCH_PACKAGE_TEMPLATE_FAILED';
export const REMOVE_PACKAGE_TEMPLATE_START = 'REMOVE_PACKAGE_TEMPLATE_START';
export const REMOVE_PACKAGE_TEMPLATE_SUCCESS = 'REMOVE_PACKAGE_TEMPLATE_SUCCESS';
export const REMOVE_PACKAGE_TEMPLATE_FAILED = 'REMOVE_PACKAGE_TEMPLATE_FAILED';
export const UPDATE_PACKAGE_TEMPLATE_START = 'UPDATE_PACKAGE_TEMPLATE_START';
export const UPDATE_PACKAGE_TEMPLATE_SUCCESS = 'UPDATE_PACKAGE_TEMPLATE_SUCCESS';
export const UPDATE_PACKAGE_TEMPLATE_FAILED = 'UPDATE_PACKAGE_TEMPLATE_FAILED';
//Product Templates
export const ADD_PRODUCT_TEMPLATE_START = 'ADD_PRODUCT_TEMPLATE_START';
export const ADD_PRODUCT_TEMPLATE_SUCCESS = 'ADD_PRODUCT_TEMPLATE_SUCCESS';
export const ADD_PRODUCT_TEMPLATE_FAILED = 'ADD_PRODUCT_TEMPLATE_FAILED';
export const FETCH_PRODUCT_TEMPLATE_START = 'FETCH_PRODUCT_TEMPLATE_START';
export const FETCH_PRODUCT_TEMPLATE_SUCCESS = 'FETCH_PRODUCT_TEMPLATE_SUCCESS';
export const FETCH_PRODUCT_TEMPLATE_FAILED = 'FETCH_PRODUCT_TEMPLATE_FAILED';
export const REMOVE_PRODUCT_TEMPLATE_START = 'REMOVE_PRODUCT_TEMPLATE_START';
export const REMOVE_PRODUCT_TEMPLATE_SUCCESS = 'REMOVE_PRODUCT_TEMPLATE_SUCCESS';
export const REMOVE_PRODUCT_TEMPLATE_FAILED = 'REMOVE_PRODUCT_TEMPLATE_FAILED';
export const UPDATE_PRODUCT_TEMPLATE_START = 'UPDATE_PRODUCT_TEMPLATE_START';
export const UPDATE_PRODUCT_TEMPLATE_SUCCESS = 'UPDATE_PRODUCT_TEMPLATE_SUCCESS';
export const UPDATE_PRODUCT_TEMPLATE_FAILED = 'UPDATE_PRODUCT_TEMPLATE_FAILED';
//Online Booking
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const ADD_BOOKING_SENDER = 'ADD_BOOKING_SENDER';
export const ADD_BOOKING_RECIPIENT = 'ADD_BOOKING_RECIPIENT';
export const ADD_BOOKING_PACKAGES = 'ADD_BOOKING_PACKAGES';
export const ADD_BOOKING_DESCRIPTION = 'ADD_BOOKING_DESCRIPTION';
export const ADD_BOOKING_REFERENCE_ONE = 'ADD_BOOKING_REFERENCE_ONE';
export const ADD_BOOKING_REFERENCE_TWO = 'ADD_BOOKING_REFERENCE_TWO';
export const ADD_BOOKING_DATE = 'ADD_BOOKING_DATE';
export const ADD_BOOKING_SERVICES = 'ADD_BOOKING_SERVICES';
export const ADD_BOOKING_DELIVERY_METHOD = 'ADD_BOOKING_DELIVERY_METHOD';
export const UPDATE_BOOKING_SERVICE_COST = 'UPDATE_BOOKING_SERVICE_COST';
export const UPDATE_BOOKING_SERVICE_CODE = 'UPDATE_BOOKING_SERVICE_CODE';
export const ADD_BOOKING_ALTERNATE_DELIVERY = 'ADD_BOOKING_ALTERNATE_DELIVERY';
export const ADD_BOOKING_DROP_OFF_LOCATIONS = 'ADD_BOOKING_DROP_OFF_LOCATIONS';
export const ADD_BOOKING_UPS_DROP_OFF_LOCATIONS = 'ADD_BOOKING_UPS_DROP_OFF_LOCATIONS';
export const ADD_BOOKING_DHL_DROP_OFF_LOCATIONS = 'ADD_BOOKING_DHL_DROP_OFF_LOCATIONS';
export const ADD_BOOKING_DELIVERY_REMARK = 'ADD_BOOKING_DELIVERY_REMARK';
export const ADD_BOOKING_CUSTOMS_VALUE = 'ADD_BOOKING_CUSTOMS_VALUE';
export const ADD_BOOKING_DOCUMENTS_ONLY = 'ADD_BOOKING_DOCUMENTS_ONLY';
export const ADD_BOOKING_CUSTOMS_CURRENCY = 'ADD_BOOKING_CUSTOMS_CURRENCY';
export const ADD_BOOKING_SERVICE_SELECTED = 'ADD_BOOKING_SERVICE_SELECTED';
export const ADD_BOOKING_PICKUP_DETAILS = 'ADD_BOOKING_PICKUP_DETAILS';
export const ADD_BOOKING_PICKUP_DETAIL_TIME = 'ADD_BOOKING_PICKUP_DETAIL_TIME';
export const ADD_BOOKING_DOCUMENTS = 'ADD_BOOKING_DOCUMENTS';
export const ADD_BOOKING_INVOICE_TYPE = 'ADD_BOOKING_INVOICE_TYPE';
export const UPDATE_BOOKING_ADDITIONAL_SERVICES = 'UPDATE_BOOKING_ADDITIONAL_SERVICES';
export const UPDATE_BOOKING_ADDITIONAL_SERVICE_OPTIONS =
  'UPDATE_BOOKING_ADDITIONAL_SERVICE_OPTIONS';
export const UPDATE_INVOICE_FORM = 'UPDATE_INVOICE_FORM';
export const ADD_BOOKING_REQUEST_PICKUP = 'ADD_BOOKING_REQUEST_PICKUP';
export const BOOKING_VALIDATION_UPDATE = 'BOOKING_VALIDATION_UPDATE';
export const ADD_BOOKING_RESIDENTIAL_ADDRESS = 'ADD_BOOKING_RESIDENTIAL_ADDRESS';
export const BOOKING_PRINT_FORMAT = 'BOOKING_PRINT_FORMAT';
export const ADD_BOOKING_FROM_RATES = 'ADD_BOOKING_FROM_RATES';
export const UPDATE_PACKAGE_COUNT = 'UPDATE_PACKAGE_COUNT';
export const UPDATE_BILLING_OPTION = 'UPDATE_BILLING_OPTION';
export const SET_MOVEMENT_DIRECTION = 'SET_MOVEMENT_DIRECTION';
export const CLEAR_DELIVERY_DATA = 'CLEAR_DELIVERY_DATA';
export const CLEAR_SERVICE_DATA = 'CLEAR_SERVICE_DATA';
export const CLEAR_PRINT_FORMAT = 'CLEAR_PRINT_FORMAT';
export const CLEAR_PICKUP_DATA = 'CLEAR_PICKUP_DATA';
export const ADD_BOOKING_FROM_HISTORY = 'ADD_BOOKING_FROM_HISTORY';
export const UPDATE_VOEC = 'UPDATE_VOEC';
export const ADD_REQUESTED_DELIVERY_DATE = 'ADD_REQUESTED_DELIVERY_DATE';
export const UPDATE_BOOKING_FIXED_DELIVERY_DATE = 'UPDATE_BOOKING_FIXED_DELIVERY_DATE';
export const SET_FIXED_DELIVERY_DATES = 'SET_FIXED_DELIVERY_DATES';
export const CLEAN_BOOKING = 'CLEAN_BOOKING';
// Online booking status
export const ADD_BOOKING_STATUS = 'ADD_BOOKING_STATUS';
export const REMOVE_BOOKING_STATUS = 'REMOVE_BOOKING_STATUS';
export const RESET_BOOKING_STATUS = 'RESET_BOOKING_STATUS';
export const RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY =
  'RESET_BOOKING_STATUS_EXCEPT_HIGH_PRIORITY';

//Request Booking
export const ADD_REQUEST_BOOKING_START = 'ADD_REQUEST_BOOKING_START';
export const ADD_REQUEST_BOOKING_SUCCESS = 'ADD_REQUEST_BOOKING_SUCCESS';
export const ADD_REQUEST_BOOKING_FAILED = 'ADD_REQUEST_BOOKING_FAILED';
//Packages Details
export const ADD_PACKAGE_ROW = 'ADD_PACKAGE_ROW';
export const REMOVE_PACKAGE_ROW = 'REMOVE_PACKAGE_ROW';
export const UPDATE_PACKAGE_DETAILS = 'UPDATE_PACKAGE_DETAILS';
export const PACKAGES_DETAILS_CLEANUP = 'PACKAGES_DETAILS_CLEANUP';
export const PACKAGES_DETAILS_REDIRECT = 'PACKAGES_DETAILS_REDIRECT';
//Shipment events on first start page visit
export const FETCH_EVENT_TRACKING_NUMBERS_SUCCESS = 'FETCH_EVENT_TRACKING_NUMBERS_SUCCESS';
export const FETCH_EVENT_TRACKING_NUMBERS_START = 'FETCH_EVENT_TRACKING_NUMBERS_START';
export const FETCH_EVENT_TRACKING_NUMBERS_FAILED = 'FETCH_EVENT_TRACKING_NUMBERS_FAILED';
export const EVENT_RESET = 'EVENT_RESET';
//Shipments
export const FETCH_SHIPMENTS_START = 'FETCH_SHIPMENTS_START';
export const FETCH_SHIPMENTS_SUCCESS = 'FETCH_SHIPMENTS_SUCCESS';
export const FETCH_SHIPMENTS_FAILED = 'FETCH_SHIPMENTS_FAILED';
export const REMOVE_SHIPMENT_START = 'REMOVE_SHIPMENT_START';
export const REMOVE_SHIPMENT_SUCCESS = 'REMOVE_SHIPMENT_SUCCESS';
export const REMOVE_SHIPMENT_FAILED = 'REMOVE_SHIPMENT_FAILED';
//Tracking
export const FETCH_TRACKED_SHIPMENTS_START = 'FETCH_TRACKED_SHIPMENTS_START';
export const FETCH_TRACKED_SHIPMENTS_SUCCESS = 'FETCH_TRACKED_SHIPMENTS_SUCCESS';
export const FETCH_TRACKED_SHIPMENTS_FAILED = 'FETCH_TRACKED_SHIPMENTS_FAILED';
//countries
export const FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED';
//Rates
export const FETCH_PAGODA_RATES_START = 'FETCH_PAGODA_RATES_START';
export const FETCH_PAGODA_RATES_SUCCESS = 'FETCH_PAGODA_RATES_SUCCESS';
export const FETCH_PAGODA_RATES_FAILED = 'FETCH_PAGODA_RATES_FAILED';
export const CLEAN_PAGODA_RATES = 'CLEAN_PAGODA_RATES';
//User Upload
export const AVATAR_UPLOAD_START = 'AVATAR_UPLOAD_START';
export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS';
export const AVATAR_UPLOAD_FAILED = 'AVATAR_UPLOAD_FAILED';
//Carriers
export const FETCH_CARRIERS_START = 'FETCH_CARRIERS_START';
export const FETCH_CARRIERS_SUCCESS = 'FETCH_CARRIERS_SUCCESS';
export const FETCH_CARRIERS_FAILED = 'FETCH_CARRIERS_FAILED';
//Notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_MULTIPLE_NOTIFICATIONS = 'ADD_MULTIPLE_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
//Additional Services
export const FETCH_ADDITIONAL_SERVICES_START = 'FETCH_ADDITIONAL_SERVICES_START';
export const FETCH_ADDITIONAL_SERVICES_SUCCESS = 'FETCH_ADDITIONAL_SERVICES_SUCCESS';
export const FETCH_ADDITIONAL_SERVICES_FAILED = 'FETCH_ADDITIONAL_SERVICES_FAILED';
