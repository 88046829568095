import React from 'react';
import { NavLink } from 'react-router-dom'
//Material ui
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles({
  root: {
    width: '72px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#393939'
  },
  navList: {
    padding: 0,
    paddingTop: '64px',
    position: 'fixed'
  },
  buttonRipple: {
    color: '#BBABBB'
  },
  active: {
    boxShadow: 'inset 0 0 8px #00000078',
    backgroundColor: '#BBABBB'
  },
  navLink: {
    height: '100%',
    width: '100%',
    textDecoration: 'none',
    color: '#909090'
  },
  listItem: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  linkItem: {
    padding: '4px 0',
    '&:hover': {
      background: '#523c4f'
    }
  },
  linkContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    padding: '4px 0px',
    '& h3': {
      fontSize: '0.8rem',
      fontWeight: '400',
    }
  }
})

const Navigator = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component='nav' className={classes.navList}>
        {props.categoryItems.map(item => (
          <ListItem key={item.id} button className={classes.listItem} TouchRippleProps={{ classes: { root: classes.buttonRipple } }}>
            <NavLink
              activeStyle={{ color: '#d1c3f0' }}
              className={classes.navLink} to={item.id} ><span className={classes.linkContent}>{item.icon} <h3>{item.name}</h3></span></NavLink>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default Navigator;

