import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ContactIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
  contactButton: {
      minWidth:30,
      padding:'6px 10px',
      transform:'translateY(-12px)'
  }
}));

export default function Contacts(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (contact) => {
    if(contact.name !== undefined){
      const {name, phone, email} = contact;
      props.handleContact(name, phone, email)
    }
    
     setAnchorEl(null); 
  }

  return (
    <div>
      <Button 
        disabled={props.disabled} 
        color="primary" 
        variant="contained" 
        className={classes.contactButton} 
        onClick={handleClick}
      >
        <ContactIcon style={{fontSize:'20'} } />
      </Button>
      {props.contacts?
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.contacts.map((contact, index) => {
            return (<MenuItem key={index} onClick={() => handleClose(contact)}>{contact.name}</MenuItem>)
          })}
        </Menu>:null
      }
    </div>
  );
}