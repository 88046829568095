import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let WisdomLogotype = (props) => {
  const color1 = '#d8bdd4';
  const color2 = '#62485e';
  const color3 = '#a07c9b';
  /* Default
    const color1 = '#f1c2db'; //light pink
    const color2 = '#803f64'; //dark purple
    const color3 = '#e06ca0'; //pink pink */

  return (

    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Lager 1"
        viewBox="0 0 80.54 97.82"
      >
        <path
          fill={color1}
          d="M69.3 32.13L53.06 22.76 45.63 27.07 61.87 36.45 69.3 32.13z"
        ></path>
        <path
          fill={color1}
          d="M69.3 32.13L61.87 36.45 62 36.53 61.95 55.03 69.34 50.74 69.39 32.19 69.3 32.13z"
        ></path>
        <path
          fill={color1}
          d="M54.23 40.89L37.99 31.51 30.53 35.85 46.77 45.22 54.23 40.89z"
        ></path>
        <path
          fill={color1}
          d="M22.9 40.28L14.29 45.28 30.53 54.66 39.13 49.66 22.9 40.28z"
        ></path>
        <path
          fill={color1}
          d="M54.23 40.89L46.77 45.22 46.92 45.31 46.87 63.79 54.26 59.5 54.31 40.93 54.23 40.89z"
        ></path>
        <path
          fill={color1}
          d="M39.13 49.66L30.53 54.66 30.47 73.32 39.18 68.26 39.23 49.71 39.13 49.66z"
        ></path>
        <g style={{ isolation: "isolate" }}>
          <path
            fill={color3}
            d="M73.51 64a37.23 37.23 0 00-.78 6l9.15 5.29 5.62-3.27zM45.38 22.11c1.45.64 2.85 1.47 4.31 2.11V6.65l-5.57 3.26v11.61c.39.21.88.4 1.26.59z"
            transform="translate(-10.46 -.84)"
          ></path>
          <path
            fill={color1}
            d="M60.69 37.14L66.31 33.87 66.26 52.53 60.64 55.79 60.69 37.14z"
          ></path>
          <path
            fill={color2}
            d="M40.77 4.11l-.05 15.34a14.35 14.35 0 003.36 2.07V9.91L82 31.79l-.13 43.54L72.73 70c-.08 1.25-.14 2.51-.2 3.76l12.71 7.34.14-51.27z"
            transform="translate(-10.46 -.84)"
          ></path>
          <path
            fill={color1}
            d="M74.93 29.02L80.55 25.76 80.4 77.03 74.78 80.29 74.93 29.02z"
          ></path>
          <path
            fill={color1}
            d="M30.31 3.27L35.93 0 80.55 25.76 74.93 29.02 30.31 3.27z"
          ></path>
        </g>
        <g style={{ isolation: "isolate" }}>
          <path
            fill={color3}
            d="M57.33 72.1a60.86 60.86 0 00-.81 6l10.28 5.97 5.62-3.26zM34.6 32.86V15.39L29 18.66v11.4a31.84 31.84 0 005.6 2.8z"
            transform="translate(-10.46 -.84)"
          ></path>
          <path
            fill={color2}
            d="M25.68 12.85v14.17a14.13 14.13 0 003.36 3v-11.4l37.88 21.91-.12 43.54-10.28-5.93c-.12 1.22-.22 2.45-.33 3.68l14 8.06.15-51.27z"
            transform="translate(-10.46 -.84)"
          ></path>
          <path
            fill={color1}
            d="M59.84 37.77L65.46 34.5 65.32 85.77 59.7 89.04 59.84 37.77z"
          ></path>
          <path
            fill={color1}
            d="M15.23 12.01L20.85 8.74 65.46 34.5 59.84 37.77 15.23 12.01z"
          ></path>
        </g>
        <path
          fill={color2}
          d="M30.53 54.66L30.47 73.32 14.23 63.94 14.29 45.28 30.53 54.66z"
        ></path>
        <path
          fill={color3}
          d="M3.37 70.13L8.99 66.87 46.88 88.74 41.26 92.01 3.37 70.13z"
        ></path>
        <path
          fill={color3}
          d="M3.5 26.59L9.12 23.33 8.99 66.87 3.37 70.13 3.5 26.59z"
        ></path>
        <path
          fill={color2}
          d="M10.6 21.63l44.62 25.76-.15 51.27L10.46 72.9zm41.12 71.22l.12-43.54L14 27.44 13.83 71l37.89 21.85"
          transform="translate(-10.46 -.84)"
        ></path>
        <path
          fill={color1}
          d="M44.76 46.55L50.38 43.28 50.23 94.55 44.62 97.82 44.76 46.55z"
        ></path>
        <path
          fill={color1}
          d="M0.14 20.79L5.76 17.52 50.38 43.28 44.76 46.55 0.14 20.79z"
        ></path>
      </svg>
    </SvgIcon>
  );
}

WisdomLogotype = pure(WisdomLogotype);
WisdomLogotype.displayName = 'WisdomLogotype';
WisdomLogotype.muiName = 'SvgIcon';

export default WisdomLogotype;