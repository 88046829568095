import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let Pickup = (props) => {

	return (
		<SvgIcon {...props}>
	    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.89 12.07"
    >
      <path
        d="M13.22 5.51V4.7a.92.92 0 00-.91-.92H3.6a.92.92 0 00-.92.92v1h-2a.56.56 0 100 1.11h2v3.29h-.83a.56.56 0 100 1.12h.83v2.27a.92.92 0 00.92.92h1.33a1.91 1.91 0 001.84 1.44 1.89 1.89 0 001.83-1.44h4.53a1.88 1.88 0 003.66 0h.47a1.79 1.79 0 001.79-1.78v-1.58c0-2.9-2.99-5.22-5.83-5.54zm-6.45 9.11a.66.66 0 11.66-.62.66.66 0 01-.66.62zm8.19 0a.66.66 0 11.66-.66.67.67 0 01-.62.66zm3.19-2a.88.88 0 01-.89.88h-.47a1.88 1.88 0 00-3.66 0H8.6a1.89 1.89 0 00-1.83-1.44 1.91 1.91 0 00-1.84 1.44H3.6v-2.28h4.24a.56.56 0 000-1.12H3.57V6.84h3.52a.56.56 0 100-1.11H3.57v-1h8.71v4.41a2.36 2.36 0 002.36 2.36h3.46z"
        transform="translate(-.16 -3.78)"
      ></path>
      <path
        d="M17.47 12.84h.22a.34.34 0 000-.67h-.22a.18.18 0 01-.17-.17.34.34 0 10-.67 0 .85.85 0 00.84.84zM8.59 8h-4a.56.56 0 000 1.12h4a.56.56 0 100-1.12z"
        transform="translate(-.16 -3.78)"
      ></path>
    </svg>
		</SvgIcon>
	);
}

Pickup = pure(Pickup);
Pickup.displayName = 'Pickup';
Pickup.muiName = 'SvgIcon';

export default Pickup
