import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey, lightGreen } from '@material-ui/core/colors';

const useStyles = makeStyles({
  dropOffContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px 40px',
  },
  dropOffLocationList: {
    display: 'grid',
    // placeContent: 'center',
  },
  dropOffLocationListTitle: {
    fontSize: '1.4rem',
    fontWeight: 300,
    marginBottom: '10px',
    paddingBottom: '5px',
    borderBottom: `1px solid ${grey[500]}`,
    color: grey[900],
  },
  dropOffLocationListWrapper: {
    display: 'grid',
    rowGap: '7px',
    width: 'fit-content',
  },
  number: {
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    padding: '2px',
    background: lightGreen[300],
    border: '1px solid black',
    color: 'black',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  dropOffRow: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    alignItems: 'center',
    gap: '4px',
  },
  streetRow: {
    gridColumn: 2,
    color: grey[700],
    fontSize: '0.9rem',
  },
});

export default function DepartureLocationsList({ translations, dropOffLocations }) {
  const classes = useStyles();

  return (
    <div className={classes.dropOffContainer}>
      <p className={classes.dropOffLocationListTitle}>{translations.nearbyDropoffLocations}</p>
      <div className={classes.dropOffLocationList}>
        <div className={classes.dropOffLocationListWrapper}>
          {dropOffLocations.map((dropOff, index) => (
            <div className={classes.dropOffRow} key={dropOff.id}>
              <div className={classes.number}>{parseInt(index) + 1}</div>
              <p>
                {dropOff.name}{' '}
                <span
                  style={{ fontWeight: 'bold' }}
                >{`(${dropOff.distance} ${dropOff.distanceUnit})`}</span>
              </p>
              {'street' in dropOff.address && (
                <p className={classes.streetRow}>{dropOff.address.street}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
