import moment from 'moment';

const getStartAndEndHourFromMoment = () => {
  const now = moment();
  const remainder = 30 - (now.minute() % 30);
  const startTime = moment(now).add(remainder, 'minutes');
  let endTime = moment('16:00', 'HH:mm');

  //check for three hour span
  const limit = moment(startTime.clone().add(3, 'hours')).isAfter(endTime);

  if (limit) endTime.add(3, 'hours');

  return {
    startTime,
    endTime,
  };
};

export const initialPickupDate = (initDate) => {
  const todayDate = moment().format('YYYY-MM-DD');
  const limit = `${todayDate}T15:00:00`;
  const sameDate = moment(initDate).isSame(todayDate, 'day');
  const defaultStart = moment('08:00', 'HH:mm');
  const defaultEnd = moment('16:00', 'HH:mm');

  if (sameDate && moment().isAfter(limit)) {
    const dayAfter = moment().add('1', 'day');

    return {
      pickupDate: dayAfter,
      pickupTimeStart: defaultStart,
      pickupTimeEnd: defaultEnd,
    };
  }
  if (sameDate) {
    const { startTime, endTime } = getStartAndEndHourFromMoment();
    return {
      pickupDate: initDate,
      pickupTimeStart: startTime,
      pickupTimeEnd: endTime,
    };
  }

  return {
    pickupDate: initDate,
    pickupTimeStart: defaultStart,
    pickupTimeEnd: defaultEnd,
  };
};
