import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DescriptionAutocomplete = ({
  onChange,
  handleAutocompleteChange,
  defaultValue,
  options,
  onBlur,
  label,
}) => {
  return (
    <>
      <Autocomplete
        id={`auto-complete-description`}
        onChange={(event, newValue) => handleAutocompleteChange(newValue)}
        freeSolo
        defaultValue={defaultValue}
        options={options}
        renderInput={(params) => {
          //NOTE: temporary fix as chrome ignores autoComplete='off'
          const paramsChanged = Object.assign({}, params);
          paramsChanged['inputProps'] = Object.assign({}, params.inputProps);
          paramsChanged['inputProps'].autoComplete = 'off';
          paramsChanged['inputProps'].maxLength = 50;

          return (
            <TextField
              {...paramsChanged}
              autoComplete="new-password"
              label={label}
              style={{ width: '100%', height: '50px' }}
              name="autocomplete-description"
              onBlur={onBlur}
              onChange={onChange}
              margin="dense"
              required
              fullWidth
            />
          );
        }}
      />
    </>
  );
};

export default DescriptionAutocomplete;
