import React from 'react';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    height: '200px',
    borderTop: '1px solid gray',
  },
  reply: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& h2': {
      textAlign: 'center',
      fontWeight: '300',
    },
  },
  uploaded: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: 'purple',
  },
  modalTitle: {
    padding: '8px',
  },
});

const RequestBookingReply = ({ translations, redo, edit }) => {
  const requestBooking = useSelector((state) => state.requestBooking);
  const classes = useStyles();
  let title = translations.requesting;

  if (!requestBooking.loading) {
    if (requestBooking.error) {
      title = translations.requestfailed;
    } else {
      title = translations.requestsent;
    }
  }

  return (
    <>
      <DialogTitle className={classes.modalTitle} id='request-booking-dialog-title'>
        {title}
      </DialogTitle>
      <div className={classes.root}>
        {requestBooking.loading ? (
          <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
            <CircleSpinnerSmall />
          </div>
        ) : (
          <div className={classes.reply}>
            <h2>
              {requestBooking.error ? translations.requestfailed : translations.requestsuccess}
            </h2>
            <p className={classes.uploaded}>
              {requestBooking.uploaded
                ? translations.documentsuploaded
                : translations.nodocumentsuploaded}
            </p>
            {!requestBooking.error && (
              <Button
                style={{ padding: '10px' }}
                color='primary'
                variant='contained'
                onClick={redo}
              >
                {translations.newbooking}
              </Button>
            )}
            {requestBooking.error && (
              <Button
                style={{ padding: '10px' }}
                color='primary'
                variant='contained'
                onClick={edit}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RequestBookingReply;
