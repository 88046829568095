import React, { Fragment, useState } from 'react';
import TrackingEventTimeline from './TrackingEventTimeline';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { getCarrierTrackingDetailsFields } from '../../../../services/tracking/headers';

const useStyles = makeStyles({
  foundPackagesTable: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '5px',
    alignItems: 'start',
    padding: '4px 10px',
  },
  trackingHeader: {
    fontWeight: 500,
  },
  viewHistoryButtonContainer: {
    justifySelf: 'center',
  },
  viewHistoryButton: {
    fontSize: '0.8rem',
    padding: '2px 20px',
    fontWeight: 400,
    backgroundColor: '#9e464c',
    '&:hover': {
      backgroundColor: '#505050',
    },
  },
  morePackagesInfo: {
    display: 'flex',
    padding: '5px 0',
    gridColumn: '1 / span 2',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
  },
  '@media (max-width:1000px)': {
    foundPackagesTable: {
      paddingBottom: '30px',
      fontSize: '0.9rem',
    },
  },
});

export const MobileTrackingDetails = ({ translations, carrier, packages }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const [trackingHistoryIdx, setTrackingHistoryIdx] = useState(null);

  const handleShowTrackingHistory = (idx) => {
    trackingHistoryIdx === idx ? setTrackingHistoryIdx(null) : setTrackingHistoryIdx(idx);
  };

  const trackingDetailResponse = showAll ? packages : [[...packages].shift()];

  const getAdditionalPackagesString = (num) =>
    translations.thereAreNumAdditionalPackages.replace('{num}', num);

  const carrierFields = getCarrierTrackingDetailsFields(translations)[carrier];
  return (
    <div className={classes.foundPackagesTable}>
      {trackingDetailResponse.map((pack, packageIndex) => {
        const selected = trackingHistoryIdx === packageIndex;
        let detailData = carrierFields.values.map((value, detailedIdx) => (
          <Fragment key={`header-$${detailedIdx}`}>
            <p className={classes.trackingHeader}>{carrierFields.headers[detailedIdx]}</p>
            <p>{pack[value]}</p>
          </Fragment>
        ));

        return (
          <Fragment key={`trackingDetails-${packageIndex}`}>
            {detailData}
            <div
              style={{ gridColumn: '1 / span 2' }}
              className={classes.viewHistoryButtonContainer}
            >
              <Button
                style={{ backgroundColor: selected && '#505050' }}
                className={classes.viewHistoryButton}
                variant="contained"
                onClick={() => handleShowTrackingHistory(packageIndex)}
                color="primary"
              >
                <p>
                  <span>{selected ? translations.hide : translations.view}</span>&nbsp;history
                </p>
              </Button>
            </div>
            <div style={{ gridColumn: '1 / -1' }}>
              <Collapse timeout={{ enter: 1000, exit: 500 }} in={selected}>
                <TrackingEventTimeline carrier={carrier} activities={pack.activities} />
              </Collapse>
            </div>
          </Fragment>
        );
      })}
      {!showAll && packages.length > 1 && (
        <div onClick={() => setShowAll(true)} className={classes.morePackagesInfo}>
          <p>{getAdditionalPackagesString(packages.length - 1)}</p>
          <ExpandIcon />
        </div>
      )}
    </div>
  );
};

export default MobileTrackingDetails;
