export const getCarrierTrackingDetailsFields = (translations) => ({
  ups: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  upsscs: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  expeditors: {
    headers: ['Shipment ID', 'Description', 'Description Time', 'From', 'To'],
    values: ['trackingNumber', 'description', 'timestamp', 'origin', 'destination'],
  },
  schenker: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  ceva: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  dhlfreight: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  matkahuolto: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.location,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'location', 'description', 'timestamp'],
  },
  hrx: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'description', 'timestamp'],
  },
  geodis: {
    headers: [
      translations.trackedshipments.trackingnumber,
      translations.trackedshipments.status,
      translations.trackedshipments.moment,
    ],
    values: ['trackingNumber', 'description', 'timestamp'],
  },
});
