import {
  UPS,
  UPSSCS,
  SCHENKER,
  EXPEDITORS,
  MATKAHUOLTO,
  DHLGF,
  DHLFREIGHT,
  CEVA,
  HRX,
  GEODIS,
} from '../../services/types';
import ExpeditorsIcon from '../../assets/Expeditors_International_logo.svg';
import SchenkerIcon from '../../assets/schenker_logotype.svg';
import UPSicon from '../../assets/ups_logotype.svg';
import MatkahuoltoIcon from '../../assets/matkahuolto_logo.svg';
import DHLGlobalForwardingIcon from '../../assets/dhl_global_forwarding_logo.svg';
import DHLFreightIcon from '../../assets/dhl-logotype.svg';
import CEVAIcon from '../../assets/ceva-logotype.svg';
import HrxIcon from '../../assets/hrx-logotype.svg';
import GeodisIcon from '../../assets/geodis-logotype-vertical.svg';
import NotAvailableIcon from '../../assets/pagodaPurple.svg';
// import NotAvailableIcon from '../../assets/help_outline-24px.svg';

export const getCarrierIcon = (carrierAlias) => {
  switch (carrierAlias) {
    case UPS:
      return UPSicon;
    case UPSSCS:
      return UPSicon;
    case SCHENKER:
      return SchenkerIcon;
    case EXPEDITORS:
      return ExpeditorsIcon;
    case MATKAHUOLTO:
      return MatkahuoltoIcon;
    case DHLGF:
      return DHLGlobalForwardingIcon;
    case DHLFREIGHT:
      return DHLFreightIcon;
    case CEVA:
      return CEVAIcon;
    case GEODIS:
      return GeodisIcon;
    case HRX:
      return HrxIcon;
    default:
      return NotAvailableIcon;
  }
};
