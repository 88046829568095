import * as yup from 'yup';
import errors from '../../../translations/errors';
import { productSchema } from './productSchemas';

export const invoiceFormSchema = (lang = 'en') => {
  const products = productSchema(lang);
  return yup.object().shape({
    invoiceNumber: yup
      .string()
      .min(1, errors.invoiceNumber.min[lang])
      .max(35, errors.invoiceNumber.max[lang]),
    reasonForExport: yup.string().required(errors.reasonForExport.required[lang]).min(1).max(20),
    termsOfShipment: yup.string().length(3, errors.termsOfShipment.length[lang]),
    products: yup
      .array()
      .of(products)
      .min(1, errors.products.min[lang])
      .max(50, errors.products.max[lang]),
  });
};
