import { roma, macarons, purplePassion, infographic, dark } from './themes';

export const darkThemes = ['dark', 'purplePassion'];
export const lightThemes = ['macarons', 'roma'];

export const getTheme = {
  roma: roma(),
  macarons: macarons(),
  purplePassion: purplePassion(),
  infographic: infographic(),
  dark: dark(),
};

/* export const getTheme = name => {
  switch (name) {
    case 'roma':roma();
      return roma();
    case 'macarons':
      return macarons();
    case 'purplePassion':
      return purplePassion();
    default:
      return roma();
  }
};
 */
