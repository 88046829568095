import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DOCUMENT_TYPE_INVOICE,
  DOCUMENT_TYPE_CERTIFICATE_OF_ORIGIN,
  DOCUMENT_TYPE_OTHER,
  INVOICE_TYPE_PAPER,
  INVOICE_TYPE_FILE,
  INVOICE_TYPE_FORM,
  UPS,
} from '../../../../services/types';
import {
  addBookingDocuments,
  addBookingInvoiceType,
  addInvoiceFormTemplate,
  updateInvoiceForm,
} from '../../../../store/actions';
import { isImportControl } from '../../../../services/validations/helpers';
//components
import UpsInvoiceForm from '../UpsInvoiceForm/UpsInvoiceForm';
import FileZoneUpload from '../../../FileZoneUpload/FileZoneUpload';
import CustomPaper from '../../../UI/CustomPaper/CustomPaper';
//material ui
import { green } from '@material-ui/core/colors';
import { Checkbox, Switch } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Description as InvoiceFormIcon } from '@material-ui/icons';
import { fetchInvoiceFormTemplates } from '../../../../store/actions/invoiceFormTemplates';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  documentsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '0 10px',
  },
  invoiceFormDisplayContainer: {
    width: '100%',
    height: '111px',
  },
  invoiceFormDisplayBox: {
    width: '100%',
    height: '76px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  invoiceFormItem: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontSize: '0.8rem',
      fontStyle: 'italic',
    },
  },
  fileAttachmentsLabel: {
    fontWeight: 500,
  },
  otherInvoiceOptionsContainer: {
    display: 'flex',
    placeItems: 'center',
    justifyContent: 'space-between',
    transform: 'translateY(-15px)',
  },
  otherInvoiceOption: {
    justifySelf: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  invoiceFormContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > hr': {
      boxShadow: '0px 1px 1px #dcdcdc',
    },
    '& > h2': {
      fontSize: '1.1rem',
      color: theme.palette.primary.main,
      fontWeight: '400',
    },
    '& > h3': {
      fontSize: '1rem',
      marginTop: '5px',
      color: theme.palette.primary.main,
      fontWeight: '400',
    },
  },
  addFilesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  documentsSelectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  documentGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileAttachments: {
    marginBottom: '10px',
  },
  brownCheckbox: {
    color: '#7f604b',
  },
  [theme.breakpoints.down('xs')]: {
    documentsContainer: {
      alignItems: 'center',
    },
    documentsSelectionContainer: {
      width: 'fit-content',
      paddingTop: '10px',
      fontSize: '0.9rem',
      flexDirection: 'column',
    },
    otherInvoiceOptionsContainer: {
      width: '90vw',
      display: 'flex',
      justifyContent: 'space-between',
      transform: 'translateY(-14px)',
      fontSize: '0.9rem',
    },
  },
}));

const GreenSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: green[600],
    },
    '&$checked + $track': {
      backgroundColor: green[600],
    },
  },
  checked: {},
  track: {},
})(Switch);

const InvoiceAndPickup = (props) => {
  const bookingData = useSelector((state) => state.booking);
  const { companyCountryCode } = useSelector((state) => state.user);
  //Documents states
  const [selectedOptions, setSelectedOptions] = useState({
    invoice: false,
  });
  const [documents, setDocuments] = useState({});
  const [importControl, setImportControl] = useState(false);

  const { invoiceFormTemplates } = useSelector((state) => state.invoiceFormTemplates);

  //refs for dispatching to redux
  const documentsRef = useRef();
  documentsRef.current = documents;

  const documentOptions = [
    { label: props.translations.onlinebooking.ci, type: DOCUMENT_TYPE_INVOICE },
    { label: props.translations.onlinebooking.co, type: DOCUMENT_TYPE_CERTIFICATE_OF_ORIGIN },
    { label: props.translations.onlinebooking.od, type: DOCUMENT_TYPE_OTHER },
  ];

  const invoiceFormExists = Object.keys(bookingData.invoiceForm).length > 0;

  // Check if invoice form templates exist
  useEffect(() => {
    // Check if invoiceFormTemplates is null
    if (invoiceFormTemplates === null) {
      dispatch(fetchInvoiceFormTemplates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check for import control, document upload is unavailable
  useEffect(() => {
    if (bookingData.documentsOnly) dispatch(addBookingInvoiceType(INVOICE_TYPE_PAPER));

    if (
      bookingData.carrier === UPS &&
      isImportControl(
        bookingData.sender.countryCode,
        bookingData.recipient.countryCode,
        companyCountryCode
      )
    ) {
      setImportControl(true);
      if (bookingData.invoiceType !== INVOICE_TYPE_PAPER)
        dispatch(addBookingInvoiceType(INVOICE_TYPE_PAPER));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updatedSelectedOptions = { ...selectedOptions };

    if (selectedOptions.invoice === false) {
      updatedSelectedOptions.invoice = true;
      if (bookingData.carrier === UPS)
        updatedSelectedOptions = { ...updatedSelectedOptions, coo: false, other: false };
      setSelectedOptions(updatedSelectedOptions);
    }

    //Check if files exist in redux store
    if (Object.keys(bookingData.documents).some((key) => bookingData.documents[key].length > 0)) {
      for (let key in bookingData.documents) {
        updatedSelectedOptions[key] = true;
      }

      setSelectedOptions(updatedSelectedOptions);
      setDocuments(bookingData.documents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleAddFile = (type) => (fileItems) => {
    setDocuments({ ...documents, [type]: fileItems });
    dispatch(addBookingDocuments(documentsRef.current));
  };

  const handleAddInvoiceForm = ({ invoiceForm, invoiceFormTemplateName }) => {
    // If invoice form name is not empty, then save it through dispatch
    if (invoiceFormTemplateName) {
      const newInvoiceForm = {
        ...invoiceForm,
        templateName: invoiceFormTemplateName,
      };
      if (Number.isInteger(Number(newInvoiceForm.invoiceNumber))) {
        newInvoiceForm.invoiceNumber = Number(newInvoiceForm.invoiceNumber);
      } else {
        return;
      }

      // Validate template name at least have 3 characters
      if (invoiceFormTemplateName.length < 3) {
        return;
      }
      dispatch(addInvoiceFormTemplate(newInvoiceForm));
    }
    dispatch(updateInvoiceForm({ invoiceForm }));
  };

  const handleCancelInvoiceForm = () => {
    dispatch(addBookingInvoiceType(INVOICE_TYPE_FILE));
  };

  const handleOptionSelected = (option) => {
    //CLEANUP: Remove files when unchecking checkbox when document type associated files exist
    if (selectedOptions[option] === true && option in documents && documents[option].length > 0)
      documents[option].length = 0;

    setSelectedOptions({ ...selectedOptions, [option]: !selectedOptions[option] });
  };

  const handleInvoiceTypeChange = (e) => {
    const { checked, name } = e.target;
    const type = checked ? name : INVOICE_TYPE_FILE;

    //Check if invoice form is unchecked and a form has previously been added - then clean invoice form data
    if (INVOICE_TYPE_FILE && invoiceFormExists) dispatch(updateInvoiceForm({ invoiceForm: {} }));

    dispatch(addBookingInvoiceType(type));
  };

  const classes = useStyles();
  return (
    <CustomPaper title={props.translations.onlinebooking.documentattachments}>
      <div className={classes.documentsContainer}>
        <div className={classes.documentsSelectionContainer}>
          {documentOptions
            .filter((docOption) => Object.keys(selectedOptions).includes(docOption.type))
            .map((docOption) => (
              <div key={docOption.type}>
                <Checkbox
                  name={docOption.type}
                  id={docOption.type}
                  disabled={importControl}
                  checked={selectedOptions[docOption.type]}
                  onChange={() => handleOptionSelected(docOption.type)}
                />
                <label htmlFor={docOption.type}>{docOption.label}</label>
              </div>
            ))}
        </div>
        <div className={classes.addFilesContainer}>
          {documentOptions.map(
            (docOption) =>
              selectedOptions[docOption.type] && (
                <div className={classes.documentGroup} key={docOption.label}>
                  {!(
                    docOption.type === DOCUMENT_TYPE_INVOICE &&
                    bookingData.invoiceType !== INVOICE_TYPE_FILE
                  ) && (
                    <div className={classes.fileAttachments}>
                      <label className={classes.fileAttachmentsLabel} htmlFor={docOption.type}>
                        {docOption.label} - {props.translations.onlinebooking.attachments}
                      </label>
                      <FileZoneUpload
                        width="100%"
                        files={
                          docOption.type in documents
                            ? documents[docOption.type].map((file) => file)
                            : null
                        }
                        updateFiles={handleAddFile(docOption.type)}
                      />
                    </div>
                  )}
                  {docOption.type === DOCUMENT_TYPE_INVOICE &&
                    bookingData.invoiceType === INVOICE_TYPE_FORM && (
                      <div className={classes.invoiceFormDisplayContainer}>
                        <label className={classes.fileAttachmentsLabel} htmlFor="invoice-form-box">
                          {props.translations.invoiceForm.generatedInvoices}
                        </label>
                        <div id="invoice-form-box" className={classes.invoiceFormDisplayBox}>
                          {/* TODO: When possible to generate multiple forms, map array of invoices instead */}
                          {invoiceFormExists && (
                            <div className={classes.invoiceFormItem}>
                              <InvoiceFormIcon />
                              <p>#{bookingData.invoiceForm.invoiceNumber}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {docOption.type === DOCUMENT_TYPE_INVOICE && bookingData.carrier === UPS && (
                    <div className={classes.otherInvoiceOptionsContainer}>
                      <div className={classes.generateInvoiceButtonContainer}>
                        <div className={classes.otherInvoiceOption}>
                          <GreenSwitch
                            disabled={importControl}
                            disableRipple
                            name={INVOICE_TYPE_FORM}
                            id={INVOICE_TYPE_FORM}
                            onChange={handleInvoiceTypeChange}
                            checked={bookingData.invoiceType === INVOICE_TYPE_FORM}
                          />
                          <label htmlFor={INVOICE_TYPE_FORM}>
                            {props.translations.invoiceForm.generateInvoice}
                          </label>
                        </div>
                      </div>
                      <div className={classes.otherInvoiceOption}>
                        <Checkbox
                          classes={{
                            root: classes.brownCheckbox,
                          }}
                          disabled={importControl}
                          color="default"
                          name={INVOICE_TYPE_PAPER}
                          id={INVOICE_TYPE_PAPER}
                          checked={bookingData.invoiceType === INVOICE_TYPE_PAPER}
                          onChange={handleInvoiceTypeChange}
                        />
                        <label htmlFor={INVOICE_TYPE_PAPER}>
                          {props.translations.onlinebooking.placeinvoices}
                        </label>
                      </div>
                    </div>
                  )}
                  {docOption.type === DOCUMENT_TYPE_INVOICE &&
                    bookingData.invoiceType === INVOICE_TYPE_FORM &&
                    !invoiceFormExists && (
                      <div className={classes.invoiceFormContainer}>
                        <h2>{props.translations.invoiceForm.invoiceForm}</h2>
                        <hr />
                        <h3>{props.translations.invoiceForm.invoiceDetails}</h3>
                        <UpsInvoiceForm
                          bookingCurrency={bookingData.customsCurrency}
                          handleAddInvoiceForm={handleAddInvoiceForm}
                          handleCancelInvoiceForm={handleCancelInvoiceForm}
                          actionTitle={props.translations.invoiceForm.generateInvoice}
                        />
                      </div>
                    )}
                </div>
              )
          )}
        </div>
      </div>
    </CustomPaper>
  );
};

export default InvoiceAndPickup;
