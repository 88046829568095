import axios from 'axios';

//instantly downloads document
export const downloadDocument = (key, filename) => async (dispatch) => {
  await axios({ url: '/api/documents/download', params: { key, filename }, method: 'GET' }).then(
    (response) => {
      const { url } = response.data;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  );
};
