import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/dhl-logotype.svg';
import translations from '../../../../../services/translations/translations';
import { useSelector } from 'react-redux';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';
import { buildDhlFreightPackagesFromTracking } from '../../TrackingResponseBuilder';
import TrackingMap from '../../../../GeoMaps/TrackingMap/TrackingMap';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px 40px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& h2': {
      fontSize: '1.1rem',
      borderBottom: '1px solid',
      color: '#770000',
      fontWeight: '500',
      marginBottom: '3px',
    },
  },
  responseHeaderDetails: {
    '& p': {
      padding: '2px 0',
      fontWeight: 500,
      '& span': {
        fontWeight: 400,
      },
    },
  },
  '@media (max-width:930px)': {
    responseHeader: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

const initialState = {
  shipToAddress: null,
  shipFromAddress: null,
  shipmentWeight: null,
  shipmentWeightUnit: null,
  serviceName: null,
  reference: null,
  delivery: null,
};

export default function DhlFreightTrackingView({ trackingState }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [state, setState] = useState(initialState);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (trackingState.result) {
      setPackages(() =>
        buildDhlFreightPackagesFromTracking(trackingState.result.trackedPackagesResult, lang)
      );

      setState({
        shipToAddress: trackingState.result.shipToAddress,
        shipFromAddress: trackingState.result.shipFromAddress,
        shipmentWeight: trackingState.result.shipmentWeight,
        shipmentWeightUnit: trackingState.result.shipmentWeightUnit,
        serviceName: trackingState.result.serviceName,
        delivery: trackingState.result.delivery,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);

  const classes = useStyles();
  return (
    <>
      <div className={classes.carrierHeader}>
        <p>{state.serviceName}</p>
        <img src={Logotype} alt="DhlFreight Logotype" />
      </div>
      <div className={classes.responseHeader}>
        {state.shipmentWeight && (
          <div>
            <h2>{translations[lang].shipmentDetails}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].weight}:{' '}
                <span>{` ${state.shipmentWeight} ${state.shipmentWeightUnit}`}</span>
              </p>
              <p>
                {translations[lang].service} <span>{state.serviceName}</span>
              </p>
            </div>
          </div>
        )}
        {state.shipFromAddress && (
          <div>
            <h2>{translations[lang].onlinebooking.shipfrom}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].location}: <span>{state.shipFromAddress.city}</span>
              </p>
              <p>
                {translations[lang].country}: <span>{state.shipFromAddress.country}</span>
              </p>
            </div>
          </div>
        )}
        {state.shipFromAddress && (
          <div>
            <h2>{translations[lang].deliveryTo}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].location}: <span>{state.shipToAddress.city}</span>
              </p>
              <p>
                {translations[lang].country}: <span>{state.shipToAddress.country}</span>
              </p>
            </div>
          </div>
        )}
      </div>
      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
      <TrackingMap
        carrier={trackingState.carrier}
        mapData={trackingState.result.trackedPackagesResult}
      />
    </>
  );
}
