import * as actionTypes from '../actions/actionTypes';

const initialState = {
  fetched: false,
  loading: false,
  error: null,
  defaultSender: null,
  defaultRecipient: null,
  defaultCustomsValue: null,
  defaultCustomsCurrency: null,
  useDefaultSender: false,
  useDefaultRecipient: false,
  productsFilteredColumns: [],
  invoiceFormPreset: {
    invoiceNumber: '',
    reasonForExport: '',
    termsOfShipment: '',
  },
  senderEmailNotifications: {
    ship: false,
    delivery: false,
    exception: false,
  },
  recipientEmailNotifications: {
    ship: false,
    delivery: false,
    exception: false,
  },
  voecNumber: '',
  autoIncrementInvoiceNumber: false,
  companyAutocomplete: true,
  defaultDescription: null,
  descriptions: [],
  upsPrintFormat: null,
  dhlfreightPrintFormat: null,
  matkahuoltoPrintFormat: null,
  updateInvoiceFormPresetLoading: false,
  updateInvoiceFormPresetError: null,
  updateNotificationsPresetLoading: false,
  updateNotificationsPresetError: null,
  useDefaultRecipientLoading: false,
  useDefaultRecipientError: null,
  useDefaultSenderLoading: false,
  useDefaultSenderError: null,
  defaultLanguageError: null,
  defaultLanguageLoading: false,
  defaultCustomsValueError: null,
  defaultCustomsValueLoading: false,
  defaultCustomsCurrencyError: null,
  defaultCustomsCurrencyLoading: false,
  companyAutocompleteError: null,
  companyAutocompleteLoading: false,
  descriptionError: null,
  descriptionLoading: false,
  upsPrintFormatError: null,
  upsPrintFormatLoading: false,
  dhlfreightPrintFormatError: null,
  dhlfreightPrintFormatLoading: false,
  matkahuoltoPrintFormatError: null,
  matkahuoltoPrintFormatLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //PRINT FORMATS
    case actionTypes.UPDATE_UPS_PRINT_FORMAT_START:
      return {
        ...state,
        upsPrintFormatError: null,
        upsPrintFormatLoading: true,
      };
    case actionTypes.UPDATE_UPS_PRINT_FORMAT_FAILED:
      return {
        ...state,
        upsPrintFormatLoading: false,
        upsPrintFormatError: action.error,
      };
    case actionTypes.UPDATE_UPS_PRINT_FORMAT_SUCCESS:
      return {
        ...state,
        upsPrintFormatLoading: false,
        upsPrintFormatError: null,
        upsPrintFormat: action.payload,
      };
    case actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_START:
      return {
        ...state,
        dhlfreightPrintFormatError: null,
        dhlfreightPrintFormatLoading: true,
      };
    case actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_FAILED:
      return {
        ...state,
        dhlfreightPrintFormatLoading: false,
        dhlfreightPrintFormatError: action.error,
      };
    case actionTypes.UPDATE_DHLFREIGHT_PRINT_FORMAT_SUCCESS:
      return {
        ...state,
        dhlfreightPrintFormatLoading: false,
        dhlfreightPrintFormatError: null,
        dhlfreightPrintFormat: action.payload,
      };
    case actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_START:
      return {
        ...state,
        matkahuoltoPrintFormatError: null,
        matkahuoltoPrintFormatLoading: true,
      };
    case actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_FAILED:
      return {
        ...state,
        matkahuoltoPrintFormatLoading: false,
        matkahuoltoPrintFormatError: action.error,
      };
    case actionTypes.UPDATE_MATKAHUOLTO_PRINT_FORMAT_SUCCESS:
      return {
        ...state,
        matkahuoltoPrintFormatLoading: false,
        matkahuoltoPrintFormatError: null,
        matkahuoltoPrintFormat: action.payload,
      };
    //FETCH TEMPLATES
    case actionTypes.FETCH_BOOKING_PRESET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: null,
        fetched: true,
        defaultSender: action.payload.defaultSenderId || null,
        defaultRecipient: action.payload.defaultRecipientId || null,
        defaultCustomsValue: action.payload.defaultCustomsValue || null,
        defaultCustomsCurrency: action.payload.defaultCustomsCurrency || null,
        useDefaultSender: action.payload.useDefaultSender || true,
        useDefaultRecipient: action.payload.useDefaultRecipient || false,
        invoiceFormPreset: action.payload.invoiceFormPreset || {
          invoiceNumber: '',
          reasonForExport: '',
          termsOfShipment: '',
        },
        senderEmailNotifications: action.payload.senderEmailNotifications || {
          ship: false,
          delivery: false,
          exception: false,
        },
        recipientEmailNotifications: action.payload.recipientEmailNotifications || {
          ship: false,
          delivery: false,
          exception: false,
        },
        productsFilteredColumns: action.payload.productsFilteredColumns || [],
        autoIncrementInvoiceNumber: action.payload.autoIncrementInvoiceNumber || false,
        companyAutocomplete: action.payload.companyAutocomplete || true,
        voecNumber: action.payload.voecNumber || '',
        defaultDescription: action.payload.defaultDescription || null,
        descriptions: action.payload.descriptions || '',
        dhlfreightPrintFormat: action.payload.dhlfreightPrintFormat || null,
        upsPrintFormat: action.payload.upsPrintFormat || null,
        matkahuoltoPrintFormat: action.payload.matkahuoltoPrintFormat || null,
      };
    //CURRENCY
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_START:
      return {
        ...state,
        defaultCustomsCurrencyError: null,
        defaultCustomsCurrencyLoading: true,
      };
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_FAILED:
      return {
        ...state,
        defaultCustomsCurrencyLoading: false,
        defaultCustomsCurrencyError: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_CURRENCY_SUCCESS:
      return {
        ...state,
        defaultCustomsCurrencyLoading: false,
        defaultCustomsCurrencyError: null,
        defaultCustomsCurrency: action.currency,
      };
    //CUSTOMS VALUE
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_START:
      return {
        ...state,
        defaultCustomsValueError: null,
        defaultCustomsValueLoading: true,
      };
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_FAILED:
      return {
        ...state,
        defaultCustomsValueLoading: false,
        defaultCustomsValueError: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_CUSTOMS_VALUE_SUCCESS:
      return {
        ...state,
        defaultCustomsValueLoading: false,
        defaultCustomsValueError: null,
        defaultCustomsValue: action.payload,
      };
    //SENDER
    case actionTypes.UPDATE_DEFAULT_SENDER_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.UPDATE_DEFAULT_SENDER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_SENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        defaultSender: action.defaultId,
      };
    case actionTypes.USE_DEFAULT_SENDER_START:
      return {
        ...state,
        useDefaultSenderLoading: true,
      };

    case actionTypes.USE_DEFAULT_SENDER_FAILED:
      return {
        ...state,
        useDefaultSenderError: action.error,
        useDefaultSenderLoading: false,
      };

    case actionTypes.USE_DEFAULT_SENDER_SUCCESS:
      return {
        ...state,
        useDefaultSender: action.isUse,
        useDefaultSenderError: null,
        useDefaultSenderLoading: false,
      };
    //RECIPIENT
    case actionTypes.UPDATE_DEFAULT_RECIPIENT_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.UPDATE_DEFAULT_RECIPIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.UPDATE_DEFAULT_RECIPIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        defaultRecipient: action.defaultId,
      };
    case actionTypes.USE_DEFAULT_RECIPIENT_START:
      return {
        ...state,
        useDefaultRecipientLoading: true,
      };
    case actionTypes.USE_DEFAULT_RECIPIENT_FAILED:
      return {
        ...state,
        useDefaultRecipientError: action.error,
        useDefaultRecipientLoading: false,
      };
    case actionTypes.USE_DEFAULT_RECIPIENT_SUCCESS:
      return {
        ...state,
        useDefaultRecipient: action.isUse,
        useDefaultRecipientError: null,
        useDefaultRecipientLoading: false,
      };
    //INVOICE FORM PRESET
    case actionTypes.UPDATE_INVOICE_FORM_PRESET_START:
      return {
        ...state,
        updateInvoiceFormPresetError: null,
        updateInvoiceFormPresetLoading: true,
      };
    case actionTypes.UPDATE_INVOICE_FORM_PRESET_FAILED:
      return {
        ...state,
        updateInvoiceFormPresetLoading: false,
        updateInvoiceFormPresetError: action.error,
      };
    case actionTypes.UPDATE_INVOICE_FORM_PRESET_SUCCESS:
      return {
        ...state,
        updateInvoiceFormPresetError: null,
        updateInvoiceFormPresetLoading: false,
        invoiceFormPreset: action.payload.invoiceFormPreset,
        productsFilteredColumns: action.payload.productsFilteredColumns,
        voecNumber: action.payload.voecNumber,
      };
    //NOTIFICATIONS PRESET
    case actionTypes.UPDATE_NOTIFICATIONS_PRESET_START:
      return {
        ...state,
        updateNotificationsPresetError: null,
        updateNotificationsPresetLoading: true,
      };
    case actionTypes.UPDATE_NOTIFICATIONS_PRESET_FAILED:
      return {
        ...state,
        updateNotificationsPresetLoading: false,
        updateNotificationsPresetError: action.error,
      };
    case actionTypes.UPDATE_NOTIFICATIONS_PRESET_SUCCESS:
      return {
        ...state,
        updateNotificationsPresetError: null,
        updateNotificationsPresetLoading: false,
        senderEmailNotifications: action.payload.senderEmailNotifications,
        recipientEmailNotifications: action.payload.recipientEmailNotifications,
      };
    //SET INVOICE AUTO INCREMENT BOOL
    case actionTypes.UPDATE_AUTO_INCREMENT_INVOICE_NUMBER:
      return {
        ...state,
        autoIncrementInvoiceNumber: action.payload,
      };
    //SET COMPANY AUTOCOMPLETE - ACTIVATE/DEACTIVATE
    case actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_START:
      return {
        ...state,
        companyAutocompleteLoading: true,
      };

    case actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_FAILED:
      return {
        ...state,
        companyAutocompleteError: action.error,
        companyAutocompleteLoading: false,
      };

    case actionTypes.UPDATE_COMPANY_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        companyAutocomplete: action.isUse,
        companyAutocompleteError: null,
        companyAutocompleteLoading: false,
      };
    //UPDATE INVOICE NUMBER IN INVOICE FORM TEMPLATE
    case actionTypes.UPDATE_INVOICE_NUMBER:
      const updatedInvoiceFormPreset = Object.assign({}, state.invoiceFormPreset);

      //TODO: Error handling is needed
      updatedInvoiceFormPreset.invoiceNumber = action.payload;
      return {
        ...state,
        invoiceFormPreset: updatedInvoiceFormPreset,
      };
    /**
     * Description
     * Handles the add/update of description

     */
    case actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_START:
      return {
        ...state,
        descriptionError: null,
        descriptionLoading: true,
      };
    case actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_FAILED:
      return {
        ...state,
        descriptionError: action.error,
        descriptionLoading: false,
      };
    case actionTypes.UPDATE_BOOKING_PRESET_DESCRIPTION_SUCCESS:
      return {
        ...state,
        defaultDescription: action.defaultDescription,
        descriptions: action.descriptions,
        descriptionError: null,
        descriptionLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
