import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { green, grey } from '@material-ui/core/colors';
import { Badge, Button, Tooltip } from '@material-ui/core';
import ContentIcon from '@material-ui/icons/More';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import translations from '../../../services/translations/translations';

const useStyles = makeStyles({
  button: {
    width: '20px',
    fontSize: '0.9rem',
    minWidth: '30px',
    height: '30px',
    backgroundColor: ({ showContentRow }) => (showContentRow ? grey[400] : grey[300]),
  },
  iconContainer: {
    position: 'absolute',
    top: 2,
    left: 7,
  },
  badge: {
    backgroundColor: green[500],
  },
  icon: {
    fontSize: 18,
    color: grey[600],
  },
  arrow: {
    fontSize: 18,
    position: 'absolute',
    bottom: -5,
    right: 3,
    transform: ({ showContentRow }) => (showContentRow ? 'rotate(180deg)' : null),
    color: grey[600],
  },
});

const PackageSpecificReferenceButton = ({ showContentRow, setShowContentRow, inputs }) => {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const classes = useStyles({ showContentRow });
  return (
    <Tooltip
      title={
        showContentRow
          ? translations[lang].packagedetails.hidePackageSpecificReference
          : translations[lang].packagedetails.showPackageSpecificReference
      }
    >
      <Button
        onClick={() => setShowContentRow(!showContentRow)}
        variant='contained'
        className={classes.button}
      >
        <div className={classes.iconContainer}>
          <Badge
            classes={{ badge: classes.badge }}
            variant='dot'
            invisible={inputs.reference.length === 0}
          >
            <ContentIcon className={classes.icon} />
          </Badge>
        </div>
        <ArrowDropDownIcon className={classes.arrow} />
      </Button>
    </Tooltip>
  );
};

export default PackageSpecificReferenceButton;
