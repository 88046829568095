import * as actionTypes from '../actions/actionTypes';

const initialState = {
  rowsPerPage: 10,
  page: 0,
  searchText: '',
  filterList: [[], [], [], [], [], [], [], [], [], []],
  viewColumnsList: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MY_SHIPMENTS_PROPS:
      return {
        ...state,
        ...action.props,
      };
    case actionTypes.EVENT_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
