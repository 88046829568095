import React, { Fragment, useState } from 'react';
import TrackingEventTimeline from './TrackingEventTimeline';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getCarrierTrackingDetailsFields } from '../../../../services/tracking/headers';

const useStyles = makeStyles((theme) => ({
  foundPackagesTable: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'grid',
    gap: '5px',
    alignItems: 'start',
    padding: '4px 20px',
  },
  trackingHeader: {
    fontWeight: 500,
  },
  viewHistoryButtonContainer: {
    justifySelf: 'center',
  },
  viewHistoryButton: {
    fontSize: '0.8rem',
    padding: '0px 4px',
    fontWeight: 400,
    backgroundColor: '#9e464c',
    '&:hover': {
      backgroundColor: '#505050',
    },
  },
  '@media (max-width:1000px)': {
    foundPackagesTable: {
      paddingBottom: '30px',
      fontSize: '0.9rem',
    },
  },
}));

const DesktopTrackingDetails = ({ translations, carrier, packages }) => {
  const [trackingHistoryIdx, setTrackingHistoryIdx] = useState(null);

  const handleShowTrackingHistory = (idx) => {
    trackingHistoryIdx === idx ? setTrackingHistoryIdx(null) : setTrackingHistoryIdx(idx);
  };

  const carrierFields = getCarrierTrackingDetailsFields(translations)[carrier];

  const classes = useStyles();
  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${carrierFields.headers.length},1fr) min-content`,
      }}
      className={classes.foundPackagesTable}
    >
      {carrierFields.headers.map((title, headerIndex, arr) => (
        <Fragment key={`header-$${headerIndex}`}>
          <p className={classes.trackingHeader}>{title}</p>
          {headerIndex === arr.length - 1 && <p></p>}
        </Fragment>
      ))}

      {/* if shipment contains childs, then map all childs */}
      {Array.isArray(packages) &&
        packages.map((pack, packageIndex) => {
          const selected = trackingHistoryIdx === packageIndex;
          const valueFields = carrierFields.values.map((value, detailedIdx) => {
            return <p key={`packagedetails-${packageIndex}${detailedIdx}`}>{pack[value]}</p>;
          });

          valueFields.push(
            <Fragment key={`buttonContainer-${packageIndex}`}>
              <div className={classes.viewHistoryButtonContainer}>
                <Button
                  style={{ backgroundColor: selected && '#505050' }}
                  className={classes.viewHistoryButton}
                  variant="contained"
                  onClick={() => handleShowTrackingHistory(packageIndex)}
                  color="primary"
                >
                  <p>
                    <span>{selected ? translations.hide : translations.view}</span>&nbsp;
                    {translations.history}
                  </p>
                </Button>
              </div>
              <div key={`trackingDetails-${packageIndex}`} style={{ gridColumn: '1 / -1' }}>
                <Collapse timeout={{ enter: 1000, exit: 500 }} in={selected}>
                  <TrackingEventTimeline carrier={carrier} activities={pack.activities} />
                </Collapse>
              </div>
            </Fragment>
          );

          return valueFields;
        })}
    </div>
  );
};

export default DesktopTrackingDetails;
