import UPSicon from '../../../assets/ups_logotype.svg';
import EnvelopeImage from '../../../assets/packaging/ups_express_envelope.webp';
import PakImage from '../../../assets/packaging/ups_express_pak.webp';
import BoxImage from '../../../assets/packaging/ups_express_box.webp';
import TubeImage from '../../../assets/packaging/ups_express_tube.png';
import FullPalletImage from '../../../assets/packaging/pallet.svg';
import halfPalletImage from '../../../assets/packaging/half_pallet.svg';
import PackageImage from '../../../assets/package.svg';
import {
  OTHER_PACKAGING,
  UPS_EXPRESS_BOX,
  UPS_EXPRESS_ENVELOPE,
  UPS_EXPRESS_PAK,
  UPS_EXPRESS_TUBE,
  FULL_PALLET,
  HALF_PALLET,
} from '../../../services/types';

export const packagingValues = [
  {
    packaging: OTHER_PACKAGING,
    icon: PackageImage,
    image: PackageImage,
    templateName: 'Own Package',
    translationId: 'otherPackaging',
    length: '',
    width: '',
    height: '',
    weight: '',
  },
  {
    packaging: FULL_PALLET,
    icon: FullPalletImage,
    image: FullPalletImage,
    templateName: 'Full Pallet',
    translationId: 'fullPallet',
    length: '120',
    width: '80',
    height: '',
    weight: '',
  },
  {
    packaging: HALF_PALLET,
    icon: halfPalletImage,
    image: halfPalletImage,
    templateName: 'Half Pallet',
    translationId: 'halfPallet',
    length: '60',
    width: '80',
    height: '',
    weight: '',
  },
  {
    packaging: UPS_EXPRESS_ENVELOPE,
    icon: UPSicon,
    image: EnvelopeImage,
    templateName: 'UPS Envelope',
    translationId: 'upsEnvelope',
    length: '34',
    width: '25',
    height: '1',
    weight: '',
  },
  {
    packaging: UPS_EXPRESS_PAK,
    icon: UPSicon,
    image: PakImage,
    templateName: 'UPS Express Pak',
    translationId: 'upsExpressPak',
    length: '',
    width: '',
    height: '',
    weight: '',
    // length: '41',
    // width: '32.5',
    // height: '1',
  },
  {
    packaging: UPS_EXPRESS_BOX,
    icon: UPSicon,
    image: BoxImage,
    templateName: 'UPS Express Box',
    translationId: 'upsExpressBox',
    length: '46',
    width: '31.5',
    height: '9.5',
    weight: '',
  },
  {
    packaging: UPS_EXPRESS_TUBE,
    icon: UPSicon,
    image: TubeImage,
    templateName: 'UPS Express Tube',
    translationId: 'upsExpressTube',
    length: '97',
    width: '19',
    height: '16.5',
    weight: '',
  },
];

export const MatkahuoltoHomeDeliveryServices = [10, 20, 30, 34];
