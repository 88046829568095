import * as actionTypes from '../actions/actionTypes';

const initialState = {
  templates: [],
  loading: false,
  addError: null,
  fetchError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD
    case actionTypes.ADD_PACKAGE_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_PACKAGE_TEMPLATE_SUCCESS:
      let updatedAddPackageTemplates = state.templates.slice();
      updatedAddPackageTemplates.splice(state.templates.length - 1, 0, action.payload);
      return {
        ...state,
        templates: updatedAddPackageTemplates,
        loading: false,
      };
    case actionTypes.ADD_PACKAGE_TEMPLATE_FAILED:
      return {
        ...state,
        addError: action.error,
        loading: false,
      };
    //FETCH
    case actionTypes.FETCH_PACKAGE_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PACKAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.packages,
        loading: false,
      };
    case actionTypes.FETCH_PACKAGE_TEMPLATE_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //UPDATE
    case actionTypes.UPDATE_PACKAGE_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_PACKAGE_TEMPLATE_SUCCESS:
      const updatedPackageTemplateIdx = state.templates.findIndex(
        ({ id }) => action.packageData.id === id
      );

      let updatedTemplates = [...state.templates];
      updatedTemplates.splice(updatedPackageTemplateIdx, 1, action.packageData);

      return {
        ...state,
        templates: updatedTemplates,
        loading: false,
      };
    case actionTypes.UPDATE_PACKAGE_TEMPLATE_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //REMOVE
    case actionTypes.REMOVE_PACKAGE_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REMOVE_PACKAGE_TEMPLATE_SUCCESS:
      const removePackageIndex = state.templates.findIndex((template) => {
        return template.id === action.removedId;
      });

      let updatedRemovePackageTemplates = state.templates.slice();
      updatedRemovePackageTemplates.splice(removePackageIndex, 1);

      return {
        ...state,
        templates: updatedRemovePackageTemplates,
        loading: false,
      };
    case actionTypes.REMOVE_PACKAGE_TEMPLATE_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
