import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '../../../UI/CustomToolbar/CustomToolbar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
    color: 'inherit',
    // backgroundColor
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, title, type } = props;
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        title={title}
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.actions}>
          {type === 'company' && (
            <Tooltip
              title={`${props.translations.templates.add} ${
                props.translations.company.substr(0, 1) + props.translations.company.substr(1)
              }`}
            >
              <IconButton onClick={props.changeToAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {type === 'package' && (
            <Tooltip
              title={`${props.translations.templates.add} ${props.translations.templates.package}`}
            >
              <IconButton onClick={props.changeToAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {type === 'product' && (
            <Tooltip title={`Add product`}>
              <IconButton onClick={props.changeToAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EmptyTable extends Component {
  render() {
    const { classes, rows = 5 } = this.props;

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          translations={this.props.translations}
          type={this.props.type}
          title={this.props.title}
          changeToAdd={this.props.changeToAdd}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableBody>
              <TableRow>
                <TableCell padding="none" align="center">
                  {this.props.translations.templates.notemplatesfound}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: `${43 * rows + 57}px` }}>
                <TableCell colSpan={7} />
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}
export default withStyles(styles)(EmptyTable);
