// Reducer for invoice form templates
import {
  ADD_INVOICE_FORM_TEMPLATE_FAIL,
  ADD_INVOICE_FORM_TEMPLATE_START,
  ADD_INVOICE_FORM_TEMPLATE_SUCCESS,
  FETCH_INVOICE_FORM_TEMPLATES_FAIL,
  FETCH_INVOICE_FORM_TEMPLATES_START,
  FETCH_INVOICE_FORM_TEMPLATES_SUCCESS,
  DELETE_INVOICE_FORM_TEMPLATE_FAIL,
  DELETE_INVOICE_FORM_TEMPLATE_START,
  DELETE_INVOICE_FORM_TEMPLATE_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  invoiceFormTemplates: null,
};

const invoiceFormTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVOICE_FORM_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_INVOICE_FORM_TEMPLATE_SUCCESS:
      const updatedInvoiceFormTemplates = Array.isArray(state.invoiceFormTemplates)
        ? [...state.invoiceFormTemplates, action.payload]
        : [action.payload];
      return {
        ...state,
        loading: false,
        invoiceFormTemplates: updatedInvoiceFormTemplates,
      };
    case ADD_INVOICE_FORM_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_INVOICE_FORM_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVOICE_FORM_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceFormTemplates: action.payload,
      };
    case FETCH_INVOICE_FORM_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_INVOICE_FORM_TEMPLATE_START:
      return {
        ...state,
        loading: true,
      };
    case DELETE_INVOICE_FORM_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceFormTemplates: state.invoiceFormTemplates.filter(
          (template) => template._id !== action.payload
        ),
      };
    case DELETE_INVOICE_FORM_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceFormTemplateReducer;
