import * as actionTypes from '../actions/actionTypes';

const initialState = {
	loading: false,
	error: null
}


const AvatarUploadReducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.AVATAR_UPLOAD_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		case actionTypes.AVATAR_UPLOAD_START:
			return {
				...state,
				loading: true,
				error: null,
			}
		default:
			return state;
	}
}

export default AvatarUploadReducer