import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { getMapData } from './helpers/mapData';
import TrackingMapMarker from '../MapMarkers/TrackingMapMarker';
import mapStyles from '../../../services/geo-map/styles/blueWaterStyle';

const TrackingMap = (props) => {
  const [locations, setLocations] = useState([]);

  const center = {
    lat: 30.5,
    lng: 29.2,
  };
  //TODO: Dynamically set senter
  // const [center, setCenter] = useState({
  // 	lat: 30.5,
  // 	lng: 29.2
  // });

  const handleApiLoaded = (map, maps, mapData) => {
    //let addedLocations = [];
    let geocoder = new maps.Geocoder();
    let locs = [];
    mapData.forEach((pack, idx) => {
      pack.location &&
        geocoder.geocode(
          { address: mapData[idx].location },

          function (results, status) {
            if (status === 'OK') {
              locs.push({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                tracking: pack.tracking,
              });

              if (locs.length === mapData.length) {
                setLocations(locs);
              }
              //Center from first package in array
              if (idx === 0) {
                map.setZoom(6);
                map.setCenter(locs[0]);
              }
            } else {
              console.log('Geocode was not successful for the following reason:', status);
            }
          }
        );
    });
  };

  const mapData = getMapData(props.mapData, props.carrier);
  const mapOptions = { styles: mapStyles };
  return (
    <div
      style={{
        height: 'fit-content',
        width: '100%',
        background: 'whitesmoke',
        boxShadow: '0px -1px 4px #969494bf',
      }}
    >
      {mapData ? (
        mapData.some((data) => !data.error) && (
          <div style={{ height: 'calc((100vw * 0.5625)', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAhwPG-cPtsOvGt0b04nIBP5VTvMMIzDuk' }}
              defaultCenter={center}
              defaultZoom={1}
              options={mapOptions}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, mapData)}
            >
              {locations.map((loc) => (
                <TrackingMapMarker
                  key={loc.tracking}
                  lat={loc.lat}
                  lng={loc.lng}
                  text={loc.tracking}
                />
              ))}
            </GoogleMapReact>
          </div>
        )
      ) : (
        <div style={{ textAlign: 'center', color: '#9e464c' }}>
          <p>Unable to trace location</p>
        </div>
      )}
    </div>
  );
};

export default TrackingMap;
