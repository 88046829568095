const b64toBlob = (content, contentType) => {
  contentType = contentType || '';
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
};

export const printPdfDataWithPreview = (data, type = 'application/pdf') => {
  let blob = null;
  blob = b64toBlob(data, type);
  const blobURL = URL.createObjectURL(blob);
  const theWindow = window.open(blobURL);
  const theDoc = theWindow.document;
  const theScript = document.createElement('script');
  function injectThis() {
    window.print();
  }
  theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
  theDoc.body.appendChild(theScript);
};

export const printPdfData = (pdfSrc) => {
  const binary = window.atob(pdfSrc.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([view], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);
  iframe.contentWindow.print();
};
