import React, { Component } from 'react';
import ShipmentHistory from '../../components/MyShipments/ShipmentHistory/ShipmentHistory';

class MyShipments extends Component {

	render() {

		return (
			<ShipmentHistory />
		);
	}
}

export default MyShipments;

