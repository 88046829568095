import React, { useState, useEffect } from 'react';
import EmptyTable from '../TemplateTables/EmptyTable/EmptyTable';
import EnhancedTable from '../TemplateTables/EnhancedTable/EnhancedTable';
import PackageTemplateDetails from './PackageTemplateDetails/PackageTemplateDetails';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const PackageTemplate = (props) => {
  const { templates, loading } = useSelector((state) => state.packageTemplates);
  const { userId } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const [content, setContent] = useState({ type: 'table', selectedId: null }); //enum: 'table','add','edit

  const dispatch = useDispatch();

  useEffect(() => {
    if (templates.length === 0) {
      dispatch(actions.fetchPackageTemplate({ userId, token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = [
    {
      id: 'templateName',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: props.translations.templates.template,
    },
    {
      id: 'length',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: props.translations.packagedetails.length,
    },
    {
      id: 'width',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: props.translations.packagedetails.width,
    },
    {
      id: 'height',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: props.translations.packagedetails.height,
    },
    {
      id: 'weight',
      numeric: false,
      disablePadding: true,
      align: 'left',
      label: props.translations.packagedetails.weight,
    },
  ];

  const changeToEdit = (id) => {
    setContent({ type: 'edit', selectedId: id });
  };

  const handleRemovePackage = (id) => {
    dispatch(actions.removePackageTemplate(id));
  };
  const handleUpdatePackage = (inputs) => {
    if (!content.selectedId) return;
    dispatch(actions.updatePackageTemplate(inputs, content.selectedId, token));
    setContent({ type: 'table', selectedId: null });
  };
  const handleAddPackage = (inputs) => {
    dispatch(actions.addPackageTemplate(inputs, token));
  };

  const serializeTemplate = (templates) => {
    return templates.map(({ id, templateName, weight, width, length, height }) => ({
      id,
      templateName,
      length,
      width,
      height,
      weight,
    }));
  };

  let renderContent;
  switch (content.type) {
    case 'table':
      const toolbarProps = [
        {
          hasSelected: false,
          funcName: 'changeToAdd',
          icon: <AddIcon />,
          toolTip: `${props.translations.templates.add} ${
            props.translations.templates.packagetemplate.substr(0, 1).toLowerCase() +
            props.translations.templates.packagetemplate.substr(1)
          }`,
        },
        {
          hasSelected: true,
          funcName: 'changeToEdit',
          icon: <EditIcon />,
          toolTip: props.translations.templates.change,
        },
        {
          hasSelected: true,
          funcName: 'handleRemovePackage',
          icon: <DeleteIcon />,
          toolTip: props.translations.shipmenthistory.textLabels.delete,
        },
      ];

      const endAdornmentFields = { description: 'cm', width: 'cm', height: 'cm', weight: 'kg' };
      const actions = {
        handleRemovePackage,
        changeToAdd: () => setContent({ type: 'add', selectedId: null }),
        changeToEdit,
      };

      renderContent =
        templates.length === 0 ? (
          <EmptyTable
            translations={props.translations}
            changeToAdd={() => setContent({ type: 'add', selectedId: null })}
            type="package"
            title={props.translations.templates.savedpackagetemplates}
          />
        ) : (
          <EnhancedTable
            rows={serializeTemplate(templates)}
            headCells={headCells}
            sortByKey={'description'}
            title={props.translations.templates.savedpackagetemplates}
            selectedTitle={props.translations.templates.selected}
            endAdornmentFields={endAdornmentFields}
            toolbarProps={toolbarProps}
            {...actions}
          />
        );
      break;
    case 'add':
      renderContent = (
        <PackageTemplateDetails
          translations={props.translations}
          handleAddPackage={handleAddPackage}
          handleChangeToTable={() => setContent({ type: 'table', selectedId: null })}
          type={content.type}
        />
      );
      break;
    case 'edit':
      const template = templates.find(({ id }) => id === content.selectedId);
      renderContent = (
        <PackageTemplateDetails
          translations={props.translations}
          handleChangeToTable={() => setContent({ type: 'table', selectedId: null })}
          packageTemplate={template}
          handleUpdatePackage={handleUpdatePackage}
          type={content.type}
        />
      );
      break;
    default:
      renderContent = (
        <EmptyTable
          translations={props.translations}
          type="package"
          title={props.translations.templates.savedpackagetemplates}
          changeToAdd={() => setContent({ type: 'add', selectedId: null })}
        />
      );
  }

  return <div>{loading ? <CircleSpinnerLarge /> : renderContent}</div>;
};

export default PackageTemplate;
