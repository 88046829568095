import React from 'react';
import { Checkbox, makeStyles, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import translations from '../../../../../services/translations/translations';
import { TermsOfShipmentCodes, ReasonForExportCodes } from '../../../../../services/types';
import translationsCommonCodes from '../../../../../services/translations/translationsCommonCodes';
import translationsTooltips from '../../../../../services/translations/translationsTooltips';
import InfoIcon from '@material-ui/icons/Info';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '628px',
  },
  invoiceDetails: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0 20px',
  },
  invoiceNumber: {
    width: '200px',
    marginBottom: '31px',
  },
  reasonForExport: {
    width: '100%',
  },
  termsOfShipment: {
    width: '100%',
  },
  autoIncrement: {
    position: 'absolute',
    width: '200px',
    left: '0px',
    bottom: '-11px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: grey[100],
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    fontSize: '0.9rem',
  },
  toolTip: {
    padding: '0 10px',
    color: grey[600],
    fontSize: '18px',
  },
  [theme.breakpoints.down('xs')]: {
    invoiceDetails: {
      gridTemplateColumns: '1fr',
      rowGap: '15px',
    },
    invoiceNumber: {
      width: '100%',
    },
    autoIncrement: {
      width: '100%',
      top: '49px',
      fontSize: '0.8rem',
      height: '40px',
    },
  },
}));

const InvoiceSettings = ({
  invoiceDetailsState,
  handleInvoiceDetailsChange,
  showAutoIncrement = false,
  autoIncrement = false,
  setAutoIncrement = null,
  smallCheckboxes = false,
}) => {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.invoiceDetails}>
        <TextField
          required
          inputProps={{ maxLength: 35 }}
          label={translations[lang].invoiceForm.invoiceNumber}
          className={classes.invoiceNumber}
          name="invoiceNumber"
          value={invoiceDetailsState.invoiceNumber}
          onChange={handleInvoiceDetailsChange}
          margin="none"
        />
        <TextField
          select
          required
          inputProps={{ maxLength: 20 }}
          className={classes.reasonForExport}
          name="reasonForExport"
          value={invoiceDetailsState.reasonForExport}
          onChange={handleInvoiceDetailsChange}
          helperText={translations[lang].invoiceForm.reasonForExport}
          margin="dense"
        >
          {ReasonForExportCodes.map((code) => (
            <MenuItem key={code} value={code}>
              {translationsCommonCodes[lang].ReasonForExportCodes[code]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          required
          inputProps={{ maxLength: 20 }}
          className={classes.termsOfShipment}
          name="termsOfShipment"
          value={invoiceDetailsState.termsOfShipment}
          onChange={handleInvoiceDetailsChange}
          helperText={translations[lang].invoiceForm.incoterm}
          margin="dense"
        >
          {TermsOfShipmentCodes.map((code) => (
            <MenuItem key={code} value={code}>
              {`${code} - ${translationsCommonCodes[lang].TermsOfShipmentCodes[code]}`}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {showAutoIncrement && (
        <div className={classes.autoIncrement}>
          {smallCheckboxes ? (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={autoIncrement}
              onClick={() => (setAutoIncrement ? setAutoIncrement(!autoIncrement) : null)}
            />
          ) : (
            <Checkbox checked={autoIncrement} onClick={() => setAutoIncrement(!autoIncrement)} />
          )}
          <label>{translations[lang].autoIncrement}</label>
          <Tooltip title={translationsTooltips[lang].autoIncrementInvoiceNumber}>
            <InfoIcon className={classes.toolTip} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default InvoiceSettings;
