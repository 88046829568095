import React from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoiceForm } from '../../../../../store/actions';
import CustomPaper from '../../../../UI/CustomPaper/CustomPaper';
import HrxIncoTerm from './HrxIncoTerm/HrxIncoTerm';

const HrxAdditionalOptions = ({ translations }) => {
  const dispatch = useDispatch();

  const handleAddTerms = (e) => {
    const invoiceForm = { termsOfShipment: e.target.value };
    dispatch(updateInvoiceForm({ invoiceForm }));
  };

  return (
    <div>
      <CustomPaper title={translations.invoiceForm.incoterm}>
        <HrxIncoTerm translations={translations} termsChange={handleAddTerms} />
      </CustomPaper>
    </div>
  );
};

export default HrxAdditionalOptions;
