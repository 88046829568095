import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import marker from '../../../assets/map-marker.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    transform: 'translateY(-50%)',
    width: 'fit-content',
  },
  marker: {
    width: '150px',
  },
  tracking: {
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    fontWeight: 500,
    fontSize: '0.7rem',
    paddingLeft: '10px',
  },
}));

const TrackingMapMarker = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={marker} alt="marker" className={classes.marker} />
      <p className={classes.tracking}>{text}</p>
    </div>
  );
};

export default TrackingMapMarker;
