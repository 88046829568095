import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let DropOff = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 17.42 20.904'>
        <g transform='translate(-45.768 -116.83)'>
          <path
            d='M630.8 606.04L616.01 614.64 630.8 623.44 645.6 614.64z'
            transform='matrix(.26458 0 0 .26458 -112.474 -39.67)'
          ></path>
          <path
            d='M630.1 625.27L616.01 616.57 616.01 633.66 630.1 642.16z'
            transform='matrix(.26458 0 0 .26458 -112.474 -39.67)'
          ></path>
          <path
            d='M631.9 625.27L646 616.57 646 633.66 631.9 642.16z'
            transform='matrix(.26458 0 0 .26458 -112.474 -39.67)'
          ></path>
          <path
            strokeWidth='0.265'
            d='M62.923 125.54a8.445 8.445 0 10-10.76 8.122l2.315 3.807 2.315-3.807a8.45 8.45 0 006.13-8.123zm-8.445 6.132a6.133 6.133 0 116.133-6.133 6.133 6.133 0 01-6.133 6.133z'
          ></path>
        </g>
      </svg>
    </SvgIcon>
  );
};

DropOff = pure(DropOff);
DropOff.displayName = 'DropOff';
DropOff.muiName = 'SvgIcon';

export default DropOff;
