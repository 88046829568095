import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import FileZoneUpload from '../../../FileZoneUpload/FileZoneUpload';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoiceFormTemplates } from '../../../../store/actions';
import InvoiceFormAutocomplete from '../../../Bookings/Templates/InvoiceFormAutocomplete';
import UpsInvoiceForm from '../../../Bookings/InvoiceAndPickup/UpsInvoiceForm/UpsInvoiceForm';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  DialogContent: {
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    minHeight: '300px',
  },
  label: {
    fontSize: '1rem',
    fontWeight: '500',
    color: 'black',
  },
  invoiceFormTemplate: {
    width: '400px',
  },
  generateNewInvoice: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  templateSelect: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '10px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '50%',
      minWidth: '200px',
    },
  },
}));

export default function FileBookingInvoiceModal({
  open,
  handleAbort,
  translations,
  handleSetInvoiceData,
  invoiceAddShipments,
  deselectAll,
}) {
  const [files, setFiles] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [view, setView] = useState('options'); // ['options', 'create']
  const dispatch = useDispatch();

  const { invoiceFormTemplates, loading } = useSelector((state) => state.invoiceFormTemplates);

  useEffect(() => {
    // Check if invoiceFormTemplates is null
    if (invoiceFormTemplates === null) {
      dispatch(fetchInvoiceFormTemplates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateFiles = (fileItems) => {
    const newFilesArray = fileItems.map((fileItem) => fileItem.file);
    setFiles(newFilesArray);
  };

  const handleSelectTemplate = (template) => {
    if (template === null) {
      setSelectedTemplateId(null);
      return;
    }
    setSelectedTemplateId(template.id);
  };

  const handleUpdateInvoiceData = (invoiceWithIds, type, data) => {
    let newInvoiceData = {};
    switch (type) {
      case 'template-form':
        // Get invoice form from template id
        const invoiceFormData = invoiceFormTemplates.find((template) => template.id === data);
        newInvoiceData = {
          type: 'form',
          invoiceData: invoiceFormData,
          shipmentIds: invoiceWithIds,
        };
        break;
      case 'file':
        // Create invoice file object
        newInvoiceData = { type, invoiceData: data, shipmentIds: invoiceWithIds };
        break;

      //TODO: Implement adding invoice from new form and add case, type should still be 'form'
      case 'new-form':
        newInvoiceData = { type: 'form', invoiceData: data, shipmentIds: invoiceWithIds };
        break;
      default:
        // TODO: Show error message
        handleAbort();
    }
    deselectAll();
    handleSetInvoiceData(newInvoiceData);
    handleAbort();
  };

  // Action to add invoice to shipment
  const handleAddInvoiceToIds = () => {
    if (files.length === 0 && selectedTemplateId === null) {
      return;
    }
    if (files.length > 0 && selectedTemplateId === null) {
      handleUpdateInvoiceData(invoiceAddShipments, 'file', files[0]);
      return;
    }
    if (files.length === 0 && selectedTemplateId !== null) {
      handleUpdateInvoiceData(invoiceAddShipments, 'template-form', selectedTemplateId);
      return;
    }

    return;
  };

  const handleAddNewInvoiceFormToIds = ({ invoiceForm, invoiceFormTemplateName }) => {
    //TODO: validations
    handleUpdateInvoiceData(invoiceAddShipments, 'new-form', invoiceForm);
  };

  const handleCancelInvoiceForm = () => {
    setView('options');
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby="label-recovery-dialog-title"
      aria-describedby="label-recovery-dialog-description"
      fullWidth={true}
      maxWidth={view === 'create' ? 'md' : 'sm'}
    >
      <DialogTitle className={classes.modalTitle} id="label-recovery-dialog-title">
        {translations.addInvoiceToShipment}
      </DialogTitle>
      {view === 'options' && (
        <div className={classes.DialogContent}>
          <div className={classes.fileAttachment}>
            <label className={classes.label} htmlFor="invoice-file-upload">
              {translations.invoiceFile}
            </label>
            <FileZoneUpload
              id="invoice-file-upload"
              title={translations.dragdropbrowse}
              updateFiles={handleUpdateFiles}
              files={files}
              width="400px"
              maxFiles={1}
              disabled={selectedTemplateId ? true : false}
            />
          </div>
          {loading && <div>Loading...</div>}
          {!loading && invoiceFormTemplates && (
            <div className={classes.invoiceFormTemplate}>
              <label className={classes.label} htmlFor="invoice-form-template">
                {translations.onlinebooking.invoiceFormTemplate}
              </label>
              <InvoiceFormAutocomplete
                templateSelected={handleSelectTemplate}
                options={invoiceFormTemplates}
                styles={classes.templateSelect}
                label={translations.categories.templates}
                defaultValue={translations.selectAtemplate}
                disabled={files.length > 0}
                // defaultValue={getTemplateSelectedName()}
              />
            </div>
          )}
          <div className={classes.generateNewInvoice}>
            <label className={classes.label} htmlFor="invoice-file-upload">
              Create new invoice
            </label>
            <Button
              style={{ borderRadius: '15px' }}
              variant="outlined"
              color="primary"
              onClick={() => setView('create')}
            >
              {translations.openInvoiceTemplate}
            </Button>
          </div>
          {/* One button to apply action and one to cancel */}
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={handleAddInvoiceToIds}>
              {translations.addInvoice}
            </Button>
            <Button variant="text" color="secondary" onClick={handleAbort}>
              {translations.cancel}
            </Button>
          </div>
        </div>
      )}
      {view === 'create' && (
        <div className={classes.DialogContent}>
          <UpsInvoiceForm
            bookingCurrency={'SEK'}
            handleAddInvoiceForm={handleAddNewInvoiceFormToIds}
            handleCancelInvoiceForm={handleCancelInvoiceForm}
            actionTitle={translations.addInvoice}
            showAutoIncrement={false}
          />
        </div>
      )}
    </Dialog>
  );
}
