import * as actionTypes from '../actions/actionTypes';

const initialState = {
  shipmentData: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        shipmentData: action.shipments,
        loading: false,
      };
    case actionTypes.FETCH_SHIPMENTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_SHIPMENTS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    //REMOVE
    case actionTypes.REMOVE_SHIPMENT_SUCCESS:
      const removedShipmentIndex = state.shipmentData.findIndex((shipment) => {
        return shipment.id === action.removedId;
      });

      let updatedRemovedShipment = state.shipmentData.slice();
      updatedRemovedShipment.splice(removedShipmentIndex, 1);
      return {
        ...state,
        shipmentData: updatedRemovedShipment,
        loading: false,
      };
    case actionTypes.REMOVE_SHIPMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REMOVE_SHIPMENT_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
