import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CARRIERS, CARRIER_NAMES } from '../../../../services/types';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarriers, updateBookingPresetPrintFormat } from '../../../../store/actions';
import { getCarrierAliasesFromAllCarriers } from '../../../../services/helpers/carriers';
import { SelectStylesMain } from '../../../UI/SelectStyles/SelectStyles';
import Select from 'react-select';
import {
  getPrinterOptionsByCarrier,
  validForCarrierSpecificPrintOptions,
} from '../../../../services/helpers/printerOptions';
import { getCarrierIcon } from '../../../../utils/carrier/carrierIcons';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '300px',
    display: 'grid',
    padding: '10px 20px 20px 20px',
    boxSizing: 'border-box',
    placeItems: 'center',
    gap: '20px',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  carrierPrintOption: {
    display: 'flex',
    flexDirection: 'column',
  },
  carrierLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    borderBottom: `1px dotted ${grey[700]}`,
    marginBottom: '5px',
  },
  carrierLabel: {
    fontWeight: '500',
  },
  printerSelect: {
    width: '300px',
  },
  buttonContainer: {
    display: 'grid',
    rowGap: '10px',
    width: '100%',
    maxWidth: '400px',
    marginTop: '20px',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default function PrintSettingsModal({ translations, bookingPresets, close }) {
  const [carrierPrintOptions, setCarrierPrintOptions] = useState([]);
  const [selectedCarrierPrintOptions, setSelectedCarrierPrintOptions] = useState({});

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { companyId, userId } = useSelector((state) => state.user);
  const carriers = useSelector((state) => state.carriers);
  useEffect(() => {
    if (carriers.allCarriers.length < 1) {
      dispatch(fetchCarriers(token, companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carriers.allCarriers.length > 0) {
      const { onlineCarriers, allCarriers } = carriers;
      let updatedSelectedCarrierPrintOptions = { ...selectedCarrierPrintOptions };

      const onlineBookingCarrierAliases = getCarrierAliasesFromAllCarriers(
        onlineCarriers,
        allCarriers
      );

      if (onlineBookingCarrierAliases.length > 0) {
        let carrierPrintOptions = [];
        onlineBookingCarrierAliases.forEach((carrierAlias) => {
          if (validForCarrierSpecificPrintOptions.includes(carrierAlias)) {
            carrierPrintOptions.push({
              carrierName: CARRIER_NAMES[carrierAlias],
              carrierAlias,
              printOptions: getPrinterOptionsByCarrier(carrierAlias, translations),
            });
            if (carrierAlias === CARRIERS.UPS) {
              updatedSelectedCarrierPrintOptions[carrierAlias] = bookingPresets.upsPrintFormat;
            }
            if (carrierAlias === CARRIERS.DHLFREIGHT) {
              updatedSelectedCarrierPrintOptions[carrierAlias] =
                bookingPresets.dhlfreightPrintFormat;
            }
            if (carrierAlias === CARRIERS.MATKAHUOLTO) {
              updatedSelectedCarrierPrintOptions[carrierAlias] =
                bookingPresets.matkahuoltoPrintFormat;
            }
          }
        });

        setSelectedCarrierPrintOptions(updatedSelectedCarrierPrintOptions);
        setCarrierPrintOptions(carrierPrintOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers.allCarriers]);

  const handlePrintOptionChange = (printFormat, carrier) => {
    setSelectedCarrierPrintOptions({ ...selectedCarrierPrintOptions, [carrier]: printFormat });
    dispatch(updateBookingPresetPrintFormat({ carrier, printFormat, token, userId }));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {carrierPrintOptions.length > 0 &&
          Object.keys(selectedCarrierPrintOptions).length > 0 &&
          carrierPrintOptions.map((c) => (
            <div key={c.carrierAlias} className={classes.carrierPrintOption}>
              <div className={classes.carrierLabelContainer}>
                <div>
                  <img
                    style={{ height: '25px', width: '100%', maxWidth: '50px' }}
                    src={getCarrierIcon(c.carrierAlias)}
                    alt="Logotype"
                  />
                </div>
                <p className={classes.carrierLabel}>{c.carrierName}</p>
              </div>
              <Select
                value={c.printOptions.find(
                  ({ value }) => value === selectedCarrierPrintOptions[c.carrierAlias]
                )}
                name={c.carrierAlias}
                onChange={(e) => handlePrintOptionChange(e.value, c.carrierAlias)}
                options={c.printOptions}
                className={classes.printerSelect}
                styles={SelectStylesMain}
                menuPlacement="auto"
              />
            </div>
          ))}
      </div>
      <p> </p>
      <div className={classes.buttonContainer}>
        <Button className={classes.cancelButton} onClick={close}>
          {translations.templates.cancel}
        </Button>
      </div>
    </div>
  );
}
