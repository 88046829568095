import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  DialogContent: {
    height: '200px',
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  dialogInfo: {
    fontWeight: '400',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#a91e1e',
    '&:hover': {
      backgroundColor: '#bf2525',
    },
  },
  abortbutton: {
    backgroundColor: 'gray',
  },
});

const DeleteShipmentModal = ({ open, handleAbort, translations, handleDelete }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby='remove-shipment-dialog-title'
      aria-describedby='remove-shipment-dialog-description'
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle className={classes.modalTitle} id='remove-shipment-dialog-title'>
        {translations.deleteShipment}
      </DialogTitle>
      <div className={classes.DialogContent}>
        <h1 className={classes.dialogInfo}>{translations.verifyDelete}?</h1>
        <div className={classes.buttonContainer}>
          <Button onClick={handleDelete} className={classes.deleteButton}>
            {translations.deleteShipment}
          </Button>
          <Button onClick={handleAbort} className={classes.abortButton}>
            {translations.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteShipmentModal;
