import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let BatchBooking = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
        <g>
          <g transform="matrix(.46785 0 0 .42926 1.649 2.886)">
            <path d="M20 0.66L16.83 3.7 16.83 18.55 20 15.51z"></path>
            <path d="M15.87 19L15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97 0 19z"></path>
            <path d="M19.31 0L13.36 0 10.21 3.02 16.16 3.02z"></path>
            <path d="M6.68 3.02L9.84 0 3.66 0 0.5 3.02z"></path>
          </g>
          <g transform="matrix(.46785 0 0 .42926 13.115 2.807)">
            <path d="M20 15.51L20 0.66 16.83 3.7 16.83 18.55z"></path>
            <path d="M0 19L15.87 19 15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97z"></path>
            <path d="M16.16 3.02L19.31 0 13.36 0 10.21 3.02z"></path>
            <path d="M0.5 3.02L6.68 3.02 9.84 0 3.66 0z"></path>
          </g>
          <g transform="matrix(.46785 0 0 .42926 1.577 13.173)">
            <path d="M20 0.66L16.83 3.7 16.83 18.55 20 15.51z"></path>
            <path d="M15.87 19L15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97 0 19z"></path>
            <path d="M19.31 0L13.36 0 10.21 3.02 16.16 3.02z"></path>
            <path d="M6.68 3.02L9.84 0 3.66 0 0.5 3.02z"></path>
          </g>
          <g transform="matrix(.46785 0 0 .42926 13.044 13.094)">
            <path d="M20 15.51L20 0.66 16.83 3.7 16.83 18.55z"></path>
            <path d="M0 19L15.87 19 15.87 3.97 9.71 3.97 9.71 8.62 6.18 8.62 6.18 3.97 0 3.97z"></path>
            <path d="M16.16 3.02L19.31 0 13.36 0 10.21 3.02z"></path>
            <path d="M0.5 3.02L6.68 3.02 9.84 0 3.66 0z"></path>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

BatchBooking = pure(BatchBooking);
BatchBooking.displayName = 'BatchBooking';
BatchBooking.muiName = 'SvgIcon';

export default BatchBooking;
