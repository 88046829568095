import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let AirFreight = (props) => {

	return (
		<SvgIcon {...props}>
		 <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Lager 1"
      viewBox="0 0 18.63 11.59"
    >
      <path
        d="M8.55 15.53H6.44a.19.19 0 01-.16-.09.21.21 0 010-.17L8 10.62H2.79l-1.29.83-1.1-.53L1 9.8.28 7.05a.18.18 0 010-.17.2.2 0 01.15-.07L2 6.88a.17.17 0 01.17.12l.77 1.66 5.13-.13L6.16 4.2a.19.19 0 010-.18.21.21 0 01.16-.08H8a.2.2 0 01.14.06l3.73 4.37 4 .1c2.84.19 5.07 2.62.19 2.62l-3.95.05c-1.18 1.44-3.36 4.28-3.38 4.31a.21.21 0 01-.18.08z"
        transform="translate(-.27 -3.94)"
      ></path>
      <path
        d="M4.75 6.31a.29.29 0 000-.58h-.62a.29.29 0 100 .58zM4.87 12.85h-.62a.29.29 0 000 .58h.62a.29.29 0 000-.58z"
        transform="translate(-.27 -3.94)"
      ></path>
    </svg>
		</SvgIcon>
	);
}

AirFreight = pure(AirFreight);
AirFreight.displayName = 'AirFreight';
AirFreight.muiName = 'SvgIcon';

export default AirFreight
