import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #eee',
    width: '100%',
    height: '100%',
    boxShadow: '1px 1px 3px #f1f1f1',
  },
  title: {
    color: theme.palette.primary.main,
    borderBottom: '1px solid #eee',
    padding: '5px',
    fontSize: '1.1rem',
  },
}));

const CustomPaper = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.title}>{props.title || ''}</p>
      {props.children}
    </div>
  );
};

export default CustomPaper;
