export const SelectStylesMain = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#eee' : styles.color,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#62485E',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 1px #62485E' : 0,
    borderColor: state.isFocused ? '#62485E' : '#CED4DA',
    '&:hover': {
      borderColor: state.isFocused ? '#62485E' : '#CED4DA',
    },
  }),
};
