import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import translationsObj from '../../../services/translations/translations.json';
import translationsTooltipsObj from '../../../services/translations/translationsTooltips';
import { grey } from '@material-ui/core/colors';

const MAX_CHARACTERS = 300;

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  subTitle: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    gap: '5px',
  },
  DialogContent: {
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  textField: {
    width: '90%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '50%',
      minWidth: '200px',
    },
  },
}));

export default function AddCompanyCommentModal({
  open,
  handleAbort,
  template,
  flow,
  updateComment,
}) {
  const [comment, setComment] = useState('');
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translations = translationsObj[lang];
  const translationsTooltips = translationsTooltipsObj[lang];

  useEffect(() => {
    if (template) {
      const templateComment = template.templateComment || '';
      setComment(templateComment);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleUpdateComment = () => {
    const templateId = template ? template.id : null;
    updateComment(comment, templateId, flow);
  };

  const handleTextChange = (e) => {
    // Allow max 300 characters
    if (e.target.value.length > MAX_CHARACTERS) return;
    setComment(e.target.value);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby="add-comment-dialog-title"
      aria-describedby="add-comment-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle className={classes.modalTitle} id="add-comment-dialog-title">
        {translations.addComment}
      </DialogTitle>
      <div className={classes.DialogContent}>
        <div className={classes.subTitle}>
          <p style={{ fontWeight: '500' }}>
            {' '}
            {translations.addCommentForTemplate}
            <span style={{ fontWeight: '400', fontStyle: 'italic' }}>
              &nbsp;&#40;{translations.max} {MAX_CHARACTERS} {translations.characters}&#41;
            </span>
          </p>
          <p style={{ fontWeight: '400', fontStyle: 'italic', color: grey[800] }}>
            {' '}
            {translationsTooltips.addCommentForTemplate}
          </p>
        </div>
        <TextField
          className={classes.textField}
          id="outlined-multiline-static"
          // label="Comments"
          placeholder={translations.addCommentHere}
          multiline
          rows={4}
          variant="outlined"
          value={comment}
          onChange={handleTextChange}
        />
        <div className={classes.buttons}>
          <Button variant="contained" color="primary" onClick={handleUpdateComment}>
            {translations.templates.save}
          </Button>
          <Button variant="text" color="secondary" onClick={handleAbort}>
            {translations.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
