import axios from 'axios';
import * as actionTypes from './actionTypes';

const addPackageTemplateSuccess = (addedPackage) => {
  return {
    type: actionTypes.ADD_PACKAGE_TEMPLATE_SUCCESS,
    payload: addedPackage,
  };
};

const addPackageTemplateStart = () => {
  return {
    type: actionTypes.ADD_PACKAGE_TEMPLATE_START,
  };
};

const addPackageTemplateFailed = (error) => {
  return {
    type: actionTypes.ADD_PACKAGE_TEMPLATE_FAILED,
    error,
  };
};

export const addPackageTemplate = (packageData, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    dispatch(addPackageTemplateStart());
    axios
      .post('/api/templates/addpackage', { packageData }, config)
      .then((resp) => {
        dispatch(addPackageTemplateSuccess(resp.data.response));
      })
      .catch((e) => addPackageTemplateFailed(e));
  };
};

const fetchPackageTemplateSuccess = (packages) => {
  return {
    type: actionTypes.FETCH_PACKAGE_TEMPLATE_SUCCESS,
    packages,
  };
};

const fetchPackageTemplateStart = () => {
  return {
    type: actionTypes.FETCH_PACKAGE_TEMPLATE_START,
  };
};
const fetchPackageTemplateFailed = (error) => {
  return {
    type: actionTypes.FETCH_PACKAGE_TEMPLATE_FAILED,
    error,
  };
};

export const fetchPackageTemplate = ({ userId, token }) => {
  return (dispatch) => {
    dispatch(fetchPackageTemplateStart());
    axios
      .get(`/api/templates/packages/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        dispatch(fetchPackageTemplateSuccess(resp.data.packages));
      })
      .catch((e) => fetchPackageTemplateFailed(e));
  };
};

//REMOVE PACKAGE TEMPLATE
const removePackageTemplateSuccess = (id) => {
  return {
    type: actionTypes.REMOVE_PACKAGE_TEMPLATE_SUCCESS,
    removedId: id,
  };
};

const removePackageTemplateStart = () => {
  return {
    type: actionTypes.REMOVE_PACKAGE_TEMPLATE_START,
  };
};

const removePackageTemplateFailed = (error) => {
  return {
    type: actionTypes.REMOVE_PACKAGE_TEMPLATE_FAILED,
    error,
  };
};

export const removePackageTemplate = (id) => {
  return (dispatch) => {
    dispatch(removePackageTemplateStart());
    axios
      .delete(`/api/templates/remove-package/${id}`)
      .then((resp) => {
        dispatch(removePackageTemplateSuccess(resp.data.removedId));
      })
      .catch((e) => removePackageTemplateFailed(e));
  };
};

//UPDATE PACKAGE TEMPLATE
const updatePackageTemplateStart = () => {
  return {
    type: actionTypes.UPDATE_PACKAGE_TEMPLATE_START,
  };
};

const updatePackageTemplateFailed = (error) => {
  return {
    type: actionTypes.UPDATE_PACKAGE_TEMPLATE_FAILED,
    error,
  };
};

const updatePackageTemplateSuccess = (packageData) => {
  return {
    type: actionTypes.UPDATE_PACKAGE_TEMPLATE_SUCCESS,
    packageData,
  };
};

export const updatePackageTemplate = (packageTemplateData, packageTemplateId, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };
  return (dispatch) => {
    dispatch(updatePackageTemplateStart());
    axios
      .put('/api/templates/update-package', { packageTemplateData, packageTemplateId }, config)
      .then((resp) => {
        dispatch(updatePackageTemplateSuccess(resp.data.result));
      })
      .catch((e) => updatePackageTemplateFailed(e));
  };
};
