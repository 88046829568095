import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import translationsCommonCodes from '../../../../../../services/translations/translationsCommonCodes';
import { HrxAllowedTermsOfShipmentCodes } from '../../../../../../services/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
  },
  termsOfShipment: {
    width: '300px',
  },
}));

const HrxIncoTerm = ({ termsChange, translations }) => {
  const [termsOfShipment, setTermsOfShipment] = useState('');

  const classes = useStyles();
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { invoiceForm } = useSelector((state) => state.booking);

  useEffect(() => {
    if ('termsOfShipment' in invoiceForm && invoiceForm.termsOfShipment !== termsOfShipment) {
      setTermsOfShipment(invoiceForm.termsOfShipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceForm]);

  return (
    <div className={classes.root}>
      <TextField
        select
        required
        inputProps={{ maxLength: 20 }}
        className={classes.termsOfShipment}
        name='termsOfShipment'
        value={termsOfShipment}
        onChange={termsChange}
        helperText={translations.invoiceForm.incoterm}
        margin='dense'
      >
        {HrxAllowedTermsOfShipmentCodes.map((code) => (
          <MenuItem key={code} value={code}>
            {`${code} - ${translationsCommonCodes[lang].TermsOfShipmentCodes[code]}`}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default HrxIncoTerm;
