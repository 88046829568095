import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: null,
  loggedIn: false,
  consent: false,
  isAdmin: false,
  allowFileBooking: false,
  userId: '',
  companyId: '',
  associatedOrigin: '',
  avatar: 'avatar.png',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  created: '',
  prevLoginAt: '',
  companyName: '',
  companyCity: '',
  companyCountryName: '',
  companyCountryCode: '',
  companyAddress1: '',
  companyAddress2: '',
  companyPostalCode: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_DETAILS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_USER_DETAILS_SUCCESS:
      const newState = {
        ...state,
        ...action.payload,
        loggedIn: true,
        loading: false,
        error: null,
      };
      return newState;
    case actionTypes.USER_AVATAR_UPLOAD_SUCCESS:
      return {
        ...state,
        avatar: action.avatar,
      };
    case actionTypes.USER_CONSENT_SUCCESS:
      return {
        ...state,
        consent: true,
      };
    case actionTypes.CLEAN_USER_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
