import React, { useEffect, useState } from 'react';
import { Checkbox, makeStyles, Snackbar, Tooltip } from '@material-ui/core';
import Select from 'react-select';
import {
  CARRIERS,
  DHL_ADDITIONAL_FIXED_DELIVERY_DATE,
  DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES,
  DHL_EURAPID,
  DHL_HOME_DELIVERY_SERVICES,
  DHL_SERVICE_POINT_DELIVERY,
  DHL_SERVICE_POINT_SERVICE_CODES,
  DOOR_DELIVERY,
} from '../../../../../services/types';
import { ValidateLoading } from '../../../../UI/Validating/Validating';
import { SelectStylesMain } from '../../../../UI/SelectStyles/SelectStyles';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import InfoIcon from '@material-ui/icons/Info';
import {
  ADD_REQUESTED_DELIVERY_DATE,
  UPDATE_BOOKING_ADDITIONAL_SERVICES,
  UPDATE_BOOKING_FIXED_DELIVERY_DATE,
  UPDATE_BOOKING_SERVICE_CODE,
} from '../../../../../store/actions/actionTypes';
import { usePrevious } from '../../../../../helpers/hooks';
import { green, grey } from '@material-ui/core/colors';
import { fetchAdditionalServices } from '../../../../../store/actions';
import SnackbarContentWrapper from '../../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { HorizontalSpinner } from '../../../../UI/Spinners/Horizontal';
import { isNull } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    width: '100%',
    display: 'grid',
    rowGap: '8px',
  },
  additionalServicesHeader: {
    width: '100%',
    padding: '7px 0',
    boxShadow: '0 4px 4px -2px grey',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '& > p': {
      textAlign: 'center',
      fontSize: '1.1rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  deliveryOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 5px',
    },
  },
  additionalServicesOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  additionalServicesTooltip: {
    padding: '0 10px',
    color: grey[600],
  },
  dropOffLocationList: {
    width: '100%',
    padding: '0 5px',
    boxSizing: 'border-box',
  },
  servicePointSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fixedDeliveryDatesContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fixedDeliveryDatesSelect: {
    width: '300px',
    marginLeft: '10px',
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}));

const filterDescriptions = (description) => {
  const removalStrings = [
    'Skicka till dhlfreight.int.se@dhl.com',
    'Upload or send to dhlfreight.int.se@dhl.com',
  ];
  if (!description) return;
  removalStrings.forEach((str) => {
    if (description.substring(str)) {
      description = description.replace(str, '');
    }
  });
  return description;
};

export const validateAdditionalServices = async ({ bookingData, code, lang, token }) => {
  const additionalServices = bookingData.additionalServices.map(({ code }) => code);

  if (!additionalServices.includes(code)) {
    additionalServices.push(code);
  } else {
    const codeIdx = additionalServices.indexOf(code);
    additionalServices.splice(codeIdx, 1);
  }

  let validation = {
    serviceCode: null,
    error: null,
    accessPoint: null,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const validationRequest = await Axios.post(
      `/api/shipping/additional-services-validate`,
      { bookingData, additionalServices, lang },
      config
    );

    if (validationRequest.data.error) throw new Error(validationRequest.data.error);

    validation['serviceCode'] = validationRequest.data.serviceCode;
    if (validationRequest.data.accessPoint)
      validation['accessPoint'] = validationRequest.data.accessPoint;
  } catch (e) {
    e.message
      ? (validation['error'] = e.message)
      : (validation['error'] = 'Error validating additional services');
  }

  return validation;
};

const initialValidationState = {
  loadingIdx: null,
  error: null,
};

const DhlFreightAdditionalServices = ({
  translations,
  dropOffLoading,
  dropOffError,
  dropOffSelected,
  handleDropOffChange,
  dropOffLocationOptions,
  selectedDeliveryMethod,
  setSelectedDeliveryMethod,
  carrierColors,
  updateCostForService,
}) => {
  const [servicePoint, setServicePoint] = useState(false);
  const [fixedDeliveryDateOptions, setFixedDeliveryDateOptions] = useState([]);

  const [validatonState, setValidationState] = useState(initialValidationState);
  const [snackbar, setSnackbar] = useState({
    variant: '',
    messages: [],
    open: false,
  });
  const bookingData = useSelector((state) => state.booking);
  const { serviceCode, additionalServiceOptions, additionalServices } = useSelector(
    (state) => state.booking
  );
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const prevServiceCode = usePrevious(bookingData.serviceCode);
  useEffect(() => {
    if (
      DHL_HOME_DELIVERY_SERVICES.includes(prevServiceCode) &&
      DHL_HOME_DELIVERY_SERVICES.includes(bookingData.serviceCode)
    )
      return;

    if (bookingData.fixedDeliveryDates.length > 0) {
      setFixedDeliveryDateOptions(
        bookingData.fixedDeliveryDates.map((date) => ({ value: date, label: date }))
      );
      handleFixedDeliveryDatesChange(bookingData.fixedDeliveryDates[0]);
    }
    checkForAdditionalServices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceCode]);

  const prevAdditionalServices = usePrevious(additionalServices.length);
  const prevAdditionalServiceOptions = usePrevious(additionalServiceOptions.length);

  //Updates cost for service when additional service option is selected
  useEffect(() => {
    if (prevAdditionalServices === undefined) return;
    prevAdditionalServices !== additionalServices.length &&
      updateCostForService(CARRIERS.DHLFREIGHT, serviceCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServices]);

  /**
   * Run effect to Auto-check additional options
   * Necessary for Euroconnect Plus where full customs declaration is needed
   */
  useEffect(() => {
    if (
      prevAdditionalServiceOptions === undefined ||
      prevAdditionalServiceOptions === additionalServiceOptions.length
    )
      return;

    if (DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES[serviceCode]) {
      if (!Array.isArray(DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES[serviceCode])) return; //Guard
      // let autoSelected = [];
      additionalServiceOptions.forEach((service) => {
        if (DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES[serviceCode].includes(service.code)) {
          const { code } = service;
          // autoSelected.push(code);
          dispatch({
            type: UPDATE_BOOKING_ADDITIONAL_SERVICES,
            additionalService: { code, value: true },
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServiceOptions]);

  const autoSelectedOptionCheck = (code) =>
    serviceCode in DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES &&
    DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES[serviceCode].includes(code);

  const checkForAdditionalServices = () => {
    if (DHL_SERVICE_POINT_SERVICE_CODES.includes(serviceCode)) {
      selectedDeliveryMethod === DOOR_DELIVERY &&
        setSelectedDeliveryMethod(DHL_SERVICE_POINT_DELIVERY);

      setServicePoint(true);
    }

    if (
      selectedDeliveryMethod === DHL_SERVICE_POINT_DELIVERY &&
      !DHL_SERVICE_POINT_SERVICE_CODES.includes(serviceCode)
    ) {
      setSelectedDeliveryMethod(DOOR_DELIVERY);
      setServicePoint(false);
    }

    if (additionalServiceOptions.length === 0)
      dispatch(fetchAdditionalServices(bookingData, lang, token));
  };

  const handleAdditionalServiceValidationLoading = (c) => {
    const serviceIndex = additionalServiceOptions.findIndex(({ code }) => code === c);
    setValidationState({ error: null, loadingIdx: serviceIndex });
  };

  const updateServiceCode = ({ prevServiceCode, newServiceCode }) => {
    dispatch({
      type: UPDATE_BOOKING_SERVICE_CODE,
      prevServiceCode,
      newServiceCode,
    });
  };

  const handleAdditionalServiceChange = async (service) => {
    const value = true;
    const { code } = service;

    // For all DHL Home Delivery product codes a validation is needed
    // The service code may also change depending on the combination
    // for all additional services requested for the Home Delivery product
    if (DHL_HOME_DELIVERY_SERVICES.includes(bookingData.serviceCode)) {
      if (!isNull(validatonState.loadingIdx)) return; //Guard against clicking multiple checkboxes before validation
      handleAdditionalServiceValidationLoading(code);
      const validation = await validateAdditionalServices({ code, bookingData, lang, token });

      if (validation.error) {
        setValidationState({ loadingIdx: null, error: validation.error });
        setSnackbar({
          ...snackbar,
          open: true,
          variant: 'warning',
          messages: [validation.error],
        });
        return;
      }

      if (validation.serviceCode && validation.serviceCode !== bookingData.serviceCode) {
        updateServiceCode({
          prevServiceCode: bookingData.serviceCode,
          newServiceCode: validation.serviceCode,
        });

        if (validation.serviceCode === '501' && validation.accessPoint) {
          handleDropOffChange({ value: validation.accessPoint });
        }
        if (validation.serviceCode === '401' && bookingData.serviceCode === '501') {
          handleDropOffChange(null);
        }
      }
      setValidationState(initialValidationState);
    }

    // Clean fixed delivery date in online booking upon deselect the fixed day option
    if (code === DHL_ADDITIONAL_FIXED_DELIVERY_DATE) {
      // Check if the option is selected
      const selected = additionalServices.find((s) => s.code === code);
      if (selected) {
        dispatch({
          type: UPDATE_BOOKING_FIXED_DELIVERY_DATE,
          payload: null,
        });
      }
    }

    const additionalService = { code, value };

    dispatch({
      type: UPDATE_BOOKING_ADDITIONAL_SERVICES,
      additionalService,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFixedDeliveryDatesChange = (date) => {
    dispatch({
      type: UPDATE_BOOKING_FIXED_DELIVERY_DATE,
      payload: date,
    });
  };

  const loading = dropOffLoading || bookingData.loadingAdditionalServices;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div style={carrierColors} className={classes.additionalServicesHeader}>
          <p>{translations.onlinebooking.additionalServices}</p>
        </div>
        {loading && (
          <div className={classes.loadingContainer}>
            <HorizontalSpinner size="lg" />
          </div>
        )}
        {!loading && servicePoint && (
          <div className={classes.dropOffLocationList}>
            {dropOffError && <p>{dropOffError}.</p>}
            {!dropOffLoading && !dropOffError && (
              <div className={classes.servicePointSelectionContainer}>
                <label htmlFor="service-points">
                  {translations.onlinebooking.chooseServicePoint}
                </label>
                <Select
                  value={dropOffSelected}
                  onChange={handleDropOffChange}
                  options={dropOffLocationOptions}
                  styles={SelectStylesMain}
                  menuPlacement="auto"
                />
              </div>
            )}
          </div>
        )}

        {!loading && additionalServiceOptions.length > 0 && (
          <div>
            {additionalServiceOptions.map((service, idx) => {
              const autoCheck = autoSelectedOptionCheck(service.code);
              const checked =
                additionalServices.some((obj) => obj.code === service.code) || autoCheck;
              if (
                checked &&
                service.code === DHL_ADDITIONAL_FIXED_DELIVERY_DATE &&
                fixedDeliveryDateOptions.length > 0 &&
                !bookingData.fixedDeliveryDate
              ) {
                handleFixedDeliveryDatesChange(fixedDeliveryDateOptions[0].value);
              }
              return (
                <div key={service.code} className={classes.additionalServicesOptionContainer}>
                  <Checkbox
                    id={service.code}
                    name={service.code}
                    checked={checked}
                    onChange={(e) => handleAdditionalServiceChange(service)}
                    className={classes.checkbox}
                    disabled={autoCheck}
                  />
                  <label htmlFor={service.code}>{service.name}</label>
                  <Tooltip title={filterDescriptions(service.description) || ''}>
                    <InfoIcon className={classes.additionalServicesTooltip} />
                  </Tooltip>
                  {/* Check if fixed delivery dates is checked and show options */}
                  {checked &&
                    service.code === DHL_ADDITIONAL_FIXED_DELIVERY_DATE &&
                    fixedDeliveryDateOptions.length > 0 &&
                    bookingData.fixedDeliveryDate && (
                      <div style={{ width: '300px' }}>
                        <Select
                          value={fixedDeliveryDateOptions.find(
                            (option) => option.value === bookingData.fixedDeliveryDate
                          )}
                          onChange={({ value }) => handleFixedDeliveryDatesChange(value)}
                          options={fixedDeliveryDateOptions}
                          styles={SelectStylesMain}
                          menuPlacement="auto"
                        />
                      </div>
                    )}
                  {validatonState.loadingIdx === idx && (
                    <ValidateLoading color={green[700]}>{translations.validate}...</ValidateLoading>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default DhlFreightAdditionalServices;
