export const createBookingStatusFromTemplateComment = (text, flow) => {
  // flow = 'recipient' | 'sender'
  const priority = 'medium';
  const variant = 'info';
  const id = `${flow}Comment`;
  return {
    text,
    variant,
    id,
    priority,
  };
};
