import * as actionTypes from './actionTypes';
import axios from 'axios';

export const fetchPagodaRates = (bookingData, carriers) => async (dispatch, getState) => {
  const { user, auth } = getState();

  dispatch({
    type: actionTypes.FETCH_PAGODA_RATES_START,
  });

  //For general rate requests, add arbitrary values for indicative rates
  const upsRequiredValues = {
    withTransitTimes: true,
    description: 'unspecified',
    customsValue: '1',
    customsCurrency: 'EUR',
  };

  //Add access point rates to the request
  upsRequiredValues['accessPointRates'] = true;

  const carrierSpecificValues = Object.assign({}, upsRequiredValues);

  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
      'Content-Type': 'application/json',
    },
  };

  const shipper = Object.assign({}, user);

  try {
    const { data } = await axios.post(
      `/api/rates/get-all`,
      {
        bookingData: { ...bookingData, ...carrierSpecificValues, shipper },
        carriers,
      },
      config
    );
    const { rates } = data;

    if (typeof rates === 'undefined' || rates.length < 1)
      throw new Error('Unable to retrieve any rates');
    dispatch({
      type: actionTypes.FETCH_PAGODA_RATES_SUCCESS,
      rates,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_PAGODA_RATES_FAILED,
      error:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const cleanPagodaRates = () => {
  return {
    type: actionTypes.CLEAN_PAGODA_RATES,
  };
};
