import * as actionTypes from './actionTypes';
import axios from 'axios';

export const fetchCountriesStart = () => {
	return {
		type: actionTypes.FETCH_COUNTRIES_START
	}
}

export const fetchCountriesSuccess = (countriesData) => {
	return {
		type: actionTypes.FETCH_COUNTRIES_SUCCESS,
		countriesData: countriesData
	}
}

export const fetchCountriesFailed = (err) => {
	return {
		type: actionTypes.FETCH_COUNTRIES_FAILED,
		error: err
	}
}

export const fetchCountries = (token) => {
	return dispatch => {
		dispatch(fetchCountriesStart());
		axios.get(`/api/countries/getall`, { headers: { Authorization: `Bearer ${token}` }})
			.then(resp => {
				dispatch(fetchCountriesSuccess(resp.data))
			})
			.catch(err => dispatch(fetchCountriesFailed(err)));
	}
}