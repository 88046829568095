import axios from 'axios';
import * as actionTypes from './actionTypes';

export const addBookingSender = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_SENDER,
    sender: payload,
  };
};

export const addBookingRecipient = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_RECIPIENT,
    recipient: payload,
  };
};

export const addBookingResidentialAddress = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_RESIDENTIAL_ADDRESS,
    residentialAddress: payload,
  };
};

export const addBookingPackages = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_PACKAGES,
    packages: payload,
  };
};

export const addBookingDescription = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_DESCRIPTION,
    description: payload,
  };
};
export const addBookingDocumentOnly = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_DOCUMENTS_ONLY,
    documentsOnly: payload,
  };
};
export const addBookingDeliveryRemark = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_DELIVERY_REMARK,
    payload,
  };
};

export const addBookingReferenceOne = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_REFERENCE_ONE,
    reference: payload,
  };
};
export const addBookingReferenceTwo = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_REFERENCE_TWO,
    reference: payload,
  };
};

export const addBookingCustomsValue = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_CUSTOMS_VALUE,
    customsValue: payload,
  };
};

export const addBookingCustomsCurrency = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_CUSTOMS_CURRENCY,
    currency: payload,
  };
};

export const addBookingDate = (payload) => {
  return {
    type: actionTypes.ADD_BOOKING_DATE,
    date: payload,
  };
};

export const addBookingServiceSelected = (selectedServiceData) => {
  const applicableUpdateKeys = [
    'serviceCode',
    'serviceName',
    'carrier',
    'billableWeight',
    'currencyCode',
    'totalRate',
    'surcharges',
  ];

  const serviceDataFiltered = Object.keys(selectedServiceData)
    .filter((key) => applicableUpdateKeys.includes(key))
    .reduce((acc, curr) => {
      acc[curr] = selectedServiceData[curr];
      return acc;
    }, {});

  return {
    type: actionTypes.ADD_BOOKING_SERVICE_SELECTED,
    selectedServiceData: serviceDataFiltered,
  };
};

export const addBookingServices = (services) => {
  return {
    type: actionTypes.ADD_BOOKING_SERVICES,
    services,
  };
};
export const addBookingDeliveryMethod = (deliveryMethod) => {
  return {
    type: actionTypes.ADD_BOOKING_DELIVERY_METHOD,
    deliveryMethod,
  };
};
export const addBookingAlternateDelivery = (address) => {
  return {
    type: actionTypes.ADD_BOOKING_ALTERNATE_DELIVERY,
    payload: address ? address.value : null,
  };
};
export const addBookingDropOffLocations = (dropOffLocations) => {
  return {
    type: actionTypes.ADD_BOOKING_DROP_OFF_LOCATIONS,
    dropOffLocations,
  };
};

export const fetchUpsDropOffLocationsSuccess = (dropOffLocations) => {
  return {
    type: actionTypes.ADD_BOOKING_UPS_DROP_OFF_LOCATIONS,
    dropOffLocations,
  };
};

export const fetchUpsDropOffLocations = (address, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    axios
      .post('/api/shipping/locator', { address, carrier: 'ups' }, config)
      .then((resp) => {
        const dropOffOptions = resp.data.locatorResults.locations.map((dropOff) => ({
          value: { code: dropOff.id, name: dropOff.name, address: dropOff.address },
          label: `${dropOff.name} (${dropOff.distance} ${dropOff.distanceUnit})`,
        }));

        dispatch(fetchUpsDropOffLocationsSuccess(dropOffOptions));
      })
      .catch((_) => {});
  };
};

export const bookingValidationUpdate = (step) => {
  return {
    type: actionTypes.BOOKING_VALIDATION_UPDATE,
    payload: step,
  };
};

export const addBookingPrintFormat = (printOption) => {
  return {
    type: actionTypes.BOOKING_PRINT_FORMAT,
    payload: printOption,
  };
};

export const addPickupDetails = (
  pickupDate,
  pickupTimeStart,
  pickupTimeEnd,
  pickupRef,
  alternateAddress
) => {
  //alternateCollectionAddress applicable for certain carriers where departure location is selected
  const alternateCollectionAddress =
    alternateAddress && 'value' in alternateAddress ? alternateAddress.value : null;

  return {
    type: actionTypes.ADD_BOOKING_PICKUP_DETAILS,
    pickupDate,
    pickupTimeStart,
    pickupTimeEnd,
    pickupRef,
    alternateCollectionAddress,
  };
};

//Adds only one of pickupTimeStart,pickupTimeEnd,pickupDate and reference
export const addPickupDetailTime = ({ field, time }) => {
  return {
    type: actionTypes.ADD_BOOKING_PICKUP_DETAIL_TIME,
    field,
    time,
  };
};

export const addBookingDocuments = (documents) => {
  return {
    type: actionTypes.ADD_BOOKING_DOCUMENTS,
    documents,
  };
};
export const addBookingInvoiceType = (invoiceType) => {
  return {
    type: actionTypes.ADD_BOOKING_INVOICE_TYPE,
    invoiceType,
  };
};
export const updateInvoiceForm = ({ invoiceForm }) => {
  return {
    type: actionTypes.UPDATE_INVOICE_FORM,
    invoiceForm,
  };
};

export const addRequestPickupOption = (option) => {
  return {
    type: actionTypes.ADD_BOOKING_REQUEST_PICKUP,
    option,
  };
};

export const addBookingFromRates = (sender, recipient, packages) => {
  return {
    type: actionTypes.ADD_BOOKING_FROM_RATES,
    sender: { ...sender, contacts: [] },
    recipient: { ...recipient, contacts: [] },
    packages,
  };
};
export const addBookingFromHistory = (bookingData) => {
  return {
    type: actionTypes.ADD_BOOKING_FROM_HISTORY,
    payload: bookingData,
  };
};

export const updatePackageCount = () => {
  return {
    type: actionTypes.UPDATE_PACKAGE_COUNT,
  };
};

export const updateBillingOption = (option) => {
  return {
    type: actionTypes.UPDATE_BILLING_OPTION,
    billingDuties: option,
  };
};

export const addMultipleNotifications = (notifications) => {
  return {
    type: actionTypes.ADD_MULTIPLE_NOTIFICATIONS,
    payload: notifications,
  };
};
export const addNotification = (notification) => {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    notification,
  };
};

export const fetchAdditionalServicesStart = () => {
  return {
    type: actionTypes.FETCH_ADDITIONAL_SERVICES_START,
  };
};

export const fetchAdditionalServicesSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ADDITIONAL_SERVICES_SUCCESS,
    payload,
  };
};

export const fetchAdditionalServicesFailed = (err) => {
  return {
    type: actionTypes.FETCH_ADDITIONAL_SERVICES_FAILED,
    error: err,
  };
};

export const fetchAdditionalServices = (bookingData, lang, token) => {
  return (dispatch) => {
    dispatch(fetchAdditionalServicesStart());
    axios
      .post(
        '/api/shipping/additional-services',
        { bookingData, lang },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        dispatch(fetchAdditionalServicesSuccess(resp.data.results));
      })
      .catch((err) => dispatch(fetchAdditionalServicesFailed(err)));
  };
};

export const setFixedDeliveryDates = (dates) => {
  return {
    type: actionTypes.SET_FIXED_DELIVERY_DATES,
    payload: dates,
  };
};

//CLEAR ACTIONS
export const removeNotification = ({ recipient }) => {
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    recipient,
  };
};

export const clearServiceData = () => {
  return {
    type: actionTypes.CLEAR_SERVICE_DATA,
  };
};
export const clearDeliveryData = () => {
  return {
    type: actionTypes.CLEAR_DELIVERY_DATA,
  };
};

export const clearPickupData = () => {
  return {
    type: actionTypes.CLEAR_PICKUP_DATA,
  };
};
export const clearPrintFormat = () => {
  return {
    type: actionTypes.CLEAR_PRINT_FORMAT,
  };
};

export const cleanBooking = () => {
  return {
    type: actionTypes.CLEAN_BOOKING,
  };
};
