import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import { getPackagingImage } from '../../../utils/carrier';
import NumberSpinner from '../NumberSpinner/NumberSpinner';
import NumberSpinnerMobile from '../NumberSpinner/NumberSpinnerMobile';
import PackagingSelect from '../PackagingSelect/PackagingSelect';
import { packagingValues } from '../../../utils/carrier/lib';
import SnackbarContentWrapper from '../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import {
  ValidatePackageTemplateDimensions,
  ValidatePackageTemplate,
} from '../../../services/validations';
//Material UI
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import StarIcon from '@material-ui/icons/StarBorder';
import PlusIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import { Checkbox } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

//other
import PackageDefaultIcon from '../../../assets/package.svg';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import FavouritesSelect from '../FavouritesSelect/FavouritesSelect';
import {
  OTHER_PACKAGING,
  UPS_EXPRESS_BOX,
  UPS_EXPRESS_ENVELOPE,
  UPS_EXPRESS_TUBE,
} from '../../../services/types';
import translationsTooltips from '../../../services/translations/translationsTooltips';
import PackageSpecificReferenceButton from '../PackageSpecificReferenceButton/PackageSpecificReferenceButton';

//Set the default packaging details as can be provided by carrier
const presetPackaging = {
  menuItems: packagingValues,
  descriptionIcon: (
    <img
      style={{ padding: '2px', transform: 'translateY(5px)' }}
      width="18"
      height="18"
      src={PackageDefaultIcon}
      alt="Default packaging icon"
    />
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr) max-content',
    gridTemplateRows: 62,
    gridColumnGap: '2%',
    paddingBottom: '24px',
    position: 'relative',
  },
  numberField: {
    textAlign: 'center !important',
    minWidth: '76px',
    paddingLeft: '5px',
    transform: 'translateY(8px)',
    '&:last-of-type': {
      marginRight: '0px',
    },
  },
  packaging: {
    padding: '5px 0',
    width: '100%',
    textAlign: 'center',
    display: 'grid',
    placeItems: 'center',
  },
  input: {
    textAlign: 'center',
  },
  inputAdornment: {
    color: 'black !important',
    fontSize: '0.875rem',
  },
  label: {
    fontSize: '0.9rem',
    paddingLeft: '4px',
    paddingTop: '2px',
    textAlign: 'center',
  },
  numberInput: {
    width: '15px',
    minWidth: '15px',
  },
  predefinedInputContainer: {
    gridColumn: 'span 4',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '266px 80px',
    gridColumnGap: '10px',
    transform: 'translateX(-15px)',
  },
  predefinedInputDetails: {
    transform: 'translateY(9px)',
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    placeItems: 'center',
    height: 50,
    backgroundColor: '#fbfbfb',
    border: '1px solid #eee',
    '& > img': {
      height: '100%',
      width: '100%',
      padding: '5px',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 500,
    },
  },
  packageTemplatesContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
    transform: 'translateX(5px)',
  },
  stackableOptionContainer: {
    position: 'absolute',
    display: 'flex',
    top: '60px',
    alignItems: 'center',
    gap: '5px',
    left: '3px',
  },
  packageTemplatesPresets: {
    gridColumn: 1,
    display: 'grid',
    gridTemplateColumns: '45px 45px',
    columnGap: 4,
    transform: 'translate(5px,5px)',
    alignItems: 'center',
  },
  packageTemplatesSimple: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(5px,-5px)',
  },
  numberSpinner: {
    gridColumn: 2,
  },
  newButton: {
    minWidth: '30px',
    padding: '1px 0px 1px 0px',
    height: '90%',
    marginLeft: '2px',
    color: '#b3b34c',
  },
  searchButton: {
    minWidth: '30px',
    padding: '1px 0px 1px 0px',
    height: '90%',
    marginLeft: '2px',
    color: theme.palette.primary.main,
  },
  saveButton: {
    width: '20px',
    fontSize: '0.9rem',
    minWidth: '30px',
    height: '30px',
    color: 'gray',
  },
  addTemplateButton: {
    color: 'white',
    backgroundColor: '#5d9665',
    '&:hover': {
      backgroundColor: '#4a7750',
    },
  },
  abortTemplateButton: {},
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  dialogContent: {
    height: '200px',
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  dialogInfo: {
    fontWeight: '400',
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  packageSpecificReference: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignItems: 'flex-end',
  },
  packageReferenceRow: {
    gridColumn: '4 / span 3',
  },
  packageReference: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
  },
  stackableToolTip: {
    paddingLeft: '2px',
    color: grey[600],
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    predefinedInputContainer: {
      gridTemplateColumns: '246px 80px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateAreas: `
      "template-presets number-spinner number-spinner reference-template"
      "number-input number-input number-input number-input"
      `,
      gridTemplateRows: '60px auto',
      rowGap: '20px',
      marginBottom: '15px',
      padding: '0 3px',
    },
    packageSpecificReference: {
      gridArea: 'reference-template',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'end',
    },
    stackableOptionContainer: {
      top: '63px',
    },
    packageTemplatesPresets: {
      gridArea: 'template-presets',
      width: 'max-content',
      gridTemplateColumns: 'minmax(min-content,45px) minmax(min-content,45px)',
      columnGap: 0,
      flexDirection: 'row',
      alignSelf: 'end',
      transform: 'none',
    },
    numberSpinner: {
      gridArea: 'number-spinner',
      width: '100%',
      alignSelf: 'end',
      justifySelf: 'center',
    },
    numberInput: {
      gridArea: 'number-input',
    },
    inputAdornment: {
      fontSize: '0.7rem',
    },
    predefinedInputContainer: {
      gridColumn: 'span 4',
      gridTemplateColumns: 'auto 80px',
      transform: 'none',
    },
    predefinedInputDetails: {
      height: 45,
      marginRight: 12,
      transform: 'translateY(7px)',
      gridTemplateColumns: 'minmax(100px,100%) 80px',
    },
    packageReferenceRow: {
      gridColumn: '1 / -1',
      padding: '15px 0px 0px 5px',
    },
  },
  '@media (max-width:400px)': {
    numberField: {
      minWidth: '60px',
    },
    label: {
      fontSize: '0.7rem',
      paddingLeft: '5px',
      paddingTop: '4px',
    },
    predefinedInputDetails: {
      marginRight: 20,
    },
  },
}));

const initialInputs = {
  templateName: 'none',
  count: 1,
  packaging: OTHER_PACKAGING,
  reference: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  stackable: true,
};

const PackageInputs = ({
  rowNumber,
  type,
  reloadKey,
  updatePackagesErrors,
  updatePackageRow,
  translations,
  initialPackages,
}) => {
  const mobileView = useMediaQuery('(max-width:600px)');

  const [packageTypeIcon, setPackageTypeIcon] = useState(PackageDefaultIcon);

  const [inputs, setInputs] = useState(initialInputs);
  const [errors, setError] = useState({
    length: false,
    width: false,
    height: false,
    weight: false,
  });

  const [templateName, setTemplateName] = useState('');
  const [showContentRow, setShowContentRow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); //for saving package template
  const [snackbar, setSnackbar] = useState({
    open: false,
    messages: [],
    variant: '',
  });
  const [fetchedTemplates, setFetchedTemplates] = useState(null);

  const booking = useSelector((state) => state.booking);
  const packageTemplates = useSelector((state) => state.packageTemplates);
  const { userId } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const dispatch = useDispatch();

  //set package type icon
  useEffect(() => {
    const packaging = packagingValues.find((v) => v.packaging === inputs.packaging);
    packaging && setPackageTypeIcon(() => packaging.image);
  }, [inputs]);

  useEffect(() => {
    //if components is used from online booking populate fields from online booking redux store
    if (booking.packages.length >= rowNumber + 1 && type === 'booking') {
      setInputs({
        ...inputs,
        templateName: booking.packages[rowNumber].templateName,
        count: booking.packages[rowNumber].count,
        packaging: booking.packages[rowNumber].packaging,
        reference: booking.packages[rowNumber].reference,
        length: booking.packages[rowNumber].length,
        width: booking.packages[rowNumber].width,
        height: booking.packages[rowNumber].height,
        weight: booking.packages[rowNumber].weight,
        stackable: booking.packages[rowNumber].stackable,
      });
    }

    if (type === 'requestBooking' && initialPackages.length >= rowNumber + 1) {
      setInputs({
        ...inputs,
        templateName: initialPackages[rowNumber].templateName,
        count: initialPackages[rowNumber].count,
        packaging: initialPackages[rowNumber].packaging,
        length: initialPackages[rowNumber].length,
        width: initialPackages[rowNumber].width,
        height: initialPackages[rowNumber].height,
        weight: initialPackages[rowNumber].weight,
        stackable: initialPackages[rowNumber].stackable,
      });
    }

    if (packageTemplates.templates.length === 0 || !fetchedTemplates) {
      dispatch(actions.fetchPackageTemplate({ userId, token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //update if packagesTemplates added for first time
    if (!fetchedTemplates && packageTemplates.templates.length !== 0) {
      setFetchedTemplates({
        ...fetchedTemplates,
        menuItems: packageTemplates.templates,
      });
    }
    //update if template is added
    if (fetchedTemplates && packageTemplates.templates.length > fetchedTemplates.menuItems.length) {
      if (modalOpen) {
        handleCloseModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageTemplates]);

  useEffect(() => {
    updatePackagesErrors(rowNumber, errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    //reload values if action from parent
    if (reloadKey > 1) {
      setInputs(initialInputs);

      setError({
        length: false,
        width: false,
        height: false,
        weight: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadKey]);

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleOpenModal = () => {
    //validate if package values are correct before opening adding dialog
    const templateErrors = Object.assign({}, errors);
    //correct for adding template, weight can be empty
    if (inputs.weight === '') {
      templateErrors.weight = false;
    }

    const validated = Object.keys(templateErrors).every((key) => templateErrors[key] === false);
    if (!validated) {
      setSnackbar({
        open: true,
        messages: [translations.templates.incorrectfields],
        variant: 'error',
      });
      return;
    }

    const yupValid = ValidatePackageTemplateDimensions(inputs, lang);

    yupValid.then((resp) => {
      if (resp.valid) {
        //POST package to backend
        setModalOpen(true);
      } else {
        setSnackbar({
          open: true,
          messages: resp.errors,
          variant: 'error',
        });
      }
    });
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value.replace(',', '.');

    setInputs({ ...inputs, [field]: value });
  };
  const handleTextFieldChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setInputs({ ...inputs, [name]: value });
  };
  const handleTextFieldBlur = (e) => {
    const {
      target: { name, value },
    } = e;
    setInputs({ ...inputs, [name]: value });
    //send package row data to parent
    updatePackageRow(rowNumber, { ...inputs, [name]: value });
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleBlur = (field) => (e) => {
    const float = parseFloat(e.target.value);

    if (isNaN(float)) {
      setError({ ...errors, [field]: true });

      //if called from (online)booking component, error value must be passed for validation to work
      if (type === 'booking') {
        updatePackageRow(rowNumber, inputs);
      }
    } else {
      if (errors[field]) setError({ ...errors, [field]: false });

      setInputs({ ...inputs, [field]: Math.round(float * 100) / 100 });
      //send package row data to parent
      updatePackageRow(rowNumber, { ...inputs, [field]: Math.round(float * 100) / 100 });
    }
  };

  const handleCountDecrease = () => {
    const updatedCount = inputs.count - 1;
    if (inputs.count > 1) {
      setInputs({
        ...inputs,
        count: updatedCount,
      });

      updatePackageRow(rowNumber, { ...inputs, count: updatedCount });
    }
  };
  const handleCountIncrease = () => {
    const updatedCount = inputs.count + 1;
    if (inputs.count < 100) {
      setInputs({
        ...inputs,
        count: updatedCount,
      });
    }

    updatePackageRow(rowNumber, { ...inputs, count: updatedCount });
  };

  const handleNumberChange = (e) => {
    if (e.target.value === '') {
      setInputs({
        ...inputs,
        count: 0,
      });
      return;
    }

    const float = parseFloat(e.target.value);

    if (isNaN(float)) return;
    if (float > 999) return;
    setInputs({
      ...inputs,
      count: float,
    });

    updatePackageRow(rowNumber, { ...inputs, count: float });
  };
  const handleNumberBlur = (e) => {
    if (e.target.value === '') {
      setInputs({
        ...inputs,
        count: 1,
      });
      updatePackageRow(rowNumber, { ...inputs, count: 1 });
      return;
    }
  };

  const handleStackableChange = () => {
    const updatedStackable = !inputs.stackable;
    setInputs({ ...inputs, stackable: updatedStackable });
    updatePackageRow(rowNumber, { ...inputs, stackable: updatedStackable });
  };

  const handlePackagingSelectChange = (packagingValues) => {
    const {
      packaging,
      length,
      width,
      height,
      weight,
      reference = '',
      templateName = null,
      stackable = true,
    } = packagingValues;

    setInputs({
      ...inputs,
      packaging,
      length,
      width,
      height,
      weight,
      reference,
      templateName,
      stackable,
    });

    updatePackageRow(
      rowNumber,
      {
        packaging,
        count: inputs.count,
        length,
        width,
        height,
        weight,
        reference,
        templateName,
        stackable,
      },
      errors
    );
  };

  const handleAddTemplate = () => {
    const { width, length, height, weight, packaging } = inputs;
    const packageTemplateValues = {
      width,
      length,
      height,
      weight,
      packaging,
      templateName: templateName,
    };
    const yupValid = ValidatePackageTemplate(packageTemplateValues, lang);

    yupValid
      .then((resp) => {
        if (resp.valid) {
          //POST package template to backend
          dispatch(actions.addPackageTemplate(packageTemplateValues, token));
        } else {
          setSnackbar({
            open: true,
            messages: resp.errors,
            variant: 'error',
          });
        }
      })
      .catch((err) => {
        //May  not be needed
        setSnackbar({
          open: true,
          messages: [translations.templates.incorrectfields],
          variant: 'error',
        });
      });
  };

  const classes = useStyles();
  const showPackagingDetails = !(
    inputs.packaging === UPS_EXPRESS_ENVELOPE ||
    inputs.packaging === UPS_EXPRESS_TUBE ||
    // inputs.packaging === UPS_EXPRESS_PAK ||
    inputs.packaging === UPS_EXPRESS_BOX
  );

  return (
    <div className={classes.root}>
      <div className={classes.stackableOptionContainer}>
        <Checkbox
          checked={!inputs.stackable}
          onClick={handleStackableChange}
          style={{ padding: 0 }}
        />
        <div style={{ display: 'flex' }}>
          <p style={{ fontSize: '0.95rem' }}>{translations.packageIsNonStackable}?</p>

          <Tooltip title={translationsTooltips[lang].nonStackableCargo}>
            <InfoIcon className={classes.stackableToolTip} />
          </Tooltip>
        </div>
      </div>
      <div className={classes.packageTemplatesPresets}>
        <FavouritesSelect
          tooltip={translations.packagedetails.tooltips.favorites}
          id={rowNumber}
          change={handlePackagingSelectChange}
          templateData={fetchedTemplates}
          noTemplatesString={translations.templates.notemplatesfound}
          color="#fab80a"
        />
        <PackagingSelect
          tooltip={translations.packagedetails.tooltips.ups}
          translations={translations.packagedetails}
          id={rowNumber}
          change={handlePackagingSelectChange}
          templateData={presetPackaging}
          descriptionIcon={packageTypeIcon}
        />
      </div>
      <div className={classes.numberSpinner}>
        {mobileView ? (
          <NumberSpinnerMobile
            title={translations.packagedetails.count}
            handleNumberChange={handleNumberChange}
            handleNumberBlur={handleNumberBlur}
            upClick={handleCountIncrease}
            downClick={handleCountDecrease}
            count={inputs.count}
          />
        ) : (
          <NumberSpinner
            title={translations.packagedetails.count}
            handleNumberChange={handleNumberChange}
            handleNumberBlur={handleNumberBlur}
            upClick={handleCountIncrease}
            downClick={handleCountDecrease}
            count={inputs.count}
          />
        )}
      </div>
      {showPackagingDetails ? (
        <>
          <TextField
            value={inputs.length}
            className={classes.numberField}
            label={translations.packagedetails.length}
            name="length"
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  classes={{
                    root: classes.inputAdornment,
                  }}
                  position="end"
                >
                  <p>cm</p>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            onChange={handleChange('length')}
            onBlur={handleBlur('length')}
            error={errors.length === true}
          />
          <TextField
            value={inputs.width}
            className={classes.numberField}
            label={translations.packagedetails.width}
            name="width"
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  classes={{
                    root: classes.inputAdornment,
                  }}
                  position="end"
                >
                  <p>cm</p>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            onChange={handleChange('width')}
            onBlur={handleBlur('width')}
            error={errors.width === true}
          />
          <TextField
            value={inputs.height}
            className={classes.numberField}
            label={translations.packagedetails.height}
            name="height"
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  classes={{
                    root: classes.inputAdornment,
                  }}
                  position="end"
                >
                  <p>cm</p>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            onChange={handleChange('height')}
            onBlur={handleBlur('height')}
            error={errors.height === true}
          />
          <TextField
            value={inputs.weight}
            className={classes.numberField}
            label={translations.packagedetails.weight}
            name="weight"
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  classes={{
                    root: classes.inputAdornment,
                  }}
                  position="end"
                >
                  <p>kg</p>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            onChange={handleChange('weight')}
            onBlur={handleBlur('weight')}
            error={errors.weight === true}
          />

          <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle className={classes.modalTitle} id="add-template-dialog-title">
              {translations.templates.new}{' '}
              {translations.templates.template.slice(0, 1).toLowerCase() +
                translations.templates.template.slice(1)}
            </DialogTitle>
            <div className={classes.dialogContent}>
              <h1 className={classes.dialogInfo}>{translations.templates.nametemplate}</h1>
              {packageTemplates.loading ? (
                <CircleSpinnerSmall />
              ) : (
                <TextField
                  required
                  inputProps={{ maxLength: 15 }}
                  className={classes.numberField}
                  value={templateName}
                  label={translations.packagedetails.name}
                  onChange={handleTemplateNameChange}
                />
              )}
              <div className={classes.buttonContainer}>
                <Button onClick={handleAddTemplate} className={classes.addTemplateButton}>
                  {translations.templates.save}{' '}
                  {translations.templates.template.slice(0, 1).toLowerCase() +
                    translations.templates.template.slice(1)}
                </Button>
                <Button onClick={handleCloseModal} className={classes.abortTemplateButton}>
                  {translations.templates.cancel}
                </Button>
              </div>
            </div>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackbar.open}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
          >
            <SnackbarContentWrapper
              onClose={handleSnackbarClose}
              variant={snackbar.variant}
              messages={snackbar.messages}
            />
          </Snackbar>
        </>
      ) : (
        <div className={classes.predefinedInputContainer}>
          <div className={classes.predefinedInputDetails}>
            <p>{inputs.templateName}</p>
            <img src={getPackagingImage(inputs.packaging)} alt="Packaging description" />
          </div>

          <TextField
            value={inputs.weight}
            className={classes.numberField}
            label={translations.packagedetails.weight}
            name="weight"
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  classes={{
                    root: classes.inputAdornment,
                  }}
                  position="end"
                >
                  <p>kg</p>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            onChange={handleChange('weight')}
            onBlur={handleBlur('weight')}
          />
        </div>
      )}
      <div className={classes.packageSpecificReference}>
        <div
          style={{
            width: 'min-content',
            height: 'min-content',
          }}
        >
          {showPackagingDetails && (
            <Tooltip title={translations.packagedetails.tooltips.savepackagetemplate}>
              <Button onClick={handleOpenModal} variant="contained" className={classes.saveButton}>
                <StarIcon
                  style={{
                    fontSize: 22,
                    position: 'absolute',
                    top: 3,
                    left: 4,
                    color: grey[600],
                  }}
                />
                <PlusIcon
                  style={{
                    fontSize: 13,
                    position: 'absolute',
                    bottom: 0,
                    right: 3,
                    color: grey[600],
                  }}
                />
              </Button>
            </Tooltip>
          )}
        </div>
        {
          /* type === 'booking' &&  */ <div
            style={{
              width: 'min-content',
              height: 'min-content',
              alignSelf: 'self-end',
            }}
          >
            <PackageSpecificReferenceButton
              inputs={inputs}
              showContentRow={showContentRow}
              setShowContentRow={setShowContentRow}
            />
          </div>
        }
      </div>
      {showContentRow && (
        <div className={classes.packageReferenceRow}>
          <TextField
            value={inputs.reference}
            // className={classes.textField}
            label="Reference"
            name="reference"
            onChange={handleTextFieldChange}
            onBlur={handleTextFieldBlur}
            className={classes.packageReference}
          />
        </div>
      )}
    </div>
  );
};

export default PackageInputs;
