import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import * as actions from '../../../../../store/actions';
//material ui
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';

const useStyles = makeStyles({

  changeAvatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    height: '350px',
  },
  button: {
    width: '130px',
    height: '30px'
  }

})

const ChangeAvatar = (props) => {

  const { userId } = useSelector(state => state.user);
  const classes = useStyles();
  const editorEl = useRef(null);
  const [image, setImage] = useState(null)

  const onChange = (event) => {
    if (event.target.files[0] !== undefined) {
      setImage(event.target.files[0])
    }

  }
  const onClickSave = () => {
    if (editorEl.current) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //const canvas = editorEl.current.getImage()

      // If you want the image resized to the canvas size (type HTMLCanvasElement)
      const canvasScaled = editorEl.current.getImageScaledToCanvas().toDataURL();

      fetch(canvasScaled)
        .then(res => res.blob())
        .then(blob => {
          let imageURL = window.URL.createObjectURL(blob);

          const imageData = new File([blob], image.name, { type: blob.type })
          var formData = new FormData();
          formData.append('avatar', imageData);


          props.onAddUserUploadImage(formData, userId);
          props.updateAvatar(imageURL)

        })
    }
  }

  return (
    <article className={classes.changeAvatar}>
      <AvatarEditor
        ref={editorEl}
        image={image || props.companyLogo}
        width={150}
        height={150}
        border={20}
        borderRadius={100}
        color={[255, 255, 255, 0.7]} // RGBA
        scale={1}
        rotate={0}
      // style={{border:'1px solid black'}}
      />
      <input
        onChange={(event) => onChange(event)}
        accept="image/*"
        /* className={classes.input} */
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" className={classes.button}>
          {props.buttons.uploadimage}
        </Button>
      </label>

      <div style={{ margin: '20px 0' }}>
        {image ? (<Button onClick={() => onClickSave()} color='primary' variant="outlined">{props.buttons.save}</Button>) : null}
        <Button onClick={() => props.toggleModal()} color='secondary' variant="outlined">{props.buttons.cancel}</Button>
      </div>

    </article>
  )
}

const mapStateToProps = state => {

  return {
    loading: state.userUpload.loading
  }

}
const mapDispatchToProps = dispatch => {

  return {
    onAddUserUploadImage: (formData, userId) => dispatch(actions.avatarUpload(formData, userId))
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAvatar);
