import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/map';
import { getTheme } from '../../../services/statistics/chartThemes';
import Paper from '@material-ui/core/Paper';
import geoJson from './world';
import { useSelector } from 'react-redux';
import translations from '../../../services/translations/translations';
import { deepOrange, red } from '@material-ui/core/colors';
const WorldExportMap = ({ data: shipmentData, theme, lang }) => {
  echarts.registerTheme(theme, getTheme[theme]);
  echarts.registerMap('World', geoJson);
  const countries = useSelector((state) => state.countries.countriesData);
  let geoNames = [];
  let maxValue = 0;

  if (countries) {
    for (let country of countries) {
      geoNames[country['countryCode']] = country['geoName'];
    }
  }

  const geoJsonCountryNames = geoJson['features'].map((obj) => obj.properties.name);
  const shipmentCountryData = shipmentData
    .filter((shpmt) => shpmt.flow === 'export')
    .reduce((acc, curr) => {
      let countryCode = curr.recipientAddressCountryCode;
      let existIdx = null;
      if (!countryCode) return acc;
      acc.forEach((val, idx) => {
        if (val['name'] === geoNames[countryCode]) {
          existIdx = idx;
          return;
        }
      });
      if (existIdx === null) acc.push({ name: geoNames[countryCode], value: 1 });
      if (existIdx !== null) {
        const updatedValue = ++acc[existIdx]['value'];
        acc.splice(existIdx, 1, {
          name: geoNames[countryCode],
          value: updatedValue,
        });
        if (updatedValue > maxValue) maxValue = updatedValue;
      }

      return acc;
    }, []);

  const countriesData = shipmentCountryData.map((item) => item.name);

  const options = {
    title: {
      text: translations[lang].statistics.worldExport,
      subtext: translations[lang].statistics.dividedByCountry,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}<br/>{c} (shipments)',
    },
    toolbox: {
      show: true,
      orient: 'horizontal',
      right: 30,
      top: 10,
      feature: {
        dataView: {
          lang: [
            translations[lang].statistics.dataView,
            translations[lang].statistics.turnOff,
            translations[lang].statistics.refresh,
          ],
          title: translations[lang].statistics.showData,
          readOnly: false,
        },
        restore: { title: translations[lang].statistics.refresh },
        saveAsImage: { title: translations[lang].statistics.saveAsImage },
      },
    },
    visualMap: {
      min: 0,
      max: maxValue || 1,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      inRange: {
        color: ['white', deepOrange[800], red[700], red[900]],
      },
    },
    series: [
      {
        name: 'World',
        type: 'map',
        mapType: 'World',
        label: {
          show: false,
        },
        data: geoJsonCountryNames.map((country) =>
          countriesData.includes(country)
            ? shipmentCountryData.find((obj) => obj.name === country)
            : { name: country, value: 0 }
        ),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default WorldExportMap;
