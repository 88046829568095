import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function InvoiceFormAutocomplete({
  templateSelected,
  styles,
  defaultValue,
  options,
  disabled = false,
  // handleBlur,
  label,
}) {
  const handleValueChange = (newValue) => {
    if (newValue) {
      templateSelected(newValue);
    }
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        key={defaultValue}
        id={`invoice-form-templates`}
        className={styles}
        onChange={(event, newValue) => {
          if (newValue === null) {
            templateSelected(null);
          } else {
            handleValueChange(newValue);
          }
        }}
        defaultValue={defaultValue}
        // onBlur={(e) => handleBlur(e)}
        options={options.sort((a, b) => a.templateName.localeCompare(b.templateName))}
        getOptionSelected={(option, value) => {
          if (typeof option === 'object') {
            return option.templateName === value;
          } else {
            return option;
          }
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'object') {
            return option.templateName;
          } else {
            return option;
          }
        }}
        renderInput={(params) => {
          //NOTE: temporary fix as chrome ignores autoComplete='off'
          const paramsChanged = Object.assign({}, params);
          paramsChanged['inputProps'] = Object.assign({}, params.inputProps);
          paramsChanged['inputProps'].autoComplete = 'new-password';
          paramsChanged['inputProps'].maxLength = 35;

          return (
            <TextField
              variant="outlined"
              {...paramsChanged}
              label={label}
              size="small"
              name="templateName"
              fullWidth
            />
          );
        }}
      />
    </>
  );
}
