import { makeStyles } from '@material-ui/core';
import { blue, grey, lime } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import translationsObj from '../../../services/translations/translations.json';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '35px',
    fontSize: '0.9rem',
    backgroundColor: lime[50],
    color: grey[900],
    borderBottom: `1px solid ${grey[200]}`,
    position: 'relative',
    display: 'grid',
    //set maxiumum column width of first to max-content to avoid overflow
    gridTemplateColumns: '1fr max-content',
    gridGap: '0.5rem',
    padding: '0 0.5rem',
    boxSizing: 'border-box',
    paddingRight: '2rem',
  },
  infoContainer: {},
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '2px',
    '& p': {
      color: blue[700],
      transform: 'translateY(1px)',
    },
  },
  costContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '220px',
    fontSize: '1rem',
  },
  costTitle: {
    fontWeight: '400',
    color: grey[800],
    fontSize: '1.05rem',
  },
  costValue: {
    fontWeight: '500',
    color: blue[700],
  },
  notAvailable: {
    color: grey[600],
    fontWeight: 400,
    paddingLeft: '4px',
    fontSize: '0.95rem',
  },
  '@media (max-width:740px)': {
    costContainer: {
      minWidth: '160px',
      fontSize: '0.9rem',
    },
    costTitle: {
      fontWeight: '400',
      fontSize: '0.9rem',
    },
    costValue: {
      fontWeight: '500',
    },
    notAvailable: {
      fontWeight: 400,
      fontSize: '0.8rem',
    },
  },
}));

export const getIconByVariant = (variant) => {
  switch (variant) {
    case 'info':
      return <InfoIcon style={{ fontSize: '17px', color: grey[700] }} />;
    default:
      return null;
  }
};

export default function OnlineBookingStatusBar() {
  const booking = useSelector((state) => state.booking);
  const { messages } = useSelector((state) => state.bookingStatus);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const translations = translationsObj[lang];

  const classes = useStyles();

  const { totalRate, currencyCode } = booking;
  const totalCost = totalRate && currencyCode ? `${totalRate} ${currencyCode}` : null;

  return (
    <div className={classes.root}>
      <div className={classes.infoContainer}>
        {messages.length > 0 &&
          messages.map((message) => (
            <div className={classes.messageContainer} key={message.id}>
              {getIconByVariant(message.variant)}
              <p style={{ fontWeight: message.priority === 'low' ? 400 : 500 }}>{message.text}</p>
            </div>
          ))}
      </div>
      <div className={classes.costContainer}>
        <p className={classes.costTitle}>{translations.totalCost}:&nbsp;</p>
        {totalCost && <p className={classes.costValue}>{totalCost}</p>}
        {!totalCost && <p className={classes.notAvailable}>N/A</p>}
      </div>
    </div>
  );
}
