import React from 'react';
import { useSelector } from 'react-redux';
import translations from '../../services/translations/translations';
import RecipientTemplate from '../../components/Template/RecipientTemplate/RecipientTemplate';
import SenderTemplate from '../../components/Template/SenderTemplate/SenderTemplate';
import PackageTemplate from '../../components/Template/PackageTemplate/PackageTemplate';
import ProductTemplate from '../../components/Template/ProductTemplate/ProductTemplate';
//material ui components
import { makeStyles } from '@material-ui/core';
import InvoiceFormTemplates from '../../components/Template/InvoiceFormTemplates/InvoiceFormTemplates';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '15px',
    justifyItems: 'center',
    '& > *': {
      maxWidth: '1000px',
      width: '100%',
    },
  },
  senderTemplates: {
    gridColumn: '1 / span 3',
  },
  recipientTemplates: {
    gridColumn: '4 / span 3',
  },
  packageTemplates: {
    gridColumn: '1 / span 3',
  },
  productTemplates: {
    gridColumn: '4 / span 3',
  },
  invoiceFormTemplates: {
    gridColumn: '4 / span 3',
  },
  '@media (max-width:1650px)': {
    senderTemplates: {
      gridColumn: '1 / span 6',
    },
    recipientTemplates: {
      gridColumn: '1 / span 6',
    },
    packageTemplates: {
      gridColumn: '1 / span 6',
    },
    productTemplates: {
      gridColumn: '1 / span 6',
    },
    invoiceFormTemplates: {
      gridColumn: '1 / span 6',
    },
  },
});

const Templates = () => {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.senderTemplates}>
        <SenderTemplate translations={translations[lang]} />
      </div>
      <div className={classes.recipientTemplates}>
        <RecipientTemplate translations={translations[lang]} />
      </div>
      <div className={classes.packageTemplates}>
        <PackageTemplate translations={translations[lang]} />
      </div>
      <div className={classes.productTemplates}>
        <ProductTemplate translations={translations[lang]} />
      </div>
      <div className={classes.invoiceFormTemplates}>
        <InvoiceFormTemplates translations={translations[lang]} />
      </div>
    </div>
  );
};

export default Templates;
