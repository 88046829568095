import { currencies } from '../constants/currencies';

//CurrencyCodes which should be visible for user, expand array if necessary
const applicableCurruncyCodes = [
  'EUR',
  'SEK',
  'USD',
  'JPY',
  'CAD',
  'AED',
  'AUD',
  'CHF',
  'NOK',
  'NZD',
  'GBP',
];

export const getApplicableCurrencies = () => {
  return currencies.filter((currencyObj) => applicableCurruncyCodes.includes(currencyObj.code));
};

export const getApplicableCurrencyCodes = () => {
  return currencies
    .filter((currencyObj) => applicableCurruncyCodes.includes(currencyObj.code))
    .map((currencyObj) => currencyObj.code);
};

export const buildOptionsFromApplicableCurrencyCodes = () => {
  return getApplicableCurrencyCodes().map((currencyCode) => ({
    value: currencyCode,
    label: currencyCode,
  }));
};
