import { useMediaQuery } from '@material-ui/core';
import React, { Fragment } from 'react';
import DesktopTrackingDetails from './DesktopTrackingDetails';
import MobileTrackingDetails from './MobileTrackingDetails';

export default function TrackingDetails(props) {
  const maxWidth600 = useMediaQuery('(max-width:600px)');

  return (
    <Fragment>
      {maxWidth600 ? <MobileTrackingDetails {...props} /> : <DesktopTrackingDetails {...props} />}
    </Fragment>
  );
}
