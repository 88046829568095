import { UPS } from '../../types';
import { invoiceFormSchema } from '../schemas';

export const ValidateInvoiceFormByCarrier = ({ carrier, invoiceForm, lang = 'en' }) => {
  let schema;
  switch (carrier) {
    case UPS:
      schema = invoiceFormSchema;
      break;
    default:
      return { valid: false, errors: ['Unable to locate carrier'], variant: 'error' };
  }

  return validateInvoiceForm({ schema, invoiceForm, lang });
};

const validateInvoiceForm = async ({ schema, invoiceForm, lang }) => {
  const productValidation = await schema(lang)
    .validate(invoiceForm)
    .then((valid) => ({ valid: true, errors: null }))
    .catch((err) => ({ valid: false, errors: err.errors, variant: 'error' }));

  return productValidation;
};
