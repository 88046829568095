import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { getCarrierIcon } from '../../../utils/carrier/carrierIcons';
import translations from '../../../services/translations/translations';
import translationsCommonCodes from '../../../services/translations/translationsCommonCodes';
import servicesTranslations from '../../../services/translations/translationsShippingServices.json';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import Button from '@material-ui/core/Button';
//icons
import OnlineBookingIcon from '../../UI/Icons/OnlineBooking';
import RequestBookingIcon from '../../UI/Icons/RequestBooking';
import SearchIcon from '@material-ui/icons/Search';
import { getLocaleFromLang } from '../../../services/helpers/localize';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    minHeight: '200px',
    width: '100%',
    '& > div:nth-child(even)': {
      background: '#f5f5f5',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateY(50%)',
  },
  headers: {
    display: 'grid',
    gridTemplateColumns: '50px minmax(200px, 300px) minmax(100px,auto) 114px',
    width: '100%',
    gap: '10px',
    borderBottom: '1px solid #a3a2a2',
    borderTop: '1px solid #a3a2a2',
    placeItems: 'center',
    color: 'black',
    fontSize: '1.05rem',
    fontWeight: 500,
    padding: '5px 10px',
    boxSizing: 'border-box',
  },
  serviceRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    borderBottom: '1px solid #d8d8d8',
  },
  serviceRow: {
    width: '100%',
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: '50px minmax(200px, 300px) minmax(100px,auto) 114px',
    alignItems: 'center',
    gap: '10px',
    '& > div:not(:first-child)': {
      justifySelf: 'center',
    },
    paddingTop: '4px',
  },
  carrierIcon: {
    width: '100%',
    maxHeight: '30px',
    alignSelf: 'center',
  },
  priceCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '1.1rem',
    width: '100%',
    padding: '5px',
    color: grey[800],
  },
  serviceNameCell: {
    width: '100%',
    fontSize: '1.1rem',
    position: 'relative',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    '& > p:first-child': {},
    '& > p:not(:first-child)': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'start',
    color: '#0a0a0a',
    backgroundColor: '#c7c7c7',
    margin: '3px',
    padding: '2px 10px',
    fontSize: '0.8rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  leadTimes: {
    gridColumn: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.95rem',
    fontWeight: 500,
    '& > p': {
      padding: '0 10px',
    },
  },
  eta: {
    gridColumn: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.95rem',
    fontWeight: 500,
    '& > p': {
      padding: '0 10px',
      fontSize: '0.9rem',
      fontWeight: 400,
    },
    '& > span': {
      fontWeight: 500,
    },
  },
  additionalDetailsContainer: {
    padding: '4px 0',
    borderTop: '1px dotted #d8d8d8',
  },
  additionalDetailsPrimary: {
    display: 'grid',
    gridTemplateColumns: '50px minmax(200px, 300px) 1fr',
    gap: '10px',
    alignItems: 'center',
    justifyItems: 'center',
  },
  additionalDetailsSecondary: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
  },
  surchargesDetails: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    '& > p': {
      flex: 1,
      fontWeight: 300,
      textAlign: 'left',
      borderBottom: '1px solid #eee',
    },
    '& > p + p': {
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  surchargeText: {
    fontStyle: 'italic',
  },
  surchargesButton: {
    gridColumn: 2,
    width: 'fit-content',
    padding: '0 15px',
  },
  error: {
    textAlign: 'center',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      '& > div:not(:last-child)': {
        borderBottom: '4px solid #b7b7b7',
      },
    },
    headers: {
      display: 'none',
    },
    serviceRow: {
      gridTemplateColumns: '50px auto',
    },
    serviceNameCell: {
      fontSize: '1rem',
      fontWeight: 500,
      gridRow: '1',
      gridColumn: '1/-1',
    },
    carrierIcon: {
      maxHeight: '25px',
      gridRow: '1',
      gridColumn: '1',
    },
    priceCell: {
      gridColumn: '1 / -1',
      fontSize: '0.9rem',
    },
    additionalDetailsPrimary: {
      gridTemplateColumns: '1fr',
      gridRow: 3,
      gridColumn: '1 / -1',
    },
    buttonsContainer: {
      gridRow: 4,
      flexDirection: 'row',
      gridColumn: '1 / -1',
    },
    leadTimes: {
      gridColumn: '1 / -1',
      justifyContent: 'center',
    },
    eta: {
      gridColumn: '1 / -1',
      justifyContent: 'center',
    },
  },
}));

const RateResult = ({ redirect }) => {
  const classes = useStyles();
  const [surchargesShown, setSurchargesShown] = useState([]);
  const rates = useSelector((state) => state.rates);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const locale = getLocaleFromLang(lang);
  moment.locale(locale);

  const handleRedirect = (path) => {
    redirect(path);
  };

  const handleShowSurcharges = (idx) => {
    const arr = new Set(surchargesShown);
    if (arr.has(idx)) {
      arr.delete(idx);
    } else {
      arr.add(idx);
    }

    setSurchargesShown(Array.from(arr));
  };

  const formatEta = (eta) => {
    if (!eta || !eta.date) return null;
    if (eta.timeEnd === '233000')
      return `${translations[lang].deliveredBy}: ${moment(eta.date, 'YYYYMMDD').format('LL')} - ${
        translations[lang].byEndOfDay
      }`;

    if (eta.timeStart) {
      const timeStart = `${eta.timeStart.substring(0, 2)}:${eta.timeStart.substring(2, 4)}`;
      const timeEnd = `${eta.timeEnd.substring(0, 2)}:${eta.timeEnd.substring(2, 4)}`;
      return `${moment(`${eta.date}`).format('LL')} - ${
        translations[lang].between
      } ${timeStart} - ${timeEnd}`;
    }

    return `${translations[lang].deliveredBy}: ${moment(`${eta.date}T${eta.timeEnd}`).format(
      'LLL'
    )}`;
  };

  const formatTimeInTransit = (businessDays, totalDays) => {
    let timeInTransitString = '';

    if (businessDays)
      timeInTransitString = timeInTransitString.concat(
        `${businessDays} ${translations[lang].rates.rateresult.workingdays}`
      );
    if (totalDays) {
      if (businessDays)
        timeInTransitString = timeInTransitString.concat(
          ` ${totalDays} ${translations[lang].rates.rateresult.total}`
        );
      if (!businessDays)
        timeInTransitString = timeInTransitString.concat(
          `${totalDays} ${translations[lang].rates.rateresult.days} ${translations[lang].rates.rateresult.total}`
        );
    }

    return timeInTransitString;
  };

  return (
    <div className={classes.root}>
      {rates.loading && (
        <div className={classes.spinner}>
          <CircleSpinnerSmall />
        </div>
      )}

      {rates.rateData && !rates.error && (
        <>
          <header className={classes.headers}>
            <div></div>
            <div>{translations[lang].rates.rateresult.serviceName}</div>
            <div>{translations[lang].rates.rateresult.price}</div>
            <div>{translations[lang].rates.rateresult.booking}</div>
          </header>
          {rates.rateData.map((ratedPackage, idx) => {
            // let isLeadTimes = Boolean(ratedPackage.businessDaysInTransit);
            const tnt = formatTimeInTransit(
              ratedPackage.businessDaysInTransit,
              ratedPackage.totalTransitDays
            );
            const eta = formatEta(ratedPackage.eta);
            return (
              <div key={idx} className={classes.serviceRowContainer}>
                <div className={classes.serviceRow} key={ratedPackage.serviceName}>
                  <img
                    className={classes.carrierIcon}
                    src={getCarrierIcon(ratedPackage.carrier)}
                    alt="Carrier logotype"
                  />
                  <div className={classes.serviceNameCell}>
                    <p>
                      {ratedPackage.serviceName
                        ? ratedPackage.serviceName
                        : servicesTranslations[lang][ratedPackage.serviceCode]}
                    </p>
                    {ratedPackage.deliveryMethod &&
                      translationsCommonCodes[lang].DeliveryMethods[
                        ratedPackage.deliveryMethod
                      ] && (
                        <p>
                          {
                            translationsCommonCodes[lang].DeliveryMethods[
                              ratedPackage.deliveryMethod
                            ]
                          }
                        </p>
                      )}
                  </div>
                  <div className={classes.priceCell}>
                    <div>
                      {ratedPackage.totalRate} {ratedPackage.currencyCode}
                    </div>
                  </div>
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="contained"
                      onClick={() => handleRedirect('/bookings/onlinebooking')}
                      className={classes.buttons}
                    >
                      <OnlineBookingIcon
                        style={{ padding: '0 5px', fontSize: 18, color: '#585858' }}
                      />
                      <span>{translations[lang].rates.rateresult.online}</span>
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleRedirect('/bookings/requestbooking')}
                      className={classes.buttons}
                    >
                      <RequestBookingIcon
                        style={{ padding: '0 5px', fontSize: 18, color: '#585858' }}
                      />{' '}
                      <span>{translations[lang].rates.rateresult.request}</span>{' '}
                    </Button>
                  </div>
                </div>
                <div className={classes.additionalDetailsContainer}>
                  <div className={classes.additionalDetailsPrimary}>
                    {ratedPackage.surcharges && ratedPackage.surcharges.length > 0 && (
                      <Button
                        onClick={() => handleShowSurcharges(idx)}
                        className={classes.surchargesButton}
                        variant="outlined"
                        color="primary"
                      >
                        <p>{translations[lang].rates.rateresult.additionalCostDetails}</p>
                        <SearchIcon style={{ paddingLeft: '10px' }} />
                      </Button>
                    )}
                    <div className={classes.leadTimes}>{tnt}</div>
                  </div>
                  {eta && (
                    <div className={classes.additionalDetailsSecondary}>
                      <div className={classes.eta}>
                        <p>{eta}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {surchargesShown.includes(idx) &&
                    ratedPackage.surcharges.map((surcharge) => (
                      <div key={surcharge.label} className={classes.surchargesDetails}>
                        {surcharge.label && <p>{surcharge.label}</p>}
                        {surcharge.value && (
                          <p>
                            {surcharge.value} <span>&nbsp;{surcharge.currency}</span>
                          </p>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </>
      )}
      {rates.error && (
        <div className={classes.error}>{translations[lang].rates.rateresult.norates}</div>
      )}
    </div>
  );
};

export default connect(null)(RateResult);
