import 'date-fns';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import translations from '../../services/translations/translations';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import 'moment/locale/fi';

export default function MaterialUIPickers({
  selectedDate,
  handleDateChange,
  disablePast = false,
  inline = false,
  locale = 'en',
  label = 'Shipping date',
}) {
  moment.locale(locale);

  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
      <KeyboardDatePicker
        disableToolbar
        variant={inline ? 'inline' : 'dialog'}
        margin='none'
        id='date-picker-dialog'
        label={label}
        format='YYYY-MM-DD'
        value={selectedDate}
        onChange={handleDateChange}
        autoOk={true}
        disablePast={disablePast}
        minDateMessage={translations[lang].noPastDates}
        invalidDateMessage={translations[lang].invalidDateFormat}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
