import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import { downloadDocument } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  shippingDocsTable: {
		width:'100%',
		borderCollapse:'collapse',
		'& th':{
			borderBottom:'1px solid black',
			fontSize:'0.9rem'
		},
		'& tr:nth-child(even)':{
			backgroundColor:'#f5f5f5'
		},
		'& td':{
			padding:'5px',
			textAlign:'center'
		},
		'& td:last-child':{
			display:'flex',
			justifyContent:'center',
			alignContent:'center',
			height:'100%'
		}
	},
	fileDownloadButton: {
		padding: '0 5px'
	},
});

const ShippingDocsTable = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <table key={'docstable'} className={classes.shippingDocsTable}>
      <tbody>
        <tr>
          <th>{props.translations.filename}</th>
          <th>{props.translations.doctype}</th>
          <th>{props.translations.lastmodified}</th>
          <th>{props.translations.download}</th>
        </tr>
        {Object.keys(props.documents).map(docType => {
          return (
            props.documents[docType].map(file => (
              <tr key={file.filename}>
                <td>{file.filename}</td>
                <td style={{color:'#1a6bd0'}}>{props.translations[docType]}</td>
                <td style={{fontSize:'0.9rem', fontWeight:500}}>{moment(file.modified).format('LLL')}</td>
                <td>
                  <Tooltip title={props.translations.filedownload}>
                    <IconButton className={classes.fileDownloadButton} onClick={() => dispatch(downloadDocument(file.path, file.filename))}>
                      <CloudDownloadIcon style={{ color: '#62485E' }} />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))
          )
        })}
      </tbody>
    </table>
  )
}

export default ShippingDocsTable



