import { TextField } from '@material-ui/core';
import React from 'react';

const ShipmentDescription = ({ label, value, onChange, onBlur, required = false }) => {
  return (
    <TextField
      inputProps={{ maxLength: 50 }}
      style={{ width: '100%', height: '50px' }}
      id="commodity-name"
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      margin="dense"
      required={required}
    />
  );
};

export default ShipmentDescription;
