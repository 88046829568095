import React, { useEffect, useState } from 'react';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableToolbarExtended from '../TableToolbars/EnhancedTableToolbarExtended';
import { getComparator, stableSort } from '../TableFeatures';
import EnhancedTableHeadExtended from '../TableHeads/EnhancedTableHeadExtended';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  tableCell: {
    padding: '0 5px',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export default function EnhancedTableExtended({
  title,
  selectedTitle,
  rows,
  sortByKey,
  headCells,
  toolbarProps,
  endAdornmentFields,
  toolbarFeatures,
  hiddenFields,
  loading = false,
  modals = [],
  ...actions
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(sortByKey);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //clean selected if component still mounted and rows removed
  useEffect(() => {
    if (rows.length === 0 && selected.length > 0) setSelected([]);

    if (rows.length > 5) setRowsPerPage(rows.length); // set initial rows per page in relation to rows length

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);

      setSelected(() => newSelected);
      return;
    }

    setSelected([]);
  };

  const deselectAll = () => {
    setSelected([]);
  };

  const isIdSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {modals.length > 0 &&
        modals.map((modal) =>
          modal.props.open ? React.cloneElement(modal, { deselectAll, key: modal.props.id }) : null
        )}
      <Paper className={classes.paper}>
        <EnhancedTableToolbarExtended
          title={title}
          selectedTitle={selectedTitle}
          toolbarProps={toolbarProps}
          toolbarFeatures={toolbarFeatures}
          loading={loading}
          selected={selected}
          {...actions}
        />
        <Table className={classes.table}>
          <EnhancedTableHeadExtended
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isSelected = isIdSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, n.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox disabled={loading} checked={isSelected} />
                    </TableCell>
                    {Object.keys(n)
                      .filter((field) => !hiddenFields.includes(field))
                      .map((field, idx) => {
                        let cellValue = n[field];
                        if (headCells[idx].customRowCells) {
                          cellValue = headCells[idx].customRowCells(cellValue, n.id);
                        }

                        return (
                          <TableCell
                            classes={{ root: classes.tableCell }}
                            key={field}
                            padding="none"
                            align={headCells[idx].align || 'left'}
                          >
                            {cellValue}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 43 * emptyRows }}>
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={'Rows per peage'}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
