import React from 'react';
import { CircleSpinnerLarge } from '../../../UI/Spinners/Circle';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    minHeight: '300px',
    padding: '30px',
  },
  responseContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > h2': {
      marginBottom: '10px',
      fontSize: '1.1rem',
      fontWeight: '400',
    },
    '& > p': {
      marginBottom: '10px',
      fontSize: '1rem',
      fontWeight: '400',
    },
  },
});

const RequestCollectionModal = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.loading && <CircleSpinnerLarge />}
      {props.error && (
        <div className={classes.responseContainer}>
          <h2 style={{ color: 'red' }}>Pickup request failed!</h2>
          <p>{props.error}</p>
          <Button onClick={props.handleEdit} color='primary' variant='contained'>
            Edit request
          </Button>
        </div>
      )}
      {props.data && (
        <div className={classes.responseContainer}>
          <h2 style={{ color: 'green' }}>Pickup request successfull!</h2>
          <p>Reference:&nbsp;{props.data.pickupNumber}</p>
        </div>
      )}
    </div>
  );
};

export default RequestCollectionModal;
