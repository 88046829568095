import { AppBar, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import CustomToolbar from '../../../UI/CustomToolbar/CustomToolbar';
import CevaTrackingView from '../TrackingViews/CevaTrackingView/CevaTrackingView';
import DhlFreightTrackingView from '../TrackingViews/DhlFreightTrackingView/DhlFreightTrackingView';
import ExpeditorsTrackingView from '../TrackingViews/ExpeditorsTrackingView/ExpeditorsTrackingView';
import GeodisTrackingView from '../TrackingViews/GeodisTrackingView/GeodisTrackingView';
import HrxTrackingView from '../TrackingViews/HrxTrackingView/HrxTrackingView';
import MatkahuoltoTrackingView from '../TrackingViews/MatkahuoltoTrackingView/MatkahuoltoTrackingView';
import SchenkerTrackingView from '../TrackingViews/SchenkerTrackingView/SchenkerTrackingView';
import UpsscsTrackingView from '../TrackingViews/UpsscsTrackingView/UpsscsTrackingView';
import UpsTrackingView from '../TrackingViews/UpsTrackingView/UpsTrackingView';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function TrackingViewProvider(props) {
  const trackingCarriers = {
    ups: <UpsTrackingView {...props} />,
    upsscs: <UpsscsTrackingView {...props} />,
    expeditors: <ExpeditorsTrackingView {...props} />,
    schenker: <SchenkerTrackingView {...props} />,
    ceva: <CevaTrackingView {...props} />,
    dhlfreight: <DhlFreightTrackingView {...props} />,
    matkahuolto: <MatkahuoltoTrackingView {...props} />,
    hrx: <HrxTrackingView {...props} />,
    geodis: <GeodisTrackingView {...props} />,
  };

  const carrierView = trackingCarriers[props.trackingState.carrier] || null;

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <AppBar elevation={0} position="static">
        <CustomToolbar title={props.translations.trackingResponse}></CustomToolbar>
      </AppBar>
      <section>{carrierView}</section>
    </Paper>
  );
}
