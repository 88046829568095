import * as yup from 'yup';
import errors from '../../../translations/errors';
import { countriesWithoutZip } from '../../../helpers/common';

export const requestBookingAddressSchema = (lang = 'en') => {
  return yup.object().shape({
    company: yup.string().max(35, errors.company.max[lang]),
    contact: yup.string().max(35, errors.contact.max[lang]),
    phone: yup.string().max(50, errors.phone.max[lang]),
    email: yup.string().email(errors.email[lang]),
    city: yup.string().max(30, errors.city.max[lang]),
    zip: yup.string().when('countryCode', {
      is: (countryCode) => !countriesWithoutZip.includes(countryCode),
      then: yup
        .string()
        .min(2, errors.zip.min[lang])
        .max(9, errors.zip.min[lang])
        .required(errors.zip.required[lang]),
      otherwise: yup.string().min(0),
    }),
    address1: yup.string().max(35, errors.addressline.max[lang]),
    address2: yup.string().max(35),
    countryName: yup.string().required(errors.countryname.required[lang]),
    countryCode: yup.string().length(2, errors.countryname.required[lang]),
    //stateRegion: '',
  });
};

export const requestBookingShipmentDetailsSchema = (lang = 'en') =>
  yup.object().shape({
    references: referencesSchema(lang),
    //TODO: when description field is added then add validation
    // description: yup.string().max(35, errors.commoditydescription.max[lang]),
  });

export const referencesSchema = (lang = 'en') => {
  yup.object().shape({
    reference1: yup.string().max(35, errors.shippingreference.max[lang]),
    reference2: yup.string().max(35, errors.shippingreference.max[lang]),
  });
};
export const flowValidationSchema = (lang = 'en') =>
  yup.mixed().oneOf(['domestic', 'import', 'export', 'third-party']).required();

export const transportModeValidationSchema = (lang = 'en') =>
  yup
    .mixed()
    .oneOf(['airExpress', 'airEconomy', 'roadFreight', 'seaFreight'], errors.flow.oneOf[lang]);

export const dateValidationSchema = (lang = 'en') =>
  //TODO: validate date is not set to previous current date
  yup.date();

export const stackableValidationSchema = (lang = 'en') =>
  yup.bool().typeError(errors.stackable.typeerror[lang]);

export const requestBookingSchema = (lang = 'en') =>
  yup.object().shape({
    sender: requestBookingAddressSchema(lang),
    recipient: requestBookingAddressSchema(lang),
    description: yup.string().max(35, errors.commoditydescription.max[lang]),
    reference: yup.string().max(50, errors.shippingreference.max[lang]),
    date: yup.date(),
  });
