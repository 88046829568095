import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  selectEmpty: {
    padding: '0px !important',
    '&:root': {
      background: 'blue',
    },
  },
  label: {
    fontSize: '0.75rem',
    color: 'gray',
    textAlign: 'center',
  },
  selectInput: {
    paddingRight: '20px',
  },
  selectContainer: {
    display: 'flex',
    padding: '0px',
    border: '1px solid #AFAFAF',
    borderRadius: '2px',
    minWidth: '0px',
  },
  button: {
    minWidth: 0,
    width: '100%',
    height: '45px',
    padding: '1px 0px 1px 2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonIcons: {
    display: 'grid',
    gridTemplateRows: '24px 16px',
    placeItems: 'center',
  },
  buttonIcon: {
    maxHeight: 24,
    maxWidth: 24,
    fontSize: '1.3rem',
  },
  menuItem: {
    fontSize: '1rem',
    minHeight: '28px',
    display: 'flex',
  },
  noTemplates: {
    fontSize: '1rem',
    minHeight: '28px',
    fontStyle: 'italic',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'default',
    },
  },
  carrierIcon: {
    marginRight: '15px',
    height: '24px',
  },
}));

const FavouritesSelect = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(packaging) {
    if (packaging.packaging !== undefined) {
      props.change(packaging);
    }
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <Tooltip title={props.tooltip}>
        <Button
          style={{ color: props.color ? props.color : '' }}
          className={classes.button}
          variant="outlined"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div className={classes.buttonIcons}>
            <StarIcon className={classes.buttonIcon} />
            <ArrowDropDownIcon style={{ color: '#4e4d4e' }} />
          </div>
        </Button>
      </Tooltip>

      <Menu
        id={props.id.toString()}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.templateData ? (
          props.templateData.menuItems.map((template, index) => (
            <MenuItem
              key={index}
              className={classes.menuItem}
              onClick={() => handleClose(template)}
            >
              <p>{template.templateName}</p>
            </MenuItem>
          ))
        ) : (
          <div>
            <MenuItem className={classes.noTemplates}>{props.noTemplatesString}</MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default FavouritesSelect;
