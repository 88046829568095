import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import AddressDetails from '../../../AddressDetails/AddressDetails';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  DialogContent: {
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    '& > section': {
      width: '100%',
    },
  },
  label: {
    fontSize: '0.9rem',
    fontWeight: '500',
    paddingBottom: '5px',
  },
  address: {
    maxWidth: '600px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '50%',
      minWidth: '200px',
    },
  },
}));

const initialRecipientDetails = {
  company: '',
  contact: '',
  phone: '',
  email: '',
  city: '',
  zip: '',
  address1: '',
  address2: '',
  countryName: '',
  countryCode: '',
  stateRegion: '',
};

export default function ModifyShipmentModal({
  open,
  handleAbort,
  shipment,
  handleUpdateShipmentData,
  translations,
}) {
  const [recipientAddress, setRecipientAddress] = useState(initialRecipientDetails);

  useEffect(() => {
    // setRecipientAddress({ ...initialRecipientDetails, company: shipment.company });

    setRecipientAddress({
      ...initialRecipientDetails,
      company: shipment.company,
      contact: shipment.contact,
      phone: shipment.phone,
      email: shipment.email,
      city: shipment.city,
      zip: shipment.zip,
      address1: shipment.address1,
      address2: shipment.address2,
      countryName: shipment.countryName,
      countryCode: shipment.countryCode,
      stateRegion: shipment.stateRegion,
    });
  }, [shipment]);

  const classes = useStyles();

  const handleTextBlur = (updatedAddressData) => (value) => {
    setRecipientAddress(updatedAddressData);
  };

  const handleSubmit = () => {
    handleUpdateShipmentData({ ...shipment, ...recipientAddress });
    handleAbort();
  };

  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby="modify-shipment-title"
      aria-describedby="modify-shipment-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle className={classes.modalTitle} id="label-recovery-dialog-title">
        Modify shipment
      </DialogTitle>
      <div className={classes.DialogContent}>
        <section className={classes.address}>
          <div className={classes.label}>Recipient address</div>
          <AddressDetails
            handleTextBlur={handleTextBlur}
            userDetails={recipientAddress}
            value="recipient"
          />
        </section>
        <div className={classes.buttons}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {translations.updateDetails}
          </Button>
          <Button variant="text" color="secondary" onClick={handleAbort}>
            {translations.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
