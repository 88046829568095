export function readFile(file) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = resolve;
    fr.readAsDataURL(file);
  });
}

export async function buildDocuments(documents) {
  var promise = Promise.resolve();
  let documentObjects = [];

  Object.keys(documents).forEach(async (key) => {
    for (let docFile of documents[key]) {
      const { file } = docFile;
      const { name } = file;
      const fileObject = promise
        .then(() => readFile(file))
        .then((res) => {
          return {
            name,
            type: key,
            file: res.target.result,
          };
        });

      documentObjects.push(fileObject);
    }
  });

  const result = await Promise.all(documentObjects);

  return result;
}

// type is either 'invoice' 'coo' or 'other'
export async function buildOneDocument(file, documentType) {
  const { name } = file;
  const fileObject = await readFile(file).then((res) => {
    return {
      name,
      type: documentType,
      file: res.target.result,
    };
  });

  return fileObject;
}
