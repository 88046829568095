import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomToolbar from '../../../UI/CustomToolbar/CustomToolbar';
//icons
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  trackingInputsContainer: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    // placeItems: 'center',
    justifyItems: 'center',
    alignItems: 'baseline',
    margin: '20px 0',
  },
  trackingInputsOptions: {
    display: 'flex',
    flexDirection: 'column',
    gridRow: 2,
  },
  optionButton: {
    padding: '5px 15px',
    margin: '5px 0',
  },
  trackingInputActions: {
    gridRow: 2,
    display: 'grid',
    gridTemplateColumns: 'min-content min-content',
    alignItems: 'center',
    transform: 'translateX(-30%) translate(-50px,20px)',
  },
  carrierSelects: {
    gridColumn: '1/-1',
    display: 'flex',
  },
  textField: {
    width: '400px',
  },
  trackingInput: {
    padding: '10px',
  },
  submitTrackingButton: {
    width: '50px',
    height: '47px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: '1px solid #3c3c3c',
    color: 'black',
    boxShadow: '1px 1px 2px #3838386b',
  },
  searchIcon: {
    fontSize: '1.7rem',
  },
  /* Media Queries */
  '@media (max-width:1400px)': {
    textField: {
      width: '300px',
    },
  },
  '@media (max-width:1200px)': {
    trackingInputActions: {
      transform: 'translate(-30%,20px)',
    },
  },
  '@media (max-width:1000px)': {
    trackingInputsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto 60px 150px',
      placeItems: 'center',
      margin: '20px 0',
      '& > p': {
        gridColumn: '1 / -1',
      },
    },
    trackingInputsOptions: {
      flexDirection: 'row',
      gridColumn: '1 / -1',
      alignSelf: 'self-start',
    },
    optionButton: {
      margin: '5px 5px',
    },
    trackingInputActions: {
      width: '100%',
      gridColumn: '1 / -1',
      gridRow: 3,
      display: 'flex',
      flexDirection: 'column',
      height: 'max-content',
      transform: 'translateX(0%)',
    },
    submitTrackingButton: {
      margin: '10px 0 0 0',
      width: '300px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    trackingInputsContainer: {
      gridTemplateRows: 'auto 60px max-content',
    },
    trackingInput: {
      fontSize: '0.9rem',
    },
    submitTrackingButton: {
      width: '75%',
    },
    textField: {
      width: '75%',
    },
  },
  '@media (max-width:400px)': {
    optionButton: {
      padding: '4px 10px',
      fontSize: '0.8rem',
      margin: '5px 2px',
    },
  },
}));

export default function TrackingInputForm({
  translations,
  handleOptionChange,
  handleSearchInputChange,
  searchOption,
  searchInput,
  showCarrierOptions,
  handleSubmit,
  setSelectedCarrier,
}) {
  const [trackingCarriers, setTrackingCarriers] = useState([]);

  const { companyCountryCode } = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (showCarrierOptions && trackingCarriers.length === 0) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      Axios.get(`/api/carriers/tracking-carriers/${companyCountryCode}`, config)
        .then((resp) => {
          const trackingCarrierAliasAndNames = resp.data.result;
          trackingCarrierAliasAndNames.length > 0 &&
            setTrackingCarriers(trackingCarrierAliasAndNames);
        })
        .catch((_) => {
          trackingCarriers.length > 0 && setTrackingCarriers([]);
        });
    }

    !showCarrierOptions && trackingCarriers.length > 0 && setTrackingCarriers([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCarrierOptions]);

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <AppBar elevation={0} position="static">
        <CustomToolbar title={translations.trackedshipments.trackShipment}></CustomToolbar>
      </AppBar>
      <section className={classes.trackingInputsContainer}>
        <p>{translations.trackedshipments.trackBy}</p>
        <div className={classes.trackingInputsOptions}>
          <Button
            onClick={() => handleOptionChange('tracking-number')}
            className={classes.optionButton}
            color="primary"
            variant={searchOption === 'tracking-number' ? 'contained' : 'outlined'}
          >
            {translations.trackedshipments.trackingnumber}
          </Button>
          <Button
            onClick={() => handleOptionChange('reference')}
            className={classes.optionButton}
            color="primary"
            variant={searchOption === 'reference' ? 'contained' : 'outlined'}
          >
            {translations.trackedshipments.reference}
          </Button>
        </div>
        <form onSubmit={handleSubmit(searchInput)} className={classes.trackingInputActions}>
          <TextField
            value={searchInput}
            className={classes.textField}
            label={translations.trackedshipments[searchOption]}
            variant="outlined"
            InputProps={{
              className: classes.trackingInput,
            }}
            onChange={handleSearchInputChange}
          />
          <Tooltip title={translations.trackedshipments.track}>
            <IconButton type="submit" variant="contained" className={classes.submitTrackingButton}>
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </Tooltip>
          {showCarrierOptions && trackingCarriers.length > 0 && (
            <div className={classes.carrierSelects}>
              <RadioGroup row aria-label="position" name="position">
                {trackingCarriers.map(({ carrierAlias, carrierName }) => (
                  <FormControlLabel
                    onClick={() => setSelectedCarrier(carrierAlias)}
                    value={carrierAlias}
                    control={<Radio color="primary" />}
                    label={carrierName}
                    key={carrierAlias}
                  />
                ))}
              </RadioGroup>
            </div>
          )}
        </form>
      </section>
    </Paper>
  );
}
