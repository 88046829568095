import React from 'react';
import { Button, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import CustomEnhancedToolbar from '../../UI/CustomEnhancedToolbar/CustomEnhancedToolbar';
import { HorizontalSpinner } from '../../UI/Spinners/Horizontal';

const useStyles = makeStyles({
  featureStyles: {
    marginRight: '25px',
  },
});

/*
Toolbar Used when multiple selection is available
*/
const EnhancedTableToolbarExtended = ({
  title,
  selectedTitle,
  selected,
  toolbarProps,
  toolbarFeatures,
  loading = false,
  ...actions
}) => {
  const classes = useStyles();

  const toolbarContent =
    selected.length > 0
      ? toolbarProps
          .filter(({ activateForIds, allowMultiple = false }) => {
            if (Array.isArray(activateForIds)) {
              // if activateForIds is an array, check that the selected ids does not inlude any id that is not in the activateForIds array
              return !selected.some((id) => !activateForIds.includes(id));
            }
            if (!allowMultiple && selected.length > 1) {
              return false;
            }
            return true;
          })
          .map((option) => (
            <div key={option['id']}>
              <Tooltip title={option.toolTip}>
                <IconButton onClick={() => actions[`${option['funcName']}`](selected)}>
                  {option.icon}
                </IconButton>
              </Tooltip>
            </div>
          ))
      : null;
  const toolbarFeatureContent =
    toolbarFeatures && selected.length > 0
      ? toolbarFeatures.map((feature) => (
          <div key={feature.title} className={classes.featureStyles}>
            <Tooltip title={feature.toolTip}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => actions[`${feature['funcName']}`](selected)}
                disabled={loading}
              >
                {feature.title}
              </Button>
            </Tooltip>
          </div>
        ))
      : null;

  return (
    <CustomEnhancedToolbar
      title={selected.length > 0 ? `${selected.length} selected` : title}
      toolbarFeatureContent={toolbarFeatureContent}
    >
      {loading ? <HorizontalSpinner size="md" /> : toolbarContent}
    </CustomEnhancedToolbar>
  );
};

export default EnhancedTableToolbarExtended;
