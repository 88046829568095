import axios from 'axios';
import { authFail } from './auth';
import * as actionTypes from './actionTypes';

export const fetchUserDetailsStart = () => {
  return { type: actionTypes.FETCH_USER_DETAILS_START };
};

export const fetchUserDetailsFail = () => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_FAILED,
  };
};

export const fetchUserDetailsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
    payload,
  };
};

export const rootTemplateSuccess = (rootTemplates) => {
  return {
    type: actionTypes.SET_ROOT_TEMPLATE_SUCCESS,
    payload: rootTemplates,
  };
};

export const fetchUserDetails = (token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    dispatch(fetchUserDetailsStart());
    axios
      .get('/api/user/userdata', config)
      .then((resp) => {
        dispatch(fetchUserDetailsSuccess(resp.data.userData));
        dispatch(rootTemplateSuccess(resp.data.userTemplates));
      })
      .catch((e) => {
        dispatch(authFail('user-error'));
        dispatch(fetchUserDetailsFail());
      });
  };
};

export const userConsentSuccess = () => {
  return {
    type: actionTypes.USER_CONSENT_SUCCESS,
  };
};
export const cleanUserDetails = () => {
  return {
    type: actionTypes.CLEAN_USER_DETAILS,
  };
};

export const userConsent = (token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    axios
      .post('/auth/consent', {}, config)
      .then((resp) => {
        dispatch(userConsentSuccess());
      })
      .catch((e) => console.error(e));
  };
};
