import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCountries } from '../../../../store/actions';
import { CircleSpinnerLarge } from '../../../UI/Spinners/Circle';
import { ProductUnitOfMeasureCodes } from '../../../../services/types';
import translationsCommonCodes from '../../../../services/translations/translationsCommonCodes';
import { buildOptionsFromApplicableCurrencyCodes } from '../../../../services/helpers/getCurrencies';
import Select from 'react-select';
import { SelectStylesMain } from '../../../UI/SelectStyles/SelectStyles';
//custom ui
import Toolbar from '../../../UI/CustomToolbar/CustomToolbar';
import SnackbarContentWrapper from '../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
//material ui
import {
  AppBar,
  Tooltip,
  IconButton,
  Button,
  MenuItem,
  Snackbar,
  TextField,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
//icons
import TableChartIcon from '@material-ui/icons/TableChart';
import { ValidateProductTemplateSimple } from '../../../../services/validations';

const currencies = buildOptionsFromApplicableCurrencyCodes();

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '350px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '10px',
    alignItems: 'end',
    gap: '30px',
  },
  textField: {
    width: '100%',
  },
  countriesLoading: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
  },
  unitContainer: {
    display: 'grid',
    alignItems: 'end',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  valueContainer: {
    display: 'grid',
    alignItems: 'end',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  currency: {
    width: '64px',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gridColumn: '1 / -1',
    '& > *': {
      margin: '0 2px',
    },
  },
  addButton: {
    width: '100%',
    maxWidth: '300px',
    backgroundColor: '#5d5d5d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#676565',
    },
  },
  cancelButton: {
    width: '100%',
    maxWidth: '300px',
  },
}));

const initialProduct = {
  description: '',
  unitNumber: '',
  unitValue: '',
  unitMeasureCode: '',
  unitCurrency: '',
  commodityCode: '',
  originCountryCode: '',
  templateId: '',
};

const ProductTemplateForm = ({
  translations,
  handleChangeToTable,
  handleSubmit,
  template,
  type,
}) => {
  const [productState, setProductState] = useState(initialProduct);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [snackbar, setSnackbar] = useState({ variant: '', messages: [], open: false });
  const { token } = useSelector((state) => state.auth);
  const { countriesData } = useSelector((state) => state.countries);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countriesData) dispatch(fetchCountries(token));
    if (template) setProductState({ ...productState, ...template });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countriesData) {
      const serializedCountriesOptions = countriesData.map((country) => ({
        label: country[lang],
        value: country.countryCode,
      }));
      setCountriesOptions(serializedCountriesOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesData]);

  const handleSubmitProduct = async () => {
    const validation = await ValidateProductTemplateSimple({
      product: productState,
      lang,
    });
    if (!validation.valid)
      setSnackbar({
        ...snackbar,
        open: true,
        messages: validation.errors || '',
        variant: validation.variant,
      });
    if (validation.valid) {
      const productData = Object.keys(productState).reduce((acc, curr) => {
        if (productState[curr] === '') {
          acc[curr] = null;
          return acc;
        }
        acc[curr] = productState[curr];
        return acc;
      }, {});

      handleSubmit({ productData });
    }
  };

  const handleProductStateChange = (e) => {
    const { value, name } = e.target;
    setProductState({ ...productState, [name]: value });
  };
  const handleUnitValueChange = (e) => {
    const value = e.target.value.replace(',', '.');

    //validate decimal places (should not exceed 6)
    const decimalIndex = value.indexOf('.');
    const decimalsCount = decimalIndex === -1 ? 0 : value.length - decimalIndex - 1;
    if (decimalsCount > 6) return;

    setProductState({ ...productState, unitValue: value });
  };
  const handleUnitValueBlur = (e) => {
    let value = e.target.value.replace(',', '.');
    //validate decimal places (should not exceed 6)
    const decimalIndex = value.indexOf('.');
    if (decimalIndex === value.length) value = value.slice(0, -1);

    const float = parseFloat(value);

    if (isNaN(float)) {
      setProductState({ ...productState, unitValue: '' });
    } else {
      setProductState({ ...productState, unitValue: float });
    }
  };

  const handleCountryChange = (e) => {
    setProductState({ ...productState, originCountryCode: e.value });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <AppBar position="static" elevation={0}>
        <Toolbar
          title={`${translations.templates[type]} ${
            translations.templates.producttemplate.substr(0, 1).toLowerCase() +
            translations.templates.producttemplate.substr(1)
          }`}
        >
          <Tooltip title={translations.templates.producttemplates}>
            <IconButton onClick={handleChangeToTable}>
              <TableChartIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <TextField
          required
          inputProps={{ maxLength: 35 }}
          label={translations.invoiceForm.description}
          className={classes.textField}
          name="description"
          value={productState.description}
          onChange={handleProductStateChange}
          margin="none"
        />
        <div className={classes.unitContainer}>
          <TextField
            inputProps={{ maxLength: 7 }}
            label={translations.invoiceForm.amount}
            className={classes.textField}
            name="unitNumber"
            value={productState.unitNumber}
            onChange={handleProductStateChange}
            margin="none"
          />
          <TextField
            select
            inputProps={{ maxLength: 3 }}
            className={classes.textField}
            style={{ transform: 'translateY(20px)' }}
            name="unitMeasureCode"
            value={productState.unitMeasureCode}
            onChange={handleProductStateChange}
            helperText={translations.invoiceForm.unitOfMeasure}
            margin="dense"
          >
            {ProductUnitOfMeasureCodes.map((code) => (
              <MenuItem key={code} value={code}>
                {translationsCommonCodes[lang].ProductUnitOfMeasureCodes[code]}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.valueContainer}>
          <TextField
            inputProps={{ maxLength: 19 }}
            label={translations.invoiceForm.unitValue}
            className={classes.textField}
            name="unitValue"
            value={productState.unitValue}
            onChange={handleUnitValueChange}
            onBlur={handleUnitValueBlur}
            margin="none"
          />
          <TextField
            id="standard-select-currency"
            select
            className={classes.currency}
            name="unitCurrency"
            value={productState.unitCurrency}
            onChange={handleProductStateChange}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="none"
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          inputProps={{ maxLength: 15, minLength: 6 }}
          label={translations.invoiceForm.commodityCode}
          className={classes.textField}
          name="commodityCode"
          value={productState.commodityCode}
          onChange={handleProductStateChange}
          margin="none"
        />
        {countriesOptions.length > 0 && (
          <Select
            styles={SelectStylesMain}
            value={countriesOptions.filter(({ value }) => value === productState.originCountryCode)}
            menuPlacement="auto"
            name="originCountryCode"
            placeholder={translations.invoiceForm.countryOfOrigin}
            onChange={handleCountryChange}
            options={countriesOptions}
          />
        )}
        {countriesOptions.length === 0 && (
          <div className={classes.countriesLoading}>
            <CircleSpinnerLarge />
          </div>
        )}
        <TextField
          required
          inputProps={{ maxLength: 50, minLength: 2 }}
          label={translations.invoiceForm.identifier}
          name="templateId"
          className={classes.textField}
          value={productState.templateId}
          onChange={handleProductStateChange}
          margin="none"
        />
        <div className={classes.buttonsContainer}>
          <Button onClick={handleSubmitProduct} variant="contained" className={classes.addButton}>
            {template ? translations.templates.update : translations.templates.add}{' '}
            {translations.invoiceForm.product}
          </Button>
          <Button onClick={handleChangeToTable} className={classes.cancelButton}>
            {translations.templates.cancel}
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </Paper>
  );
};

export default ProductTemplateForm;
