import React, { Component } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { withStyles } from '@material-ui/core/styles';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import FilePond plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const styles = (theme) => ({
  root: {
    width: '300px',
    '& .filepond--panel-root': {
      border: `1px solid ${theme.palette.primary.main}`,
      background: 'white',
    },
    '& .filepond--file': {
      background: theme.palette.primary.main,
    },
    '& .filepond--root': {
      marginBottom: '0',
    },
  },
});

class FileZoneUpload extends Component {
  render() {
    let title = this.props.title ? this.props.title : 'Drag & Drop or Browse';
    const disabled = this.props.disabled ? this.props.disabled : false;
    const { classes } = this.props;
    return (
      <div
        style={{ width: this.props.width ? this.props.width : '300px' }}
        className={classes.root}
      >
        {/* Pass FilePond properties as attributes */}
        <FilePond
          ref={(ref) => (this.pond = ref)}
          labelIdle={title}
          files={this.props.files}
          allowMultiple={true}
          maxFiles={this.props.maxFiles || 5}
          onupdatefiles={(fileItems) => this.props.updateFiles(fileItems)}
          disabled={disabled}
        ></FilePond>
      </div>
    );
  }
}

export default withStyles(styles)(FileZoneUpload);
