import React, { useState, useEffect } from 'react';
import { CARRIERS, ProductUnitOfMeasureCodes } from '../../../../../services/types';
import errorMessages from '../../../../../services/translations/errors';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Snackbar, Switch, TextField } from '@material-ui/core';
import { SelectStylesMain } from '../../../../UI/SelectStyles/SelectStyles';
import Select from 'react-select';
import { ValidateProductByCarrier } from '../../../../../services/validations';
import SnackbarContentWrapper from '../../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductTemplate } from '../../../../../store/actions';
import { serializeTemplateProduct } from '../../../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(3, max-content) 74px 60px max-content',
    padding: '20px',
    alignItems: 'end',
    gap: '30px',
  },
  templateIdentifierSelect: {
    gridColumn: 1,
    width: '100%',
  },
  templateDescriptionSelect: {
    gridColumn: 2,
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  unitContainer: {
    display: 'grid',
    alignItems: 'end',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  valueContainer: {
    display: 'grid',
    alignItems: 'end',
    gridTemplateColumns: 'max-content max-content',
    gap: '20px',
  },
  currency: {
    width: '64px',
    zIndex: 0,
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '12px',
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  },
  saveNewTemplateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    '& > p': {
      fontSize: '1rem',
      fontWeight: 300,
    },
  },
  saveCurrentTemplateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      '& > p': {
        fontSize: '1rem',
        fontWeight: 300,
      },
    },
  },
  idTextfield: {
    gridColumn: '1 / -1',
    placeSelf: 'center',
    width: '100%',
    maxWidth: '300px',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gridRow: 6,
    gridColumn: '1 / -1',
    '& > *': {
      margin: '2px 0',
    },
  },
  addButton: {
    width: '100%',
    maxWidth: '300px',
    backgroundColor: '#5d5d5d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#676565',
    },
  },
  cancelButton: {
    width: '100%',
    maxWidth: '300px',
  },
}));

const initialProduct = {
  description: '',
  unitNumber: '',
  unitValue: '',
  unitMeasureCode: '',
  commodityCode: '',
  originCountryCode: '',
  templateId: '',
};

const ProductModal = ({
  lang,
  translationsCommonCodes,
  translations,
  handleAddProduct,
  handleCancelProduct,
  currency,
}) => {
  const initialProductOptions = {
    value: 'new',
    label: translations.invoiceForm.newProduct.toUpperCase(),
  };

  const [selectedId, setSelectedId] = useState('new');
  const [productState, setProductState] = useState(initialProduct);
  const [templateId, setTemplateId] = useState('');
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [productDescriptionOptions, setProductDescriptionOptions] = useState([
    initialProductOptions,
  ]);
  const [productIdentifierOptions, setProductIdentifierOptions] = useState([initialProductOptions]);
  const [saveProduct, setSaveProduct] = useState(false);
  const [updateProduct, setUpdateProduct] = useState(false);
  const [snackbar, setSnackbar] = useState({ variant: '', messages: [], open: false });
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.countries.countriesData);
  const { templates } = useSelector((state) => state.productTemplates);
  const { token } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.user);

  useEffect(() => {
    if (templates.length === 0) {
      dispatch(fetchProductTemplate({ userId, token }));
    }

    const serializedCountriesOptions = countries.map((country) => ({
      label: country[lang],
      value: country.countryCode,
    }));
    setCountriesOptions(serializedCountriesOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (templates.length > 0) {
      if (productDescriptionOptions.length === 1) {
        const serializedProductOptions = templates.map(({ id, description }) => ({
          value: id,
          label: description,
        }));
        setProductDescriptionOptions([...productDescriptionOptions, ...serializedProductOptions]);
      }
      if (productIdentifierOptions.length === 1) {
        const serializedProductIdentifierOptions = templates
          .sort((a, b) => a.templateId.localeCompare(b.templateId))
          .map(({ id, templateId }) => ({
            value: id,
            label: templateId,
          }));
        setProductIdentifierOptions([
          ...productIdentifierOptions,
          ...serializedProductIdentifierOptions,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const handleTemplateChange = (e) => {
    const { value } = e;
    let updatedProductState;
    if (value === 'new') {
      updatedProductState = initialProduct;
    } else {
      const templateProduct = templates.find((template) => template.id === e.value);
      //serialize product from template
      updatedProductState = serializeTemplateProduct({ product: templateProduct });
    }
    'templateId' in updatedProductState && setTemplateId(updatedProductState.templateId);
    setProductState(updatedProductState);
    setSelectedId(e.value);
  };

  const handleProductStateChange = (e) => {
    const { value, name } = e.target;
    setProductState({ ...productState, [name]: value });
  };

  const handleUnitValueChange = (e) => {
    const value = e.target.value.replace(',', '.');

    //validate decimal places (should not exceed 6)
    const decimalIndex = value.indexOf('.');
    const decimalsCount = decimalIndex === -1 ? 0 : value.length - decimalIndex - 1;
    if (decimalsCount > 6) return;

    setProductState({ ...productState, unitValue: value });
  };

  const handleUnitValueBlur = (e) => {
    let value = e.target.value.replace(',', '.');
    //validate decimal places (should not exceed 6)
    const decimalIndex = value.indexOf('.');
    if (decimalIndex === value.length) value = value.slice(0, -1);

    const float = parseFloat(value);

    if (isNaN(float)) {
      setProductState({ ...productState, unitValue: '' });
    } else {
      setProductState({ ...productState, unitValue: value });
    }
  };

  const handleCountryChange = (e) => {
    setProductState({ ...productState, originCountryCode: e.value });
  };

  const handleSaveProduct = () => {
    updateProduct && !saveProduct && setUpdateProduct(false);
    setSaveProduct(!saveProduct);
  };
  const handleUpdateProduct = () => {
    saveProduct && !updateProduct && setSaveProduct(false);
    setUpdateProduct(!updateProduct);
  };

  const handleSubmitProduct = async () => {
    const product = {
      ...productState,
      unitCurrency: currency,
      saveProduct,
      updateProduct,
      templateId,
    };

    //Validate that template id is unique
    if (saveProduct && templates.some(({ templateId }) => templateId === product.templateId)) {
      setSnackbar({
        ...snackbar,
        open: true,
        messages: [errorMessages.templates.exists[lang]],
        variant: 'error',
      });
      return;
    }

    const validation = await ValidateProductByCarrier({
      carrier: CARRIERS.UPS,
      product,
      lang,
    });
    if (!validation.valid)
      setSnackbar({
        ...snackbar,
        open: true,
        messages: validation.errors || '',
        variant: validation.variant,
      });
    if (validation.valid) handleAddProduct({ product });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const updatedSnackbar = { ...snackbar, open: false };
    setSnackbar({ ...snackbar, ...updatedSnackbar });
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.templateIdentifierSelect}>
          <label htmlFor="template-identifier-select">
            {translations.invoiceForm.selectFromProductId}
          </label>
          <Select
            id="template-identifier-select"
            styles={SelectStylesMain}
            value={productIdentifierOptions.filter(({ value }) => value === selectedId)}
            placeholder={translations.invoiceForm.savedProducts}
            onChange={handleTemplateChange}
            options={productIdentifierOptions}
          />
        </div>
        <div className={classes.templateDescriptionSelect}>
          <label htmlFor="template-description-select">
            {translations.invoiceForm.selectFromDescription}
          </label>
          <Select
            id="template-description-select"
            styles={SelectStylesMain}
            value={productDescriptionOptions.filter(({ value }) => value === selectedId)}
            placeholder={translations.invoiceForm.savedProducts}
            onChange={handleTemplateChange}
            options={productDescriptionOptions}
          />
        </div>

        <TextField
          required
          inputProps={{ maxLength: 35 }}
          label={translations.invoiceForm.description}
          className={classes.textField}
          name="description"
          value={productState.description}
          onChange={handleProductStateChange}
          margin="none"
        />
        <div className={classes.unitContainer}>
          <TextField
            required
            inputProps={{ maxLength: 7 }}
            label={translations.invoiceForm.amount}
            className={classes.textField}
            name="unitNumber"
            value={productState.unitNumber}
            onChange={handleProductStateChange}
            margin="none"
          />
          <TextField
            select
            required
            inputProps={{ maxLength: 3 }}
            className={classes.textField}
            style={{ transform: 'translateY(20px)' }}
            name="unitMeasureCode"
            value={productState.unitMeasureCode}
            onChange={handleProductStateChange}
            helperText={translations.invoiceForm.unitOfMeasure}
            margin="dense"
          >
            {ProductUnitOfMeasureCodes.map((code) => (
              <MenuItem key={code} value={code}>
                {translationsCommonCodes.ProductUnitOfMeasureCodes[code]}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.valueContainer}>
          <TextField
            inputProps={{ maxLength: 19 }}
            label={translations.invoiceForm.unitValue}
            className={classes.textField}
            name="unitValue"
            value={productState.unitValue}
            onChange={handleUnitValueChange}
            onBlur={handleUnitValueBlur}
            margin="none"
          />
          <TextField
            disabled
            className={classes.currency}
            classes={{
              root: classes.input,
            }}
            label={translations.invoiceForm.currency}
            name="unitCurrency"
            defaultValue={currency}
            margin="none"
            variant="outlined"
          />
        </div>
        <TextField
          required
          inputProps={{ maxLength: 15, minLength: 6 }}
          label={translations.invoiceForm.commodityCode}
          className={classes.textField}
          name="commodityCode"
          value={productState.commodityCode}
          onChange={handleProductStateChange}
          margin="none"
        />
        <Select
          styles={SelectStylesMain}
          value={countriesOptions.filter(({ value }) => value === productState.originCountryCode)}
          menuPlacement="auto"
          name="originCountryCode"
          placeholder={translations.invoiceForm.countryOfOrigin}
          onChange={handleCountryChange}
          options={countriesOptions}
        />
        {selectedId === 'new' ? (
          <div className={classes.saveNewTemplateContainer}>
            <p>{translations.invoiceForm.saveAsTemplate}</p>
            <Switch
              disableRipple
              onClick={() => setSaveProduct(!saveProduct)}
              checked={saveProduct}
            />
          </div>
        ) : (
          <div className={classes.saveCurrentTemplateContainer}>
            <div>
              <p>{translations.invoiceForm.saveAsNewTemplate}</p>
              <Switch disableRipple onClick={handleSaveProduct} checked={saveProduct} />
            </div>
            <div>
              <p>{translations.invoiceForm.updateTemplate}</p>
              <Switch disableRipple onClick={handleUpdateProduct} checked={updateProduct} />
            </div>
          </div>
        )}
        {saveProduct && (
          <TextField
            required
            inputProps={{ maxLength: 50, minLength: 2 }}
            label={translations.invoiceForm.identifier}
            className={classes.idTextfield}
            value={templateId}
            onChange={(e) => setTemplateId(e.target.value)}
            margin="none"
          />
        )}
        <div className={classes.buttonsContainer}>
          <Button onClick={handleSubmitProduct} variant="contained" className={classes.addButton}>
            {translations.templates.add}&nbsp;{translations.invoiceForm.product}
          </Button>
          <Button onClick={handleCancelProduct} className={classes.cancelButton}>
            {translations.templates.cancel}
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default ProductModal;
