import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let FIicon = (props) => {
 
    return (

        <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
      <path fill="#fff" d="M0 0h640v480H0z"></path>
      <path fill="#003580" d="M0 174.5h640v131H0z"></path>
      <path fill="#003580" d="M175.5 0h130.9v480h-131z"></path>
    </svg>
          
      </SvgIcon>
    );
} 



FIicon = pure(FIicon);
FIicon.displayName = 'FIicon';
FIicon.muiName = 'SvgIcon';


export default FIicon
