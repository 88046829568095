import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React from 'react';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '750px',
    margin: '10px',
  },
  handleTableDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    minHeight: '100px',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
  emphasizeNumber: {
    color: 'black',
    fontWeight: 'bold',
  },
  statusContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    gap: '10px',
  },
  printAllTitle: {
    gridColumn: '1 / -1',
    fontWeight: '500',
    marginBottom: '-6px',
  },
  actionContainer: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(50px, max-content))',
    gap: '10px',
  },
}));

export default function FileBookingShipmentHandler({
  loading,
  successBookings,
  errorBookings,
  successBookingsByFormat,
  actionByFormat,
  translations,
}) {
  const classes = useStyles();
  const { processBatchFileErrors } = useSelector((state) => state.batchBooking);
  const noResult =
    !loading &&
    processBatchFileErrors.length === 0 &&
    successBookings.length === 0 &&
    errorBookings.length === 0;

  return (
    <div className={classes.root}>
      <label>Status</label>
      <div className={classes.handleTableDataContainer}>
        {loading && <p> {translations.loadingText}</p>}
        {processBatchFileErrors.length > 0 &&
          processBatchFileErrors.map((error) => (
            <p key={error} style={{ color: 'red' }}>
              {error}
            </p>
          ))}
        {noResult && <p>{translations.noResultsText}</p>}
        {!loading && (
          <div className={classes.statusContainer}>
            {successBookings.length > 0 && (
              <p style={{ color: green[700] }}>
                <span className={classes.emphasizeNumber}>{successBookings.length}</span>{' '}
                {translations.bookingsSucceeded} .
              </p>
            )}
            {!loading && errorBookings.length > 0 && (
              <p style={{ color: 'red' }}>
                <span className={classes.emphasizeNumber}>{errorBookings.length} </span>{' '}
                {`${translations.bookings} ${translations.failed}`}
              </p>
            )}

            {Object.keys(successBookingsByFormat).length > 0 && (
              <>
                <p className={classes.printAllTitle}>
                  {translations.printLabelsForSuccess}
                  {':'}
                </p>
                <div className={classes.actionContainer}>
                  {Object.keys(successBookingsByFormat).map((format) =>
                    actionByFormat(format, successBookingsByFormat[format])
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
