import axios from 'axios';
import * as actionTypes from './actionTypes';

export const authStart = () => {
	return { type: actionTypes.AUTH_START }
}

export const authFail = (message = 'auth-error') => {
	return {
		type: actionTypes.AUTH_FAILED,
		message
	}
}

export const authSuccess = (token) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token
	}
}

export const checkAuthTimeout = (expirationTime) => {
	return dispatch => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	}
}

export const authCheckState = () => {
	return dispatch => {
		const token = localStorage.getItem('token');
		if (!token) {
			dispatch(logout());
		} else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date()) {
				dispatch(logout());
			} else {
				dispatch(authSuccess(token));
				dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
			}
		}
	}
}

export const auth = (email, password) => {

	return dispatch => {
		dispatch(authStart());
		axios.post('/auth/login', { email, password })
			.then(resp => {
				const expirationDate = new Date(new Date().getTime() + resp.data.expiresIn * 1000);
				localStorage.setItem('token', resp.data.token);
				localStorage.setItem('expirationDate', expirationDate);
				dispatch(authSuccess(resp.data.token));
				dispatch(checkAuthTimeout(resp.data.expiresIn));
			}).catch(err => {
				dispatch(authFail());
			});
	}
}

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expirationDate');
	return {
		type: actionTypes.AUTH_LOGOUT
	}

}