import React, { useEffect } from 'react';
import DateSelect from '../../../DateSelect/DateSelect';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pickupContainer: {
    padding: '15px',
  },
  pickupSelectionOptions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  pickupOption: {
    display: 'flex',
    alignItems: 'center',
  },
  requestPickupContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: '10px',
  },
}));

const HrxPickup = (props) => {
  useEffect(() => {
    if (!props.requestPickup) props.handlePickupChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.pickupContainer}>
      <div className={classes.requestPickupContainer}>
        <DateSelect
          locale={props.locale}
          label={props.translations.onlinebooking.pickupdate}
          inline={true}
          handleDateChange={props.handlePickupDateChange}
          selectedDate={props.selectedPickupDate}
        />
        <TextField
          id='pickupReference'
          inputProps={{ maxLength: 35 }}
          label={props.translations.onlinebooking.pickupInstructions}
          className={classes.textField}
          name='pickupReference'
          value={props.pickupReference}
          onChange={props.handlePickupReferenceChange}
          margin='none'
        />
      </div>
    </div>
  );
};

export default HrxPickup;
