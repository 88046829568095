import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTableCompany from '../TemplateTables/EnhancedTableCompany/EnhancedTableCompany';
import AddCompany from '../AddCompany/AddSenderCompany';
import EditSenderCompany from '../EditCompany/EditSenderCompany';
import EditSenderCompanyContacts from '../EditContacts/EditSenderCompanyContacts';
import EmptyTable from '../TemplateTables/EmptyTable/EmptyTable';
import { connect, useDispatch } from 'react-redux';
import {
  copyCompanyTemplate,
  fetchSenderCompaniesTemplates,
  removeSenderCompaniesTemplates,
  updateDefaultSender,
  updateTemplateComment,
} from '../../../store/actions';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import AddCompanyCommentModal from '../AddCompanyCommentModal/AddCompanyCommentModal';

const initialState = {
  content: 'table',
  editCompany: null,
  editCompanyIndex: null,
};

const initialUpdateCommentModalState = {
  open: false,
  template: null,
  flow: null,
};

function SenderTemplate(props) {
  const [state, setState] = useState(initialState);
  const [updateCommentModalState, setUpdateCommentModalState] = useState(
    initialUpdateCommentModalState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.templates.length < 1) {
      props.onfetchSenderTemplates(props.id, props.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCompany = () => {
    setState({ ...state, content: 'add', editCompany: null });
  };
  const handleChangeToTable = () => {
    setState({ ...state, content: 'table', editCompany: null });
  };

  const handleChangeToContacts = (id) => {
    const companyEdit = props.templates.filter((obj) => obj.id === id);
    const companyIndex = props.templates.findIndex((company) => company.idid);

    setState({
      ...state,
      content: 'editCompanyContacts',
      editCompany: companyEdit[0],
      editCompanyIndex: companyIndex,
    });
  };

  const handleRemoveCompany = (id) => {
    props.onRemoveSenderTemplates(id, props.token);
  };

  const handleEditCompany = (id) => {
    const companyEdit = props.templates.filter((obj) => obj.id === id);
    setState({ ...state, content: 'editCompany', editCompany: companyEdit[0] });
  };

  const handleUpdateDefault = (id) => {
    props.onUpdateDefaultSender(props.id, id);
  };

  const handleUpdateCommentModal = (id) => {
    const template = props.templates.find((template) => template.id === id);
    setUpdateCommentModalState({
      ...updateCommentModalState,
      open: true,
      template,
      flow: 'sender',
    });
  };

  const handleCopyTemplate = (id) => {
    const copyToFlow = 2; //copy template to recipient
    props.onCopyTemplate(id, copyToFlow);
  };

  const handleUpdateComment = useCallback(
    (comment, templateId, flow) => {
      dispatch(updateTemplateComment(templateId, comment, flow));
      setUpdateCommentModalState(initialUpdateCommentModalState);
    },
    [dispatch]
  );

  const modals = useMemo(
    () => [
      <AddCompanyCommentModal
        id="updateCommentModal"
        open={updateCommentModalState.open}
        template={updateCommentModalState.template}
        flow={updateCommentModalState.flow}
        translations={props.translations}
        updateComment={handleUpdateComment}
        handleAbort={() => setUpdateCommentModalState(initialUpdateCommentModalState)}
      />,
    ],
    [updateCommentModalState, props.translations, handleUpdateComment]
  );

  let output = null;
  let tableData = null;
  let content = state.content;

  const companyRows = [
    {
      id: 'templateName',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.templates.template,
    },
    {
      id: 'companyName',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.company,
    },
    {
      id: 'companyCountry',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.country,
    },
    {
      id: 'companyContacts',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.templates.contacts,
    },
    {
      id: 'companyCity',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.city,
    },
    {
      id: 'companyPostalCode',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.zip,
    },
    {
      id: 'companyAddress1',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: `${props.translations.addressrow} 1`,
    },
  ];
  const contactRows = [
    {
      id: 'name',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.contact,
    },
    {
      id: 'phone',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.phone,
    },
    {
      id: 'email',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.email,
    },
  ];

  if (content === 'table') {
    if (props.loading) {
      tableData = <CircleSpinnerLarge />;
    } else {
      if (props.templates.length < 1) {
        tableData = (
          <EmptyTable
            translations={props.translations}
            type="company"
            title={props.translations.templates.savedsenders}
            rows={9}
            changeToAdd={handleAddCompany}
          />
        );
      } else {
        tableData = (
          <EnhancedTableCompany
            translations={props.translations}
            title={props.translations.templates.savedsenders}
            copyTooltip={props.translations.templates.savedrecipients}
            rows={companyRows}
            handleUpdateDefaultTemplate={handleUpdateDefault}
            updateTemplateComment={handleUpdateCommentModal}
            handleRemoveTemplate={handleRemoveCompany}
            handleAddCompany={handleAddCompany}
            handleEditTemplate={handleEditCompany}
            handleEditTemplateContacts={handleChangeToContacts}
            handleCopyTemplate={handleCopyTemplate}
            defaultId={props.defaultId}
            companyData={props.templates}
            modals={modals}
          />
        );
      }
    }

    output = tableData;
  } else if (content === 'add') {
    output = (
      <AddCompany
        translations={props.translations}
        handleAddCompany={handleAddCompany}
        handleChangeToTable={handleChangeToTable}
        flow="sender"
      />
    );
  } else if (content === 'editCompany') {
    output = (
      <EditSenderCompany
        translations={props.translations}
        handleChangeToContacts={handleChangeToContacts}
        handleChangeToTable={handleChangeToTable}
        companyData={state.editCompany}
        flow="sender"
      />
    );
  } else if (content === 'editCompanyContacts') {
    output = (
      <EditSenderCompanyContacts
        translations={props.translations}
        title={props.translations.templates.contacts}
        handleChangeToTable={handleChangeToTable}
        companyIndex={state.editCompanyIndex}
        companyData={state.editCompany}
        rows={contactRows}
      />
    );
  }

  return <div>{output}</div>;
}

const mapStateToProps = (state) => {
  return {
    id: state.user.userId,
    defaultId: state.bookingPresets.defaultSender,
    templates: state.senderTemplates.templates,
    loading: state.senderTemplates.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchSenderTemplates: (id, token) =>
      dispatch(fetchSenderCompaniesTemplates({ userId: id, token })),
    onRemoveSenderTemplates: (id, token) => dispatch(removeSenderCompaniesTemplates(id, token)),
    onUpdateDefaultSender: (userId, senderId) => dispatch(updateDefaultSender(userId, senderId)),
    onCopyTemplate: (templateId, copyToFlow) =>
      dispatch(copyCompanyTemplate({ templateId, copyToFlow })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SenderTemplate);
