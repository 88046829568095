import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/ups_logotype.svg';
import translations from '../../../../../services/translations/translations';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { filterUpsAddress, buildUpsScsPackagesFromTracking } from '../../TrackingResponseBuilder';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px 40px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& h2': {
      fontSize: '1.1rem',
      borderBottom: '1px solid',
      color: '#770000',
      fontWeight: '500',
      marginBottom: '3px',
    },
  },
  responseHeaderDetails: {
    '& p': {
      padding: '2px 0',
      fontWeight: 500,
      '& span': {
        fontWeight: 400,
      },
    },
  },
  '@media (max-width:930px)': {
    responseHeader: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

const initialState = {
  shipToAddress: null,
  shipmentWeight: null,
  shipmentWeightUnit: null,
  serviceName: null,
  deliveryDetails: null,
};

export default function UpsscsTrackingView({ trackingState }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [state, setState] = useState(initialState);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    setPackages(() =>
      buildUpsScsPackagesFromTracking(
        [
          {
            activity: trackingState.result.results.Activity,
            trackingNumber: trackingState.result.results.InquiryNumber.Value,
          },
        ],
        lang
      )
    );

    const shipToAddressResult = trackingState.result.results.ShipmentAddress
      ? filterUpsAddress(trackingState.result.results.ShipmentAddress, '02')
      : null;

    const deliveryDetails = trackingState.result.results.DeliveryDetail
      ? trackingState.result.results.DeliveryDetail
      : null;

    setState({
      shipToAddress: shipToAddressResult,
      shipmentWeight: trackingState.result.results.ShipmentWeight.Weight,
      shipmentWeightUnit: trackingState.result.results.ShipmentWeight.UnitOfMeasurement.Code,
      serviceName: trackingState.result.results.Service.Description,
      deliveryDetails,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);

  const classes = useStyles();
  return (
    <>
      <div className={classes.carrierHeader}>
        <p>{state.serviceName}</p>
        <img src={Logotype} alt="UPS Logotype" />
      </div>
      <div className={classes.responseHeader}>
        {state.shipToAddress && (
          <div>
            <h2>{translations[lang].recipientAddress}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].location}: <span>{state.shipToAddress.city}</span>
              </p>
              <p>
                {translations[lang].postalCode}: <span>{state.shipToAddress.postalCode}</span>
              </p>
              <p>
                {translations[lang].country}: <span>{state.shipToAddress.countryCode}</span>
              </p>
            </div>
          </div>
        )}
        {state.shipmentWeight && (
          <div>
            <h2>{translations[lang].shipmentDetails}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].weight}:&nbsp;
                <span>
                  {state.shipmentWeight} {state.shipmentWeightUnit}
                </span>
              </p>
              <p>
                {translations[lang].service}:&nbsp;<span>{state.serviceName}</span>
              </p>
            </div>
          </div>
        )}
        {state.deliveryDetails && (
          <div>
            <h2>{translations[lang].deliveryDetails}:&nbsp;</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].trackedshipments.titles.description}:&nbsp;
                <span>{state.deliveryDetails.Type && state.deliveryDetails.Type.Description}</span>
              </p>
              <p>
                {translations[lang].trackedshipments.moment}:&nbsp;
                <span>
                  {state.deliveryDetails.Time
                    ? moment(`${state.deliveryDetails.Date}T${state.deliveryDetails.Time}`).format(
                        'LLL'
                      )
                    : moment(state.deliveryDetails.Date).format('LL')}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>

      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
    </>
  );
}
