import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  makeStyles,
  MenuItem,
  Menu,
  TextField,
  Typography,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
// import AddNotificationIcon from '@material-ui/icons/AddAlert';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';
import RemoveEmailRowIcon from '@material-ui/icons/RemoveCircle';
import { grey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import SnackbarContentWrapper from '../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { validateNotifications } from '../../../../services/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    width: '100%',
    border: `1px solid ${grey[200]}`,
  },
  noticationButton: {
    width: '100%',
  },
  addEmailContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '30px auto 30px',
    margin: '5px 0',
    padding: '0 10px',
    gap: '5px',
    boxSizing: 'border-box',
  },
  notificationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  options: {
    display: 'flex',
    '& >*:not(:first-child)': {
      marginLeft: '5px',
    },
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addRowButton: {
    width: 80,
    height: 24,
    gridColumn: 2,
    background: grey[300],
    border: 'none',
    borderRadius: '5px',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `0px 2px 4px #bdbdbd94`,
    },
  },
  addRowIcon: {
    fontSize: '30px',
    color: theme.palette.primary.main,
  },
  removeRowButton: {
    padding: 0,
    minWidth: '20px',
  },
  removeRowIcon: {
    color: theme.status.danger,
  },
  menuButton: {
    minWidth: '25px',
    maxHeight: '30px',
    padding: '0 10px',
  },
  textField: {
    width: '100%',
    fontSize: '0.9rem',
  },
  actionContainer: {
    display: 'flex',
    margin: '5px 0',
    width: '100%',
    justifyContent: 'center',
  },
  buttons: {
    width: '100%',
    maxWidth: '120px',
    padding: '3px 0',
  },
}));

const initialSelectedState = { ship: false, exception: false, delivery: false };
const initialSnackbar = {
  variant: '',
  messages: [],
  open: false,
};

const UpsNotifications = ({ handleAddNotification, notificationOptions, translations, lang }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(initialSelectedState);
  const [emails, setEmails] = useState(['']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState(initialSnackbar);

  const {
    sender: { email: senderEmail },
    recipient: { email: recipientEmail },
    notifications: prevNotifications,
  } = useSelector((state) => state.booking);

  const classes = useStyles();

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSelectOption = (key) => {
    setSelected({ ...selected, [key]: !selected[key] });
  };

  const handleSubmit = async () => {
    const notifications = Object.keys(selected)
      .filter((key) => selected[key])
      .map((k) => k.toString());

    const validation = await validateNotifications({
      notifications: [
        ...prevNotifications,
        { notifications, notificationType: 'email', recipients: emails },
      ],
      lang,
    });

    if (!validation.valid) {
      setSnackbar({ open: true, messages: validation.errors, variant: validation.variant });
      return;
    }

    handleAddNotification({ notifications, notificationType: 'email', recipients: emails });
    handleCancel();
  };

  const handleEmailChange =
    (rowNumber) =>
    ({ target: { value } }) => {
      const updatedEmails = [...emails];
      updatedEmails[rowNumber] = value;
      setEmails(updatedEmails);
    };

  const handleCancel = () => {
    setOpen(false);
    setEmails(['']);
    setSelected(initialSelectedState);
  };

  const handleCloseMenu = (email, rowNumber) => {
    if (email) {
      const updatedEmails = [...emails];
      updatedEmails[rowNumber] = email;
      setEmails(updatedEmails);
    }
    setAnchorEl(null);
  };
  const handleAddEmailRow = () => {
    if (emails.length >= 5) return; //maximum of 5 emails can be added
    const updatedEmails = [...emails];
    updatedEmails.push('');
    setEmails(updatedEmails);
  };
  const handleRemoveRow = (rowNumber) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(rowNumber, 1);
    setEmails(updatedEmails);
  };

  //TODO: optimize below function to only be called upon email row change
  const menuHasItems = () => {
    //first check if email has previously been added
    let prevSender = false,
      prevRecipient = false;
    prevNotifications.forEach(({ recipients }) => {
      if (recipients.includes(senderEmail)) prevSender = true;
      if (recipients.includes(recipientEmail)) prevRecipient = true;
    });

    return (
      (senderEmail.length > 3 && !emails.includes(senderEmail) && !prevSender) ||
      (recipientEmail.length > 3 && !emails.includes(recipientEmail) && !prevRecipient)
    );
  };

  const prevEmails = prevNotifications.reduce((acc, { recipients }) => {
    acc.push(...recipients);
    return acc;
  }, []);

  return (
    <div className={classes.root}>
      {!open && (
        <Button
          onClick={() => setOpen(!open)}
          variant="outlined"
          color="primary"
          className={classes.noticationButton}
        >
          {translations.onlinebooking.addEmailNotifications}
        </Button>
      )}
      {open && (
        <div className={classes.notificationContainer}>
          <Typography>{translations.onlinebooking.selectNotificationAndEnterEmail}</Typography>
          <div className={classes.options}>
            {notificationOptions.map(({ key, label }) => (
              <div key={key} className={classes.optionContainer}>
                <Checkbox
                  id={key}
                  checked={selected[key]}
                  onClick={() => handleSelectOption(key)}
                />
                <label htmlFor={key}>{label}</label>
              </div>
            ))}
          </div>
          {emails.map((email, rowNumber) => (
            <div key={rowNumber} className={classes.addEmailContainer}>
              <Button
                onClick={(e) => (menuHasItems() ? setAnchorEl(e.currentTarget) : null)}
                className={classes.menuButton}
                variant="contained"
              >
                <DownArrowIcon />
              </Button>
              {menuHasItems() && (
                <Menu
                  id="email-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  {senderEmail.length > 3 &&
                    !emails.includes(senderEmail) &&
                    !prevEmails.includes(senderEmail) && (
                      <MenuItem onClick={() => handleCloseMenu(senderEmail, rowNumber)}>
                        {senderEmail}
                      </MenuItem>
                    )}
                  {recipientEmail.length > 3 &&
                    !emails.includes(recipientEmail) &&
                    !prevEmails.includes(recipientEmail) && (
                      <MenuItem onClick={() => handleCloseMenu(recipientEmail, rowNumber)}>
                        {recipientEmail}
                      </MenuItem>
                    )}
                </Menu>
              )}
              <TextField
                className={classes.textField}
                value={email}
                variant="outlined"
                onChange={(e) => handleEmailChange(rowNumber)(e)}
                inputProps={{ maxLength: 50 }}
                placeholder="Email"
              />
              {emails.length > 1 && (
                <Button
                  className={classes.removeRowButton}
                  onClick={() => handleRemoveRow(rowNumber)}
                >
                  <RemoveEmailRowIcon className={classes.removeRowIcon} />
                </Button>
              )}
            </div>
          ))}
          {emails.length < 5 && (
            <div
              style={{ marginTop: 0, justifyItems: 'end' }}
              className={classes.addEmailContainer}
            >
              <Tooltip title={translations.onlinebooking.addEmail}>
                <button className={classes.addRowButton} onClick={handleAddEmailRow}>
                  &#43;
                </button>
              </Tooltip>
            </div>
          )}
          <div className={classes.actionContainer}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className={classes.buttons}
            >
              {translations.templates.add}
              {/* <AddNotificationIcon style={{ fontSize: '20px' }} /> */}
            </Button>
            <Button onClick={handleCancel} color="primary" className={classes.buttons}>
              {translations.templates.cancel}
            </Button>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default UpsNotifications;
