import React from 'react'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
	root: {
		backgroundColor: '#FFF',
		height: '42px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: '#565656',
		borderRadius: '4px 4px 0 0',
		paddingLeft: '8px'
	},
	title: {

	},
	actions: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '5px',
		/* color:'blue !important', */
		'&:>*': {
			padding: '30px'
		}
	}
})

const CustomToolbar = (props) => {
	const { classes } = props;

	return (
		<div className={classes.root}>
			<div className="title">{props.title}</div>
			<div className={classes.actions}>
				{props.children}
			</div>
		</div>
	)
}

export default withStyles(styles)(CustomToolbar);
