import React, { useEffect, useState } from 'react';
import translations from '../../../../../services/translations/translations';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/geodis-logotype-vertical.svg';
import { buildGeodisPackagesFromTracking } from '../../TrackingResponseBuilder';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';
import { useSelector } from 'react-redux';
import { serializeGeodisShipmentDetails } from '../../../../../services/tracking';
import Axios from 'axios';
import { HorizontalSpinner } from '../../../../UI/Spinners/Horizontal';
import { CARRIER_NAMES } from '../../../../../services/types';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  loading: {
    display: 'grid',
    height: '100px',
    placeItems: 'center',
    boxShadow: '0 4px 2px -2px #c7c7c7',
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    // gridTemplateColumns: '1fr 1fr',
    gridAutoFlow: 'column',
    fontSize: '0.9rem',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '5px',
      '& p:first-child': {
        fontWeight: 300,
        textAlign: 'end',
      },
    },
  },

  '@media (max-width:830px)': {
    responseHeader: {},
  },
});

const GeodisDetailsMapper = (t) => [
  { key: 'trackingNumber', text: t.trackedshipments.trackingnumber, col: 2 },
  { key: 'masterBill', text: 'Master Bill', col: 2 },
  { key: 'houseBill', text: 'House Bill', col: 2 },
  { key: 'transportationMode', text: 'Transportation mode', col: 2 },
  { key: 'serviceLevel', text: 'Service level', col: 2 },
  { key: 'incoTerms', text: 'Inco term', col: 2 },
  { key: 'etd', text: 'ETD', col: 2 },
  { key: 'eta', text: 'ETA', col: 2 },
  { key: 'ata', text: 'ATA', col: 2 },
  { key: 'atd', text: 'ATD', col: 2 },
  { key: 'goodsDescription', text: 'Goods description', col: 2 },
  { key: 'numberOfContainers', text: 'No. Containers', col: 2 },
  { key: 'pieces', text: 'Pieces', col: 2 },
  { key: 'chargeableWeight', text: 'Chargeable weight', col: 2 },
  { key: 'actualWeight', text: 'Actual Weight', col: 2 },
  { key: 'volume', text: 'Volume', col: 2 },
  { key: 'consignorName', text: 'Consignor name', col: 1 },
  { key: 'consignorAddressLine1', text: 'Consignor address', col: 1 },
  { key: 'consignorAddressLine2', text: 'Consignor address 2', col: 1 },
  { key: 'consignorPostalCode', text: 'Consignor postal', col: 1 },
  { key: 'consignorCity', text: 'Consignor city', col: 1 },
  { key: 'consignorState', text: 'Consignor state', col: 1 },
  { key: 'consigneeName', text: 'Consignee name', col: 1 },
  { key: 'consigneeAddressLine1', text: 'Consignee address', col: 1 },
  { key: 'consigneeAddressLine2', text: 'Consignee address 2', col: 1 },
  { key: 'consigneePostalCode', text: 'Consignee postal', col: 1 },
  { key: 'consigneeCity', text: 'Consignee city', col: 1 },
  { key: 'consigneeState', text: 'Consignee state', col: 1 },
  { key: 'sendingAgentName', text: 'Sending agent name', col: 1 },
  { key: 'sendingAgentAddressLine1', text: 'Sending agent address', col: 1 },
  { key: 'sendingAgentAddressLine2', text: 'Sending agent address 2', col: 1 },
  { key: 'sendingAgentPostalCode', text: 'Sending agent postal', col: 1 },
  { key: 'sendingAgentCity', text: 'Sending agent city', col: 1 },
  { key: 'sendingAgentState', text: 'Sending agent state', col: 1 },
  { key: 'receivingAgentName', text: 'Receiving agent name', col: 1 },
  { key: 'receivingAgentAddressLine1', text: 'Receiving agent address', col: 1 },
  { key: 'receivingAgentAddressLine2', text: 'Receiving agent address 2', col: 1 },
  { key: 'receivingAgentPostalCode', text: 'Receiving agent postal', col: 1 },
  { key: 'receivingAgentCity', text: 'Receiving agent city', col: 1 },
  { key: 'receivingAgentState', text: 'Receiving agent state', col: 1 },
  { key: 'controllingPartyName', text: 'Controlling party name', col: 1 },
];

export default function GeodisTrackingView({ trackingState }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [detailsState, setDetailsState] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (trackingState.result) {
      setPackages(() =>
        buildGeodisPackagesFromTracking(trackingState.result.trackedPackagesResult, lang)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);

  useEffect(() => {
    setDetailsLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    Axios.post(
      '/api/tracking/shipment-details',
      { carrier: trackingState.carrier, trackingValue: trackingState.trackingNumber },
      config
    )
      .then((resp) => {
        const shipmentDetails = serializeGeodisShipmentDetails(resp.data.results);

        if (shipmentDetails) {
          setDetailsState(shipmentDetails);
        }
        setDetailsLoading(false);
      })
      .catch((_) => {
        setDetailsLoading(false);
        detailsState && setDetailsState(null);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobileView = useMediaQuery('(max-width:850px)');
  const classes = useStyles();

  return (
    <>
      <div className={classes.carrierHeader}>
        <p>
          {detailsState && detailsState.transportationMode
            ? `${CARRIER_NAMES[trackingState.carrier]} ${detailsState.transportationMode}`
            : `${CARRIER_NAMES[trackingState.carrier]}`}
        </p>
        <img src={Logotype} alt="GeodisTrackingView Logotype" />
      </div>
      {detailsLoading && (
        <div className={classes.loading}>
          <HorizontalSpinner />
        </div>
      )}
      {!detailsLoading && detailsState && (
        <section className={classes.responseHeader}>
          {GeodisDetailsMapper(translations[lang]).map((v, i) => (
            <div style={{ gridColumn: !mobileView ? v.col : 1 }} key={v.key}>
              <p>{v.text}:&nbsp;</p>
              <p>{detailsState[v.key]}</p>
            </div>
          ))}
        </section>
      )}
      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
    </>
  );
}
