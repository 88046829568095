import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
import { SelectStylesMain } from '../../UI/SelectStyles/SelectStyles';
import AddressDetails from '../../AddressDetails/AddressDetails';
import { fetchCarriers } from '../../../store/actions';
import translations from '../../../services/translations/translations';
import { userDetailsMapper } from '../../../utils/address/mappers';
import Select from 'react-select';
import { getLocaleFromLang } from '../../../services/helpers/localize';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//components
import UPSCollectionDetails from './UPSCollectionDetails/UPSCollectionDetails';
import RequestCollectionModal from './RequestCollectionModal/RequestCollectionModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& > section': {
      display: 'flex',
      height: '100%',
    },
  },
  header: {
    gridColumn: 'span 2',
    fontWeight: 300,
    padding: '0px 0 10px 0',
  },
  description: {
    paddingTop: '15px',
  },
  createPickup: {
    width: '100%',
  },
  createContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    minHeight: '351px',
    paddingTop: '10px',
    paddingBottom: '40px',
  },
  senderDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '20px',
  },
  senderDetailsHeader: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    maxWidth: '300px',
  },
  editContainer: {
    position: 'absolute',
    right: 0,
    transform: 'translateY(-10px)',
  },
  senderDetailsContainer: {
    width: '100%',
    padding: '0 40px',
    boxSizing: 'border-box',
  },
  collectionDetailsContainer: {
    width: '100%',
    height: 'max-content',
    display: 'grid',
    gap: '20px',
    justifyItems: 'center',
  },
  modalTitle: {
    padding: '8px',
  },
}));

const initialSenderDetails = {
  company: '',
  contact: '',
  phone: '',
  email: '',
  city: '',
  zip: '',
  address1: '',
  address2: '',
  countryName: '',
  countryCode: '',
  stateRegion: '',
};

const RequestCollection = () => {
  const { token } = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.user);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  const containerEl = useRef(null);

  const carriers = useSelector((state) => state.carriers);
  const [senderAddress, setSenderAddress] = useState(initialSenderDetails);
  const [editMode, setEditMode] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [modal, setModal] = useState({ open: false, resp: null, loading: false });

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (carriers.allCarriers.length < 1) {
      dispatch(fetchCarriers(token, userDetails.companyId));
    }

    setSenderAddress(userDetailsMapper(userDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //scroll to bottom whenever a carrier is being selected
  useEffect(() => {
    if (selectedCarrier) {
      const currentHeight = containerEl.current.offsetHeight;
      window.scrollTo(0, currentHeight);
    }
  }, [selectedCarrier]);

  const resetState = () => {
    setEditMode(false);
    setSelectedCarrier('');
    setSenderAddress(userDetailsMapper(userDetails));
  };

  const handleTextBlur = (updatedAddressData) => (value) => {
    setSenderAddress(updatedAddressData);
  };

  const handleSubmitWithCarrierDetails = (carrierSpecificData) => {
    const requestData = { ...carrierSpecificData, sender: senderAddress, carrier: selectedCarrier };
    setModal({ ...modal, open: true, loading: true, error: null, resp: null });

    axios
      .post(
        `/api/collection/create`,
        { requestData },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        setModal({ ...modal, open: true, resp: resp.data, loading: false, error: null });
      })
      .catch((e) => {
        setModal({
          ...modal,
          open: true,
          loading: false,
          resp: null,
          error: e.response.data.message,
        });
      });
  };

  const handleCarrierChange = (e) => {
    setSelectedCarrier(e.value);
  };

  const handleCloseModal = () => {
    resetState();
    setModal({ ...modal, open: false, resp: null });
  };

  const handleEdit = () => {
    setModal({ ...modal, open: false, resp: null });
  };

  const locale = getLocaleFromLang(lang);

  const carrierSpecificDetails = {
    ups: (
      <UPSCollectionDetails
        handleSubmitWithCarrierDetails={handleSubmitWithCarrierDetails}
        translations={translations[lang]}
        lang={lang}
        locale={locale}
      />
    ),
  };

  let carrierOptions = [];

  if (carriers.onlineCarriers.length > 0) {
    const { onlineCarriers, allCarriers } = carriers;
    carrierOptions = onlineCarriers.map((carrier) => {
      const carrierOption = allCarriers.find((c) => c.id === carrier);
      return { label: carrierOption['carrierName'], value: carrierOption['carrierAlias'] };
    });
  }

  return (
    <section ref={containerEl} style={{ justifyContent: 'center' }}>
      <Paper className={classes.createPickup}>
        <AppBar elevation={0} position="static">
          <CustomToolbar
            title={translations[lang].managecollection.requestcollection.title}
          ></CustomToolbar>
        </AppBar>
        <Dialog open={modal.open} onClose={handleCloseModal} fullWidth={true} maxWidth={'sm'}>
          <DialogTitle className={classes.modalTitle} id="rate-dialog-title">
            {translations[lang].managecollection.requestcollection.modal.title}
          </DialogTitle>
          <RequestCollectionModal
            handleEdit={handleEdit}
            loading={modal.loading}
            error={modal.error}
            data={modal.resp}
            translations={translations[lang]}
          />
        </Dialog>
        <div className={classes.createContainer}>
          <div className={classes.senderDetails}>
            <div className={classes.senderDetailsHeader}>
              <p style={{ width: '100%', textAlign: 'center' }}>
                {translations[lang].managecollection.requestcollection.locationtitle}
              </p>
              {!editMode && (
                <div className={classes.editContainer}>
                  <Tooltip
                    title={translations[lang].managecollection.requestcollection.changelocation}
                  >
                    <IconButton onClick={() => setEditMode(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            {!editMode && <div style={{ marginTop: '50px' }}></div>}
            <div className={classes.senderDetailsContainer}>
              <AddressDetails
                staticAddress={!editMode}
                handleTextBlur={handleTextBlur}
                userDetails={senderAddress}
                value="sender"
              />
            </div>
          </div>
          <div className={classes.collectionDetailsContainer}>
            <p className={classes.description}>
              {translations[lang].managecollection.requestcollection.transportcarrier}
            </p>
            {carrierOptions.length > 0 && (
              <div style={{ width: '50%', zIndex: 1002 }}>
                <Select
                  styles={SelectStylesMain}
                  value={carrierOptions.filter((option) => option.value === selectedCarrier)}
                  placeholder={translations[lang].managecollection.requestcollection.selectcarrier}
                  label={translations[lang].managecollection.requestcollection.carrier}
                  onChange={handleCarrierChange}
                  options={carrierOptions}
                />
              </div>
            )}
            {selectedCarrier && carrierSpecificDetails[selectedCarrier]}
          </div>
        </div>
      </Paper>
    </section>
  );
};

export default RequestCollection;
