import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/schenker_logotype.svg';
import translations from '../../../../../services/translations/translations';
import { buildSchenkerPackagesFromTracking } from '../../TrackingResponseBuilder';
import { useSelector } from 'react-redux';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';

const useStyles = makeStyles({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px 40px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& h2': {
      fontSize: '1.1rem',
      borderBottom: '1px solid',
      color: '#770000',
      fontWeight: '500',
      marginBottom: '3px',
    },
  },
  responseHeaderDetails: {
    '& p': {
      padding: '2px 0',
      fontWeight: 500,
      '& span': {
        fontWeight: 400,
      },
    },
  },
  '@media (max-width:930px)': {
    responseHeader: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

export default function SchenkerTrackingView({ trackingState }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (trackingState.result) {
      setPackages(() =>
        buildSchenkerPackagesFromTracking(trackingState.result.trackedPackagesResult, lang)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);

  const classes = useStyles();

  return (
    <>
      <div className={classes.carrierHeader}>
        <p>{trackingState.result.serviceName}</p>
        <img src={Logotype} alt="Schenker Logotype" />
      </div>
      <div className={classes.responseHeader}>
        <div>
          <h2>{translations[lang].shipmentDetails}</h2>
          <div className={classes.responseHeaderDetails}>
            <p>
              {translations[lang].weight}:&nbsp;
              <span>{`${trackingState.result.shipmentWeight} ${trackingState.result.shipmentWeightUnit}`}</span>
            </p>
            <p>
              {translations[lang].service}:&nbsp;<span>{trackingState.result.service}</span>
            </p>
          </div>
        </div>
        <div>
          <h2>{translations[lang].onlinebooking.shipfrom}</h2>
          <div className={classes.responseHeaderDetails}>
            <p>
              {translations[lang].location}:&nbsp;
              <span>{trackingState.result.shipFromAddress.City}</span>
            </p>
            <p>
              {translations[lang].postalCode}:&nbsp;
              <span>{trackingState.result.shipFromAddress.PostalCode}</span>
            </p>
            <p>
              {translations[lang].country}:&nbsp;
              <span>{trackingState.result.shipFromAddress.CountryName}</span>
            </p>
          </div>
        </div>
        <div>
          <h2>Deliver to</h2>
          <div className={classes.responseHeaderDetails}>
            <p>
              {translations[lang].location}&nbsp;
              <span>{trackingState.result.shipToAddress.City}</span>
            </p>
            <p>
              {translations[lang].postalCode}:&nbsp;
              <span>{trackingState.result.shipToAddress.PostalCode}</span>
            </p>
            <p>
              {translations[lang].country}:&nbsp;
              <span>{trackingState.result.shipToAddress.CountryName}</span>
            </p>
          </div>
        </div>
      </div>
      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
    </>
  );
}
