import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'grid',
    gridGap: '5px',
    placeItems: 'start',
  },
  button: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: '25px',
    border: '1px solid #949494',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  selected: {
    backgroundColor: '#e6e0e8',
  },
});

const TransportMode = ({ handleMode, selectedModes }) => {
  const classes = useStyles();

  const modes = [
    { title: 'Air Express', name: 'airExpress' },
    { title: 'Air Economy', name: 'airEconomy' },
    { title: 'Road Freight', name: 'roadFreight' },
    { title: 'Sea Freight', name: 'seaFreight' },
  ];

  return (
    <div className={classes.root}>
      {modes.map(mode => (
        <Button
          key={mode.name}
          onClick={() => handleMode(mode.name)}
          className={clsx([
            selectedModes.includes(mode.name) ? classes.selected : null,
            classes.button,
          ])}
          variant='outlined'
        >
          <span>{mode.title}</span>
        </Button>
      ))}
    </div>
  );
};

export default TransportMode;
