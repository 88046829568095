import React, { useEffect, useState } from 'react';
//components
import PickupSelection from './PickupSelection/PickupSelection';
import InvoiceSelection from './InvoiceSelection/InvoiceSelection';
import { checkOnlineBookingInvoiceRequired } from '../../../services/validations/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import CustomPaper from '../../UI/CustomPaper/CustomPaper';
import { UPS, DHLFREIGHT, HRX, CARRIERS } from '../../../services/types';
import HrxAdditionalOptions from './AdditionalOptions/HrxAdditionalOptions/HrxAdditionalOptions';
import VoecContainer from './AdditionalOptions/VoecContainer/VoecContainer';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  pickupInvoiceContainer: {
    display: 'grid',
    width: '100%',
    maxWidth: '800px',
    gridTemplateColumns: '1fr',
    rowGap: '20px',
  },
});

const carriersApplicableForInvoice = [UPS, DHLFREIGHT];
const carriersApplicableForAdditionalOptions = [HRX];

const getCarrierAdditionalOptions = (carrier, translations) => {
  switch (carrier) {
    case HRX:
      return <HrxAdditionalOptions translations={translations} />;
    default:
      return null;
  }
};

const InvoiceAndPickup = (props) => {
  const [invoiceRequired, setInvoiceRequired] = useState(true);
  const bookingData = useSelector((state) => state.booking);
  useEffect(() => {
    const required =
      checkOnlineBookingInvoiceRequired(
        bookingData.sender.countryCode,
        bookingData.recipient.countryCode,
        bookingData.carrier
      ) && !bookingData.documentsOnly;

    if (invoiceRequired !== required) {
      setInvoiceRequired(required);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.pickupInvoiceContainer}>
        {/* Some type of Pickup should be present for all carriers */}
        <CustomPaper title={props.translations.onlinebooking.pickupselection}>
          <PickupSelection
            locale={props.locale}
            translations={props.translations}
            withOptions={true}
          />
        </CustomPaper>
        {/* Specific carrier options */}
        {carriersApplicableForAdditionalOptions.includes(bookingData.carrier) &&
          getCarrierAdditionalOptions(bookingData.carrier, props.translations)}
        {/* Invoice uploads only work on UPS right now */}
        {invoiceRequired && carriersApplicableForInvoice.includes(bookingData.carrier) && (
          <InvoiceSelection translations={props.translations} />
        )}
        {/* Voec registration number upon shipping to Norway */}
        {bookingData.carrier === CARRIERS.UPS && bookingData.recipient.countryCode === 'NO' && (
          <VoecContainer translations={props.translations} />
        )}
      </div>
    </div>
  );
};

export default InvoiceAndPickup;
