import React from 'react';
import { darken, makeStyles, Radio } from '@material-ui/core';
import { useSelector } from 'react-redux';
import translations from '../../services/translations/translations.json';

const useStyles = makeStyles((theme) => ({
  stackableContainer: {
    height: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 5px',
    boxSizing: 'border-box',
  },
  stackableSelection: {
    margin: '0 20px',
    display: 'flex',
    alignItems: 'center',
    '& > label': {
      color: darken(theme.palette.primary.main, 0.07),
    },
  },
}));

const StackableSelection = ({ setStackable, stackable }) => {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  const classes = useStyles();

  return (
    <div className={classes.stackableContainer}>
      <p>{translations[lang].requestbooking.isThisShipmentStackable}?</p>
      <div className={classes.stackableSelection}>
        <label htmlFor='stackable'>{translations[lang].yes}</label>
        <Radio
          color='primary'
          checked={stackable === true}
          onChange={() => setStackable(true)}
          name='stackable'
          id='stackable'
        />
        <label htmlFor='non-stackable'>{translations[lang].no}</label>
        <Radio
          color='primary'
          checked={stackable === false}
          onChange={() => setStackable(false)}
          name='non-stackable'
          id='non-stackable'
        />
      </div>
    </div>
  );
};

export default StackableSelection;
