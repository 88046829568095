import React from 'react'

const PackageDelete = (props) => {

	const defaults = {
		color: '#fff',
		height: '24',
		width: '24'
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20.17"
			width={props.width || defaults.width}
			height={props.height || defaults.height}>
		>
			<polygon points="5.04 9.1 4.13 8.71 4.13 6.59 0 4.63 0 15.58 9.17 19.51 9.17 9 5.04 7.03 5.04 9.1" fill={props.color || defaults.color} />
			<polygon points="13.49 1.66 9.63 0 0.58 3.9 4.69 5.86 13.49 1.66" fill={props.color || defaults.color} />
			<polygon points="14.62 2.14 5.76 6.36 9.63 8.2 18.69 3.88 14.62 2.14" fill={props.color || defaults.color} />
			<path d="M20.25,11.88v-6l-9.17,4.37V20.76L14,19.51a5,5,0,1,0,6.24-7.63ZM18,20.5a4.12,4.12,0,0,1-3.22-6.7l5.79,5.79A4.09,4.09,0,0,1,18,20.5ZM21.18,19l-5.79-5.79A4.09,4.09,0,0,1,20.25,13a4.13,4.13,0,0,1,.93,6Z" transform="translate(-1 -1.25)" fill={props.color || defaults.color} />
		</svg>
	)
}

export default PackageDelete
