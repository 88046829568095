/* Carrier aliases */
export const UPS = 'ups';
export const UPSSCS = 'upsscs';
export const SCHENKER = 'schenker';
export const EXPEDITORS = 'expeditors';
export const MATKAHUOLTO = 'matkahuolto';
export const PAGODA = 'pagoda';
export const DHLGF = 'dhlgf';
export const DHLFREIGHT = 'dhlfreight';
export const CEVA = 'ceva';
export const HRX = 'hrx';
export const GEODIS = 'geodis';

export const CARRIERS = {
  UPS: 'ups',
  UPSSCS: 'upsscs',
  SCHENKER: 'schenker',
  EXPEDITORS: 'expeditors',
  MATKAHUOLTO: 'matkahuolto',
  PAGODA: 'pagoda',
  DHLGF: 'dhlgf',
  DHLFREIGHT: 'dhlfreight',
  CEVA: 'ceva',
  HRX: 'hrx',
  GEODIS: 'geodis',
};

export const CARRIER_NAMES = {
  [UPS]: 'UPS',
  [UPSSCS]: 'UPS SCS',
  [SCHENKER]: 'Schenker',
  [EXPEDITORS]: 'Expeditors',
  [MATKAHUOLTO]: 'Matkahuolto',
  [PAGODA]: 'Pagoda Logistics',
  [DHLGF]: 'DHL Global Forwarding',
  [DHLFREIGHT]: 'DHL Freight',
  [HRX]: 'HRX',
  [GEODIS]: 'Geodis',
};

/* Recover label carriers */
export const CARRIERS_APPLICABLE_FOR_LABEL_RECOVERY = [UPS, DHLFREIGHT];

/* Delivery types */
export const MATKAHUOLTO_HOME_DELIVERY = '99';
export const MATKAHUOLTO_DROPOFF_DELIVERY = '90';
export const DOOR_DELIVERY = '00';
export const UPS_ACCESS_POINT_DELIVERY = '01';
export const DHL_SERVICE_POINT_DELIVERY = '41';

export const DOOR_TO_DOOR = 'door-door';
export const DOOR_TO_DROPOFF = 'door-dropoff';

export const DHL_RETURN_SERVICES = ['502', '402', '104'];
export const DHL_HOME_DELIVERY_SERVICES = ['502', '402', '501', '401'];

// Additional services
export const DHL_ADDITIONAL_FIXED_DELIVERY_DATE = 'fixedDeliveryDate';

/* Service point types */
export const DHL_SERVICE_POINT_SERVICE = '103';
export const DHL_SERVICE_POINT_RETURN_SERVICE = '104';
export const DHL_SERVICE_POINT_SERVICE_CODES = [DHL_SERVICE_POINT_SERVICE]; //Additional services should perhaps be included

export const DHL_EUROCONNECT_PLUS = '232'; //DHL Euroconnect Plus
export const DHL_EUROCONNECT = '202'; //DHL Euroconnect Plus
export const DHL_EURAPID = '233'; //DHL Eurapid

//DHL Autoselect additional services
export const DHL_ADDITIONAL_OPTION_AUTOSELECT_CODES = {
  [DHL_EUROCONNECT_PLUS]: ['customsHandlingFullService'],
  [DHL_EUROCONNECT]: ['customsHandlingFullService'],
};

/* Billing types */
export const BILL_DUTIES_RECIPIENT = 'R';
export const BILL_DUTIES_SENDER = 'S';

/* Document types */
export const DOCUMENT_TYPE_INVOICE = 'invoice';
export const DOCUMENT_TYPE_CERTIFICATE_OF_ORIGIN = 'coo';
export const DOCUMENT_TYPE_OTHER = 'other';

export const INVOICE_TYPE_PAPER = 'paper';
export const INVOICE_TYPE_FILE = 'file';
export const INVOICE_TYPE_FORM = 'form';

/* Packaging types */
export const OTHER_PACKAGING = '02';
export const FULL_PALLET = '701';
export const HALF_PALLET = '702';
export const UPS_EXPRESS_ENVELOPE = '01';
export const UPS_EXPRESS_PAK = '04';
export const UPS_EXPRESS_BOX = '21';
export const UPS_EXPRESS_TUBE = '03';

/* Notification types */
export const NOTIFICATION_RECIPIENT_EMAIL = 'email';
export const NOTIFICATION_RECIPIENT_TEXT_MESSAGE = 'text-message';
export const NOTIFICATION_TYPE_EXCEPTION = 'exception';
export const NOTIFICATION_TYPE_SHIP = 'ship';
export const NOTIFICATION_TYPE_ACCESS_POINT = 'access-point';
export const NOTIFICATION_TYPE_DELIVERY = 'delivery';

export const UnitOfMeasures = [
  'BBL',
  'CAR',
  'CKG',
  'CM2',
  'CTN',
  'CUR',
  'CYK',
  'DOZ',
  'DPC',
  'DPR',
  'FBM',
  'GCN',
  'GM',
  'GRS',
  'HUN',
  'KG',
  'KM3',
  'KTS',
  'L',
  'M',
  'M2',
  'M3',
  'MC',
  'NO',
  'PCS',
  'PFL',
  'PK',
  'PRS',
  'RBA',
  'SQ',
  'T',
  'THS',
  'X',
];

export const ProductUnitOfMeasureCodes = [
  'BA',
  'BE',
  'BG',
  'BH',
  'BOX',
  'BT',
  'BU',
  'CI',
  'CM',
  'CON',
  'CR',
  'CS',
  'CT',
  'CY',
  'DOZ',
  'EA',
  'EN',
  'FT',
  'KG',
  'KGS',
  'LB',
  'LBS',
  'L',
  'M',
  'NMB',
  'PA',
  'PAL',
  'PC',
  'PCS',
  'PF',
  'PKG',
  'PR',
  'PRS',
  'RL',
  'SET',
  'SME',
  'SYD',
  'TU',
  'YD',
  'OTH',
];
export const TermsOfShipmentCodes = [
  'DDU',
  'DDP',
  'CFR',
  'EXW',
  'CIF',
  'CIP',
  'CPT',
  'DAF',
  'DEQ',
  'DES',
  'FAS',
  'FCA',
  'FOB',
];
export const HrxAllowedTermsOfShipmentCodes = ['DAP', 'EXW'];

export const ReasonForExportCodes = [
  'SALE',
  'GIFT',
  'SAMPLE',
  'RETURN',
  'REPAIR',
  'INTERCOMPANYDATA',
  'Any other reason',
];

/* Flow types */
export const THIRD_PARTY = 'third-party';
export const DOMESTIC = 'domestic';
export const EXPORT = 'export';
export const IMPORT = 'import';

/* Label short name types */
export const LABEL_SHORT_NAME_TYPE = {
  PDFA4: 'PDF A4',
  PDF: 'PDF',
  PDF6: 'PDF 4x6',
  PDFX2: 'PDF A4 X2',
  ZPL6: 'ZPL 4x6',
  ZPL8: 'ZPL 4x8',
};
