import React, { useEffect } from 'react';
import AddressInputs from './AddressInputs/AddressInputs';
import AddressOutputs from './AddressOutputs/AddressOutputs';
import {
  fetchSenderCompaniesTemplates,
  fetchRecipientCompaniesTemplates,
} from '../../store/actions';
import translations from '../../services/translations/translations';
import { useSelector, useDispatch } from 'react-redux';

const AddressDetails = ({ userDetails, value, staticAddress, handleTextBlur }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { userId } = useSelector((state) => state.user);
  const senderTemplates = useSelector((state) => state.senderTemplates.templates);
  const recipientTemplates = useSelector((state) => state.recipientTemplates.templates);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (senderTemplates.length < 1) {
      dispatch(fetchSenderCompaniesTemplates({ userId, token }));
    }
    if (recipientTemplates.length < 1) {
      dispatch(fetchRecipientCompaniesTemplates({ userId, token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let templates;
  if (value === 'recipient') templates = recipientTemplates;
  if (value === 'sender') templates = senderTemplates;

  const addressProps = staticAddress ? (
    <AddressOutputs translations={translations[lang]} addressData={userDetails} />
  ) : (
    <AddressInputs
      type={value}
      templates={templates}
      translations={translations[lang]}
      textBlur={(addressDetails) => handleTextBlur(addressDetails)(value)}
      addressData={userDetails}
    />
  );

  return <>{addressProps}</>;
};

export default AddressDetails;
