import axios from 'axios';
import * as actionTypes from './actionTypes';

export const rootTemplateSuccess = (rootTemplates) => {
  return {
    type: actionTypes.SET_ROOT_TEMPLATE_SUCCESS,
    payload: rootTemplates,
  };
};

//DEFAULT LANGUAGE
export const updateDefaultLanguageStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_LANGUAGE_START,
  };
};

export const updateDefaultLanguageFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_LANGUAGE_FAILED,
    error,
  };
};

export const updateDefaultLanguageSuccess = (language) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_LANGUAGE_SUCCESS,
    language,
  };
};

export const updateDefaultLanguage = (language, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateDefaultLanguageStart());
    axios
      .put(`/api/roottemplates/defaultlang/${language}`, null, config)
      .then((resp) => {
        dispatch(updateDefaultLanguageSuccess(resp.data.language));
      })
      .catch((err) => {
        updateDefaultLanguageFailed(err);
      });
  };
};

//DEFAULT Currency
export const updateDefaultCurrencyStart = () => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CURRENCY_START,
  };
};

export const updateDefaultCurrencyFailed = (error) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CURRENCY_FAILED,
    error,
  };
};

export const updateDefaultCurrencySuccess = (currency) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_CURRENCY_SUCCESS,
    currency,
  };
};

export const updateDefaultCurrency = (currency, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateDefaultCurrencyStart());
    axios
      .put(`/api/roottemplates/defaultcurr/${currency}`, null, config)
      .then((resp) => {
        dispatch(updateDefaultCurrencySuccess(resp.data.currency));
      })
      .catch((err) => {
        updateDefaultCurrencyFailed(err);
      });
  };
};
