import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/fi';

const getUpsActivity = (Activity, lang) => {
  let city, countryCode, location, description, timestamp;
  const activities = Array.isArray(Activity) ? Activity : [Activity];
  moment.locale(lang);
  for (let idx of activities) {
    if (
      typeof idx.ActivityLocation === 'object' &&
      idx.ActivityLocation.Address.hasOwnProperty('CountryCode')
    ) {
      countryCode = idx.ActivityLocation.Address.CountryCode;
      if (idx.ActivityLocation.Address.hasOwnProperty('City')) {
        city = idx.ActivityLocation.Address.City;
      }
      break;
    }
  }

  if ('Status' in activities[0] && 'Description' in activities[0].Status)
    description = activities[0].Status.Description;
  timestamp = moment(`${activities[0].Date}T${activities[0].Time}`).format('LLL');

  location = `${city},${countryCode}`;

  return {
    location,
    description,
    timestamp,
  };
};

const getUpsScsActivity = (Activity, lang) => {
  moment.locale(lang);
  let city, countryCode, location, description, timestamp;
  const activities = Array.isArray(Activity) ? Activity : [Activity];

  for (let idx of activities) {
    if (idx.ActivityLocation && idx.ActivityLocation.hasOwnProperty('CountryCode')) {
      countryCode = idx.ActivityLocation.CountryCode;
      if (idx.ActivityLocation.hasOwnProperty('City')) {
        city = idx.ActivityLocation.City;
      }
      break;
    }
  }

  if ('Description' in activities[0]) description = activities[0].Description;
  timestamp = moment(`${activities[0].Date}T${activities[0].Time}`).format('LLL');

  location = `${city},${countryCode}`;

  return {
    location,
    description,
    timestamp,
  };
};

const getSchenkerActivity = (activity, lang) => {
  moment.locale(lang);

  const { Address, Time, Date, Status } = activity.ActivityLocation;
  const { CountryCode, City } = Address;
  const location = `${City},${CountryCode}`;

  const timestamp = moment(`${Date}T${Time}`).format('LLL');
  const description = Status['Description'];

  return {
    location,
    description,
    timestamp,
  };
};
const getCevaActivity = (activity, lang) => {
  moment.locale(lang);

  const { StatusDate, LocationName } = activity;
  const location = LocationName;
  const timestamp = moment(StatusDate).format('LLL');
  const description = activity.Description;

  return {
    location,
    description,
    timestamp,
  };
};
const getDhlActivity = (activity, lang) => {
  moment.locale(lang);
  const location = `${activity.terminalName},${activity.terminalCountry}`;
  const timestamp = moment(`${activity.eventDate}T${activity.eventTime}`).format('LLL');
  const description = activity.eventDescription;

  return {
    location,
    description,
    timestamp,
  };
};
const getMatkahuoltoActivity = (activity, lang) => {
  let location, timestamp, description;
  moment.locale(lang);
  if (activity) {
    location = `${activity.place},FI`;
    timestamp = moment(activity.eventTime).format('LLL');
    description = activity.eventDescription;
  }

  return {
    location,
    description,
    timestamp,
  };
};
const getGeodisActivity = (activity, lang) => {
  let location, timestamp, description;
  moment.locale(lang);
  const formatTime = (time) => moment(time).format('LLL');
  if (activity) {
    location = `${activity.City},${activity.Country}`;
    timestamp = activity.Actual
      ? formatTime(activity.Actual)
      : activity.Estimated
      ? formatTime(activity.Estimated)
      : null;
    description = activity.Description;
  }

  return {
    location,
    description,
    timestamp,
  };
};

const getHrxActivity = (activity, lang) => {
  let location, timestamp, description;
  moment.locale(lang);
  if (activity) {
    location = `${activity.Name}`;
    timestamp = moment(activity.DateTime, 'DD.MM.YYYY HH:mm').format('LLL');
    description = activity.Event;
  }

  return {
    location,
    description,
    timestamp,
  };
};

export const filterUpsAddress = (addressArray, filterCode) => {
  const filteredAddress = addressArray
    .filter((addressObject) => addressObject.Type.Code === filterCode)
    .reduce((finalAddressFormat, currentAddressFormat) => {
      finalAddressFormat.city = currentAddressFormat.Address.City;
      finalAddressFormat.postalCode = currentAddressFormat.Address.PostalCode;
      finalAddressFormat.countryCode = currentAddressFormat.Address.CountryCode;

      return finalAddressFormat;
    }, {});

  return filteredAddress;
};

export const buildUpsPackagesFromTracking = (packages, lang) => {
  const packagesTracking = packages.map((pack) => {
    const trackingNumber = pack.TrackingNumber;
    const alternateTracking = pack.hasOwnProperty('AlternateTrackingNumber')
      ? pack.AlternateTrackingNumber
      : null;
    const activityProps = getUpsActivity(pack.Activity, lang);
    const { location, description, timestamp } = activityProps;
    let packageAddress = {};

    if (pack.hasOwnProperty('PackageAddress')) {
      try {
        const { Address } = pack.PackageAddress;

        if (typeof Address.AddressLine == 'string') packageAddress['address'] = Address.AddressLine;
        if (typeof Address.City == 'string') packageAddress['city'] = Address.City;
        if (typeof Address.PostalCode == 'string')
          packageAddress['postalCode'] = Address.PostalCode;
        if (typeof Address.CountryCode == 'string')
          packageAddress['countryCode'] = Address.CountryCode;
      } catch (e) {
        packageAddress = null;
      }
    } else {
      packageAddress = null;
    }

    const activities = Array.isArray(pack.Activity) ? pack.Activity : [pack.Activity];

    return {
      trackingNumber,
      location,
      description,
      activities,
      timestamp,
      packageAddress,
      alternateTracking,
    };
  });

  return packagesTracking;
};
export const buildUpsScsPackagesFromTracking = (packages, lang) => {
  const packagesTracking = packages.map((pack) => {
    const trackingNumber = pack.trackingNumber;
    const activityProps = getUpsScsActivity(pack.activity, lang);
    const { location, description, timestamp } = activityProps;

    const activities = Array.isArray(pack.activity) ? pack.activity : [pack.activity];

    return {
      trackingNumber,
      location,
      activities,
      description,
      timestamp,
    };
  });

  return packagesTracking;
};

export const buildExpeditorsPackagesFromTracking = (packages, lang) => {
  moment.locale(lang);
  let origin = '',
    destination = '';

  const packagesTracking = packages.map((pack) => {
    const recentActivity = Object.assign({}, pack.Activity[0]);
    if ('name' in recentActivity.origin) origin = recentActivity.origin.name;
    if ('name' in recentActivity.destination) destination = recentActivity.destination.name;

    return {
      trackingNumber: pack.TrackingNumber,
      origin,
      activities: pack.Activity,
      destination,
      description: recentActivity.description,
      timestamp: moment(recentActivity.time).format('LLL'),
    };
  });

  return packagesTracking;
};
export const buildGeodisPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];
  const { location, description, timestamp } = getGeodisActivity(currentActivity, lang);

  return [
    {
      trackingNumber: TrackingNumber,
      location,
      activities: Activity,
      description,
      timestamp,
    },
  ];
};

export const buildSchenkerPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];

  const { location, description, timestamp } = getSchenkerActivity(currentActivity, lang);

  return [
    {
      trackingNumber: TrackingNumber,
      activities: Activity,
      location,
      description,
      timestamp,
    },
  ];
};
export const buildCevaPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];

  const { location, description, timestamp } = getCevaActivity(currentActivity, lang);

  return [
    {
      trackingNumber: TrackingNumber,
      activities: Activity,
      location,
      description,
      timestamp,
    },
  ];
};
export const buildDhlFreightPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];

  const { location, description, timestamp } = getDhlActivity(currentActivity, lang);

  const activities = Array.isArray(packages[0].Activity)
    ? packages[0].Activity
    : [packages[0].Activity];

  return [
    {
      trackingNumber: TrackingNumber,
      location,
      description,
      timestamp,
      activities,
    },
  ];
};
export const buildMatkahuoltoPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];

  const { location, description, timestamp } = getMatkahuoltoActivity(currentActivity, lang);

  return [
    {
      trackingNumber: TrackingNumber,
      activities: Activity,
      location,
      description,
      timestamp,
    },
  ];
};
export const buildHrxPackagesFromTracking = (packages, lang) => {
  //get most recent activity
  const { Activity, TrackingNumber } = packages[0];
  const currentActivity = Activity[0];

  const { location, description, timestamp } = getHrxActivity(currentActivity, lang);

  return [
    {
      trackingNumber: TrackingNumber,
      location,
      description,
      timestamp,
    },
  ];
};
