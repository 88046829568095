import React from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import { buildOptionsFromApplicableCurrencyCodes } from '../../../../../services/helpers/getCurrencies';

//TODO: move these values to database (also exists in account settings component)
const currencies = buildOptionsFromApplicableCurrencyCodes();

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  textField: {
    marginRight: '5px',
    marginLeft: '5px',
  },
  monetaryValue: {
    width: '100%',
    minWidth: '100px',
  },
  currency: {
    minWidth: '56px',
  },
  menu: {},
});

export default function CustomsValue({
  required,
  translations,
  monetaryValue,
  handleMonetaryValueChange,
  handleMonetaryValueBlur,
  currency,
  handleCurrencyChange,
  monetaryError = false,
}) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="standard-customs-value"
        label={translations.customsvalue}
        className={clsx(classes.monetaryValue, classes.textField)}
        value={monetaryValue}
        onChange={handleMonetaryValueChange}
        onBlur={handleMonetaryValueBlur}
        margin="dense"
        required={required}
        error={monetaryError}
      />
      <TextField
        id="standard-select-currency"
        select
        label={translations.select}
        className={clsx(classes.currency, classes.textField)}
        value={currency}
        onChange={handleCurrencyChange}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText={translations.selectcurrency}
        margin="dense"
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </form>
  );
}
