import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: '8px',
    textAlign: 'center',
  },
  DialogContent: {
    borderTop: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  actionContainer: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '10px',
  },
}));

export default function FileBookingPrintModal({
  open,
  handleAbort,
  printShipments,
  actionByFormat,
  translations,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby="label-recovery-dialog-title"
      aria-describedby="label-recovery-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle className={classes.modalTitle} id="label-recovery-dialog-title">
        {translations.printLabels}
      </DialogTitle>
      <div className={classes.DialogContent}>
        <p style={{ fontWeight: '500' }}>{translations.printLabelsForSelected}</p>

        <div className={classes.actionContainer}>
          {Object.keys(printShipments).map((format) =>
            actionByFormat(format, printShipments[format])
          )}
        </div>
      </div>
    </Dialog>
  );
}
