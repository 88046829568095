export const getLocaleFromLang = lang => {
  switch (lang) {
    case 'se':
      return 'sv-SE';
    case 'en':
      return 'en-US';
    case 'fi':
      return 'fi-FI';
    default:
      return 'en-US';
  }
};
