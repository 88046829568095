//groupby returns map
export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function serializeTemplateProduct({ product }) {
  return Object.keys(product).reduce((acc, field) => {
    if (!product[field]) {
      acc[field] = '';
      return acc;
    }
    if (field === 'unitValue') {
      acc[field] = (parseFloat(product[field]).toPrecision(10) / 1).toString();
      return acc;
    }
    acc[field] = product[field];
    return acc;
  }, {});
}
