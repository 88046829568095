import { groupNotificationsByType } from '../helpers';
import { notificationsSchema } from '../schemas';

export const validateNotifications = async ({ notifications, lang }) => {
  const notificationsGroupedByType = groupNotificationsByType({ notifications });

  const validate = await notificationsSchema(lang)
    .validate(notificationsGroupedByType)
    .then((valid) => ({ valid: true, errors: null }))
    .catch((err) => ({ valid: false, errors: err.errors, variant: 'error' }));

  return validate;
};
