import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button, Dialog, DialogTitle, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import translationsCommonCodes from '../../../../services/translations/translationsCommonCodes';
import translations from '../../../../services/translations/translations';
import {
  updateProductTemplate,
  addProductTemplate,
  updateInvoiceNumber,
  fetchProductTemplate,
} from '../../../../store/actions';
//components
import ProductModal from './ProductModal/ProductModal';
import SnackbarContentWrapper from '../../../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import { ValidateInvoiceFormByCarrier } from '../../../../services/validations';
import InvoiceSettings from './InvoiceSettings/InvoiceSettings';
import { green, grey } from '@material-ui/core/colors';
import AddedProductsTable from './AddedProductsTable/AddedProductsTable';
import { CARRIERS } from '../../../../services/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceDetailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceSettings: {
    maxWidth: '700px',
    alignSelf: 'center',
    margin: '5px 0 15px 0',
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  productTemplatesSelectTable: {
    padding: '6px',
    border: `1px solid ${grey[100]}`,
    boxShadow: `1px 1px 4px ${grey[100]}`,
    borderRadius: '4px',
    minHeight: '180px',
    height: 'fit-content',
  },
  productTemplatesSelectTableHeader: {
    marginBottom: '10px',
    height: '28px',
    display: 'flex',
    alignItems: 'end',
  },
  addedProductsTableContainer: {
    width: '100%',
  },
  addedProductsTableHeader: {
    display: 'grid',
    gridTemplateColumns: 'max-content 35px 1fr',
    columnGap: '10px',
    height: '35px',
    margin: '10px',
  },
  addedProductsTableHeaderText: {
    textAlign: 'center',
    fontSize: '1.1rem',
    alignSelf: 'center',
    color: theme.palette.primary.main,
  },
  generateInvoiceButtonContainer: {
    gridColumn: '1 / -1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    '& > *': {
      margin: '2px 0',
      width: '100%',
      maxWidth: '700px',
    },
  },
  addedNumber: {
    display: 'grid',
    placeItems: 'center',
    alignSelf: 'end',
    border: `2px solid ${green[600]}`,
    height: '30px',
    width: '30px',
    '& > p': {
      fontWeight: '700',
      fontSize: '1.1rem',
    },
  },
  addProductButton: {
    width: '100%',
    maxWidth: '700px',
    justifySelf: 'end',
    fontSize: '0.9rem',
    backgroundColor: green[700],
    padding: '4px 12px',
    color: 'white',
    borderRadius: '15px',
    '&:hover': {
      background: green[800],
    },
  },
}));

const createRowsFromTemplateData = ({
  id,
  templateId,
  description,
  unitCurrency,
  unitNumber,
  unitValue,
  unitMeasureCode,
  commodityCode,
  originCountryCode,
}) => {
  return {
    id,
    templateId,
    description,
    unitNumber,
    unitValue,
    unitCurrency,
    unitMeasureCode,
    commodityCode,
    originCountryCode,
  };
};

const initialInvoiceDetails = { invoiceNumber: '', reasonForExport: '', termsOfShipment: '' };

const UpsInvoiceForm = ({
  handleCancelInvoiceForm,
  handleAddInvoiceForm,
  bookingCurrency,
  actionTitle,
  forceSaveTemplate = false,
  showAutoIncrement = false,
  autoIncrement = false,
  setAutoIncrement = null,
}) => {
  const [productList, setProductList] = useState([]);
  const [templateRows, setTemplateRows] = useState([]);
  const [invoiceFormTemplateName, setInvoiceFormTemplateName] = useState('');
  const [addedProductsCounter, setAddedproductsCounter] = useState(new Map());
  const [invoiceDetailsState, setInvoiceDetailsState] = useState(initialInvoiceDetails);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ variant: '', messages: [], open: false });
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const { token } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.user);
  const { templates } = useSelector((state) => state.productTemplates);
  const { invoiceFormPreset, autoIncrementInvoiceNumber, productsFilteredColumns } = useSelector(
    (state) => state.bookingPresets
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setInvoiceDetailsState((state) => ({ ...state, ...invoiceFormPreset }));
    if (templates.length === 0) {
      dispatch(fetchProductTemplate({ userId, token }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.length > 0 && templateRows.length === 0) {
      const templateRowsData = templates
        .map((template) => createRowsFromTemplateData(template))
        .sort((a, b) => a.templateId.localeCompare(b.templateId));
      setTemplateRows(templateRowsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const handleInvoiceDetailsChange = (e) => {
    const { value, name } = e.target;
    setInvoiceDetailsState({ ...invoiceDetailsState, [name]: value });
  };

  const getFieldsForRequest = ({ productData }) => {
    const productDataFields = Object.assign({}, productData);
    if ('saveProduct' in productDataFields) delete productDataFields.saveProduct;
    if ('createdAt' in productDataFields) delete productDataFields.createdAt;
    if ('id' in productDataFields) delete productDataFields.id;
    if ('updateProduct' in productDataFields) delete productDataFields.updateProduct;

    return productDataFields;
  };

  //TODO: Critical - All Products needs to update
  // const updateProductInList = (oldProduct, newProduct) => {
  //   return newProduct;
  // };

  const handleUnitAmountChange = (templateId, type) => {
    //If product id added, update the product list
    const updatedProductList = [...productList];
    const templateIdIndex = updatedProductList.findIndex((p) => p.templateId === templateId);

    if (templateIdIndex >= 0) {
      let productForUpdate = updatedProductList[templateIdIndex];
      if (type === 'increase') productForUpdate.unitNumber++;
      if (type === 'decrease' && productForUpdate.unitNumber > 1) productForUpdate.unitNumber--;
    }

    const updatedTemplateRows = [...templateRows];
    const templateRowIndex = updatedTemplateRows.findIndex((t) => t.templateId === templateId);

    if (templateRowIndex >= 0) {
      const updatedTemplate = updatedTemplateRows[templateRowIndex];
      if (type === 'increase') updatedTemplate.unitNumber++;
      if (type === 'decrease' && updatedTemplate.unitNumber > 1) updatedTemplate.unitNumber--;

      updatedTemplateRows.splice(templateRowIndex, 1, updatedTemplate);
    }

    setTemplateRows(() => updatedTemplateRows);
    setProductList(updatedProductList);
  };

  const handleAddProduct = ({ product, from }) => {
    let templateId = null;
    const shouldUpdateTemplate = Boolean(product.updateProduct);

    if (!(product.updateProduct || product.saveProduct)) {
      if (from === 'modal') templateId = `${product.templateId}${uuidv4()}`;
      if (from === 'table') templateId = product.templateId;
    }

    //Save or Update if selected
    if (shouldUpdateTemplate) {
      //Update templateRows
      if (product.templateId) {
        templateId = product.templateId;
        delete product.updateProduct;
        const updatedTemplateRows = [...templateRows];
        const templateRowIndex = updatedTemplateRows.findIndex((t) => t.templateId === templateId);

        const updatedTemplate = createRowsFromTemplateData(product);

        updatedTemplateRows.splice(templateRowIndex, 1, updatedTemplate);
        setTemplateRows(() => updatedTemplateRows);
      }

      if ('id' in product) {
        const productData = getFieldsForRequest({ productData: product });
        dispatch(updateProductTemplate({ productData, productId: product.id, token }));
      }
    }

    if (product.saveProduct) {
      templateId = product.templateId;
      const productData = getFieldsForRequest({ productData: product });
      dispatch(
        addProductTemplate({
          productData: { ...productData },
          token,
        })
      );
    }

    if (!templateId) return;

    const updatedProductList = [...productList];

    //check if product already in product list
    const templateIdIndex = updatedProductList.findIndex((p) => p.templateId === templateId);
    if (templateIdIndex >= 0) {
      let productForUpdate;

      if (shouldUpdateTemplate) {
        productForUpdate = { ...product, count: updatedProductList[templateIdIndex].count + 1 };
        updatedProductList.splice(templateIdIndex, 1, productForUpdate);
      }
      if (!shouldUpdateTemplate) {
        productForUpdate = updatedProductList[templateIdIndex];
        productForUpdate.count++;
      }

      setAddedproductsCounter((map) => new Map(map.set(templateId, productForUpdate.count)));
    } else {
      updatedProductList.push({ ...product, count: 1, templateId });

      setAddedproductsCounter((map) => new Map(map.set(templateId, 1)));
    }

    setProductList(updatedProductList);
    setProductModalOpen(false);
  };

  const handleRemoveProduct = ({ templateId }) => {
    const updatedProductList = [...productList];
    const templateIdIndex = updatedProductList.findIndex((p) => p.templateId === templateId);

    if (templateId < 0) return;

    //Update product
    const addedCount = addedProductsCounter.get(templateId);

    if (!addedCount || addedCount === 0) return;
    const productForUpdate = updatedProductList[templateIdIndex];

    if (addedCount === 1) {
      updatedProductList.splice(templateIdIndex, 1);
    }
    if (addedCount > 1) {
      productForUpdate.count--;
      setAddedproductsCounter((map) => new Map(map.set(templateId, productForUpdate.count)));

      updatedProductList.splice(templateIdIndex, 1, productForUpdate);
    }
    setProductList(updatedProductList);

    //Update added count
    setAddedproductsCounter((map) => new Map(map.set(templateId, addedCount - 1)));
  };

  const handleSubmitInvoiceForm = async () => {
    const productListFields = [
      'commodityCode',
      'description',
      'originCountryCode',
      'unitCurrency',
      'unitMeasureCode',
      'unitNumber',
      'unitValue',
    ];
    const products = productList.map((product) =>
      Object.keys(product).reduce((acc, field) => {
        if (productListFields.includes(field)) {
          if (field === 'unitNumber') {
            acc[field] = (product.count * parseFloat(product[field])).toString();
          } else {
            acc[field] = product[field].toString();
          }
          return acc;
        }
        return acc;
      }, {})
    );

    const invoiceForm = Object.assign({}, invoiceDetailsState, { products });
    const validation = await ValidateInvoiceFormByCarrier({
      carrier: CARRIERS.UPS,
      invoiceForm,
      lang,
    });
    if (!validation.valid)
      setSnackbar({
        ...snackbar,
        open: true,
        messages: validation.errors || '',
        variant: validation.variant,
      });
    if (validation.valid) {
      autoIncrementInvoiceNumber && handleIncrementInvoiceNumber(); //Update invoice number if user preset increment is set
      handleAddInvoiceForm({ invoiceForm, invoiceFormTemplateName }); //Add the invoice form to booking
    }
  };

  const handleIncrementInvoiceNumber = () => {
    if (!parseInt(invoiceDetailsState.invoiceNumber)) {
      return;
    }

    let invoiceNumber = parseInt(invoiceDetailsState.invoiceNumber) + 1 + '';

    dispatch(updateInvoiceNumber({ invoiceNumber, userId, token }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const updatedSnackbar = { ...snackbar, open: false };
    setSnackbar({ ...snackbar, ...updatedSnackbar });
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Dialog
        open={productModalOpen}
        onClose={() => setProductModalOpen(false)}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="product-dialog-title">
          {translations[lang].invoiceForm.addProduct}
        </DialogTitle>
        <ProductModal
          lang={lang}
          translationsCommonCodes={translationsCommonCodes[lang]}
          translations={translations[lang]}
          handleCancelProduct={() => setProductModalOpen(false)}
          handleAddProduct={({ product }) => handleAddProduct({ product, from: 'modal' })}
          currency={bookingCurrency}
        />
      </Dialog>

      <div className={classes.invoiceDetailsContainer}>
        <div className={classes.invoiceSettings}>
          <InvoiceSettings
            invoiceDetailsState={invoiceDetailsState}
            handleInvoiceDetailsChange={handleInvoiceDetailsChange}
            showAutoIncrement={showAutoIncrement}
            autoIncrement={autoIncrement}
            setAutoIncrement={setAutoIncrement}
          />
        </div>

        <div className={classes.productContainer}>
          <div className={classes.addedProductsTableContainer}>
            <div className={classes.addedProductsTableHeader}>
              <p className={classes.addedProductsTableHeaderText}>
                {translations[lang].invoiceForm.addedProducts}
              </p>
              <div className={classes.addedNumber}>
                <p>{[...addedProductsCounter].reduce((acc, curr) => (acc += curr[1]), 0)}</p>
              </div>
              <Button
                onClick={() => setProductModalOpen(true)}
                className={classes.addProductButton}
              >
                +{translations[lang].invoiceForm.addProduct}
              </Button>
            </div>

            <AddedProductsTable
              translationsCommonCodes={translationsCommonCodes[lang]}
              products={productList}
              handleRemoveProduct={handleRemoveProduct}
              handleAddProduct={({ product }) => handleAddProduct({ product, from: 'table' })}
              translations={translations[lang]}
              templateRows={templateRows}
              addedProductsCounter={addedProductsCounter}
              productsFilteredColumns={productsFilteredColumns}
              handleUnitAmountChange={handleUnitAmountChange}
              setInvoiceFormTemplateName={(v) => setInvoiceFormTemplateName(v)}
              invoiceFormTemplateName={invoiceFormTemplateName}
              forceSaveTemplate={forceSaveTemplate}
            />
            <div className={classes.generateInvoiceButtonContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmitInvoiceForm}>
                {actionTitle || translations[lang].invoiceForm.generateInvoice}
              </Button>
              <Button onClick={handleCancelInvoiceForm}>
                {translations[lang].templates.cancel}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          messages={snackbar.messages}
        />
      </Snackbar>
    </div>
  );
};

export default UpsInvoiceForm;
