import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTableCompany from '../TemplateTables/EnhancedTableCompany/EnhancedTableCompany';
import AddCompany from '../AddCompany/AddRecipientCompany';
import EditRecipientCompany from '../EditCompany/EditRecipientCompany';
import EditRecipientCompanyContacts from '../EditContacts/EditRecipientCompanyContacts';
import EmptyTable from '../TemplateTables/EmptyTable/EmptyTable';
import { connect, useDispatch } from 'react-redux';
import {
  copyCompanyTemplate,
  fetchRecipientCompaniesTemplates,
  removeRecipientCompaniesTemplates,
  updateDefaultRecipient,
  updateTemplateComment,
} from '../../../store/actions';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
import AddCompanyCommentModal from '../AddCompanyCommentModal/AddCompanyCommentModal';

const initialState = {
  content: 'table',
  editCompany: null,
  editCompanyIndex: null,
};

const initialUpdateCommentModalState = {
  open: false,
  template: null,
  flow: null,
};

function RecipientTemplate(props) {
  const [state, setState] = useState(initialState);
  const [updateCommentModalState, setUpdateCommentModalState] = useState(
    initialUpdateCommentModalState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.templates.length < 1) {
      props.onfetchRecipientTemplates(props.id, props.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCompany = () => {
    setState({ ...state, content: 'add', editCompany: null });
  };
  const handleChangeToTable = () => {
    setState({ ...state, content: 'table', editCompany: null });
  };

  const handleChangeToContacts = (id) => {
    const companyEdit = props.templates.filter((obj) => obj.id === id);
    const companyIndex = props.templates.findIndex((company) => company.idid);

    setState({
      ...state,
      content: 'editCompanyContacts',
      editCompany: companyEdit[0],
      editCompanyIndex: companyIndex,
    });
  };

  const handleRemoveCompany = (id) => {
    props.onRemoveRecipientTemplates(id, props.token);
  };

  const handleEditCompany = (id) => {
    const companyEdit = props.templates.filter((obj) => obj.id === id);
    setState({ ...state, content: 'editCompany', editCompany: companyEdit[0] });
  };

  const handleUpdateDefault = (id) => {
    props.onUpdateDefaultRecipient(props.id, id);
  };
  const handleUpdateCommentModal = (id) => {
    const template = props.templates.find((template) => template.id === id);
    setUpdateCommentModalState({
      ...updateCommentModalState,
      open: true,
      template,
      flow: 'recipient',
    });
  };

  const handleCopyTemplate = (id) => {
    const copyToFlow = 2; //copy template to recipient
    props.onCopyTemplate(id, copyToFlow);
  };

  const handleUpdateComment = useCallback(
    (comment, templateId, flow) => {
      dispatch(updateTemplateComment(templateId, comment, flow));
      setUpdateCommentModalState(initialUpdateCommentModalState);
    },
    [dispatch]
  );

  const modals = useMemo(
    () => [
      <AddCompanyCommentModal
        id="updateCommentModal"
        open={updateCommentModalState.open}
        template={updateCommentModalState.template}
        flow={updateCommentModalState.flow}
        updateComment={handleUpdateComment}
        handleAbort={() => setUpdateCommentModalState(initialUpdateCommentModalState)}
      />,
    ],
    [updateCommentModalState, handleUpdateComment]
  );

  let output = null;
  let tableData = null;
  let content = state.content;

  const companyRows = [
    {
      id: 'templateName',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.templates.template,
    },
    {
      id: 'companyName',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.company,
    },
    {
      id: 'companyCountry',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.country,
    },
    {
      id: 'companyContacts',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.templates.contacts,
    },
    {
      id: 'companyCity',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.city,
    },
    {
      id: 'companyPostalCode',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.zip,
    },
    {
      id: 'companyAddress1',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: `${props.translations.addressrow} 1`,
    },
  ];
  const contactRows = [
    {
      id: 'name',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.contact,
    },
    {
      id: 'phone',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.phone,
    },
    {
      id: 'email',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: props.translations.email,
    },
  ];

  if (content === 'table') {
    if (props.loading) {
      tableData = <CircleSpinnerLarge />;
    } else {
      if (props.templates.length < 1) {
        tableData = (
          <EmptyTable
            translations={props.translations}
            type="company"
            title={props.translations.templates.savedrecipients}
            rows={9}
            changeToAdd={handleAddCompany}
          />
        );
      } else {
        tableData = (
          <EnhancedTableCompany
            translations={props.translations}
            title={props.translations.templates.savedrecipients}
            copyTooltip={props.translations.templates.savedsenders}
            rows={companyRows}
            handleUpdateDefaultTemplate={handleUpdateDefault}
            updateTemplateComment={handleUpdateCommentModal}
            handleRemoveTemplate={handleRemoveCompany}
            handleAddCompany={handleAddCompany}
            handleEditTemplate={handleEditCompany}
            handleEditTemplateContacts={handleChangeToContacts}
            handleCopyTemplate={handleCopyTemplate}
            defaultId={props.defaultId}
            companyData={props.templates}
            modals={modals}
          />
        );
      }
    }

    output = tableData;
  } else if (content === 'add') {
    output = (
      <AddCompany
        translations={props.translations}
        handleAddCompany={handleAddCompany}
        handleChangeToTable={handleChangeToTable}
        flow="recipient"
      />
    );
  } else if (content === 'editCompany') {
    output = (
      <EditRecipientCompany
        translations={props.translations}
        handleChangeToContacts={handleChangeToContacts}
        handleChangeToTable={handleChangeToTable}
        companyData={state.editCompany}
        flow="recipient"
      />
    );
  } else if (content === 'editCompanyContacts') {
    output = (
      <EditRecipientCompanyContacts
        translations={props.translations}
        title="Kontakter"
        handleChangeToTable={handleChangeToTable}
        companyIndex={state.editCompanyIndex}
        companyData={state.editCompany}
        rows={contactRows}
      />
    );
  }

  return <div>{output}</div>;
}

const mapStateToProps = (state) => {
  return {
    id: state.user.userId,
    defaultId: state.bookingPresets.defaultRecipient,
    templates: state.recipientTemplates.templates,
    loading: state.recipientTemplates.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchRecipientTemplates: (id, token) =>
      dispatch(fetchRecipientCompaniesTemplates({ userId: id, token })),
    onRemoveRecipientTemplates: (id, token) =>
      dispatch(removeRecipientCompaniesTemplates(id, token)),
    onUpdateDefaultRecipient: (userId, recipientId) =>
      dispatch(updateDefaultRecipient(userId, recipientId)),
    onCopyTemplate: (templateId, copyToFlow) =>
      dispatch(copyCompanyTemplate({ templateId, copyToFlow })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientTemplate);
