import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../../../../../assets/ups_logotype.svg';
import translations from '../../../../../services/translations/translations';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { buildUpsPackagesFromTracking, filterUpsAddress } from '../../TrackingResponseBuilder';
import TrackingDetails from '../../TrackingDetails/TrackingDetails';
import TrackingMap from '../../../../GeoMaps/TrackingMap/TrackingMap';
import { grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  carrierHeader: {
    height: '50px',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > img': {
      height: '100%',
      padding: '5px 0',
      boxSizing: 'border-box',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
  },
  responseHeader: {
    padding: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px 40px',
    boxShadow: '0 4px 2px -2px #c7c7c7',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& h2': {
      fontSize: '1.1rem',
      borderBottom: '1px solid',
      color: '#770000',
      fontWeight: '500',
      marginBottom: '3px',
    },
  },
  responseHeaderDetails: {
    '& p': {
      padding: '2px 0',
      fontSize: '1rem',
      fontWeight: 500,
      '& span': {
        fontWeight: 400,
      },
    },
  },
  alternateTrackingContainer: {
    borderBottom: `1px solid ${grey[900]}`,
    borderTop: `1px solid ${grey[900]}`,
    backgroundColor: grey[300],
    color: red[700],
    '& > p': {
      padding: '5px 0',
      textAlign: 'center',
      fontWeight: '500',
    },
  },
  alternateTrackingNumberLink: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  delivery: {
    gridColumn: '1/-1',
    alignItems: 'center',
    border: `1px solid #770000`,
    borderRadius: '4px',
    padding: '10px',
  },
  deliveryDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  deliveryDescription: {
    color: '#770000',
    fontSize: '0.9rem',
  },
  deliveryDate: {
    fontSize: '1.3rem',
  },
  '@media (max-width:930px)': {
    responseHeader: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  [theme.breakpoints.down('xs')]: {
    responseHeader: {
      '& h2': {
        fontSize: '1rem',
      },
    },
    responseHeaderDetails: {
      '& p': {
        fontSize: '0.9rem',
      },
    },
  },
}));

const initialState = {
  shipToAddress: null,
  shipmentWeight: null,
  shipmentWeightUnit: null,
  serviceName: null,
  reference: null,
  delivery: null,
};

export default function UpsTrackingView({ trackingState, trackingNumberRedirect }) {
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [state, setState] = useState(initialState);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (trackingState.result) {
      setPackages(() =>
        buildUpsPackagesFromTracking(trackingState.result.trackedPackagesResult, lang)
      );

      const shipToAddressResult = trackingState.result.shipToAddress
        ? filterUpsAddress(trackingState.result.shipToAddress, '02')
        : null;

      setState({
        shipToAddress: shipToAddressResult,
        shipmentWeight: trackingState.result.shipmentWeight,
        shipmentWeightUnit: trackingState.result.shipmentWeightUnit,
        serviceName: trackingState.result.serviceName,
        reference: trackingState.result.referenceNumber,
        delivery: trackingState.result.delivery,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingState.result]);

  const handleAlternateTracking = (tracking) => {
    trackingNumberRedirect(tracking);
  };

  const classes = useStyles();

  return (
    <>
      {packages.length > 0 && packages[0].alternateTracking && (
        <div className={classes.alternateTrackingContainer}>
          <p>
            {translations[lang].alternateTrackingNumber}&#58;{' '}
            <span
              onClick={() => handleAlternateTracking(packages[0].alternateTracking)}
              className={classes.alternateTrackingNumberLink}
            >{`${packages[0].alternateTracking}`}</span>
          </p>
        </div>
      )}
      <div className={classes.carrierHeader}>
        <p>{state.serviceName}</p>
        <img src={Logotype} alt="UPS Logotype" />
      </div>
      <div className={classes.responseHeader}>
        {state.shipToAddress && (
          <div>
            <h2>{translations[lang].recipientAddress}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].location}&#58; <span>{state.shipToAddress.city}</span>
              </p>
              <p>
                {translations[lang].postalCode}&#58; <span>{state.shipToAddress.postalCode}</span>
              </p>
              <p>
                {translations[lang].country}&#58; <span>{state.shipToAddress.countryCode}</span>
              </p>
            </div>
          </div>
        )}
        {state.shipmentWeight && (
          <div>
            <h2>{translations[lang].shipmentDetails}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].weight}&#58;&nbsp;
                <span>
                  {state.shipmentWeight} {state.shipmentWeightUnit}
                </span>
              </p>
              <p>
                {translations[lang].service}&#58;&nbsp;<span>{state.service}</span>
              </p>
              <p>
                {translations[lang].shippingReference}&#58; <span>{state.reference}</span>
              </p>
            </div>
          </div>
        )}
        {packages.length > 0 && packages[0].packageAddress && (
          <div>
            <h2> {translations[lang].deliveryTo}</h2>
            <div className={classes.responseHeaderDetails}>
              <p>
                {translations[lang].address}&#58;&nbsp;
                <span>{packages[0].packageAddress.address}</span>
              </p>
              <p>
                {translations[lang].location}&#58; <span>{packages[0].packageAddress.city}</span>
              </p>
              <p>
                {translations[lang].postalCode}&#58;&nbsp;
                <span>{packages[0].packageAddress.postalCode}</span>
              </p>
              <p>
                {translations[lang].country}&#58;&nbsp;
                <span>{packages[0].packageAddress.countryCode}</span>
              </p>
            </div>
          </div>
        )}
        {state.delivery && (
          <div className={classes.delivery}>
            <div className={classes.deliveryDetailsContainer}>
              <p className={classes.deliveryDescription}>{state.delivery.description}</p>
              {state.delivery.date && (
                <p className={classes.deliveryDate}>{`${moment(
                  state.delivery.date,
                  'YYYYMMDD'
                ).format('dddd, LL')}`}</p>
              )}
            </div>
          </div>
        )}
      </div>
      {packages && (
        <TrackingDetails
          translations={translations[lang]}
          packages={packages}
          carrier={trackingState.carrier}
        />
      )}
      {trackingState.result && (
        <TrackingMap
          carrier={trackingState.carrier}
          mapData={trackingState.result.trackedPackagesResult}
        />
      )}
    </>
  );
}
