import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  selectEmpty: {
    padding: '0px !important',
    '&:root': {
      background: 'blue',
    },
  },
  label: {
    fontSize: '0.75rem',
    color: 'gray',
    textAlign: 'center',
  },
  selectInput: {
    paddingRight: '20px',
  },
  selectContainer: {
    display: 'flex',
    padding: '0px',
    border: '1px solid #AFAFAF',
    borderRadius: '2px',
    minWidth: '0px',
  },
  button: {
    minWidth: 0,
    width: '100%',
    padding: '1px 0px 1px 2px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonIcons: {
    display: 'grid',
    gridTemplateRows: '24px 16px',
    placeItems: 'center',
  },
  buttonImage: {
    maxHeight: 18,
    maxWidth: 24,
    padding: '2px',
  },
  menuItem: {
    fontSize: '1rem',
    minHeight: '28px',
    display: 'flex',
  },
  noTemplates: {
    fontSize: '1rem',
    minHeight: '28px',
    fontStyle: 'italic',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'default',
    },
  },
  carrierIcon: {
    height: 24,
  },
}));

const PackagingSelect = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(packaging) {
    if (packaging.packaging !== undefined) {
      props.change(packaging);
    }
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <Tooltip title={props.tooltip}>
        <Button
          style={{ color: props.color ? props.color : '' }}
          className={classes.button}
          variant="outlined"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div className={classes.buttonIcons}>
            <img
              className={classes.buttonImage}
              src={props.descriptionIcon}
              alt="package type icon"
            />
            <ArrowDropDownIcon style={{ color: '#4e4d4e' }} />
          </div>
        </Button>
      </Tooltip>

      <Menu
        id={props.id.toString()}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.templateData.menuItems.map((template, index) => (
          <MenuItem key={index} className={classes.menuItem} onClick={() => handleClose(template)}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50px auto',
                columnGap: 20,
                justifyItems: 'center',
              }}
            >
              <img
                alt="Package descriptive icon"
                className={classes.carrierIcon}
                src={template.image}
              />
              <p>{props.translations[template.translationId]}</p>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PackagingSelect;
