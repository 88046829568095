import React from 'react';

const DetailsRow = (props) => {
	return (
		<>
			<h2 style={{ padding: '4px 4px 4px 0' }}>{props.description}</h2>
			<p style={{ padding: '4px 4px 4px 0' }}>{props.value}</p>
		</>
	)
}

export default DetailsRow
