import React, { useState, useRef } from 'react';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import Logotype from '../UI/Icons/wisdomLogotype';
import AppMenu from './AppMenu/AppMenu';
import clsx from 'clsx';
//Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import MyAccountIcon from '@material-ui/icons/PersonRounded';
import LogoutIcon from '@material-ui/icons/Input';
import NotificationIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';
import AllApps from '@material-ui/icons/Apps';
//Redux
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const styles = (theme) => ({
  secondaryBar: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#7b5d77',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      zIndex: 2000,
    },
  },
  appBarRoot: {
    borderRadius: 0,
    boxShadow: '0 4px 2px -4px #777777',
    border: 'none',
  },
  buttonRoot: {
    color: 'white',
    width: '180px',
    borderRadius: 0,
    padding: '10px',
  },
  buttonLabel: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  deliveryBadge: {
    backgroundColor: '#d1c3f0',
    color: 'black',
  },
  menuItem: {
    width: '38px',
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      width: '60px',
    },
  },
  toolbar: {
    paddingLeft: 0,
    minHeight: '44px',
  },
  logoContainer: {
    textDecoration: 'none',
    color: '#c5b1c5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    flex: 1,
    '& > h2': {
      fontSize: '1.5rem',
      fontFamily: 'Philosopher, sans-serif',
      fontWeight: '200',
      paddingLeft: '5px',
      transform: 'translateY(-2px)',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.2rem',
      },
    },
    '&:hover': {
      cursor: 'default',
    },
  },
  accountMenu: {
    padding: 0,
    paddingRight: '20px',
    maxWidth: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    '& > p': {
      fontSize: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem',
        paddingLeft: '5px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '5px',
    },
  },
  accountList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '220px',
    minHeight: '100px',
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  navLink: {
    display: 'grid',
    gridTemplateColumns: '20px auto',
    gap: '20px',
    height: '100%',
    width: '100%',
    textDecoration: 'none',
    color: 'darkslategray',
    '& > p': {
      color: 'black',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popover: {
    width: '500px',
  },
  accountIcon: {
    color: 'white',
    transform: 'translateX(10px)',
  },
  allApps: {
    fontSize: 29,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      transform: 'scale(1.2)',
    },
  },
  icons: {
    fontSize: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  listItemIcon: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '30px',
    },
  },
});

const Header = (props) => {
  const avatarRef = useRef(null);
  const appMenuRef = useRef(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState(null);
  const [anchorElAppMenu, setAnchorElAppMenu] = useState(null);
  const avatarOpen = Boolean(anchorElAvatar);
  const avatarId = avatarOpen ? 'simple-avatar-popper' : undefined;
  const appMenuOpen = Boolean(anchorElAppMenu);
  const appMenuId = appMenuOpen ? 'simple-appmenu-popper' : undefined;
  /* 
  
   NOTE: temporary not used, for fetching events. Redo this later and make component functinal
    componentDidMount() {
      if (props.userDetails && props.isEventsFetched === false) {
        if (props.userDetails.prevLoginAt !== null) {
          props.onFetchEvents(props.companyId, props.userDetails.prevLoginAt);
        }
      }
    } */

  const handleLogout = () => {
    props.onLogout();
  };

  const handleEventClick = (badgeInvisible) => {
    //return if no new events exist
    if (badgeInvisible) return;

    let trackingNumbers = null;
    if (props.eventTrackingNumbers) {
      trackingNumbers = props.eventTrackingNumbers.map((value) => value.trackingNumber);
    }

    props.eventReset();
    props.history.push({
      pathname: '/statistics/trackedshipments',
      state: {
        trackingNumbers,
        title: 'Nya events',
      },
    });
  };
  const handleAvatarClick = (event) => {
    setAnchorElAvatar(anchorElAvatar ? null : event.currentTarget);
  };
  const handleAppMenuClick = (event) => {
    setAnchorElAppMenu(anchorElAppMenu ? null : event.currentTarget);
  };
  const handleAvatarClose = (event) => {
    setAnchorElAvatar(null);
  };
  const handleAppMenuClose = (event) => {
    setAnchorElAppMenu(null);
  };

  const { classes } = props;
  let userName = null;

  if (props.userDetails) {
    userName = `${props.userDetails.firstname} ${props.userDetails.lastname}`;
  }

  let authRedirect = null;
  if (!props.isAuth) {
    authRedirect = <Redirect to="/" />;
  }

  //if no new events, badge should be invisible
  const badgeInvisible = props.eventTrackingNumbers === null;

  return (
    <>
      <AppBar
        square
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={1}
        classes={{
          root: classes.appBarRoot,
        }}
      >
        {authRedirect}
        <Toolbar
          classes={{
            root: classes.toolbar,
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <NavLink to="/bookings" className={classes.logoContainer}>
                <Logotype className={classes.icons} />
                <h2>Wisdom</h2>
              </NavLink>
            </div>
            <List
              component="nav"
              style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}
            >
              <ListItem
                ref={avatarRef}
                button
                className={classes.accountMenu}
                TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                onClick={handleAvatarClick}
                aria-describedby={avatarId}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AccountIcon className={clsx([classes.accountIcon, classes.icons])} />
                </ListItemIcon>
                <p>{userName}</p>
              </ListItem>
              <Popover
                id={avatarId}
                open={avatarOpen}
                anchorEl={anchorElAvatar}
                onClose={handleAvatarClose}
                onClick={handleAvatarClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                className={classes.popover}
              >
                <List className={classes.accountList} component="nav">
                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.navLink} to="/settings">
                      <MyAccountIcon />
                      <p>{props.translations.myaccount}</p>
                    </NavLink>
                  </ListItem>
                  <ListItem onClick={() => handleLogout()} className={classes.listItem}>
                    <div className={classes.navLink}>
                      <LogoutIcon />
                      <p>{props.translations.logout}</p>
                    </div>
                  </ListItem>
                </List>
              </Popover>
              <ListItem
                className={classes.menuItem}
                onClick={() => handleEventClick(badgeInvisible)}
              >
                <Badge
                  badgeContent={
                    props.eventTrackingNumbers ? props.eventTrackingNumbers.length : null
                  }
                  invisible={badgeInvisible}
                  classes={{ badge: classes.deliveryBadge }}
                >
                  <NotificationIcon className={classes.icons} />
                </Badge>
              </ListItem>
              <ListItem
                className={classes.menuItem}
                ref={appMenuRef}
                button
                onClick={handleAppMenuClick}
                aria-describedby={appMenuId}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AllApps className={classes.allApps} />
                </ListItemIcon>
              </ListItem>
              <Popover
                id={appMenuId}
                open={appMenuOpen}
                anchorEl={anchorElAppMenu}
                onClose={handleAppMenuClose}
                onClick={handleAppMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <AppMenu categoryItems={props.categoryItems} />
              </Popover>
            </List>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.user,
    isAuth: state.auth.token !== null,
    companyId: state.user.companyId,
    isEventsFetched: state.events.isFetched,
    eventTrackingNumbers: state.events.eventTrackingNumbers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    //onFetchEvents: (companyId, prevLogin) => dispatch(actions.fetchEventTrackingNumbers(companyId, prevLogin)),
    eventReset: () => dispatch(actions.eventReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Header)));
