import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  NOTIFICATION_RECIPIENT_EMAIL,
  NOTIFICATION_RECIPIENT_TEXT_MESSAGE,
  NOTIFICATION_TYPE_DELIVERY,
  NOTIFICATION_TYPE_EXCEPTION,
  NOTIFICATION_TYPE_SHIP,
  NOTIFICATION_TYPE_ACCESS_POINT,
} from '../../../services/types';
import {
  addBookingPrintFormat,
  addMultipleNotifications,
  addNotification,
  removeNotification,
  updatePackageCount,
} from '../../../store/actions';
import Select from 'react-select';
import { SelectStylesMain } from '../../UI/SelectStyles/SelectStyles';
import { CARRIERS } from '../../../services/types';
//components
import CustomPaper from '../../UI/CustomPaper/CustomPaper';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import UpsNotifications from './UpsNotifications/UpsNotifications';
import { grey, red, green, brown, lime } from '@material-ui/core/colors';
//icons
import RemoveNotificationIcon from '@material-ui/icons/RemoveCircle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '../../UI/Icons/TextMessage';
import NotificationShipIcon from '../../UI/Icons/PackageMove';
import NotificationAccessPointIcon from '../../UI/Icons/DropOff';
import NotificationDeliveryIcon from '@material-ui/icons/DoneOutline';
import NotificationExceptionIcon from '@material-ui/icons/WarningTwoTone';
import NotificationUnknownIcon from '@material-ui/icons/Help';
import { Button, Tooltip } from '@material-ui/core';
import { getPrinterOptionsByCarrier } from '../../../services/helpers/printerOptions';
import {
  buildNotificationForOnlineBooking,
  hasEmailNotificationsAdded,
} from '../../../services/helpers/notifications';
import { isImportControl } from '../../../services/validations/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  review: {
    display: 'grid',
    width: '100%',
    maxWidth: '1000px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '10px',
  },
  address: {
    gridColumn: 'span 3',
  },
  goodsDetails: {
    gridColumn: 'span 1',
  },
  customsDetails: {
    gridColumn: 'span 2',
  },
  printerOption: {
    gridColumn: 'span 3',
  },
  printerOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    '& > p': {
      color: 'red',
      paddingTop: '3px',
    },
  },
  printerSelect: {
    width: '300px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-child(odd)': {
      backgroundColor: '#f5f5f5',
    },
  },
  detailsRow: {
    display: 'grid',
    gridTemplateColumns: '140px auto',
    padding: '3px 5px',
    '& h4': {
      fontWeight: 500,
      textAlign: 'right',
    },
    '& p': {
      paddingLeft: '10px',
    },
  },
  addedNotifications: {
    gridColumn: '1 / span 3',
  },
  notificationsAction: {
    gridColumn: '1 / span 3',
  },
  notificationRow: {
    display: 'grid',
    alignItems: 'center',
    padding: '3px 5px',
    gridTemplateColumns: '30px auto 130px',
  },
  notificationForwardingTypeIcon: {
    color: grey[800],
    padding: '0 5px',
    fontSize: 16,
  },
  notificationTypes: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
    '& > div': {
      display: 'flex',
    },
  },
  notificationPrefixPlaceholder: {
    padding: '0 5px',
    minWidth: '25px',
  },
  removeEmailButton: {
    padding: 0,
    minWidth: 0,
  },
  removeEmailIcon: {
    color: theme.status.danger,
  },
  '@media (max-width: 1000px)': {
    review: {
      gridTemplateColumns: '1fr',
      maxWidth: '500px',
    },
    address: {
      gridColumn: 'span 1',
    },
    customsDetails: {
      gridColumn: 'span 1',
    },
    printerOption: {
      gridColumn: 'span 1',
    },
    notificationsAction: {
      gridColumn: 'span 1',
    },
    addedNotifications: {
      gridColumn: 'span 1',
    },
    printerOptionContainer: {
      minHeight: '80px',
    },
  },
}));

const VerifySend = (props) => {
  const [importControl, setImportControl] = useState(false);
  const bookingData = useSelector((state) => state.booking);
  const { companyCountryCode } = useSelector((state) => state.user);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const {
    upsPrintFormat,
    dhlfreightPrintFormat,
    matkahuoltoPrintFormat,
    senderEmailNotifications,
    recipientEmailNotifications,
  } = useSelector((state) => state.bookingPresets);
  const [printerOption, setPrinterOption] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    //set initial printer option depending on carrier
    const printerOptions = getPrinterOptionsByCarrier(bookingData.carrier, props.translations);
    if (
      bookingData.printFormat &&
      printerOptions.some((option) => option.value === bookingData.printFormat)
    ) {
      const initialOption = printerOptions.find(
        (option) => option.value === bookingData.printFormat
      );

      setPrinterOption(initialOption);
    } else {
      let initialOption = printerOptions[0];
      if (bookingData.carrier === CARRIERS.UPS) {
        initialOption =
          Object.values(printerOptions).find(({ value }) => value === upsPrintFormat) ||
          printerOptions[0];
      }
      if (bookingData.carrier === CARRIERS.DHLFREIGHT) {
        initialOption =
          Object.values(printerOptions).find(({ value }) => value === dhlfreightPrintFormat) ||
          printerOptions[0];
      }
      if (bookingData.carrier === CARRIERS.MATKAHUOLTO) {
        initialOption =
          Object.values(printerOptions).find(({ value }) => value === matkahuoltoPrintFormat) ||
          printerOptions[0];
      }

      handlePrintOptionChange(initialOption);
    }

    //update package count
    dispatch(updatePackageCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingData.carrier !== CARRIERS.UPS) return;
    const importControlShipment = isImportControl(
      bookingData.sender.countryCode,
      bookingData.recipient.countryCode,
      companyCountryCode
    );

    setImportControl(importControlShipment);
    if (hasEmailNotificationsAdded(bookingData.notifications)) return; //If notifications previously been added, do not fetch presets

    const hasSenderNotificationsPreset = Object.values(senderEmailNotifications).some((v) => v);
    const hasRecipientNotificationsPreset = Object.values(recipientEmailNotifications).some(
      (v) => v
    );

    //If no email notifications have been added. Check whether preset notifications exist
    if (
      !importControlShipment &&
      (hasSenderNotificationsPreset || hasRecipientNotificationsPreset)
    ) {
      let presetNotifications = [];
      if (bookingData.recipient.email.length > 0 && hasRecipientNotificationsPreset) {
        const notification = buildNotificationForOnlineBooking(
          recipientEmailNotifications,
          bookingData.recipient.email
        );
        presetNotifications.push(notification);
      }
      if (bookingData.sender.email.length > 0 && hasSenderNotificationsPreset) {
        const notification = buildNotificationForOnlineBooking(
          senderEmailNotifications,
          bookingData.sender.email
        );
        presetNotifications.push(notification);
      }
      if (presetNotifications.length > 0) dispatch(addMultipleNotifications(presetNotifications));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrintOptionChange = (e) => {
    setPrinterOption(e);
    dispatch(addBookingPrintFormat(e.value));
  };

  const addressValues = [
    { title: `${props.translations.company}:`, key: 'company' },
    { title: `${props.translations.contact}:`, key: 'contact' },
    { title: `${props.translations.phone}:`, key: 'phone' },
    { title: `${props.translations.city}:`, key: 'city' },
    { title: `${props.translations.zip}:`, key: 'zip' },
    { title: `${props.translations.addressrow} 1:`, key: 'address1' },
    { title: `${props.translations.addressrow} 2:`, key: 'address2' },
  ];

  const options = getPrinterOptionsByCarrier(bookingData.carrier, props.translations);

  let notificationOptions = [
    { label: props.translations.onlinebooking.ship, key: NOTIFICATION_TYPE_SHIP },
    {
      label: props.translations.onlinebooking.exception,
      key: NOTIFICATION_TYPE_EXCEPTION,
    },
    { label: props.translations.onlinebooking.delivery, key: NOTIFICATION_TYPE_DELIVERY },
  ];

  if (importControl)
    notificationOptions = notificationOptions.filter(({ key }) => key !== NOTIFICATION_TYPE_SHIP);

  const handleAddNotification = (notifications) => {
    dispatch(addNotification(notifications));
  };

  const getNotificationTypeLayout = (notificationType) => {
    switch (notificationType) {
      case NOTIFICATION_TYPE_ACCESS_POINT:
        return {
          gridPlacement: 1,
          icon: <NotificationAccessPointIcon style={{ color: lime[900] }} />,
          tooltip: props.translations.onlinebooking.notifyAtDropOff,
        };
      case NOTIFICATION_TYPE_SHIP:
        return {
          gridPlacement: 2,
          icon: (
            <NotificationShipIcon style={{ transform: 'translateY(2px)', color: brown[800] }} />
          ),
          tooltip: props.translations.onlinebooking.shippedNotification,
        };
      case NOTIFICATION_TYPE_EXCEPTION:
        return {
          gridPlacement: 3,
          icon: <NotificationExceptionIcon style={{ color: red[800] }} />,
          tooltip: props.translations.onlinebooking.exceptionNotification,
        };
      case NOTIFICATION_TYPE_DELIVERY:
        return {
          gridPlacement: 4,
          icon: <NotificationDeliveryIcon style={{ color: green[800] }} />,
          tooltip: props.translations.onlinebooking.deliveryNotification,
        };
      default:
        return {
          gridPlacement: 1,
          icon: <NotificationUnknownIcon />,
          tooltip: 'Unknown notification',
        };
    }
  };

  const NotificationRecipientTypeIcon = {
    [NOTIFICATION_RECIPIENT_EMAIL]: (
      <EmailIcon className={classes.notificationForwardingTypeIcon} />
    ),
    [NOTIFICATION_RECIPIENT_TEXT_MESSAGE]: (
      <PhoneIcon className={classes.notificationForwardingTypeIcon} />
    ),
  };

  const handleRemoveNotification = ({ recipient }) => {
    dispatch(removeNotification({ recipient }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.review}>
        <div className={classes.address}>
          <CustomPaper title={props.translations.onlinebooking.shipfrom}>
            <div className={classes.detailsContainer}>
              {addressValues.map((obj) => (
                <div key={`sender${obj.key}`} className={classes.detailsRow}>
                  <h4>{obj.title}</h4>
                  <p>{bookingData.sender[obj.key]}</p>
                </div>
              ))}
            </div>
          </CustomPaper>
        </div>
        <div className={classes.address}>
          <CustomPaper title={props.translations.onlinebooking.shipto}>
            <div className={classes.detailsContainer}>
              {addressValues.map((obj) => (
                <div key={`recipient${obj.key}`} className={classes.detailsRow}>
                  <h4>{obj.title}</h4>
                  <p>{bookingData.recipient[obj.key]}</p>
                </div>
              ))}
            </div>
          </CustomPaper>
        </div>
        <div className={classes.goodsDetails}>
          <CustomPaper title={props.translations.onlinebooking.goodsdetails}>
            <div className={classes.detailsContainer}>
              <div className={classes.detailsRow}>
                <h4>{props.translations.onlinebooking.packagecount}:</h4>
                <p>{bookingData.packageCount && bookingData.packageCount}</p>
              </div>
              <div className={classes.detailsRow}>
                <h4>{props.translations.onlinebooking.chargeableweight}:</h4>
                <p>{bookingData.billableWeight}kg</p>
              </div>
              <div className={classes.detailsRow}>&nbsp;</div>
            </div>
          </CustomPaper>
        </div>
        <div className={classes.customsDetails}>
          <CustomPaper title={props.translations.onlinebooking.shipmentdetails}>
            <div className={classes.detailsContainer}>
              <div className={classes.detailsRow}>
                <h4>{props.translations.onlinebooking.reference}:</h4>
                <p>{bookingData.reference}</p>
              </div>
              <div className={classes.detailsRow}>
                <h4>{props.translations.onlinebooking.commoditydescription}:</h4>
                <p>{bookingData.description}</p>
              </div>
              <div className={classes.detailsRow}>
                <h4>{props.translations.onlinebooking.customsvalue}:</h4>
                <p>
                  {bookingData.customsValue} {bookingData.currency}
                </p>
              </div>
            </div>
          </CustomPaper>
        </div>
        {printerOption && (
          <div className={classes.printerOption}>
            <CustomPaper title={props.translations.onlinebooking.printermethod}>
              <div className={classes.printerOptionContainer}>
                <Select
                  value={printerOption}
                  onChange={handlePrintOptionChange}
                  options={options}
                  className={classes.printerSelect}
                  styles={SelectStylesMain}
                  menuPlacement="auto"
                />
                {printerOption.value.substring(0, 3) === 'ZPL' && (
                  <p>
                    {props.translations.onlinebooking.tothermalprinter}{' '}
                    <span style={{ fontWeight: '600', color: 'black' }}>Zebra BrowserPrinter</span>{' '}
                    {props.translations.onlinebooking.beinstalled}{' '}
                  </p>
                )}
              </div>
            </CustomPaper>
          </div>
        )}
        {bookingData.notifications.length > 0 && (
          <div className={classes.addedNotifications}>
            <CustomPaper title={props.translations.onlinebooking.shipmentNotifications}>
              <div className={classes.detailsContainer}>
                {bookingData.notifications.map(
                  ({ recipients, notifications, notificationType }, idx) => {
                    return recipients.map((notificationRecipient) => (
                      <div key={notificationRecipient} className={classes.notificationRow}>
                        <div className={classes.notificationPrefixPlaceholder}>
                          {/* Only email is removable */}
                          {notificationType === NOTIFICATION_RECIPIENT_EMAIL && (
                            <Tooltip
                              title={props.translations.onlinebooking.removeFromNotifications}
                            >
                              <Button
                                className={classes.removeEmailButton}
                                onClick={() =>
                                  handleRemoveNotification({ recipient: notificationRecipient })
                                }
                              >
                                <RemoveNotificationIcon className={classes.removeEmailIcon} />
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {NotificationRecipientTypeIcon[notificationType]}
                          <p>{notificationRecipient}</p>
                        </div>

                        <div className={classes.notificationTypes}>
                          {notifications.map((notification) => {
                            const { gridPlacement, icon, tooltip } =
                              getNotificationTypeLayout(notification);
                            return (
                              <div
                                key={`${notificationRecipient}${gridPlacement}`}
                                style={{ gridColumn: gridPlacement, gridRow: idx + 1 }}
                              >
                                <Tooltip title={tooltip}>{icon}</Tooltip>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ));
                  }
                )}
              </div>
            </CustomPaper>
          </div>
        )}
        <div className={classes.notificationsAction}>
          {bookingData.carrier === CARRIERS.UPS && (
            <UpsNotifications
              lang={lang}
              handleAddNotification={handleAddNotification}
              notificationOptions={notificationOptions}
              senderEmail={bookingData.sender.email}
              recipientEmail={bookingData.recipient.email}
              translations={props.translations}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifySend;
