import {
  senderAddressSchema,
  recipientAddressSchema,
  shipmentDetailsSchema,
  packagesSchema,
  bookingSchema,
  serviceValidationSchema,
  invoiceFormSchema,
} from '../schemas';

import translations from '../../translations/translations';

import { shipmentOutsideEU, getFieldsFromData } from '../helpers';
import { CARRIERS } from '../../types';

export const VerifyOnlineBookingSteps = async (step, data, lang = 'en') => {
  const defaults = {
    valid: true,
    errors: null,
  };
  //set option to catch ALL errors
  const options = {
    //strict:false,
    abortEarly: false,
    //stripUnknown:false,
    //recursive:true,
  };

  const steps = ['sender', 'recipient', 'shipmentDetails', 'invoicePickup'];

  switch (step) {
    case 0:
      const validateSender = {
        ...data.sender,
        date: data.date,
      };
      const senderValidation = await senderAddressSchema(lang)
        .validate(validateSender, options)
        .then((valid) => {
          //TODO: Perhaps transfer the verified data here, then store these in redux
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
            step: steps[step],
          };
        });
      //validate sender
      return senderValidation;
    case 1:
      //validate recipient - needed?
      if (data.recipient === null) {
        return {
          valid: false,
          errors: ['No recipient found'],
          step: steps[step],
          variant: 'error',
        };
      }

      //For correctly validating recipient, movement direction must be included
      //as different values depend on domestic services
      const validateRecipient = {
        ...data.recipient,
        movementDirection: data.movementDirection,
      };

      const recipientValidation = await recipientAddressSchema(lang)
        .validate(validateRecipient, options)
        .then((valid) => {
          //TODO: Perhaps transfer the verified data here, then store these in redux
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
            step: steps[step],
          };
        });
      //validate sender
      return recipientValidation;
    case 2:
      /*  Validate service and packages
          Although already validated when searching services, the extra validation is made to ensure package values are not changed after service has been selected
      */

      const shipmentDetails = getFieldsFromData(data, [
        'reference',
        'description',
        'customsValue',
        'documentsOnly',
        'movementDirection',
      ]);
      const serviceDetails = getFieldsFromData(data, [
        'serviceCode',
        'carrier',
        'alternateDeliveryAddress',
        'deliveryRemark',
      ]);

      const shipmentDetailsValidation = shipmentDetailsSchema(lang).validate(
        shipmentDetails,
        options
      );
      const packagesValidation = packagesSchema(lang).validate(data.packages, options);
      const serviceValidation = serviceValidationSchema(lang).validate(serviceDetails, options);

      const packageServiceValidation = await Promise.all([
        packagesValidation,
        serviceValidation,
        shipmentDetailsValidation,
      ])
        .then((_) => {
          return {
            valid: true,
            errors: null,
          };
        })
        .catch((err) => {
          return {
            valid: false,
            errors: [err.errors[0]],
            variant: 'error',
            step: steps[step],
          };
        });
      return packageServiceValidation;
    case 3:
      const required =
        shipmentOutsideEU(data.sender.countryCode, data.recipient.countryCode) &&
        data.carrier !== CARRIERS.DHLFREIGHT;
      if (required && data.invoiceType === 'file') {
        if (!('invoice' in data.documents) || data.documents['invoice'].length < 1) {
          return {
            valid: false,
            errors: [translations[lang].onlinebooking.needci],
            variant: 'error',
            step: steps[step],
          };
        }
      }

      //For HRX, incoterm is required
      if (data.carrier === CARRIERS.HRX) {
        if (!('termsOfShipment' in data.invoiceForm)) {
          return {
            valid: false,
            errors: [translations[lang].onlinebooking.anIncotermIsRequired],
            variant: 'error',
            step: steps[step],
          };
        }
      }

      if (required && data.invoiceType === 'form') {
        const invoiceFormValidation = invoiceFormSchema(lang)
          .validate(data.invoiceForm, options)
          .then((valid) => {
            //TODO: Perhaps transfer the verified data here, then store these in redux
            return {
              valid: true,
              errors: null,
            };
          })
          .catch(function (err) {
            return {
              valid: false,
              errors: [translations[lang].onlinebooking.needci],
              variant: 'error',
              step: steps[step],
            };
          });

        return invoiceFormValidation;
      }

      return {
        valid: true,
        errors: null,
      };

    case 4:
      //Validate all values need for booking
      const {
        sender,
        recipient: recipientBase,
        description,
        reference,
        date,
        customsValue,
        currency,
        packages,
        documentsOnly,
        movementDirection,
      } = data;

      const request = {
        sender,
        recipient: { ...recipientBase, movementDirection },
        description,
        reference,
        date,
        customsValue,
        currency,
        packages,
        documentsOnly,
        movementDirection,
      };
      const bookingValidation = await bookingSchema(lang)
        .validate({ ...request }, options)
        .then((valid) => {
          //TODO: Perhaps transfer the verified data here, then store these in redux store
          return {
            valid: true,
            errors: null,
          };
        })
        .catch(function (err) {
          return {
            valid: false,
            errors: err.errors,
            variant: 'error',
            step: steps[step],
          };
        });
      //validate sender
      return bookingValidation;
    default:
      return defaults;
  }
};

export const onlineBookingServiceRequestValidation = async ({ lang, data }) => {
  const options = {
    abortEarly: false,
  };

  const shipmentDetails = getFieldsFromData(data, [
    'reference',
    'description',
    'customsValue',
    'documentsOnly',
    'movementDirection',
  ]);

  const shipmentDetailsValidation = shipmentDetailsSchema(lang).validate(shipmentDetails, options);
  const packagesValidation = packagesSchema(lang).validate(data.packages, options);

  const serviceRequestValidation = await Promise.all([
    packagesValidation,
    shipmentDetailsValidation,
  ])
    .then((_) => {
      return {
        valid: true,
        errors: null,
      };
    })
    .catch((err) => {
      return {
        valid: false,
        errors: [err.errors[0]],
        variant: 'error',
      };
    });
  return serviceRequestValidation;
};
