import * as actionTypes from '../actions/actionTypes';

const initialState = {
  senderTemplate: null,
  fetchRulesError: null,
  fetchRulesLoading: false,
  batchBookingBaseRules: null,
  batchBookingCountryRules: [],
  batchBookingData: [],
  batchBookingProcessedShipments: [],
  batchBookingProcessedErrors: [],
  processBatchBookingLoading: false,
  processBatchBookingError: null,
  processBatchFileLoading: false,
  processBatchFileErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UPDATE SENDER TEMPLATE FOR BOOKING
    case actionTypes.UPDATE_SENDER_TEMPLATE_FOR_BOOKING:
      return {
        ...state,
        senderTemplate: action.senderTemplate,
      };
    //FETCH RULES
    case actionTypes.FETCH_BATCH_BOOKING_RULES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_BATCH_BOOKING_RULES_SUCCESS:
      return {
        ...state,
        batchBookingBaseRules: action.baseRules,
        batchBookingCountryRules: action.countryRules,
        loading: false,
      };
    case actionTypes.FETCH_BATCH_BOOKING_RULES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };
    //UPDATE RULES
    case actionTypes.UPDATE_BATCH_BOOKING_RULES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_BATCH_BOOKING_RULES_SUCCESS:
      return {
        ...state,
        // batchBookingBaseRules: action.payload,
        loading: false,
      };
    case actionTypes.UPDATE_BATCH_BOOKING_RULES_FAILED:
      return {
        ...state,
        fetchError: action.error,
        loading: false,
      };

    // REMOVE COUNTRY RULE
    case actionTypes.REMOVE_BATCH_BOOKING_COUNTRY_RULE_SUCCESS:
      const updatedCountryRules = state.batchBookingCountryRules.filter(
        (rule) => rule.id !== action.removedId
      );

      return {
        ...state,
        batchBookingCountryRules: updatedCountryRules,
      };
    case actionTypes.REMOVE_BATCH_BOOKING_COUNTRY_RULE_FAILED:
      return state;

    // PROCESS BATCH FILE
    case actionTypes.PROCESS_BATCH_FILE_START:
      return {
        ...state,
        processBatchFileLoading: true,
        batchBookingData: [],
        batchBookingProcessedShipments: [],
        batchBookingProcessedErrors: [],
        processBatchFileErrors: [],
      };
    case actionTypes.PROCESS_BATCH_FILE_SUCCESS:
      return {
        ...state,
        processBatchFileLoading: false,
        batchBookingData: action.payload,
      };
    case actionTypes.PROCESS_BATCH_FILE_FAILED:
      return {
        ...state,
        processBatchFileLoading: false,
        batchBookingProcessedShipments: [],
        processBatchFileErrors: action.errors,
      };

    //PROCESS BATCH BOOKING
    case actionTypes.PROCESS_BATCH_BOOKING_START:
      return {
        ...state,
        processBatchBookingLoading: true,
      };
    case actionTypes.PROCESS_BATCH_BOOKING_SUCCESS:
      const { errors, success } = action;

      const updatedBatchBookingProcessedData = [
        ...state.batchBookingProcessedShipments,
        ...success,
      ];

      const updatedBatchBookingData = state.batchBookingData.map((item) => {
        const error = errors.find((error) => error.id === item.id);
        const successItem = success.find((successItem) => successItem.id === item.id);
        if (error) {
          item['errors'] = error.errors;
          item['bookedStatus'] = 'error';
        }
        if (successItem) {
          // Update all the fields in case an item is updated
          item = { ...item, ...successItem.shipment };
          item['bookedStatus'] = 'success';
        }
        return item;
      });

      return {
        ...state,
        processBatchBookingLoading: false,
        batchBookingData: updatedBatchBookingData,
        batchBookingProcessedShipments: updatedBatchBookingProcessedData,
        batchBookingProcessedErrors: errors,
      };
    case actionTypes.PROCESS_BATCH_BOOKING_FAILED:
      return {
        ...state,
        processBatchBookingLoading: false,
        processBatchBookingError: action.error,
      };

    case actionTypes.UPDATE_BATCH_BOOKING_DATA_WHEN_PRINTED:
      const updatedBatchBookingDataWhenPrinted = state.batchBookingData.map((item) => {
        if (action.ids.includes(item.id)) {
          item['printStatus'] = true;
        }
        return item;
      });

      return {
        ...state,
        batchBookingData: updatedBatchBookingDataWhenPrinted,
      };

    case actionTypes.UPDATE_INVOICE_DATA_FOR_BATCH_BOOKING:
      const { shipmentIds, invoiceStatus } = action.invoiceData;
      const updatedBatchBookingDataWhenInvoiceCreated = state.batchBookingData.map((item) => {
        if (shipmentIds.includes(item.id)) {
          item['invoiceStatus'] = invoiceStatus;
        }
        return item;
      });

      return {
        ...state,
        batchBookingData: updatedBatchBookingDataWhenInvoiceCreated,
      };

    case actionTypes.UPDATE_BATCH_BOOKING_DATA_SHIPMENT:
      const shipmentIndex = state.batchBookingData.findIndex(
        (shipment) => shipment.id === action.shipment.id
      );
      const updatedBatchBookingDataShipment = [...state.batchBookingData];
      updatedBatchBookingDataShipment[shipmentIndex] = action.shipment;
      // const updatedBatchBookingDataShipment = state.batchBookingData.map((item) => {
      //   if (item.id === action.shipment.id) {
      //     item = { ...item, ...action.shipment };
      //   }
      //   return item;
      // });
      return {
        ...state,
        batchBookingData: updatedBatchBookingDataShipment,
      };

    // RESET BATCH BOOKING STATE
    case actionTypes.CLEAR_BATCH_BOOKING_STATE:
      return {
        ...state,
        batchBookingData: [],
        batchBookingProcessedShipments: [],
        batchBookingProcessedErrors: [],
      };
    default:
      return state;
  }
};

export default reducer;
