import React, { useState, useEffect } from 'react';
import { fetchCountries } from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { stateRegionCountries, stateRegionPaths } from '../../../services/helpers/common';
import SelectList from '../../SelectionLists/IntegrationReactSelect/IntegrationReactSelect';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import CompanyAutocomplete from '../../Bookings/Templates/CompanyAutocomplete';

const useStyles = makeStyles({
  addressContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridColumnGap: '15px',
    width: '100%',
    placeItems: 'center',
  },
  textField: {
    gridColumn: 'span 6',
    width: '100%',
    marginBottom: '1px',
    marginTop: '5px',
  },
});

const initialAddressData = {
  company: '',
  contact: '',
  phone: '',
  email: '',
  city: '',
  zip: '',
  address1: '',
  address2: '',
  countryName: '',
  countryCode: '',
  stateRegion: '',
};

const AddressInputs = (props) => {
  const [addressData, setAddressData] = useState(initialAddressData);

  const [errors, setError] = useState({
    company: false,
    contact: false,
    phone: false,
    email: false,
    city: false,
    zip: false,
    address1: false,
    countryName: false,
    countryCode: false,
    stateRegion: false,
  });

  const [stateRegionList, setStateRegionList] = useState([]);
  const [hasStateOrRegion, setHasStateOrRegion] = useState(false);
  const countries = useSelector((state) => state.countries.countriesData);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries === null) {
      dispatch(fetchCountries(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (props.addressData) {
      let mountedAddressData = { ...addressData, ...props.addressData };

      // If address data includes country code which requires state/province/region, fetch states/regions
      if (stateRegionCountries.includes(props.addressData.countryCode)) {
        if (
          props.addressData.countryCode !== addressData.countryCode ||
          stateRegionList.length === 0
        ) {
          fetchStatesRegions(props.addressData.countryCode);
          !hasStateOrRegion && setHasStateOrRegion(true);
        }
      } else {
        hasStateOrRegion && setHasStateOrRegion(false);
        stateRegionList.length > 0 && setStateRegionList([]);
      }

      if (Array.isArray(countries) && countries.length > 0) {
        // If address data only includes country code, set country name
        if (props.addressData.countryCode && !props.addressData.countryName) {
          const country = countries.find(
            (country) => country.countryCode === props.addressData.countryCode
          );
          mountedAddressData = { ...mountedAddressData, countryName: country.en };
          // setAddressData((prev) => ({ ...prev, countryName: country[lang] }));
        }
      }

      setAddressData(mountedAddressData);

      props.textBlur(mountedAddressData);
    } else {
      props.textBlur(addressData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (e) => {
    if (stateRegionCountries.includes(e.value.code)) {
      setHasStateOrRegion(true);
      fetchStatesRegions(e.value.code);
    } else {
      hasStateOrRegion && setHasStateOrRegion(false);
      stateRegionList.length > 0 && setStateRegionList([]);
    }

    const updatedAddressFields = {
      ...addressData,
      countryName: e.value.name,
      countryCode: e.value.code,
      stateRegion: '',
    };

    setAddressData(updatedAddressFields);
    props.textBlur(updatedAddressFields);
  };

  const fetchStatesRegions = (countryCode) => {
    const path = stateRegionPaths[countryCode];

    axios
      .get(`/api/countries/states-or-regions/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        setStateRegionList(resp.data.statesOrRegions);
      })
      .catch((e) => {
        setStateRegionList([]);
      });
  };

  const handleStateChange = (e) => {
    setAddressData({ ...addressData, stateRegion: e.value.code });
    props.textBlur({ ...addressData, stateRegion: e.value.code });
  };

  const handleTextChange = (value) => (e) => {
    setAddressData({ ...addressData, [value]: e.target.value });
  };

  const handleTextBlur = (e) => {
    //set error, as of now only if field is empty
    e.target.value === ''
      ? setError({ ...errors, [e.target.name]: true })
      : errors[e.target.name] && setError({ ...errors, [e.target.name]: false });
    props.textBlur(addressData);
  };
  const handleCompanyBlur = (e) => {
    //set error, as of now only if field is empty
    e.target.value === ''
      ? setError({ ...errors, [e.target.name]: true })
      : errors[e.target.name] && setError({ ...errors, [e.target.name]: false });
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
    props.textBlur({ ...addressData, [e.target.name]: e.target.value });
  };

  const classes = useStyles();

  const handleTemplateSelected = (company) => {
    const updatedAddress = {
      company: company.company || '',
      phone: company.contacts.length > 0 ? company.contacts[0].phone : company.phone || '',
      email: company.contacts.length > 0 ? company.contacts[0].email : company.email || '',
      contact: company.contacts.length > 0 ? company.contacts[0].name : '',
      city: company.city || '',
      zip: company.zip || '',
      address1: company.address1 || '',
      address2: company.address2 || '',
      countryName: company.countryName || '',
      countryCode: company.countryCode || '',
      stateRegion: company.stateRegion || '',
    };

    setAddressData(updatedAddress);
    props.textBlur(updatedAddress);
  };

  const fieldProps = [
    // { name: 'company', maxLength: 25, label: props.translations.company, required: true },
    { name: 'contact', maxLength: 35, label: props.translations.contact, required: true },
    {
      name: 'address1',
      maxLength: 35,
      label: props.translations.addressrow + ' 1',
      required: true,
    },
    {
      name: 'address2',
      maxLength: 35,
      label: props.translations.addressrow + ' 2',
      required: false,
    },
    { name: 'city', maxLength: 30, label: props.translations.city, required: true },
    { name: 'zip', maxLength: 9, label: props.translations.zip, required: true },
    { name: 'phone', maxLength: 25, label: props.translations.phone, required: true },
    { name: 'email', maxLength: 50, label: props.translations.email, required: true },
  ];

  return (
    <div
      style={{ gridTemplateRows: stateRegionList.length > 0 && '100px' }}
      className={classes.addressContainer}
    >
      {countries ? (
        <div
          style={{ width: '100%', gridColumn: !hasStateOrRegion ? '1 / span 12' : '1 / span 6' }}
        >
          {' '}
          <SelectList
            id={props.type}
            lang={lang}
            translations={props.translations.selectList}
            change={handleCountryChange}
            value={addressData.countryName}
            height="48"
            translateY="-4"
            countries={countries}
            maxMenuHeight={240}
          />
        </div>
      ) : null}

      {stateRegionList.length > 0 ? (
        <div style={{ width: '100%', gridColumn: '7 / span 6' }}>
          <SelectList
            lang={lang}
            translations={props.translations.selectList}
            change={handleStateChange}
            value={addressData.stateRegion}
            height="48"
            translateY="-4"
            statesOrRegions={stateRegionList}
            maxMenuHeight={240}
          />
        </div>
      ) : null}

      {props.templates ? (
        <div className={classes.textField}>
          <CompanyAutocomplete
            index={props.type}
            styles={classes.companyAutoSelect}
            label={props.translations.company}
            templateSelected={handleTemplateSelected}
            defaultValue={addressData.company}
            key={addressData.company}
            options={props.templates}
            handleCompanyBlur={handleCompanyBlur}
          />
        </div>
      ) : (
        <TextField
          id={`request-booking-company-${props.type}`}
          inputProps={{ maxLength: 35 }}
          label={props.translations.company}
          className={classes.textField}
          name="company"
          value={addressData.company}
          onChange={handleTextChange('company')}
          onBlur={handleTextBlur}
          margin="none"
          error={errors.company}
          required
        />
      )}
      {fieldProps.map((field) => (
        <TextField
          key={field.name}
          id={`request-booking-${field.name}-${props.type}`}
          inputProps={{ maxLength: field.maxLength }}
          label={field.label}
          className={classes.textField}
          name={field.name}
          value={addressData[field.name]}
          onChange={handleTextChange(field.name)}
          onBlur={handleTextBlur}
          margin="none"
          error={errors[field.name]}
          required={field.required}
        />
      ))}
    </div>
  );
};

export default AddressInputs;
