import React from 'react';
import ReactEcharts from 'echarts-for-react';
import Paper from '@material-ui/core/Paper';
import translations from '../../../services/translations/translations';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import moment from 'moment';
import { getTheme } from '../../../services/statistics/chartThemes';
import { groupBy } from '../../../helpers';

const TotalCostPerMonth = ({ data: shipmentData, lang, theme, locale }) => {
  moment.locale(locale);
  echarts.registerTheme(theme, getTheme[theme]);

  let currency = '';
  const data = Array.from(groupBy(shipmentData, (shpmt) => moment(shpmt.createdAt).month()))
    .sort((a, b) => a[0] - b[0])
    .map((month) => {
      let cost = month[1].reduce((acc, shpmt) => {
        if (typeof shpmt.totalCost === 'number') acc += shpmt.totalCost;
        if (currency.length === 0 && shpmt.costCurrency) currency = shpmt.costCurrency;
        return Math.floor(acc);
      }, 0);

      return { monthNbr: month[0], cost };
    });

  const options = {
    title: {
      text: `${translations[lang].statistics.totalCostPerMonth} (${currency})`,
    },
    tooltip: {
      formatter: (params) => `${params.value.toLocaleString(locale)}`,
    },
    toolbox: {
      show: true,
      orient: 'horizontal',
      right: 30,
      top: 10,
      feature: {
        dataZoom: {
          title: {
            zoom: translations[lang].statistics.zoomRegion,
            back: translations[lang].statistics.zoomOut,
          },
          yAxisIndex: 'none',
        },
        magicType: {
          title: {
            line: translations[lang].statistics.switchToLineChart,
            bar: translations[lang].statistics.switchToBarChart,
          },
          type: ['line', 'bar'],
        },
        saveAsImage: { title: translations[lang].statistics.saveAsImage },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      name: translations[lang].statistics.month,
      data: data.map((month) => moment(month.monthNbr + 1, 'MM').format('MMM')),
    },
    yAxis: {
      axisLabel: {
        formatter: (value) => `${value.toLocaleString(locale)} ${currency}`,
        fontWeight: 400,
        fontSize: 14,
      },
    },
    series: [
      {
        name: 'Cost',
        type: 'line',
        showBackground: true,
        areaStyle: {},
        label: {
          show: true,
          formatter: (params) => `${params.value.toLocaleString(locale)}`,
          position: 'top',
          fontStyle: 400,
          fontSize: 14,
          lineHeight: 16,
        },
        data: data.map((month) => {
          return { value: month.cost };
        }),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEcharts
        echarts={echarts}
        option={options}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default TotalCostPerMonth;
