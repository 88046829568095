import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContactForm from './ContactForm/ContactForm';
import { CircleSpinnerLarge } from '../../UI/Spinners/Circle';
//material ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '../../UI/CustomToolbar/CustomToolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import TableChartIcon from '@material-ui/icons/TableChart';
//temp
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../../store/actions';

let counter = -1;

function createData(name, phone, email) {
  counter++;
  return { id: counter, name, phone, email };
}

const toolbarStyles = (theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, title } = props;

  let toolbarTitle =
    numSelected >= 0
      ? `${props.translations.templates.contact} ${
          props.translations.templates.selected.substr(0, 1) +
          props.translations.templates.selected.substr(1)
        }`
      : title;

  return (
    <AppBar position='static' elevation={0}>
      <Toolbar title={toolbarTitle}>
        <div className={classes.actions}>
          {numSelected !== null ? (
            <Tooltip title={props.translations.shipmenthistory.textLabels.delete}>
              <IconButton onClick={props.handleDelete} aria-label='Delete'>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div>
              <Tooltip title={props.translations.company}>
                <IconButton /* onClick={props.handleEditCompany} */ aria-label='Company'>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={props.translations.templates.senderlist}>
                <IconButton onClick={props.handleChangeToTable} aria-label='Company Table'>
                  <TableChartIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class EnhancedTableHead extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'></TableCell>
          {this.props.rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.align} //{row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
              >
                {row.label}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    minHeight: '108px',
    overflowX: 'auto',
  },
  noContacts: {
    backgroundColor: '#f1f1f1',
    textAlign: 'center',
  },
});

class EditSenderCompanyContacts extends Component {
  constructor(props) {
    super();
    this.contactData = props.companyData.contacts.map((contact) =>
      createData(contact.name, contact.phone, contact.email)
    );
    this.state = {
      selected: null,
      data: this.contactData,
      updating: false,
    };
  }

  componentDidUpdate(prevProps) {
    const companyIndex = this.props.companyIndex;
    const companyContacts = this.props.senderTemplates.templates[companyIndex].contacts;
    //depricated: update if user has been added and therefore global store has more users than current state

    //update if contact has been added or deleted
    if (this.state.data.length !== companyContacts.length) {
      counter = -1;
      const newContactData = companyContacts.map((contact) =>
        createData(contact.name, contact.phone, contact.email)
      );

      this.setState({ ...this.state, data: newContactData });
    }

    //update details if user has updated and reducer is finished fetching
    if (prevProps.senderTemplates.loading) {
      const updatedContacts = companyContacts.slice();
      counter = -1;
      const updatedContactData = updatedContacts.map((contact) =>
        createData(contact.name, contact.phone, contact.email)
      );

      this.setState({ ...this.state, data: updatedContactData, updating: false, selected: [] });
    }
  }

  handleClick = (event, index) => {
    let newSelected = index;

    if (index === this.state.selected) {
      this.setState({ ...this.state, selected: null });
    } else {
      this.setState({ ...this.state, selected: newSelected });
    }
  };

  handleDelete = () => {
    if (Number.isInteger(this.state.selected)) {
      this.props.handleRemoveContact(this.state.selected, this.props.companyData.id);
    }
  };

  handleUpdateSenderCompanyContact = (contactData) => {
    const id = this.state.selected;
    this.props.handleUpdateContact(contactData, id, this.props.companyData.id);
    this.setState({ ...this.state, updating: true });
  };
  handleAddSenderCompanyContact = (contactData) => {
    const companyId = this.props.companyData.id;
    const userId = this.props.userId;
    this.props.handleAddContact(contactData, companyId, userId);
  };

  handleClickAway = () => {
    this.setState({ ...this.state, selected: null });
  };

  render() {
    const { classes } = this.props;
    const { data, selected } = this.state;
    const loading = this.props.senderTemplates.loading;

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          translations={this.props.translations}
          title={this.props.title}
          numSelected={selected}
          handleEditContacts={this.handleEditContacts}
          handleDelete={this.handleDelete}
          handleChangeToTable={this.props.handleChangeToTable}
        />
        {loading ? (
          <CircleSpinnerLarge />
        ) : (
          <>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby='tableTitle'>
                <EnhancedTableHead rows={this.props.rows} rowCount={data.length} />
                {data.length > 0 ? (
                  <TableBody>
                    {data.map((n, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, index)}
                          role='checkbox'
                          tabIndex={-1}
                          key={index}
                          selected={this.state.selected === index}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox checked={this.state.selected === index} />
                          </TableCell>
                          {/*    <TableCell padding="none" align="left">{n.id}</TableCell> */}
                          <TableCell padding='none' align='left'>
                            {n.name}
                          </TableCell>
                          <TableCell padding='none' align='left'>
                            {n.phone}
                          </TableCell>
                          <TableCell padding='none' align='left'>
                            {n.email}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <tbody>
                    <tr>
                      <td className={classes.noContacts} colSpan='5'>
                        {this.props.translations.templates.nocontactsfound}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            <ContactForm
              translations={this.props.translations}
              handleChangeToTable={this.props.handleChangeToTable}
              handleAddCompanyContact={this.handleAddSenderCompanyContact}
              handleUpdateCompanyContact={this.handleUpdateSenderCompanyContact}
              contactSelected={this.state.data[selected] || undefined}
            />
          </>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    senderTemplates: state.senderTemplates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleRemoveContact: (contactIndex, companyId) =>
      dispatch(actions.removeSenderCompanyContact(contactIndex, companyId)),
    handleAddContact: (contactData, companyId, userId) =>
      dispatch(actions.addSenderCompanyContact(contactData, companyId, userId)),
    handleUpdateContact: (contactData, contactIndex, companyId) =>
      dispatch(actions.updateSenderCompanyContact(contactData, contactIndex, companyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditSenderCompanyContacts));
