import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import translations from '../../../services/translations/translations';
/* import { useDispatch } from 'react-redux'; */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
import { CircleSpinnerSmall } from '../../UI/Spinners/Circle';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import { CARRIER_NAMES, UPS } from '../../../services/types';

const useStyles = makeStyles({
  pendingPickups: {
    width: '100%',
  },
  pendingPickupsContainer: {
    minHeight: '30px',
  },
  pendingTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  pendingRow: {
    display: 'grid',
    padding: '2px 5px',
    columnGap: '5px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    '&:nth-child(odd)': {
      backgroundColor: '#f5f5f5',
    },
    '& h2': {
      fontSize: '0.8rem',
      fontWeight: '500',
    },
    '& p': {
      fontSize: '0.8rem',
      fontWeight: '400',
    },
  },
});

const initialPendingPickupsState = {
  pendings: [],
  requested: [],
  loading: false,
  error: null,
};

const PendingCollections = () => {
  const { token } = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);
  const [pendingPickupsState, setPendingPickupsState] = useState(initialPendingPickupsState);

  useEffect(() => {
    retrievePendingCollectionsUPS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrievePendingCollectionsUPS = () => {
    setPendingPickupsState({ ...pendingPickupsState, loading: true, error: null });
    axios
      .get(`/api/collection/status/${UPS}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        if (resp.data.success) {
          setPendingPickupsState({
            ...pendingPickupsState,
            pendings: [{ carrier: resp.data.carrier, collections: resp.data.pendingCollections }],
            requested: resp.data.requestedPickups,
            loading: false,
            error: null,
          });
        } else {
          setPendingPickupsState(initialPendingPickupsState);
        }
      })
      .catch((e) => setPendingPickupsState(initialPendingPickupsState));
  };

  const buildPickupPendingList = (pu) => {
    const pendingTable = pu.reduce((acc, curr) => {
      //UPS specific values
      const collectionData = curr.collections.map((collection) => (
        <div key={collection.pickupNumber} className={classes.pendingRow}>
          <div>
            <h2>{translations[lang].managecollection.pendings.pickupcarrier}</h2>
            <p>{CARRIER_NAMES[curr.carrier]}</p>
          </div>
          <div>
            <h2>{translations[lang].managecollection.pendings.pickupnumber}</h2>
            <p>{collection.pickupNumber}</p>
          </div>
          <div>
            <h2>{translations[lang].managecollection.pendings.pickupreference}</h2>
            <p>{collection.reference}</p>
          </div>
          <div>
            <h2>{translations[lang].managecollection.pendings.status}</h2>
            <p>{collection.status || 'N/A'}</p>
          </div>
          <div>
            <h2>{translations[lang].managecollection.pendings.servicedate}</h2>
            <p>{moment(collection.serviceDate).format('LL')}</p>
          </div>
          <div>
            <h2>{translations[lang].shipmenthistory.columnLabels.created}</h2>
            <p>{moment(collection.createdAt).format('LL')}</p>
          </div>
        </div>
      ));

      acc.push(collectionData);
      return acc;
    }, []);

    return <div className={classes.pendingTable}>{pendingTable}</div>;
  };

  const classes = useStyles();

  const existPendings =
    pendingPickupsState.pendings.length > 0 &&
    pendingPickupsState.pendings.some(
      (pending) => Array.isArray(pending.collections) && pending.collections.length > 0
    );

  let table;
  if (pendingPickupsState.loading) {
    table = (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircleSpinnerSmall />
      </div>
    );
  } else {
    existPendings
      ? (table = buildPickupPendingList(pendingPickupsState.pendings))
      : (table = (
          <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
            {translations[lang].managecollection.pendings.nopendings}
          </p>
        ));
  }

  return (
    <section>
      <Paper className={classes.pendingPickups}>
        <AppBar elevation={0} position="static">
          <CustomToolbar title={translations[lang].managecollection.pendings.title}></CustomToolbar>
        </AppBar>
        <div className={classes.pendingPickupsContainer}>{table}</div>
      </Paper>
    </section>
  );
};

export default PendingCollections;
