import React, { useState, useEffect, useRef } from 'react';
import {
  fetchRecipientCompaniesTemplates,
  addBookingRecipient,
  bookingValidationUpdate,
  addBookingResidentialAddress,
  addCompanyTemplate,
  updateRecipientCompanyTemplateFromBooking,
} from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
//components
import RecipientInputs from './RecipientInputs/RecipientInputs';
import FavouriteCompanies from '../Templates/FavouriteCompanies';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReloadIcon from '@material-ui/icons/Autorenew';
import { flowByCountry } from '../../../services/validations/helpers';
import { Tooltip } from '@material-ui/core';
import {
  ADD_BOOKING_STATUS,
  REMOVE_BOOKING_STATUS,
  SET_MOVEMENT_DIRECTION,
} from '../../../store/actions/actionTypes';
import { createBookingStatusFromTemplateComment } from '../../../services/helpers/bookingStatus';
import AddCompanyCommentModal from '../../Template/AddCompanyCommentModal/AddCompanyCommentModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    height: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButtons: {
    display: 'flex',
    '& > *': {
      marginRight: '5px',
    },
  },
  recipientContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '800px',
    justifySelf: 'center',
  },
  recipientInputsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  reloadButton: {
    color: '#333333',
    backgroundColor: '#e4e4e8',
    height: '38px',
    width: '50px',
    margin: '1px 0 3px',
    padding: '5px',
  },
  templateSelect: {
    width: '220px',
  },
  addressFooter: {
    marginTop: '10px',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '40px',
    width: '100%',
    fontSize: '1rem',
  },
  saveContainer: {
    gridColumn: '1',
    display: 'flex',
    alignItems: 'center',
  },

  [theme.breakpoints.down('xs')]: {
    header: {
      justifyContent: 'center',
    },
    addressFooter: {
      fontSize: '0.85rem',
    },
    reloadButton: {
      height: '35px',
      width: '120px',
      '& p': {
        fontSize: '100%',
      },
    },
    templateSelect: {
      width: '220px',
    },
  },
}));

const initialUpdateCommentModalState = {
  open: false,
  template: null,
  flow: null,
  comment: '',
};

export default function RecipientDetails(props) {
  const [type, setType] = useState('manual');
  const [residentialAddress, setResidentialAddress] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [updateTemplate, setUpdateTemplate] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [keyCounter, setKeyCounter] = useState(0);
  const [updateCommentModalState, setUpdateCommentModalState] = useState(
    initialUpdateCommentModalState
  );
  const [newComment, setNewComment] = useState('');

  const { companyCountryCode } = useSelector((state) => state.user);
  const useRecipientTemplate = useSelector((state) => state.bookingPresets.useDefaultRecipient);
  const recipientTemplateId = useSelector((state) => state.bookingPresets.defaultRecipient);
  const recipient = useSelector((state) => state.booking.recipient);
  const booking = useSelector((state) => state.booking);
  const templates = useSelector((state) => state.recipientTemplates.templates);
  const id = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const ra = useRef();
  ra.current = residentialAddress;
  const templateNameRef = useRef();
  templateNameRef.current = templateName;
  const saveRecipient = useRef();
  saveRecipient.current = saveAddress;
  const recipientData = useRef();
  recipientData.current = recipient;
  const updateRecipientTemplate = useRef();
  updateRecipientTemplate.current = updateTemplate;
  const updateComment = useRef();
  updateComment.current = newComment;

  useEffect(() => {
    if (booking.residentialAddress) {
      setResidentialAddress(booking.residentialAddress);
    }

    if (templates.length < 1) {
      dispatch(fetchRecipientCompaniesTemplates({ userId: id, token }));
    }
    //look if global state has values saved
    if (recipient !== null) {
      setType('store');
    } else if (templates.length > 0 && useRecipientTemplate && recipientTemplateId) {
      const recipientDefault = templates.filter((template) => recipientTemplateId === template.id);

      if (recipientDefault.length > 0) {
        dispatch(
          addBookingRecipient({
            templateId: recipientDefault[0].id,
            company: recipientDefault[0].company,
            contact:
              recipientDefault[0].contacts.length > 0 ? recipientDefault[0].contacts[0].name : '',
            phone:
              recipientDefault[0].contacts.length > 0
                ? recipientDefault[0].contacts[0].phone
                : recipientDefault[0].phone,
            email:
              recipientDefault[0].contacts.length > 0
                ? recipientDefault[0].contacts[0].email
                : recipientDefault[0].email,
            city: recipientDefault[0].city,
            zip: recipientDefault[0].zip,
            address1: recipientDefault[0].address1,
            address2: recipientDefault[0].address2,
            address3: recipientDefault[0].address3,
            countryName: recipientDefault[0].countryName,
            countryCode: recipientDefault[0].countryCode,
            stateRegion: recipientDefault[0].stateRegion,
            contacts: recipientDefault[0].contacts,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //fires when component unmounts
  useEffect(() => {
    return () => {
      if (saveRecipient.current && templateNameRef.current) {
        let saveTemplateData = Object.assign({}, recipientData.current);
        if (updateComment.current) saveTemplateData.templateComment = updateComment.current;
        dispatch(addCompanyTemplate(saveTemplateData, templateNameRef.current, 'recipient', token));
      }

      if (updateRecipientTemplate.current && templateNameRef.current) {
        let updateTemplateData = Object.assign({}, recipientData.current);
        if (updateComment.current) updateTemplateData.templateComment = updateComment.current;
        dispatch(
          updateRecipientCompanyTemplateFromBooking(
            updateTemplateData,
            templateNameRef.current,
            token
          )
        );
      }

      dispatch(addBookingResidentialAddress(ra.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.length > 0 && !recipient && useRecipientTemplate && recipientTemplateId) {
      const recipientDefault = templates.filter((template) => recipientTemplateId === template.id);

      if (recipientDefault.length > 0) {
        dispatch(
          addBookingRecipient({
            templateId: recipientDefault[0].id || null,
            company: recipientDefault[0].company || '',
            phone:
              recipientDefault[0].contacts.length > 0
                ? recipientDefault[0].contacts[0].phone
                : recipientDefault[0].phone || '',
            email:
              recipientDefault[0].contacts.length > 0
                ? recipientDefault[0].contacts[0].email
                : recipientDefault[0].email || '',
            contact:
              recipientDefault[0].contacts.length > 0 ? recipientDefault[0].contacts[0].name : '',
            city: recipientDefault[0].city || '',
            zip: recipientDefault[0].zip || '',
            address1: recipientDefault[0].address1 || '',
            address2: recipientDefault[0].address2 || '',
            address3: recipientDefault[0].address3 || '',
            countryName: recipientDefault[0].countryName || '',
            countryCode: recipientDefault[0].countryCode || '',
            stateRegion: recipientDefault[0].stateRegion || '',
            contacts: recipientDefault[0].contacts,
          })
        );

        addTemplateComment(recipientDefault[0].templateComment);

        if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in recipient inputs referring to update and empty string
        setType('template');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  // Adds a comment to the booking status, also removes existing recipient comment if exists
  const addTemplateComment = (comment) => {
    const bookingStatusComment = createBookingStatusFromTemplateComment(comment, 'recipient');
    dispatch({
      type: ADD_BOOKING_STATUS,
      payload: bookingStatusComment,
    });
  };

  const handleTemplateSelected = (company) => {
    //Reset services if data exist in redux store and company template changes
    if (
      booking.recipient &&
      (booking.services.length > 0 || booking.carrier) &&
      booking.recipient.company !== company.company
    ) {
      props.handleClearServices();
    }

    updateTemplate && setUpdateTemplate(false);
    saveAddress && setSaveAddress(false);
    //Reset template name if exists
    templateName.length > 0 && setTemplateName('');

    dispatch(
      addBookingRecipient({
        templateId: company.id,
        company: company.company || '',
        phone: company.contacts.length > 0 ? company.contacts[0].phone : company.phone || '',
        email: company.contacts.length > 0 ? company.contacts[0].email : company.email || '',
        contact: company.contacts.length > 0 ? company.contacts[0].name : '',
        city: company.city || '',
        zip: company.zip || '',
        address1: company.address1 || '',
        address2: company.address2 || '',
        address3: company.address3 || '',
        countryName: company.countryName || '',
        countryCode: company.countryCode || '',
        stateRegion: company.stateRegion || '',
        contacts: company.contacts,
      })
    );
    addTemplateComment(company.templateComment);
    if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in recipient inputs referring to update and empty string
    type !== 'template' && setType('template');
    type === 'template' && setKeyCounter(keyCounter + 1);
  };

  const handleReload = () => {
    dispatch(
      addBookingRecipient({
        templateId: null,
        company: '',
        contact: '',
        phone: '',
        email: '',
        city: '',
        zip: '',
        address1: '',
        address2: '',
        address3: '',
        countryName: '',
        countryCode: '',
        stateRegion: '',
      })
    );

    type !== 'reload' && setType('reload');
    type === 'reload' && setKeyCounter(keyCounter + 1);

    if (booking.updated) dispatch(bookingValidationUpdate(null)); //aimed to clean errors in recipient inputs referring to update and empty string

    dispatch({
      type: REMOVE_BOOKING_STATUS,
      payload: 'recipientComment',
    });

    updateTemplate && setUpdateTemplate(false);
    saveAddress && setSaveAddress(false);
  };

  const handleTextBlur = (recipient) => {
    dispatch(addBookingRecipient(recipient));
  };

  const handleUpdateContact = (name, phone, email) => {
    dispatch(addBookingRecipient({ ...recipient, contact: name, phone, email }));
  };

  const handleResidentialAddressChange = () => {
    if (booking.services.length > 0 || booking.carrier) {
      props.handleClearServices();
    }
    setResidentialAddress(!residentialAddress);
  };
  const handleSaveAddressChange = () => {
    updateTemplate && setUpdateTemplate(false);
    setSaveAddress(!saveAddress);
    setTemplateName(booking.recipient.company); //Default favourite name
  };

  const handleUpdateTemplateChange = () => {
    saveAddress && setSaveAddress(false);
    setUpdateTemplate(!updateTemplate);
    setTemplateName(booking.recipient.company); //Default favourite name
  };

  const checkMovementDirectionChange = (recipientCountryCode) => {
    const movementDirection = flowByCountry(
      booking.sender.countryCode,
      recipientCountryCode,
      companyCountryCode
    );
    booking.movementDirection !== movementDirection &&
      dispatch({ type: SET_MOVEMENT_DIRECTION, movementDirection });
  };

  const checkServicesReset = (field, value) => {
    if (booking.services.length > 0 || booking.carrier) {
      if (booking.recipient && booking.recipient[field] !== value) {
        props.handleClearServices();
        return true;
      }
    }
    return false;
  };

  const getTemplateSelectedName = () => {
    if (!booking.recipient || !booking.recipient.templateId) return;
    const { templateName } = templates.find(({ id }) => id === booking.recipient.templateId);
    return templateName;
  };

  const handleUpdateCommentModal = () => {
    let template = null;
    let comment = null;
    // Case for existing template
    if (updateTemplate && booking.recipient.templateId) {
      template = templates.find(({ id }) => id === booking.recipient.templateId);
      comment = template.templateComment;
    }

    setUpdateCommentModalState({
      open: true,
      template,
      flow: 'recipient',
      comment,
    });
  };

  const handleUpdateComment = (comment) => {
    setUpdateCommentModalState(initialUpdateCommentModalState);
    setNewComment(comment);
  };

  const classes = useStyles();

  const key = `${type}${keyCounter}`;

  return (
    <div className={classes.root}>
      <AddCompanyCommentModal
        id="updateCommentModal"
        open={updateCommentModalState.open}
        template={updateCommentModalState.template}
        flow={updateCommentModalState.flow}
        updateComment={handleUpdateComment}
        handleAbort={() => setUpdateCommentModalState(initialUpdateCommentModalState)}
      />
      <div className={classes.recipientContainer} key={type}>
        <div className={classes.header}>
          <div className={classes.headerButtons}>
            {templates.length > 0 ? (
              <FavouriteCompanies
                templateSelected={handleTemplateSelected}
                options={templates}
                styles={classes.templateSelect}
                label={props.translations.onlinebooking.buttons.templates}
                defaultValue={getTemplateSelectedName()}
              />
            ) : null}
            <Tooltip title={props.translations.onlinebooking.buttons.cleanfields}>
              <Button className={classes.reloadButton} variant="contained" onClick={handleReload}>
                <ReloadIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.recipientInputsContainer}>
          <RecipientInputs
            handleUpdateCommentModal={handleUpdateCommentModal}
            handleResidentialAddressChange={handleResidentialAddressChange}
            residentialAddress={residentialAddress}
            handleSaveAddressChange={handleSaveAddressChange}
            handleUpdateTemplateChange={handleUpdateTemplateChange}
            saveAddress={saveAddress}
            updateTemplate={updateTemplate}
            translations={props.translations}
            templateSelected={handleTemplateSelected}
            templates={templates}
            updateContact={handleUpdateContact}
            textBlur={handleTextBlur}
            checkMovementDirectionChange={checkMovementDirectionChange}
            checkServicesReset={checkServicesReset}
            flow={booking.movementDirection}
            type={type}
            recipientData={recipient}
            key={key}
            templateNameChange={(e) => setTemplateName(e.target.value)}
            templateName={templateName}
            refresh={() => setKeyCounter(keyCounter + 1)}
          />
        </div>
      </div>
    </div>
  );
}
