import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateUseDefaultSender,
  updateUseDefaultRecipient,
  updateDefaultCustomsValue,
  updateDefaultCustomsCurrency,
  updateCompanyAutocomplete,
} from '../../../store/actions';
import translationsTooltips from '../../../services/translations/translationsTooltips';
//UI
import CustomToolbar from '../../UI/CustomToolbar/CustomToolbar';
//Material UI
import { Paper, AppBar, Switch, Button, Dialog, DialogTitle, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomsValue from '../PackageAndService/ShipmentDetails/CustomsValue/CustomsValue';
import InvoiceFormModal from './InvoiceFormModal/InvoiceFormModal';
import { grey } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import AddDescription from './AddDescription/AddDescription';
import NotificationsModal from './NotificationsModal/NotificationsModal';
import PrintSettingsModal from './PrintSettingsModal/PrintSettingsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontWeight: '400',
    color: 'black',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  defaultAddresses: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    borderBottom: `3px ridge ${grey[100]}`,
    '& > article': {
      display: 'flex',
      margin: '0 20px',
      padding: '0 20px',
      boxSizing: 'border-box',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '230px',
    },
  },
  additionalSettings: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    placeItems: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    gap: '10px',
    '& article': {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      border: '1px solid #eee',
      width: '100%',
      height: '100%',
      padding: '5px',
      boxSizing: 'border-box',
      placeItems: 'center',
      '& > label': {
        width: '100%',
        textAlign: 'center',
        borderBottom: `3px double ${grey[200]}`,
      },
    },
  },
  predefinedHeader: {
    gridColumn: '1 / -1',
    padding: '5px 0',
  },
  customsValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  customsValue: {
    maxWidth: '150px',
  },
  openInvoiceFormButton: {
    margin: '10px 0',
  },
  autoCompleteContainer: {
    display: 'flex',
  },
  autoCompleteItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  labelOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    width: '100%',
  },
  toolTip: {
    padding: '0 5px',
    color: grey[600],
    fontSize: '18px',
  },
  paperWidthSm: {
    maxWidth: '700px !important',
  },
  [theme.breakpoints.down('xs')]: {
    additionalSettings: {
      gridTemplateColumns: '1fr',
    },
  },
}));

const BookingSettings = (props) => {
  const { defaultLanguage: lang, defaultCurrency } = useSelector((state) => state.rootTemplates);
  const { token } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.user.userId);
  const bookingPresets = useSelector((state) => state.bookingPresets);
  const [useDefaults, setUseDefaults] = useState({
    sender: bookingPresets.useDefaultSender,
    recipient: bookingPresets.useDefaultRecipient,
  });
  const [monetaryValueState, setMonetaryValueState] = useState('');
  const [invoiceFormPresetModalOpen, setInvoiceFormPresetModalOpen] = useState(false);
  const [notificationsPresetsModalOpen, setNotificationsPresetsModalOpen] = useState(false);
  const [printPresetsModalOpen, setPrintPresetsModalOpen] = useState(false);

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (bookingPresets.useDefaultSender !== useDefaults.sender)
      setUseDefaults({ ...useDefaults, sender: bookingPresets.useDefaultSender });
    if (bookingPresets.useDefaultRecipient !== useDefaults.recipient)
      setUseDefaults({ ...useDefaults, recipient: bookingPresets.useDefaultRecipient });
  });

  useEffect(() => {
    setMonetaryValueState(bookingPresets.defaultCustomsValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingPresets.defaultCustomsValue]);

  const handleUpdateDefaultSender = () => {
    const isUse = !useDefaults.sender;
    dispatch(updateUseDefaultSender(userId, isUse));
  };

  const handleUpdateDefaultRecipient = () => {
    const isUse = !useDefaults.recipient;
    dispatch(updateUseDefaultRecipient(userId, isUse));
  };

  const handleCurrencyChange = (e) => {
    dispatch(updateDefaultCustomsCurrency(e.target.value, token));
  };

  const handleMonetaryValueChange = (e) => {
    const monValue = e.target.value.replace(',', '.');
    setMonetaryValueState(monValue);
  };

  const handleMonetaryValueBlur = (e) => {
    const float = parseFloat(e.target.value);

    if (isNaN(float)) {
      if (e.target.value === '') dispatch(updateDefaultCustomsValue(null, token)); //remove(reset) fixed customs value
      if (e.target.value !== '') setMonetaryValueState(bookingPresets.defaultCustomsValue || '');
    } else {
      dispatch(updateDefaultCustomsValue(float, token));
    }
  };

  const handleOpenInvoiceFormModal = () => {
    setInvoiceFormPresetModalOpen(true);
  };
  const handleOpenNotificationsModal = () => {
    setNotificationsPresetsModalOpen(true);
  };
  const handleOpenPrintModal = () => {
    setPrintPresetsModalOpen(true);
  };

  const handleUpdateCompanyAutocomplete = () => {
    const isUse = !bookingPresets.companyAutocomplete;
    dispatch(updateCompanyAutocomplete(userId, isUse));
  };

  const handleCloseInvoiceModal = () => setInvoiceFormPresetModalOpen(false);
  const handleCloseNotificationsModal = () => setNotificationsPresetsModalOpen(false);
  const handleClosePrintModal = () => setPrintPresetsModalOpen(false);

  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      <AppBar elevation={0} position="static">
        <CustomToolbar title={props.translations.bookingpreferences}></CustomToolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <section className={classes.defaultAddresses}>
          <article>
            <label htmlFor="default-sender-switch" className={classes.label}>
              {props.translations.use} {props.translations.defaultsender}
            </label>
            <Switch
              id="default-sender-switch"
              disableRipple
              onClick={handleUpdateDefaultSender}
              checked={useDefaults.sender}
            />
          </article>
          <article>
            <label htmlFor="default-recipient-switch" className={classes.label}>
              {props.translations.use} {props.translations.defaultrecipient}
            </label>
            <Switch
              id="default-recipient-switch"
              disableRipple
              onClick={handleUpdateDefaultRecipient}
              checked={useDefaults.recipient}
            />
          </article>
        </section>
        <section className={classes.additionalSettings}>
          <Dialog
            open={invoiceFormPresetModalOpen}
            onClose={handleCloseInvoiceModal}
            fullWidth={true}
            classes={{
              paperWidthSm: classes.paperWidthSm,
            }}
          >
            <DialogTitle id="invoice-form-dialog-title">
              {props.translations.onlinebooking.invoiceFormTemplate}
            </DialogTitle>
            <InvoiceFormModal
              bookingPresets={bookingPresets}
              translations={props.translations}
              close={handleCloseInvoiceModal}
            />
          </Dialog>
          <Dialog
            open={notificationsPresetsModalOpen}
            onClose={handleCloseNotificationsModal}
            fullWidth={true}
            classes={{
              paperWidthSm: classes.paperWidthSm,
            }}
          >
            <DialogTitle id="notifications-dialog-title">
              {props.translations.notifications}
            </DialogTitle>
            <NotificationsModal
              bookingPresets={bookingPresets}
              translations={props.translations}
              close={handleCloseNotificationsModal}
            />
          </Dialog>
          <Dialog
            open={printPresetsModalOpen}
            onClose={handleClosePrintModal}
            fullWidth={true}
            classes={{
              paperWidthSm: classes.paperWidthSm,
            }}
          >
            <DialogTitle id="print-dialog-title">{props.translations.printLabelFormat}</DialogTitle>
            <PrintSettingsModal
              bookingPresets={bookingPresets}
              translations={props.translations}
              close={handleClosePrintModal}
            />
          </Dialog>
          <p className={classes.predefinedHeader}> {props.translations.predefinedValues}</p>
          <article>
            <label className={classes.label}>
              {props.translations.onlinebooking.invoiceFormTemplate}
            </label>
            <Button
              className={classes.openInvoiceFormButton}
              variant="outlined"
              onClick={handleOpenInvoiceFormModal}
            >
              {props.translations.openInvoiceTemplate}
            </Button>
          </article>
          <article className={classes.autoCompleteContainer}>
            <label className={classes.label}>{props.translations.notifications}</label>
            <Button
              className={classes.openInvoiceFormButton}
              variant="outlined"
              onClick={handleOpenNotificationsModal}
            >
              {`${props.translations.add}/${props.translations.remove} ${props.translations.notifications}`}
            </Button>
          </article>
          <article className={classes.autoCompleteContainer}>
            <label className={classes.label}>
              {props.translations.onlinebooking.commoditydescription}
            </label>
            <AddDescription userId={userId} translations={props.translations} />
          </article>
          <article className={classes.customsValueContainer}>
            <label className={classes.label}>{props.translations.onlinebooking.customsvalue}</label>
            <div className={classes.customsValue}>
              <CustomsValue
                required={false}
                translations={props.translations.onlinebooking}
                monetaryValue={monetaryValueState}
                currency={bookingPresets.defaultCustomsCurrency || defaultCurrency}
                handleCurrencyChange={handleCurrencyChange}
                handleMonetaryValueChange={handleMonetaryValueChange}
                handleMonetaryValueBlur={handleMonetaryValueBlur}
                monetaryError={false}
              />
            </div>
          </article>
          <article className={classes.autoCompleteContainer}>
            <label className={classes.label}>{props.translations.autofillFields}</label>
            <div className={classes.autoCompleteItem}>
              <label htmlFor="company-autocomplete">{`${props.translations.toggleAutofillCompany}`}</label>
              <Tooltip title={translationsTooltips[lang].toggleAutofillAutocomplete}>
                <InfoIcon className={classes.toolTip} />
              </Tooltip>
              <Switch
                id="company-autocomplete"
                size="small"
                disableRipple
                onClick={handleUpdateCompanyAutocomplete}
                checked={bookingPresets.companyAutocomplete}
              />
            </div>
          </article>
          <article className={classes.autoCompleteContainer}>
            <label className={classes.label}>{props.translations.printLabelFormat}</label>
            <Button
              className={classes.openInvoiceFormButton}
              variant="outlined"
              onClick={handleOpenPrintModal}
            >
              {props.translations.setPrintFormat}
            </Button>
          </article>
        </section>
      </div>
    </Paper>
  );
};

export default BookingSettings;
