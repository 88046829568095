import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getTheme } from '../../../services/statistics/chartThemes';
import Paper from '@material-ui/core/Paper';
import translations from '../../../services/translations/translations';

const CostPerCountryData = ({ data: shipmentData, theme, lang, locale }) => {
  echarts.registerTheme(theme, getTheme[theme]);

  let currency = '';
  // TODO: include sender Country to combine export total cost with import costs as well
  const { costs, countries } = shipmentData.reduce(
    (acc, curr) => {
      // const senderCountry = curr.senderAddressCountryCode;
      let recipientCountryNotExists = false;
      const recipientCountry = curr.recipientAddressCountryCode;
      if (curr.totalCost && curr.totalCost > 0) {
        // senderCountryNotExists = !acc['countries'].includes(senderCountry)
        recipientCountryNotExists = !acc['countries'].includes(recipientCountry);

        if (/* senderCountryNotExists ||  */ recipientCountryNotExists) {
          /* senderCountryNotExists && acc['countries'].push(senderCountry); */
          recipientCountryNotExists && acc['countries'].push(recipientCountry);
        }

        let countryExists = false;

        acc['costs'].forEach((obj, idx) => {
          if (obj.country === recipientCountry) {
            acc['costs'][idx]['cost'] += Math.floor(curr.totalCost);
            if (!countryExists) countryExists = true;
          }
        });

        //add carrier if does not exist
        if (!countryExists) {
          if (currency.length === 0 && curr.costCurrency) currency = curr.costCurrency;
          acc['costs'][acc['costs'].length] = {
            cost: Math.floor(curr.totalCost),
            country: recipientCountry,
          };
        }
      }

      return acc;
    },
    { countries: [], costs: [] }
  );

  const options = {
    title: {
      text: translations[lang].statistics.sumOfCostsPerCountry,
      textStyle: {
        fontWeight: 'normal',
      },
    },
    tooltip: {
      formatter: (params) => `${params.value.toLocaleString(locale)}`,
    },
    toolbox: {
      show: true,
      orient: 'horizontal',
      right: 30,
      top: 10,
      feature: {
        saveAsImage: { title: translations[lang].statistics.saveAsImage },
      },
    },
    xAxis: {
      data: countries,
    },
    yAxis: {
      axisLabel: {
        formatter: (value) => `${value.toLocaleString(locale)} ${currency}`,
        fontWeight: 'normal',
        fontSize: 12,
      },
    },
    series: [
      {
        name: translations[lang].statistics.costs,
        type: 'bar',
        label: {
          show: true,
          formatter: (params) => `${params.value.toLocaleString(locale)}`,
          position: 'top',
          fontStyle: 'normal',
          color: 'black',
          fontWeight: 700,
          fontSize: 10,
          lineHeight: 16,
        },
        data: costs.map(({ cost, country }) => ({ value: cost, name: country })),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%' }}
        className="react_for_echarts"
        theme={theme}
      />
    </Paper>
  );
};

export default CostPerCountryData;
