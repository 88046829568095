import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  helperText: {
    position: 'absolute',
    bottom: '-14px',
    fontSize: '0.7rem',
  },
}));

export default function AddressAutocomplete({
  onClose,
  setInputValue,
  inputValue,
  country,
  name,
  maxLength,
  searchTypes,
  helperText = '',
  updateFieldsFromAutocomplete,
  ...rest //text field props
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [placeId, setPlaceId] = React.useState(null);

  const handlePlace = (value) => {
    if ('place_id' in value && value.place_id !== placeId) {
      setPlaceId(value.place_id);
      try {
        new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
          {
            placeId: value.place_id,
            fields: ['address_components'],
          },
          (details) => {
            const standardFields = {
              zip: '',
            };
            const countryFields = {};
            const addressFields = {};

            details.address_components.forEach((entry) => {
              if (entry.types[0] === 'postal_code') {
                standardFields['zip'] = entry.long_name.replace(/ /g, '');
              }
              if (entry.types[0] === 'country') {
                countryFields['countryCode'] = entry.short_name;
              }
              if (entry.types[0] === 'postal_town' || entry.types[0] === 'locality') {
                standardFields['city'] = entry.long_name;
              }
              if (entry.types[0] === 'administrative_area_level_1') {
                countryFields['stateRegion'] = entry.short_name;
              }
              if (entry.types[0] === 'route') {
                addressFields['streetName'] = entry.long_name;
              }
              if (entry.types[0] === 'street_number') {
                addressFields['streetNumber'] = entry.long_name;
              }
            });
            updateFieldsFromAutocomplete(standardFields, countryFields, addressFields);
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (value && typeof value === 'object') {
      handlePlace(value);
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: country ? { country } : null,
        types: searchTypes,
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    if (!value && inputValue.length > 0) {
      setValue(inputValue);
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Autocomplete
      id={`google-map-places-${name}`}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.structured_formatting.main_text
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={'Inga alternativ'}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        const paramsChanged = Object.assign({}, params);
        paramsChanged['inputProps'].autoComplete = 'new-password';
        paramsChanged['inputProps'].maxLength = maxLength;
        return (
          <TextField
            helperText={helperText}
            FormHelperTextProps={{ className: classes.helperText }}
            name={name}
            {...paramsChanged}
            {...rest}
            style={{ width: '100%', marginBottom: '1px' }}
          />
        );
      }}
      renderOption={(option) => {
        if (typeof option === 'string') return;
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <LocationOnIcon className={classes.icon} />
            </div>
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </div>
          </div>
        );
      }}
    />
  );
}
