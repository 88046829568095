import { makeStyles } from '@material-ui/core';
import React from 'react';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import { grey, green } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    padding: '0px 5px',
    position: 'absolute',
    top: '-25%',
    right: '-3px',
  },
  icon: {
    fontSize: '0.8rem',
    transform: 'translateX(-1px)',
  },
  button: {
    width: '14px',
    height: '14px',
    color: 'white',
    border: `1px solid ${grey[300]}`,
    backgroundColor: green[700],
    '&:active': {
      backgroundColor: `${green[400]} !important`,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: green[800],
    },
  },
});

const TableCellNumberSpinner = ({ handleDecreaseOne, handleIncreaseOne }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <button className={classes.button}>
        <UpArrow onClick={handleIncreaseOne} className={classes.icon} />
      </button>
      <button className={classes.button}>
        <DownArrow onClick={handleDecreaseOne} className={classes.icon} />
      </button>
    </div>
  );
};

export default TableCellNumberSpinner;
