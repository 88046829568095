import React, { useState, useEffect } from 'react';
import {
  MATKAHUOLTO,
  UPS,
  MATKAHUOLTO_HOME_DELIVERY,
  MATKAHUOLTO_DROPOFF_DELIVERY,
  DHLFREIGHT,
  DHL_SERVICE_POINT_SERVICE_CODES,
  DHL_EURAPID,
} from '../../../../services/types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addBookingServiceSelected, clearPickupData } from '../../../../store/actions';
import { getCarrierIcon } from '../../../../utils/carrier/carrierIcons';
import servicesTranslations from '../../../../services/translations/translationsShippingServices.json';
import moment from 'moment';
import { HorizontalSpinner } from '../../../UI/Spinners/Horizontal';
import { clearPrintFormat, setFixedDeliveryDates } from '../../../../store/actions/booking';
import {
  ADD_REQUESTED_DELIVERY_DATE,
  UPDATE_BOOKING_FIXED_DELIVERY_DATE,
} from '../../../../store/actions/actionTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  serviceOptions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '3px',
    boxSizing: 'border-box',
    padding: '5px',
    width: '100%',
  },
  serviceButton: {
    width: '100%',
    height: '100%',
    padding: '5px',
    border: '1px solid #dcdcdc',
    //backgroundColor: '#e0e0e0',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'blue',
    },
  },
  serviceContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridTemplateRows: '20px minmax(20px,auto) 20px 20px',
    justifyItems: 'start',
    alignItems: 'center',
    height: '100%',
  },
  serviceName: {
    fontWeight: 400,
    fontSize: '0.9rem',
    lineHeight: '0.8rem',
  },
  rateLoadingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
  },
  serviceDetails: {
    margin: 5,
    padding: 5,
    fontWeight: 300,
    fontSize: '0.9rem',
    border: ({ backgroundColor }) => `1px solid ${backgroundColor}`,
  },
  serviceSuffix: {
    fontSize: '0.85rem',
    fontWeight: 500,
  },
  logoContainer: {
    gridColumn: '2 / span 1',
    gridRow: '1 / span 2',
    width: '100%',
    placeSelf: 'center',
  },
  serviceCost: {
    fontWeight: 600,
    fontSize: '0.9rem',
    height: '20px',
  },
  serviceSubtitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    '& > p': {
      fontSize: '0.8rem',
      fontWeight: '500',
      '& > span': {
        paddingLeft: '4px',
        fontSize: '0.7rem',
        fontWeight: '500',
      },
    },
  },
  tnt: {
    gridColumn: '1 / span 2',
    fontSize: '0.9rem',
    fontWeight: 400,
    justifySelf: 'center',
  },
  eta: {
    gridColumn: '1 / -1',
    fontSize: '0.85rem',
    lineHeight: '0.85rem',
    fontWeight: 500,
    width: '100%',
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  surchargeWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px,max-content) 200px',
  },
  button: {
    position: 'relative',
    padding: '5px 0',
    top: '100px',
    width: '200px',
    height: '50px',
    backgroundColor: '#5f361f',
    color: 'white',
  },
  '@media (max-width:600px)': {
    serviceOptions: {
      gridTemplateColumns: '1fr',
    },
  },
}));

const ShipServices = ({
  translations,
  carrierColors,
  handleDeliveryMethodChangeByCode,
  clearAlternateDeliveryAddress,
  matkahuoltoHomeDeliveryServices,
  handleDropOffLocationRequest,
  dropOffLoading,
  serviceLoadingIdx,
  updateCostForService,
  selectedDeliveryMethod,
  lang,
}) => {
  const dispatch = useDispatch();
  const {
    carrier,
    additionalServices,
    fixedDeliveryDate,
    fixedDeliveryDates,
    services,
    printFormat,
    alternateDeliveryAddress,
    serviceCode: serviceSelected,
  } = useSelector((state) => state.booking);
  const [selected, setSelected] = useState(null);
  const [selectedServiceSurcharges, setSelectedServiceSurcharges] = useState([]);
  const [selectedServiceDescription, setSelectedServiceDescription] = useState('');

  useEffect(() => {
    //If services exist in redux, and service has been selected then use same selection
    if (services.length > 0 && serviceSelected) {
      services.forEach((service, index) => {
        if (
          service.carrier === UPS &&
          service.serviceCode === serviceSelected &&
          service.deliveryMethod === selectedDeliveryMethod
        ) {
          setSelected(index);
        }
        if (service.carrier !== UPS && service.serviceCode === serviceSelected) {
          setSelected(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, selectedDeliveryMethod]);

  const clearDetails = () => {
    selectedServiceSurcharges.length > 0 && setSelectedServiceSurcharges([]);
    selectedServiceDescription.length > 0 && setSelectedServiceDescription('');
  };

  const handleServiceSelect = (service, index) => {
    //In case fetching drop off locations, it's not possible to switch service
    if (dropOffLoading) return;

    // Check if service.options exists, if so check if it contains fixedDeliveryDates and that it is an array and not empty
    if (
      service.options &&
      service.options.fixedDeliveryDates &&
      Array.isArray(service.options.fixedDeliveryDates) &&
      service.options.fixedDeliveryDates.length > 0
    ) {
      dispatch(setFixedDeliveryDates(service.options.fixedDeliveryDates));
    } else {
      fixedDeliveryDates.length > 0 && dispatch(setFixedDeliveryDates([]));
    }

    /**
     * When selecting the same service, do nothing
     * Different carriers can have same service code therefore also validate carrier
     * UPS also validates delivery method
     */
    if (carrier === UPS) {
      if (
        service.serviceCode === serviceSelected &&
        service.carrier === carrier &&
        service.deliveryMethod === selectedDeliveryMethod
      )
        return;
    }
    if (carrier !== UPS) {
      if (service.serviceCode === serviceSelected && service.carrier === carrier) return;
    }

    clearDetails();

    /**
     * MATKAHUOLTO
     * Whenever switching between Matkahuolto home delivery product and drop off deliveries
     */
    if (carrier === MATKAHUOLTO && service.carrier === MATKAHUOLTO) {
      const previousServiceHomeDelivery = matkahuoltoHomeDeliveryServices.includes(serviceSelected);
      const currentServiceHomeDelivery = matkahuoltoHomeDeliveryServices.includes(
        service.serviceCode
      );

      if (previousServiceHomeDelivery && !currentServiceHomeDelivery)
        handleDeliveryMethodChangeByCode(MATKAHUOLTO_DROPOFF_DELIVERY);

      if (!previousServiceHomeDelivery && currentServiceHomeDelivery)
        handleDeliveryMethodChangeByCode(MATKAHUOLTO_HOME_DELIVERY);
    }

    /**
     * ALL CARRIERS
     * Run Drop off location search directly whenever carrier Matkahoulto is selected
     */
    //Run below when selected service has a different carrier than previously selected
    if (service.carrier !== carrier) {
      dispatch(clearPickupData()); //clear previously selected pickup (with other carrier)
      if (printFormat) dispatch(clearPrintFormat()); //clear previously selected print method (with other carrier)
      if (alternateDeliveryAddress) clearAlternateDeliveryAddress(); //clear previously selected alternate delivery address (with other carrier)
      /**
       * MATKAHUOLTO
       * Run Drop off location search directly whenever carrier Matkahoulto is selected
       */
      if (service.carrier === MATKAHUOLTO) {
        if (matkahuoltoHomeDeliveryServices.includes(service.serviceCode)) {
          handleDeliveryMethodChangeByCode(MATKAHUOLTO_HOME_DELIVERY);
        } else {
          handleDeliveryMethodChangeByCode(MATKAHUOLTO_DROPOFF_DELIVERY);
        }
      }
    }
    /**
     * DHL
     */
    if (service.carrier === DHLFREIGHT) {
      // If fixed delivery date has been selected, clear it
      if (fixedDeliveryDate) {
        dispatch({
          type: UPDATE_BOOKING_FIXED_DELIVERY_DATE,
          payload: null,
        });
      }

      //Handle service points for applicable services
      DHL_SERVICE_POINT_SERVICE_CODES.includes(service.serviceCode) &&
        handleDropOffLocationRequest(DHLFREIGHT, true);
      !DHL_SERVICE_POINT_SERVICE_CODES.includes(service.serviceCode) &&
        clearAlternateDeliveryAddress();

      if (service.serviceCode === DHL_EURAPID) {
        dispatch({
          type: ADD_REQUESTED_DELIVERY_DATE,
          payload: moment(service.eta.date, 'YYYYMMDD').format('YYYY-MM-DD'),
        });
      }

      //reset rate for previous service if any additional services exist on previous selected service
      if (additionalServices.length > 0)
        updateCostForService(carrier, serviceSelected, { additionalServices: [] });
    }

    /**
     * UPS
     */
    if (service.carrier === UPS) {
      if (service.deliveryMethod !== selectedDeliveryMethod) {
        handleDeliveryMethodChangeByCode(service.deliveryMethod);
      }
    }

    setSelected(index);
    service.surcharges && setSelectedServiceSurcharges(service.surcharges);
    service.serviceDescription &&
      lang in service.serviceDescription &&
      setSelectedServiceDescription(service.serviceDescription[lang]);

    dispatch(addBookingServiceSelected(service));
  };

  const formatEta = (eta) => {
    if (!eta || !eta.date) return null;
    if (!eta.timeEnd) return moment(`${eta.date}`).format('dddd, MMM D');
    if (eta.timeEnd === '233000')
      return `${moment(`${eta.date}T${eta.timeEnd}`).format('dddd, MMM D')} - ${
        translations.byEndOfDay
      }`;
    if (eta.timeStart) {
      const timeStart = `${eta.timeStart.substring(0, 2)}:${eta.timeStart.substring(2, 4)}`;
      const timeEnd = `${eta.timeEnd.substring(0, 2)}:${eta.timeEnd.substring(2, 4)}`;

      return `${moment(`${eta.date}`).format('dddd, MMM D')} - ${
        translations.between
      } ${timeStart}-${timeEnd}`;
    }
    return moment(`${eta.date}T${eta.timeEnd}`).format(
      `dddd, MMM D [- ${translations.latestBy}] HH:mm`
    );
  };

  const formatTimeInTransit = (businessDays, totalDays) => {
    let timeInTransitString = '';

    if (businessDays)
      timeInTransitString = timeInTransitString.concat(
        `${businessDays} ${translations.rates.rateresult.workingdays}`
      );
    if (totalDays) {
      if (businessDays)
        timeInTransitString = timeInTransitString.concat(
          ` / ${totalDays} ${translations.rates.rateresult.total}`
        );
      if (!businessDays)
        timeInTransitString = timeInTransitString.concat(
          `${totalDays} ${translations.rates.rateresult.days} ${translations.rates.rateresult.total}`
        );
    }

    return timeInTransitString;
  };

  const classes = useStyles(carrierColors);
  return (
    <div className={classes.root}>
      <div className={classes.serviceOptions}>
        {services.map((service, index) => {
          const eta = formatEta(service.eta);
          const tnt = formatTimeInTransit(service.businessDaysInTransit, service.totalTransitDays);
          return (
            <Button
              style={{
                backgroundColor: index === selected ? '#eee' : 'white',
              }}
              onClick={() => handleServiceSelect(service, index)}
              key={index}
              className={classes.serviceButton}
            >
              <div className={classes.serviceContainer}>
                <p className={classes.serviceName}>
                  {service.serviceName
                    ? service.serviceName
                    : servicesTranslations[lang][service.serviceCode]}
                  <span className={classes.serviceSuffix}>
                    {service.deliveryMethod === '01' ? ` (Access point)` : ''}
                  </span>
                </p>
                <div className={classes.logoContainer}>
                  <img
                    style={{ maxHeight: '35px', width: '50px' }}
                    src={getCarrierIcon(service.carrier)}
                    alt="Logotype"
                  />
                </div>
                <div className={classes.serviceCost}>
                  {index === serviceLoadingIdx ? (
                    <div className={classes.rateLoadingContainer}>
                      <HorizontalSpinner size="md" />
                    </div>
                  ) : (
                    <p>{`${service.totalRate} ${service.currencyCode}`}</p>
                  )}
                  {/* {service.totalRate} {service.currencyCode} */}
                </div>
                <p className={classes.tnt}>{tnt}</p>
                {eta && <p className={classes.eta}>{eta}</p>}
              </div>
            </Button>
          );
        })}
      </div>
      {(selectedServiceDescription.length > 0 || selectedServiceSurcharges.length > 0) && (
        <div className={classes.serviceDetails}>
          {selectedServiceDescription.length > 0 && (
            <p style={{ fontSize: '1rem' }}>{selectedServiceDescription}</p>
          )}
          {selectedServiceSurcharges.length > 0 && (
            <div style={{ margin: '5px 0' }}>
              <p style={{ fontWeight: 500 }}>
                {translations.rates.rateresult.additionalCostDetails}
              </p>
              {selectedServiceSurcharges.map((surcharge) => (
                <div key={surcharge.label} className={classes.surchargeWrapper}>
                  <p>{surcharge.label}</p>
                  {surcharge.value && (
                    <p style={{ fontWeight: 400 }}>
                      {surcharge.value}{' '}
                      <span style={{ fontWeight: 300 }}>{surcharge.currency}</span>
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShipServices;
