import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let SeaFreight = (props) => {

	return (
		<SvgIcon {...props}>
		 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.84 7.11">
      <path
        d="M16.53 3.41l-1 1.44-7.7-.09-.9-1.35H2.25l1.71 3.32a.69.69 0 00.62.38h11.75a.71.71 0 00.61-.35l1.89-3.35z"
      ></path>
      <path  d="M4.1 0L4.1 1.57 5.86 1.28 5.86 0 4.1 0z"></path>
      <path
        d="M11.47 2.42H8.04v2.06h3.43V2.42zM13.38 0H9.96v2.06h3.42V0zM15.44 2.42h-3.61v2.06h3.58V2.42zM1.63 1.75h-.6a.29.29 0 010-.57h.6a.29.29 0 110 .57zM1.63 5.82h-.6a.29.29 0 010-.57h.6a.29.29 0 110 .57zM1.04 3.77H.29A.28.28 0 010 3.49a.29.29 0 01.29-.29h.75a.29.29 0 01.29.29.28.28 0 01-.29.28zM3.51 1.28v2.13l3.19.08V1.28z"
      ></path>
    </svg>
		</SvgIcon>
	);
}

SeaFreight = pure(SeaFreight);
SeaFreight.displayName = 'SeaFreight';
SeaFreight.muiName = 'SvgIcon';

export default SeaFreight
