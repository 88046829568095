export const isNumericString = (str) => {
  if (typeof str !== 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const uuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const isUUID = (value) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value);

export const filterUUIDsFromString = (string) => {
  let filteredString = string;
  if (string.length < 36) return string;
  if (string.length === 36) {
    if (isUUID(string)) {
      return '';
    }
    return string;
  }

  if (string.length > 36) {
    while (filteredString.length >= 36) {
      if (!uuidPattern.test(string)) return filteredString;
      if (uuidPattern.test(string)) filteredString = filteredString.replace(uuidPattern, '');
    }
  }

  return filteredString;
};
