import {FETCH_CARRIERS_START,FETCH_CARRIERS_SUCCESS,FETCH_CARRIERS_FAILED } from './actionTypes';
import axios from 'axios';

export const fetchCarriersStart = () => {
	return {
		type: FETCH_CARRIERS_START
	}
}

export const fetchCarriersSuccess = (carriers, allowedCarriers) => {
	return {
		type: FETCH_CARRIERS_SUCCESS,
		carriers,
		allowedCarriers
	}
}

export const fetchCarriersFailed = (err) => {
	return {
		type: FETCH_CARRIERS_FAILED,
		error: err
	}
}

export const fetchCarriers = (token, companyId) => {
	
	return dispatch => {
		dispatch(fetchCarriersStart());
		axios.get(`/api/carriers/company/${companyId}`, { headers: { Authorization: `Bearer ${token}` }})
			.then(resp => {
				dispatch(fetchCarriersSuccess(resp.data.carriers, resp.data.allowedCarriers))
			})
			.catch(err => dispatch(fetchCarriersFailed(err)));
	}
}