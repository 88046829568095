export const roma = () => {
  const colorPalette = [
    '#E01F54',
    '#001852',
    '#f5e8c8',
    '#b8d2c7',
    '#c6b38e',
    '#a4d8c2',
    '#f3d999',
    '#d3758f',
    '#dcc392',
    '#2e4783',
    '#82b6e9',
    '#ff6347',
    '#a092f1',
    '#0a915d',
    '#eaf889',
    '#6699FF',
    '#ff6666',
    '#3cb371',
    '#d5b158',
    '#38b6b6',
  ];

  const theme = {
    color: colorPalette,

    visualMap: {
      color: ['#e01f54', '#e7dbc3'],
      textStyle: {
        color: '#333',
      },
    },

    candlestick: {
      itemStyle: {
        normal: {
          color: '#e01f54',
          color0: '#001852',
          lineStyle: {
            width: 1,
            color: '#f5e8c8',
            color0: '#b8d2c7',
          },
        },
      },
    },

    graph: {
      color: colorPalette,
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#E01F54'],
            [0.8, '#b8d2c7'],
            [1, '#001852'],
          ],
          width: 8,
        },
      },
    },
  };

  return theme;
};

export const dark = () => {
  const contrastColor = '#eee';
  const axisCommon = function () {
    return {
      axisLine: {
        lineStyle: {
          color: contrastColor,
        },
      },
      axisTick: {
        lineStyle: {
          color: contrastColor,
        },
      },
      axisLabel: {
        textStyle: {
          color: contrastColor,
        },
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#aaa',
        },
      },
      splitArea: {
        areaStyle: {
          color: contrastColor,
        },
      },
    };
  };

  const colorPalette = [
    '#dd6b66',
    '#759aa0',
    '#e69d87',
    '#8dc1a9',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ];
  const theme = {
    color: colorPalette,
    backgroundColor: '#333',
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: contrastColor,
        },
        crossStyle: {
          color: contrastColor,
        },
      },
    },
    legend: {
      textStyle: {
        color: contrastColor,
      },
    },
    textStyle: {
      color: contrastColor,
    },
    title: {
      textStyle: {
        color: contrastColor,
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: contrastColor,
        },
      },
    },
    dataZoom: {
      textStyle: {
        color: contrastColor,
      },
    },
    timeline: {
      lineStyle: {
        color: contrastColor,
      },
      itemStyle: {
        normal: {
          color: colorPalette[1],
        },
      },
      label: {
        normal: {
          textStyle: {
            color: contrastColor,
          },
        },
      },
      controlStyle: {
        normal: {
          color: contrastColor,
          borderColor: contrastColor,
        },
      },
    },
    timeAxis: axisCommon(),
    logAxis: axisCommon(),
    valueAxis: axisCommon(),
    categoryAxis: axisCommon(),

    line: {
      symbol: 'circle',
    },
    graph: {
      color: colorPalette,
    },
    gauge: {
      title: {
        textStyle: {
          color: contrastColor,
        },
      },
    },
    candlestick: {
      itemStyle: {
        normal: {
          color: '#FD1050',
          color0: '#0CF49B',
          borderColor: '#FD1050',
          borderColor0: '#0CF49B',
        },
      },
    },
  };
  theme.categoryAxis.splitLine.show = false;

  return theme;
};
export const infographic = () => {
  const colorPalette = [
    '#C1232B',
    '#27727B',
    '#FCCE10',
    '#E87C25',
    '#B5C334',
    '#FE8463',
    '#9BCA63',
    '#FAD860',
    '#F3A43B',
    '#60C0DD',
    '#D7504B',
    '#C6E579',
    '#F4E001',
    '#F0805A',
    '#26C0C0',
  ];

  const theme = {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#27727B',
      },
    },

    visualMap: {
      color: ['#C1232B', '#FCCE10'],
    },

    toolbox: {
      iconStyle: {
        normal: {
          borderColor: colorPalette[0],
        },
      },
    },

    tooltip: {
      backgroundColor: 'rgba(50,50,50,0.5)',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#27727B',
          type: 'dashed',
        },
        crossStyle: {
          color: '#27727B',
        },
        shadowStyle: {
          color: 'rgba(200,200,200,0.3)',
        },
      },
    },

    dataZoom: {
      dataBackgroundColor: 'rgba(181,195,52,0.3)',
      fillerColor: 'rgba(181,195,52,0.2)',
      handleColor: '#27727B',
    },

    categoryAxis: {
      axisLine: {
        lineStyle: {
          color: '#27727B',
        },
      },
      splitLine: {
        show: false,
      },
    },

    valueAxis: {
      axisLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: ['#ccc'],
          type: 'dashed',
        },
      },
    },

    timeline: {
      lineStyle: {
        color: '#27727B',
      },
      controlStyle: {
        normal: {
          color: '#27727B',
          borderColor: '#27727B',
        },
      },
      symbol: 'emptyCircle',
      symbolSize: 3,
    },

    line: {
      itemStyle: {
        normal: {
          borderWidth: 2,
          borderColor: '#fff',
          lineStyle: {
            width: 3,
          },
        },
        emphasis: {
          borderWidth: 0,
        },
      },
      symbol: 'circle',
      symbolSize: 3.5,
    },

    candlestick: {
      itemStyle: {
        normal: {
          color: '#C1232B',
          color0: '#B5C334',
          lineStyle: {
            width: 1,
            color: '#C1232B',
            color0: '#B5C334',
          },
        },
      },
    },

    graph: {
      color: colorPalette,
    },

    map: {
      label: {
        normal: {
          textStyle: {
            color: '#C1232B',
          },
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
      itemStyle: {
        normal: {
          areaColor: '#ddd',
          borderColor: '#eee',
        },
        emphasis: {
          areaColor: '#fe994e',
        },
      },
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#B5C334'],
            [0.8, '#27727B'],
            [1, '#C1232B'],
          ],
        },
      },
      axisTick: {
        splitNumber: 2,
        length: 5,
        lineStyle: {
          color: '#fff',
        },
      },
      axisLabel: {
        textStyle: {
          color: '#fff',
        },
      },
      splitLine: {
        length: '5%',
        lineStyle: {
          color: '#fff',
        },
      },
      title: {
        offsetCenter: [0, -20],
      },
    },
  };

  return theme;
};

export const macarons = () => {
  const colorPalette = [
    '#2ec7c9',
    '#b6a2de',
    '#5ab1ef',
    '#ffb980',
    '#d87a80',
    '#8d98b3',
    '#e5cf0d',
    '#97b552',
    '#95706d',
    '#dc69aa',
    '#07a2a4',
    '#9a7fd1',
    '#588dd5',
    '#f5994e',
    '#c05050',
    '#59678c',
    '#c9ab00',
    '#7eb00a',
    '#6f5553',
    '#c14089',
  ];

  const theme = {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#008acd',
      },
    },

    visualMap: {
      itemWidth: 15,
      color: ['#5ab1ef', '#e0ffff'],
    },

    toolbox: {
      iconStyle: {
        normal: {
          borderColor: colorPalette[0],
        },
      },
    },

    tooltip: {
      backgroundColor: 'rgba(50,50,50,0.5)',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#008acd',
        },
        crossStyle: {
          color: '#008acd',
        },
        shadowStyle: {
          color: 'rgba(200,200,200,0.2)',
        },
      },
    },

    dataZoom: {
      dataBackgroundColor: '#efefff',
      fillerColor: 'rgba(182,162,222,0.2)',
      handleColor: '#008acd',
    },

    grid: {
      borderColor: '#eee',
    },

    categoryAxis: {
      axisLine: {
        lineStyle: {
          color: '#008acd',
        },
      },
      splitLine: {
        lineStyle: {
          color: ['#eee'],
        },
      },
    },

    valueAxis: {
      axisLine: {
        lineStyle: {
          color: '#008acd',
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(200,200,200,0.1)'],
        },
      },
      splitLine: {
        lineStyle: {
          color: ['#eee'],
        },
      },
    },

    timeline: {
      lineStyle: {
        color: '#008acd',
      },
      controlStyle: {
        normal: { color: '#008acd' },
        emphasis: { color: '#008acd' },
      },
      symbol: 'emptyCircle',
      symbolSize: 3,
    },

    line: {
      smooth: true,
      symbol: 'emptyCircle',
      symbolSize: 3,
    },

    candlestick: {
      itemStyle: {
        normal: {
          color: '#d87a80',
          color0: '#2ec7c9',
          lineStyle: {
            color: '#d87a80',
            color0: '#2ec7c9',
          },
        },
      },
    },

    scatter: {
      symbol: 'circle',
      symbolSize: 4,
    },

    map: {
      label: {
        normal: {
          textStyle: {
            color: '#d87a80',
          },
        },
      },
      itemStyle: {
        normal: {
          borderColor: '#eee',
          areaColor: '#ddd',
        },
        emphasis: {
          areaColor: '#fe994e',
        },
      },
    },

    graph: {
      color: colorPalette,
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#2ec7c9'],
            [0.8, '#5ab1ef'],
            [1, '#d87a80'],
          ],
          width: 10,
        },
      },
      axisTick: {
        splitNumber: 10,
        length: 15,
        lineStyle: {
          color: 'auto',
        },
      },
      splitLine: {
        length: 22,
        lineStyle: {
          color: 'auto',
        },
      },
      pointer: {
        width: 5,
      },
    },
  };

  return theme;
};
export const purplePassion = () => {
  return {
    color: ['#9b8bba', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
    backgroundColor: 'rgba(91,92,110,1)',
    textStyle: {},
    title: { textStyle: { color: '#ffffff' }, subtextStyle: { color: '#cccccc' } },
    line: {
      itemStyle: { normal: { borderWidth: '2' } },
      lineStyle: { normal: { width: '3' } },
      symbolSize: '7',
      symbol: 'circle',
      smooth: !0,
    },
    radar: {
      itemStyle: { normal: { borderWidth: '2' } },
      lineStyle: { normal: { width: '3' } },
      symbolSize: '7',
      symbol: 'circle',
      smooth: !0,
    },
    bar: {
      itemStyle: {
        normal: { barBorderWidth: 0, barBorderColor: '#ccc' },
        emphasis: { barBorderWidth: 0, barBorderColor: '#ccc' },
      },
    },
    pie: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    scatter: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    boxplot: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    parallel: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    sankey: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    funnel: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    gauge: {
      itemStyle: {
        normal: { borderWidth: 0, borderColor: '#ccc' },
        emphasis: { borderWidth: 0, borderColor: '#ccc' },
      },
    },
    candlestick: {
      itemStyle: {
        normal: {
          color: '#e098c7',
          color0: 'transparent',
          borderColor: '#e098c7',
          borderColor0: '#8fd3e8',
          borderWidth: '2',
        },
      },
    },
    graph: {
      itemStyle: { normal: { borderWidth: 0, borderColor: '#ccc' } },
      lineStyle: { normal: { width: 1, color: '#aaaaaa' } },
      symbolSize: '7',
      symbol: 'circle',
      smooth: !0,
      color: ['#9b8bba', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
      label: { normal: { textStyle: { color: '#eeeeee' } } },
    },
    map: {
      itemStyle: {
        normal: { areaColor: '#eeeeee', borderColor: '#444444', borderWidth: 0.5 },
        emphasis: { areaColor: 'rgba(224,152,199,1)', borderColor: '#444444', borderWidth: 1 },
      },
      label: {
        normal: { textStyle: { color: '#000000' } },
        emphasis: { textStyle: { color: 'rgb(255,255,255)' } },
      },
    },
    geo: {
      itemStyle: {
        normal: { areaColor: '#eeeeee', borderColor: '#444444', borderWidth: 0.5 },
        emphasis: { areaColor: 'rgba(224,152,199,1)', borderColor: '#444444', borderWidth: 1 },
      },
      label: {
        normal: { textStyle: { color: '#000000' } },
        emphasis: { textStyle: { color: 'rgb(255,255,255)' } },
      },
    },
    categoryAxis: {
      axisLine: { show: !0, lineStyle: { color: '#cccccc' } },
      axisTick: { show: !1, lineStyle: { color: '#333' } },
      axisLabel: { show: !0, textStyle: { color: '#cccccc' } },
      splitLine: { show: !1, lineStyle: { color: ['#eeeeee', '#333333'] } },
      splitArea: {
        show: !0,
        areaStyle: { color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'] },
      },
    },
    valueAxis: {
      axisLine: { show: !0, lineStyle: { color: '#cccccc' } },
      axisTick: { show: !1, lineStyle: { color: '#333' } },
      axisLabel: { show: !0, textStyle: { color: '#cccccc' } },
      splitLine: { show: !1, lineStyle: { color: ['#eeeeee', '#333333'] } },
      splitArea: {
        show: !0,
        areaStyle: { color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'] },
      },
    },
    logAxis: {
      axisLine: { show: !0, lineStyle: { color: '#cccccc' } },
      axisTick: { show: !1, lineStyle: { color: '#333' } },
      axisLabel: { show: !0, textStyle: { color: '#cccccc' } },
      splitLine: { show: !1, lineStyle: { color: ['#eeeeee', '#333333'] } },
      splitArea: {
        show: !0,
        areaStyle: { color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'] },
      },
    },
    timeAxis: {
      axisLine: { show: !0, lineStyle: { color: '#cccccc' } },
      axisTick: { show: !1, lineStyle: { color: '#333' } },
      axisLabel: { show: !0, textStyle: { color: '#cccccc' } },
      splitLine: { show: !1, lineStyle: { color: ['#eeeeee', '#333333'] } },
      splitArea: {
        show: !0,
        areaStyle: { color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'] },
      },
    },
    toolbox: {
      iconStyle: { normal: { borderColor: '#999999' }, emphasis: { borderColor: '#666666' } },
    },
    legend: { textStyle: { color: '#cccccc' } },
    tooltip: {
      axisPointer: {
        lineStyle: { color: '#cccccc', width: 1 },
        crossStyle: { color: '#cccccc', width: 1 },
      },
    },
    timeline: {
      lineStyle: { color: '#8fd3e8', width: 1 },
      itemStyle: { normal: { color: '#8fd3e8', borderWidth: 1 }, emphasis: { color: '#8fd3e8' } },
      controlStyle: {
        normal: { color: '#8fd3e8', borderColor: '#8fd3e8', borderWidth: 0.5 },
        emphasis: { color: '#8fd3e8', borderColor: '#8fd3e8', borderWidth: 0.5 },
      },
      checkpointStyle: { color: '#8fd3e8', borderColor: 'rgba(138,124,168,0.37)' },
      label: {
        normal: { textStyle: { color: '#8fd3e8' } },
        emphasis: { textStyle: { color: '#8fd3e8' } },
      },
    },
    visualMap: { color: ['#8a7ca8', '#e098c7', '#cceffa'] },
    dataZoom: {
      backgroundColor: 'rgba(0,0,0,0)',
      dataBackgroundColor: 'rgba(255,255,255,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: { color: '#333333' },
    },
    markPoint: {
      label: {
        normal: { textStyle: { color: '#eeeeee' } },
        emphasis: { textStyle: { color: '#eeeeee' } },
      },
    },
  };
};
