import React from 'react';
import { connect, useSelector } from 'react-redux';
//Components
import CompanyHeader from './CompanyHeader/CompanyHeader';
import DetailsRow from './DetailsRow/DetailsRow';
//Material UI
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
//translations
import translations from '../../../services/translations/translations';

const useStyles = makeStyles((theme) => ({
  aboutme: {
    gridArea: 'main',
    position: 'relative',
    '& article': {
      height: '100%',
      padding: '8px',
      display: 'grid',
      boxSizing: 'border-box',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gridColumnGap: '4px',
      gridRowGap: '4px',
    },
    '& h2': {
      textAlign: 'right',
      paddingRight: '7px',
    },
    '& p': {
      fontSize: '1rem',
      fontWeight: '300',
    },
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    gridRow: '2/4',
    gridColumn: '1 / -1',
  },
  details: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content 1fr',
  },
  background: {
    width: '100%',
    height: '145px',
    background:
      'linear-gradient(0deg, #FFF 0%,transparent 100%),linear-gradient(90deg, #FFF 0%,transparent 100%),linear-gradient(135deg, rgba(51, 51, 51, 0.1) 0%, rgba(51, 51, 51, 0.1) 66%,rgba(249, 249, 249, 0.1) 66%, rgba(249, 249, 249, 0.1) 100%),linear-gradient(0deg, rgba(231, 231, 231, 0.05) 0%, rgba(231, 231, 231, 0.05) 83%,rgba(255, 255, 255, 0.05) 83%, rgba(255, 255, 255, 0.05) 100%),linear-gradient(0deg, rgba(32, 32, 32, 0.1) 0%, rgba(32, 32, 32, 0.1) 51%,rgba(138, 138, 138, 0.1) 51%, rgba(138, 138, 138, 0.1) 100%),linear-gradient(0deg, rgba(79, 79, 79, 0.07) 0%, rgba(79, 79, 79, 0.07) 9%,rgba(52, 52, 52, 0.07) 9%, rgba(52, 52, 52, 0.07) 100%),linear-gradient(135deg, rgba(154, 154, 154, 0.05) 0%, rgba(154, 154, 154, 0.05) 82%,rgba(76, 76, 76, 0.05) 82%, rgba(76, 76, 76, 0.05) 100%),linear-gradient(0deg, rgba(226, 226, 226, 0.1) 0%, rgba(226, 226, 226, 0.1) 87%,rgba(158, 158, 158, 0.1) 87%, rgba(158, 158, 158, 0.1) 100%),linear-gradient(45deg, rgba(177, 177, 177, 0.1) 0%, rgba(177, 177, 177, 0.1) 82%,rgba(199, 199, 199, 0.1) 82%, rgba(199, 199, 199, 0.1) 100%),linear-gradient(135deg, rgba(41, 41, 41, 0.04) 0%, rgba(41, 41, 41, 0.04) 10%,rgba(28, 28, 28, 0.04) 10%, rgba(28, 28, 28, 0.04) 100%),linear-gradient(90deg, rgb(174, 174, 174),rgb(181, 181, 181))',
    position: 'absolute',
    borderRadius: '4px 4px 0 0',
  },
  [theme.breakpoints.down('sm')]: {
    aboutme: {
      '& h2': {
        fontSize: '0.9rem !important',
      },
      '& p': {
        fontSize: '0.9rem',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    details: {
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
    },
    aboutme: {
      '& article': {
        gridTemplateRows: '1fr 1fr 1fr',
      },
    },
  },
}));

const AboutMe = (props) => {
  const classes = useStyles();
  const userData = useSelector((state) => state.user);
  const lang = useSelector((state) => state.rootTemplates.defaultLanguage);

  const userDetails = [
    { id: 1, description: translations[lang].firstname + ':', value: userData.firstname },
    { id: 2, description: translations[lang].lastname + ':', value: userData.lastname },
    { id: 3, description: translations[lang].phone + ':', value: userData.phone },
    { id: 4, description: translations[lang].email + ':', value: userData.email },
    { id: 5, description: translations[lang].address + ':', value: userData.companyAddress1 },
    {
      id: 6,
      description: userData.companyAddress2 ? translations[lang].address : '',
      value: userData.companyAddress2,
    },
    { id: 7, description: translations[lang].zip + ':', value: userData.companyPostalCode },
    { id: 8, description: translations[lang].city + ':', value: userData.companyCity },
    { id: 9, description: translations[lang].country + ':', value: userData.companyCountryName },
  ];

  return (
    <Paper elevation={1} className={classes.aboutme}>
      <div className={classes.background}></div>
      <article>
        <CompanyHeader
          translations={translations[lang].myaccount.companyheader}
          companyName={userData.companyName}
        />
        <div className={classes.detailsContainer}>
          <div className={classes.details}>
            {userDetails.map((row) => (
              <DetailsRow key={row.id} description={row.description} value={row.value} />
            ))}
          </div>
        </div>
      </article>
    </Paper>
  );
};

export default connect(null)(AboutMe);
