import * as actionTypes from './actionTypes';
import axios from 'axios';

export const addRequestBookingStart = (booking) => {
	return {
		type: actionTypes.ADD_REQUEST_BOOKING_START,
		booking
	}
}

export const addRequestBookingSuccess = (uploaded) => {
	return {
		type: actionTypes.ADD_REQUEST_BOOKING_SUCCESS,
		uploaded,
	}
}
export const addRequestBookingFailed = (error) => {
	return {
		type: actionTypes.ADD_REQUEST_BOOKING_FAILED,
		error
	}
}

export const addRequestBooking = (formData, token) => {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${token}`
		}
	}

	return dispatch => {
		dispatch(addRequestBookingStart());

		axios.post(`/api/requestBooking/ship`, formData, config)
			.then(resp => {
				return dispatch(addRequestBookingSuccess(resp.data.uploaded));
			}).catch(err => {
				return dispatch(addRequestBookingFailed(err));
			});
	}

}
