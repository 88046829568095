import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import { getTheme } from '../../../services/statistics/chartThemes';
import translations from '../../../services/translations/translations';
import Paper from '@material-ui/core/Paper';
import { CARRIER_NAMES } from '../../../services/types';

const CarrierDistribution = ({ data: shipmentData, theme, lang }) => {
  echarts.registerTheme(theme, getTheme[theme]);

  const { carriers, carrierRatio } = shipmentData.reduce(
    (acc, curr) => {
      if (
        curr.carrier &&
        curr.carrier.length > 0 &&
        Object.keys(CARRIER_NAMES).includes(curr.carrier)
      ) {
        if (!acc['carriers'].includes(curr.carrier))
          acc['carriers'].push(CARRIER_NAMES[curr.carrier]);

        let carrierExists = false;

        acc['carrierRatio'].forEach((obj, idx) => {
          if (obj.carrier === CARRIER_NAMES[curr.carrier]) {
            acc['carrierRatio'][idx]['value']++;
            if (!carrierExists) carrierExists = true;
          }
        });

        //add carrier if does not exist
        if (!carrierExists) {
          acc['carrierRatio'][acc['carrierRatio'].length] = {
            value: 1,
            carrier: CARRIER_NAMES[curr.carrier],
          };
        }
      }

      return acc;
    },
    { carriers: [], carrierRatio: [] }
  );

  const options = {
    title: {
      text: translations[lang].statistics.carrierDistribution,
      textStyle: {
        fontWeight: 'normal',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 10,
      top: 40,
      data: carriers,
    },
    series: [
      {
        name: translations[lang].statistics.carrier,
        type: 'pie',
        radius: ['50%', '70%'],
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '25',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        avoidLabelOverlap: false,
        data: carrierRatio.map(({ value, carrier }) => {
          return { value, name: carrier };
        }),
      },
    ],
  };

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%', boxSizing: 'border-box', padding: '5px 0' }}
        className='react_for_echarts'
        theme={theme}
      />
    </Paper>
  );
};

export default CarrierDistribution;
