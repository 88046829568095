import React from 'react';
import { makeStyles, Radio } from '@material-ui/core';
import Select from 'react-select';
import * as shippingTypes from '../../../../../services/types';
import { SelectStylesMain } from '../../../../UI/SelectStyles/SelectStyles';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 30px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  additionalServicesHeader: {
    alignSelf: 'baseline',
    width: '100%',
    padding: '7px 0',
    boxShadow: '0 4px 4px -2px grey',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '& > p': {
      textAlign: 'center',
      fontSize: '1.1rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
  },
  deliveryOptions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '1.2rem',
    padding: '10px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 5px',
    },
    boxSizing: 'border-box',
  },
  fixedDeliveryOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  deliveryMethod: {
    fontSize: '1.2rem',
    // backgroundColor: '#612c142e',
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  dropOffLocationList: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
}));

const UpsAdditionalServices = ({
  handleDeliveryMethodChange,
  selectedDeliveryMethod,
  removeTextMessageNotification,
  addTextMessageNotification,
  translations,
  dropOffSelected,
  setDropOffSelected,
  handleDropOffChange,
  carrierColors,
}) => {
  const classes = useStyles();
  const { serviceCode, upsDropOffLocations } = useSelector((state) => state.booking);

  useEffect(() => {
    if (selectedDeliveryMethod === shippingTypes.UPS_ACCESS_POINT_DELIVERY) {
      !dropOffSelected &&
        upsDropOffLocations.length > 0 &&
        setDropOffSelected(upsDropOffLocations[0]);

      //Automatically add text message notification when access point is selected
      addTextMessageNotification();
    }
    if (selectedDeliveryMethod === shippingTypes.DOOR_DELIVERY) {
      removeTextMessageNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryMethod]);

  const serviceCodesNoOptions = ['11'];

  return (
    <div className={classes.root}>
      <div style={carrierColors} className={classes.additionalServicesHeader}>
        <p>{translations.onlinebooking.deliverto}</p>
      </div>
      {!serviceCodesNoOptions.includes(serviceCode) && (
        <div className={classes.deliveryOptions}>
          <div>
            <Radio
              color="primary"
              checked={selectedDeliveryMethod === shippingTypes.DOOR_DELIVERY}
              onChange={handleDeliveryMethodChange}
              value={shippingTypes.DOOR_DELIVERY}
              name="door"
              id="door"
            />
            <label htmlFor="door">{translations.onlinebooking.door}</label>
          </div>
          <div>
            <Radio
              color="primary"
              checked={selectedDeliveryMethod === shippingTypes.UPS_ACCESS_POINT_DELIVERY}
              onChange={handleDeliveryMethodChange}
              value={shippingTypes.UPS_ACCESS_POINT_DELIVERY}
              name="accesspoint"
              id="accesspoint"
            />
            <label htmlFor="accesspoint">Access Point</label>
          </div>
        </div>
      )}
      {serviceCodesNoOptions.includes(serviceCode) && (
        <div className={classes.fixedDeliveryOptionContainer}>
          {selectedDeliveryMethod === shippingTypes.DOOR_DELIVERY && (
            <p className={classes.deliveryMethod}>{translations.onlinebooking.door}</p>
          )}
          {selectedDeliveryMethod === shippingTypes.UPS_ACCESS_POINT_DELIVERY && (
            <p className={classes.deliveryMethod}>Access point</p>
          )}
        </div>
      )}
      {selectedDeliveryMethod === shippingTypes.UPS_ACCESS_POINT_DELIVERY && (
        <div className={classes.dropOffLocationList}>
          <div>
            {upsDropOffLocations.length > 0 && (
              <Select
                value={dropOffSelected}
                onChange={handleDropOffChange}
                options={upsDropOffLocations}
                styles={SelectStylesMain}
                menuPlacement="auto"
              />
            )}

            {upsDropOffLocations.length === 0 && (
              <p>{translations.onlinebooking.noaccesspointfound}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpsAdditionalServices;
