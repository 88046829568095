import axios from 'axios';
import * as actionTypes from './actionTypes';
import { createBookingStatusFromTemplateComment } from '../../services/helpers/bookingStatus';

//RECIPIENT AND SENDER ADD - WITH CONTACT
export const addCompanyTemplate = (company, templateName, flow, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };
  const companyData = Object.assign({}, company);
  companyData.templateName = templateName;
  if ('templateId' in companyData) delete companyData.templateId; //If saving an old template as new, previous templateId should not exist in request.

  const requestData = { companyData, templateName, flow };

  return (dispatch) => {
    if (flow === 'sender') dispatch(addSenderCompanyTemplateSubmitStart());
    if (flow === 'recipient') dispatch(addRecipientCompanyTemplateSubmitStart());

    axios
      .post('/api/templates/addcompany', requestData, config)
      .then((resp) => {
        if (flow === 'sender') {
          dispatch(addSenderCompanyTemplateSubmitSuccess(resp.data.response));
        } else if (flow === 'recipient') {
          dispatch(addRecipientCompanyTemplateSubmitSuccess(resp.data.response));
        }
        try {
          if (resp.data.response.templateComment) {
            const bookingStatusComment = createBookingStatusFromTemplateComment(
              resp.data.response.templateComment,
              flow
            );
            dispatch({
              type: actionTypes.ADD_BOOKING_STATUS,
              payload: bookingStatusComment,
            });
          }
        } catch {}
      })
      .catch((err) => {
        if (flow === 'sender') {
          dispatch(addSenderCompanyTemplateSubmitFailed(err));
        } else if (flow === 'recipient') {
          dispatch(addRecipientCompanyTemplateSubmitFailed(err));
        }
      });
  };
};

//RECIPIENT - ADD
export const addRecipientCompanyTemplateSubmitStart = () => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_TEMPLATE_START,
  };
};

export const addRecipientCompanyTemplateSubmitFailed = (error) => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_TEMPLATE_FAILED,
    error,
  };
};

export const addRecipientCompanyTemplateSubmitSuccess = (addedCompany) => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_TEMPLATE_SUCCESS,
    payload: addedCompany,
  };
};

export const addRecipientCompanyTemplate = (companyData, flow, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  const requestData = { companyData, flow };

  return (dispatch) => {
    dispatch(addRecipientCompanyTemplateSubmitStart());
    axios
      .post('/api/templates/addcompany', requestData, config)
      .then((resp) => {
        dispatch(addRecipientCompanyTemplateSubmitSuccess(resp.data.response));
      })
      .catch((err) => dispatch(addRecipientCompanyTemplateSubmitFailed(err)));
  };
};

//RECIPIENT - UPDATE
export const updateRecipientCompanyTemplateSubmitStart = () => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_TEMPLATE_START,
  };
};

export const updateRecipientCompanyTemplateSubmitFailed = (error) => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_TEMPLATE_FAILED,
    error,
  };
};

export const updateRecipientCompanyTemplateSubmitSuccess = (updatedCompany, id) => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_TEMPLATE_SUCCESS,
    updatedCompany,
    id,
  };
};

export const updateRecipientCompanyTemplateFromBooking = (queryData, templateName, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  if (!(templateName && queryData.templateId)) return;
  const { templateId } = queryData;

  const companyData = Object.assign({}, queryData);
  delete companyData.templateId;
  companyData['templateName'] = templateName;

  // If template does not have any contacts assigned
  // then add the contact details entered
  // TODO: Option to add new contacts even if a contact exists
  if (companyData.contacts.length === 0) {
    companyData.contacts.push({
      name: companyData.contact,
      email: companyData.email,
      phone: companyData.phone,
    });
    delete companyData.phone;
    delete companyData.email;
  }

  // If contacts exist then check and update contact
  if (companyData.contacts.length > 0) {
    //check whether the contacts include the contact name entered
    const contactExists = companyData.contacts.some(
      (contact) => contact.name === companyData.contact
    );
    // if contact name entered does exist then check and update if email or phone is different
    if (contactExists) {
      const contactIndex = companyData.contacts.findIndex(
        (contact) => contact.name === companyData.contact
      );
      if (companyData.contacts[contactIndex].email !== companyData.email) {
        companyData.contacts[contactIndex].email = companyData.email;
      }
      if (companyData.contacts[contactIndex].phone !== companyData.phone) {
        companyData.contacts[contactIndex].phone = companyData.phone;
      }
    } else {
      // if contact name entered does not exist then add it to the contacts array
      companyData.contacts.push({
        name: companyData.contact,
        email: companyData.email,
        phone: companyData.phone,
      });
    }
    delete companyData.phone;
    delete companyData.email;
  }

  if (companyData.contact) delete companyData.contact;

  return (dispatch) => {
    dispatch(updateRecipientCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/updatecompany', { companyData, id: templateId }, config)
      .then((resp) => {
        dispatch(updateRecipientCompanyTemplateSubmitSuccess(resp.data.results, templateId));
        try {
          if (resp.data.results.templateComment) {
            const bookingStatusComment = createBookingStatusFromTemplateComment(
              resp.data.results.templateComment,
              'recipient'
            );
            dispatch({
              type: actionTypes.ADD_BOOKING_STATUS,
              payload: bookingStatusComment,
            });
          }
        } catch {}
      })
      .catch((err) => dispatch(updateRecipientCompanyTemplateSubmitFailed(err)));
  };
};

export const updateRecipientCompanyTemplateFromTemplates = (queryData, templateId, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };
  const companyData = Object.assign({}, queryData);

  // If template does not have any contacts assigned
  // then add the contact details entered
  // TODO: Option to add new contacts even if a contact exists
  if (companyData.contacts.length === 0) {
    companyData.contacts.push({
      name: companyData.contact,
      email: companyData.email,
      phone: companyData.phone,
    });
    delete companyData.phone;
    delete companyData.email;
  }

  if (companyData.contact) delete companyData.contact;

  return (dispatch) => {
    dispatch(updateRecipientCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/updatecompany', { companyData, id: templateId }, config)
      .then((resp) => {
        dispatch(updateRecipientCompanyTemplateSubmitSuccess(resp.data.results, templateId));
      })
      .catch((err) => dispatch(updateRecipientCompanyTemplateSubmitFailed(err)));
  };
};

//RECIPIENT - FETCH
export const fetchRecipientCompaniesTemplatesSubmitStart = () => {
  return {
    type: actionTypes.FETCH_RECIPIENT_COMPANIES_TEMPLATES_START,
  };
};

export const fetchRecipientCompaniesTemplatesSubmitFailed = (error) => {
  return {
    type: actionTypes.FETCH_RECIPIENT_COMPANIES_TEMPLATES_FAILED,
    error,
  };
};

export const fetchRecipientCompaniesTemplatesSubmitSuccess = (fetchedCompanies) => {
  return {
    type: actionTypes.FETCH_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS,
    payload: fetchedCompanies,
  };
};

export const fetchRecipientCompaniesTemplates = ({ userId, token }) => {
  return (dispatch) => {
    dispatch(fetchRecipientCompaniesTemplatesSubmitStart());
    axios
      .get(`/api/templates/recipients/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        dispatch(fetchRecipientCompaniesTemplatesSubmitSuccess(resp.data.companies));
      })
      .catch((err) => dispatch(fetchRecipientCompaniesTemplatesSubmitFailed(err)));
  };
};

//RECIPIENT - REMOVE
export const removeRecipientCompaniesTemplatesStart = () => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_START,
  };
};

export const removeRecipientCompaniesTemplatesSuccess = (payload) => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_SUCCESS,
    payload,
  };
};

export const removeRecipientCompaniesTemplatesFailed = (err) => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANIES_TEMPLATES_FAILED,
    error: err,
  };
};
export const removeRecipientCompaniesTemplates = (userId, token) => {
  return (dispatch) => {
    dispatch(removeRecipientCompaniesTemplatesStart());
    axios
      .delete(`/api/templates/removerecipients/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        dispatch(removeRecipientCompaniesTemplatesSuccess(resp.data));
      })
      .catch((err) => dispatch(removeRecipientCompaniesTemplatesFailed(err)));
  };
};

//SENDER - ADD
export const addSenderCompanyTemplateSubmitStart = () => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_TEMPLATE_START,
  };
};

export const addSenderCompanyTemplateSubmitFailed = (error) => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_TEMPLATE_FAILED,
    error,
  };
};

export const addSenderCompanyTemplateSubmitSuccess = (addedCompany) => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_TEMPLATE_SUCCESS,
    payload: addedCompany,
  };
};

export const addSenderCompanyTemplate = (companyData, flow, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  const requestData = { companyData, flow };
  return (dispatch) => {
    dispatch(addSenderCompanyTemplateSubmitStart());
    axios
      .post('/api/templates/addcompany', requestData, config)
      .then((resp) => {
        dispatch(addSenderCompanyTemplateSubmitSuccess(resp.data.response));
      })
      .catch((err) => {
        dispatch(addSenderCompanyTemplateSubmitFailed(err));
      });
  };
};

//SENDER - UPDATE
export const updateSenderCompanyTemplateSubmitStart = () => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_TEMPLATE_START,
  };
};

export const updateSenderCompanyTemplateSubmitFailed = (error) => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_TEMPLATE_FAILED,
    error,
  };
};

export const updateSenderCompanyTemplateSubmitSuccess = (updatedCompany, id) => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_TEMPLATE_SUCCESS,
    updatedCompany,
    id,
  };
};

export const updateSenderCompanyFromBooking = (queryData, templateName, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  if (!(templateName && queryData.templateId)) return;
  const { templateId } = queryData;

  const companyData = Object.assign({}, queryData);
  delete companyData.templateId;
  companyData['templateName'] = templateName;

  // If template does not have any contacts assigned
  // then add the contact details entered
  // TODO: Option to add new contacts even if a contact exists
  if (companyData.contacts.length === 0) {
    companyData.contacts.push({
      name: companyData.contact,
      email: companyData.email,
      phone: companyData.phone,
    });
    delete companyData.phone;
    delete companyData.email;
  }

  // If contacts exist then check and update contact
  if (companyData.contacts.length > 0) {
    //check whether the contacts include the contact name entered
    const contactExists = companyData.contacts.some(
      (contact) => contact.name === companyData.contact
    );
    // if contact name entered does exist then check and update if email or phone is different
    if (contactExists) {
      const contactIndex = companyData.contacts.findIndex(
        (contact) => contact.name === companyData.contact
      );
      if (companyData.contacts[contactIndex].email !== companyData.email) {
        companyData.contacts[contactIndex].email = companyData.email;
      }
      if (companyData.contacts[contactIndex].phone !== companyData.phone) {
        companyData.contacts[contactIndex].phone = companyData.phone;
      }
    } else {
      // if contact name entered does not exist then add it to the contacts array
      companyData.contacts.push({
        name: companyData.contact,
        email: companyData.email,
        phone: companyData.phone,
      });
    }
    delete companyData.phone;
    delete companyData.email;
  }

  if (companyData.contact) delete companyData.contact;

  return (dispatch) => {
    dispatch(updateSenderCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/updatecompany', { companyData, id: templateId }, config)
      .then((resp) => {
        dispatch(updateSenderCompanyTemplateSubmitSuccess(resp.data.results, templateId));
        try {
          if (resp.data.results.templateComment) {
            const bookingStatusComment = createBookingStatusFromTemplateComment(
              resp.data.results.templateComment,
              'sender'
            );
            dispatch({
              type: actionTypes.ADD_BOOKING_STATUS,
              payload: bookingStatusComment,
            });
          }
        } catch {}
      })
      .catch((err) => dispatch(updateSenderCompanyTemplateSubmitFailed(err)));
  };
};
export const updateSenderCompanyFromTemplates = (queryData, templateId, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  const companyData = Object.assign({}, queryData);

  return (dispatch) => {
    dispatch(updateSenderCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/updatecompany', { companyData, id: templateId }, config)
      .then((resp) => {
        dispatch(updateSenderCompanyTemplateSubmitSuccess(resp.data.results, templateId));
      })
      .catch((err) => dispatch(updateSenderCompanyTemplateSubmitFailed(err)));
  };
};

//SENDER - FETCH
export const fetchSenderCompaniesTemplatesSubmitStart = () => {
  return {
    type: actionTypes.FETCH_SENDER_COMPANIES_TEMPLATES_START,
  };
};

export const fetchSenderCompaniesTemplatesSubmitFailed = (error) => {
  return {
    type: actionTypes.FETCH_SENDER_COMPANIES_TEMPLATES_FAILED,
    error,
  };
};

export const fetchSenderCompaniesTemplatesSubmitSuccess = (fetchedCompanies) => {
  return {
    type: actionTypes.FETCH_SENDER_COMPANIES_TEMPLATES_SUCCESS,
    payload: fetchedCompanies,
  };
};

export const fetchSenderCompaniesTemplates = ({ userId, token }) => {
  return (dispatch) => {
    dispatch(fetchSenderCompaniesTemplatesSubmitStart());

    axios
      .get(`/api/templates/senders/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        dispatch(fetchSenderCompaniesTemplatesSubmitSuccess(resp.data.companies));
      })
      .catch((err) => dispatch(fetchSenderCompaniesTemplatesSubmitFailed(err)));
  };
};

//SENDER - REMOVE
export const removeSenderCompaniesTemplatesStart = () => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANIES_TEMPLATES_START,
  };
};

export const removeSenderCompaniesTemplatesSuccess = (payload) => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANIES_TEMPLATES_SUCCESS,
    payload,
  };
};

export const removeSenderCompaniesTemplatesFailed = (err) => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANIES_TEMPLATES_FAILED,
    error: err,
  };
};

export const removeSenderCompaniesTemplates = (userId, token) => {
  const config = {
    headers: {
      Authorization: 'bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    dispatch(removeSenderCompaniesTemplatesStart());
    axios
      .delete(`/api/templates/removesenders/${userId}`, config)
      .then((resp) => {
        dispatch(removeSenderCompaniesTemplatesSuccess(resp.data));
      })
      .catch((err) => dispatch(removeSenderCompaniesTemplatesFailed(err)));
  };
};

//RECIPIENT CONTACT - ADD
export const addRecipientCompanyContactStart = () => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_CONTACT_START,
  };
};

export const addRecipientCompanyContactFailed = (error) => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_CONTACT_FAILED,
    error,
  };
};

export const addRecipientCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.ADD_RECIPIENT_COMPANY_CONTACT_SUCCESS,
    contactData: updatedContactData,
    companyId,
  };
};

export const addRecipientCompanyContact = (contactData, templateCompanyId) => {
  const requestData = { contactData, templateCompanyId };

  return (dispatch) => {
    dispatch(addRecipientCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/addtemplatecompanycontact', requestData)
      .then((resp) => {
        dispatch(
          addRecipientCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId)
        );
      })
      .catch((err) => addRecipientCompanyContactFailed(err));
  };
};

//RECIPIENT CONTACT - UPDATE
export const updateRecipientCompanyContactStart = () => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_CONTACT_START,
  };
};

export const updateRecipientCompanyContactFailed = (error) => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_CONTACT_FAILED,
    error,
  };
};

export const updateRecipientCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.UPDATE_RECIPIENT_COMPANY_CONTACT_SUCCESS,
    updatedContactData,
    companyId,
  };
};

export const updateRecipientCompanyContact = (contactData, contactIndex, templateCompanyId) => {
  const requestData = { contactData, contactIndex, templateCompanyId };

  return (dispatch) => {
    dispatch(updateRecipientCompanyContactStart());
    axios
      .put(`/api/templates/updatetemplatecompanycontact`, requestData)
      .then((resp) => {
        dispatch(
          updateRecipientCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId)
        );
      })
      .catch((err) => updateRecipientCompanyContactFailed(err));
  };
};

//RECIPIENT CONTACT - REMOVE

export const removeRecipientCompanyContactStart = () => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANY_CONTACT_START,
  };
};

export const removeRecipientCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANY_CONTACT_SUCCESS,
    updatedContactData,
    companyId,
  };
};

export const removeRecipientCompanyContactFailed = (err) => {
  return {
    type: actionTypes.REMOVE_RECIPIENT_COMPANY_CONTACT_FAILED,
    error: err,
  };
};

export const removeRecipientCompanyContact = (contactIndex, templateCompanyId) => {
  const requestData = { contactIndex, templateCompanyId };

  return (dispatch) => {
    dispatch(removeRecipientCompanyContactStart());
    axios
      .put(`/api/templates/removetemplatecompanycontact`, requestData)
      .then((resp) => {
        dispatch(
          removeRecipientCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId)
        );
      })
      .catch((err) => dispatch(removeRecipientCompanyContactFailed(err)));
  };
};

//SENDER CONTACT - ADD
export const addSenderCompanyContactStart = () => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_CONTACT_START,
  };
};
export const addSenderCompanyContactFailed = (error) => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_CONTACT_FAILED,
    error,
  };
};

export const addSenderCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.ADD_SENDER_COMPANY_CONTACT_SUCCESS,
    contactData: updatedContactData,
    companyId,
  };
};

export const addSenderCompanyContact = (contactData, templateCompanyId) => {
  const requestData = { contactData, templateCompanyId };

  return (dispatch) => {
    dispatch(addSenderCompanyTemplateSubmitStart());
    axios
      .put('/api/templates/addtemplatecompanycontact', requestData)
      .then((resp) => {
        dispatch(addSenderCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId));
      })
      .catch((err) => addSenderCompanyContactFailed(err));
  };
};

//Sender CONTACT - UPDATE
export const updateSenderCompanyContactStart = () => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_CONTACT_START,
  };
};

export const updateSenderCompanyContactFailed = (error) => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_CONTACT_FAILED,
    error,
  };
};

export const updateSenderCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.UPDATE_SENDER_COMPANY_CONTACT_SUCCESS,
    updatedContactData,
    companyId,
  };
};

export const updateSenderCompanyContact = (contactData, contactIndex, templateCompanyId) => {
  const requestData = { contactData, contactIndex, templateCompanyId };

  return (dispatch) => {
    dispatch(updateSenderCompanyContactStart());
    axios
      .put(`/api/templates/updatetemplatecompanycontact`, requestData)
      .then((resp) => {
        dispatch(
          updateSenderCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId)
        );
      })
      .catch((err) => updateSenderCompanyContactFailed(err));
  };
};

//SENDER CONTACT - REMOVE
export const removeSenderCompanyContactStart = () => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANY_CONTACT_START,
  };
};

export const removeSenderCompanyContactSuccess = (updatedContactData, companyId) => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANY_CONTACT_SUCCESS,
    updatedContactData,
    companyId,
  };
};

export const removeSenderCompanyContactFailed = (err) => {
  return {
    type: actionTypes.REMOVE_SENDER_COMPANY_CONTACT_FAILED,
    error: err,
  };
};
export const removeSenderCompanyContact = (contactIndex, templateCompanyId) => {
  const requestData = { contactIndex, templateCompanyId };

  return (dispatch) => {
    dispatch(removeSenderCompanyContactStart());
    axios
      .put(`/api/templates/removetemplatecompanycontact`, requestData)
      .then((resp) => {
        dispatch(
          removeSenderCompanyContactSuccess(resp.data.updatedContactData, resp.data.companyId)
        );
      })
      .catch((err) => dispatch(removeSenderCompanyContactFailed(err)));
  };
};

//COPY TEMPLATES BETWEEN SENDER AND RECIPIENT
export const copyCompanyTemplateStart = (copyToFlow) => {
  let type;
  if (copyToFlow === 1) type = actionTypes.ADD_SENDER_COMPANY_TEMPLATE_START;
  if (copyToFlow === 2) type = actionTypes.ADD_RECIPIENT_COMPANY_TEMPLATE_START;

  return {
    type,
  };
};

export const copyCompanyTemplateSuccess = (addedCompany, copyToFlow) => {
  let type;
  if (copyToFlow === 1) type = actionTypes.ADD_SENDER_COMPANY_TEMPLATE_SUCCESS;
  if (copyToFlow === 2) type = actionTypes.ADD_RECIPIENT_COMPANY_TEMPLATE_SUCCESS;

  return {
    type,
    payload: addedCompany,
  };
};

export const copyCompanyTemplateFailed = (err) => {
  return {
    type: actionTypes.COPY_COMPANY_TEMPLATES_START_FAILED,
    error: err,
  };
};
export const copyCompanyTemplate = ({ templateId, copyToFlow }) => {
  return (dispatch) => {
    dispatch(copyCompanyTemplateStart(copyToFlow));
    axios
      .post(`/api/templates/copytemplate`, { templateId, copyToFlow })
      .then((resp) => {
        dispatch(copyCompanyTemplateSuccess(resp.data.response, copyToFlow));
      })
      .catch((err) => dispatch(copyCompanyTemplateFailed(err)));
  };
};

// UPDATE TEMPLATE COMMENT
export const updateTemplateComment = (templateId, comment, flow) => async (dispatch, getState) => {
  try {
    if (flow === 'sender') dispatch({ type: actionTypes.UPDATE_SENDER_TEMPLATE_COMMENT_START });
    if (flow === 'recipient')
      dispatch({ type: actionTypes.UPDATE_RECIPIENT_TEMPLATE_COMMENT_START });

    const { auth } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/templates/update-company-template-comment`,
      {
        templateId,
        comment,
      },
      config
    );

    if (flow === 'sender')
      dispatch({
        type: actionTypes.UPDATE_SENDER_TEMPLATE_COMMENT_SUCCESS,
        templateComment: data.updatedComment,
        templateId,
      });
    if (flow === 'recipient')
      dispatch({
        type: actionTypes.UPDATE_RECIPIENT_TEMPLATE_COMMENT_SUCCESS,
        templateComment: data.updatedComment,
        templateId,
      });
  } catch (err) {
    const error =
      err.response && err.response.data.message ? err.response.data.message : err.message;

    if (flow === 'sender')
      dispatch({ type: actionTypes.UPDATE_SENDER_TEMPLATE_COMMENT_FAILED, error });
    if (flow === 'recipient')
      dispatch({ type: actionTypes.UPDATE_RECIPIENT_TEMPLATE_COMMENT_FAILED, error });
  }
};
