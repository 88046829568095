import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let TextMessage = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 15.833 15.367'>
        <g fill='#4e4d4d' strokeWidth='0.265' transform='translate(-46.304 -114.793)'>
          <path d='M56.86 116.002c-2.762 0-5.005 1.608-5.005 3.593 0 .934.5 1.786 1.323 2.426l-.913 1.998 2.553-1.149a6.697 6.697 0 002.048.315c2.765 0 5.006-1.609 5.006-3.59 0-1.982-2.252-3.593-5.011-3.593zm-2.026 4.019a.474.474 0 11.473-.471.474.474 0 01-.478.47zm2.027 0a.474.474 0 11.473-.471.471.471 0 01-.473.47zm2.029 0a.474.474 0 11.474-.471.474.474 0 01-.48.47z'></path>
          <path d='M54.8 123.83l-.376.17v1.805a.172.172 0 01-.172.172H48.32a.172.172 0 01-.172-.172v-9.126a.172.172 0 01.172-.172h4.519a6.59 6.59 0 012.717-1.082 1.365 1.365 0 00-.926-.368h-6.689a1.378 1.378 0 00-1.373 1.376v12.086a1.378 1.378 0 001.373 1.376h6.69a1.378 1.378 0 001.372-1.376v-4.455a7.599 7.599 0 01-1.204-.233zm-5.422 4.467a.175.175 0 01-.172.172h-.974a.172.172 0 01-.171-.172v-.841a.175.175 0 01.172-.172h.973a.175.175 0 01.172.172zm2.567 0a.172.172 0 01-.172.172h-.974a.175.175 0 01-.172-.172v-.841a.175.175 0 01.172-.172h.974a.175.175 0 01.172.172zm2.566 0a.172.172 0 01-.17.172h-.973a.172.172 0 01-.172-.172v-.841a.175.175 0 01.172-.172h.974a.172.172 0 01.17.172z'></path>
        </g>
      </svg>
    </SvgIcon>
  );
};

TextMessage = pure(TextMessage);
TextMessage.displayName = 'TextMessage';
TextMessage.muiName = 'SvgIcon';

export default TextMessage;
