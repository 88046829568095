import { UPS } from '../../types';
import { productSchema, productSchemaSimple } from '../schemas';

export const ValidateProductTemplate = ({ product, lang = 'en' }) => {
  return validateProduct({ schema: productSchema, product, lang });
};

export const ValidateProductByCarrier = ({ carrier, product, lang = 'en' }) => {
  /* 
  TODO:
    Add carrier specific structure if adding additional carrier with product/invoice generation

    change default case to validation error

   */
  let schema;
  switch (carrier) {
    case UPS:
      schema = productSchema;
      break;
    default:
      return { valide: false, errors: ['Unable to locate carrier'], variant: 'error' };
  }

  return validateProduct({ schema, product, lang });
};
const validateProduct = async ({ schema, product, lang }) => {
  const productValidation = await schema(lang)
    .validate(product)
    .then((valid) => ({ valid: true, errors: null }))
    .catch((err) => ({ valid: false, errors: err.errors, variant: 'error' }));

  return productValidation;
};
export const ValidateProductTemplateSimple = async ({ product, lang }) => {
  const productValidation = await productSchemaSimple(lang)
    .validate(product)
    .then((valid) => ({ valid: true, errors: null }))
    .catch((err) => ({ valid: false, errors: err.errors, variant: 'error' }));

  return productValidation;
};
