import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let Tracking = (props) => {

	return (
		<SvgIcon {...props}>
			<polygon points="12.27 0 9.37 2.77 14.83 2.77 17.73 0 12.27 0" />
			<polygon points="9.03 0 3.36 0 0.46 2.77 6.14 2.77 9.03 0" />
			<path d="M16.32,9.81h0a5,5,0,0,1,2.3,1.83V.89L15.71,3.68V9.62A4.76,4.76,0,0,1,16.32,9.81Z" transform="translate(-0.26 -0.28)" />
			<path d="M9.8,16.54a5,5,0,0,1-.06-3.88,5.07,5.07,0,0,1,5.09-3.19V3.93H9.17V8.2H5.94V3.93H.26v13.8H10.53A5.14,5.14,0,0,1,9.8,16.54Z" transform="translate(-0.26 -0.28)" />
			<path d="M15.93,10.81a4,4,0,0,0-5.14,5.31,4,4,0,0,0,3.66,2.4,4,4,0,0,0,1.48-7.71Zm.73,4.59a2.37,2.37,0,1,1-2.2-3.24,2.25,2.25,0,0,1,.87.17A2.37,2.37,0,0,1,16.66,15.4Z" transform="translate(-0.26 -0.28)" />
			<path d="M19.12,18l-1.31-1.31-1.34,1.15,1.4,1.4a.81.81,0,0,0,1.25,0C19.69,18.62,19.46,18.29,19.12,18Z" transform="translate(-0.26 -0.28)" />

		</SvgIcon>
	);
}

Tracking = pure(Tracking);
Tracking.displayName = 'Tracking';
Tracking.muiName = 'SvgIcon';

export default Tracking
