import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: 'fit-content',
    opacity: 1,
    fontSize: ({ fontSize }) => fontSize,
    color: ({ color }) => color,
    animation: `$flicker 500ms ease-in-out infinite`,
  },
  '@keyframes flicker': {
    '0%': {
      opacity: 0.2,
    },
    '50%': {
      opacity: 0.7,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export const ValidateLoading = ({ color = 'black', fontSize = 16, children }) => {
  const styles = useStyles({ color, fontSize });
  return <div className={styles.loading}>{children}</div>;
};
